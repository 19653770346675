import React, { useState, useEffect } from 'react';
import { ChevronUpIcon, CloseIcon, AgendaIcon, ListCheckedIcon, TickFilledIcon } from 'shared/icons/icons';
import { Utility } from 'utils';
import { toast } from 'react-toastify';
import moment from 'moment';
import { Link } from 'react-router-dom';
import { GET_EVENT_AGENDAS } from 'config/constants/api_endpoints';

export const ViewAgendaList = (props) => {
  const [agendaList, setAgendaList] = useState([]);
  const [isChecked, setIsChecked] = useState(false);
  const [activeClass, setActiveClass] = useState('');

  const getAgendasList = () => {
    const params = {
      campaign_id: props.campaignId,
      user_client_id: props.userData.id,
      checked: isChecked
    };
    if(props.isAgenda)
      params.campaign_id = props.parentId;

    Utility.sendRequest(GET_EVENT_AGENDAS, 1, params, (err, res, body) => {
      body = JSON.parse(body);

      if (body.error) {
        toast.error(body.error.message, { containerId: 'private' });
      } else {
        if(body.data){
          setAgendaList(body.data);
        }

      }
    });
  };

  useEffect(() => {
    getAgendasList();
    isChecked ? setActiveClass('active') : setActiveClass('');
  }, [isChecked]);

  const timeInAmPm = (date) => {
    return moment.utc(date).local().format("hh:mm A");
  };

  const getDayAndDate = () => {
    return moment.utc(props.eventDate).format('dddd, MMMM DD, YYYY');
  }

  const paintAgendaLists = () => {
    const arr = [];
    agendaList.length &&
    agendaList.map(a => {
      arr.push(
        <div className="d-flex  flex-lg-row flex-column mb-4" >
          <div className="mr-3 font-bold display-8 agenda-time" >{moment.utc(a.date).local().format("hh:mm A")}</div>
          <div className="mt-3 border-top pt-4 w-100" >
            {a.data.map(agenda => {
              const goBackLink = agenda.parent_id ? `/campaign/${agenda.parent_id}` : `/campaign/${agenda.id}`
              return (
                <Link to={{
                        pathname: `/agenda/${agenda.id}`,
                        calledFrom: 'AgendaList',
                        goBackLink: goBackLink
                      }}
                      key={`link-${agenda.id}`}
                >
                <div className="agenda-details d-flex mb-3" >
                  <div className="position-relative" >
                    {
                      agenda.image_url ?
                      <img src={agenda.image_url} className="agenda-img"  alt="" /> :
                      <img src={require('assets/images/profile/img-additional.png')} className="agenda-img"  alt="" />
                    }
                    {
                      (agenda.is_calendar_added && agenda.is_active) &&
                      <TickFilledIcon mainClass="agenda-checked" fillClass="fill-white" />
                    }
                  </div>
                    <div className="agenda-details-inner w-100 text-dark" >
                      <div className="font-bold display-7 article-title mb-2" >{agenda.sub_heading}</div>
                    <div className="font-14 mb-2" >{timeInAmPm(agenda.start_at)} - {timeInAmPm(agenda.end_at)}</div>
                  </div>
                </div>
                </Link>
              );
            })}
          </div>
        </div>
      );
    });
    return arr;
  };


  return(
    <>
      <div className="article-page-side h-100" >
            <div className="article-page-side-head py-3 px-4 align-items-center border-bottom" >
              <AgendaIcon fillClass="fill-less-black" />
              <span className="display-8 font-bold ml-2" >View Agenda</span>
              <ChevronUpIcon
                handleClick={props.closeForm}
                mainClass="chevron-up-icon"
              />
                <CloseIcon
                  mainClass="ml-auto cursor-pointer"
                  handleClick={props.closeForm}
                />
            </div>
            <div className="article-page-side-body agenda-side-body" >
              <div className="d-flex justify-content-between align-items-center mb-4 pb-2" >
                <div className="display-7 font-bold text-primary" >{getDayAndDate()}</div>
                <div className={`ml-auto cursor-pointer ${activeClass}`} onClick={() => setIsChecked(!isChecked)}>
                  <ListCheckedIcon
                    fillClass="fill-black"
                  />
                </div>
              </div>
              {paintAgendaLists()}
            </div>
        </div>
    </>
  );
};
