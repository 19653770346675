import React, { useState, useEffect } from "react";
import NewsCard from "../../../scenes/profile/components/NewsCard";
import { useDispatch } from "react-redux";
import { CustomSlider } from "../Slider";

// import { toast } from "react-toastify";
import { Skeleton } from "@mui/material";
const SuggestedNews = (props) => {
  // const [buttonClicked, setButtonClicked] = useState(false);

  // const [checkId, setCheckId] = useState('');
  // useEffect(() => {
  //   console.log(props.feeds , "useffect ke andar")
  //   const data = props.feeds.find((item) => {
  //     return item.corporate_id === checkId
  //   })
  //   console.log(data, props.joinedNeighborhood, checkId, "****************")
  //   if (props.joinedNeighborhood.length !== 0) {
  //     if (props.joinedNeighborhood.includes(checkId) && data) {
  //       handleArticleClick(data);
  //     }
  //     else {
  //       if (data) {
  //         toast.error(`please join this ${data.community_name || data.author_name}`, { containerId: "private" });
  //       }
  //     }
  //   }
  // }, [buttonClicked])

  // console.log(props.joinedNeighborhood, "props")
  // console.log(props.feeds, "feeds")
  // const handleClick = (checId) => {
  //   console.log("wokring")

  //   setButtonClicked(!buttonClicked);
  //   setCheckId(checId);
  //   console.log("here")

  // }
  // const [isNewsOpen, setNewsOpen] = useState(true);

  // console.log(props.joinedNeighborhood, "joined neifh")
  const dispatch = useDispatch();
  const handleArticleClick = (data) => {
    // setNewsOpen(false);
    // console.log(isNewsOpen,"clicked ho gaya")
    props.history.push({
      pathname: `/news-details/${data.parent_id || data.id}`,
      calledFrom: props.type,
      goBackLink: goBackLink(),
    });
  };
  const goBackLink = () => {
    if (props.type === "suggestedReads") {
      return "/home";
    } else if (props.type === "myArticles") return "/profile";
  };
  return (
    <div className="slider-mob px-lg-0 mt-5 px-1">
      <h1 className="font-24-bold mb-25 home-page-heading">
        <span className="font-weight-bold home-page-headings d-flex align-items-center">
          <img src={require("assets/images/news.png")} height="20px" />
          <p className="pl-2 m-auto">Featured News</p>
        </span>

        <span
          className="see-all-text display-7"
          onClick={() =>
            // dispatch(
            //   updateSeeAll({
            //     isOpen: "article",
            //     calledFrom: "article",
            //   })
            // )
            props.history.push("/news")
          }
        >
          See All
        </span>
      </h1>
      {/* <h1 className="font-24-bold mb-25 d-flex justify-content-between">
        <span className="m-1">
          {" "}
          <img src={require("assets/images/news.png")} height="20px" />
          Featured News
        </span>

        <span
          className="see-all-text display-7"
          // onClick={() => this.props.setSeeAll("courses")}
        >
          See All
        </span>
      </h1> */}
      {/* <div className="card-container d-flex pb-4">
       
        {props.feeds && props.feeds.length !== 0 ? (
          // [...props.feeds]
          //   .filter((data) => {
          //     return data.ranking >= 1 && data.ranking <= 5;
          //   })
          props.feeds.map((data, index) => {
            return (
              <div key={index} className="mr-3">
                <NewsCard
                  onClick={() => {
                    // handleArticleClick(data);
                    // console.log(
                    //   "on click called ",
                    //   checkId,
                    //   props.joinedNeighborhood,
                    //   data.id
                    // );
                    props.feeds.map((news) => {
                      if (news.corporate_id === null) {
                        handleArticleClick(data);
                      }
                      else {
                        if (news.id === data.id) {
                          //   console.log(typeof corporate_id)
                          if (typeof corporate_id === 'undefined') {
                            checkId.push(news.corporate_id);
                          }
                          else {
                            news.corporate_id.map((id) => {
                              checkId.push(id)
                            })
                          }
                        }
                      }
                    })
                    for (var i = 0; i < checkId.length; i++) {
                      //   console.log("in for");
                      if (props.joinedNeighborhood.includes(checkId[i])) {
                        handleArticleClick(data);
                        return;
                      }
                      else {
                        if (i === checkId.length)
                          toast.error(`please join this ${data.community_name || data.author_name}`, { containerId: "private" });
                      }

                    }
                  }}
                  key={data.id}
                  data={data}
                  Totalcourses={props.feeds}
                // joinedNeighborhood={this.props.joinedNeighborhood}
                // index={index}
                />
              </div>
            );
            // <CourseCard />
          })
        ) : (
          <div className="d-flex align-items-center justify-content-center mt-5">
            <div className="px-5">
              <span className="font-bold display-6">No Records Found</span>
            </div>
          </div>
        )}
      </div> */}
      <CustomSlider>
        {/* {[1, 2, 3, 4, 5, 6, 7, 8, 9, 10].map((item) => (
                <div className="bg-secondary m-3 p-5">
                  <span className="p-5 m-3">{item}</span>
                </div>
              ))} */}
        {props.loadingFeeds ? (
          <div className="d-flex">
            {[1, 2, 3, 4].map((item) => (
              <Skeleton
                variant="rounded"
                width={540}
                height={250}
                key={item}
                className="mr-3 rounded-20 "
              />
            ))}
          </div>
        ) : props.feeds && props.feeds.length !== 0 ? (
          props.feeds
            .filter((item) => item.ranking !== 1)
            .map((data, index) => {
              return (
                <div key={index} className="mr-3">
                  <NewsCard
                    Totalcourses={props.feeds}
                    handleArticleClick={handleArticleClick}
                    // onClick={(e) => {
                    //   props.feeds.map((news) => {
                    //     if (news.corporate_id === null) {
                    //       handleArticleClick(data);
                    //       console.log("corporate is null")
                    //     }
                    //     else if (news.id === data.id) {
                    //       console.log("id is not null")
                    //       handleClick(news.corporate_id);
                    //     }
                    //   })
                    // }}
                    key={data.id}
                    data={data}
                    joinedNeighborhood={props.joinedNeighborhood}
                    // index={index}
                  />
                </div>
              );
              // <CourseCard />
            })
        ) : (
          <div className="d-flex align-items-center justify-content-center mt-5">
            <div className="px-5">
              <span className="font-bold display-6">No Records Found</span>
            </div>
          </div>
        )}
      </CustomSlider>
    </div>
  );
};

export default SuggestedNews;
