import React, { Component } from 'react';
import UploadFile from 'shared/components/file_upload';
import * as Utility from 'utils/utility';
import { CloseSquareIcon, CircleFilledAddLargeIcon } from 'shared/icons/icons';
import { Form } from 'react-bootstrap';
import { InputField } from 'shared/components/form_elements';
import { SketchPicker } from 'react-color';
import { toast } from 'react-toastify';
import { CLIENT_ID } from 'config/constants/config';
import { CRM_MODULE_IDS } from 'config/constants';
import { CATEGORIES } from 'config/constants/api_endpoints';

const validateFields = ['name'];

export class CampaignCategory extends Component {
  constructor(props) {
    super(props);

    this.state = {
      formData: {},
      formName: 'campaign_category',
      imagePreviewUrl: '',
      imageFile: ''
    };
  }

  componentDidMount = () => {
    if (this.props.categoryId) this.getCategoryDetails(this.props.categoryId);
  }

  componentDidUpdate = (prevProps) => {
    if (prevProps.categoryId !== this.props.categoryId) this.getCategoryDetails(this.props.categoryId);
  }

  getCategoryDetails = (id) => {
    Utility.sendRequest(`${CATEGORIES}/${id}`, 1, {}, async (err, res, body) => {
      body = JSON.parse(body);

      if (body.error) {
        toast.error(body.error.message, { containerId: 'private' });
      } else {
        this.setDefaultData(body);
      }
    });
  }

  setDefaultData = (data) => {
    const categoryData = {
      name: data.name,
      color_code: data.color_code
    };

    this.setState({ formData: categoryData, imagePreviewUrl: data.image_url });
  }

  handleChange = (event) => {
    let formData = this.state.formData;

    if (event.target.name === 'color_code') this.validateColorField();

    formData = Utility.validateAndReturnFormData(this, formData, event, validateFields,  this.state.formName);

    if (event.target.name === 'color_code') {
      if (event.target.value.length === 1 && event.target.value !== '#') formData.color_code = `#${formData.color_code}`;
    }

    this.setState({ formData });
  }

  validateColorField = () => {
    const element = Utility.getElementById('color_code');
    const error = Utility.getElementById(`color_codeError`);

    if (element.value.length > 7) {
      if (error) error.textContent = 'Only 6 characters allowed';
      return false;
    } else {
      if (error) error.textContent = null;
      return true;
    }
  }

  handleFileChange = (inputId, previewURL, file, name) => {
    const formData = { ...this.state.formData };

    if (name === 'image_url') {
      this.setState({ imagePreviewUrl: previewURL, imageFile: file, formData }, () => {
        Utility.isFilePathEmpty('image_url', this.state.imagePreviewUrl, this.state.formName);
      });
    }
  }

  removeUploadedFile = (inputId, name, e) => {
    e.preventDefault();
    if (name === 'image_url') {
      const formData = { ...this.state.formData };
      formData.image_url = '';
      this.setState({ imageFile: '', imagePreviewUrl: '', formData });
    }
  }

  handleChangeComplete = (color) => {
    const formData = this.state.formData;
    formData.color_code = color.hex;
    this.openColorSelector();

    this.setState({ formData });
  };

  uploadFiles = async (id, imageSignedURL) => {
    let imageURL = this.state.imagePreviewUrl;

    if (!Utility.isEmpty(this.state.imageFile.size)) {
      imageURL = await Utility.uploadFile(this.state.imageFile, imageSignedURL);
    }

    this.updateImageUrl(id, imageURL);
  }

  updateImageUrl = (id, imageURL) => {
    const params  = {
      image_url: imageURL,
    };

    Utility.sendRequest(`${CATEGORIES}/${id}`, 3, params, (err, res, body) => {
      body = JSON.parse(body);

      if (body.error) {
        toast.error(body.error.message, { containerId: 'private' });
      } else {
        this.props.getCategoryList();
        this.props.closeSummary();
      }
    });
  }

  createParams = (formData) => {
    const userData = this.props.userData;

    return {
      name: formData.name,
      color_code: formData.color_code,
      created_by: userData.id,
      updated_by: userData.id,
      is_active: true,
      is_global: false,
      client_id: CLIENT_ID,
      owner_id: userData.id,
      campaign_type_id: this.props.selectedGroup.id || this.props.campaignTypeId,
      crm_module_id: CRM_MODULE_IDS.CAMPAIGN
    };
  }

  createCategory = (params) => {
    Utility.sendRequest(CATEGORIES, 2, params, async (err, res, body) => {
      body = JSON.parse(body);

      if (body.error) {
        toast.error(body.error.message, { containerId: 'private' });
      } else {
        if (!Utility.isEmpty(this.state.imagePreviewUrl) && body.data.imageSignedURL) {
          await this.uploadFiles(body.data.id, body.data.imageSignedURL);
        } else {
          this.props.getCategoryList();
          this.props.closeSummary();
        }
      }
    });
  }

  updateCategory = (id) => {
    const params = {
      name: this.state.formData.name,
      color_code: this.state.formData.color_code
    };

    Utility.sendRequest(`${CATEGORIES}/${id}`, 3, params, async (err, res, body) => {
      body = JSON.parse(body);

      if (body.error) {
        toast.error(body.error.message, { containerId: 'private' });
      } else {
        if (!Utility.isEmpty(this.state.imagePreviewUrl) && body.data.imageSignedURL) {
          await this.uploadFiles(body.data.id, body.data.imageSignedURL);
        } else {
          this.props.getCategoryList();
          this.props.closeSummary();
        }
      }
    });
  }

  handleFormErrors = () => {
    const isValid = Utility.showFormErrors(this, validateFields, this.state.formName);
    const colorField = this.validateColorField();

    return ((isValid && colorField) ? true : false);
  }

  submit = (e) => {
    e.preventDefault();
    const formData = Utility.deepClone(this.state.formData);
    const isFormValid = this.handleFormErrors();

    if (!isFormValid) {
      toast.error('Fill the necessary details', { containerId: 'public' });
      return;
    }

    if (this.props.categoryId) {
      this.updateCategory(this.props.categoryId);
    } else {
      const params = this.createParams(formData);
      this.createCategory(params);
    }
  }

  openColorSelector = () => {
    this.setState((state) => {
      return { showColorSelector: !state.showColorSelector };
    });
  }

  render() {
    return (
      <>
        <div className="add-category">
          <div className="add-category-head d-flex align-items-center">
            <div className="display-5 font-bold">Add Category</div>
            <div className="ml-auto">
              <CloseSquareIcon mainClass="width-20 cursor-pointer" handleClick={this.props.closeSummary} />
            </div>
          </div>
          <div className="add-category-body py-4">
            <Form className="shar-form search-form search-form-pwa sidebar-form pb-4 pb-md-0">
              <Form.Group className="mb-4 pb-1">
                <Form.Label className="font-bold display-8 mb-3">Category Name</Form.Label>
                <InputField
                  required={true}
                  type="text"
                  name="name"
                  ref="name"
                  value={this.state.formData.name}
                  placeholder="Enter Category Name..."
                  handleChange={this.handleChange}
                />
              </Form.Group>
              <Form.Group className="mb-4 pb-1">
                <Form.Label className="font-bold display-8 mb-3">{this.props.edit ? 'Edit Primary Image' : 'Add an image or logo'}</Form.Label>
                <div className="align-items-center justify-content-center post-pic position-relative page-sidebar-upload create-article-upload ml-1">
                  <label className="post-uplodad-label mb-0 cursor-pointer">
                    <UploadFile
                      name='image_url'
                      inputId="image_url"
                      removeText="Remove image"
                      categoryName="category_image"
                      acceptTypes="image/x-png,image/gif,image/jpeg"
                      handleFileChange={this.handleFileChange}
                      removeUploadedFile={this.removeUploadedFile}
                      uploadedFilePreviewUrl={this.state.imagePreviewUrl}
                    />
                    <div className="error errorIcon" id={'image_urlError'} />
                    <CircleFilledAddLargeIcon mainClass="add-icon-upper" fillClass="fill-primary" />
                  </label>
                  <div className="error errorIcon" />
                </div>
              </Form.Group>
              <Form.Group className="mb-4 pb-1">
                <Form.Label className="font-bold display-8 mb-3">Assign a color to category</Form.Label>
                <div className="align-items-center mb-4 shar-color-picker-container" >
                  <InputField
                    type="text"
                    id="color_code"
                    name="color_code"
                    ref="color_code"
                    value={this.state.formData.color_code}
                    placeholder="#HEX"
                    handleChange={this.handleChange}
                  />
                  <div className="shar-color-picker cursor-pointer" onClick={this.openColorSelector}/>
                  {
                  this.state.showColorSelector &&
                  <SketchPicker
                    color={this.state.formData.color_code}
                    onChangeComplete={this.handleChangeComplete}
                  />
                }
                </div>
              </Form.Group>
              <button
               className="btn btn-theme btn-primary btn-rounded mt-3 font-14"
               onClick={this.submit}
              >
                CONFIRM
              </button>
            </Form>
          </div>
        </div>
      </>
    );
  }
}
