import React, { Component } from "react";
import { Switch, Redirect } from "react-router-dom";
import { connect } from "react-redux";
import {
  updateSelectedModule,
  updateSelectedSidebarItem,
  updateIsChatFormOpen,
  updateUserData,
} from "shared/actions";
import { PUBLIC_URL } from "config/constants";
import { Utility } from "utils";
import { LandingPage } from "scenes/home";
import ProfileContainer from "scenes/profile";
// import PwaProfileContainer from "scenes/profile/pwa";
import AppLayout from "shared/components/layout";
import HomePageLayout from "shared/components/home_page_layout";
import TermsOfServices from "shared/components/terms_and_privacy/terms_of_services";
import PrivacyPolicy from "shared/components/terms_and_privacy/privacy_policy";
import SignIn from "scenes/users/components/sign_in";
import SignUp from "scenes/users/components/registration";
import ForgotPassword from "scenes/users/components/forgot_password/index.js";
import CreatePassword from "scenes/users/components/create_password";
import { PwaLandingPage } from "scenes/users/components/pwa";
import HomePage from "scenes/users/components/home/index.js";
import ActivateUser from "scenes/users/components/activate_user";
import NetworkContainer from "scenes/networks/new_index";
import CommunicationsContainer from "scenes/communications";
import EmptyLayout from "shared/components/empty_layout";
import GiftCard from "scenes/tools/toolbox/gift_cards/public";
import CampaignContainer from "scenes/campaigns";
import FinanceContainer from "scenes/finances";
import GroupChat from "shared/components/group_chats.js";
import StatisticsContainer from "scenes/statistics";
import ToolsContainer from "scenes/tools";
import ShopContainer from "scenes/shops";
import TopicDetail from "scenes/topics/index.js";
import Campaign from "scenes/campaigns/web";
import { ViewAgenda } from "shared/components/v2/campaign/components/view_agenda";
import CreateEvent from "../shared/components/v2/leftPanelPages/CreateEvent.js";
import Events from "../shared/components/v2/leftPanelPages/events";
import Courses from "../shared/components/v2/leftPanelPages/courses";
import MediaView from "scenes/campaigns/components/media_view.js";
import GroupHome from "scenes/groups";
import CoursePage from "../scenes/Course/index.js";
import GroupDetail from "../scenes/groups/detail/index";
import cookie from "react-cookies";
import { IconsScreen } from "shared/icons/AllIcons";
import AppRoute from "./app_route";
import { Region } from "../scenes/region";
import News from "../shared/components/v2/leftPanelPages/news";
import EventDetails from "../shared/components/EventDetails";
import NewsDetails from "../shared/components/NewsDetails";
import Events_Confirmation from "../shared/components/v2/events_confirmation";
import NewsCreationForm from "../shared/components/NewsCreationForm";
import ConfirmSubmitCourse from "../scenes/Course/ConfirmCoursePage/index.js";
import Community_page from "../scenes/users/components/home/Communities/community_page.js";

class SMRoutes extends Component {
  checkLocal = (data) => {
    if (typeof localStorage !== "undefined") {
      let localData = Utility.getLocalStorage("users");

      if (localData.length > 0) {
        const id = localData.find((user) => user.id === data.id);
        if (!id) {
          localData.push(data);
          Utility.setLocalStorage("users", localData);
        }
      } else {
        Utility.setLocalStorage("users", []);
        localData = Utility.getLocalStorage("users");
        localData.push(data);
        Utility.setLocalStorage("users", localData);
      }
    }
  };

  componentDidMount = () => {
    this.props.updateSelectedSidebarItem(this.props.location.pathname);
  };

  componentDidUpdate = () => {
    let selectedModule = this.props.crmModules.find((item) => {
      return (
        item.name.toLowerCase() === this.props.location.pathname.split("/")[1]
      );
    });

    selectedModule = !Utility.isEmpty(selectedModule)
      ? {
          id: selectedModule.id,
          name: selectedModule.name,
          image_url: selectedModule.image_url,
        }
      : {};

    this.props.updateSelectedModule(selectedModule);

    this.props.updateSelectedSidebarItem(this.props.location.pathname);
  };

  render() {
    let config;
    let path;
    const skipLogin = ["gift_cards"];
    const actualPath = this.props.location.pathname;
    const createHistory = this.props.history;
    const pathArray = actualPath.split("/");
    const userAuth = {
      isAuthenticated: cookie.load("sessionToken") !== undefined ? true : false,
    };
    const pwaModeEnabled = Utility.pwaModeEnabled();
    const profileContainer = pwaModeEnabled
      ? ProfileContainer
      : ProfileContainer;

    const handlePwaLanding = () => {
      return Utility.pwaModeEnabledNew() ? PwaLandingPage : LandingPage;
    };

    if (pathArray.length === 3 && skipLogin.includes(pathArray[1]))
      path = "/" + pathArray[1];
    else path = actualPath;

    if (
      (path === "/users/sign_up" || path === "/users/sign_in") &&
      userAuth.isAuthenticated
    ) {
      return <Redirect to="/home" />;
    }

    if (!userAuth.isAuthenticated && !PUBLIC_URL.includes(path)) {
      return pwaModeEnabled ? (
        <Redirect to="/sign_in" />
      ) : (
        <Redirect to="/users/sign_in" />
      );
    } else {
      config = (
        <Switch>
          {pwaModeEnabled ? (
            <AppRoute
              exact
              path="/"
              layout={HomePageLayout}
              component={handlePwaLanding()}
              history={createHistory}
            />
          ) : (
            <AppRoute
              exact
              path="/"
              layout={HomePageLayout}
              component={LandingPage}
              history={createHistory}
            />
          )}

          <AppRoute
            exact
            path="/home"
            layout={AppLayout}
            component={HomePage}
            history={createHistory}
          />

          <AppRoute
            exact
            path="/confirm-submit/:id"
            layout={AppLayout}
            component={ConfirmSubmitCourse}
            history={createHistory}
          />
          <AppRoute
            exact
            path="/course-create"
            layout={AppLayout}
            component={CoursePage}
            history={createHistory}
          />

          <AppRoute
            exact
            path="/community-page"
            layout={AppLayout}
            component={Community_page}
            history={createHistory}
          />
          <AppRoute
            exact
            path="/event"
            layout={EmptyLayout}
            component={ViewAgenda}
            history={createHistory}
          />

          <AppRoute
            exact
            path="/event-confirmation/:id"
            layout={EmptyLayout}
            component={Events_Confirmation}
            history={createHistory}
          />
          <AppRoute
            exact
            path="/terms_of_services"
            layout={EmptyLayout}
            component={TermsOfServices}
            history={createHistory}
          />

          <AppRoute
            exact
            path="/privacy_policy"
            layout={EmptyLayout}
            component={PrivacyPolicy}
            history={createHistory}
          />
          <AppRoute
            exact
            path="/users/sign_in"
            layout={EmptyLayout}
            component={SignIn}
            history={createHistory}
          />
          <AppRoute
            exact
            path="/users/sign_up"
            layout={EmptyLayout}
            component={SignUp}
            history={createHistory}
          />
          <AppRoute
            exact
            path="/users/forgot_password"
            layout={EmptyLayout}
            component={ForgotPassword}
            history={createHistory}
          />
          <AppRoute
            exact
            path="/users/create_password"
            layout={EmptyLayout}
            component={CreatePassword}
            history={createHistory}
          />
          <AppRoute
            exact
            path="/users/activate_user"
            layout={EmptyLayout}
            component={ActivateUser}
            history={createHistory}
          />
          <AppRoute
            exact
            path="/profile"
            layout={AppLayout}
            component={profileContainer}
            history={createHistory}
          />
          <AppRoute
            exact
            path="/profile/:id"
            layout={AppLayout}
            component={profileContainer}
            history={createHistory}
          />

          <AppRoute
            exact
            path="/communications"
            layout={AppLayout}
            component={CommunicationsContainer}
            history={createHistory}
          />
          <AppRoute
            exact
            path="/networks"
            layout={AppLayout}
            component={NetworkContainer}
            history={createHistory}
          />
          <AppRoute
            exact
            path="/gift_cards/:id"
            layout={EmptyLayout}
            component={GiftCard}
            history={createHistory}
          />
          <AppRoute
            exact
            path="/campaigns"
            layout={AppLayout}
            component={CampaignContainer}
            history={createHistory}
          />
          <AppRoute
            exact
            path="/finances"
            layout={AppLayout}
            component={FinanceContainer}
            history={createHistory}
          />
          <AppRoute
            exact
            path="/statistics"
            layout={AppLayout}
            component={StatisticsContainer}
            history={createHistory}
          />
          <AppRoute
            exact
            path="/tools"
            layout={AppLayout}
            component={ToolsContainer}
            history={createHistory}
          />
          <AppRoute
            exact
            path="/shops"
            layout={AppLayout}
            component={ShopContainer}
            history={createHistory}
          />
          <AppRoute
            exact
            path="/campaign/:id"
            layout={AppLayout}
            component={Campaign}
            history={createHistory}
          />
          <AppRoute
            exact
            path="/agenda/:id"
            layout={AppLayout}
            component={Campaign}
            history={createHistory}
          />
          <AppRoute
            exact
            path="/groups"
            layout={AppLayout}
            component={GroupHome}
            history={createHistory}
          />
          <AppRoute
            exact
            path="/groups/:id"
            layout={AppLayout}
            component={GroupDetail}
            history={createHistory}
          />
          <AppRoute
            exact
            path="/events"
            layout={AppLayout}
            component={Events}
            history={createHistory}
          />
          <AppRoute
            exact
            path="/create-event"
            layout={AppLayout}
            component={CreateEvent}
            history={createHistory}
          />
          <AppRoute
            exact
            path="/create-article"
            layout={AppLayout}
            component={NewsCreationForm}
            history={createHistory}
          />
          <AppRoute
            exact
            path="/courses"
            layout={AppLayout}
            component={Courses}
            history={createHistory}
          />
          <AppRoute
            exact
            path="/news"
            layout={AppLayout}
            component={News}
            history={createHistory}
          />
          <AppRoute
            exact
            path="/media"
            layout={AppLayout}
            component={MediaView}
            history={createHistory}
          />
          <AppRoute
            exact
            path="/topics/:id"
            layout={AppLayout}
            component={TopicDetail}
            history={createHistory}
          />
          <AppRoute
            exact
            path="/group_chat"
            layout={EmptyLayout}
            component={GroupChat}
            history={createHistory}
          />
          <AppRoute
            exact
            path="/icons"
            layout={EmptyLayout}
            component={IconsScreen}
            history={createHistory}
          />
          <AppRoute
            exact
            path="/region"
            layout={AppLayout}
            component={Region}
            history={createHistory}
          />
          <AppRoute
            exact
            path="/event-details/:id"
            layout={AppLayout}
            component={EventDetails}
            history={createHistory}
          />
          <AppRoute
            exact
            path="/news-details/:id"
            layout={AppLayout}
            component={NewsDetails}
            history={createHistory}
          />
        </Switch>
      );
    }

    return <div>{config}</div>;
  }
}

const mapActionToProps = {
  updateSelectedModule,
  updateSelectedSidebarItem,
  updateIsChatFormOpen,
  updateUserData,
};

const mapStateToProps = (state) => ({ crmModules: state.crmModules });

export default connect(mapStateToProps, mapActionToProps)(SMRoutes);
