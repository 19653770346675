import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import * as Utility from "utils/utility";
import { Form } from "react-bootstrap";
import { InputField } from "shared/components/form_elements";
import HomeContainer from "shared/components/home_container";
import { GET_USERS_BY_NETWORKS } from "config/constants/api_endpoints";
import { CLIENT_ID } from "config/constants/config";
import MyCommunity from "shared/components/my_community";
import { GET_FOLLOWERS_FOLLOWING } from "config/constants/api_endpoints";
import { showLoader } from "shared/actions";
import { connect } from "react-redux";
import { Spinner } from "react-bootstrap";
import { GIL_CLIENT_ID, ROLE_IDS } from "config/constants";
import { FEATURED_COMMUNITY } from "../../../config/constants/api_endpoints";
import { GrSearch } from "react-icons/gr";

import ProductPagination from "./campaign/components/ProductPagination";
import { Grid } from "@material-ui/core";
import { Skeleton } from "@mui/material";

function Communities(props) {
  const userData = useSelector((state) => state.userData);
  const [pageNumber, setPageNumber] = useState(1);
  const [users, setUsers] = useState([]);
  const [totalPage, setTotalPage] = useState(1);
  const [search, setSearch] = useState("");
  const [isApiResponse, setIsApiResponse] = useState(false);
  const [isJoined, setIsJoined] = useState(true);
  const searchBarData = useSelector((state) => state.headerSearchbar);
  const [communityData, setCommunity] = useState([]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    getUserDetails(pageNumber, searchBarData);
  }, []);

  useEffect(() => {
    communityAll();
  }, []);

  useEffect(() => {
    if (isJoined) {
      setIsJoined(false);
    }
  }, [isJoined]);

  useEffect(() => {
    if (
      [
        ROLE_IDS.USER,
        ROLE_IDS.CLIENT_OWNER,
        ROLE_IDS.CLIENT_ADMIN,
        ROLE_IDS.PARENT_OWNER,
      ].includes(userData.role_id)
    ) {
      // if (userData.role_id === ROLE_IDS.USER || userData.role_id === ROLE_IDS.PARENT_OWNER) {
      setSearch(searchBarData);
      setPageNumber(1);
      getUserDetails(1, searchBarData);
    }
  }, [searchBarData]);

  const getUserDetails = (page_number, search) => {
    const params = {
      client_id: CLIENT_ID,
      type: "users",
      limit: 30,
      page_number,
      is_active: true,
      forCommunity: true,
      sort_by: `[["user_name", "asc"]]`,
    };

    setLoading(true);

    if (search) params.search = search;

    // props.showLoader(true);
    Utility.sendRequest(GET_USERS_BY_NETWORKS, 1, params, (err, res, body) => {
      body = JSON.parse(body);
      setLoading(false);

      setIsApiResponse(true);
      props.showLoader(false);
      if (body.error) {
        toast.error(body.error.message, { containerId: "private" });
      } else {
        if (body.registrants) {
          setUsers(body.registrants);

          setTotalPage(body.total_pages);
        }
      }
    });
  };

  const setDetailsFollowerfollowing = (followingId) => {
    const { id } = userData;
    if (id !== followingId) {
      const params = {
        followerId: id,
        followingId: followingId,
        clientId: CLIENT_ID,
      };

      Utility.sendRequest(
        GET_FOLLOWERS_FOLLOWING,
        2,
        params,
        (err, res, body) => {
          body = JSON.parse(body);
          if (body.error) {
            toast.error(body.error.message, { containerId: "private" });
          } else {
            getUserDetails(undefined, searchBarData);
          }
        }
      );
    }
  };

  const handlePageNumberChange = (value) => {
    setPageNumber(value);
    getUserDetails(value, searchBarData);
  };

  const handleSearch = (ev) => {
    ev.persist();
    const searchString = ev.target.value;
    setSearch(searchString);
  };

  const communityAll = () => {
    const params = {
      platform_id: CLIENT_ID,
      is_client_active: true,
      user_id: userData.user_id,
    };
    setLoading(true);

    Utility.sendRequest(FEATURED_COMMUNITY, 1, params, (err, res, body) => {
      body = JSON.parse(body);
      setLoading(false);

      if (body.error) {
        toast.error(body.error.message, { containerId: "private" });
      } else {
        const newData = [];
        body.data.map((item) => {
          if (!item.region_id) {
            newData.push(item);
          }
        });
        const sortedData = newData.sort((a, b) =>
          a.community_name.localeCompare(b.community_name)
        );
        setCommunity(sortedData);
      }
    });
  };
  return (
    <>
      <HomeContainer
        name="Community"
        className="community-page "
        goBackLink={() => window.history.back()}
        history={"props.history"}
      >
        <div>
          {/* <h1 className="font-24-bold mb-4">
            {userData.platform_id === GIL_CLIENT_ID &&
            userData.role_id === ROLE_IDS.USER &&
            (props.heading === "Community Members" ||
              props.heading === "Community") ? (
              ""
            ) : (
              <ChevronLeftIcon
                handleClick={() => props.closeCallout(null)}
                mainClass="cursor-pointer see-all-back"
              />
            )}
            {props.heading}
          </h1> */}
        </div>
        {
          <div className="dashboard-page-container gil-dashboard">
            <div className="community-section">
              <>
                {CLIENT_ID === "0bc8dc7c-b5a3-4344-b313-819fc640f6b5" && (
                  <>
                    <div className="mb-2">
                      <span className="font-bold display-6">Neighborhoods</span>
                    </div>
                    {Utility.isEmpty(users) && isApiResponse && !loading && (
                      <div className="d-flex align-items-center justify-content-center mt-5">
                        <div className="">
                          <span className="font-bold display-6">
                            No record found
                          </span>
                        </div>
                      </div>
                    )}
                    <div className="d-flex flex-lg-wrap community-section-inner slider-mob-inner mb-42">
                      {!loading ? (
                        !Utility.isEmpty(communityData) &&
                        communityData
                          .filter((item) => {
                            const data = search.length
                              ? item.community_name
                                  .toLowerCase()
                                  .includes(search.toLowerCase())
                              : item.community_name;
                            return data;
                          })
                          .filter((data) => data.role_id !== ROLE_IDS.USER)
                          .filter(
                            (thing, index, self) =>
                              index === self.findIndex((t) => t.id === thing.id)
                          )
                          .map((user) => {
                            return (
                              <MyCommunity
                                key={user.id}
                                item={user}
                                handleOnClick={setDetailsFollowerfollowing}
                                isJoined={isJoined}
                                setIsJoined={setIsJoined}
                                showCharFollowButtons={true}
                                fromSeeAll={Utility.pwaModeEnabled()}
                                closeCallout={props.closeCallout}
                                isFeatured={false}
                                profilePage={true}
                              />
                            );
                          })
                      ) : // <Spinner animation="border" size="sm" role="status" />
                      Utility.pwaModeEnabled() ? (
                        <div className="d-flex">
                          {[1, 2, 3, 4].map((item) => (
                            <Skeleton
                              variant="rectangular"
                              width={200}
                              height={139}
                              className="m-2"
                              key={item}
                            />
                          ))}
                        </div>
                      ) : (
                        <Grid container spacing={5} className="w-100">
                          {[1, 2, 3, 4, 5, 6, 7].map((item) => (
                            <Grid item md={2} lg={3} xl={2} key={item}>
                              <Skeleton
                                variant="rectangular"
                                width={200}
                                height={139}
                                className="m-2"
                              />
                            </Grid>
                          ))}
                        </Grid>
                      )}
                    </div>
                    <div className="mb-2">
                      <span className="font-bold display-6">Citizens</span>
                    </div>
                  </>
                )}
              </>
              {Utility.isEmpty(users) && isApiResponse && !loading && (
                <div className="d-flex align-items-center justify-content-center mt-5">
                  <div className="">
                    <span className="font-bold display-6">No record found</span>
                  </div>
                </div>
              )}
              <div className="d-flex flex-lg-wrap community-section-inner slider-mob-inner mb-42">
                {!loading ? (
                  !Utility.isEmpty(users) &&
                  users.map((user) => {
                    return (
                      <MyCommunity
                        key={user.id}
                        item={user}
                        handleOnClick={setDetailsFollowerfollowing}
                        showCharFollowButtons={true}
                        fromSeeAll={Utility.pwaModeEnabled()}
                        closeCallout={props.closeCallout}
                      />
                    );
                  })
                ) : // <Spinner animation="border" size="sm" role="status" />
                Utility.pwaModeEnabled() ? (
                  <div className="d-flex">
                    {[1, 2, 3, 4].map((item) => (
                      <Skeleton
                        variant="rectangular"
                        width={200}
                        height={139}
                        className="m-2"
                        key={item}
                      />
                    ))}
                  </div>
                ) : (
                  <Grid container spacing={5} className="w-100">
                    {[1, 2, 3, 4, 5, 6, 7].map((item) => (
                      <Grid item md={2} lg={3} xl={2} key={item}>
                        <Skeleton
                          variant="rectangular"
                          width={200}
                          height={139}
                          className="m-2"
                        />
                      </Grid>
                    ))}
                  </Grid>
                )}
              </div>
              <div>
                <ProductPagination
                  value={pageNumber}
                  count={totalPage}
                  handleChangePage={(value) => handlePageNumberChange(value)}
                  size="small"
                />
              </div>
            </div>
          </div>
        }
      </HomeContainer>
    </>
  );
}

const mapActionToProps = { showLoader };

const mapStateToProps = () => ({});

export default connect(mapStateToProps, mapActionToProps)(Communities);
