import React, { useEffect, useState } from "react";
import MessageSectionCard from "./MessageSectionCard";

const MessageFeedSection = (props) => {
  const [messagesData, setMessagesData] = useState([]);

  useEffect(() => {
    if (props.userChats || props.allGroups) {
      const TempMessageData = [...props.userChats, ...props.allGroups].sort(
        function (a, b) {
          var keyA = new Date(a.recent_message_time),
            keyB = new Date(b.recent_message_time);
          // Compare the 2 dates
          if (keyA > keyB) return -1;
          if (keyA < keyB) return 1;
          return 0;
        }
      );
      setMessagesData(TempMessageData);
    }
  }, [props.userChats, props.allGroups]);
  return (
    <div className="mt-3" id="commentsContainer">
      {messagesData && messagesData.length > 0 ? (
        messagesData.map((items) => {
          return (
            <MessageSectionCard
              {...props}
              onClick={() => {
                props.setActiveScreenName("Chat");
                props.setActiveChatDetail(items);
              }}
              isGroup={Object.keys(items).includes("group_private_key")}
              Data={{ ...items }}
            />
          );
        })
      ) : (
        <div className="d-flex justify-items-center w-100">
          <h4 className="m-5 mx-auto">No record found</h4>
        </div>
      )}
    </div>
  );
};

export default MessageFeedSection;
