import React, { Component } from 'react';
import { Row, Col, Table, Spinner, Button, Form } from 'react-bootstrap';
import { Utility, Date } from 'utils';
import { toast } from 'react-toastify';
import { DropDown } from 'shared/components/form_elements';
import { MenuTitle } from 'shared/components/templates/menu_list';
import { ACCOUNTS } from 'config/constants/api_endpoints';
// import { PayDayAlert } from 'scenes/finances/components/pay_day_alert';
import { Receipt } from 'shared/components/templates/receipt';
import { NoRecordFound } from 'shared/components/no_record_found';
import { ReceiptView } from 'scenes/finances/accounts/receipt_view';
import SideCallout from 'shared/components/templates/side_callout';
import { ChevronLeftIcon, CloseIcon } from 'shared/icons/icons';
import { InputField } from 'shared/components/form_elements';

const sortList = [
  { id: '1', name: "Last 30 days" },
  { id: '2', name: 'Last 6 months' },
  { id: '3', name: 'Last 1 year' }
];

export default class AccountList extends Component {
  _isMounted = false;

  constructor(props) {
    super(props);

    this.state = {
      accounts: [],
      balance: 0,
      deposit: 0,
      withdrawal: 0,
      refunds: 0,
      showLoader: true,
      showReceipt: false,
      accountDetails: {},
      showReceiptDetails: false
    };
  }

  componentDidMount = () => {
    const params = { platform_id: this.props.userData.platform_id };
    this._isMounted = true;

    Utility.sendRequest(ACCOUNTS, 1, params, (err, res, body) => {
      body = JSON.parse(body);

      if (body.error) {
        toast.error(body.error.message, { containerId: 'private' });
      } else if (this._isMounted) {
        this.setState({
          accounts: body.data.records,
          balance: body.data.total_balance,
          deposit: body.data.total_deposit,
          withdrawal: body.data.total_withdrawal,
          refund: body.data.total_refund,
          showLoader: false
        });
      }
    });
  }

  componentWillUnmount = () => this._isMounted = false;

  showReceipt = (detailId) => {
    this.setState({ showReceipt: true });
    this.getAccountDetails(detailId);
  }

  hideReceipt = () => {
    this.setState({ showReceipt: false });
  }

  modalActivate = () => {
    document.getElementById('receipt').className += ' d-block';
  }

  getAccountDetails = (detailId) => {
    const accountDetails = this.state.accounts.find(account => {
      return account.td_id === detailId;
    });

    if (this._isMounted) this.setState({ accountDetails });
  }

  render() {
    return (
      <>
        <Col xl={9} lg={8} xs={12} className="ModalOpen h-md-100 scroll-y top-on-sm d-none d-lg-block" id="account-list" >
          <div className="d-block d-lg-none position-relative bg-white px-3 py-4 border-bottom back-button-sm">
            <Button variant="link" className="color-primary text-left align-items-center" onClick={() => this.props.updateSelectedMenuItem({})}>
              <img className="width-10 align-baseline mr-3" src={require("assets/images/chevron_left.svg")} alt="left-icon" />
              Back to Finances
            </Button>
          </div>
          <div className="p-4">
            {/* heading row  */}
            <Row className="mx-0 p-sm-3">
              <div className="network-box-shadow h-100 mt-3 mt-sm-0">
                <div className="network-item-header d-flex align-items-center mt-md-1">
                  <div className="round-circle mr-2">
                  </div>
                  <MenuTitle menuName={this.props.selectedMenuItem.menu_name}/>
                </div>
              </div>
              {/*<div className="ml-auto" >
                <Button varaint="primary" className="btn-toolbox text-center" >
                  <img src={require("assets/images/tip_icon.svg")} alt="Sharemeister Logo Symbol" />
                  Tools
                </Button>
              </div>*/}
            </Row>
            {/* payday Alert  */}
            {/* <PayDayAlert/> */}
            {/* Sort select box continer  */}
            <div className="px-sm-4 py-2">
              <div className="d-flex justify-content-between flex-wrap">
                <div className="account-overview">
                  <div className="font-22 font-bold mb-4">Overview</div>
                  <div className="d-flex align-items-center flex-wrap">
                    <BalanceCard
                      label='Available'
                      balance={this.state.balance}
                      showLoader={this.state.showLoader}
                    />
                    <BalanceCard
                      label='Deposits'
                      balance={this.state.deposit}
                      showLoader={this.state.showLoader}
                    />
                    <BalanceCard
                      label='Withdrawals'
                      balance={this.state.withdrawal}
                      showLoader={this.state.showLoader}
                    />
                    <BalanceCard
                      label='Refunds'
                      balance={this.state.refund}
                      showLoader={this.state.showLoader}
                    />
                  </div>
                </div>
                {/* <div className="d-flex flex-wrap align-items-center justify-content-end align-self-baseline w-lg-100">
                  <span className="font-weight-bolder font-18 mr-3">Sort by</span>
                  <Form.Group controlId="sort" className="mb-0 finance-sort-by" >
                    <DropDown
                      optionId="id"
                      optionValue="name"
                      required={true}
                      name="sort"
                      ref="gender"
                      options={sortList}
                      handleChange={this.handleChange}
                      />
                  </Form.Group>
                </div> */}
              </div>
              {/* Accounts body  */}
              <div className="mt-5">
                <div className="font-22 font-bold mb-4">Account History</div>
                <AccountsTable accounts={this.state.accounts} showLoader={this.state.showLoader} showReceipt={this.showReceipt}/>
                {
                  Utility.isEmpty(this.state.accounts) && !this.state.showLoader &&
                  <NoRecordFound/>
                }
              </div>
            </div>
          </div>
        </Col>
        {
          this.state.showReceipt &&
            <SideCallout 
              position="position-fixed create-campaign-margin select-option-callout"
              calloutHeading='Select Option'
              calloutHeadingImg={require("assets/images/icons/menu_vertical_icon.svg")}
              calloutCloseIcon={ <CloseIcon /> }
              closeCallOut={() => this.setState({showReceipt: false})}
            >
              <div onClick={() => this.setState({showReceiptDetails: true})} className="view-receipt-btn p-4 d-flex justify-content-between align-items-center display-8 b-500 cursor-pointer" >
                View Receipt

                <ChevronLeftIcon mainClass="icon-rotate" />
              </div>
            </SideCallout>
        }
        {
          this.state.showReceiptDetails &&
          <SideCallout 
            position="position-fixed create-campaign-margin receipt-view-callout"
            calloutHeading='Receipt Details'
            calloutHeadingSvg={<ChevronLeftIcon mainClass="mr-3" />}
            calloutCloseIcon={ <CloseIcon /> }
            closeCallOut={() => this.setState({showReceiptDetails: false})}
          >
            <ReceiptView accountDetails={this.state.accountDetails}/>
            <div className="my-5" >
              <label className="font-bold display-8 mb-3 form-label">Send to:</label>
              <InputField
                required={true}
                type="text"
                placeholder="Enter Email"
              />
            </div>
            <button className="btn btn-primary btn-lg font-weight-bold py-3 h-auto ml-3" style={{minWidth: 160}}  >Send</button>
          </SideCallout>
        }
      </>
    );
  }
}

const AccountsTable = props => (
  <>
    {
      props.showLoader
      ? <center><Spinner animation="border" role="status"/></center>
      : <Table striped responsive size="sm" className="shar-table">
        <thead>
          <tr>
            <th className="border-top-0 border-bottom-0">Date</th>
            <th className="border-top-0 border-bottom-0">Description</th>
            <th className="border-top-0 border-bottom-0">Impact</th>
            <th className="border-top-0 border-bottom-0">Deposit</th>
            <th className="border-top-0 border-bottom-0">Withdrawal</th>
            <th className="border-top-0 border-bottom-0">Balance</th>
            <th className="border-top-0 border-bottom-0"></th>
          </tr>
        </thead>
        <tbody>
          {
            props.accounts.map((item) => {
              return (
                <tr key={`${item.td_id}account`}>
                  <td>{Date.formatDate(item.date, 'MM/DD/YYYY')}</td>
                  <td>{item.description}</td>
                  <td>${Utility.toLocaleString(item.impact)}</td>
                  <td>${Utility.toLocaleString(item.deposit)}</td>
                  <td>${Utility.toLocaleString(item.withdrawal)}</td>
                  <td>${Utility.toLocaleString(item.balance)}</td>
                  <td className="cursor-pointer" onClick={() => props.showReceipt(item.td_id)}><img src={require("assets/images/icons/menu_vertical_icon.svg")} style={{height: 13}} alt="View Receipt" /></td>
                </tr>
              );
            })
          }
        </tbody>
      </Table>
    }
  </>
);

const BalanceCard = props => (
  <>
    <div className="border p-3 border-radius-8 mx-3">
      <div className="display-8 font-medium">{props.label}</div>
      <div className="display-7 font-bold text-primary">
        {
          props.showLoader
          ? <Spinner animation="border" role="status" size="sm"/>
          : <>{`$${Utility.toLocaleString(props.balance)}`}</>
        }
      </div>
    </div>
  </>
);
