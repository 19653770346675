import React from "react";
import useMediaQuery from '@mui/material/useMediaQuery';
import Button from "@mui/material/Button";
import ClickAwayListener from "@mui/material/ClickAwayListener";
import Grow from "@mui/material/Grow";
import Paper from "@mui/material/Paper";
import Popper from "@mui/material/Popper";
import MenuItem from "@mui/material/MenuItem";
import MenuList from "@mui/material/MenuList";
import { BiMessageDetail } from "react-icons/bi";
import { FiLink } from "react-icons/fi";
import Stack from "@mui/material/Stack";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import { makeStyles } from "@mui/styles";
import { fontWeight } from "@mui/system";
import { toast } from "react-toastify";

const theme = createTheme({
  palette: {
    primary: {
      main: "#8037BF",
    },
    secondary: {
      main: "#11cb5f",
    },
  },
});

const useStyles = makeStyles((theme) => {
  return {
    root: {
      "&.MuiButton-root": {
        // border: "1px black solid",
        color: "#422A66",
        boxShadow: "0px 1px 3px rgba(0, 0, 0, 0.3)",
        // width: "8rem",
        height: "58px",
        borderRadius: "15px",
        fontWeight: 700,
        fontSize: "1rem",
      },
    },
  };
});

const ShareMenu = (props) => {
  const [open, setOpen] = React.useState(false);
  const anchorRef = React.useRef(null);
  const classes = useStyles();
  const isTab = useMediaQuery('(max-width:820px)');

  const handleToggle = () => {
    setOpen((prevOpen) => !prevOpen);
  };

  const handleClose = (event) => {
    if (anchorRef.current && anchorRef.current.contains(event.target)) {
      return;
    }

    setOpen(false);
  };

  function handleListKeyDown(event) {
    if (event.key === "Tab") {
      event.preventDefault();
      setOpen(false);
    } else if (event.key === "Escape") {
      setOpen(false);
    }
  }

  // return focus to the button when we transitioned from !open -> open
  const prevOpen = React.useRef(open);
  React.useEffect(() => {
    if (prevOpen.current === true && open === false) {
      anchorRef.current.focus();
    }

    prevOpen.current = open;
  }, [open]);
  return (
    <div>
      <Stack direction="row" spacing={2}>
        <div>
          <Button
            ref={anchorRef}
            id="composition-button"
            aria-controls={open ? "composition-menu" : undefined}
            aria-expanded={open ? "true" : undefined}
            aria-haspopup="true"
            onClick={handleToggle}
            className={`${classes.root} attend-button `}
          >
            SHARE
          </Button>
          <Popper
            open={open}
            anchorEl={anchorRef.current}
            role={undefined}
            placement={isTab ? "top-start" : "bottom-start"}
            transition
            disablePortal
            sx={{ width: "15rem" }}
          >
            {({ TransitionProps, placement }) => (
              <Grow
                {...TransitionProps}
                style={{
                  transformOrigin:
                    placement === "bottom-start" ? "left top" : "left bottom",
                }}
              >
                <Paper sx={{ borderRadius: "15px" }}>
                  <ClickAwayListener onClickAway={handleClose}>
                    <MenuList
                      autoFocusItem={open}
                      id="composition-menu"
                      aria-labelledby="composition-button"
                      onKeyDown={handleListKeyDown}
                    >
                      {props.userData &&
                        props.userData.network !== "clients" && (
                          <MenuItem onClick={handleClose}>
                            <div
                              className="d-flex menu-list-content align-items-center"
                              onClick={() => {
                                navigator.clipboard.writeText(window.location);
                                toast.success("Link copied to Clipboard");
                                document
                                  .getElementById("chat-container-header")
                                  .click();
                              }}
                            >
                              <BiMessageDetail size={25} className="mr-13px" />
                              <p>In a Message</p>
                            </div>
                          </MenuItem>
                        )}
                      <MenuItem onClick={handleClose}>
                        <div
                          className="d-flex menu-list-content align-items-center"
                          onClick={() => {
                            navigator.clipboard.writeText(window.location);
                            toast.success("Link copied to Clipboard");
                          }}
                        >
                          <FiLink size={25} className="mr-13px mr-3" />
                          <p>Copy Link</p>
                        </div>
                      </MenuItem>
                    </MenuList>
                  </ClickAwayListener>
                </Paper>
              </Grow>
            )}
          </Popper>
        </div>
      </Stack>
    </div>
  );
};

export default ShareMenu;
