import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";
import * as Utility from "utils/utility";
import { Form } from "react-bootstrap";
import { InputField } from "../../../../shared/components/form_elements";
import HomeContainer from "../../../../shared/components/home_container";
import { GET_USERS_BY_NETWORKS } from "../../../../config/constants/api_endpoints";
import { CLIENT_ID } from "../../../../config/constants/config";
import MyCommunity from "../../../../shared/components/my_community";
import { GET_FOLLOWERS_FOLLOWING } from "../../../../config/constants/api_endpoints";
import { ChevronLeftIcon } from "../../../../shared/icons/icons";
import { showLoader } from "../../../../shared/actions";
import { connect } from "react-redux";
import { Spinner } from "react-bootstrap";
import { GIL_CLIENT_ID, ROLE_IDS } from "../../../../config/constants";

function DiscussionCommunities({search, setCommunitiesData}, ...props) {
  const userData = useSelector((state) => state.userData);
  const [users, setUsers] = useState([]);
  const [neighbourhoods, setNeighbourhoods] = useState([]);
  const [isApiResponse, setIsApiResponse] = useState(false);

  useEffect(() => {
    getUserDetails();
  }, [search]);

  const getUserDetails = () => {
    const params = {
      client_id: CLIENT_ID,
      type: "all",
      forCommunity: true,
    };

    if (search) params.search = search;

    showLoader(true);
    Utility.sendRequest(GET_USERS_BY_NETWORKS, 1, params, (err, res, body) => {
      body = JSON.parse(body);

      setIsApiResponse(true);
      showLoader(false);
      if (body.error) {
        toast.error(body.error.message, { containerId: "private" });
      } else {
        if (
          body.registrants &&
          CLIENT_ID === "0bc8dc7c-b5a3-4344-b313-819fc640f6b5"
        ) {
          const userList = [];
          const neighbourhood = [];
          body.registrants.sort(compare).forEach((element) => {
            if (element.network === "users") userList.push(element);
            else neighbourhood.push(element);
          });
          setUsers(userList);
          setNeighbourhoods(neighbourhood);
        } else {
          if (body.registrants) setUsers(body.registrants);
        }
      }
    });
  };

  function compare(a, b) {
    if (a.user_name < b.user_name) {
      return -1;
    }
    if (a.user_name > b.user_name) {
      return 1;
    }
    return 0;
  }

  const setDetailsFollowerfollowing = (followingId) => {
    const { id } = userData;
    if (id !== followingId) {
      const params = {
        followerId: id,
        followingId: followingId,
        clientId: CLIENT_ID,
      };

      Utility.sendRequest(
        GET_FOLLOWERS_FOLLOWING,
        2,
        params,
        (err, res, body) => {
          body = JSON.parse(body);
          if (body.error) {
            toast.error(body.error.message, { containerId: "private" });
          } else {
            getUserDetails();
          }
        }
      );
    }
  };

  return (
    <>
        {/* <div>
          {CLIENT_ID === GIL_CLIENT_ID && userData.role_id === ROLE_IDS.USER ? (
            ""
          ) : (
            <Form className="shar-form search-form search-form-pwa sidebar-form">
              <Form.Group className="mb-0">
                <InputField
                  required={true}
                  type="text"
                  placeholder="Search Community"
                  handleChange={(ev) => handleSearch(ev)}
                />
              </Form.Group>
            </Form>
          )}
          {Utility.isEmpty(users) && isApiResponse && (
            <div className="d-flex align-items-center justify-content-center mt-5">
              <div className="">
                <span className="font-bold display-6">No record found</span>
              </div>
            </div>
          )}
        </div> */}
        {
          <div className="dashboard-page-container gil-dashboard">
            <div className="community-section">
              {/* <>
                {CLIENT_ID === "0bc8dc7c-b5a3-4344-b313-819fc640f6b5" && (
                  <>
                    <div className="mb-2">
                      <span className="font-bold display-6">
                        Neighbourhoods
                      </span>
                    </div>
                    <div className="d-flex flex-lg-wrap community-section-inner slider-mob-inner mb-42">
                      {!Utility.isEmpty(neighbourhoods) ? (
                        neighbourhoods.map((user) => {
                          return (
                            <MyCommunity
                              key={user.id}
                              item={user}
                              handleOnClick={setDetailsFollowerfollowing}
                              showCharFollowButtons={true}
                              fromSeeAll={Utility.pwaModeEnabled()}
                              closeCallout={props.closeCallout}
                            />
                          );
                        })
                      ) : (
                        <Spinner animation="border" size="sm" role="status" />
                      )}
                    </div>
                    <div className="mb-2">
                      <span className="font-bold display-6">Citizens</span>
                    </div>
                  </>
                )}
              </> */}
              <div className="d-flex flex-lg-wrap community-section-inner slider-mob-inner mb-42 mt-4 min-h-full">
                {!Utility.isEmpty(users) ? (
                  users.map((user) => {
                    return (
                      <MyCommunity
                        key={user.id}
                        item={user}
                        handleOnClick={setDetailsFollowerfollowing}
                        showCharFollowButtons={true}
                        fromSeeAll={Utility.pwaModeEnabled()}
                        closeCallout={props.closeCallout}
                      />
                    );
                  })
                ) : (
                  <Spinner animation="border" size="sm" role="status" />
                )}
              </div>
            </div>
          </div>
        }
    </>
  );
}

const mapActionToProps = { showLoader };

const mapStateToProps = () => ({});

export default connect(mapStateToProps, mapActionToProps)(DiscussionCommunities);
