import React from "react";
import { AiOutlineArrowLeft } from "react-icons/ai";
import { withRouter } from "react-router-dom";
import { CoursesIcon, NewsIcon } from "../../shared/icons/icons";
import CourseContainer from "../campaigns/courses";

const CoursePage = (props) => {
  return (
    <>
      <div className={`CreateArticle-page-sidebar-head z-index-2 align-items-center`}>
        <AiOutlineArrowLeft
          style={{ width: "20px", height: "20px" }}
          className="CourseHeaderIcon ml-3 mr-2 cursor-pointer"
          onClick={() => props.history.goBack()}
          // onClick={() => props.history.goBack()}
        />
        <CoursesIcon h={25} w={25} />

        <span className="font-bold display-8 ml-2">{"Create a Course"}</span>
      </div>
      <div className="Create-course-page-container">
        <CourseContainer calledFrom="User"/>
      </div>
    </>
  );
};

export default withRouter(CoursePage);
