import React, { useState, useEffect } from "react";
import Topics from "../../users/components/home/topics";
import CourseCard from "../../profile/components/CourseCard";
import NewsCard from "../../profile/components/NewsCard";
import EventCard from "../../profile/components/EventCard";
import MyCommunity from "shared/components/my_community";
import { useSelector } from "react-redux";
import { CLIENT_ID } from "config/constants/config";
import { GET_FOLLOWERS_FOLLOWING } from "config/constants/api_endpoints";
import * as Utility from "utils/utility";
import { toast } from "react-toastify";
import Discussions from "./discussions";
import ControlPointIcon from "@mui/icons-material/ControlPoint";
import { GrSearch } from "react-icons/gr";
import { updateCampaignFormOpen } from "shared/actions";
import { useDispatch } from "react-redux";

export const RightPanel = (props) => {
  const { openCreate, setOpenCreate } = props;
  const userData = useSelector((state) => state.userData);
  const [topicsFilter, setTopicsFilter] = useState([]);
  const [search, setSearch] = useState("");
  let debouncedFn = null;

  const dispatch = useDispatch();

  useEffect(() => {
    setTopicsFilter([]);
  }, [props.panel]);

  const handleArticleClick = (data) => {
    props.history.push({
      pathname: `/campaign/${data.parent_id || data.id}`,
      calledFrom: props.type,
      goBackLink: goBackLink(),
    });
  };
  const goBackLink = () => {
    if (props.type === "suggestedReads") {
      return "/home";
    } else if (props.type === "myArticles") return "/profile";
  };

  const handleSearch = (ev) => {
    ev.persist();
    if (!debouncedFn) {
      debouncedFn = Utility.debounce(() => {
        const searchString = ev.target.value;
        setSearch(searchString);
      }, 500);
    }
    debouncedFn();
  };

  const setDetailsFollowerfollowing = (followingId) => {
    const { id } = userData;
    if (id !== followingId) {
      const params = {
        followerId: id,
        followingId: followingId,
        clientId: CLIENT_ID,
      };

      Utility.sendRequest(
        GET_FOLLOWERS_FOLLOWING,
        2,
        params,
        (err, res, body) => {
          body = JSON.parse(body);
          if (body.error) {
            toast.error(body.error.message, { containerId: "private" });
          }
        }
      );
    }
  };
  // console.log("Topics", topicsFilter);
  return (
    <div
      className="border-left border-top p-2"
      style={{ width: props.title === "Discussions" ? "78%" : "100%" }}
    >
      {props.title !== "Discussions" && (
        <div className="d-flex flex-row justify-content-between align-items-center  px-3 py-3">
          <h1 className="font-24-bold">{props.title}</h1>

          <span className="d-flex flex-row align-items-center  flex-nowrap w-25">
            <div className="SeactionSearchContainer w-100">
              <div className="searchSectionWrapper bg-white">
                <input
                  className="SeactionSearch"
                  placeholder={`Search ${props.title}`}
                  onChange={(ev) => {
                    handleSearch(ev);
                  }}
                />

                <span className="SeactionSearchIcon">
                  <GrSearch className="regionIcon" />
                </span>
              </div>
            </div>
            {props.title !== "Community" && (
              <ControlPointIcon
                onClick={() => {
                  if (props.title === "Courses") {
                    dispatch(updateCampaignFormOpen(true));
                    props.setSelectedItem("course");
                  }
                }}
                className={props.title === "Courses" && "cursor-pointer"}
              />
            )}
          </span>
        </div>
      )}

      {props.title !== "Discussions" && props.title !== "Community" && (
        <div className="px-3">
          <Topics
            isRegion={true}
            setTopicsFilter={setTopicsFilter}
            topicsFilter={topicsFilter}
          />
        </div>
      )}
      {props.title === "Discussions" && (
        <div className="m-3 w-100">
          <Discussions
            data={props.discussionsData}
            history={props.history}
            setSelectedIcon={props.setSelectedIcon}
            selectedIcon={props.selectedIcon}
            openCreate={openCreate}
            setOpenCreate={setOpenCreate}
            location={props.location || localStorage.getItem("region") || ""}
            region={props.region || localStorage.getItem("country") || ""}
          />
        </div>
      )}
      <div className="d-flex flex-wrap">
        {props.title === "Courses" &&
          props.data
            .filter((item) =>
              search.length
                ? item.heading.toLowerCase().includes(search.toLowerCase())
                : item
            )
            .filter((item) =>
              topicsFilter.length
                ? topicsFilter.includes(item.campaign_category)
                : item
            )
            .map((item) => (
              <div className="m-3" key={item.id}>
                <CourseCard data={item} key={item.id} />
              </div>
            ))}
      </div>

      <div className="d-flex flex-wrap">
        {props.title === "News" &&
          props.data
            .filter((item) =>
              search.length
                ? item.heading.toLowerCase().includes(search.toLowerCase())
                : item
            )
            .filter((item) =>
              topicsFilter.length
                ? topicsFilter.includes(item.campaign_category)
                : item
            )
            .map((data, index) => (
              <div className="m-3" key={data.id}>
                <NewsCard
                  onClick={() => handleArticleClick(data)}
                  key={data.id}
                  data={data}
                  Totalcourses={props.feeds}
                  index={index}
                  isVertical={true}
                />
              </div>
            ))}
      </div>

      <div className="d-flex flex-wrap">
        {props.title === "Events" &&
          props.data
            .filter((item) =>
              search.length
                ? item.heading.toLowerCase().includes(search.toLowerCase())
                : item
            )
            .filter((item) =>
              topicsFilter.length
                ? topicsFilter.includes(item.campaign_category)
                : item
            )
            .map((data) => (
              <div className="m-3" key={data.id}>
                <EventCard key={data.id} items={data} />
              </div>
            ))}
      </div>
      {props.title === "Community" && (
        <span className="font-bold display-6 ml-3">Neighbourhoods</span>
      )}
      <div className="d-flex flex-wrap">
        {props.title === "Community" &&
          props.neighbourhoods
            .filter((item) =>
              search.length
                ? item.user_name.toLowerCase().includes(search.toLowerCase())
                : item
            )
            .map((user) => (
              <div className="m-3" key={user.id}>
                <MyCommunity
                  key={user.id}
                  item={user}
                  handleOnClick={setDetailsFollowerfollowing}
                  showCharFollowButtons={true}
                  fromSeeAll={Utility.pwaModeEnabled()}
                  closeCallout={props.closeCallout}
                />
              </div>
            ))}
      </div>
      {props.title === "Community" && (
        <span className="font-bold display-6 ml-3">Citizens</span>
      )}
      <div className="d-flex flex-wrap">
        {props.title === "Community" &&
          props.data
            .filter((item) =>
              search.length
                ? item.user_name.toLowerCase().includes(search.toLowerCase())
                : item
            )
            .map((user) => (
              <div className="m-3" key={user.id}>
                <MyCommunity
                  key={user.id}
                  item={user}
                  handleOnClick={setDetailsFollowerfollowing}
                  showCharFollowButtons={true}
                  fromSeeAll={Utility.pwaModeEnabled()}
                  closeCallout={props.closeCallout}
                />
              </div>
            ))}
      </div>
    </div>
  );
};
