import React, { useEffect } from "react";
import { connect, useSelector } from "react-redux";
import { showLoader, updateSeeAll } from "shared/actions";
import NewUploadFile from "shared/components/NewFileUpload";
import { Utility } from "utils";
import { HollowAvatar, MoreDropWhite, NewsIcon } from "../icons/icons";
import { DropDown, InputField, Textarea } from "./form_elements";
import { useState } from "react";
import FloatEditor from "./FloatEditor";
import {
  ACCOUNTS,
  CAMPAIGNS,
  CAMPAIGN_DETAILS,
  FEATURED_COMMUNITY,
  GET_ACCOUNTS,
  GET_USERS_BY_NETWORKS,
  GET_CLIENTS_SUMMARY,
  MESSAGE_IMAGE_URL,
} from "../../config/constants/api_endpoints";
import { toast } from "react-toastify";
import { CLIENT_ID, COMMUNITY_CARD_LIMIT } from "config/constants/config";
import {
  CAMPAIGN_TYPES_IDS,
  GIL_CLIENT_ID,
  ROLE_IDS,
} from "../../config/constants";
import News_confirmation from "./v2/news_confirmation";
import { AiOutlineArrowLeft } from "react-icons/ai";
import { Autocomplete, Menu, MenuItem, TextField } from "@mui/material";
import { Spinner } from "react-bootstrap";

const NewsCreationForm = (props) => {
  const [headline, setHeadline] = useState("");
  const [ranking, setRanking] = useState(null);
  const [description, setDescription] = useState("");
  const [Articlebanner, setArticlebanner] = useState("");
  const [createrId, setCreaterId] = useState([]);
  const [menuRender, setMenuRender] = useState(null);
  const [errors, setErrors] = useState({
    image: "",
    headline: "",
    about: "",
    body: "",
    community: "",
  });

  const [errorBody, setErrorBody] = useState("");
  const [bodyData, setbodyData] = useState("");
  const [defaultData, setDefaultData] = useState("");
  const [joinedNeignBourhood, setJoinedNeighbourhood] = useState([]);
  const [imageFile, setImageFile] = useState("");
  const [signedUrl, setSignedUrl] = useState("");
  const [success, setSuccess] = useState(false);
  const [newsData, setNewsData] = useState([]);
  const [loading, setLoading] = useState(false);
  const setError = (value, name) => {
    var error = { ...errors };
    errors[name] = value;
    setErrors(error);
  };

  const validate = () => {
    let isError = true;
    console.log(
      createrId &&
        createrId.length <= 0 &&
        [ROLE_IDS.CLIENT_ADMIN, ROLE_IDS.CLIENT_OWNER, ROLE_IDS.USER].includes(
          props.userData.role_id
        )
    );
    if (
      createrId &&
      createrId.length <= 0 &&
      [ROLE_IDS.CLIENT_ADMIN, ROLE_IDS.CLIENT_OWNER, ROLE_IDS.USER].includes(
        props.userData.role_id
      )
    ) {
      if (props.campId) {
        var error = { ...errors };
        error["community"] = "Please enter a community";
        setErrors(error);
      } else {
        setError("Please enter a community", "community");
      }
      isError = false;
    }
    if (!headline) {
      setError("Please enter a heading of article", "headline");
      isError = false;
    }
    if (!Articlebanner) {
      setError("Please enter an image of article", "image");
      isError = false;
    }
    if (!description) {
      setError("Please enter some description of article", "about");
      isError = false;
    }
    if (!bodyData && !defaultData) {
      setError("", "body");
      setErrorBody("Please enter body of article");
      isError = false;
    }

    return isError;
  };

  const handleFileChange = (inputId, previewURL, file, name) => {
    errors["image"] = "";
    setArticlebanner(previewURL);
    setImageFile(file);
  };

  const createParams = (campaignTypeId, Corp_ID, method) => {
    const params = {};
    const authorData = props.userData;
    if (method === "POST") {
      params.author_name = authorData.user_name;
      params.author_email = authorData.email;
      params.author_id = authorData.user_id;
      params.created_by = authorData.user_id;
    }
    params.heading = headline;
    params.sub_heading = description;
    if (Corp_ID) {
      params.corporate_id = Corp_ID;
    }
    params.description = description;
    params.description_1 = bodyData ? bodyData : defaultData;
    params.user_client_id = authorData.id;
    params.client_id = CLIENT_ID;
    params.campaign_type_id = CAMPAIGN_TYPES_IDS.story;
    params.plain_description = description;
    params.updated_by = authorData.user_id;
    params.schedule_start = "";
    params.publisher_network_id = CLIENT_ID;
    params.location = "";
    params.video_url = "";
    params.embedded_video_link = "";
    params.image_url = "";
    params.document_url = "";
    params.embedded_image_link = "";
    params.imageSignedURL = "";
    params.videoSignedURL = "";
    params.documentSignedURL = "";
    params.parent_image_url = "";
    params.parent_heading = "";
    params.category_id = "dc3a01b7-eacd-465a-9dc4-61121llbzzeb";
    params.ranking = ranking;
    params.parent_id = null;
    params.non_profits_id = null;
    params.meeting_url = "";
    params.schedule_start = "";
    params.organizers = [];
    params.is_public = true;
    params.groups = [];
    if (method === "PUT") delete params.publisher_network_id;

    return params;
  };
  const accounts = useSelector((state) => state.accountList);
  const uploadFiles = async (id, imageSignedURL) => {
    let imageURL = Articlebanner;

    if (imageFile !== "") {
      imageURL = await Utility.uploadFile(imageFile, imageSignedURL);
    }

    updateCampaignMediaUrl(id, imageURL, "", imageSignedURL);
  };

  const functionstory = (menus, selectedMenuItemId) => {
    for (var i = 0; i < menus.length; i++) {
      if (selectedMenuItemId === menus[i].id) {
        return menus[i];
      }
    }
  };

  const updateCampaignMediaUrl = (campId, imageURL, videoURL = "") => {
    const params = {
      image_url: imageURL,
      video_url: videoURL,
    };

    Utility.sendRequest(
      CAMPAIGNS + "/" + campId,
      3,
      params,
      (err, res, body) => {
        body = JSON.parse(body);
        props.showLoader(false);
        if (body.error) {
          toast.error(body.error.message, { containerId: "private" });
        } else {
          if (props.isNotSideBar) {
            props.runGetCall(CAMPAIGN_TYPES_IDS.story);

            props.handleMenuItemClick(
              functionstory(props.menus, props.selectedMenuItemId)
            );
            props.closeCallout();
          }
          setNewsData(body);
          setSuccess(true);
        }
      }
    );
  };

  useEffect(() => {
    if (bodyData && errors.body !== "") {
      errors["body"] = "";
    }
    setErrorBody("");
  }, [bodyData]);

  const createCampaign = (params, camp_id, index) => {
    // props.showLoader(true);
    setLoading(true);
    Utility.sendRequest(CAMPAIGNS, 2, params, async (err, res, body) => {
      body = JSON.parse(body);
      if (body.error) {
        toast.error(body.error.message, { containerId: "private" });
      } else {
        const campData = body;
        if (!Utility.isEmpty(Articlebanner)) {
          await uploadFiles(campData.id, campData.imageSignedURL);
        } else {
          if (props.isFromEdmin) {
            props.runGetCall(CAMPAIGN_TYPES_IDS.story);
            props.handleMenuItemClick(
              functionstory(props.menus, props.selectedMenuItemId)
            );
            props.closeCallout();
          }
          setSuccess(true);
          setNewsData(body);
        }

        if (!index || index === createrId.length - 1) {
          toast.success(body.message, { containerId: "private" });
        }
      }
      if (!index || index === createrId.length - 1) {
        // props.showLoader(false);
        setLoading(false);
      }
    });
  };

  const UpdateCampaign = (params, camp_id, index) => {
    // props.showLoader(true);
    setLoading(true);
    Utility.sendRequest(
      `${CAMPAIGNS}/${props.campId}`,
      3,
      params,
      async (err, res, body) => {
        body = JSON.parse(body);
        if (body.error) {
          toast.error(body.error.message, { containerId: "private" });
        } else {
          const campData = body;
          if (!Utility.isEmpty(Articlebanner)) {
            Utility.sendRequest(
              MESSAGE_IMAGE_URL,
              1,
              {},
              async (error, response, body) => {
                body = JSON.parse(body);
                let newType;
                if (body.error) {
                  console.log(body.error);
                } else {
                  await uploadFiles(campData.id, body.path);
                }
              }
            );
          } else {
            if (props.isFromEdmin) {
              props.runGetCall(CAMPAIGN_TYPES_IDS.story);
              props.handleMenuItemClick(
                functionstory(props.menus, props.selectedMenuItemId)
              );
              props.closeCallout();
            }
            setSuccess(true);
            setNewsData(body);
          }

          if (!index || index === createrId.length - 1) {
            toast.success(body.message, { containerId: "private" });
          }
        }
        if (!index || index === createrId.length - 1) {
          // props.showLoader(false);
          setLoading(false);
        }
      }
    );
  };

  const handleClick = (event) => {
    event.preventDefault();
    setMenuRender(event.currentTarget);
  };

  useEffect(() => {
    let response = [];
    if (
      props.userData &&
      props.userData.role_id !== ROLE_IDS.CLIENT_ADMIN &&
      props.userData.role_id !== ROLE_IDS.CLIENT_OWNER
    ) {
      if (accounts && accounts.Clients && accounts.Clients.length > 0) {
        const allAccounts = [...accounts.Clients];

        const tempBody = allAccounts.filter(
          (value, index, self) =>
            index === self.findIndex((t) => t.id === value.id)
        );
        response = [
          ...tempBody.map((items) => {
            if (items.client_id === props.userData.client_id) {
              setCreaterId([...createrId, items.client_id]);
            }
            return {
              name: items.user_name,
              value: items.client_id ? items.client_id : items.id,
            };
          }),
        ];
        setJoinedNeighbourhood(response);
      }
      if (
        props.userData &&
        (props.userData.role_id === ROLE_IDS.PARENT_ADMIN ||
          props.userData.role_id === ROLE_IDS.PARENT_OWNER)
      ) {
        const params = {
          client_id: GIL_CLIENT_ID,
          type: "clients",
        };
        Utility.sendRequest(
          GET_CLIENTS_SUMMARY,
          1,
          params,
          async (error, response, body) => {
            body = JSON.parse(body);
            response = body.clients.map((data) => {
              return {
                name: data.user_name,
                value: data.client_id,
              };
            });
            setJoinedNeighbourhood([...joinedNeignBourhood, ...response]);
          }
        );
      }
    } else {
      if (props.userData) {
        setCreaterId(props.userData.client_id);
        setJoinedNeighbourhood([
          { name: props.userData.user_name, value: props.userData.client_id },
        ]);
      }
    }
  }, [props.userData, accounts]);

  const getCampaignDetails = (campId) => {
    props.showLoader(true);
    const params = {
      user_client_id: props.userData.id,
      campaign_id: campId,
      publisher_network_id: CLIENT_ID,
    };

    Utility.sendRequest(CAMPAIGN_DETAILS, 1, params, (err, res, body) => {
      body = JSON.parse(body);

      if (body.error) {
        toast.error(body.error.message, { containerId: "private" });
      } else {
        if (body.data && body.data.length > 0) {
          const campaigns_data = body.data.filter(
            (item) => item.campaign_type_id !== CAMPAIGN_TYPES_IDS.section
          );
          if (campaigns_data && campaigns_data.length > 0) {
            setHeadline(campaigns_data[0].heading);
            setDescription(campaigns_data[0].description);
            setArticlebanner(campaigns_data[0].image_url);
            setDefaultData(campaigns_data[0].description_1);
            setRanking(campaigns_data[0].ranking);
          }
        }
      }

      props.showLoader(false);
    });
  };
  useEffect(() => {
    if (props.isUpdate) {
      getCampaignDetails(props.campId);
    }
  }, []);

  const submit = (e) => {
    e.preventDefault();
    if (validate()) {
      if (createrId && createrId.length > 0) {
        if (!props.isUpdate) {
          const creater_ids = Array.isArray(createrId)
            ? [...createrId]
            : [createrId];
          creater_ids.map((data, index) => {
            const params = createParams(CAMPAIGN_TYPES_IDS.post, data, "POST");
            if (params) {
              createCampaign(params, CAMPAIGN_TYPES_IDS.post, index);
            }
          });
        } else {
          if (typeof createrId === "string") {
            const params = createParams(
              CAMPAIGN_TYPES_IDS.post,
              createrId,
              "PUT"
            );
            if (params) {
              UpdateCampaign(params, CAMPAIGN_TYPES_IDS.post);
            }
          } else {
            createrId.map((data, index) => {
              const params = createParams(CAMPAIGN_TYPES_IDS.post, data, "PUT");
              if (params) {
                UpdateCampaign(params, CAMPAIGN_TYPES_IDS.post, index);
              }
            });
          }
        }
      } else {
        if (!props.isUpdate) {
          const params = createParams(CAMPAIGN_TYPES_IDS.post, "", "POST");
          if (params) {
            createCampaign(params, CAMPAIGN_TYPES_IDS.post);
          }
        } else {
          const params = createParams(CAMPAIGN_TYPES_IDS.post, "", "PUT");
          if (params) {
            UpdateCampaign(params, CAMPAIGN_TYPES_IDS.post);
          }
        }
      }
    }
  };
  return success ? (
    newsData && <News_confirmation item={newsData} />
  ) : (
    <>
      <div
        className={`${
          props.isNotSideBar && " position-fixed left-0 "
        } CreateArticle-page-sidebar-head align-items-center`}
      >
        <AiOutlineArrowLeft
          style={{ width: "20px", height: "20px" }}
          className="CourseHeaderIcon ml-3 mr-2 cursor-pointer"
          onClick={() => {
            props.isNotSideBar
              ? props.changeDisCard()
              : props.updateSeeAll({
                  isOpen: "newsDiscard",
                  calledFrom: "newsDiscard",
                }) && props.history.goBack();
          }}
          // onClick={() => props.history.goBack()}
        />
        <NewsIcon h={25} w={25} />

        <span className="font-bold display-8 ml-2">
          {props.isUpdate ? "Update a News Article" : "Create a News Article"}
        </span>
        <div className="ml-auto cursor-pointer">
          {props.isUpdate ? (
            <button
              onClick={submit}
              className="btn btn-primary CourseHeaderBtn"
            >
              {loading ? <Spinner animation="border" role="status"/> : "Update"}

            </button>
          ) : (
            <button
              onClick={submit}
              className="btn btn-primary CourseHeaderBtn"
            >
              {loading ? <Spinner animation="border" role="status"/> : "Post"
            }
            </button>
          )}
        </div>
      </div>
      <div className="d-flex flex-column creator-form-article">
        <div className="mt-3">
          <p className="header-padding">Upload Content</p>
          <div
            className={`${
              props.isNotSideBar && "mediaflex"
            } d-flex createNewsForm`}
          >
            <div
              className={`${
                props.isNotSideBar && "custom-input-image-wrapper"
              } position-relative d-flex justify-content-center rounded-md bg-black news-image-cls`}
            >
              {!Utility.isEmpty(Articlebanner) ? (
                <>
                  <img
                    src={Articlebanner}
                    className={`${
                      props.isNotSideBar && "custom-article-banner-container "
                    } article-banner-container object-fit-contain`}
                  />
                  <div
                    onClick={(e) => handleClick(e)}
                    className="remove-profile-banner upload-menu-background  cursor-pointer"
                  >
                    <MoreDropWhite />
                  </div>
                  <Menu
                    id="basic-menu"
                    anchorEl={menuRender}
                    open={Boolean(menuRender)}
                    onClose={() => setMenuRender(null)}
                    MenuListProps={{
                      "aria-labelledby": "basic-button",
                    }}
                  >
                    <MenuItem>
                      <NewUploadFile
                        name="banner_image_url"
                        inputId="image_url"
                        purposeFor="change"
                        removeText="Remove image"
                        categoryName="user_banner_image"
                        acceptTypes="image/x-png,image/gif,image/jpeg"
                        uploadedFilePreviewUrl={Articlebanner}
                        handleFileChange={handleFileChange}
                        removeUploadedFile={(inputId, name, e) => {
                          e.preventDefault();
                          setMenuRender(null);
                          setArticlebanner("");
                        }}
                      />
                    </MenuItem>
                    <MenuItem
                      className="p-2 justify-content-center"
                      onClick={(e) => {
                        e.preventDefault();
                        setMenuRender(null);
                        setArticlebanner("");
                      }}
                    >
                      Remove Image
                    </MenuItem>
                  </Menu>
                </>
              ) : (
                <div
                  className={`${
                    props.isNotSideBar && "custom-input-image"
                  } Input-ArticleCreation pt-3 pl-3 w-100`}
                >
                  <div
                    className={`${
                      props.isNotSideBar && " position-relative w-100 "
                    }  d-flex w-fit flex-column cursor-pointer align-items-center justify-content-center`}
                  >
                    <NewUploadFile
                      name="banner_image_url"
                      inputId="image_url"
                      isImportant={true}
                      removeText="Remove image"
                      categoryName="user_banner_image"
                      uploadedFilePreviewUrl={Articlebanner}
                      handleFileChange={handleFileChange}
                      acceptTypes="image/x-png,image/gif,image/jpeg"
                    />
                  </div>
                </div>
              )}
              {errors.image && <span className="error">{errors.image}</span>}
            </div>
            <div className="HeaderFormContainer">
              <div className="posted-by-container">
                <Autocomplete
                  multiple
                  disableCloseOnSelect
                  id="joinedNeighbourhood"
                  options={joinedNeignBourhood}
                  value={
                    joinedNeignBourhood &&
                    joinedNeignBourhood.filter(
                      (data) => createrId && createrId.includes(data.value)
                    )
                  }
                  getOptionLabel={(option) => option.name}
                  onChange={(event, newValue) => {
                    errors["community"] = "";
                    setCreaterId([...newValue.map((item) => item.value)]);
                  }}
                  renderOption={(props, option) => {
                    return (
                      <li
                        className="community-option-container"
                        key={option.value}
                        {...props}
                      >
                        <input
                          className="community-option-container-checkbox"
                          type="checkbox"
                          checked={createrId.includes(option.value)}
                        />
                        <p className="community-option-container-text">
                          {option.name}
                        </p>
                      </li>
                    );
                  }}
                  name="joinedNeighbourhood"
                  className="field-common-styling ml-0 w-100"
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      className={`${
                        createrId &&
                        createrId.length > 0 &&
                        "autoComplete-textfield-styling-hideplaceHolder"
                      } autoComplete-textfield-styling`}
                      placeholder={
                        createrId.length > 0
                          ? ""
                          : `Select A Community${
                              [
                                ROLE_IDS.CLIENT_ADMIN,
                                ROLE_IDS.CLIENT_OWNER,
                                ROLE_IDS.USER,
                              ].includes(props.userData.role_id)
                                ? "*"
                                : ""
                            }`
                      }
                      variant="outlined"
                    />
                  )}
                />
                {errors.community && (
                  <span className="error">{errors.community}</span>
                )}
              </div>
              <div className="w-full mt-15">
                <InputField
                  required={true}
                  type="text"
                  className="field-common-styling w-100 ml-0"
                  name="article_headline *"
                  // ref="article_headline"
                  value={headline}
                  handleChange={(event) => {
                    errors["headline"] = "";
                    setHeadline(event.target.value);
                  }}
                  placeholder="Headline *"
                />
                {errors.headline && (
                  <span className="error">{errors.headline}</span>
                )}
              </div>
              <div className="w-full mt-15">
                <Textarea
                  required={true}
                  type="text"
                  value={description}
                  handleChange={(event) => {
                    errors["about"] = "";
                    setDescription(event.target.value);
                  }}
                  className="field-common-styling w-100 ml-0 field-common-text-area"
                  name="article_about"
                  // ref="article_about"
                  maxLength={250}
                  placeholder="About *"
                />
                {errors.about && <span className="error">{errors.about}</span>}
              </div>
            </div>
          </div>
        </div>
        <div className="w-100 editorClass ">
          <FloatEditor
            isEdit={props.campId ? true : false}
            defaultData={defaultData}
            setDescription={setbodyData}
          />
          {errorBody && <span className="error">{errorBody}</span>}
        </div>
      </div>
    </>
  );
};

const mapActionToProps = { showLoader, updateSeeAll };

const mapStateToProps = (state) => ({
  userData: state.userData,
});

export default connect(mapStateToProps, mapActionToProps)(NewsCreationForm);
