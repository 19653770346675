import React, { useState, useEffect } from 'react';
import { CAMPAIGN_TYPES_IDS } from 'config/constants';
import { ChevronUpIcon } from 'shared/icons/icons';
import SideCallout from 'shared/components/templates/side_callout';
import { USER_BADGES } from 'config/constants/api_endpoints';
import { Utility } from 'utils';
import { CLIENT_ID, BADGES_IDS } from 'config/constants/config';
import { useSelector } from 'react-redux';

export default function CreateFormSelection(props){
  const [userBadges, setUserBadges] = useState([]);
  const userData = useSelector(state => state.userData);
 
  useEffect(() => {
    getUserBadges();
  }, []);

  const handleCardClick = (card) => {
    props.handleArrowClick(card);
  };

  const haveBadge = (badgeId) => {
    let flag = false;
    userBadges.forEach(e => {
      if(e.badge_id === badgeId && e.have_badge)
        flag = true;
    });
    return flag;
  };

  const getUserBadges = () => {
    const params = {
      userClientId: userData.id,
      clientId: CLIENT_ID,
    };

    Utility.sendRequest(USER_BADGES, 1, params, (error, response, body) => {
      body = JSON.parse(body);

      if (!body.error) setUserBadges(body);
    });
  };

  return(
    <SideCallout
      position={props.position}
      closeCallOut={props.closeIsSelectionFormOpen}
      calloutHeading='What do you want to create?'
      calloutCloseIcon={
        <ChevronUpIcon />
      }
      innerPosition="create-card-pwa-outer" >
      <div className={`create-card-pwa`}>
        <p className="card-body m-0 text-center font-bold display-6"
          onClick={() => handleCardClick(CAMPAIGN_TYPES_IDS.story)}>
          <img
          className="mr-3"
          src={haveBadge(BADGES_IDS.CAMPAIGN) ?
              require('assets/images/profile/badges/gm-badge.svg') :
              require('assets/images/profile/badges/empty-badge.svg')}
          alt=""
          />
          Create Article
        </p>
      </div>
      <div className={`create-card-pwa`}>
        <p className="card-body m-0 text-center font-bold display-6"
          onClick={() => handleCardClick('group')}>
          <img
          className="mr-3"
          src={haveBadge(BADGES_IDS.GROUP) ?
              require('assets/images/profile/badges/gm-badge.svg') :
              require('assets/images/profile/badges/empty-badge.svg')}
          alt=""
          />
          Create Group
        </p>
      </div>
      <div className={`create-card-pwa`}>
        <p className="card-body m-0 text-center font-bold display-6"
          onClick={() => handleCardClick(CAMPAIGN_TYPES_IDS.event)}>
          <img
          className="mr-3"
          src={haveBadge(BADGES_IDS.EVENT) ?
              require('assets/images/profile/badges/gm-badge.svg') :
              require('assets/images/profile/badges/empty-badge.svg')}
          alt=""
          />
          Create Event
        </p>
      </div>
      <div className="pt-4 text-center mt-auto">
        <button
          className="btn btn-primary btn-block create-card-pwa-btn"
          onClick={props.closeIsSelectionFormOpen}>
          CANCEL
        </button>
      </div>
    </SideCallout>
  );
}
