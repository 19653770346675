import React from "react";

const DiscardChanges = (props) => {
  const functionevent = (menus, selectedMenuItemId) => {
    for (var i = 0; i < menus.length; i++) {
      if (selectedMenuItemId === menus[i].id) {
        return menus[i];
      }
    }
  };
  const functionstory = (menus, selectedMenuItemId2) => {
    for (var i = 0; i < menus.length; i++) {
      if (selectedMenuItemId2 === menus[i].id) {
        return menus[i];
      }
    }
  };

  const event = functionevent(props.menus, props.selectedMenuItemId);
  const story = functionstory(props.menus, props.selectedMenuItemId2);

  return (
    <div className="d-flex flex-column justify-content-center align-content-center">
      <h2 className="text-center lead mb-2">Discard Changes</h2>
      <p className="text-center lead">Are you sure you want </p>
      <p className="text-center lead">
        to discard the changes of your {props.heading}?{" "}
      </p>
      <div className="d-flex justify-content-center bg-white ">
        <div className="px-3">
          <button
            className="btn btn-default p-2 cancel_button "
            onClick={() =>
              props.isNotSideBar ? props.changeDisCard() : props.closeCallout()
            }
          >
            Cancel
          </button>
        </div>
        <button
          className="btn btn-primary p-2 cancel_button "
          onClick={() => {
            // console.log("working")
            // console.log(story)
            // !props.isNotSideBar && props.history.goBack()
            if (props.selectedMenuItemId2) {
              props.handleMenuItemClick(story);
              props.closeCallout();
            } else {
              props.handleMenuItemClick(event);
              props.closeCallout();
            }
          }}
        >
          Discard
        </button>
      </div>
    </div>
  );
};
export default DiscardChanges;
