import React, { useEffect } from "react";
import { useHistory, useLocation } from "react-router-dom";
import { AiOutlineHeart, AiTwotoneHeart } from "react-icons/ai";
import { EventIcon } from "../../../icons/icons";
import { Skeleton } from "@mui/material";

const EventsHeader = (props) => {
  const history = useHistory();
  const { pathname, state } = useLocation();
  useEffect(() => {
    if (state && state.form && !localStorage.getItem("RouteFrom")) {
      state.from.includes("/event-confirmation") &&
        localStorage.setItem("RouteFrom", "/event-confirmation");
    } else {
      localStorage.getItem("RouteFrom") === "/event-confirmation" &&
        localStorage.setItem("RouteFrom", "/event-confirmation");
    }
    return () => {
      localStorage.removeItem("RouteFrom");
    };
  }, []);
  return (
    <div className="bg-white w-full d-flex align-items-center justify-content-between backArrowRow">
      <div className="d-flex">
        <img
          src={require("assets/images/icons/back-arrow.svg")}
          className="ml-3 cursor-pointer"
          alt="back-arrow"
          onClick={() => {
            localStorage.getItem("RouteFrom") === "/event-confirmation"
              ? history.push("/events")
              : history.goBack();
          }}
        />
        <span className="mx-3 regionIcon">
          <EventIcon />
        </span>
        {/* <img
          src={require("assets/images/icons/greyCircle.svg")}
          className="ml-2 mr-2"
          alt="greyCircle"
        /> */}
        <p className="mt-auto mb-auto b-700">{props.eventDetails.heading}</p>
      </div>
      <div className="d-flex">
        {/* <img
          src={require("assets/images/icons/heart-black.svg")}
          className="mr-2"
          alt="heart-black"
        /> */}
        {props.isCampaignLikeLoading ? (
          <Skeleton variant="circular" width={40} height={40} />
        ) : props.isLiked ? (
          <AiTwotoneHeart
            onClick={() => props.getSentimentDetails()}
            className="width-30 mr-2 text-danger"
          />
        ) : (
          <AiOutlineHeart
            onClick={() => props.getSentimentDetails()}
            className="width-30 mr-2"
          />
        )}
        <p className="mr-3 mt-auto mb-auto">{props.eventDetails.likes_count}</p>
      </div>
    </div>
  );
};

export default EventsHeader;
