import React from "react";
import Box from "@mui/material/Box";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import CalendarTodayIcon from "@mui/icons-material/CalendarToday";
import { isEmpty } from "lodash";

export const DatePickerInput = ({
  InputProps,
  inputProps,
  inputRef,
  isMobile,
  setOpen,
  isEmpty,
}) => {
  const months = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ];

  return (
    <Box className="datePickerOuterBox">
      <Box className="datePickerInnerBox" onClick={() => setOpen(true)}>
        <>
          <span className="datePickerCalendarSpan">
            {InputProps.endAdornment}
            {isMobile && <CalendarTodayIcon className="calendarIcon" />}
          </span>
          <input
            ref={inputRef}
            {...inputProps}
            readOnly={true}
            className="datePickerInput"
            value={
              isEmpty
                ? "MM/DD/YYYY"
                : inputProps.value
                ? months[new Date(inputProps.value).getMonth()] +
                  " " +
                  new Date(inputProps.value).getDate() +
                  "th, " +
                  new Date(inputProps.value).getFullYear()
                : months[new Date().getMonth()] +
                  " " +
                  new Date().getDate() +
                  "th, " +
                  new Date().getFullYear()
            }
          />
          <KeyboardArrowDownIcon className="downArrowIcon" />
        </>
      </Box>
    </Box>
  );
};
