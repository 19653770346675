import React, { memo } from "react";
import useMediaQuery from '@mui/material/useMediaQuery';
import { CloseIcon } from "shared/icons/icons";
import { Utility } from "utils";
import { CLIENT_ID } from "config/constants/config";
import { GIL_CLIENT_ID } from "config/constants";
import classNames from "classnames";
const pwaModeEnabled = Utility.pwaModeEnabled();

let SideCallout = ({ isHeaderVisible = true, ...props }) => {
  const isTab = useMediaQuery('(max-width:820px)');
  return (
    <div
      className={
        CLIENT_ID === GIL_CLIENT_ID &&
          (props.isCourseAssignment || props.isProfilePage)
          ? `px-0 profile-position position-fixed bg-white w-100 h-100 ${!props.isProfilePage ? "assignmentPage" : "profilePopUpPage"
          }`
          : props.isCreate
            ? `position-fixed create-position
    bg-white createPage pl-2 pr-0`
            : `col-5 px-0 ${props.position ? props.position : "position-absolute "
            }
      bg-white page-sidebar-outer ${window.location.pathname.includes("/campaign/") && "on-course-page"
            } `
      }
    >
      <div className="pwa-callout-decorator-sec py-3 d-xl-none closeModalTab">
        <div onClick={props.closeCallOut} className="pwa-callout-decorator" />
      </div>
      <div
        className={`page-sidebar-inner pageSideBarTab h-100 overflow-auto scroll-y shar-form search-form-pwa sidebar-form search-form ${props.innerPosition} ${props.temperoryClass} ${isTab ? "commTabOuterSection" : ""}`}
      >
        {isHeaderVisible && (
          <div
            className={`page-sidebar-head border-bottom align-items-center page-sidebar-head-article ${props.isCreatePage && "bg-white"
              }`}
          >
            {props.calloutHeadingSvg}
            <img
              src={props.calloutHeadingImg}
              alt=""
              className="sidebar-header-icon"
            />
            <span className="font-bold display-8 ml-2">
              {props.calloutHeading}
            </span>
            <div
              className="ml-auto cursor-pointer"
              onClick={props.closeCallOut}
            >
              {pwaModeEnabled ? props.calloutCloseIcon : <CloseIcon />}
            </div>
          </div>
        )}
        <div
          className={classNames(
            "page-sidebar-body",
            props.customBodyClass,
            props.isProfilePage ? "p-0" : "",
            props.isCommunity && "mx-0 w-100"
          )}
        >
          {props.children}
        </div>
      </div>
    </div>
  );
};

export default SideCallout = memo(SideCallout);
