import React, { Component } from 'react';
import { Row, Col, Form, Button } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { InputField, Checkbox } from 'shared/components/form_elements';
import { Utility } from 'utils';
import Card from 'scenes/tools/toolbox/gift_cards/public/components/card';

const validateFields = ['first_name', 'last_name', 'email', 'is_terms_and_service_agreement'];

const genderList = [
  { id: 1, name: 'Male' },
  { id: 2, name: 'Female' }
];

export default class Audience  extends Component {
  constructor(props) {
    super(props);

    this.state = {
      formData: {
        first_name: '',
        last_name: '',
        zip_code: '',
        email: '',
        gender: 1,
        is_terms_and_service_agreement: false
      },
    };
  }

  handleChange = (e) => {
    let formData = this.state.formData;

    formData = Utility.validateAndReturnFormData(this, formData, e, validateFields);

    this.setState({ formData });
  }

  submit = (e) => {
    e.preventDefault();
    const formData = this.state.formData;

    if (!Utility.showFormErrors(this, validateFields)) return;

    this.props.saveAudience(formData);
  }

  render(){
    const formStyle = {
      border: `2px solid ${this.props.colorCode}`
    };

    return(
      <Row className="justify-content-center giftcard-outer-container2 mx-auto">
        <Col sm={6} >
          <Card
            colorCode={this.props.colorCode}
            selectedBrand={this.props.selectedBrand}
            selectedBeneficiary={this.props.selectedBeneficiary}
          />
        </Col>
        <Col sm={6} className="pl-5">
          <Link to={{}} onClick={() => this.props.backSteps()} className="font-bold d-block my-4 py-3 link-light-grey" >BACK</Link>
          <h2>Almost there!</h2>
          <p className="display-8" >Enter the information.</p>
          <Form className="audience-form">
            <Form.Group controlId="" className="mt-3">
              <InputField
                required={true}
                name="first_name"
                ref="first_name"
                type="text"
                handleChange={this.handleChange}
                placeholder="First Name"
                inlineStyle={formStyle}
              />
            </Form.Group>
            <Form.Group controlId="" className="mt-3">
              <InputField
                required={true}
                name="last_name"
                ref="last_name"
                type="text"
                handleChange={this.handleChange}
                placeholder="Last Name"
                inlineStyle={formStyle}
              />
            </Form.Group>
            <Form.Group controlId="" className="mt-3">
              <InputField
                required={true}
                name="zip_code"
                ref="zip_code"
                type="number"
                handleChange={this.handleChange}
                placeholder="Zip Code"
                inlineStyle={formStyle}
              />
            </Form.Group>
            <Form.Group controlId="" className="mt-3">
              <InputField
                required={true}
                name="email"
                ref="email"
                type="email"
                handleChange={this.handleChange}
                placeholder="Email"
                inlineStyle={formStyle}
              />
            </Form.Group>
            <Row className="font-italic-bold link-light-grey mt-2">
              {
                genderList.map((item, index) => {
                  return(
                    <div className="col-4 ml-4" key={ "gender" + index }>
                      <Form.Check
                        custom
                        checked={parseInt(this.state.formData.gender) === item.id}
                        onChange={(e) => this.handleChange(e)}
                        type="radio"
                        name="gender"
                        ref="gender"
                        id={item.id}
                        label={item.name}
                      />
                    </div>
                  );
                })
              }
            </Row>
            <Form.Group className="d-flex mt-3">
              <div key="is_terms_and_service_agreement">
                <Checkbox
                  ref="is_terms_and_service_agreement"
                  placeholder="Please select terms and policy"
                  checkboxId="is_terms_and_service_agreement"
                  name="is_terms_and_service_agreement"
                  label=""
                  handleChange={this.handleChange}
                />
              </div>
              <div className="checkbox-text-info">
                 <Form.Label htmlFor="is_terms_and_service_agreement">I agree to <Link to="/terms_of_services" target="_blank" className="text-url" style={{ color :this.props.colorCode }} >Terms of Service</Link> and <Link to="/privacy_policy" target="_blank" className="text-url" style={{ color :this.props.colorCode }}>Privacy Policy</Link></Form.Label>
                <div className="error errorIcon" id={"is_terms_and_service_agreementError"}/>
              </div>
            </Form.Group>
            <Button type="submit" variant="primary" style={{ backgroundColor :this.props.colorCode, borderColor: this.props.colorCode, borderRadius: '0' }} className="sm-btn" onClick={(e) => this.submit(e)}>Donate</Button>
          </Form>
        </Col>
      </Row>
    );
  }
}
