import React, { useEffect, useState } from "react";
import ChatLanding from "./Screens/ChatLanding";
import Chat from "./Screens/Chat";

const RightSection = (props) => {
  switch (props.activeScreenName) {
    case "Landing":
      return <ChatLanding {...props} />;
    case "Chat":
      return (
        <>
          <Chat {...props} />
        </>
      );
    default:
      return <>LANDING2</>;
  }
};

export default RightSection;
