import Avatar from "react-avatar";
import React, { useEffect, useState } from "react";
import { Button, Image, Spinner } from "react-bootstrap";
import { Utility } from "utils";
import { ROLE_IDS } from "config/constants";
import { useSelector } from "react-redux";
import { BiEditAlt } from "react-icons/bi";
import { CLIENT_ID } from "../../../config/constants/config";
import { toast } from "react-toastify";
import { store } from "../../../store";
import {
  CLIENTS,
  GET_FOLLOWERS_FOLLOWING,
  GET_WEA_LIST,
  USER_AUTH,
  ADD_USER_TO_NEIGHBORHOOD,
} from "../../../config/constants/api_endpoints";
import CustomModal from "../../../components/shared/customModal";
import { DropDown } from "../../../shared/components/form_elements";
import { updateUserData } from "../../../shared/actions";
import { useDispatch } from "react-redux";
import { getNeighborhoodsOfUser } from "../../../services/profile";

const UserProfileSection = ({
  bannerImage,
  profileImgUrl,
  userData,
  handleClickEdit,
  isPublic,
  handleFollow,
  HandleStartChat,
  setClickableState,
  setIsNeighborhoodJoined,
}) => {
  const User = useSelector((state) => state.userData);
  const [isJoined, setIsJoined] = useState(false);
  const [isFollowed, setIsFollowed] = useState(false);
  const { userNeighborhoods } = useSelector((state) => state);
  const userTileId = useSelector((state) => state.userData.user_id);
  const [showMessage, setShowMessage] = useState(false);
  const [open, setOpen] = React.useState(false);
  const [tag, setTag] = React.useState("");
  const [subtag, setSubTag] = React.useState("");
  const [Organization, setOrganization] = useState([]);
  const [Topics, setTopics] = useState([]);
  const [loader, setLoader] = useState(false);
  const [isJoiningNeighbourhood, setIsJoiningNeighbourhood] = useState(false);
  // const [Wea, setWea] = useState([]);

  const dispatch = useDispatch();

  const tagData = [
    {
      name: "Category",
      value: "",
    },
    {
      name: "Organization",
      value: "Organization",
    },
    {
      name: "Topics",
      value: "Topics",
    },
  ];

  const handleOpen = () => setOpen(true);
  const handleClose = () => {
    setOpen(false);
    setTag("");
    setSubTag("");
  };
  const joinNeighbourHood = async (
    neighbourhood_id,
    setIsJoineed,
    user_id,
    user_client_id
  ) => {
    const params = {
      client_id: CLIENT_ID,
      neighbourhood_id,
      user_id,
      user_client_id,
    };
    // store.dispatch(showLoader(true));
    setIsJoiningNeighbourhood(true);
    let result = null;
    await Utility.sendRequest(
      ADD_USER_TO_NEIGHBORHOOD,
      2,
      params,
      (err, res, body) => {
        body = JSON.parse(body);
        // store.dispatch(showLoader(false));
        setIsJoiningNeighbourhood(false);
        if (body.error) {
          toast.error(body.error.message, { containerId: "private" });
        } else {
          getNeighborhoodsOfUser(store.getState().userData);
          result = body;
          // store.dispatch(showLoader(false));
          setIsJoiningNeighbourhood(false);
          if (setIsJoineed) {
            setIsJoineed();
          }
          return body;
        }
      }
    );
  };
  const authUser = () => {
    Utility.sendRequest(
      USER_AUTH,
      1,
      localStorage.token,
      (error, response, body) => {
        body = JSON.parse(body);

        if (!body.error) {
          dispatch(updateUserData(body));
        } else {
          toast.error(body.error.message, { containerId: "public" });
        }
      }
    );
  };

  const getAllTag = (tag, setValue) => {
    Utility.sendRequest(
      GET_WEA_LIST,
      1,
      { tag_type: tag },
      (err, response, body) => {
        body = JSON.parse(body);
        if (!body.error) {
          body.data.unshift({ id: "", name: "Sub Category" });
          setValue(body.data);
        } else {
          toast.error(JSON.parse(body).error.message, {
            containerId: "private",
          });
        }
      }
    );
  };

  useEffect(() => {
    getAllTag("topic_tag", setTopics);
    getAllTag("organisation_tag", setOrganization);
  }, []);

  const tags = {
    Organization: Organization,
    Topics: Topics,
    // Wea: Wea,
  };

  useEffect(() => {
    handleFollow(isFollowed);
  }, [isFollowed]);

  const handleFollowFollowing = (
    followingId,
    followerId,
    clientId,
    isFollowed
  ) => {
    const params = { followerId, followingId, clientId };
    params["is_active"] = true;

    Utility.sendRequest(
      GET_FOLLOWERS_FOLLOWING,
      2,
      params,
      (err, res, body) => {
        body = JSON.parse(body);
        if (body.error) {
          toast.error(body.error.message, { containerId: "private" });
        } else {
          setIsFollowed(true);
        }
      }
    );
  };

  useEffect(() => {
    setIsFollowed(userData.is_followed);
    if (userData.role_id === ROLE_IDS.USER) {
      setShowMessage(true);
      setClickableState(true);
    }
  }, [userData]);

  useEffect(() => {
    if (userData.role_id === ROLE_IDS.CLIENT_OWNER) {
      setIsNeighborhoodJoined(showMessage);
    }
  }, [showMessage]);

  useEffect(() => {
    if (ROLE_IDS.USER !== userData.role_id) {
      if (
        userNeighborhoods &&
        userNeighborhoods.length > 0 &&
        userData.client_id &&
        (userData.role_id === ROLE_IDS.CLIENT_OWNER ||
          userData.role_id === ROLE_IDS.CLIENT_ADMIN)
      ) {
        if (
          userNeighborhoods.some(
            (data) => data.client_id === userData.client_id
          )
        ) {
          setShowMessage(true);
          setClickableState(true);
        } else {
          setShowMessage(false);
          setClickableState(false);
        }
      }
    }
  }, [userData, userNeighborhoods]);

  const updateClientDetails = () => {
    setLoader(true);
    const params = {
      sub_tag: subtag,
      tag: tag,
    };

    Utility.sendRequest(CLIENTS, 3, params, async (error, response, body) => {
      body = JSON.parse(body);
      if (!body.error) {
        toast.success(body.message, { containerId: "public" });
        authUser();
        handleClose();
        setLoader(false);
      } else {
        toast.error(body.error.message, { containerId: "public" });
        setLoader(false);
      }
    });
  };

  return (
    <div className="profileMainContainer overflow-hidden">
      <CustomModal open={open} classToOverride="col-lg-4" onClose={handleClose}>
        <div className="m-4">
          {" "}
          <DropDown
            id="tag"
            required={true}
            optionId="value"
            optionValue="name"
            name="tag"
            className="field-common-styling w-100"
            // ref="tag"
            value={tag}
            options={tagData}
            handleChange={(e) => setTag(e.target.value)}
          />
          {tag !== "" && (
            <DropDown
              id="subtag"
              required={true}
              optionId="id"
              optionValue="name"
              name="subtag"
              className="field-common-styling w-100"
              // ref="tag"
              value={subtag}
              options={tags[tag] || []}
              handleChange={(e) => setSubTag(e.target.value)}
            />
          )}
          <Button
            onClick={() => updateClientDetails()}
            className="w-100 mt-3 bg-primary"
            disabled={tag === "" || subtag === ""}
          >
            {loader ? <Spinner animation="border" role="status" /> : "Update"}
          </Button>
        </div>
      </CustomModal>
      <img
        src={
          !Utility.isEmpty(bannerImage)
            ? bannerImage
            : require("assets/images/profile/user-profile-bg.jpg")
        }
        className="profile-page-banner-container"
      />
      <div className="profile-page-userAvatar-container " id="profileContainer">
        {!Utility.isEmpty(profileImgUrl) ? (
          <Image
            className="rounded-circle w-fit profile-page-userImage"
            src={profileImgUrl}
          />
        ) : (
          <Avatar
            name={userData && userData.user_name}
            round={true}
            size="220"
            className="profile-page-userAvatar"
            textSizeRatio={1.75}
            color="#007bb3"
            maxInitials={1}
          />
        )}
        <p className="fs-36 ff-Inter-Bold">{userData && userData.user_name}</p>
        {userData.network === "clients" ? (
          <p className="profile-user-location ff-Inter-Bold">
            {userData.client_city && `${userData.client_city}`}
            {userData.country &&
              `${userData.client_city ? ", " : ""}${userData.country}`}
          </p>
        ) : (
          <p className="profile-user-location ff-Inter-Bold">
            {/* {userData.user_address1 && `${userData.user_address1}`} */}
            {userData.user_city && `${userData.user_city}`}
            {userData.country &&
              `${userData.user_city ? ", " : ""}${userData.country}`}
          </p>
        )}
        {userData.role_id === ROLE_IDS.CLIENT_OWNER && (
          <p className="d-flex font-16-bold align-items-center">
            <span className="mr-2 font-weight-bold text-secondary">
              {userData.tag
                ? `${
                    userData.tag.charAt(0).toUpperCase() + userData.tag.slice(1)
                  }, `
                : !isPublic && "Add Category"}
            </span>
            <span className="font-weight-bold text-secondary">
              {userData.sub_tag_name}
            </span>
            {!isPublic && (
              <BiEditAlt
                className="ml-1 width-20 cursor-pointer text-muted"
                onClick={handleOpen}
              />
            )}
          </p>
        )}
        <p className="profile-user-headline">{userData.user_profile_heading}</p>

        {!isPublic ||
        ((userData.role_id === ROLE_IDS.CLIENT_ADMIN ||
          userData.role_id === ROLE_IDS.CLIENT_OWNER) &&
          userData.user_id === User.user_id) ? (
          <Button
            onClick={handleClickEdit}
            className="CustomProfileButtonStyle btn-bg-white"
          >
            EDIT PROFILE
          </Button>
        ) : showMessage ? (
          <>
            {userData.role_id === ROLE_IDS.USER &&
              !isFollowed &&
              userData.id !== User.id && (
                <Button
                  onClick={() =>
                    handleFollowFollowing(
                      userData.id,
                      User.id,
                      CLIENT_ID,
                      isFollowed
                    )
                  }
                  className="CustomProfileButtonStyle mb-2"
                >
                  Follow
                </Button>
              )}
            {(userData.role_id !== ROLE_IDS.USER ||
              (userData.role_id === ROLE_IDS.USER &&
                (isFollowed || userData.id === User.id))) && (
              <Button
                onClick={HandleStartChat}
                className="CustomProfileButtonStyle"
              >
                MESSAGE
              </Button>
            )}
          </>
        ) : (
          <Button
            disabled={!userData.is_public || isJoiningNeighbourhood}
            onClick={() => {
              joinNeighbourHood(
                userData.client_id,
                () => setIsJoined(true),
                userTileId,
                userData.id
              );
            }}
            className="CustomProfileButtonStyle"
          >
            {isJoiningNeighbourhood ? (
              <Spinner animation="border" role="status" />
            ) : (
              "JOIN"
            )}
          </Button>
        )}
      </div>
    </div>
  );
};

export default UserProfileSection;
