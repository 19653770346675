import React from 'react';
import { Form } from 'react-bootstrap';
import { FollowerInfo } from './follower_info';
import { MemberGroupIcon, ChevronUpIcon, CloseIcon } from 'shared/icons/icons';
import { InputField } from 'shared/components/form_elements';
import { Utility } from 'utils';
import { NoRecordFound } from 'shared/components/no_record_found';

export const ViewingMembers = (props) => {
  let debouncedFn;

  const handleSearch = (ev) => {
    ev.persist();

    if (!debouncedFn) {
      debouncedFn =  Utility.debounce(() => {
         const searchString = ev.target.value;
         props.getAllMembers(searchString);
      }, 500);
    }
    debouncedFn();
  };

  return(
    <>
       <div className="article-page-side h-100" >
        <div className="article-page-side-head py-3 px-4 align-items-center border-bottom" >
          <MemberGroupIcon
            fillClass="fill-less-black" />
          <span className="display-8 font-bold ml-2" >{props.heading}</span>
          <ChevronUpIcon
            handleClick={() => props.switchRightSection('none')}
            mainClass="chevron-up-icon"
          />
          {props.loadedFrom === 'campaignModule' &&
            <CloseIcon
              mainClass="ml-auto cursor-pointer"
              handleClick={() => props.switchRightSection('none')}
            />
          }
        </div>
        <div className="article-page-side-body" >
          <Form className="shar-form search-form search-form-pwa sidebar-search pb-3 search-form-full sidebar-form" >
            <Form.Group>
              <InputField
                handleChange={handleSearch}
                type="text"
                placeholder="Search Members..."
              />
            </Form.Group>
          </Form>
          {!Utility.isEmpty(props.allMembers) ?
            props.allMembers.map((e) =>
            <FollowerInfo
              {...e}
              key={e.id}
              id={e.user_client_id}
              imageUrl={e.image_url}
              name={e.user_name}
              isFollowed={e.is_followed}
              setFollowerfollowing={() => props.setFollowerfollowing(e.user_client_id)}
            />) : <NoRecordFound />
          }
        </div>
      </div>
    </>
  );
};
