import React, { Component } from "react";
import { Button, Form, Spinner } from "react-bootstrap";
import { CircleOutlinedAddIcon, CloseIcon } from "shared/icons/icons";
import { CircleFilledAddLargeIcon } from "shared/icons/icons";
import { InputField } from "shared/components/form_elements";
import UploadFile from "shared/components/file_upload";
import RichTextEditor from "shared/components/rich_text_editor";
import {
  ImagePreview,
  VideoPreview,
} from "../../../shared/components/file_upload";

export class LessonForm extends Component {
  constructor(props) {
    super(props);
  }

  paintImageInput = () => (
    <Form.Group className="mb-4 pb-1">
      <Form.Label className="font-bold display-8 mb-3">
        Add a Media <span className="text-red">*</span>{" "}
      </Form.Label>
      <div className="align-items-center justify-content-center post-pic position-relative page-sidebar-upload create-article-upload">
        <>
          {!(
            this.props.formStates.imagePreviewUrl ||
            this.props.formStates.videoPreviewUrl
          ) ? (
            <input
              type="file"
              id="media_url"
              style={{ display: "none" }}
              accept="video/*, image/*"
              onChange={(event) =>
                event.target.files &&
                event.target.files[0].type.includes("video")
                  ? this.props.handleFileChange(
                      "video_url",
                      URL.createObjectURL(event.target.files[0]),
                      event.target.files[0]
                    )
                  : this.props.handleFileChange(
                      "image_url",
                      URL.createObjectURL(event.target.files[0]),
                      event.target.files[0]
                    )
              }
            />
          ) : (
            <>
              {this.props.formStates.imagePreviewUrl && (
                <>
                  <ImagePreview
                    previewURL={this.props.formStates.imagePreviewUrl}
                  />
                  <p
                    className="cursor-pointer f-weight-bold"
                    onClick={() => this.props.removeUploadedFile("image_url")}
                  >
                    Remove Image
                  </p>
                </>
              )}
              {this.props.formStates.videoPreviewUrl && (
                <>
                  <VideoPreview
                    previewURL={this.props.formStates.videoPreviewUrl}
                  />
                  <p
                    className="cursor-pointer f-weight-bold"
                    onClick={() => this.props.removeUploadedFile()}
                  >
                    Remove Video
                  </p>
                </>
              )}
            </>
          )}
          <div className="error errorIcon mb-3" id={"image_urlError"} />
          {!(
            this.props.formStates.imagePreviewUrl ||
            this.props.formStates.videoPreviewUrl
          ) && (
            <label
              htmlFor="media_url"
              className="post-uplodad-label mb-0 cursor-pointer"
            >
              <CircleFilledAddLargeIcon
                mainClass="add-icon-upper"
                fillClass="fill-primary"
              />
            </label>
          )}
        </>
      </div>
      <div className="my-2 ml-1 display-8 font-medium">Or</div>
      <InputField
        required={true}
        type="text"
        name="embedded_image_link"
        ref="embedded_image_link"
        value={this.props.newCampaign.embedded_image_link}
        handleChange={this.props.handleChange}
        placeholder="Embed Image URL..."
      />
      <div className="my-2 ml-1 display-8 font-medium">Or</div>
      <InputField
        type="text"
        name="embedded_video_link"
        value={this.props.newCampaign.embedded_video_link}
        handleChange={this.props.handleChange}
        placeholder="Embed YouTube URL..."
      />
    </Form.Group>
  );

  paintVideoInput = () => (
    <Form.Group className="mb-4 pb-1">
      <Form.Label className="font-bold display-8 mb-3">Add a Video</Form.Label>
      <div className="align-items-center justify-content-center post-pic position-relative page-sidebar-upload create-article-upload">
        <label className="post-uplodad-label mb-0 cursor-pointer">
          <UploadFile
            name="video_url"
            inputId="video_url"
            removeText="Remove video"
            acceptTypes="video/mp4"
            uploadedFilePreviewUrl={this.props.formStates.videoPreviewUrl}
            handleFileChange={this.props.handleFileChange}
            removeUploadedFile={this.props.removeUploadedFile}
          />
          <div className="error errorIcon" id={"video_urlError"} />
          {!this.props.formStates.videoPreviewUrl && (
            <CircleFilledAddLargeIcon
              mainClass="add-icon-upper"
              fillClass="fill-primary"
            />
          )}
        </label>
      </div>
      <div className="my-2 ml-1 display-8 font-medium">Or</div>
      <InputField
        type="text"
        name="embedded_video_link"
        value={this.props.newCampaign.embedded_video_link}
        handleChange={this.props.handleChange}
        placeholder="Embed YouTube URL..."
      />
    </Form.Group>
  );

  render() {
    const props = this.props;
    const headingTxt = props.formStates.isEdit ? "Edit" : "Create";
    return (
      <div className="">
        <div className="d-flex justify-content-between border-bottom align-items-center">
          <div className="align-items-center font-weight-bold font-18 my-4">
            <CircleOutlinedAddIcon mainClass="mr-3" /> {headingTxt} Lesson
          </div>
          <div className="cursor-pointer" onClick={props.closeForm}>
            <CloseIcon />
          </div>
        </div>
        <Form
          className="shar-form search-form search-form-pwa sidebar-form py-4"
          id="lesson_form"
        >
          <Form.Group className="mb-4 pb-1">
            <Form.Label className="font-bold display-8 mb-3">
              Add Lesson Title
              <span className="text-red">*</span>
            </Form.Label>
            <InputField
              required={true}
              name="heading"
              ref="heading"
              type="text"
              id="lesson_heading"
              placeholder="Create a new Lesson"
              value={props.newCampaign.heading}
              handleChange={props.handleChange}
            />
            <div className="error errorIcon mb-3" id={"lesson_headingError"} />
          </Form.Group>

          {this.paintImageInput()}

          <Form.Group className="mb-4 pb-1">
            <Form.Label className="font-bold display-8 mb-3">
              Enter Description
              <span className="font-light-italic">(Details)</span>
            </Form.Label>
            <RichTextEditor
              id="description"
              name="description"
              placeholder="Add Text..."
              data={props.newCampaign.description}
              changeDescription={props.changeDescription}
            />
          </Form.Group>
          <Button
            disabled={props.isCreatingCampaigns}
            type="submit"
            variant="primary"
            onClick={props.submit}
            className={`btn btn-primary btn-primary-pwa btn-post-size font-18 my-4 ml-1 font-weight-bold`}
          >
            {props.isCreatingCampaigns ? (
              <Spinner animation="border" role="status" />
            ) : (
              "Save"
            )}
          </Button>
          <div className="">
            <div className="display-8 font-medium-italic cursor-pointer btn btn-post-size">
              Cancel
            </div>
          </div>
        </Form>
      </div>
    );
  }
}
