export const API_BASE_URL = process.env.REACT_APP_BASE_API_URL;
export const BASE_URL = process.env.REACT_APP_BASE_URL;

export const API_PATH = `${API_BASE_URL}/v1`;

/* Public URL's */
export const LOGIN = "/users/login";
export const LOGOUT = "/users/logout";
export const SIGN_UP = "/users/sign_up";
export const CREATE_PASSWORD = "/users/create_password";
export const FORGOT_PASSWORD = "/users/forgot_password";
export const ACTIVATE_USER = "/users/activate_user";
export const ACTIVATION_CODE_VERIFICATION =
  "/users/activation_code_verification";
export const RESEND_CONFIRMATION_LINK = "/users/resend_confirmation";
export const VERIFY_OTP = "/users/verify_otp";
export const CHECK_USER_EXISTANCE = "/users/check_existance";
export const RESEND_OTP = "/users/resend_otp";
export const UPDATE_USER = "/users";
export const VERIFY_ACTIVATION_CODE = "/users/activation_code_verification";
export const GET_ACCOUNTS = "/users/accounts";
export const SWITCH_ACCOUNT = "/users/switch_account";
export const SEND_OTP = "/sendMessage";
export const SEND_VERIFICATION_CODE = "/users/send_verification_code";
export const CREATE_APPLICANTS = "/users/create_applicants";
export const NEIGHBORHOOD_SIGN_UP = "/clients/neighborhood_sign_up";
export const VERIFY_CODE = "/clients/verify_activation_code";
export const RESEND_VERIFICATION_CODE = "/clients/resend_confirmation";
// export const SEND_VERIFICATION_CODE = '/clients/sendOtpOnMobile';

export const SAVE_USER_INTEREST = "/saveUserInteret";
export const LINKEDIN = "/auth/linkedin";
export const FACEBOOK = "/auth/facebook";
export const GOOGLE = "/auth/google";

export const CLIENT_TYPES_AND_SUBTYPES = "/client_types";

export const SDG = "/sustainable_development_goals";

export const CLIENT_GOALS = "/clients/goals";

export const TOOL_CAMPAIGN = "/tool/:tool_id/campaign/:id";

export const GIFT_CARD_BRANDS = "/gift_card_brands";
export const GIFT_CARDS = "/gift_cards";

export const AUDIENCES = "/audiences";
export const AUDIENCES_USER_DETAILS = "/audiences";
export const AUDIENCES_LIST = "/audiencesList";

export const CRM_MODULES = "/crm_modules";

export const GET_FOLLOWERS_FOLLOWING = "/users/follower_following";
export const USER_PROFILE = "/users/user_profile";
export const USER_ACTIVITY = "/activity";

/* Authenticated URL's */
export const USERS = "/users";
export const USERS_COUNT = "/users/count";
export const USER_AUTH = "/users/auth_user";
export const USER_EMAIL_CHECK = "/users/checkUserEmail";
export const UPDATE_INSTA_TOKEN = "/users/updateInstaToken";
export const COUNTRIES = "/countries";
export const STATES = "/states";
export const USER_REPORT = "/userReport";
export const USER_REPORT_UPDATE = "/userReportUpdate";
export const TIMEZONE = "/timezones";

export const CLIENT_NETWORK_CHANNELS = "/client_network_channels";
export const CLIENT_NETWORK_CHANNELS_COUNT = "/client_network_channels/count";
export const VERIFY_INVITATION = "/client_network_channels/verify_invitation";
export const RESEND_NETWORK_INVITATION =
  "/client_network_channels/resend_invitation";
export const PENDING_NETWORKS = "/web/networks/pending";
export const NETWORK_CHANNELS_LAT_LONG = "/client_network_channels/location";

export const CLIENT_TYPES = "/client_types";
export const CLIENTS = "/clients";
export const CLIENTS_DOMAIN = "/web/clients/domain";
export const CLIENTS_COUNT = "/clients/count";
export const GET_CLIENT_LOGO_URL = "/get_client_logo_url";

/* Direct Messaging  URL's*/
export const CHATS = "/chats";
export const CHATS_CLIENTS = "/chats/clients";
export const FIND_USER_TO_CHAT = "/find_user_to_chat";
export const MESSAGES = "/messages";
export const MESSAGES_COUNT = "/messages/count";
export const MESSAGE_MARK_AS_READ = "/messages/mark_read";
export const UPDATE_MESSAGES = "/messages/update_message";
export const GROUP_MESSAGES_MARK_READ = "/messages/mark_group_messages_read";
export const GETMYALLCHATFRIENDS = "/getAllChatsUser";
export const GETALLJOINEDGROUPS = "/getGroupsYouHaveJoined";
export const MESSAGE_IMAGE_URL = "/image_message_url";
export const REGIONS_LIST = "/regions_list";
export const COUNTRIES_BY_REGION = "/countries_by_region";
export const CAMPAIGNS_SHARED_IN_CHAT = "/messages/getCampaignsSharedInChats";
// export const RECENT_CHATS = '/recent_chats';

/* Campaigns */
export const CLIENTS_BENEFICIARIES = "/clients/beneficiaries";
export const CLIENTS_PUBLISHERS = "/clients/publishers";
export const CAMPAIGNS = "/campaigns";
export const CAMPAIGN_STORIES = "/campaigns/livefeed";
export const CAMPAIGNS_TOP_STORIES = "/campaigns_top_stories";
export const CAMPAIGN_DETAILS = "/campaign_details";
export const CAMPAIGNS_COUNT = "/campaigns/count";
export const ALL_PARENT_CAMPAIGNS = "/all_parent_campaigns";
export const CAMPAIGNS_BY_CATEGORY = "/campaigns_by_category";
export const CAMPAIGNS_BY_CATEGORY_NAME = "/campaigns_by_category_name";
export const GROUPS_BY_CATEGORY_NAME = "/groups_by_category_name";
export const ALL_PUBLIC_EVENT_CAMPAIGNS = "/all_public_event_campaigns";
export const FAVORITE_CAMPAIGNS = "/favourite_campaigns";
export const EVENT_CAMPAIGN_PRESENCE_FOR_CALENDAR =
  "/event_campaigns_presence_for_calendar";
export const FEATURED_FEEDS = "/campaigns/featured_feeds";
export const CAMPAIGNS_BY_ID = "/campaign_by_id";
export const ALL_CAMPAIGN_CHAPTERS = "/get_all_campaign_chapters";
export const CAMPAIGN_COMMENTS = "/campaign_comments";
export const ALL_CAMPAIGN_COMMENTS = "/all_campaign_comments";
export const ALL_CC_COUNT = "/all_campaign_comments_count";
export const COMMENTS_MARK_AS_READ = "/comments_mark_as_read";
export const CAMPAIGN_SENTIMENT_DETAILS = "/campaign_sentiment_details";
export const CAMPAIGN_IS_LIKED = "/campaign_is_liked";
export const CAMPAIGN_VIEWS = "/campaign_views";
export const EVENT_CAMPAIGNS = "/event_campaigns";
export const GET_MY_DONATIONS = "/get_my_donations";
export const SEND_FF_SHARE_NOTI = "/send_ff_share_noti";
export const SET_CAMPAIGN_HEADLINE = "/set_campaign_headline";
export const SET_CAMPAIGN_SHARINGS = "/set_campaign_sharings";
export const GET_CATEGORIES_BY_CLIENTS = "/product_categories";
export const UPDATE_CAMPAIGNS_RANKING = "/update_ranking";
export const SHARE_EVENT = "/invite_for_event";
export const GET_ALL_ASSIGNMENTS = "/getAllAssignments";
export const GET_DISCUSSION_COMMENTS = "/getDiscussionComments";
export const FLAG_DICUSSION_COMMENT = "/flagDiscussionComment";
export const CREATE_DISCUSSION_COMMENT = "/createDiscussionComment";
export const UPDATE_DISCUSSION_COMMENT = "/updateDiscussionComment";
export const CREATE_DISCUSSION_FLAG = "/createDiscussionUpVote";
export const CREATE_DISCUSSION_LIKE = "/createDiscussionLike";
export const DELETE_DISCUSSION_COMMENT = "/deleteDiscussionComment";
export const GET_ASSIGNMENT_BY_LESSON = "/getAssignmentByLesson";
export const SIGNED_URL = "/media/signed_url";
export const WEA_QUALITY_MASTER = "/wea_quality_master";
/* Event Calendar */
export const CALENDAR_DETAILS = "/calendar_details";

/* Groups */
export const GROUPS = "/groups";
export const DEFAULT_GROUPS = "/default_group";

/* Group Members */
export const GROUP_MEMBERS = "/group_members";
export const MY_INVITATION = "/my_invitation";

export const COURSEPROGRESS_UPDATEACTIVE =
  "/campaignsCourseProgress/updateActive";
export const LEAVE_GROUP = "/leave_group";
export const ADD_PERMISSION = "/add_permissions";
export const REMOVE_PERMISSION = "/remove_permission";

/* Event Members */
export const EVENT_MEMBERS = "/event_members";
export const EVENT_MEMBERS_LIST = "/event_members_list";
export const MY_EVENT_INVITATION = "/my_event_invitation";
export const EVENT_MEMBERS_COUNTS = "/event_members/count";
export const EVENT_REMOVE = "/event_members_remove";
export const MEMBER_ALL_EVENT = "/member_all_event";

/* Group Campaigns */
export const GROUP_CAMPAIGNS = "/group_campaigns";

/* Finances */
export const CLIENT_STATEMENTS = "/statements";
export const ACCOUNTS = "/accounts";

/* Statistics */
export const OVERVIEW_STATS = "/web/statistics/overview_stats";
export const AUDIENCES_STATS = "/web/statistics/audiences_stats";
export const CAMPAIGNS_STATS = "/web/statistics/campaigns_stats";
export const OVERVIEW_STATS_LIST = "/web/statistics";

/* Revenue Distriibution */
export const REVENUE_DISTRIBUTIONS = "/revenue_distributions";

/* Media Upload */
export const MEDIA_SIGNED_URL = "/media/signed_url";

/* Client Notifications */
export const CLIENT_NOTIFICATIONS = "/user_clients/:id/notifications";
export const CLIENT_NOTIFICATIONS_COUNT =
  "/user_clients/:id/notifications/count";
export const NOTIFICATION_MARK_AS_READ =
  "/user_clients_notifications/mark_as_read";

export const MARK_ALL_AS_READ = "/user_clients_notifications/mark_all_as_read";

/* Push Notification */
export const PUSH_NOTIFICATION = "/push_notification_subscription";
export const SEND_PUSH_NOTIFICATION = "/send_push_notification";

/* Categories */
export const CATEGORIES = "/categories";
export const CATEGORIES_BY_MODULE_ID = "/categories_by_module_id";
export const TOPIC_CATEGORIES = "/topic_categories";
export const GET_PRODUCTS_BY_CATEGORY = "/categories_products";

/* Products */
export const PRODUCTS = "/products";
export const SUBPRODUCTS = "/products/subProductList";
export const GET_IMAGE_URL = "/getImageUrl";
export const PRODUCT_TYPE = "/product_types";
export const DISPLAY_PRODUCT_TYPES = "/displayProductTypes";
export const GET_PRODUCTS_BY_TYPES = "/getProductByTypes";
export const SWAP_SEQUENCES = "/updateSequenceNo";
export const PRODUCT_PREVIEW = "/product/preview";

/* Orders */
export const ORDERS = "/orders";
export const ORDER_ITEMS = "/order_items";
export const SEND_ORDER = "/sendOrder";
/* Contact Message */
export const CONTACT = "/sendContactMessage";

/* Client Card Details */
export const CLIENT_PAYMENT_DETAILS = "/payment_details";
export const CLIENT_CARD_UPDATE = "/card_update";
export const ACCOUNT_SUBSCRIPTION = "/subscription";

/* Tags Relation */
export const TAG_RELATION = "/tag_relations";

/* Tags */
export const GET_USER_TAGS = "/users/tags";
export const GET_TAGS_BY_CATEGORIES = "/get_tags_by_categories";
export const TAGS = "/tags";
export const SAVE_SELECTED_TAG = "/tag_relations/bulk";
export const DELETE_TAGS = "/tag_relations/bulkDelete";

/* Attribute */
export const ATTRIBUTES = "/attributes";
export const COLOR_ATTRIBUTES = "/attributes/colors";
export const IMAGE_ATTRIBUTES = "/attributes/images";

/* ProductAttribute */
export const PRODUCTATTRIBUTES = "/product_attributes";

/* Networks */
export const GET_NETWORK_MENU = "/networkSummary";
export const GET_USERS_BY_NETWORKS = "/networkUsers";
export const UPDATE_ADMIN_ROLE = "/updateIsAdmin";
export const UPDATE_USER_CLIENT = "/user_client/update";
export const UPDATE_USER_CATEGORY = "/update_users_category";
export const USER_ACTIVATE_DEACTIVATE = "/users/activate_or_deactivate";
// Neighborhood

export const NEIGHBORHOOD_ACTIVATE_DEACTIVATE =
  "/activate_deactivate_neighbourhood";
export const GET_USERS_OF_NEIGHBORHOOD = "/users/getNeighborhoodMembers";
export const REMOVE_USERS_FROM_NEIGHBORHOOD =
  "/users/removeUserFromNeighborhood";
export const ALTER_ADMIN_STATUS_IN_NEIGHBORHOOD = "/users/setNeighborhoodadmin";
export const ADD_USER_TO_NEIGHBORHOOD = "/users/addUserToNeighborhood";
export const GET_JOINED_NEIGHBORHOOD = "/users/getJoinedNeighborhood";

/* Agendas */
export const GET_EVENT_AGENDAS = "/get_event_agendas";

/* Categories */
export const CREATE_CATEGORY = "/categories";

export const MODULE_TYPES = "/moduleList";

export const BADGES = "/badges";

export const USER_BADGES = "/user_badges";

/* My event invitations list */
export const EVENT_INVITATIONS_LIST = "/event_invitation_list";

/* License */
export const LICENSE = "/licenses";
export const CLIENT_LICENSES = "/client_licenses";
export const GET_INVOICE_LICENSE_DATA = "/get_invoice_license_data";

/* Support Users */
export const SUPPORT_USER = "/support_users";

/* Support Users */
export const SUPPORT_USER_BY_SCHOOL = "/support_users_by_school_id";

/* Assigment */
export const GET_ASSIGNMENTS = "/assignment";

/* Assignment Questions */
export const CREATE_EDIT_QUESTIONS = "/assignmentQuestion";
export const GET_ASSIGNMENTS_BY_CAMPGAIN = "/getQuizByLesson";
export const USER_ASSIGNMENT = "/userAssignment";
export const USER_ASSIGNMENT_DETAILS = "/userAssignmentDetails";
export const SWAP_ASSIGNMENT_IDS = "/swap_assignment_ids";
export const SWAP_IDS = "/swap_ids";
export const COURSE_COMPLETIONSTATUS = "/courseCompletionStatus";
/* Assignment Answers */
export const ASSIGNMENT_ANSWERS = "/getAssignmentAnswer";
/* Campaigns Course Progress */
export const CAMPAIGNS_COURSE_PROGRESS = "/campaignsCourseProgress";
/*Legacy images*/
export const GET_LEGACY_IMAGES = "/staticCourseImage";
/* Ethos Score */
export const ETHOS_SCORE = "/ethosScore";

/* Tax API */
export const GET_TAX_RATE = "/users/get_tax_rate";

/* Schools */
export const SCHOOLS = "/schools";

/*EndorseMents*/

export const ADD_ENDORSEMENTS = "/add_endorsement";
export const GET_ENDORSEMENTS = "/get_endorsement";

/* Get transaction details */
export const TRANSACTION_DETAILS = "/transactionDetails";

/* admin access */
export const ADMIN_ACCESS_REQUEST = "/users/send_admin_request";
export const ACCEPT_ADMIN_ACCESS = "/users/accept_admin_request";
export const UPDATE_ADMIN_ACCESS = "/users/update_admin_request";
export const REMOVE_ADMIN_ACCESS = "/users/remove_admin_request";

/* invite member*/
export const INVITE_MEMBER = "/invite_member";

/* Campaign Courses */
export const COURSES_BY_CATEGORIES = "/campaign_courses";
export const COURSES_BY_CATEGORY_ID = "/courses_by_category_id";

/* Quality */
export const QUALITY_MASTER = "/quality_master";
export const GET_USER_QUALITY = "/get_user_quality";
export const UPDATE_USER_QUALITY = "/update_user_quality";

/* getClients number without authorization  */
export const GET_CLIENTS_SUMMARY = "/getClients";
export const FEATURED_COMMUNITY = "/user_featured_commmunity";
export const SENDMAIL_TO_ADMIN = "/sendMailToAdmin";
export const GET_REGIONS_LIST = "/regions_list";
// export const GET_ORGANIZATION_LIST = "/organization_list";
// export const GET_TOPIC_LIST = "/topic_list";
export const GET_WEA_LIST = "/wea_tag_list";
export const UPDATE_CATEGORIES = "/tag_id_neighbourhood";
export const NEIGHBORHOOD_BY_TAG = "/neighbourhood_by_tag";
