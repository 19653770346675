import React from 'react';

export default function termsOfServices() {
  return (
    <section className="main-container">
			<div className="container">
				<div className="py-2 mb-5 text-light-black terms-services">
					<h3 className="display-3 text-black-500 font-weight-bold mt-4 mb-0 text-center py-3">Privacy Policy</h3>
					<div className="text-grey font-14">
						<h5 className="text-blue pb-2 pt-3">1. INTRODUCTION</h5>
						<p>
							<span className="font-weight-bold">AGREEMENT AND PARTIES. </span> Sharemeister, Inc., a California corporation (“Company,” “us,” or “we”) respects the privacy of the visitors to the Company’s website located at http://www.sharemeister.com (“website” or “site”).  This privacy policy (“privacy policy” or “policy”) is an agreement between Company and you, as the user of the website (“you”). This agreement tells you when this policy is effective, what information we collect from you, how we might use it, how you can review or change the personal information you provided to us, how we may change this policy, and the steps we take to ensure that your personal information is protected.
						</p>
						<h5 className="text-blue pb-2 pt-3">2. AGREEMENT TO TERMS OF PRIVACY POLICY AND TERMS OF USE</h5>
						<p>
							<span className="font-weight-bold">AGREEMENT TO TERMS.</span> All activities in which you may engage on this website are voluntary. You are not required to provide any personal information to us unless you choose to access features on this site which require the information. If you do not agree with the terms of this policy or other terms of use on this website, then you should immediately exit this website and discontinue using the site. If you do not agree with the terms of our privacy policy and our terms of use posted at [www.sharemeister.com], please do not provide us with personal information, and leave this website. The terms of use of this site are expressly incorporated herein by reference and made a part of this policy. By using Company’s website, you signify that you agree to the terms of this privacy policy as well as to our terms of use.
						</p>
						<h5 className="text-blue pb-2 pt-3">3. EFFECTIVE DATE OF AND CHANGES TO PRIVACY POLICY </h5>
						<p>
							<span className="font-weight-bold">EFFECTIVE DATES.</span>  This privacy policy is effective as of the date listed above, and will remain in effect except with respect to any of its provisions that are changed in the future, in which case the changes will become effective on the date they are posted on the website or we otherwise notify you of the changes. We reserve the right to change this privacy policy at any time without prior notice to you. Changes, modifications, additions, or deletions will be effective immediately on their posting to this site. You should check this policy periodically as its terms may change from time to time. Your continued use of the site after we post any such modifications will constitute your acknowledgment of the modified policy and your agreement to abide and be bound by the modified policy. We will also revise the “last updated” date found at the beginning of this privacy policy when we post changes to it.
						</p>
						<h5 className="text-blue pb-2 pt-3">4. POLICY APPLICABLE TO ONLINE ACTIVITIES ONLY </h5>
						<p>
							<span className="font-weight-bold">DISCLAIMERS.</span> The privacy policy does not extend to anything that is inherent in the operation of the Internet, and therefore beyond Company’s control, and is not to be applied in any manner contrary to applicable law or governmental regulation. This online privacy policy only applies to information collected through this site. This privacy policy does not apply to any information we may collect from you in any place other than this website.
						</p>
						<h5 className="text-blue pb-2 pt-3">5. PERSONAL INFORMATION WE MAY COLLECT </h5>
						<p>
							<span className="font-weight-bold">TYPES OF INFORMATION COLLECTED.</span> We may ask you for certain information referred to on this site as “personal information,” which includes information that pertains to your identity. Such information may include, but is not limited to, items such as your name, address, date of birth, age, gender, and e-mail address. You may be required to provide personal information to access or use certain parts of our website or features of the site. If you do not provide the requested personal information, you may not be able to access or use the features of the site where such information is requested.
						</p>
						<h5 className="text-blue pb-2 pt-3">6. GENERAL INFORMATION WE MAY COLLECT</h5>
						<p>
							<span className="font-weight-bold">TYPES OF INFORMATION COLLECTED.</span> There may be other instances in which we collect information from you that is more general in nature (“general information”). Such information may include your browser type and Internet Protocol (IP) address, which enables us to identify your computer or device on a Transmission Control Protocol/Internet Protocol (TCP/IP) network, which includes the World Wide Web. Your computer has an IP address, which is required in order for you to communicate with others via the Internet. Such general information, including the click stream data and cookies, as mentioned below, may be collected from you whenever you access the site, whether or not you provide us with any personal information.
						</p>
						<div className="pl-5  pb-4">
							<p>
								<strong>6.1. Click Stream Data:</strong>
							</p>
							<p>When you visit our website we may also collect “click stream data” (server address or domain name, for example). This information can be combined with information you have provided to us by registering, for example, which will enable us to analyze and better customize your visits. We may use click stream data for traffic analysis or e-commerce analysis of our website, to determine which features of our site are most or least effective or useful to you.</p>
							<p>
								<strong>6.2. Cookies:</strong>
							</p>
							<p>We may also use cookies to gather information about your visits to our website and websites that embed our code to enhance your experience. More information on how we use cookies is available in Section 11.</p>
						</div>
						<h5 className="text-blue pb-2 pt-3">7. GENERAL INFORMATION ABOUT YOU</h5>
						<p>
							<span className="font-weight-bold">USE OF GENERAL INFORMATION.</span> We will primarily use the personal information you provide and any general information we receive from you to provide services and/or products to you, including, but not limited to, the following:
						</p>
						<div className="pl-5 pb-4">
							<p>
								<strong>A.</strong>	To administer a content promotion survey or other site special events and to provide you with the relevant products or services described in the survey.
							</p>
							<p>
								<strong>B.</strong>	To send you information or materials via e-mail about products, offers, and news we think might interest you. This information may relate to products, offers, and news of the Company or its licensees or selected commercial partners. If you no longer wish to receive these types of e-mails from us, or if you want to modify your personal data or want your personal data to be deleted from our database, see Section 12.
							</p>
							<p>
								<strong>C.</strong>	To analyze visits to the website and learn about the interests of our visitors in aggregate and also to better understand your interests and needs, so we can improve our products and services and deliver to you the type of content, features and promotions that you are most interested in.
							</p>
						</div>
						<h5 className="text-blue pb-2 pt-3">8. PROTECTION OF YOUR PERSONAL INFORMATION AND GENERAL INFORMATION</h5>
						<div className="pl-5 pb-4">
							<p>
								<strong>8.1. Security Measures:</strong>
							</p>
							<p>In order to protect both the personal information and the general information that we receive from you through our website, we have implemented appropriate security measures.  Your personal information is stored on a secure server.</p>
							<p>
								<strong>8.2. Risk of Interception:</strong>
							</p>
							<p>Please keep in mind, however, that whenever you give out personal information online there is a risk that third parties may intercept and use that information. While Company strives to protect your personal information and privacy, we cannot guarantee the security of any information you disclose online. By using this site, you expressly acknowledge and agree that we do not guarantee the security of any data provided to or received by us through this site and that any personal information, general information, or other data or information received from you through the site is provided to us at your own risk, which you expressly assume.</p>
						</div>
						<h5 className="text-blue pb-2 pt-3">9. NO USE OF THIS SITE BY PERSONS UNDER 18 PERMITTED </h5>
						<div className="pl-5 pb-4">
							<p>
								<strong>9.1. Registration of Minors Declined:</strong>
							</p>
							<p>Company intends that only persons who are 18 years or older use this website. BY USING THE SERVICE, YOU REPRESENT THAT YOU ARE AT LEAST 18 YEARS OLD AND LEGALLY ABLE TO ENTER INTO THIS AGREEMENT.  IF YOU ARE LESS THAN 18 YEARS OLD BUT ARE AT LEAST 16 YEARS OLD, YOU MAY ONLY USE THIS SITE AND/OR SERVICE UNDER THE SUPERVISION OF A PARENT OR LEGAL GUARDIAN WHO AGREES TO ABIDE BY THIS AGREEMENT.  THE SITE AND ITS RELATED SERVICES ARE NOT INTENDED FOR USE BY CHILDREN UNDER 16 YEARS OF AGE. Personal registration information submitted by a person under the age of 16 will not be accepted. Any general information provided by a person under the age of 18 and gathered (for example, through the use of cookies) during his or her visit may be used as indicated in this policy (see Section 6). We encourage parents and guardians of children under 18 to regularly check and monitor their children’s use of e-mail and other activities online.</p>
							<p>
								<strong>9.2. Notice Concerning Children:</strong>
							</p>
							<p>We are a general audience site, and do not direct any of our content specifically at children under 16 years of age. We understand and are committed to respecting the sensitive nature of children’s privacy online. If we learn or have reason to suspect that a site user is under age 16, we will promptly delete any personal information in that user’s account.</p>
						</div>
						<h5 className="text-blue pb-2 pt-3">10.	THIRD PARTIES</h5>
						<div className="pl-5 pb-4">
							<p>
								<strong>10.1.	Disclosing Information to Third Parties:</strong>
							</p>
							<p>WHO MAY HAVE ACCESS TO YOUR INFORMATION</p>
							<p>We are going to share your information when you “OPT-IN” to campaigns associated with our network clients who are a campaign owner, creator, distributor, and/or beneficiary.</p>
							<p>Except as described in this policy, we do not sell, trade, or otherwise transfer your personal information or general information to third parties without informing you in advance. We provide some of our services through contractual arrangements with affiliates, service providers, partners and other third parties (collectively, “service partners”). Our service partners may use your personal information to operate our sites and to deliver their services. For example, your data may be transferred to website hosting partners and other parties who assist us in designing and operating the website, executing services (such as for prize fulfillment), or helping us analyze collected data. These parties will only use your data for the purpose of carrying out the work as agreed with us and will be required to keep the information confidential. We will encourage our service partners to adopt and post privacy policies. However, the use of your personal information by our service partners is governed by the privacy policies of those service partners and is not subject to our control.</p>
							<p>
								<strong>10.3.	Security:</strong>
							</p>
							<p>
								<strong>RELEASE OF INFORMATION.</strong> Company will release personal or general information without your consent for security purposes, when required by law, or to prevent imminent harm to any person or entity. We will disclose personal or general information upon receipt of a court order or subpoena, or to cooperate with a law enforcement investigation, which may include responding to requests and court orders from jurisdictions outside the United States. We fully cooperate with law enforcement agencies in identifying those who use our services for illegal activities. We reserve the right to report to law enforcement agencies any activities that we in good faith believe to be unlawful, as determined in our sole discretion. Release of your personal data for security purposes, as described in this agreement to any person or entity under any circumstances shall be based on a determination made solely by us, exercising our own discretion, permission for which is expressly granted by you to us in accordance with this policy.
							</p>
							<p>
								<strong>10.4.	Links to Third Parties:</strong>
							</p>
							<p>
								<strong>PURPOSE OF LINKS.</strong> For your convenience and to improve the usage of the website we may insert links to third parties on this site. This privacy policy does not apply to such third party websites. These links take you outside our service and off Company’s site and are beyond our control. This may include, for instance, links from partners that may use Company’s logos as part of a co-branding agreement. The sites those links can take you to have their own separate privacy policy and although we seek to protect the integrity of our site, Company is not liable for the content and activities of those sites. Your visits and access to such websites is at your own risk. Please note that those other sites may send their own cookies to users, collect data, or solicit personal information.
							</p>
						</div>
						<h5 className="text-blue pb-2 pt-3">11.	AUTOMATIC COLLECTION</h5>
						<p>
							<strong>COOKIES. </strong> We may use cookies to collect certain types of information.  A cookie is a small string of information that the website you visit transfers to your computer for identification purposes. Cookies can be used to follow your activity on the website and that information helps us to understand your preferences and improve your website experience. Cookies are also used for such activities as remembering your user name and password, if you use such a feature on this site. You can turn off all cookies, in case you prefer not to receive them. You can also have your computer warn you whenever cookies are being used. For both options you have to adjust your browser settings. There are also software products available that can manage cookies for you. Please be aware, however, that when you choose to reject cookies, this choice may limit the functionality of the website you visit and you may lose access to some of its features.
						</p>
						<h5 className="text-blue pb-2 pt-3">12.	DELETING OR MODIFYING PERSONAL INFORMATION</h5>
						<p>
							<strong>CHANGING YOUR INFORMATION.</strong> You may cancel your registration at any time, and you may opt out of receiving any e-mails from Company. Additionally, you can choose to have all your personal information deleted from our database. You may opt out, or delete or modify your personal information, by visiting our website registration page at [www.sharemeister.com] and following the instructions provided. We encourage you to update your personal information promptly when it changes.
						</p>
						<h5 className="text-blue pb-2 pt-3">13.	QUESTIONS OR COMMENTS</h5>
						<p>
							<strong>CONTACT INFORMATION.</strong> If you have any questions or comments relating to our website or the privacy policy, send an e-mail to [hello@sharemeister.com] or write [13355 Noel Road, Suite 1100, Dallas, Texas, 75240].
						</p>
					</div>
				</div>
			</div>
		</section>
  );
}
