import React from "react";
import Avatar from "react-avatar";
import { withRouter } from "react-router-dom";
import { ClockIcon } from "shared/icons/icons";
import { useDispatch, useSelector } from "react-redux";
import {
  EVENT_MEMBERS,
  EVENT_REMOVE,
} from "../../../config/constants/api_endpoints";
import { toast } from "react-toastify";
import * as Utility from "utils/utility";
import { useLocation } from "react-router";
import moment from "moment-timezone";
import { updateRefreshEventsAPI, showLoader } from "shared/actions";

const EventCard = ({ items, campaign_id, ...props }) => {
  const startDate = items.start_at ? new Date(items.start_at) : new Date();
  const endDate = items.end_at ? new Date(items.end_at) : new Date();
  const userData = useSelector((state) => state.userData);
  const { userNeighborhoods } = useSelector((state) => state);
  const location = useLocation();
  const dispatch = useDispatch();

  const handleClick = () => {
    if (userNeighborhoods && userNeighborhoods.length !== 0 && items) {
      if (
        userNeighborhoods.some((item) => item.client_id === items.corporate_id)
      ) {
        handleEventClick(items);
      } else {
        if (items) {
          toast.error(
            items.is_public
              ? `Please join ${items.corporate_name || items.author_name}`
              : `You are not a part of ${
                  items.corporate_name || items.author_name
                }`,
            { containerId: "private" }
          );
        }
      }
    }
  };

  const handleEventClick = (item) => {
    props.history.push({
      pathname: `/event-details/${item.id ? item.id : item.campaign_id}`,
      goBackLink: "/media",
      from: location.pathname,
    });
  };

  const sendRSVP = async () => {
    const params = {
      campaignId: campaign_id ? campaign_id : items.id,
      userClientIds: [userData.id],
      status: "approved",
      isActive: true,
      memberId: userData.id,
    };

    await Utility.sendRequest(EVENT_MEMBERS, 2, params, (err, res, body) => {
      body = JSON.parse(body);

      if (body.error) {
        toast.error(body.error.message, { containerId: "private" });
      } else {
        if (body.data) {
          toast.success('The event has been added to "My Events"', {
            containerId: "private",
          });
          dispatch(updateRefreshEventsAPI(Math.random()));
        }
      }
    });
  };

  const removeRSVP = async () => {
    const params = {
      campaign_id: items.id,
      user_client_id: [userData.id],
    };

    await Utility.sendRequest(EVENT_REMOVE, 2, params, (err, res, body) => {
      body = JSON.parse(body);

      if (body.error) {
        toast.error(body.error.message, { containerId: "private" });
      } else {
        if (body.data) {
          toast.success('The event has been removed from "My Events"', {
            containerId: "private",
          });
          dispatch(updateRefreshEventsAPI(Math.random()));
        }
      }
    });
  };

  const getMonthName = (date) => {
    return date
      .toLocaleString("default", { month: "short" })
      .toLocaleUpperCase();
  };

  const getTime = (date) => {
    return date.toLocaleString("en-US", {
      hour: "numeric",
      minute: "numeric",
      hour12: true,
    });
  };

  const formatDate = (date) => {
    return (
      date.toLocaleString("default", { month: "short" }) +
      " " +
      date.getDate() +
      " " +
      date.getFullYear() +
      " " +
      getTime(date)
    );
  };

  const dateFormating = (campaigns_timezone) => {
    return (
      <span
        className="font-10 ml-2"
        style={{
          color: "#f2f2f2",
          whiteDpace: "nowrap",
          overflow: "hidden",
          textOverflow: "ellipsis",
          whiteSpace: "nowrap",
          width: "calc(100% - 20px)",
        }}
      >
        {items.campaigns_dates.map((date, i) => {
          return (
            <React.Fragment key={date}>
              {i > 0 && ", "}
              {moment(date)
                .tz(campaigns_timezone.split(" (")[0])
                .format("MMM DD YYYY HH:mm")}
            </React.Fragment>
          );
        })}
      </span>
    );
  };

  const eventDateFilter = () => {
    const arrayDate = items.campaigns_dates;
    let filterDate = [];
    let currentDate = moment().format("YYYY-MM-DD");

    if (arrayDate) {
      filterDate = arrayDate.filter((date) => {
        let endDate = moment(moment(date).format("YYYY-MM-DD"));
        let days = endDate.diff(currentDate, "days");
        return days >= 0 && date;
      });
    }

    return arrayDate ? (
      filterDate.length > 0 ? (
        <DateComponent
          month={moment(filterDate[0]).format("MMM")}
          day={moment(filterDate[0]).format("DD")}
        />
      ) : (
        <DateComponent
          month={moment(arrayDate[arrayDate.length - 1]).format("MMM")}
          day={moment(arrayDate[arrayDate.length - 1]).format("DD")}
        />
      )
    ) : (
      <DateComponent
        month={getMonthName(startDate)}
        day={startDate.getDate()}
      />
    );
  };
  const DateComponent = ({ month, day }) => (
    <div className="profile-event-time-box">
      <div className="profile-event-time-box-day">{month}</div>
      <div className="profile-event-time-box-date">{day}</div>
    </div>
  );

  return (
    <div>
      {items && (
        <div
          key={items.id}
          onClick={() => {
            if (items.corporate_id === null) {
              handleEventClick(items);
            } else {
              handleClick();
            }

            // props.eventsData.map((event) => {
            //   if (event.id === items.id) {
            //     if (event.corporate_id === null) {
            //       alert("qwwe");
            //       handleEventClick(items);
            //     } else {
            //       alert("dfsf")
            //       handleClick(event.id, event.corporate_id);
            //     }
            //   }
            // });
          }}
          className={`events-card position-relative events-card-wrapper bg-white cursor-pointer ${
            props.TotalEvents &&
            props.TotalEvents.length === props.index + 1 &&
            "mr-2"
          } ${props.index === 0 && !props.isNoLeftMargin && "ml-0"}  ${
            props.index > 0 && !props.isNoLeftMargin && "ml-3"
          }`}
        >
          {items.image_url ? (
            <span className={"image-container"}>
              <img
                className={"events-image article-card-image"}
                src={items.image_url}
                alt="#"
              />
            </span>
          ) : (
            <div className=" d-flex justify-content-center align-items-center h-50">
              {/* <Avatar
                name={items.author_name}
                round={true}
                size={"20"}
                textSizeRatio={1.75}
                color="#007bb3"
                maxInitials={1}
                className={"article-pic"}
              /> */}
              <Avatar
                name={items.author_name}
                round={true}
                size={"61"}
                textSizeRatio={1.75}
                color="#007bb3"
                maxInitials={1}
                className={"community-avatar"}
              />
            </div>
          )}
          <div className="events-card-content">
            <div className="d-flex">
              {eventDateFilter()}
              <div
                className={`course-text-course course-category-container`}
                style={{
                  color: items.category_color,
                }}
              >
                {/* {items.campaign_category} */}{" "}
              </div>
            </div>
            <span className={"course-title-container m-0"}>
              {items.heading && items.heading.length > 20
                ? items.heading.slice(0, 20) + "..."
                : items.heading}
            </span>
            <div className="font-10 d-flex align-items-center b-500">
              <ClockIcon mainClass="w-10" fillClass="fill-cuurent"/>

              {items.campaigns_dates && items.campaigns_dates.length > 0 ? (
                <>
                  {items.campaigns_timezone &&
                    dateFormating(items.campaigns_timezone)}
                </>
              ) : (
                <span className="font-10 ml-2" style={{ color: "#f2f2f2" }}>
                  {formatDate(startDate) + " - " + formatDate(endDate)}
                </span>
              )}
            </div>
            {/* <p className="font-10 ml-3" style={{ color: "#5c6978" }}>
            Timezone : { && items.campaigns_timezone.split(' (')[0] }
            </p> */}
            {items.community_name && (
              <div className="font-10 d-flex align-items-center b-500">
                <input
                  type="checkbox"
                  id="region"
                  className="checkbox-round w-10"
                />
                <label
                  htmlFor="region"
                  style={{ color: "#f2f2f2" }}
                  className="mb-0 ml-2"
                >
                  {items.region && items.region + "/"}
                  {items.country
                    ? items.country
                    : items.community_name && items.community_name}
                </label>
              </div>
            )}
          </div>
        </div>
      )}
    </div>
  );
};

export default withRouter(EventCard);
