import React, { useState, useEffect } from "react";
import { Row, Col, Container } from "react-bootstrap";
import { HomeNavbar } from "scenes/home/components/navbar";
import { NavLink } from "react-router-dom";
import ReactPlayer from "react-player";
import moment from "moment";
import { CloseIcon } from "shared/icons/icons";
import OwlCarousel from "react-owl-carousel";
import { useDispatch } from "react-redux";
import { updateUserData } from "shared/actions";
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";
import { CLIENT_ID, CRM_MODULES } from "config/constants/config";
import { CAMPAIGN_TYPES_IDS } from "config/constants";
import * as Utility from "utils/utility";
import { ALL_PUBLIC_EVENT_CAMPAIGNS } from "config/constants/api_endpoints";
import { toast } from "react-toastify";
import DownloadComplete from "shared/components/download_complete";
import WeaFooter from "../../config/WeaFooter";

export function LandingPage() {
  const [playing, setPlaying] = useState(false);
  const [publicEventList, setPublicEventList] = useState([]);
  const [isIos, setIos] = useState(false);
  const [showStepsPage, setShowStepPage] = useState(false);
  const [showDownloadPage, setShowDownloadPage] = useState(false);
  const startOfDay = moment.utc(moment().startOf("day").format()).format();
  const endOfDay = moment.utc(moment().endOf("day").format()).format();
  const publicEventDate = moment().format("DD MMMM YYYY");
  const dispatch = useDispatch();
  const pwaModeEnabled = Utility.pwaModeEnabled();

  useEffect(() => {
    dispatch(updateUserData({}));
    getPublicEvents();
    getMobileOperatingSystem();
  }, []);

  const [installable, setInstallable] = useState(false);
  const [deferredPrompt, setDeferredPrompt] = useState("");

  useEffect(() => {
    window.addEventListener("beforeinstallprompt", (e) => {
      e.preventDefault();
      setDeferredPrompt(e);
      setInstallable(true);
    });
  }, []);

  const handleInstallClick = () => {
    // Hide the app provided install promotion
    // Show the install prompt
    if (installable && deferredPrompt) {
      deferredPrompt.prompt();
      // Wait for the user to respond to the prompt
      deferredPrompt.userChoice.then((choiceResult) => {
        if (choiceResult.outcome === "accepted") {
          console.log("User accepted the install prompt");
          setShowDownloadPage(true);
        } else {
          console.log("User dismissed the install prompt");
        }
      });
      setInstallable(false);
    }
  };

  const getMobileOperatingSystem = () => {
    var userAgent = navigator.userAgent || navigator.vendor || window.opera;
    if (/android/i.test(userAgent)) {
      setIos(false);
    }
    if (/iPad|iPhone|iPod/.test(userAgent) && !window.MSStream) {
      setIos(true);
    }
  };

  const handleButtonClick = () => {
    if (isIos) {
      setShowStepPage(true);
      setShowDownloadPage(false);
    } else {
      handleInstallClick();
      setShowStepPage(false);
    }
  };

  const getPublicEvents = () => {
    const params = {
      module_id: CRM_MODULES.CAMPAIGNS,
      client_id: CLIENT_ID,
      user_client_id: "",
      campaign_type_id: CAMPAIGN_TYPES_IDS.event,
      current_time: moment.utc().format("YYYY-MM-DD HH:mm:ss"),
      start_of_day: startOfDay,
      end_of_day: endOfDay,
      search: "",
      date_for_month: publicEventDate,
    };

    if (
      moment(publicEventDate).format("MMMM YYYY") !==
      moment().format("MMMM YYYY")
    ) {
      params.start_of_day = moment
        .utc(moment(publicEventDate).startOf("day").format())
        .format();
      params.end_of_day = moment
        .utc(moment(publicEventDate).endOf("day").format())
        .format();
    }

    Utility.sendRequest(
      ALL_PUBLIC_EVENT_CAMPAIGNS,
      1,
      params,
      (err, res, body) => {
        body = JSON.parse(body);

        if (body.error) {
          toast.error(body.error.message, { containerId: "private" });
        } else {
          setPublicEventList(body.data);
        }
      }
    );
  };

  const paintGilVideo = () => {
    return (
      <>
        <HomeNavbar />
        <div className="player-wrapper">
          <ReactPlayer
            playing={playing}
            url={require("assets/videos/GIL Journey Passport Promo.mp4")}
            className="react-player video-fit-screen"
            width="100%"
            controls={true}
            playsInline={true}
            loop={false}
            onEnded={() => setPlaying(false)}
          />
          <div className="landing-page-video-close">
            <CloseIcon
              mainClass="cursor-pointer"
              fillClass="fill-white"
              handleClick={() => setPlaying(false)}
            />
          </div>
        </div>
      </>
    );
  };

  const getDateInFormat = (date) => {
    return moment.utc(date).local().format("MMMM DD, YYYY");
  };

  const paintPublicEvents = () => {
    const eventList = [];
    publicEventList.length &&
      publicEventList.map((e) => {
        eventList.push(
          e.data.map((event) => {
            return (
              <Col md={4} className="mb-4 mb-0" key={event.id}>
                <div className="card h-100">
                  <div className="card-img">
                    <img className="w-100" src={event.image_url} alt="" />
                  </div>
                  <div className="card-body">
                    <div className="d-flex event-date-field mb-3">
                      <img
                        className="mr-2"
                        src={require("assets/images/gil/cal-icon.svg")}
                        alt=""
                      />
                      {getDateInFormat(e.date)}
                    </div>
                    <p>{event.heading}</p>
                  </div>
                </div>
              </Col>
            );
          })
        );
      });
    return eventList;
  };

  const VidPlayIcon = () => {
    return (
      <svg width="30px" height="38px" viewBox="0 0 30 38">
        <g
          id="PWA-2021"
          stroke="none"
          strokeWidth="1"
          fill="none"
          fillRule="evenodd"
        >
          <g
            id="Upload-App"
            transform="translate(-166.000000, -229.000000)"
            fill="#FFFFFF"
          >
            <g
              id="arrow/caret/right"
              transform="translate(166.000000, 229.000000)"
            >
              <path
                d="M0,34.8333333 C0.0702958535,35.6273366 0.508635919,36.4078928 1.32281541,37.014474 C3.08656929,38.3285087 5.94617933,38.3285087 7.70993321,37.014474 L28.6637546,21.4034258 C30.4454151,20.0760504 30.4454151,17.9239496 28.6637546,16.5965742 L7.70993321,0.985525967 C5.94617933,-0.328508656 3.08656929,-0.328508656 1.32281541,0.985525967 C0.508273901,1.59237693 0.0699061189,2.3733576 0.00771206294,3.16763388 L0,3.16666667 L0,34.8333333 Z"
                id="Shape"
              />
            </g>
          </g>
        </g>
      </svg>
    );
  };

  return showStepsPage && Utility.pwaModeEnabled() ? (
    playing ? (
      <div className="hide-header">{paintGilVideo()} </div>
    ) : (
      <div className="pwa-login-screen shar-form px-4 py-5 h-100 d-flex flex-column bg-primary text-white">
        <div className="d-flex mb-4 pb-2">
          <div onClick={() => setShowStepPage(false)}>
            <img
              src={require("assets/images/icons/back_icon_white.svg")}
              alt="back icon"
            />
          </div>
          <div className="mx-auto" style={{ maxWidth: 125 }}>
            <img
              src={require("assets/images/logos/main-logo-white.png")}
              className="ml-n3"
              alt="gil white logo"
            />
          </div>
        </div>
        <div className="position-relative flex-center mx-n4">
          <img src={require("assets/images/articles/article.jpg")} />
          <div className="position-absolute" onClick={() => setPlaying(true)}>
            <VidPlayIcon />
          </div>
        </div>
        <div className="mt-4 text-dark ios-splash-screen mx-auto py-3 px-4">
          <h4 className="text-primary display-6 font-bold text-center mb-4">
            Steps to Upload app
          </h4>
          <ol className="font-14 pl-3">
            <li>Swipe up on your iOS device</li>
            <li>Select upload</li>
            <li>Select "Add to Home Screen"</li>
            <li>Open App from your iOS device.</li>
          </ol>
        </div>
        <div className="mt-5 text-center font-italic">
          To Start <br />
          Swipe up here.
        </div>
        <div className="text-center pt-2">
          <img
            src={require("assets/images/icons/back_icon_white.svg")}
            style={{ transform: "rotate(90deg)" }}
            alt="back icon"
          />
        </div>
      </div>
    )
  ) : showDownloadPage && Utility.pwaModeEnabled() ? (
    <DownloadComplete handleButtonClick={() => setShowDownloadPage(false)} />
  ) : (
    <React.Fragment>
      {playing ? (
        paintGilVideo()
      ) : (
        <div className="gil-main-container">
          <section className="subtract-left-section gil-sec-hero">
            <HomeNavbar />
            <div className="px-4">
              <Container>
                <div className="row align-items-center mt-5">
                  <Col md={6}>
                    <h1 className="mb-sm-4 mb-2 b-700 font-70 pt-5 pt-lg-0">
                      Join Your learning Community.
                    </h1>
                    <p className="display-5 mb-sm-4 mb-3 line-divider">
                      Grow as a leader in your National Alliance, Organization
                      and Community
                    </p>
                    {!pwaModeEnabled ? (
                      <NavLink
                        to={"/users/sign_up"}
                        className="btn btn-lg text-transform-inh btn-primary mr-3 mb-3 line-height-38 b-700"
                      >
                        Join Community
                      </NavLink>
                    ) : (
                      <button
                        onClick={handleButtonClick}
                        className="btn btn-lg btn-primary btn-inverce text-transform-inh mb-3 b-700"
                      >
                        Get the app
                      </button>
                    )}
                    <div className="d-flex align-items-center play-section flex-nowrap mt-md-5 pt-5 pb-sm-4">
                      <button
                        className="btn-play btnPlayTab ml-3 mr-4 mr-md-5"
                        onClick={() => setPlaying(true)}
                      >
                        <img
                          src={require("assets/images/gil/play-icon.svg")}
                          alt="play"
                        />
                      </button>
                      <span className="play-section-text display-7 b-700 pl-5">
                        Why are we gathering
                      </span>
                    </div>
                  </Col>
                  <Col md={6} className="left-round-bg">
                    <div className="gil-mobile-view">
                      <div className="mt-0 mobile-img-1 pt-5">
                        <img
                          alt="gil-mobile"
                          className="img-fluid"
                          src={require("assets/images/gil/gil-mobile-img-1.png")}
                        />
                      </div>
                      <div className="overlay-mobile-img">
                        <img
                          className="img-fluid"
                          src={require("assets/images/gil/gil-mobile-img-2.png")}
                          alt="gil-mobile"
                        />
                      </div>
                    </div>
                  </Col>
                </div>
              </Container>
            </div>
          </section>
          <section className="subtract-right-section mt-5 journey-pasport-section">
            <Container>
              <div className="text-center py-4 py-md-5">
                <div className="gil-sub-title text-uppercase">
                  Journey Passport Benefits{" "}
                </div>
                <Col lg={7} className="px-0 mx-auto">
                  <div className="gil-title">
                    Create your journey passport to grow as a leader
                  </div>
                </Col>
              </div>
              <Row>
                <Col xs={6} md={3}>
                  <div className="journey-card-list text-center mb-4">
                    <div className="journey-icon left-radius-0 mb-4">
                      <img
                        src={require("assets/images/gil/identify-calling.svg")}
                        alt="identify calling"
                      />
                    </div>
                    <div className="display-7 b-500">Identify Calling</div>
                  </div>
                </Col>
                <Col xs={6} md={3}>
                  <div className="journey-card-list text-center mb-4">
                    <div className="journey-icon top-radius-0 mb-4">
                      <img
                        src={require("assets/images/gil/engage-mentorship.svg")}
                        alt="Engage in Mentorship"
                      />
                    </div>
                    <div className="display-7 b-500">Engage in Mentorship</div>
                  </div>
                </Col>
                <Col xs={6} md={3}>
                  <div className="journey-card-list text-center mb-4">
                    <div className="journey-icon bottom-radius-0 mb-4">
                      <img
                        src={require("assets/images/gil/learn-peers.svg")}
                        alt="Learn with Peers"
                      />
                    </div>
                    <div className="display-7 b-500">Learn with Peers</div>
                  </div>
                </Col>
                <Col xs={6} md={3}>
                  <div className="journey-card-list text-center mb-4">
                    <div className="journey-icon right-radius-0 mb-4">
                      <img
                        src={require("assets/images/gil/discover-engaging-storytelling.svg")}
                        alt="Discover Engaging Storytelling"
                      />
                    </div>
                    <div className="display-7 b-500">Share Your Story</div>
                  </div>
                </Col>
              </Row>
              <Row id="purpose" className="py-5 my-md-5">
                <Col lg={6}>
                  <div className="our-purpose-img">
                    <img
                      src={require("assets/images/gil/our-purpose.jpg")}
                      alt="Our Purpose"
                    />
                    <div className="our-purpose-caption">
                      <div className="display-7 text-center b-700">
                        Take an epic journey around the world with our
                        community.
                      </div>
                    </div>
                  </div>
                </Col>
                <Col lg={6}>
                  <div className="mb-4 pl-md-4 pt-md-5">
                    <div className="gil-sub-title text-uppercase">Purpose</div>
                    <div className="gil-title">Grow, Empower, Connect</div>
                  </div>
                  <ul className="pl-4 gil-list">
                    <li className="mb-3">
                      <b>Grow</b> in understanding and practical skills to
                      pursue your calling.
                    </li>

                    <li className="mb-3">
                      <b>Empower</b> your National Evangelical Alliance,
                      churches and members to be a greater voice and platform
                      for unity, justice and service.
                    </li>

                    <li className="mb-3">
                      <b>Connect</b> with global leaders with common interests.
                    </li>
                  </ul>
                </Col>
              </Row>
            </Container>
          </section>

          <section className="leadership-topics-sec mb-5">
            <Container className="bg-primary text-white py-5 px-4">
              <div className=" text-center mb-4">
                <div className="font-45 b-700">Leadership Topics</div>
              </div>
              <p className="mb-4 display-6 text-center col-lg-11 mx-auto">
                The Passport App is your virtual face, customized pathway, and
                ongoing personal record of learning goals and achievements
                connecting with a global community of advanced leaders in over
                150 nations..
              </p>
              <div className="text-center display-7">
                <a href="" className="text-white">
                  Unity
                </a>{" "}
                <span className="mx-2">•</span>{" "}
                <a href="" className="text-white">
                  Voice
                </a>{" "}
                <span className="mx-2">•</span>{" "}
                <a href="" className="text-white">
                  Vision
                </a>{" "}
                <span className="mx-2">•</span>{" "}
                <a href="" className="text-white">
                  Sustainability
                </a>{" "}
                <span className="mx-2">•</span>{" "}
                <a href="" className="text-white">
                  Service
                </a>{" "}
                <span className="mx-2">•</span>{" "}
                <a href="" className="text-white">
                  Leadership
                </a>{" "}
                <span className="mx-2">•</span>{" "}
                <a href="" className="text-white">
                  Partnership
                </a>{" "}
                <span className="mx-2">•</span>{" "}
                <a href="" className="text-white">
                  Community
                </a>
              </div>
              <div className="mt-4 text-center">
                {!pwaModeEnabled ? (
                  <NavLink
                    to={"/users/sign_up"}
                    className="btn btn-lg btn-white text-transform-inh btn-center btn-inverce mb-3 font-weight-bold"
                  >
                    Join Community
                  </NavLink>
                ) : (
                  <button
                    onClick={handleButtonClick}
                    className="btn btn-lg btn-primary btn-inverce text-transform-inh mb-3 b-700"
                  >
                    Get the app
                  </button>
                )}
              </div>
            </Container>
          </section>

          <section className="subtract-left-section">
            <Container>
              <div id="peerLearning" className="text-center pt-5">
                <div className="gil-sub-title text-uppercase">
                  Peer learning
                </div>
                <Col lg={7} className="px-0 mx-auto">
                  <div className="gil-title">Join Your Community</div>
                </Col>
              </div>
              <Row className="py-5">
                <Col md={6} className="mb-4">
                  <div className="peer-learning-card">
                    <img
                      src={require("assets/images/gil/peer-learning.jpg")}
                      alt="peer-learning"
                    />
                    <div className="peer-learning-content">
                      <h2 className="display-5">Peer Learning</h2>
                      <div className="text-center peer-learning-overlay">
                        <div className="p-4">
                          <p>
                            You have much you can teach your peers, and they
                            have much to teach you. Engage with like-minded
                            others in a relational, curated learning
                            environment.{" "}
                          </p>
                          <div className="row no-gutters justify-content-center">
                            {!pwaModeEnabled ? (
                              <Col md={6} className="px-1">
                                <NavLink
                                  to={"/users/sign_up"}
                                  className="btn btn-lg btn-white text-transform-inh btn-center btn-inverce mb-3 font-weight-bold"
                                >
                                  Join Community
                                </NavLink>
                              </Col>
                            ) : (
                              <Col md={6} className="px-1">
                                <button
                                  onClick={handleButtonClick}
                                  className="btn btn-lg text-transform-inh btn-white btn-center mb-3 font-weight-bold"
                                >
                                  Get the app
                                </button>
                              </Col>
                            )}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </Col>
                <Col md={6} className="mb-4">
                  <div className="peer-learning-card">
                    <img
                      src={require("assets/images/gil/mentorship.jpg")}
                      alt="Mentorship"
                    />
                    <div className="peer-learning-content">
                      <h2 className="display-5 b-500 mb-0">Mentorship</h2>
                      <div className="text-center peer-learning-overlay">
                        <div className="p-4">
                          <p>
                            Curious minds never stop learning, and that includes
                            even the most advanced leaders. Connect with the
                            skills and guidance to lead with greater
                            effectiveness.{" "}
                          </p>
                          <div className="row no-gutters justify-content-center">
                            {!pwaModeEnabled ? (
                              <Col md={6} className="px-1">
                                <NavLink
                                  to={"/users/sign_up"}
                                  className="btn btn-lg btn-white text-transform-inh btn-center btn-inverce mb-3 font-weight-bold"
                                >
                                  Join Community
                                </NavLink>
                              </Col>
                            ) : (
                              <Col md={6} className="px-1">
                                <button
                                  onClick={handleButtonClick}
                                  className="btn btn-lg text-transform-inh btn-white btn-center mb-3 font-weight-bold"
                                >
                                  Get the app
                                </button>
                              </Col>
                            )}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </Col>
                <Col md={6} className="mb-4">
                  <div className="peer-learning-card">
                    <img
                      src={require("assets/images/gil/connection.jpg")}
                      alt="Connection"
                    />
                    <div className="peer-learning-content">
                      <h2 className="display-5">Connection</h2>
                      <div className="text-center peer-learning-overlay">
                        <div className="p-4">
                          <p>
                            Find others who are on a path similar to yours.
                            Bond, learn, share, and grow with new friends in
                            Christ as you strive toward a similar goal.{" "}
                          </p>
                          <div className="row no-gutters justify-content-center">
                            {!pwaModeEnabled ? (
                              <Col md={6} className="px-1">
                                <NavLink
                                  to={"/users/sign_up"}
                                  className="btn btn-lg btn-white text-transform-inh btn-center btn-inverce mb-3 font-weight-bold"
                                >
                                  Join Community
                                </NavLink>
                              </Col>
                            ) : (
                              <Col md={6} className="px-1">
                                <button
                                  onClick={handleButtonClick}
                                  className="btn btn-lg text-transform-inh btn-white btn-center mb-3 font-weight-bold"
                                >
                                  Get the app
                                </button>
                              </Col>
                            )}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </Col>
                <Col md={6} className="mb-4">
                  <div className="peer-learning-card">
                    <img
                      src={require("assets/images/gil/leadership.jpg")}
                      alt="Leadership"
                    />
                    <div className="peer-learning-content">
                      <h2 className="display-5">Leadership</h2>
                      <div className="text-center peer-learning-overlay">
                        <div className="p-4">
                          <p>
                            Grow in your leadership capabilities, and guide your
                            peers through their own progression and journey.{" "}
                          </p>
                          <div className="row no-gutters justify-content-center">
                            {!pwaModeEnabled ? (
                              <Col md={6} className="px-1">
                                <NavLink
                                  to={"/users/sign_up"}
                                  className="btn btn-lg btn-white text-transform-inh btn-center btn-inverce mb-3 font-weight-bold"
                                >
                                  Join Community
                                </NavLink>
                              </Col>
                            ) : (
                              <Col md={6} className="px-1">
                                <button
                                  onClick={handleButtonClick}
                                  className="btn btn-lg text-transform-inh btn-white btn-center mb-3 font-weight-bold"
                                >
                                  Get the app
                                </button>
                              </Col>
                            )}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </Col>
              </Row>
            </Container>
          </section>
          <section className="our-member-section py-5">
            <Container>
              <div id="members" className="text-center pt-5 mb-5">
                <div className="gil-sub-title text-uppercase">Members</div>
                <Col lg={12} className="px-0 mx-auto">
                  {/* <div className="gil-title">Discover who is all part of our Journey Passport community</div> */}
                  <div className="gil-title-small">
                    Discover who is part of the Journey Passport Community.
                  </div>
                </Col>
              </div>
              <OwlCarousel
                className="owl-theme dot-bg"
                items={4}
                loop
                margin={10}
                nav
                responsive={{
                  0: {
                    items: 1,
                  },
                  600: {
                    items: 2,
                  },
                  768: {
                    items: 2,
                  },
                  1000: {
                    items: 4,
                  },
                }}
              >
                <div className="journey-card-list text-center mb-4 item">
                  <div className="journey-icon left-radius-0 mb-4">
                    <img
                      src={require("assets/images/gil/member1.jpg")}
                      alt=""
                    />
                  </div>
                  <div className="display-7 b-500">Amanda Jackson</div>
                </div>
                <div className="journey-card-list text-center mb-4 item">
                  <div className="journey-icon top-radius-0 mb-4">
                    <img
                      src={require("assets/images/gil/member2.jpg")}
                      alt="John Smith"
                    />
                  </div>
                  <div className="display-7 b-500">Brian Stiller</div>
                </div>
                <div className="journey-card-list text-center mb-4 item">
                  <div className="journey-icon bottom-radius-0 mb-4">
                    <img
                      src={require("assets/images/gil/member3.jpg")}
                      alt="Learn with Peers"
                    />
                  </div>
                  <div className="display-7 b-500">Efraim Tendero</div>
                </div>
                <div className="journey-card-list text-center mb-4 item">
                  <div className="journey-icon right-radius-0 mb-4">
                    <img
                      src={require("assets/images/gil/member4.jpg")}
                      alt=""
                    />
                  </div>
                  <div className="display-7 b-500">Emerson Boyce</div>
                </div>
                <div className="journey-card-list text-center mb-4 item">
                  <div className="journey-icon left-radius-0 mb-4">
                    <img
                      src={require("assets/images/gil/member5.jpg")}
                      alt=""
                    />
                  </div>
                  <div className="display-7 b-500">Esme Bowers</div>
                </div>
                <div className="journey-card-list text-center mb-4 item">
                  <div className="journey-icon top-radius-0 mb-4">
                    <img
                      src={require("assets/images/gil/member6.jpg")}
                      alt="John Smith"
                    />
                  </div>
                  <div className="display-7 b-500">Godfrey Yogarajah</div>
                </div>
                <div className="journey-card-list text-center mb-4 item">
                  <div className="journey-icon bottom-radius-0 mb-4">
                    <img
                      src={require("assets/images/gil/member7.jpg")}
                      alt="Learn with Peers"
                    />
                  </div>
                  <div className="display-7 b-500">Thomas Bucher</div>
                </div>
                <div className="journey-card-list text-center mb-4 item">
                  <div className="journey-icon right-radius-0 mb-4">
                    <img
                      src={require("assets/images/gil/member8.jpg")}
                      alt=""
                    />
                  </div>
                  <div className="display-7 b-500">Tom Correll</div>
                </div>
              </OwlCarousel>
            </Container>
          </section>

          <section className="subtract-right-section upcoming-event-sec px-4 pt-5 py-lg-5">
            <Container>
              <div id="upcomingEvents" className="text-center pt-5 mb-4">
                <div className="gil-title">Upcoming Events</div>
              </div>
              <div>
                {Utility.isEmpty(publicEventList) && (
                  <div className="d-flex align-items-center justify-content-center">
                    <div className="px-5">
                      <span className="font-bold display-6">
                        No events found
                      </span>
                    </div>
                  </div>
                )}
                {paintPublicEvents()}
              </div>
            </Container>
            <Container id="partners">
              <div className="text-center pt-5">
                <div className="gil-title">Partners</div>
              </div>
              <div className="pt-5">
                <Row className="partner-images text-center">
                  <Col className="col-md-4 col-12 mb-5">
                    <img
                      src={require("assets/images/city_specific_images/lausanne.svg")}
                      alt="Our Purpose"
                    />
                    <div className="mt-2 display-8 b-600">
                      Lausanne Movement
                    </div>
                  </Col>
                  <Col className="col-md-4 col-12 mb-5">
                    <img
                      src={require("assets/images/city_specific_images/TOPIC.jpg")}
                      alt="Our Purpose"
                    />
                    <div className="mt-2 display-8 b-600">TOPIC</div>
                  </Col>
                  <Col className="col-md-4 col-12 mb-5">
                    <img
                      src={require("assets/images/city_specific_images/etd.jpg")}
                      alt="Purpose"
                    />
                    <div className="mt-2 display-8 b-600">
                      Evangelical Training Directory
                    </div>
                  </Col>

                  <Col className="col-md-4 col-12 mb-5">
                    <img
                      src={require("assets/images/city_specific_images/BGU.png")}
                      alt="Our Purpose"
                    />
                    <div className="mt-2 display-8 b-600">
                      Bakke Graduate University
                    </div>
                  </Col>
                  <Col className="col-md-4 col-12 mb-5">
                    <img
                      className="bg-primary p-2"
                      src={require("assets/images/city_specific_images/IACL.png")}
                      alt="Our Purpose"
                    />
                    <div className="mt-2 display-8 b-600">ICAL</div>
                  </Col>
                  <Col className="col-md-4 col-12 mb-5">
                    <img
                      className="bg-primary p-2"
                      src={require("assets/images/city_specific_images/hendricks.png")}
                      alt="Our Purpose"
                    />
                    <div className="mt-2 display-8 b-600">
                      The Hendricks Center
                    </div>
                  </Col>
                </Row>
              </div>
            </Container>
          </section>
          <section className="subtract-left-section pt-md-5 our-gil-section">
            <Container>
              <Row id="about" className="py-5">
                <Col lg={6}>
                  <div className="mb-4 pr-md-4 pt-md-5">
                    {/* <div className="gil-sub-title text-uppercase"></div> */}
                    <div className="gil-title">About Journey Passport</div>
                  </div>
                  <div className="mb-4 display-8 line-height-5 b-300 pr-md-5">
                    <p>
                      The Journey Passport App connects leaders through events,
                      coaching and peer learning both virtually and
                      face-to-face, the Journey Passport provides individually
                      tailored pathways to leadership growth. With resources for
                      National Evangelical Alliance leaders, members, and any
                      advanced leader who wants to connect to other global
                      Christian leaders, the Journey Passport serves the whole
                      body of Christ.
                    </p>
                    <p>
                      Each person develops their own extensive profile including
                      growth goals and personal expertise. Each person has
                      opportunities to create or join learning communities of
                      common interest, geography or projects. Each person can
                      create and share stories of what they see God doing in
                      their community for others to learn from. Degree and
                      non-formal training programs are available to provide
                      extended leadership development all connected by specific
                      community dialogues.
                    </p>
                    <p>
                      The Journey Passport provides a virtual learning
                      experience to support a global evangelical community
                      through theWorld Evangelical Alliance (WEA), which
                      includes 9 Regional Alliances, 130+ National Evangelical
                      Alliances, key area Commisions and Program and Affiliates
                      and Partner Networks; and the Global Instititute of
                      Leadership (GIL) whose purpose is to strengthen leaders to
                      bring about national, social, and spiritual
                      transformation.
                    </p>
                  </div>
                </Col>
                <Col lg={6}>
                  <div className="our-purpose-img">
                    <img
                      src={require("assets/images/gil/historygil.jpg")}
                      alt="Our Purpose"
                    />
                    <div className="our-purpose-caption">
                      <div className="display-7 text-center b-700">
                        Journey Passport is designed to connect 650 <br />{" "}
                        million Christians in 130 countries
                      </div>
                    </div>
                  </div>
                </Col>
              </Row>
            </Container>
          </section>
          <section className="bg-light py-5">
            <div className="container">
              <div class="gil-sub-title text-uppercase">Mission and Vision</div>
              {/* <div class="gil-title mb-5">National Impact through the GIL:</div> */}
              <div className="row mb-lg-5 vimeo-links-row">
                <div className="col-md-3 mb-4 mb-md-0">
                  <div className="peer-learning-card vimeo-video-link flex-center">
                    <img
                      src={require("assets/images/gil/peer-learning.jpg")}
                      className="vimeo-video-img"
                      alt=""
                    />
                    <div className="peer-learning-content">
                      <h2 className="display-6">Introduction</h2>
                      <div className="text-center peer-learning-overlay">
                        <div className="row no-gutters justify-content-center">
                          <a
                            className="btn btn-white font-weight-bold mt-3"
                            href="https://vimeo.com/375433167"
                            target="_blank"
                          >
                            Watch Video
                          </a>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="col-md-3 mb-4 mb-md-0">
                  <div className="peer-learning-card vimeo-video-link flex-center">
                    <img
                      src={require("assets/images/gil/leadership.jpg")}
                      className="vimeo-video-img"
                      alt=""
                    />
                    <div className="peer-learning-content">
                      <h2 className="display-6">Community</h2>
                      <div className="text-center peer-learning-overlay">
                        <div className="row no-gutters justify-content-center">
                          <a
                            className="btn btn-white font-weight-bold mt-3"
                            href="https://vimeo.com/375432761"
                            target="_blank"
                          >
                            Watch Video
                          </a>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="col-md-3 mb-4 mb-md-0">
                  <div className="peer-learning-card vimeo-video-link flex-center">
                    <img
                      src={require("assets/images/gil/connection.jpg")}
                      className="vimeo-video-img"
                      alt=""
                    />
                    <div class="peer-learning-content">
                      <h2 class="display-6">Unity</h2>
                      <div class="text-center peer-learning-overlay">
                        <div class="row no-gutters justify-content-center">
                          <a
                            class="btn btn-white font-weight-bold mt-3"
                            href="https://vimeo.com/375432942"
                            target="_blank"
                          >
                            Watch Video
                          </a>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="col-md-3 mb-4 mb-md-0">
                  <div className="peer-learning-card vimeo-video-link flex-center">
                    <img
                      src={require("assets/images/gil/mentorship.jpg")}
                      className="vimeo-video-img"
                      alt=""
                    />
                    <div class="peer-learning-content">
                      <h2 class="display-6">Voice</h2>
                      <div class="text-center peer-learning-overlay">
                        <div class="row no-gutters justify-content-center">
                          <a
                            class="btn btn-white font-weight-bold mt-3"
                            href="https://vimeo.com/375433034"
                            target="_blank"
                          >
                            Watch Video
                          </a>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
          <section className="leadership-topics-sec px-4 px-md-0 mb-5 pb-md-5">
            <Container className="bg-primary text-white py-5 px-4">
              <div className=" text-center mb-4">
                <div className="font-45 b-700">
                  Subscribe to Journey Passport
                </div>
              </div>
              <div className="text-center display-7">
                Sign up for our newsletter to hear all about the work that our
                community is accomplishing worldwide.
              </div>
              <div className="mt-4 text-center newsletter-panel">
                <div className="input-group col-12 col-lg-8 col-xl-6 mx-auto">
                  <input
                    type="email"
                    className="form-control display-8"
                    placeholder="Your email"
                  />
                  <div className="input-group-btn">
                    <button
                      className="btn btn-white btn-center b-700"
                      type="submit"
                    >
                      Subscribe
                    </button>
                  </div>
                </div>
              </div>
            </Container>
          </section>

          <WeaFooter />
        </div>
      )}
    </React.Fragment>
  );
}
