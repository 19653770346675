export const allNews = [
    {
        "id": "30bb2620-e622-11eb-886f-397cd7cd2e86",
        "author_name": "Himanshu Bisht",
        "user_client_id": "da44aec1-8e0f-11eb-8803-a97811f03dc2",
        "author_image_url": null,
        "profile_img": "https://sharemeister-crm-dev.s3.us-west-2.amazonaws.com/client/0bc8dc7c-b5a3-4344-b313-819fc640f6b5/1625466702943logo_url",
        "client_id": "0bc8dc7c-b5a3-4344-b313-819fc640f6b5",
        "description": "<p>This is a new article.</p>",
        "ranking": 4,
        "created_at": "2021-07-16T10:40:08.581Z",
        "updated_at": "2022-03-31T10:58:08.150Z",
        "campaign_id": "30bb2620-e622-11eb-886f-397cd7cd2e86",
        "image_url": "https://sharemeister-crm-dev.s3.us-west-2.amazonaws.com/campaign_media/30bb2620-e622-11eb-886f-397cd7cd2e86/1626432008588banner_image",
        "embedded_image_link": "",
        "embedded_video_link": "",
        "video_url": "",
        "document_url": "",
        "heading": "Article44",
        "sub_heading": "New title",
        "priority": 0,
        "location": "",
        "is_headline": false,
        "resource_email": null,
        "resource_name": null,
        "author_email": "himanshu.bisht@ksolves.com",
        "sub_campaign_count": [],
        "campaign_category": "South Pacific",
        "campaign_category_id": "dc3a09b7-eecd-415a-9ac4-63321d3zziab",
        "category_color": "#b3c733",
        "comments_count": "0",
        "smiles_count": "0"
    },
    {
        "id": "602a5c00-c6d3-11eb-ab3c-75c237895579",
        "author_name": "Aman Jain",
        "user_client_id": "190b85b0-9924-11eb-9c53-1b40e4c04975",
        "author_image_url": "https://sharemeister-crm-dev.s3.us-west-2.amazonaws.com/user_media/190b85b0-9924-11eb-9c53-1b40e4c04975/1622712265531image",
        "profile_img": "https://sharemeister-crm-dev.s3.us-west-2.amazonaws.com/client/0bc8dc7c-b5a3-4344-b313-819fc640f6b5/1625466702943logo_url",
        "client_id": "0bc8dc7c-b5a3-4344-b313-819fc640f6b5",
        "description": "<p>&nbsp;&nbsp;</p>\n",
        "ranking": 10,
        "created_at": "2021-06-06T14:27:51.875Z",
        "updated_at": "2021-06-06T14:28:06.875Z",
        "campaign_id": "602a5c00-c6d3-11eb-ab3c-75c237895579",
        "image_url": "https://sharemeister-crm-dev.s3.us-west-2.amazonaws.com/campaign_media/602a5c00-c6d3-11eb-ab3c-75c237895579/1622989671884banner_image",
        "embedded_image_link": "",
        "embedded_video_link": "",
        "video_url": "",
        "document_url": "",
        "heading": "Article A",
        "sub_heading": "Chapter one",
        "priority": 0,
        "location": "",
        "is_headline": false,
        "resource_email": null,
        "resource_name": null,
        "author_email": "himanshu.bisht+1@ksolves.com",
        "sub_campaign_count": [],
        "campaign_category": "South Pacific",
        "campaign_category_id": "dc3a09b7-eecd-415a-9ac4-63321d3zziab",
        "category_color": "#b3c733",
        "comments_count": "0",
        "smiles_count": "0"
    },
    {
        "id": "de97bac0-cdc1-11eb-86a8-2b2a91997475",
        "author_name": "Ashu Verma",
        "user_client_id": "7dbabe91-c21e-11eb-971b-f54639f85d8a",
        "author_image_url": null,
        "profile_img": "https://sharemeister-crm-dev.s3.us-west-2.amazonaws.com/client/0bc8dc7c-b5a3-4344-b313-819fc640f6b5/1625466702943logo_url",
        "client_id": "0bc8dc7c-b5a3-4344-b313-819fc640f6b5",
        "description": "<p>This is a new article.</p>\n",
        "ranking": 10,
        "created_at": "2021-06-15T10:10:11.184Z",
        "updated_at": "2021-06-15T10:10:19.869Z",
        "campaign_id": "de97bac0-cdc1-11eb-86a8-2b2a91997475",
        "image_url": "",
        "embedded_image_link": "https://homepages.cae.wisc.edu/~ece533/images/watch.png",
        "embedded_video_link": "",
        "video_url": "https://sharemeister-crm-dev.s3.us-west-2.amazonaws.com/campaign_media/de97bac0-cdc1-11eb-86a8-2b2a91997475/1623751811194banner_video",
        "document_url": "",
        "heading": "Article5n",
        "sub_heading": "Title one",
        "priority": 0,
        "location": "",
        "is_headline": false,
        "resource_email": null,
        "resource_name": null,
        "author_email": "himanshu.bisht+8@ksolves.com",
        "sub_campaign_count": [],
        "campaign_category": "Central Asia",
        "campaign_category_id": "dc3a09b1-eecd-425a-9dc4-63321dlagceb",
        "category_color": "#08a8f4",
        "comments_count": "0",
        "smiles_count": "0"
    },
    {
        "id": "ef4ccca0-d99e-11eb-9dee-33919b7fca72",
        "author_name": "Aman Jain",
        "user_client_id": "190b85b0-9924-11eb-9c53-1b40e4c04975",
        "author_image_url": "https://sharemeister-crm-dev.s3.us-west-2.amazonaws.com/user_media/190b85b0-9924-11eb-9c53-1b40e4c04975/1622712265531image",
        "profile_img": "https://sharemeister-crm-dev.s3.us-west-2.amazonaws.com/client/0bc8dc7c-b5a3-4344-b313-819fc640f6b5/1625466702943logo_url",
        "client_id": "0bc8dc7c-b5a3-4344-b313-819fc640f6b5",
        "description": "<p>&nbsp;&nbsp;&nbsp;</p>\n",
        "ranking": 5,
        "created_at": "2021-06-30T12:30:20.781Z",
        "updated_at": "2022-04-04T11:31:45.648Z",
        "campaign_id": "ef4ccca0-d99e-11eb-9dee-33919b7fca72",
        "image_url": "https://sharemeister-crm-dev.s3.us-west-2.amazonaws.com/campaign_media/ef4ccca0-d99e-11eb-9dee-33919b7fca72/1625056220789banner_image",
        "embedded_image_link": "",
        "embedded_video_link": "",
        "video_url": "",
        "document_url": "",
        "heading": "Check article",
        "sub_heading": "Chapter one",
        "priority": 0,
        "location": "",
        "is_headline": false,
        "resource_email": null,
        "resource_name": null,
        "author_email": "himanshu.bisht+1@ksolves.com",
        "sub_campaign_count": [],
        "campaign_category": "Africa",
        "campaign_category_id": "dc3a09b7-eecd-265a-9dc4-633007b0081b",
        "category_color": "#9906eb",
        "comments_count": "0",
        "smiles_count": "0"
    },
    {
        "id": "af2cb9b0-de61-11eb-8b17-7574749e3e4d",
        "author_name": "Himanshu Bisht",
        "user_client_id": "da44aec1-8e0f-11eb-8803-a97811f03dc2",
        "author_image_url": null,
        "profile_img": "https://sharemeister-crm-dev.s3.us-west-2.amazonaws.com/client/0bc8dc7c-b5a3-4344-b313-819fc640f6b5/1625466702943logo_url",
        "client_id": "0bc8dc7c-b5a3-4344-b313-819fc640f6b5",
        "description": "<p>&nbsp;</p>\n",
        "ranking": 2,
        "created_at": "2021-07-06T13:54:29.718Z",
        "updated_at": "2022-03-31T11:56:51.395Z",
        "campaign_id": "af2cb9b0-de61-11eb-8b17-7574749e3e4d",
        "image_url": "https://sharemeister-crm-dev.s3.us-west-2.amazonaws.com/campaign_media/af2cb9b0-de61-11eb-8b17-7574749e3e4d/1625587684654banner_image",
        "embedded_image_link": "",
        "embedded_video_link": "",
        "video_url": "",
        "document_url": "",
        "heading": "Article5654",
        "sub_heading": "Chapter one",
        "priority": 0,
        "location": "",
        "is_headline": false,
        "resource_email": null,
        "resource_name": null,
        "author_email": "himanshu.bisht@ksolves.com",
        "sub_campaign_count": [],
        "campaign_category": "North America",
        "campaign_category_id": "dc3a09b7-eecd-415a-9dc4-63321daeu1eb",
        "category_color": "#1d4d19",
        "comments_count": "0",
        "smiles_count": "0"
    },
    {
        "id": "64db85e0-de6a-11eb-8b17-7574749e3e4d",
        "author_name": "Himanshu Bisht",
        "user_client_id": "da44aec1-8e0f-11eb-8803-a97811f03dc2",
        "author_image_url": null,
        "profile_img": "https://sharemeister-crm-dev.s3.us-west-2.amazonaws.com/client/0bc8dc7c-b5a3-4344-b313-819fc640f6b5/1625466702943logo_url",
        "client_id": "0bc8dc7c-b5a3-4344-b313-819fc640f6b5",
        "description": "<p>&nbsp;&nbsp;</p>\n",
        "ranking": 2,
        "created_at": "2021-07-06T14:56:50.503Z",
        "updated_at": "2022-03-31T11:56:46.530Z",
        "campaign_id": "64db85e0-de6a-11eb-8b17-7574749e3e4d",
        "image_url": "https://sharemeister-crm-dev.s3.us-west-2.amazonaws.com/campaign_media/64db85e0-de6a-11eb-8b17-7574749e3e4d/1625633733694banner_image",
        "embedded_image_link": "",
        "embedded_video_link": "",
        "video_url": "",
        "document_url": "",
        "heading": "Article s23",
        "sub_heading": "Title one",
        "priority": 0,
        "location": "",
        "is_headline": false,
        "resource_email": null,
        "resource_name": null,
        "author_email": "himanshu.bisht@ksolves.com",
        "sub_campaign_count": [],
        "campaign_category": "North America",
        "campaign_category_id": "dc3a09b7-eecd-415a-9dc4-63321daeu1eb",
        "category_color": "#1d4d19",
        "comments_count": "0",
        "smiles_count": "0"
    },
    {
        "id": "cf18a720-da6a-11eb-9dee-33919b7fca72",
        "author_name": "Naman Kumar",
        "user_client_id": "8f28c9e1-c79d-11eb-893e-0fb1ef4c3ca8",
        "author_image_url": null,
        "profile_img": "https://sharemeister-crm-dev.s3.us-west-2.amazonaws.com/client/0bc8dc7c-b5a3-4344-b313-819fc640f6b5/1625466702943logo_url",
        "client_id": "0bc8dc7c-b5a3-4344-b313-819fc640f6b5",
        "description": "<p>&nbsp;&nbsp;</p>\n",
        "ranking": 4,
        "created_at": "2021-07-01T12:49:44.092Z",
        "updated_at": "2022-03-14T07:34:56.203Z",
        "campaign_id": "cf18a720-da6a-11eb-9dee-33919b7fca72",
        "image_url": "https://sharemeister-crm-dev.s3.us-west-2.amazonaws.com/campaign_media/cf18a720-da6a-11eb-9dee-33919b7fca72/1625143784101banner_image",
        "embedded_image_link": "",
        "embedded_video_link": "",
        "video_url": "",
        "document_url": "",
        "heading": "New chat article",
        "sub_heading": "Chapter one",
        "priority": 0,
        "location": "",
        "is_headline": false,
        "resource_email": null,
        "resource_name": null,
        "author_email": "himanshu.bisht+12@ksolves.com",
        "sub_campaign_count": [],
        "campaign_category": "North America",
        "campaign_category_id": "dc3a09b7-eecd-415a-9dc4-63321daeu1eb",
        "category_color": "#1d4d19",
        "comments_count": "0",
        "smiles_count": "0"
    },
    {
        "id": "14d5aee0-de6a-11eb-8b17-7574749e3e4d",
        "author_name": "Himanshu Bisht",
        "user_client_id": "da44aec1-8e0f-11eb-8803-a97811f03dc2",
        "author_image_url": null,
        "profile_img": "https://sharemeister-crm-dev.s3.us-west-2.amazonaws.com/client/0bc8dc7c-b5a3-4344-b313-819fc640f6b5/1625466702943logo_url",
        "client_id": "0bc8dc7c-b5a3-4344-b313-819fc640f6b5",
        "description": "<p>&nbsp;</p>\n",
        "ranking": 10,
        "created_at": "2021-07-06T14:54:36.250Z",
        "updated_at": "2021-07-06T14:54:46.200Z",
        "campaign_id": "14d5aee0-de6a-11eb-8b17-7574749e3e4d",
        "image_url": "https://sharemeister-crm-dev.s3.us-west-2.amazonaws.com/campaign_media/14d5aee0-de6a-11eb-8b17-7574749e3e4d/1625583276263banner_image",
        "embedded_image_link": "",
        "embedded_video_link": "",
        "video_url": "",
        "document_url": "",
        "heading": "Article5611",
        "sub_heading": "Title one",
        "priority": 0,
        "location": "",
        "is_headline": false,
        "resource_email": null,
        "resource_name": null,
        "author_email": "himanshu.bisht@ksolves.com",
        "sub_campaign_count": [],
        "campaign_category": "North America",
        "campaign_category_id": "dc3a09b7-eecd-415a-9dc4-63321daeu1eb",
        "category_color": "#1d4d19",
        "comments_count": "0",
        "smiles_count": "0"
    },
    {
        "id": "8e8abdc0-325c-11ec-a557-25420fddb59b",
        "author_name": "Bru Nescafe",
        "user_client_id": "eaddc381-30a2-11ec-a439-c31c565445db",
        "author_image_url": null,
        "profile_img": "https://sharemeister-crm-dev.s3.us-west-2.amazonaws.com/client/0bc8dc7c-b5a3-4344-b313-819ppzhau6b5/1642672696207logo_url",
        "client_id": "0bc8dc7c-b5a3-4344-b313-819ppzhau6b5",
        "description": "<p>sdjnfjksdf iushjsdffgjs fuifyudgf s sdaufafuy fuyzgds hgsdcudsfuciufayevfgdsv  gieawfv ew efiaf</p>",
        "ranking": 3,
        "created_at": "2021-10-21T10:49:25.156Z",
        "updated_at": "2022-04-01T09:11:31.216Z",
        "campaign_id": "8e8abdc0-325c-11ec-a557-25420fddb59b",
        "image_url": "https://sharemeister-crm-dev.s3.us-west-2.amazonaws.com/campaign_media/8e8abdc0-325c-11ec-a557-25420fddb59b/1634813365166banner_image",
        "embedded_image_link": "",
        "embedded_video_link": "",
        "video_url": "",
        "document_url": "",
        "heading": "asknadasDausjbc jhbxu sid wagfdsaj fsdfausfs",
        "sub_heading": "cjksdc",
        "priority": 0,
        "location": "",
        "is_headline": false,
        "resource_email": null,
        "resource_name": null,
        "author_email": "asw66456@cuoly.com",
        "sub_campaign_count": [],
        "campaign_category": "MiddleEast",
        "campaign_category_id": "dc3a09b7-eecd-465a-9dc4-63325mmhg7eb",
        "category_color": "#f48608",
        "comments_count": "0",
        "smiles_count": "0"
    },
    {
        "id": "3db89530-8d4c-11eb-adef-b7a3afc95859",
        "author_name": "GIL",
        "user_client_id": "cc932351-74bc-11ea-950b-71c6872dc7d9",
        "author_image_url": "https://sharemeister-crm-dev.s3.us-west-2.amazonaws.com/client/0bc8dc7c-b5a3-4344-b313-819fc640f6b5/1625466702943logo_url",
        "profile_img": "https://sharemeister-crm-dev.s3.us-west-2.amazonaws.com/client/0bc8dc7c-b5a3-4344-b313-819fc640f6b5/1625466702943logo_url",
        "client_id": "0bc8dc7c-b5a3-4344-b313-819fc640f6b5",
        "description": "<p>Testing 5</p>",
        "ranking": 3,
        "created_at": "2021-03-25T09:26:55.759Z",
        "updated_at": "2022-04-04T11:30:40.322Z",
        "campaign_id": "3db89530-8d4c-11eb-adef-b7a3afc95859",
        "image_url": "https://sharemeister-crm-dev.s3.us-west-2.amazonaws.com/campaign_media/3db89530-8d4c-11eb-adef-b7a3afc95859/1616664415775banner_image",
        "embedded_image_link": "",
        "embedded_video_link": "",
        "video_url": "",
        "document_url": "",
        "heading": "Test1",
        "sub_heading": "test",
        "priority": 0,
        "location": "",
        "is_headline": false,
        "resource_email": null,
        "resource_name": null,
        "author_email": "owner@gil.com",
        "sub_campaign_count": [],
        "campaign_category": "Asia",
        "campaign_category_id": "dc3a01b7-eacd-465a-9dc4-61121llbzzeb",
        "category_color": "#31e914",
        "comments_count": "0",
        "smiles_count": "0"
    },
    {
        "id": "68a8b200-9933-11eb-9c53-1b40e4c04975",
        "author_name": "Aman Jain",
        "user_client_id": "190b85b0-9924-11eb-9c53-1b40e4c04975",
        "author_image_url": "https://sharemeister-crm-dev.s3.us-west-2.amazonaws.com/user_media/190b85b0-9924-11eb-9c53-1b40e4c04975/1622712265531image",
        "profile_img": "https://sharemeister-crm-dev.s3.us-west-2.amazonaws.com/client/0bc8dc7c-b5a3-4344-b313-819fc640f6b5/1625466702943logo_url",
        "client_id": "0bc8dc7c-b5a3-4344-b313-819fc640f6b5",
        "description": "<p><span style=\"color: rgb(89,88,89);font-size: 16px;font-family: proxima-nova, \"Helvetica Neue\", helvetica, arial, sans-serif;\">As the COVID-19 virus made its way throughout the United States, testing kits were distributed equally among labs across the 50 states, without consideration of population density or actual needs for testing in those states.</span>&nbsp;&nbsp;</p>\n",
        "ranking": 10,
        "created_at": "2021-04-09T12:59:24.329Z",
        "updated_at": "2021-06-07T13:22:27.890Z",
        "campaign_id": "68a8b200-9933-11eb-9c53-1b40e4c04975",
        "image_url": "https://sharemeister-crm-dev.s3.us-west-2.amazonaws.com/campaign_media/68a8b200-9933-11eb-9c53-1b40e4c04975/1617973164338banner_image",
        "embedded_image_link": "",
        "embedded_video_link": "",
        "video_url": "",
        "document_url": "https://sharemeister-crm-dev.s3.us-west-2.amazonaws.com/campaign_media/68a8b200-9933-11eb-9c53-1b40e4c04975/1617973164341banner_document",
        "heading": "Article one",
        "sub_heading": "Title1",
        "priority": 0,
        "location": "",
        "is_headline": false,
        "resource_email": null,
        "resource_name": null,
        "author_email": "himanshu.bisht+1@ksolves.com",
        "sub_campaign_count": [],
        "campaign_category": "Europe",
        "campaign_category_id": "dc3a09b7-qqcd-461a-9ac4-6332a61187eb",
        "category_color": "#e9142d",
        "comments_count": "0",
        "smiles_count": "0"
    },
    {
        "id": "5c58f310-a370-11eb-b2d1-07e90fe25379",
        "author_name": "Gil Citizen ",
        "user_client_id": "4d883f93-a5b0-4d66-be12-4142e01aa776",
        "author_image_url": "https://sharemeister-crm-dev.s3.us-west-2.amazonaws.com/user_media/4d883f93-a5b0-4d66-be12-4142e01aa776/1619607274055image",
        "profile_img": "https://sharemeister-crm-dev.s3.us-west-2.amazonaws.com/client/0bc8dc7c-b5a3-4344-b313-819fc640f6b5/1625466702943logo_url",
        "client_id": "0bc8dc7c-b5a3-4344-b313-819fc640f6b5",
        "description": "<p>my first article</p>\n",
        "ranking": 10,
        "created_at": "2021-04-22T13:40:54.603Z",
        "updated_at": "2021-06-02T08:29:31.129Z",
        "campaign_id": "5c58f310-a370-11eb-b2d1-07e90fe25379",
        "image_url": "https://sharemeister-crm-dev.s3.us-west-2.amazonaws.com/campaign_media/5c58f310-a370-11eb-b2d1-07e90fe25379/1619098854613banner_image",
        "embedded_image_link": "",
        "embedded_video_link": "",
        "video_url": "",
        "document_url": "",
        "heading": "Article to understand the process",
        "sub_heading": "GIL Citizen and neighbour",
        "priority": 0,
        "location": "",
        "is_headline": false,
        "resource_email": null,
        "resource_name": null,
        "author_email": "citizen@gil.com",
        "sub_campaign_count": [],
        "campaign_category": "South Pacific",
        "campaign_category_id": "dc3a09b7-eecd-415a-9ac4-63321d3zziab",
        "category_color": "#b3c733",
        "comments_count": "0",
        "smiles_count": "0"
    },
    {
        "id": "881ed5d0-a377-11eb-b2d1-07e90fe25379",
        "author_name": "GIL Neighbour",
        "user_client_id": "ac8bd4e0-a36d-11eb-b2d1-07e90fe25379",
        "author_image_url": null,
        "profile_img": null,
        "client_id": "ac8badd0-a36d-11eb-b2d1-07e90fe25379",
        "description": "<p>qwqd rvevd</p>\n",
        "ranking": 10,
        "created_at": "2021-04-22T14:32:14.514Z",
        "updated_at": "2021-06-10T07:16:42.046Z",
        "campaign_id": "881ed5d0-a377-11eb-b2d1-07e90fe25379",
        "image_url": "https://sharemeister-crm-dev.s3.us-west-2.amazonaws.com/campaign_media/881ed5d0-a377-11eb-b2d1-07e90fe25379/1623309338161banner_image",
        "embedded_image_link": "fbf.com",
        "embedded_video_link": "",
        "video_url": "",
        "document_url": "",
        "heading": "GIL Neighbour article ",
        "sub_heading": "qwqwq",
        "priority": 0,
        "location": "",
        "is_headline": false,
        "resource_email": null,
        "resource_name": null,
        "author_email": "emilyrosewell24@gmail.com",
        "sub_campaign_count": [],
        "campaign_category": "Asia",
        "campaign_category_id": "dc3a01b7-eacd-465a-9dc4-61121llbzzeb",
        "category_color": "#31e914",
        "comments_count": "0",
        "smiles_count": "0"
    },
    {
        "id": "33097350-b166-11eb-ae18-ad4bb1f53c3a",
        "author_name": "Aman Jain",
        "user_client_id": "190b85b0-9924-11eb-9c53-1b40e4c04975",
        "author_image_url": "https://sharemeister-crm-dev.s3.us-west-2.amazonaws.com/user_media/190b85b0-9924-11eb-9c53-1b40e4c04975/1622712265531image",
        "profile_img": "https://sharemeister-crm-dev.s3.us-west-2.amazonaws.com/client/0bc8dc7c-b5a3-4344-b313-819fc640f6b5/1625466702943logo_url",
        "client_id": "0bc8dc7c-b5a3-4344-b313-819fc640f6b5",
        "description": "<p>&nbsp;&nbsp;&nbsp;</p>\n",
        "ranking": 10,
        "created_at": "2021-05-10T08:03:26.609Z",
        "updated_at": "2021-05-10T08:03:30.450Z",
        "campaign_id": "33097350-b166-11eb-ae18-ad4bb1f53c3a",
        "image_url": "https://sharemeister-crm-dev.s3.us-west-2.amazonaws.com/campaign_media/33097350-b166-11eb-ae18-ad4bb1f53c3a/1620633806625banner_image",
        "embedded_image_link": "",
        "embedded_video_link": "",
        "video_url": "",
        "document_url": "",
        "heading": "Africa article",
        "sub_heading": "New Africa",
        "priority": 0,
        "location": "",
        "is_headline": false,
        "resource_email": null,
        "resource_name": null,
        "author_email": "himanshu.bisht+1@ksolves.com",
        "sub_campaign_count": [],
        "campaign_category": "Africa",
        "campaign_category_id": "dc3a09b7-eecd-265a-9dc4-633007b0081b",
        "category_color": "#9906eb",
        "comments_count": "0",
        "smiles_count": "0"
    },
    {
        "id": "4a2c7300-b87a-11eb-967b-13b5e3f3e976",
        "author_name": "Nitin Kumar",
        "user_client_id": "ee97cd02-993e-11eb-9c53-1b40e4c04975",
        "author_image_url": "https://sharemeister-crm-dev.s3.us-west-2.amazonaws.com/client/ee97cd00-993e-11eb-9c53-1b40e4c04975/1617978551713logo_url",
        "profile_img": "https://sharemeister-crm-dev.s3.us-west-2.amazonaws.com/client/ee97cd00-993e-11eb-9c53-1b40e4c04975/1617978551713logo_url",
        "client_id": "ee97cd00-993e-11eb-9c53-1b40e4c04975",
        "description": "<p>xdgfd</p>\n",
        "ranking": 10,
        "created_at": "2021-05-19T08:14:53.499Z",
        "updated_at": "2021-05-19T08:14:56.920Z",
        "campaign_id": "4a2c7300-b87a-11eb-967b-13b5e3f3e976",
        "image_url": "https://sharemeister-crm-dev.s3.us-west-2.amazonaws.com/campaign_media/4a2c7300-b87a-11eb-967b-13b5e3f3e976/1621412093510banner_image",
        "embedded_image_link": "",
        "embedded_video_link": "",
        "video_url": "",
        "document_url": "",
        "heading": "Test article",
        "sub_heading": "Title1",
        "priority": 0,
        "location": "",
        "is_headline": false,
        "resource_email": null,
        "resource_name": null,
        "author_email": "himanshu.bisht+2@ksolves.com",
        "sub_campaign_count": [],
        "campaign_category": "South Pacific",
        "campaign_category_id": "dc3a09b7-eecd-415a-9ac4-63321d3zziab",
        "category_color": "#b3c733",
        "comments_count": "0",
        "smiles_count": "0"
    },
    {
        "id": "5bd6a940-c365-11eb-971b-f54639f85d8a",
        "author_name": "GIL",
        "user_client_id": "cc932351-74bc-11ea-950b-71c6872dc7d9",
        "author_image_url": "https://sharemeister-crm-dev.s3.us-west-2.amazonaws.com/client/0bc8dc7c-b5a3-4344-b313-819fc640f6b5/1625466702943logo_url",
        "profile_img": "https://sharemeister-crm-dev.s3.us-west-2.amazonaws.com/client/0bc8dc7c-b5a3-4344-b313-819fc640f6b5/1625466702943logo_url",
        "client_id": "0bc8dc7c-b5a3-4344-b313-819fc640f6b5",
        "description": "<p>My test article.</p>\n",
        "ranking": 10,
        "created_at": "2021-06-02T05:42:46.494Z",
        "updated_at": "2021-06-02T05:42:48.967Z",
        "campaign_id": "5bd6a940-c365-11eb-971b-f54639f85d8a",
        "image_url": "https://sharemeister-crm-dev.s3.us-west-2.amazonaws.com/campaign_media/5bd6a940-c365-11eb-971b-f54639f85d8a/1622612566506banner_image",
        "embedded_image_link": "",
        "embedded_video_link": "",
        "video_url": "",
        "document_url": "",
        "heading": "test 3",
        "sub_heading": "Test article",
        "priority": 0,
        "location": "",
        "is_headline": false,
        "resource_email": null,
        "resource_name": null,
        "author_email": "owner@gil.com",
        "sub_campaign_count": [],
        "campaign_category": "North America",
        "campaign_category_id": "dc3a09b7-eecd-415a-9dc4-63321daeu1eb",
        "category_color": "#1d4d19",
        "comments_count": "0",
        "smiles_count": "0"
    },
    {
        "id": "a84be070-c35a-11eb-971b-f54639f85d8a",
        "author_name": "Aman Jain",
        "user_client_id": "190b85b0-9924-11eb-9c53-1b40e4c04975",
        "author_image_url": "https://sharemeister-crm-dev.s3.us-west-2.amazonaws.com/user_media/190b85b0-9924-11eb-9c53-1b40e4c04975/1622712265531image",
        "profile_img": "https://sharemeister-crm-dev.s3.us-west-2.amazonaws.com/client/0bc8dc7c-b5a3-4344-b313-819fc640f6b5/1625466702943logo_url",
        "client_id": "0bc8dc7c-b5a3-4344-b313-819fc640f6b5",
        "description": "<p>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</p>\n",
        "ranking": 10,
        "created_at": "2021-06-02T04:26:10.300Z",
        "updated_at": "2021-06-02T04:26:17.016Z",
        "campaign_id": "a84be070-c35a-11eb-971b-f54639f85d8a",
        "image_url": "https://sharemeister-crm-dev.s3.us-west-2.amazonaws.com/campaign_media/a84be070-c35a-11eb-971b-f54639f85d8a/1622607970313banner_image",
        "embedded_image_link": "",
        "embedded_video_link": "",
        "video_url": "",
        "document_url": "",
        "heading": "New Article test",
        "sub_heading": "Title one",
        "priority": 0,
        "location": "",
        "is_headline": false,
        "resource_email": null,
        "resource_name": null,
        "author_email": "himanshu.bisht+1@ksolves.com",
        "sub_campaign_count": [],
        "campaign_category": "North America",
        "campaign_category_id": "dc3a09b7-eecd-415a-9dc4-63321daeu1eb",
        "category_color": "#1d4d19",
        "comments_count": "0",
        "smiles_count": "0"
    },
    {
        "id": "ed833df0-bc57-11eb-971b-f54639f85d8a",
        "author_name": "GIL",
        "user_client_id": "cc932351-74bc-11ea-950b-71c6872dc7d9",
        "author_image_url": "https://sharemeister-crm-dev.s3.us-west-2.amazonaws.com/client/0bc8dc7c-b5a3-4344-b313-819fc640f6b5/1625466702943logo_url",
        "profile_img": "https://sharemeister-crm-dev.s3.us-west-2.amazonaws.com/client/0bc8dc7c-b5a3-4344-b313-819fc640f6b5/1625466702943logo_url",
        "client_id": "0bc8dc7c-b5a3-4344-b313-819fc640f6b5",
        "description": "<p>My Article</p>\n",
        "ranking": 10,
        "created_at": "2021-05-24T06:18:59.803Z",
        "updated_at": "2021-06-02T05:41:40.929Z",
        "campaign_id": "ed833df0-bc57-11eb-971b-f54639f85d8a",
        "image_url": "https://sharemeister-crm-dev.s3.us-west-2.amazonaws.com/campaign_media/ed833df0-bc57-11eb-971b-f54639f85d8a/1621837139814banner_image",
        "embedded_image_link": "",
        "embedded_video_link": "",
        "video_url": "",
        "document_url": "",
        "heading": "Test 2",
        "sub_heading": "Test Article",
        "priority": 0,
        "location": "",
        "is_headline": false,
        "resource_email": null,
        "resource_name": null,
        "author_email": "owner@gil.com",
        "sub_campaign_count": [],
        "campaign_category": "North America",
        "campaign_category_id": "dc3a09b7-eecd-415a-9dc4-63321daeu1eb",
        "category_color": "#1d4d19",
        "comments_count": "0",
        "smiles_count": "0"
    },
    {
        "id": "92c19700-c43a-11eb-971b-f54639f85d8a",
        "author_name": "Ankit Kumar",
        "user_client_id": "aa288b71-c425-11eb-971b-f54639f85d8a",
        "author_image_url": "https://sharemeister-crm-dev.s3.us-west-2.amazonaws.com/user_media/aa288b71-c425-11eb-971b-f54639f85d8a/1622695628244image",
        "profile_img": "https://sharemeister-crm-dev.s3.us-west-2.amazonaws.com/client/0bc8dc7c-b5a3-4344-b313-819fc640f6b5/1625466702943logo_url",
        "client_id": "0bc8dc7c-b5a3-4344-b313-819fc640f6b5",
        "description": "<p>&nbsp;&nbsp;&nbsp;&nbsp;.</p>\n",
        "ranking": 10,
        "created_at": "2021-06-03T07:09:01.427Z",
        "updated_at": "2021-06-03T07:09:08.153Z",
        "campaign_id": "92c19700-c43a-11eb-971b-f54639f85d8a",
        "image_url": "https://sharemeister-crm-dev.s3.us-west-2.amazonaws.com/campaign_media/92c19700-c43a-11eb-971b-f54639f85d8a/1622704141435banner_image",
        "embedded_image_link": "",
        "embedded_video_link": "",
        "video_url": "",
        "document_url": "",
        "heading": "Article Issue",
        "sub_heading": "Title one",
        "priority": 0,
        "location": "",
        "is_headline": false,
        "resource_email": null,
        "resource_name": null,
        "author_email": "himanshu.bisht+9@ksolves.com",
        "sub_campaign_count": [],
        "campaign_category": "Africa",
        "campaign_category_id": "dc3a09b7-eecd-265a-9dc4-633007b0081b",
        "category_color": "#9906eb",
        "comments_count": "0",
        "smiles_count": "0"
    },
    {
        "id": "8afd6eb0-c537-11eb-971b-f54639f85d8a",
        "author_name": "Aman Verma",
        "user_client_id": "844613b1-c21c-11eb-971b-f54639f85d8a",
        "author_image_url": null,
        "profile_img": "https://sharemeister-crm-dev.s3.us-west-2.amazonaws.com/client/0bc8dc7c-b5a3-4344-b313-819fc640f6b5/1625466702943logo_url",
        "client_id": "0bc8dc7c-b5a3-4344-b313-819fc640f6b5",
        "description": "<p>&nbsp;&nbsp;&nbsp;&nbsp;</p>\n",
        "ranking": 10,
        "created_at": "2021-06-04T13:19:51.071Z",
        "updated_at": "2021-06-04T13:20:13.513Z",
        "campaign_id": "8afd6eb0-c537-11eb-971b-f54639f85d8a",
        "image_url": "https://sharemeister-crm-dev.s3.us-west-2.amazonaws.com/campaign_media/8afd6eb0-c537-11eb-971b-f54639f85d8a/1622812791078banner_image",
        "embedded_image_link": "",
        "embedded_video_link": "",
        "video_url": "",
        "document_url": "",
        "heading": "Issue Article",
        "sub_heading": "Title one",
        "priority": 0,
        "location": "",
        "is_headline": false,
        "resource_email": null,
        "resource_name": null,
        "author_email": "himanshu.bisht+7@ksolves.com",
        "sub_campaign_count": [],
        "campaign_category": "MiddleEast",
        "campaign_category_id": "dc3a09b7-eecd-465a-9dc4-63325mmhg7eb",
        "category_color": "#f48608",
        "comments_count": "0",
        "smiles_count": "0"
    },
    {
        "id": "961304c0-c6d3-11eb-ab3c-75c237895579",
        "author_name": "Aman Jain",
        "user_client_id": "190b85b0-9924-11eb-9c53-1b40e4c04975",
        "author_image_url": "https://sharemeister-crm-dev.s3.us-west-2.amazonaws.com/user_media/190b85b0-9924-11eb-9c53-1b40e4c04975/1622712265531image",
        "profile_img": "https://sharemeister-crm-dev.s3.us-west-2.amazonaws.com/client/0bc8dc7c-b5a3-4344-b313-819fc640f6b5/1625466702943logo_url",
        "client_id": "0bc8dc7c-b5a3-4344-b313-819fc640f6b5",
        "description": "<p>&nbsp;&nbsp;</p>\n",
        "ranking": 10,
        "created_at": "2021-06-06T14:29:22.324Z",
        "updated_at": "2021-06-06T14:29:43.736Z",
        "campaign_id": "961304c0-c6d3-11eb-ab3c-75c237895579",
        "image_url": "https://sharemeister-crm-dev.s3.us-west-2.amazonaws.com/campaign_media/961304c0-c6d3-11eb-ab3c-75c237895579/1622989762334banner_image",
        "embedded_image_link": "",
        "embedded_video_link": "",
        "video_url": "",
        "document_url": "",
        "heading": "Article B",
        "sub_heading": "Chapter two",
        "priority": 0,
        "location": "",
        "is_headline": false,
        "resource_email": null,
        "resource_name": null,
        "author_email": "himanshu.bisht+1@ksolves.com",
        "sub_campaign_count": [],
        "campaign_category": "South Pacific",
        "campaign_category_id": "dc3a09b7-eecd-415a-9ac4-63321d3zziab",
        "category_color": "#b3c733",
        "comments_count": "0",
        "smiles_count": "0"
    },
    {
        "id": "fd2b8e40-c785-11eb-893e-0fb1ef4c3ca8",
        "author_name": "Kunal Roy",
        "user_client_id": "30f5bd61-ad67-11eb-ae18-ad4bb1f53c3a",
        "author_image_url": "https://sharemeister-crm-dev.s3.us-west-2.amazonaws.com/user_media/30f5bd61-ad67-11eb-ae18-ad4bb1f53c3a/1624963696075image",
        "profile_img": "https://sharemeister-crm-dev.s3.us-west-2.amazonaws.com/client/0bc8dc7c-b5a3-4344-b313-819fc640f6b5/1625466702943logo_url",
        "client_id": "0bc8dc7c-b5a3-4344-b313-819fc640f6b5",
        "description": "<p>&nbsp;</p>\n",
        "ranking": 10,
        "created_at": "2021-06-07T11:46:25.710Z",
        "updated_at": "2021-06-07T11:46:31.512Z",
        "campaign_id": "fd2b8e40-c785-11eb-893e-0fb1ef4c3ca8",
        "image_url": "https://sharemeister-crm-dev.s3.us-west-2.amazonaws.com/campaign_media/fd2b8e40-c785-11eb-893e-0fb1ef4c3ca8/1623066385721banner_image",
        "embedded_image_link": "",
        "embedded_video_link": "",
        "video_url": "",
        "document_url": "",
        "heading": "Article Info",
        "sub_heading": "Chapter one",
        "priority": 0,
        "location": "",
        "is_headline": false,
        "resource_email": null,
        "resource_name": null,
        "author_email": "himanshu.bisht+3@ksolves.com",
        "sub_campaign_count": [],
        "campaign_category": "Europe",
        "campaign_category_id": "dc3a09b7-qqcd-461a-9ac4-6332a61187eb",
        "category_color": "#e9142d",
        "comments_count": "0",
        "smiles_count": "0"
    },
    {
        "id": "506e07c0-c788-11eb-893e-0fb1ef4c3ca8",
        "author_name": "Atul Arora",
        "user_client_id": "550b1201-b9ff-11eb-967b-13b5e3f3e976",
        "author_image_url": null,
        "profile_img": "https://sharemeister-crm-dev.s3.us-west-2.amazonaws.com/client/0bc8dc7c-b5a3-4344-b313-819fc640f6b5/1625466702943logo_url",
        "client_id": "0bc8dc7c-b5a3-4344-b313-819fc640f6b5",
        "description": "<p>&nbsp;</p>\n",
        "ranking": 10,
        "created_at": "2021-06-07T12:03:04.384Z",
        "updated_at": "2021-06-07T12:03:12.404Z",
        "campaign_id": "506e07c0-c788-11eb-893e-0fb1ef4c3ca8",
        "image_url": "https://sharemeister-crm-dev.s3.us-west-2.amazonaws.com/campaign_media/506e07c0-c788-11eb-893e-0fb1ef4c3ca8/1623067384392banner_image",
        "embedded_image_link": "",
        "embedded_video_link": "",
        "video_url": "",
        "document_url": "",
        "heading": "Article Test",
        "sub_heading": "Chapter",
        "priority": 0,
        "location": "",
        "is_headline": false,
        "resource_email": null,
        "resource_name": null,
        "author_email": "himanshu.bisht+4@ksolves.com",
        "sub_campaign_count": [],
        "campaign_category": "MiddleEast",
        "campaign_category_id": "dc3a09b7-eecd-465a-9dc4-63325mmhg7eb",
        "category_color": "#f48608",
        "comments_count": "0",
        "smiles_count": "0"
    },
    {
        "id": "4534e860-c792-11eb-893e-0fb1ef4c3ca8",
        "author_name": "Atul Arora",
        "user_client_id": "550b1201-b9ff-11eb-967b-13b5e3f3e976",
        "author_image_url": null,
        "profile_img": "https://sharemeister-crm-dev.s3.us-west-2.amazonaws.com/client/0bc8dc7c-b5a3-4344-b313-819fc640f6b5/1625466702943logo_url",
        "client_id": "0bc8dc7c-b5a3-4344-b313-819fc640f6b5",
        "description": "<p>&nbsp;</p>\n",
        "ranking": 10,
        "created_at": "2021-06-07T13:14:20.521Z",
        "updated_at": "2021-06-07T13:14:30.535Z",
        "campaign_id": "4534e860-c792-11eb-893e-0fb1ef4c3ca8",
        "image_url": "https://sharemeister-crm-dev.s3.us-west-2.amazonaws.com/campaign_media/4534e860-c792-11eb-893e-0fb1ef4c3ca8/1623071660529banner_image",
        "embedded_image_link": "",
        "embedded_video_link": "",
        "video_url": "",
        "document_url": "",
        "heading": "New Article A",
        "sub_heading": "Chapter one",
        "priority": 0,
        "location": "",
        "is_headline": false,
        "resource_email": null,
        "resource_name": null,
        "author_email": "himanshu.bisht+4@ksolves.com",
        "sub_campaign_count": [],
        "campaign_category": "North America",
        "campaign_category_id": "dc3a09b7-eecd-415a-9dc4-63321daeu1eb",
        "category_color": "#1d4d19",
        "comments_count": "0",
        "smiles_count": "0"
    },
    {
        "id": "faffe8c0-c792-11eb-893e-0fb1ef4c3ca8",
        "author_name": "Kunal Roy",
        "user_client_id": "30f5bd61-ad67-11eb-ae18-ad4bb1f53c3a",
        "author_image_url": "https://sharemeister-crm-dev.s3.us-west-2.amazonaws.com/user_media/30f5bd61-ad67-11eb-ae18-ad4bb1f53c3a/1624963696075image",
        "profile_img": "https://sharemeister-crm-dev.s3.us-west-2.amazonaws.com/client/0bc8dc7c-b5a3-4344-b313-819fc640f6b5/1625466702943logo_url",
        "client_id": "0bc8dc7c-b5a3-4344-b313-819fc640f6b5",
        "description": "<p>&nbsp;&nbsp;</p>\n",
        "ranking": 10,
        "created_at": "2021-06-07T13:19:25.523Z",
        "updated_at": "2021-06-07T13:19:36.761Z",
        "campaign_id": "faffe8c0-c792-11eb-893e-0fb1ef4c3ca8",
        "image_url": "https://sharemeister-crm-dev.s3.us-west-2.amazonaws.com/campaign_media/faffe8c0-c792-11eb-893e-0fb1ef4c3ca8/1623071965532banner_image",
        "embedded_image_link": "",
        "embedded_video_link": "",
        "video_url": "",
        "document_url": "",
        "heading": "New Article B",
        "sub_heading": "Chapter one",
        "priority": 0,
        "location": "",
        "is_headline": false,
        "resource_email": null,
        "resource_name": null,
        "author_email": "himanshu.bisht+3@ksolves.com",
        "sub_campaign_count": [],
        "campaign_category": "Europe",
        "campaign_category_id": "dc3a09b7-qqcd-461a-9ac4-6332a61187eb",
        "category_color": "#e9142d",
        "comments_count": "0",
        "smiles_count": "0"
    },
    {
        "id": "1dd5a5c0-c828-11eb-893e-0fb1ef4c3ca8",
        "author_name": "GIL",
        "user_client_id": "cc932351-74bc-11ea-950b-71c6872dc7d9",
        "author_image_url": "https://sharemeister-crm-dev.s3.us-west-2.amazonaws.com/client/0bc8dc7c-b5a3-4344-b313-819fc640f6b5/1625466702943logo_url",
        "profile_img": "https://sharemeister-crm-dev.s3.us-west-2.amazonaws.com/client/0bc8dc7c-b5a3-4344-b313-819fc640f6b5/1625466702943logo_url",
        "client_id": "0bc8dc7c-b5a3-4344-b313-819fc640f6b5",
        "description": "<p>&nbsp;&nbsp;</p>\n",
        "ranking": 10,
        "created_at": "2021-06-08T07:06:58.981Z",
        "updated_at": "2021-06-08T07:07:05.728Z",
        "campaign_id": "1dd5a5c0-c828-11eb-893e-0fb1ef4c3ca8",
        "image_url": "https://sharemeister-crm-dev.s3.us-west-2.amazonaws.com/campaign_media/1dd5a5c0-c828-11eb-893e-0fb1ef4c3ca8/1623136018990banner_image",
        "embedded_image_link": "",
        "embedded_video_link": "",
        "video_url": "",
        "document_url": "",
        "heading": "Test4",
        "sub_heading": "Test one ",
        "priority": 0,
        "location": "",
        "is_headline": false,
        "resource_email": null,
        "resource_name": null,
        "author_email": "owner@gil.com",
        "sub_campaign_count": [],
        "campaign_category": "North America",
        "campaign_category_id": "dc3a09b7-eecd-415a-9dc4-63321daeu1eb",
        "category_color": "#1d4d19",
        "comments_count": "0",
        "smiles_count": "0"
    },
    {
        "id": "77cdcd20-c6d2-11eb-862a-45102e9fb581",
        "author_name": "Aman Jain",
        "user_client_id": "190b85b0-9924-11eb-9c53-1b40e4c04975",
        "author_image_url": "https://sharemeister-crm-dev.s3.us-west-2.amazonaws.com/user_media/190b85b0-9924-11eb-9c53-1b40e4c04975/1622712265531image",
        "profile_img": "https://sharemeister-crm-dev.s3.us-west-2.amazonaws.com/client/0bc8dc7c-b5a3-4344-b313-819fc640f6b5/1625466702943logo_url",
        "client_id": "0bc8dc7c-b5a3-4344-b313-819fc640f6b5",
        "description": "<p>&nbsp;</p>\n",
        "ranking": 10,
        "created_at": "2021-06-06T14:21:22.037Z",
        "updated_at": "2021-06-10T07:10:25.877Z",
        "campaign_id": "77cdcd20-c6d2-11eb-862a-45102e9fb581",
        "image_url": "https://sharemeister-crm-dev.s3.us-west-2.amazonaws.com/campaign_media/77cdcd20-c6d2-11eb-862a-45102e9fb581/1623308954309banner_image",
        "embedded_image_link": "",
        "embedded_video_link": "",
        "video_url": "",
        "document_url": "",
        "heading": "Article South Pacific",
        "sub_heading": "Chapter one",
        "priority": 0,
        "location": "",
        "is_headline": false,
        "resource_email": null,
        "resource_name": null,
        "author_email": "himanshu.bisht+1@ksolves.com",
        "sub_campaign_count": [],
        "campaign_category": "South Pacific",
        "campaign_category_id": "dc3a09b7-eecd-415a-9ac4-63321d3zziab",
        "category_color": "#b3c733",
        "comments_count": "0",
        "smiles_count": "0"
    },
    {
        "id": "e42f6360-c9d5-11eb-86a8-2b2a91997475",
        "author_name": "Naman Kumar",
        "user_client_id": "8f28c9e1-c79d-11eb-893e-0fb1ef4c3ca8",
        "author_image_url": null,
        "profile_img": "https://sharemeister-crm-dev.s3.us-west-2.amazonaws.com/client/0bc8dc7c-b5a3-4344-b313-819fc640f6b5/1625466702943logo_url",
        "client_id": "0bc8dc7c-b5a3-4344-b313-819fc640f6b5",
        "description": "<p>This is a new article.</p>\n",
        "ranking": 10,
        "created_at": "2021-06-10T10:23:25.856Z",
        "updated_at": "2021-06-10T10:23:41.518Z",
        "campaign_id": "e42f6360-c9d5-11eb-86a8-2b2a91997475",
        "image_url": "https://sharemeister-crm-dev.s3.us-west-2.amazonaws.com/campaign_media/e42f6360-c9d5-11eb-86a8-2b2a91997475/1623320605866banner_image",
        "embedded_image_link": "",
        "embedded_video_link": "",
        "video_url": "",
        "document_url": "",
        "heading": "Article Testt",
        "sub_heading": "Title1",
        "priority": 0,
        "location": "",
        "is_headline": false,
        "resource_email": null,
        "resource_name": null,
        "author_email": "himanshu.bisht+12@ksolves.com",
        "sub_campaign_count": [],
        "campaign_category": "South Pacific",
        "campaign_category_id": "dc3a09b7-eecd-415a-9ac4-63321d3zziab",
        "category_color": "#b3c733",
        "comments_count": "0",
        "smiles_count": "0"
    },
    {
        "id": "21ea3f80-c9dc-11eb-86a8-2b2a91997475",
        "author_name": "GIL",
        "user_client_id": "cc932351-74bc-11ea-950b-71c6872dc7d9",
        "author_image_url": "https://sharemeister-crm-dev.s3.us-west-2.amazonaws.com/client/0bc8dc7c-b5a3-4344-b313-819fc640f6b5/1625466702943logo_url",
        "profile_img": "https://sharemeister-crm-dev.s3.us-west-2.amazonaws.com/client/0bc8dc7c-b5a3-4344-b313-819fc640f6b5/1625466702943logo_url",
        "client_id": "0bc8dc7c-b5a3-4344-b313-819fc640f6b5",
        "description": "<p>This is a new article.</p>\n",
        "ranking": 10,
        "created_at": "2021-06-10T11:08:06.395Z",
        "updated_at": "2021-06-15T09:45:51.509Z",
        "campaign_id": "21ea3f80-c9dc-11eb-86a8-2b2a91997475",
        "image_url": "",
        "embedded_image_link": "https://homepages.cae.wisc.edu/~ece533/images/boat.png",
        "embedded_video_link": "https://www.youtube.com/watch?v=NpEaa2P7qZI",
        "video_url": "",
        "document_url": "",
        "heading": "Test 5",
        "sub_heading": "Title1",
        "priority": 0,
        "location": "",
        "is_headline": false,
        "resource_email": null,
        "resource_name": null,
        "author_email": "owner@gil.com",
        "sub_campaign_count": [],
        "campaign_category": "MiddleEast",
        "campaign_category_id": "dc3a09b7-eecd-465a-9dc4-63325mmhg7eb",
        "category_color": "#f48608",
        "comments_count": "0",
        "smiles_count": "0"
    },
    {
        "id": "07092a10-cdc3-11eb-86a8-2b2a91997475",
        "author_name": "Ashu Verma",
        "user_client_id": "7dbabe91-c21e-11eb-971b-f54639f85d8a",
        "author_image_url": null,
        "profile_img": "https://sharemeister-crm-dev.s3.us-west-2.amazonaws.com/client/0bc8dc7c-b5a3-4344-b313-819fc640f6b5/1625466702943logo_url",
        "client_id": "0bc8dc7c-b5a3-4344-b313-819fc640f6b5",
        "description": "<p>&nbsp;&nbsp;&nbsp;</p>\n",
        "ranking": 10,
        "created_at": "2021-06-15T10:18:28.538Z",
        "updated_at": "2021-06-15T10:18:28.538Z",
        "campaign_id": "07092a10-cdc3-11eb-86a8-2b2a91997475",
        "image_url": "",
        "embedded_image_link": "https://homepages.cae.wisc.edu/~ece533/images/boat.png",
        "embedded_video_link": "https://www.youtube.com/watch?v=NpEaa2P7qZI",
        "video_url": "",
        "document_url": "",
        "heading": "Test Article1",
        "sub_heading": "Chapter one",
        "priority": 0,
        "location": "",
        "is_headline": false,
        "resource_email": null,
        "resource_name": null,
        "author_email": "himanshu.bisht+8@ksolves.com",
        "sub_campaign_count": [],
        "campaign_category": "Latin America",
        "campaign_category_id": "dc3a01b7-eecd-465a-91c4-633bb2z834eb",
        "category_color": "#9bf408",
        "comments_count": "0",
        "smiles_count": "0"
    },
    {
        "id": "11ee4e60-cf6c-11eb-8701-d717fe3b3533",
        "author_name": "GIL",
        "user_client_id": "cc932351-74bc-11ea-950b-71c6872dc7d9",
        "author_image_url": "https://sharemeister-crm-dev.s3.us-west-2.amazonaws.com/client/0bc8dc7c-b5a3-4344-b313-819fc640f6b5/1625466702943logo_url",
        "profile_img": "https://sharemeister-crm-dev.s3.us-west-2.amazonaws.com/client/0bc8dc7c-b5a3-4344-b313-819fc640f6b5/1625466702943logo_url",
        "client_id": "0bc8dc7c-b5a3-4344-b313-819fc640f6b5",
        "description": "<p>Go to the forest to learn</p>\n",
        "ranking": 10,
        "created_at": "2021-06-17T13:01:02.922Z",
        "updated_at": "2021-06-17T13:01:07.362Z",
        "campaign_id": "11ee4e60-cf6c-11eb-8701-d717fe3b3533",
        "image_url": "https://sharemeister-crm-dev.s3.us-west-2.amazonaws.com/campaign_media/11ee4e60-cf6c-11eb-8701-d717fe3b3533/1623934862930banner_image",
        "embedded_image_link": "",
        "embedded_video_link": "",
        "video_url": "",
        "document_url": "",
        "heading": "test artilcle",
        "sub_heading": "The Main",
        "priority": 0,
        "location": "",
        "is_headline": false,
        "resource_email": null,
        "resource_name": null,
        "author_email": "owner@gil.com",
        "sub_campaign_count": [],
        "campaign_category": "South Pacific",
        "campaign_category_id": "dc3a09b7-eecd-415a-9ac4-63321d3zziab",
        "category_color": "#b3c733",
        "comments_count": "0",
        "smiles_count": "0"
    },
    {
        "id": "3c311a40-d7dc-11eb-9b83-29ee70d21b52",
        "author_name": "Aman Jain",
        "user_client_id": "190b85b0-9924-11eb-9c53-1b40e4c04975",
        "author_image_url": "https://sharemeister-crm-dev.s3.us-west-2.amazonaws.com/user_media/190b85b0-9924-11eb-9c53-1b40e4c04975/1622712265531image",
        "profile_img": "https://sharemeister-crm-dev.s3.us-west-2.amazonaws.com/client/0bc8dc7c-b5a3-4344-b313-819fc640f6b5/1625466702943logo_url",
        "client_id": "0bc8dc7c-b5a3-4344-b313-819fc640f6b5",
        "description": "<p>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</p>\n",
        "ranking": 10,
        "created_at": "2021-06-28T06:44:06.761Z",
        "updated_at": "2021-06-28T06:44:11.196Z",
        "campaign_id": "3c311a40-d7dc-11eb-9b83-29ee70d21b52",
        "image_url": "https://sharemeister-crm-dev.s3.us-west-2.amazonaws.com/campaign_media/3c311a40-d7dc-11eb-9b83-29ee70d21b52/1624862646770banner_image",
        "embedded_image_link": "",
        "embedded_video_link": "",
        "video_url": "",
        "document_url": "",
        "heading": "Test Article A",
        "sub_heading": "Chapter one",
        "priority": 0,
        "location": "",
        "is_headline": false,
        "resource_email": null,
        "resource_name": null,
        "author_email": "himanshu.bisht+1@ksolves.com",
        "sub_campaign_count": [],
        "campaign_category": "North America",
        "campaign_category_id": "dc3a09b7-eecd-415a-9dc4-63321daeu1eb",
        "category_color": "#1d4d19",
        "comments_count": "0",
        "smiles_count": "0"
    },
    {
        "id": "462948c0-d8d0-11eb-9c3a-0973df6b822e",
        "author_name": "Kunal Roy",
        "user_client_id": "30f5bd61-ad67-11eb-ae18-ad4bb1f53c3a",
        "author_image_url": "https://sharemeister-crm-dev.s3.us-west-2.amazonaws.com/user_media/30f5bd61-ad67-11eb-ae18-ad4bb1f53c3a/1624963696075image",
        "profile_img": "https://sharemeister-crm-dev.s3.us-west-2.amazonaws.com/client/0bc8dc7c-b5a3-4344-b313-819fc640f6b5/1625466702943logo_url",
        "client_id": "0bc8dc7c-b5a3-4344-b313-819fc640f6b5",
        "description": "<p>&nbsp;&nbsp;&nbsp;</p>\n",
        "ranking": 10,
        "created_at": "2021-06-29T11:51:00.695Z",
        "updated_at": "2021-06-29T11:51:06.125Z",
        "campaign_id": "462948c0-d8d0-11eb-9c3a-0973df6b822e",
        "image_url": "https://sharemeister-crm-dev.s3.us-west-2.amazonaws.com/campaign_media/462948c0-d8d0-11eb-9c3a-0973df6b822e/1624967460708banner_image",
        "embedded_image_link": "",
        "embedded_video_link": "",
        "video_url": "",
        "document_url": "",
        "heading": "Group Article one",
        "sub_heading": "Chapter one",
        "priority": 0,
        "location": "",
        "is_headline": false,
        "resource_email": null,
        "resource_name": null,
        "author_email": "himanshu.bisht+3@ksolves.com",
        "sub_campaign_count": [],
        "campaign_category": "North America",
        "campaign_category_id": "dc3a09b7-eecd-415a-9dc4-63321daeu1eb",
        "category_color": "#1d4d19",
        "comments_count": "0",
        "smiles_count": "0"
    },
    {
        "id": "fcdc22e0-d7e5-11eb-9b83-29ee70d21b52",
        "author_name": "Ramesh Kumar",
        "user_client_id": "f1cc56c1-caa4-11eb-86a8-2b2a91997475",
        "author_image_url": null,
        "profile_img": "https://sharemeister-crm-dev.s3.us-west-2.amazonaws.com/client/0bc8dc7c-b5a3-4344-b313-819fc640f6b5/1625466702943logo_url",
        "client_id": "0bc8dc7c-b5a3-4344-b313-819fc640f6b5",
        "description": "<p>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</p>\n",
        "ranking": 10,
        "created_at": "2021-06-28T07:53:55.479Z",
        "updated_at": "2021-06-28T07:54:00.476Z",
        "campaign_id": "fcdc22e0-d7e5-11eb-9b83-29ee70d21b52",
        "image_url": "https://sharemeister-crm-dev.s3.us-west-2.amazonaws.com/campaign_media/fcdc22e0-d7e5-11eb-9b83-29ee70d21b52/1624866835489banner_image",
        "embedded_image_link": "",
        "embedded_video_link": "",
        "video_url": "",
        "document_url": "",
        "heading": "My article A",
        "sub_heading": "Chapter one",
        "priority": 0,
        "location": "",
        "is_headline": false,
        "resource_email": null,
        "resource_name": null,
        "author_email": "himanshu.bisht+13@ksolves.com",
        "sub_campaign_count": [],
        "campaign_category": "North America",
        "campaign_category_id": "dc3a09b7-eecd-415a-9dc4-63321daeu1eb",
        "category_color": "#1d4d19",
        "comments_count": "0",
        "smiles_count": "0"
    },
    {
        "id": "72c952b0-cf72-11eb-8701-d717fe3b3533",
        "author_name": "Raja Jani",
        "user_client_id": "55cfd4f1-cf71-11eb-8701-d717fe3b3533",
        "author_image_url": null,
        "profile_img": "https://sharemeister-crm-dev.s3.us-west-2.amazonaws.com/client/0bc8dc7c-b5a3-4344-b313-819fc640f6b5/1625466702943logo_url",
        "client_id": "0bc8dc7c-b5a3-4344-b313-819fc640f6b5",
        "description": "<p>Goal needs to be found</p>\n",
        "ranking": 10,
        "created_at": "2021-06-17T13:46:42.399Z",
        "updated_at": "2021-06-17T13:46:47.533Z",
        "campaign_id": "72c952b0-cf72-11eb-8701-d717fe3b3533",
        "image_url": "https://sharemeister-crm-dev.s3.us-west-2.amazonaws.com/campaign_media/72c952b0-cf72-11eb-8701-d717fe3b3533/1623937602405banner_image",
        "embedded_image_link": "",
        "embedded_video_link": "https://www.youtube.com/watch?v=TQMbvJNRpLE",
        "video_url": "",
        "document_url": "",
        "heading": "Connect",
        "sub_heading": "Goal",
        "priority": 0,
        "location": "",
        "is_headline": false,
        "resource_email": null,
        "resource_name": null,
        "author_email": "hrr14419@cuoly.com",
        "sub_campaign_count": [
            null,
            null
        ],
        "campaign_category": "North America",
        "campaign_category_id": "dc3a09b7-eecd-415a-9dc4-63321daeu1eb",
        "category_color": "#1d4d19",
        "comments_count": "0",
        "smiles_count": "0"
    },
    {
        "id": "f925a9a0-d99d-11eb-9dee-33919b7fca72",
        "author_name": "Naman Kumar",
        "user_client_id": "8f28c9e1-c79d-11eb-893e-0fb1ef4c3ca8",
        "author_image_url": null,
        "profile_img": "https://sharemeister-crm-dev.s3.us-west-2.amazonaws.com/client/0bc8dc7c-b5a3-4344-b313-819fc640f6b5/1625466702943logo_url",
        "client_id": "0bc8dc7c-b5a3-4344-b313-819fc640f6b5",
        "description": "<p>&nbsp;&nbsp;&nbsp;</p>\n",
        "ranking": 10,
        "created_at": "2021-06-30T12:23:27.812Z",
        "updated_at": "2021-06-30T12:23:38.142Z",
        "campaign_id": "f925a9a0-d99d-11eb-9dee-33919b7fca72",
        "image_url": "https://sharemeister-crm-dev.s3.us-west-2.amazonaws.com/campaign_media/f925a9a0-d99d-11eb-9dee-33919b7fca72/1625055807822banner_image",
        "embedded_image_link": "",
        "embedded_video_link": "",
        "video_url": "",
        "document_url": "",
        "heading": "Chat article",
        "sub_heading": "Chapter one",
        "priority": 0,
        "location": "",
        "is_headline": false,
        "resource_email": null,
        "resource_name": null,
        "author_email": "himanshu.bisht+12@ksolves.com",
        "sub_campaign_count": [],
        "campaign_category": "North America",
        "campaign_category_id": "dc3a09b7-eecd-415a-9dc4-63321daeu1eb",
        "category_color": "#1d4d19",
        "comments_count": "0",
        "smiles_count": "0"
    },
    {
        "id": "2b035170-d99e-11eb-9dee-33919b7fca72",
        "author_name": "Naman Kumar",
        "user_client_id": "8f28c9e1-c79d-11eb-893e-0fb1ef4c3ca8",
        "author_image_url": null,
        "profile_img": "https://sharemeister-crm-dev.s3.us-west-2.amazonaws.com/client/0bc8dc7c-b5a3-4344-b313-819fc640f6b5/1625466702943logo_url",
        "client_id": "0bc8dc7c-b5a3-4344-b313-819fc640f6b5",
        "description": "<p>&nbsp;&nbsp;</p>\n",
        "ranking": 10,
        "created_at": "2021-06-30T12:24:51.471Z",
        "updated_at": "2021-06-30T12:24:57.000Z",
        "campaign_id": "2b035170-d99e-11eb-9dee-33919b7fca72",
        "image_url": "https://sharemeister-crm-dev.s3.us-west-2.amazonaws.com/campaign_media/2b035170-d99e-11eb-9dee-33919b7fca72/1625055891481banner_image",
        "embedded_image_link": "",
        "embedded_video_link": "",
        "video_url": "",
        "document_url": "",
        "heading": "Article chat",
        "sub_heading": "Chapter one",
        "priority": 0,
        "location": "",
        "is_headline": false,
        "resource_email": null,
        "resource_name": null,
        "author_email": "himanshu.bisht+12@ksolves.com",
        "sub_campaign_count": [],
        "campaign_category": "North America",
        "campaign_category_id": "dc3a09b7-eecd-415a-9dc4-63321daeu1eb",
        "category_color": "#1d4d19",
        "comments_count": "0",
        "smiles_count": "0"
    },
    {
        "id": "22b496e0-d99f-11eb-9dee-33919b7fca72",
        "author_name": "Aman Jain",
        "user_client_id": "190b85b0-9924-11eb-9c53-1b40e4c04975",
        "author_image_url": "https://sharemeister-crm-dev.s3.us-west-2.amazonaws.com/user_media/190b85b0-9924-11eb-9c53-1b40e4c04975/1622712265531image",
        "profile_img": "https://sharemeister-crm-dev.s3.us-west-2.amazonaws.com/client/0bc8dc7c-b5a3-4344-b313-819fc640f6b5/1625466702943logo_url",
        "client_id": "0bc8dc7c-b5a3-4344-b313-819fc640f6b5",
        "description": "<p>&nbsp;&nbsp;&nbsp;</p>\n",
        "ranking": 10,
        "created_at": "2021-06-30T12:31:47.030Z",
        "updated_at": "2021-06-30T12:31:51.960Z",
        "campaign_id": "22b496e0-d99f-11eb-9dee-33919b7fca72",
        "image_url": "https://sharemeister-crm-dev.s3.us-west-2.amazonaws.com/campaign_media/22b496e0-d99f-11eb-9dee-33919b7fca72/1625056307039banner_image",
        "embedded_image_link": "",
        "embedded_video_link": "",
        "video_url": "",
        "document_url": "",
        "heading": "Article chat ",
        "sub_heading": "Chapter one",
        "priority": 0,
        "location": "",
        "is_headline": false,
        "resource_email": null,
        "resource_name": null,
        "author_email": "himanshu.bisht+1@ksolves.com",
        "sub_campaign_count": [],
        "campaign_category": "Latin America",
        "campaign_category_id": "dc3a01b7-eecd-465a-91c4-633bb2z834eb",
        "category_color": "#9bf408",
        "comments_count": "0",
        "smiles_count": "0"
    },
    {
        "id": "1e7c15f0-d9a2-11eb-9dee-33919b7fca72",
        "author_name": "Akhil Kumar",
        "user_client_id": "53291901-c79c-11eb-893e-0fb1ef4c3ca8",
        "author_image_url": "https://sharemeister-crm-dev.s3.us-west-2.amazonaws.com/client/53291900-c79c-11eb-893e-0fb1ef4c3ca8/1623205797362logo_url",
        "profile_img": "https://sharemeister-crm-dev.s3.us-west-2.amazonaws.com/client/0bc8dc7c-b5a3-4344-b313-819fc640f6b5/1625466702943logo_url",
        "client_id": "0bc8dc7c-b5a3-4344-b313-819fc640f6b5",
        "description": "<p>&nbsp;&nbsp;</p>\n",
        "ranking": 10,
        "created_at": "2021-06-30T12:53:08.435Z",
        "updated_at": "2021-06-30T12:53:13.377Z",
        "campaign_id": "1e7c15f0-d9a2-11eb-9dee-33919b7fca72",
        "image_url": "https://sharemeister-crm-dev.s3.us-west-2.amazonaws.com/campaign_media/1e7c15f0-d9a2-11eb-9dee-33919b7fca72/1625057588443banner_image",
        "embedded_image_link": "",
        "embedded_video_link": "",
        "video_url": "",
        "document_url": "",
        "heading": "Article 121",
        "sub_heading": "Chapter one",
        "priority": 0,
        "location": "",
        "is_headline": false,
        "resource_email": null,
        "resource_name": null,
        "author_email": "himanshu.bisht+11@ksolves.com",
        "sub_campaign_count": [],
        "campaign_category": "MiddleEast",
        "campaign_category_id": "dc3a09b7-eecd-465a-9dc4-63325mmhg7eb",
        "category_color": "#f48608",
        "comments_count": "0",
        "smiles_count": "0"
    },
    {
        "id": "7c631880-d9b1-11eb-9dee-33919b7fca72",
        "author_name": "Aman Jain",
        "user_client_id": "190b85b0-9924-11eb-9c53-1b40e4c04975",
        "author_image_url": "https://sharemeister-crm-dev.s3.us-west-2.amazonaws.com/user_media/190b85b0-9924-11eb-9c53-1b40e4c04975/1622712265531image",
        "profile_img": "https://sharemeister-crm-dev.s3.us-west-2.amazonaws.com/client/0bc8dc7c-b5a3-4344-b313-819fc640f6b5/1625466702943logo_url",
        "client_id": "0bc8dc7c-b5a3-4344-b313-819fc640f6b5",
        "description": "<p>&nbsp;&nbsp;</p>\n",
        "ranking": 10,
        "created_at": "2021-06-30T14:43:08.427Z",
        "updated_at": "2021-06-30T14:43:20.255Z",
        "campaign_id": "7c631880-d9b1-11eb-9dee-33919b7fca72",
        "image_url": "https://sharemeister-crm-dev.s3.us-west-2.amazonaws.com/campaign_media/7c631880-d9b1-11eb-9dee-33919b7fca72/1625064188437banner_image",
        "embedded_image_link": "",
        "embedded_video_link": "",
        "video_url": "",
        "document_url": "",
        "heading": "Share article",
        "sub_heading": "Chapter one",
        "priority": 0,
        "location": "",
        "is_headline": false,
        "resource_email": null,
        "resource_name": null,
        "author_email": "himanshu.bisht+1@ksolves.com",
        "sub_campaign_count": [],
        "campaign_category": "Asia",
        "campaign_category_id": "dc3a01b7-eacd-465a-9dc4-61121llbzzeb",
        "category_color": "#31e914",
        "comments_count": "0",
        "smiles_count": "0"
    },
    {
        "id": "400249f0-d9b2-11eb-9dee-33919b7fca72",
        "author_name": "Akhil Kumar",
        "user_client_id": "53291901-c79c-11eb-893e-0fb1ef4c3ca8",
        "author_image_url": "https://sharemeister-crm-dev.s3.us-west-2.amazonaws.com/client/53291900-c79c-11eb-893e-0fb1ef4c3ca8/1623205797362logo_url",
        "profile_img": "https://sharemeister-crm-dev.s3.us-west-2.amazonaws.com/client/0bc8dc7c-b5a3-4344-b313-819fc640f6b5/1625466702943logo_url",
        "client_id": "0bc8dc7c-b5a3-4344-b313-819fc640f6b5",
        "description": "<p>&nbsp;&nbsp;</p>\n",
        "ranking": 10,
        "created_at": "2021-06-30T14:48:36.626Z",
        "updated_at": "2021-06-30T14:48:42.822Z",
        "campaign_id": "400249f0-d9b2-11eb-9dee-33919b7fca72",
        "image_url": "https://sharemeister-crm-dev.s3.us-west-2.amazonaws.com/campaign_media/400249f0-d9b2-11eb-9dee-33919b7fca72/1625064516636banner_image",
        "embedded_image_link": "",
        "embedded_video_link": "",
        "video_url": "",
        "document_url": "",
        "heading": "Share Article 1",
        "sub_heading": "Chapter one",
        "priority": 0,
        "location": "",
        "is_headline": false,
        "resource_email": null,
        "resource_name": null,
        "author_email": "himanshu.bisht+11@ksolves.com",
        "sub_campaign_count": [],
        "campaign_category": "MiddleEast",
        "campaign_category_id": "dc3a09b7-eecd-465a-9dc4-63325mmhg7eb",
        "category_color": "#f48608",
        "comments_count": "0",
        "smiles_count": "0"
    },
    {
        "id": "1d0647e0-dd5d-11eb-8b17-7574749e3e4d",
        "author_name": "Akhil Kumar",
        "user_client_id": "53291901-c79c-11eb-893e-0fb1ef4c3ca8",
        "author_image_url": "https://sharemeister-crm-dev.s3.us-west-2.amazonaws.com/client/53291900-c79c-11eb-893e-0fb1ef4c3ca8/1623205797362logo_url",
        "profile_img": "https://sharemeister-crm-dev.s3.us-west-2.amazonaws.com/client/0bc8dc7c-b5a3-4344-b313-819fc640f6b5/1625466702943logo_url",
        "client_id": "0bc8dc7c-b5a3-4344-b313-819fc640f6b5",
        "description": "<p>&nbsp;&nbsp;</p>\n",
        "ranking": 10,
        "created_at": "2021-07-05T06:49:15.363Z",
        "updated_at": "2021-07-07T05:33:59.675Z",
        "campaign_id": "1d0647e0-dd5d-11eb-8b17-7574749e3e4d",
        "image_url": "https://sharemeister-crm-dev.s3.us-west-2.amazonaws.com/campaign_media/1d0647e0-dd5d-11eb-8b17-7574749e3e4d/1625467755371banner_image",
        "embedded_image_link": "",
        "embedded_video_link": "",
        "video_url": "",
        "document_url": "",
        "heading": "Share article121",
        "sub_heading": "Chapter one ",
        "priority": 0,
        "location": "",
        "is_headline": false,
        "resource_email": null,
        "resource_name": null,
        "author_email": "himanshu.bisht+11@ksolves.com",
        "sub_campaign_count": [],
        "campaign_category": "South Pacific",
        "campaign_category_id": "dc3a09b7-eecd-415a-9ac4-63321d3zziab",
        "category_color": "#b3c733",
        "comments_count": "0",
        "smiles_count": "0"
    },
    {
        "id": "de6af450-8e19-11eb-8803-a97811f03dc2",
        "author_name": "Himanshu Bisht",
        "user_client_id": "da44aec1-8e0f-11eb-8803-a97811f03dc2",
        "author_image_url": null,
        "profile_img": "https://sharemeister-crm-dev.s3.us-west-2.amazonaws.com/client/0bc8dc7c-b5a3-4344-b313-819fc640f6b5/1625466702943logo_url",
        "client_id": "0bc8dc7c-b5a3-4344-b313-819fc640f6b5",
        "description": "<p><span style=\"color: rgb(33,37,41);background-color: rgb(255,255,255);font-size: 16px;font-family: Roboto, sans-serif;\">The Global Institute of Central Asia (GIL) was created by the World Evangelical Alliance (WEA) in [year] with the purpose of strengthening leaders and their peers to bring about national, social, and spiritual transformation.</span>&nbsp;</p>\n",
        "ranking": 10,
        "created_at": "2021-03-26T09:58:52.193Z",
        "updated_at": "2021-07-05T14:55:44.718Z",
        "campaign_id": "de6af450-8e19-11eb-8803-a97811f03dc2",
        "image_url": "https://sharemeister-crm-dev.s3.us-west-2.amazonaws.com/campaign_media/de6af450-8e19-11eb-8803-a97811f03dc2/1616752732211banner_image",
        "embedded_image_link": "",
        "embedded_video_link": "",
        "video_url": "",
        "document_url": "https://sharemeister-crm-dev.s3.us-west-2.amazonaws.com/campaign_media/de6af450-8e19-11eb-8803-a97811f03dc2/1616752732226banner_document",
        "heading": "First Article",
        "sub_heading": "Title1",
        "priority": 0,
        "location": "",
        "is_headline": false,
        "resource_email": null,
        "resource_name": null,
        "author_email": "himanshu.bisht@ksolves.com",
        "sub_campaign_count": [],
        "campaign_category": "North America",
        "campaign_category_id": "dc3a09b7-eecd-415a-9dc4-63321daeu1eb",
        "category_color": "#1d4d19",
        "comments_count": "0",
        "smiles_count": "0"
    },
    {
        "id": "0817f610-d265-11eb-af38-8f100b8e12a3",
        "author_name": "Himanshu Bisht",
        "user_client_id": "da44aec1-8e0f-11eb-8803-a97811f03dc2",
        "author_image_url": null,
        "profile_img": "https://sharemeister-crm-dev.s3.us-west-2.amazonaws.com/client/0bc8dc7c-b5a3-4344-b313-819fc640f6b5/1625466702943logo_url",
        "client_id": "0bc8dc7c-b5a3-4344-b313-819fc640f6b5",
        "description": "<p>&nbsp;&nbsp;</p>\n",
        "ranking": 10,
        "created_at": "2021-06-21T07:48:13.430Z",
        "updated_at": "2021-07-07T05:30:18.704Z",
        "campaign_id": "0817f610-d265-11eb-af38-8f100b8e12a3",
        "image_url": "https://sharemeister-crm-dev.s3.us-west-2.amazonaws.com/campaign_media/0817f610-d265-11eb-af38-8f100b8e12a3/1624261693439banner_image",
        "embedded_image_link": "",
        "embedded_video_link": "",
        "video_url": "",
        "document_url": "",
        "heading": "Article Az",
        "sub_heading": "Chapter one",
        "priority": 0,
        "location": "",
        "is_headline": false,
        "resource_email": null,
        "resource_name": null,
        "author_email": "himanshu.bisht@ksolves.com",
        "sub_campaign_count": [],
        "campaign_category": "Africa",
        "campaign_category_id": "dc3a09b7-eecd-265a-9dc4-633007b0081b",
        "category_color": "#9906eb",
        "comments_count": "0",
        "smiles_count": "0"
    },
    {
        "id": "99be07f0-dfd8-11eb-923d-c1ae63cdab96",
        "author_name": "Akhil Kumar",
        "user_client_id": "53291901-c79c-11eb-893e-0fb1ef4c3ca8",
        "author_image_url": "https://sharemeister-crm-dev.s3.us-west-2.amazonaws.com/client/53291900-c79c-11eb-893e-0fb1ef4c3ca8/1623205797362logo_url",
        "profile_img": "https://sharemeister-crm-dev.s3.us-west-2.amazonaws.com/client/0bc8dc7c-b5a3-4344-b313-819fc640f6b5/1625466702943logo_url",
        "client_id": "0bc8dc7c-b5a3-4344-b313-819fc640f6b5",
        "description": "<p>This is a new article.</p>",
        "ranking": 10,
        "created_at": "2021-07-08T10:38:15.027Z",
        "updated_at": "2021-07-08T10:38:21.405Z",
        "campaign_id": "99be07f0-dfd8-11eb-923d-c1ae63cdab96",
        "image_url": "https://sharemeister-crm-dev.s3.us-west-2.amazonaws.com/campaign_media/99be07f0-dfd8-11eb-923d-c1ae63cdab96/1625740695038banner_image",
        "embedded_image_link": "",
        "embedded_video_link": "",
        "video_url": "",
        "document_url": "",
        "heading": "Article221",
        "sub_heading": "New chapter",
        "priority": 0,
        "location": "",
        "is_headline": false,
        "resource_email": null,
        "resource_name": null,
        "author_email": "himanshu.bisht+11@ksolves.com",
        "sub_campaign_count": [],
        "campaign_category": "South Pacific",
        "campaign_category_id": "dc3a09b7-eecd-415a-9ac4-63321d3zziab",
        "category_color": "#b3c733",
        "comments_count": "0",
        "smiles_count": "0"
    },
    {
        "id": "df353af0-dfe3-11eb-923d-c1ae63cdab96",
        "author_name": "Himanshu Bisht",
        "user_client_id": "da44aec1-8e0f-11eb-8803-a97811f03dc2",
        "author_image_url": null,
        "profile_img": "https://sharemeister-crm-dev.s3.us-west-2.amazonaws.com/client/0bc8dc7c-b5a3-4344-b313-819fc640f6b5/1625466702943logo_url",
        "client_id": "0bc8dc7c-b5a3-4344-b313-819fc640f6b5",
        "description": "<p>This is a new article and will be used for testing purpose only.</p>",
        "ranking": 10,
        "created_at": "2021-07-08T11:58:56.040Z",
        "updated_at": "2021-07-08T12:02:14.313Z",
        "campaign_id": "df353af0-dfe3-11eb-923d-c1ae63cdab96",
        "image_url": "https://sharemeister-crm-dev.s3.us-west-2.amazonaws.com/campaign_media/df353af0-dfe3-11eb-923d-c1ae63cdab96/1625745536051banner_image",
        "embedded_image_link": "",
        "embedded_video_link": "",
        "video_url": "",
        "document_url": "",
        "heading": "South Pacific article",
        "sub_heading": "Chapter one",
        "priority": 0,
        "location": "",
        "is_headline": false,
        "resource_email": null,
        "resource_name": null,
        "author_email": "himanshu.bisht@ksolves.com",
        "sub_campaign_count": [],
        "campaign_category": "South Pacific",
        "campaign_category_id": "dc3a09b7-eecd-415a-9ac4-63321d3zziab",
        "category_color": "#b3c733",
        "comments_count": "0",
        "smiles_count": "0"
    },
    {
        "id": "60772190-dfef-11eb-923d-c1ae63cdab96",
        "author_name": "Deepak Kumar",
        "user_client_id": "bb272c20-dfea-11eb-923d-c1ae63cdab96",
        "author_image_url": "https://sharemeister-crm-dev.s3.us-west-2.amazonaws.com/user_media/bb272c20-dfea-11eb-923d-c1ae63cdab96/1625748731798image",
        "profile_img": "https://sharemeister-crm-dev.s3.us-west-2.amazonaws.com/client/0bc8dc7c-b5a3-4344-b313-819fc640f6b5/1625466702943logo_url",
        "client_id": "0bc8dc7c-b5a3-4344-b313-819fc640f6b5",
        "description": "<p>This is a new article.</p>",
        "ranking": 10,
        "created_at": "2021-07-08T13:21:17.356Z",
        "updated_at": "2021-07-08T13:21:22.594Z",
        "campaign_id": "60772190-dfef-11eb-923d-c1ae63cdab96",
        "image_url": "https://sharemeister-crm-dev.s3.us-west-2.amazonaws.com/campaign_media/60772190-dfef-11eb-923d-c1ae63cdab96/1625750477366banner_image",
        "embedded_image_link": "",
        "embedded_video_link": "",
        "video_url": "",
        "document_url": "",
        "heading": "Article2s",
        "sub_heading": "Title one",
        "priority": 0,
        "location": "",
        "is_headline": false,
        "resource_email": null,
        "resource_name": null,
        "author_email": "himanshu.bisht+15@ksolves.com",
        "sub_campaign_count": [],
        "campaign_category": "South Pacific",
        "campaign_category_id": "dc3a09b7-eecd-415a-9ac4-63321d3zziab",
        "category_color": "#b3c733",
        "comments_count": "0",
        "smiles_count": "0"
    },
    {
        "id": "6ac0e340-3c7c-11ec-a4d5-69c2978f0ae2",
        "author_name": "Parul Sardana",
        "user_client_id": "e966cad1-1f52-11ec-9c05-1fdb4b960d6b",
        "author_image_url": "https://sharemeister-crm-dev.s3.us-west-2.amazonaws.com/client/e966cad0-1f52-11ec-9c05-1fdb4b960d6b/1645965135897logo_url",
        "profile_img": "https://sharemeister-crm-dev.s3.us-west-2.amazonaws.com/client/0bc8dc7c-b5a3-4344-b313-819fc640f6b5/1625466702943logo_url",
        "client_id": "0bc8dc7c-b5a3-4344-b313-819fc640f6b5",
        "description": "<p>test description</p>",
        "ranking": 1,
        "created_at": "2021-11-03T08:02:40.631Z",
        "updated_at": "2022-04-04T12:57:41.079Z",
        "campaign_id": "6ac0e340-3c7c-11ec-a4d5-69c2978f0ae2",
        "image_url": "https://sharemeister-crm-dev.s3.us-west-2.amazonaws.com/campaign_media/6ac0e340-3c7c-11ec-a4d5-69c2978f0ae2/1635926560639banner_image",
        "embedded_image_link": "",
        "embedded_video_link": "",
        "video_url": "",
        "document_url": "",
        "heading": "test Article",
        "sub_heading": "test",
        "priority": 0,
        "location": "",
        "is_headline": false,
        "resource_email": null,
        "resource_name": null,
        "author_email": "parul@sharemeister.com",
        "sub_campaign_count": [],
        "campaign_category": "South Pacific",
        "campaign_category_id": "dc3a09b7-eecd-415a-9ac4-63321d3zziab",
        "category_color": "#b3c733",
        "comments_count": "0",
        "smiles_count": "0"
    },
    {
        "id": "631276a0-1d35-11ed-bc85-598901bbd8f0",
        "author_name": "Suraj K",
        "user_client_id": "bbc06100-6704-11ec-83b2-d183209b9332",
        "author_image_url": "https://sharemeister-crm-dev.s3.us-west-2.amazonaws.com/client/bbbfebd0-6704-11ec-83b2-d183209b9332/1661856473002logo_url",
        "profile_img": "https://sharemeister-crm-dev.s3.us-west-2.amazonaws.com/client/0bc8dc7c-b5a3-4344-b313-819fc640f6b5/1625466702943logo_url",
        "client_id": "0bc8dc7c-b5a3-4344-b313-819fc640f6b5",
        "description": "<p class=\"ql-align-justify\">Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum</p><p class=\"ql-align-justify\">Sed ut perspiciatis unde omnis iste natus error sit voluptatem accusantium doloremque laudantium, totam rem aperiam, eaque ipsa quae ab illo inventore veritatis et quasi architecto beatae vitae dicta sunt explicabo. Nemo enim ipsam voluptatem quia voluptas sit aspernatur aut odit aut fugit, sed quia consequuntur magni dolores eos qui ratione voluptatem sequi nesciunt. Neque porro quisquam est, qui dolorem ipsum quia dolor sit amet, consectetur, adipisci velit, sed quia non numquam eius modi tempora incidunt ut labore et dolore magnam aliquam quaerat voluptatem. Ut enim ad minima veniam, quis nostrum exercitationem ullam corporis suscipit laboriosam, nisi ut aliquid ex ea commodi consequatur? Quis autem vel eum iure reprehenderit qui in ea voluptate velit esse quam nihil molestiae consequatur, vel illum qui dolorem eum fugiat quo voluptas nulla pariatur</p><p class=\"ql-align-justify\">Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.</p><p><br></p>",
        "ranking": null,
        "created_at": "2022-08-16T07:31:05.105Z",
        "updated_at": "2022-08-16T07:31:09.307Z",
        "campaign_id": "631276a0-1d35-11ed-bc85-598901bbd8f0",
        "image_url": "https://sharemeister-crm-dev.s3.us-west-2.amazonaws.com/campaign_media/631276a0-1d35-11ed-bc85-598901bbd8f0/1660635065122banner_image",
        "embedded_image_link": "",
        "embedded_video_link": "",
        "video_url": "",
        "document_url": "",
        "heading": "Independence Day",
        "sub_heading": "15th auguest ",
        "priority": 0,
        "location": "",
        "is_headline": false,
        "resource_email": null,
        "resource_name": null,
        "author_email": "suraj.kumar@geminisolutions.in",
        "sub_campaign_count": [],
        "campaign_category": "Asia",
        "campaign_category_id": "dc3a01b7-eacd-465a-9dc4-61121llbzzeb",
        "category_color": "#31e914",
        "comments_count": "0",
        "smiles_count": "0"
    },
    {
        "id": "651e4ec0-6191-11ec-a84c-fb5ba9e385f7",
        "author_name": "Baizul Islam",
        "user_client_id": "456b8c21-3327-11ec-a557-25420fddb59b",
        "author_image_url": null,
        "profile_img": "https://sharemeister-crm-dev.s3.us-west-2.amazonaws.com/client/0bc8dc7c-b5a3-4344-b313-819fc640f6b5/1625466702943logo_url",
        "client_id": "0bc8dc7c-b5a3-4344-b313-819fc640f6b5",
        "description": "<p>Test Description</p>",
        "ranking": 3,
        "created_at": "2021-12-20T12:36:03.635Z",
        "updated_at": "2022-08-17T05:35:17.128Z",
        "campaign_id": "651e4ec0-6191-11ec-a84c-fb5ba9e385f7",
        "image_url": "https://sharemeister-crm-dev.s3.us-west-2.amazonaws.com/campaign_media/651e4ec0-6191-11ec-a84c-fb5ba9e385f7/1640003763645banner_image",
        "embedded_image_link": "",
        "embedded_video_link": "",
        "video_url": "",
        "document_url": "",
        "heading": "Test",
        "sub_heading": "Test Title",
        "priority": 0,
        "location": "",
        "is_headline": false,
        "resource_email": null,
        "resource_name": null,
        "author_email": "baizul.islam@geminisolutions.in",
        "sub_campaign_count": [],
        "campaign_category": "Africa",
        "campaign_category_id": "dc3a09b7-eecd-265a-9dc4-633007b0081b",
        "category_color": "#9906eb",
        "comments_count": "0",
        "smiles_count": "0"
    },
    {
        "id": "6bda2040-325c-11ec-a557-25420fddb59b",
        "author_name": "Bru Nescafe",
        "user_client_id": "eaddc381-30a2-11ec-a439-c31c565445db",
        "author_image_url": null,
        "profile_img": "https://sharemeister-crm-dev.s3.us-west-2.amazonaws.com/client/0bc8dc7c-b5a3-4344-b313-819ppzhau6b5/1642672696207logo_url",
        "client_id": "0bc8dc7c-b5a3-4344-b313-819ppzhau6b5",
        "description": "<p>sdjak djsa uyuewaejdwjnbbsbsxhciusdcjgchx j u  adsuias disuaydsighzj zjxIUIS adh sjus sui s uygesduygaau</p>",
        "ranking": 2,
        "created_at": "2021-10-21T10:48:26.958Z",
        "updated_at": "2022-08-31T05:24:58.539Z",
        "campaign_id": "6bda2040-325c-11ec-a557-25420fddb59b",
        "image_url": "https://sharemeister-crm-dev.s3.us-west-2.amazonaws.com/campaign_media/6bda2040-325c-11ec-a557-25420fddb59b/1634813306968banner_image",
        "embedded_image_link": "",
        "embedded_video_link": "",
        "video_url": "",
        "document_url": "",
        "heading": "sjkdajsdjs",
        "sub_heading": "adasdasdas",
        "priority": 0,
        "location": "",
        "is_headline": false,
        "resource_email": null,
        "resource_name": null,
        "author_email": "asw66456@cuoly.com",
        "sub_campaign_count": [],
        "campaign_category": "Asia",
        "campaign_category_id": "dc3a01b7-eacd-465a-9dc4-61121llbzzeb",
        "category_color": "#31e914",
        "comments_count": "0",
        "smiles_count": "0"
    }
]

export const myCommunities = ["Asia", "Africa", "Central Asia"]


export const allNewsCategories = [
    {
        "id": "581fb3b0-961e-11eb-9bd5-2785873d540d",
        "name": "All News",
        "color_code": null
    },
    {
        "id": "786fb3b0-961e-11eb-9bd5-2785873d540d",
        "name": "Global News",
        "color_code": null
    },
    {
        "id": "a1057470-9835-11eb-9bd5-2785873d540d",
        "name": "Africa",
        "color_code": "#4d4287"
    },
    {
        "id": "37cd0960-984d-11eb-9bd5-2785873d540d",
        "name": "Asia",
        "color_code": null
    },
    {
        "id": "39fa9120-9b55-11eb-9c53-1b40e4c04975",
        "name": "Caribbean",
        "color_code": "#08041c"
    },
    {
        "id": "ede6ed20-b746-11eb-967b-13b5e3f3e976",
        "name": "Central Asia",
        "color_code": null
    },
    {
        "id": "55173f40-b747-11eb-967b-13b5e3f3e976",
        "name": "Europe",
        "color_code": null
    },
    {
        "id": "5c94f8c0-b747-11eb-967b-13b5e3f3e976",
        "name": "Latin America",
        "color_code": null
    },
    {
        "id": "64dda360-b747-11eb-967b-13b5e3f3e976",
        "name": "MiddleEast",
        "color_code": null
    },
    {
        "id": "6b84fa10-b747-11eb-967b-13b5e3f3e976",
        "name": "North America",
        "color_code": null
    },
    {
        "id": "72df9b30-b747-11eb-967b-13b5e3f3e976",
        "name": "South Pacific",
        "color_code": null
    },
]