import React, { useEffect, useState } from "react";
import Avatar from "react-avatar";
import { withRouter } from "react-router-dom";
import { GIL_CLIENT_ID } from "../../../config/constants";
import { CLIENT_ID } from "config/constants/config";
import { BsFillCircleFill } from "react-icons/bs";
import moment from "moment";
import { ClockIcon } from "../../icons/icons";
import EventCard from "../../../scenes/profile/components/EventCard";
import { CustomSlider } from "../Slider";
import { Skeleton } from "@mui/material";

const EventContainer = (props) => {
  const [value, setValue] = useState(null);
  const eventsData = props.eventsData.filter(({ heading }) =>
    heading.toLowerCase().includes(props.search)
  );
  const handleArticleClick = (data) => {
    props.history.push({
      pathname: `/campaign/${data.parent_id || data.id}`,
      calledFrom: props.type,
      goBackLink: goBackLink(),
    });
  };

  const goBackLink = () => {
    if (props.type === "suggestedReads") {
      return "/home";
    } else if (props.type === "myArticles") return "/profile";
  };
  function sum(arr) {
    let sum = 0; // initialize sum
    for (let i = 0; i < arr.length; i++) sum += arr[i];

    return sum;
  }
  const getTime = (startDate) => {
    const start_time = new Date(startDate);
    return start_time.toLocaleString("en-US", {
      hour: "numeric",
      minute: "numeric",
      hour12: true,
    });
  };

  useEffect(() => {
    if (eventsData.length > 0 && eventsData) {
      if (
        props.PanalTitle === "All Events" ||
        props.PanalTitle === "My Events"
      ) {
        props.setFlag(false);
      }
    } else {
      if (props.PanalTitle === "All Events" || props.PanalTitle === "My Events")
        props.setFlag(true);
    }
  }, [props.PanalTitle, eventsData]);
  return (
    <>
      
        <div
          className="course-panal-container  "
          style={props.isArticles ? { width: props.width } : { width: "100%" }}
        >
          {props.PanalTitle && eventsData && eventsData.length !== 0 && (
            <h1
              className={`course-text-${props.category} ml-4 font-24-bold mb-4 pl-4`}
              style={{ color: props.colorCode ? props.colorCode : "#000" }}
            >
              {props.PanalTitle}
            </h1>
          )}
          {/* <div className="event-page-outer pb-4 d-flex"> */}
          <CustomSlider>
            { props.loading ? <div className="d-flex ml-10"> {[1, 2, 3, 4].map((item, i) => (<Skeleton key={i} variant="rounded" className="mr-3 rounded-20 " width={320} height={270} />))}</div> : eventsData.length > 0 &&
              eventsData
                .sort(function (a, b) {
                  return new Date(b.updated_at) - new Date(a.updated_at);
                })
                .map((data, index) => (
                  <EventCard
                    key={data.id}
                    rsvpState={
                      props.myEventsId && props.myEventsId.includes(data.id)
                    }
                    items={data}
                    index={index}
                    panalTitle={props.PanalTitle}
                    eventsData={eventsData}
                    joinedNeighborhood={props.joinedNeighborhood}
                  />
                ))}
            {/* </div> */}
          </CustomSlider>
        </div>
  
    </>
  );
};

const EventContainerCard = withRouter(EventContainer);

export default EventContainerCard;
