import React, { Component } from 'react';
import { MdVideocam, MdInsertDriveFile, MdClose } from 'react-icons/md';
import { Row, Form, Image } from 'react-bootstrap';
import  MediaView  from 'scenes/campaigns/components/media_view';
import { Utility } from 'utils';
import UploadFile  from 'shared/components/file_upload';
import Tooltip from '@material-ui/core/Tooltip';

export default class PostTemplate extends Component {
  render () {
    return (
      <Row className="mx-0">
        <Form.Group>
          <Form.Label className="font-16-medium">Enter post details</Form.Label>
          <div className="border form-border-radius" >
              <Form.Control
                as="textarea"
                className="post-textarea border-0 upload-box-sizing"
                placeholder="Type post details here"
                ref="description"
                id="description"
                value={this.props.description || ''}
                onChange={(e) => {this.props.changeDescription(e.target.value);}}
              />
            <div className="media-previewer mb-4 flex">
              {
                !Utility.isEmpty(this.props.imagePreviewUrl) &&
                <div className="position-relative d-inline-block ml-3 mb-1">
                  <div className="post-media-close position-absolute flex-center" onClick={ () => this.props.removeUploadedFile('image_url')}> <MdClose /> </div>
                  <Image src={this.props.imagePreviewUrl} className="post-media-preview" alt="image"/>
                </div>
              }
              {
                !Utility.isEmpty(this.props.videoPreviewUrl) &&
                <div className="position-relative d-inline-block ml-3 post-video-upload mb-1">
                  <div className="post-media-close position-absolute flex-center" onClick={ () => this.props.removeUploadedFile('video_url')} > <MdClose /> </div>
                  <MediaView videoUrl={this.props.videoPreviewUrl}/>
                </div>
              }
            </div>
            <div className="position-absolute post-media-uploader">
              <Form.Label className="postion-relative display-8">
                <Tooltip title="Upload Image">
                  <span className="font-bold display-xl-6 display-7 text-truncate text-width"><MdInsertDriveFile /></span>
                </Tooltip>
                <UploadFile
                  acceptTypes="image/x-png,image/gif,image/jpeg"
                  inputId="image_url"
                  handleFileChange={this.props.handleFileChange}
                  source="post"
                />
              </Form.Label>
              <Form.Label className="postion-relative display-6 ml-2">
                <Tooltip title="Upload Video">
                  <span className="font-bold display-xl-6 display-7 text-truncate text-width"><MdVideocam /></span>
                </Tooltip>
                <UploadFile
                  acceptTypes="video/mp4"
                  inputId="video_url"
                  handleFileChange={this.props.handleFileChange}
                  source="post"
                />
              </Form.Label>
            </div>
          </div>
          <div className="error errorIcon" id="postTemplateError" />
        </Form.Group>
      </Row>
    );
  }
}
