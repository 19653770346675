import React from 'react';
import SideCallout from "shared/components/templates/side_callout";
import { MemberGroupIcon } from 'shared/icons/icons';
import { Form } from 'react-bootstrap';
import { InputField, Textarea } from 'shared/components/form_elements';
import * as Utility from 'utils/utility';

const validateFields = ['attributeName','attributeDescription'];
class EditAttributeForm extends React.Component {

    constructor(props) {
        super(props);
    
        this.state = {
          formData: {
            attributeName: this.props.selectedAttributeItem.attributeName || '',
            attributeDescription: this.props.selectedAttributeItem.attributeDescription || ''
          },
        };
      }

      handleChange = (event) => {

        let formData = this.state.formData;
    
        formData = Utility.validateAndReturnFormData(this, formData, event, validateFields);
        this.setState({ formData });
      }

    render(){
    return(
        <>
        <SideCallout
          position="position-fixed create-campaign-margin"
          closeCallOut={this.props.onClose}
          calloutHeading="Edit Product Attributes"
          calloutHeadingSvg={<MemberGroupIcon fillClass="fill-black" />}
        >
        <div className="font-italic font-bold mt-3 font-18" >Additional Details</div>

        <div className="bg-light py-4 additional-field-details mt-4" >
          <Form.Group className="mb-4 pb-1">
            <Form.Label className="font-bold display-8 mb-3">
            </Form.Label>
            <InputField
              required={true}
              type="text"
              name="attributeName"
              ref="attributeName"
              handleChange={this.handleChange}
              placeholder="Add Field Name..."
              className="w-75"
              value={this.state.formData.attributeName}
            />
            <Textarea
              required={true}
              type="text"
              name="attributeDescription"
              ref="attributeDescription"
              placeholder="Add text…"
              handleChange={this.handleChange}
              value={this.state.formData.attributeDescription}
              className="scroll-y w-75 py-3 mt-3"
            />
          </Form.Group>
          <div className="w-75 text-right" >
            <button onClick={()=>{this.props.setAttributeValues(this.state.formData)}} className="btn text-primary bg-light-primary btn-rounded font-weight-bold px-5 py-2 font-18" >Save &amp; Add</button>
          </div>
        </div>
        </SideCallout>
        </>
    )
}
}
export default EditAttributeForm;
