export const NETWORK_TYPES = {
  Clients: "Neighborhoods",
  Parents: "Cities",
  Users: "Citizens",
  Admins: "Admins",
};

export const NETWORK = {
  Users: "Citizen",
  Clients: "Neighborhood",
  Parents: "City",
  Admins: "Managed Accounts",
};

export const CLIENT_ID = "0bc8dc7c-b5a3-4344-b313-819fc640f6b5";
export const GIL_PARENT_OWNER_ID = "cc932351-74bc-11ea-950b-71c6872dc7d9";

export const PRIMARY_COLOR = "#272262";

export const CRM_MODULES = {
  NETWORK: "ac836894-a352-4460-a011-09828c38ec10",
  CAMPAIGNS: "b6427f96-9cae-4ece-bb73-2dde4e57a734",
};

export const ACCOUNT_TYPE = {
  student: "a2ee3130-7681-11eb-9010-31966e29b0b4",
  parent: "9211f9d0-768d-11eb-9acb-c556ac41a913",
  teacher: "9cef8020-768d-11eb-9acb-c556ac41a913",
  mentor: "a674c970-768d-11eb-9acb-c556ac41a913",
  coach: "b5b6bb50-768d-11eb-9acb-c556ac41a913",
};

export const PLAN = {
  28: "Monthly",
  365: "Annual",
};

export const BADGES_IDS = {
  CAMPAIGN: "604611b9-997f-4d4b-a7e7-524caaisa4b9",
  GROUP: "9bed9123-06a7-4b1f-bf1f-4ed218ghzd79",
  EVENT: "8aa22364-8d6f-47c0-a149-d0hhzg679974",
};

export const NOTIFICATIONS_TYPES = {
  campaign_comments: "/campaign/",
  campaign_donation: "/campaign/",
  campaign_sharing: "/groups/",
  group_invitation: "/groups/",
  campaign_likes: "/campaign/",
  follow: "/profile/",
  new_direct_message: null,
  campaign_ff_sharing: "/campaign/",
  account_admin: "/profile/",
  neighbourhood_member: "/profile/",
};

export const NOTIFICATIONS_IDS = {
  CAMPAIGN_COMMENTS: "9a9f2fb4-c569-4b25-97b4-29a284630fef",
  CAMPAIGN_DONATION: "6c35dc50-c1f1-11ea-b3de-0242ac130004",
  CAMPAIGN_SHARING: "6c35dd4a-c1f1-11ea-b3de-0242ac130004",
  GROUP_INVITATION: "6c35de12-c1f1-11ea-b3de-0242ac130004",
  CAMPAIGN_LIKES: "6c35deda-c1f1-11ea-b3de-0242ac130004",
  FOLLOW: "6c35e0e2-c1f1-11ea-b3de-0242ac130004",
  NEW_DIRECT_MESSAGE: "6c35e1b4-c1f1-11ea-b3de-0242ac130004",
  CAMPAIGN_FF_SHARING: "6c35dc50-c1f1-11ea-b3de-0242jnzg6004",
  ACCOUNT_ADMIN: "6c35dc50-c1f1-11ea-b3de-126gcjag6004",
  ADD_MEMBER_PERMISSION: "941ba18c-c8b1-45fa-a0d3-4c3db3109692",
  EDIT_GROUP_PERMISSION: "6904a4ad-091f-4e18-9fff-35137cd0f196",
  REMOVE_ADD_MEMBER_PERMISSION: "10d7c37c-fcdb-443d-a152-1811aa2d04bf",
  REMOVE_EDIT_GROUP_PERMISSION: "ae9f26f1-b8ea-4afb-ab1c-1b19ff4bec00",
  GROUP_MESSAGE: "a4c9cda6-b003-45f1-8c77-ff1c7b92edd9",
  ADDED_IN_NEIGHBOURHOOD: "ef1ed0ba-4362-4a71-b0a3-6025f7b73892",
};

export const CLIENT_NAME = "Journey Passport";

export const NAMES_MAPPING = {
  CAMPAIGN: {
    story: {
      parent: "article",
      child: "chapter",
    },
    event: {
      parent: "event",
      child: "agenda",
    },
  },
  SUB_CAMPAIGN: "Chapter",
  SUB_CAMPAIGN_EVENT: "Agenda",
};

export const COMMUNITY_CARD_LIMIT = 5;

export const SUGGESTED_READS_LIMIT = 4;

export const RECOMMENDED_READS_LIMIT = 3;

export const NON_PROFITS_LIMIT = 5;

export const GROUPS_LIMIT = 3;

export const GROUPS_INVITE_MEM_LIMIT = 5;

export const GROUPS_CAMPAIGNS_LIMIT = 4;

export const GROUPS_EVENTS_LIMIT = 3;

export const GROUPS_INVITE_FOLLOWER_LIMIT = 5;

export const GROUPS_INVITE_FOLLOWING_LIMIT = 5;
