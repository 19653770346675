import { filter } from "lodash";
import React, { useEffect } from "react";
import { AiOutlineFileDone } from "react-icons/ai";
import { MdForum } from "react-icons/md";
import { connect } from "react-redux";
import { USER_ASSIGNMENT_DETAILS } from "../../../../../config/constants/api_endpoints";
import AssignmentInputPanal from "./AssignmentInputPanal";
import { Utility } from "utils";
import { toast } from "react-toastify";
import { showLoader } from "../../../../actions";

const Assignments = (props) => {
  useEffect(() => {
    if (props.LessonAllAssignments.length !== 0) {
      handleSubmitAssignment();
    }
  }, [props.LessonAllAssignments]);

  const handleSubmitAssignment = () => {
    const assignmentData = props.LessonAllAssignments[0];

    let attemptedQ = 0;
    let UnattemptedQ = 0;
    assignmentData &&
      assignmentData.question.forEach(function (val, index) {
        if (val.answers === null) {
          UnattemptedQ += 1;
        } else {
          attemptedQ += 1;
        }
      });
    if (UnattemptedQ === 0 && attemptedQ === assignmentData.question.length) {
      let totalCorrectAnswers = 0;
      assignmentData.question &&
        assignmentData.question.forEach(function (val, index) {
          const details = filter(
            val.question_options_attributes,
            (value) =>
              (value.is_correct === 1 && value.selected_answer === true) ||
              value.is_essay === true
          );
          if (details.length > 0) {
            totalCorrectAnswers = totalCorrectAnswers + 1;
          }
        });
      const score =
        (totalCorrectAnswers * 100) / assignmentData.question.length;

      const params = {
        assignment_id: assignmentData.id,
        user_id: props.user_id,
        campaign_id: assignmentData.campaign_id,
        total_attempted_questions: assignmentData.question.length,
        total_correct_answers: totalCorrectAnswers,
        score: score,
        status: 3,
      };
      props.showLoader(true);
      Utility.sendRequest(
        USER_ASSIGNMENT_DETAILS,
        2,
        params,
        (err, res, body) => {
          body = JSON.parse(body);
          if (body.error) {
            toast.error(body.error.message, { containerId: "private" });
            props.showLoader(false);
          } else {
            props.getUserAssignmentDetails();
            // props.getAllParentCampaigns();
            props.showLoader(false);
          }
        }
      );
    }
  };
  return (
    <>
      {props.isLesson && (
        <div className="course_panal_header">
          <AiOutlineFileDone className="courseSidebarIcon" />
          <div className="header_title">Assignment</div>
        </div>
      )}
      {props.lessondata !== null &&
        props.lessondata.length > 0 ? 
        props.lessondata
          .sort((assignment1, assignment2) =>
            assignment1.assignment_name.localeCompare(
              assignment2.assignment_name
            )
          )
          .map((data, index) => {
            return (
              <React.Fragment key={data.id}>
                {data && (
                  <>
                    {data.question &&
                      data.question.filter((data) => {
                        return data.question_type === 3;
                      }).length > 0 && (
                        <div className="lesson-assignment-container">
                          <div className="assignment-questions-container pt-2 overflow-x-hidden">
                            <>
                              {
                                // !props.isLesson &&

                                <div className="sub-item px-4 assignment-heading  d-flex mt-2 row ml-3 align-items-center">
                                  {" "}
                                  <div className="Sub-heading-section">
                                    <div className="section-heading">
                                      {/* {console.log("Section List",props.sectionList)} */}
                                      {props.sectionList &&
                                        props.sectionList !== null &&
                                        props.sectionList.map(
                                          (value, index) =>
                                            value.lessons &&
                                            value.lessons !== null &&
                                            value.lessons
                                              .sort(
                                                (assignment1, assignment2) =>
                                                  assignment1.heading.localeCompare(
                                                    assignment2.heading
                                                  )
                                              )
                                              .map((lesson) => {
                                                if (
                                                  data.campaign_id === lesson.id
                                                ) {
                                                  return (
                                                    <>
                                                      <h5>{lesson.heading}</h5>
                                                      {value.heading}
                                                    </>
                                                  );
                                                }
                                              })
                                        )}
                                    </div>
                                    <div className="section-sub-heading">
                                      {/* {data.assignment_name} */}
                                    </div>
                                  </div>
                                  {/* <button
                                      className="btn btn-primary mr-2 ml-auto btn-half-rounded px-5 font-weight-bold"
                                      onClick={() =>
                                        handleSubmitAssignment(assignmentData)
                                      }
                                    >
                                      Submit Assignment
                                    </button> */}
                                </div>
                              }
                              <div
                                className={
                                  props.isLesson
                                    ? "lesson_answer_padding"
                                    : "px-3"
                                }
                              >
                                {data.question &&
                                  data.question.map((questionData, index) => {
                                    return (
                                      questionData.question_type === 3 && (
                                        <AssignmentInputPanal
                                          {...props}
                                          data={data}
                                          assignmentData={data}
                                          questionData={questionData}
                                          handleSubmitAssignment={
                                            handleSubmitAssignment
                                          }
                                        />
                                      )
                                    );
                                  })}
                              </div>
                            </>
                          </div>
                        </div>
                      )}
                  </>
                )}
              </React.Fragment>
            );
          }) : 
          <div className="header_title no-data-styling">No Assignment Found</div>
        }
    </>
  );
};

const mapActionstoProps = {
  showLoader,
};

export default connect(null, mapActionstoProps)(Assignments);
