import React, { Component } from "react";
import { connect } from "react-redux";
import UserComment from "./UserComment";
import * as Utility from "utils/utility";
import { COMMENT_TOOL_BAR_OPTION } from "../../../../../config/constants";
import CommentTool from "./CommentTool";
import RichTextEditor from "../../../rich_text_editor";

import CloseIcon from "@mui/icons-material/Close";
import { IconButton } from "@mui/material";
import ChatEditor from "../../../ChatEditor";

export class Discussion extends Component {
  constructor(props) {
    super(props);
    this.state = {
      commentTime: null,
      isEdit: false,
      commentMediasList: null,
      isReply: false,
      typeOfMedia: "",
      replyData: "",
      replyMedia: null,
      commentData: "",
      formName: "add_primary_product",
    };
  }

  handleEdit = () => {
    this.setState({
      isEdit: !this.state.isEdit,
      commentData: this.props.commentData.description,
      isReply: false,
    });

    if (
      this.props.commentData.image_url &&
      this.props.commentData.image_url.length > 0
    ) {
      this.setState({
        commentMediasList: this.props.commentData.image_url[0],
        typeOfMedia: "image",
      });
    }
    if (
      this.props.commentData.file_url &&
      this.props.commentData.file_url.length > 0
    ) {
      this.setState({
        commentMediasList: this.props.commentData.file_url[0],
        typeOfMedia: "doct",
      });
    }
    if (
      this.props.commentData.video_url &&
      this.props.commentData.video_url.length > 0
    ) {
      this.setState({
        commentMediasList: this.props.commentData.video_url[0],
        typeOfMedia: "video",
      });
    }
  };

  handleReply = () => {
    if (this.props.isDiscussion) {
      this.props.setScrollToReplyBox(!this.state.isReply);
    }
    this.setState({
      isEdit: false,
      isReply: !this.state.isReply,
    });
  };

  changeDescription = (data, id) => {
    var dataHash = this.state.commentData;
    dataHash = data;
    Utility.isRichTextEmpty(id, data, this.state.formName);
    this.setState({
      commentData: dataHash,
    });
  };

  componentDidMount() {
    const updatedAt = new Date(this.props.commentData.created_at);

    this.setState({
      commentTime: updatedAt.toLocaleString("en-US", {
        day: "numeric", // numeric, 2-digit
        year: "numeric", // numeric, 2-digit
        month: "long", // numeric, 2-digit, long, short, narrow
      }),
    });
  }

  render() {
    return (
      <>
        <div
          className={`${
            this.props.isCampaign
              ? "w-100"
              : this.props.isReply
              ? "w-76"
              : this.props.iscommentReply
              ? "w-75"
              : this.props.isChatDiscussion
              ? "w-100"
              : "w-77"
          } comment_container mx-auto`}
        >
          {this.props.commentData.level === 1 && (
            <div className="comment_date">
              <p>
                {this.props.commentData.created_at && this.state.commentTime}
              </p>
            </div>
          )}
          <div className="lesson_comment_container">
            {!this.state.isEdit ? (
              <UserComment
                containerClassToOverRide={`commentSectionContainer`}
                secondaryContainerClasses={`commentSectionWrapper`}
                handleEdit={this.handleEdit}
                handleReply={this.handleReply}
                isDiscussion={this.props.isDiscussion}
                {...this.state}
                {...this.props}
              />
            ) : (
              <>
                <div className="comment-input position-relative comment-inline-input">
                  {!this.props.isRegion && (
                    <RichTextEditor
                      id="comment"
                      name="comment"
                      placeholder="Start a Conversation ....."
                      data={this.state.commentData}
                      changeDescription={this.changeDescription}
                      isComment={true}
                      toolbar={COMMENT_TOOL_BAR_OPTION}
                    />
                  )}
                  <CommentTool
                    purpose="Edit"
                    handleEdit={this.handleEdit}
                    Data={this.props.commentData}
                    commentData={this.state.commentData}
                    getCommentData={this.props.getCommentData}
                    changeDescription={this.changeDescription}
                    isRegion={this.props.isRegion}
                    isCampaign={this.props.isCampaign}
                  />
                  <div className="position-absolute closeIconEdit">
                    <IconButton
                      className="comment-button"
                      onClick={this.handleEdit}
                    >
                      <CloseIcon />
                    </IconButton>
                  </div>
                </div>
              </>
            )}
          </div>
        </div>
        {this.state.isReply && (
          <div
            className={`comment-input comment-inline-input w-75 mx-auto ${
              this.props.isDiscussion ? "replyChatBox" : ""
            }`}
          >
            <div className="reply-container" id="replyContainerId">
              <>
                {!this.props.isRegion && (
                  <div
                    className={`${
                      this.props.isDiscussion ? "position-relative" : ""
                    }`}
                  >
                    <RichTextEditor
                      id="comment"
                      name="comment"
                      placeholder="Start a Conversation ....."
                      data={this.state.commentData}
                      changeDescription={this.changeDescription}
                      isComment={true}
                      toolbar={COMMENT_TOOL_BAR_OPTION}
                    />
                    {this.props.isDiscussion && (
                      <div className="position-absolute closeIconDiss">
                        <IconButton
                          className="comment-button"
                          onClick={this.handleReply}
                        >
                          <CloseIcon />
                        </IconButton>
                      </div>
                    )}
                  </div>
                )}

                <CommentTool
                  purpose="Reply"
                  {...this.props}
                  parent_id={this.props.commentData.id}
                  handleReply={this.handleReply}
                  getCommentData={this.props.getCommentData}
                  commentData={this.state.commentData}
                  changeDescription={this.changeDescription}
                  isRegion={this.props.isRegion}
                  isCampaign={this.props.isCampaign}
                />
              </>
            </div>
          </div>
        )}
      </>
    );
  }
}

const mapStateToProps = (state) => ({});

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(Discussion);
