import React, { useState, useEffect } from "react";
import { TOPIC_CATEGORIES } from "config/constants/api_endpoints";
import { Utility } from "utils";
import { toast } from "react-toastify";
import { CLIENT_ID, CRM_MODULES } from "config/constants/config";
import { GIL_CLIENT_ID } from "config/constants/index";
import { CAMPAIGN_TYPES_IDS } from "config/constants";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";

export default function Topics({
  isRegion = false,
  setTopicsFilter,
  topicsFilter = [],
}) {
  const [topics, setTopics] = useState([]);
  const userData = useSelector((state) => state.userData);

  useEffect(() => {
    if (CLIENT_ID === GIL_CLIENT_ID) getTopics();
  }, []);

  const getTopics = () => {
    const params = {
      module_id: CRM_MODULES.CAMPAIGNS,
      client_id: CLIENT_ID,
      user_client_id: userData.id,
      campaign_type_id: CAMPAIGN_TYPES_IDS.story,
      fromHomePage: true,
    };

    Utility.sendRequest(TOPIC_CATEGORIES, 1, params, (err, res, body) => {
      body = JSON.parse(body);

      if (body.error) {
        toast.error(body.error.message, { containerId: "private" });
      } else {
        setTopics(body.data);
      }
    });
  };

  const handleTopicsFilter = (name) => {
    if (!topicsFilter.includes(name))
      setTopicsFilter((item) => [...item, name]);
    else topicsFilter.splice(topicsFilter.indexOf(name), 1);
  };

  return (
    <div
      className={`interest-section mb-42 topics-section-outer px-0 ml-lg-n4 border-bottom border-lg-none ${
        isRegion ? "mt-3" : "mt-5"
      }`}
    >
      <h1 className="font-24-bold mb-3 mb-lg-4  pl-md-4 pl-3 pr-0 ">
        <img
          src={require("assets/images/topics.png")}
          height="25px"
          width="25px"
        />{" "}
        {isRegion ? "Filter By Topic" : "Topics"}
      </h1>
      <div className="d-flex slider-mob-inner  pl-lg-4 pl-3">
        {!Utility.isEmpty(topics) &&
          topics.map((e) => {
            return (
              <div
                className={`interest-inner overflow-hidden mr-3 mb-3  ${
                  isRegion && topicsFilter.includes(e.name)
                    ? "topics-filter"
                    : "bg-white"
                }`}
                key={e.id}
              >
                <Link
                  to={
                    !isRegion && {
                      pathname: `/topics/${e.id}`,
                      goBackLink: "/home",
                      // image_url: e.image_url,
                      // calledFrom: e.name,
                      color: e.color_code,
                    }
                  }
                  onClick={() => isRegion && handleTopicsFilter(e.name)}
                >
                  {/* <img src={!Utility.isEmpty(e.image_url) ? e.image_url : require('assets/images/img_not_available.png') } alt="" /> */}
                  <div
                    className="display-8 font-bold interest-text text-truncate text-center"
                    style={{
                      color:
                        isRegion && topicsFilter.includes(e.name)
                          ? "white"
                          : "#422A66",
                    }}
                  >
                    #{e.name}
                  </div>
                </Link>
              </div>
            );
          })}
      </div>
    </div>
  );
}
