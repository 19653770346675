import React, { useState, useEffect } from "react";
import { toast } from "react-toastify";
import { useSelector } from "react-redux";
import * as Utility from "utils/utility";
import { CLIENT_ID } from "config/constants/config";
import { CAMPAIGN_TYPES_IDS } from "config/constants";
import {
  showLoader,
  updateSeeAll,
  updateIsChatFormOpen,
  updateChatFriend,
} from "shared/actions";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import EventContainerCard from "../EventsContainer";
import Create_course from "../../cards/create_course";
import CampaignSelectionForm from "../../../../scenes/users/components/create_campaign/campaign_selection_form";
import { useDispatch } from "react-redux";
import { CAMPAIGN_STORIES } from "config/constants/api_endpoints";
import {
  MEMBER_ALL_EVENT,
  REGIONS_LIST,
} from "../../../../config/constants/api_endpoints";
import { GrSearch } from "react-icons/gr";
import { EventIcon } from "../../../icons/icons";
import { DesktopDatePicker } from "../campaign/components/desktop_datepicker";
import moment from "moment";
import { GET_JOINED_NEIGHBORHOOD } from "../../../../config/constants/api_endpoints";
import { Skeleton } from "@mui/material";

function Events(props) {
  const [startDate, setStartDate] = useState(new Date());
  const [endDate, setEndDate] = useState("");
  const [isStartDateChanged, setIsStartDateChanged] = useState(false);
  const [isEndDateChanged, setIsEndDateChanged] = useState(false);
  const userData = useSelector((state) => state.userData);
  const refreshEventsApi = useSelector((state) => state.refreshEventsApi);
  const userId = props.userId ? props.userId : userData.id;
  const [campaignsTopStories, setCampaignsTopStories] = useState([]);
  const [campaignsFeaturedStories, setCampaignsFeaturedStories] = useState([]);
  const [flag, setFlag] = useState(false);
  const [myEvents, setMyEvents] = useState([]);
  const [myEventsId, setMyEventsId] = useState([]);
  const [allMyEvents, setAllMyEvents] = useState([]);
  const [search, setSearch] = useState("");
  const [categoriesList, setCategoriesList] = useState([]);
  const [selectedItem, setSelectedItem] = useState("");
  const [allFeaturedStories, setAllFeaturedStories] = useState([]);
  const [allTopStories, setAllTopStories] = useState([]);
  const [refetch, setRefetch] = useState(false);
  const [joinedNeighborhood, setJoinedNeighborhood] = useState([]);


  let debouncedFn = null;

  const [liveFeed, setLiveFeed] = useState({
    event: {
      data: [],
      loading: false,
    },
    featuredEvent: {
      data: [],
      loading: false,
    },
    myEvents: {
      data: [],
      loading: false,
    }
  });
  const dispatch = useDispatch();
  useEffect(() => {
    props.userData.user_id && getJoinednaighbouHoods();
  }, [props.userData.user_id]);

  // API for getting featured/All courses, news, events
  const loadAllStories = async ({
    user_client_id,
    campTypeId,
    isFeatured,
    userId,
    start_at,
    end_at,
    get_public_private,
    type
  }) => {
    liveFeed[type] = {
      data: [],
      loading: true,
    };
    setLiveFeed({ ...liveFeed });
    const params = {
      publisher_network_id: CLIENT_ID,
      type: campTypeId,
      user_client_id,
      is_featured: isFeatured,
      user_id: userId,
      start_at: start_at,
      end_at: end_at,
      get_public_private: get_public_private,
    };

    await Utility.sendRequest(CAMPAIGN_STORIES, 1, params, (err, res, body) => {
      body = JSON.parse(body);

      if (body.error) {
        toast.error(body.error.message, { containerId: "private" });
      } else {
        const data = body.data.filter(
          (items) =>
            items.corporate_is_active === true ||
            items.corporate_is_active === null
        );
        liveFeed[type] = {
          data: data,
          loading: false,
        };
        setLiveFeed({ ...liveFeed });
        // if (isFeatured === true) {
        //   // For Setting Featured Events data
        //   setAllFeaturedStories(Data);
        //   //   For Filtering Featured Events starting from current date
        // } else {
        //   // For Setting All Events data
        //   setAllTopStories(Data);
        //   //   For Filtering All Events starting from current date
        // }
      }
    });
  };

  useEffect(() => {
    setMyEventsId(allMyEvents.map((item) => item.id));
  }, [allMyEvents]);

  const getJoinednaighbouHoods = () => {
    // dispatch(showLoader(true));
    Utility.sendRequest(
      `${GET_JOINED_NEIGHBORHOOD}?user_id=${props.userData.user_id}&platform_id=${CLIENT_ID}`,
      1,
      {},
      (err, res, body) => {
        body = JSON.parse(body);

        if (body.error) {
          // dispatch(showLoader(false));
          toast.error(body.error.message, { containerId: "private" });
        } else {
          // dispatch(showLoader(false));

          setJoinedNeighborhood(
            body.map((item) => {
              return item.client_id;
            })
          );
          // dispatch(
          //   updateNeighborList(
          //     body.map((item) => {
          //       return item.client_id;
          //     })
          //   )
          // );
        }
      }
    );
  };
  const getRegions = () => {
    const params = {};
    Utility.sendRequest(REGIONS_LIST, 1, params, (err, res, body) => {
      body = JSON.parse(body);
      if (body.error) {
        toast.error(body.error.message, { containerId: "private" });
      } else {
        setCategoriesList(body.data);
      }
    });
  };

  const getMyEvents = (start_at, end_at) => {
    let params;
    if (start_at && end_at) {
      params = {
        start_at: start_at,
        end_at: end_at,
      };
    } else {
      params = {};
    }
    liveFeed["myEvents"] = {
      data: [],
      loading: true,
    };
    setLiveFeed({ ...liveFeed });
    Utility.sendRequest(
      `${MEMBER_ALL_EVENT}/${userData.id}`,
      1,
      params,
      (err, res, body) => {
        body = JSON.parse(body);
        if (body.error) {
          toast.error(body.error.message, { containerId: "private" });
        } else {
          setRefetch(true);
          // For Setting My Events data
          const data = body.filter(
              (items) =>
                items.corporate_is_active === true ||
                items.corporate_is_active === null
            )
          liveFeed["myEvents"] = {
            data: data,
            loading: false,
          };
          setLiveFeed({ ...liveFeed });
        }
      }
    );
  };

  useEffect(() => {
    getRegions();
  }, []);

  useEffect(() => {
    const campTypeId = CAMPAIGN_TYPES_IDS.event;
    loadAllStories({
      campTypeId: campTypeId,
      start_at: startDate && endDate && moment(startDate).format("YYYY-MM-DD"),
      end_at: startDate && endDate && moment(endDate).format("YYYY-MM-DD"),
      userId: userData.user_id,
      get_public_private: true,
      type: "event",
    });
    loadAllStories({
      campTypeId: campTypeId,
      isFeatured: true,
      user_client_id: userData.id,
      start_at: startDate && endDate && moment(startDate).format("YYYY-MM-DD"),
      end_at: startDate && endDate && moment(endDate).format("YYYY-MM-DD"),
      //   start_at: start_at,
      userId: userData.id,
      type: "featuredEvent"
    });
    if (refetch) {
      getMyEvents(
        startDate && endDate && moment(startDate).format("YYYY-MM-DD"),
        startDate && endDate && moment(endDate).format("YYYY-MM-DD")
      );
    }
  }, [startDate, endDate]);

  useEffect(() => {
    // if (!refetch) {
    getMyEvents(
      startDate && endDate && moment(startDate).format("YYYY-MM-DD"),
      startDate && endDate && moment(endDate).format("YYYY-MM-DD")
    );
    // }
  }, [refreshEventsApi]);

  const getFormattedDate = (date) => {
    return (
      date.getFullYear() +
      "-" +
      (date.getMonth() + 1 > 9
        ? date.getMonth() + 1
        : "0" + (date.getMonth() + 1)) +
      "-" +
      (date.getDate() > 9 ? date.getDate() : "0" + date.getDate())
    );
  };

  return (
    <div className="pwa-callout-body  pt-4  courses-page-container pb-2">
      {selectedItem === "events" && (
        <CampaignSelectionForm
          position="position-fixed create-campaign-margin"
          userData={userData}
          setSelectedItem={setSelectedItem}
          selectedItem={selectedItem}
          joinedNeighborhood={joinedNeighborhood}
        />
      )}
      <>
        <div className="d-flex justify-content-between align-items-center">
          <div className="w-25 pl-3 newsSearchBar">
            <div
              className={`pt-5 d-flex align-items-center mb-3 mb-lg-4  pl-4 pl-md-4  pr-0`}
            >
              <EventIcon w={25} h={22} />
              <span className="ml-2 font-24-bold">Events</span>
            </div>
            <div className="SeactionSearchContainer w-100 position-relative pl-4">
              <div className="searchSectionWrapper bg-white mb-5">
                <input
                  className="SeactionSearch"
                  placeholder={`Search Events..`}
                  onChange={(ev) => setSearch(ev.target.value)}
                />

                <span className="SeactionSearchIcon">
                  <GrSearch className="regionIcon" />
                </span>
              </div>
            </div>
          </div>
          {props.accounts &&
            props.accounts.Clients &&
            props.accounts.Clients.length > 0 && (
              // <div className="w-25 mr-4">
              <Create_course
                setSelectedItem={setSelectedItem}
                heading={"EVENT"}
                item="event"
                onClick={() => props.history.push("/create-event")}
                className="mt-5 ml-4 w-100"
              />
              // </div>
            )}
          <div className="datePickerContainer mt-4 d-flex justify-content-end w-100 mb-3">
            <div className="startDatePicker">
              <p className="startDate">Start Date</p>
              <div className="mr-4">
                <DesktopDatePicker
                  value={startDate}
                  setValue={setStartDate}
                  maxDate={endDate}
                  setIsChanged={setIsStartDateChanged}
                />
              </div>
            </div>
            <div className="endDatePicker">
              <p className="endDate">End Date</p>
              <div className="mr-4">
                <DesktopDatePicker
                  value={endDate}
                  setValue={setEndDate}
                  minDate={startDate}
                  setIsChanged={setIsEndDateChanged}
                  isEmpty={!isEndDateChanged}
                />
              </div>
            </div>
          </div>
        </div>
        <>
          {flag && !liveFeed.event.loading && !liveFeed.featuredEvent.loading && (
            <div className="d-flex align-items-center justify-content-center mt-5">
              <div className="px-5">
                <span className="font-bold display-6">No Records Found</span>
              </div>
            </div>
          )}
          <div className="mt-4">
            <EventContainerCard
              eventsData={liveFeed.myEvents.data}
              setFlag={setFlag}
              myEventsId={myEventsId}
              PanalTitle={"My Events"}
              category="featured"
              type={"events"}
              categoriesList={categoriesList}
              isArticles={false}
              search={search.toLowerCase()}
              joinedNeighborhood={joinedNeighborhood}
              loading= {liveFeed.myEvents.loading}
              // width={CLIENT_ID===GIL_CLIENT_ID && props.type==="suggestedReads" && "93vw"}
            />
            <div className="mt-4">
              <EventContainerCard
                eventsData={liveFeed.featuredEvent.data}
                myEventsId={myEventsId}
                setFlag={setFlag}
                PanalTitle={"Featured Events"}
                category="featured"
                type={"events"}
                categoriesList={categoriesList}
                isArticles={false}
                search={search.toLowerCase()}
                joinedNeighborhood={joinedNeighborhood}
                loading= {liveFeed.featuredEvent.loading}
                // width={CLIENT_ID===GIL_CLIENT_ID && props.type==="suggestedReads" && "93vw"}
              />
            </div>
            <div className="mt-4">
            <EventContainerCard
                eventsData={liveFeed.event.data}
                myEventsId={myEventsId}
                setFlag={setFlag}
                PanalTitle={"All Events"}
                type={"events"}
                categoriesList={categoriesList}
                isArticles={false}
                search={search.toLowerCase()}
                joinedNeighborhood={joinedNeighborhood}
                loading= {liveFeed.event.loading}
                // width={CLIENT_ID===GIL_CLIENT_ID && props.type==="suggestedReads" && "93vw"}
              />
            </div>
          </div>

          {categoriesList.map((categoryDetails) => {
            return (
              <div key={categoryDetails.id} className="mt-4">
                <EventContainerCard
                  eventsData={
                    categoryDetails.name === "Global"
                      ? liveFeed.event.data.filter(
                          (data) =>
                            data.region_details &&
                            Object.keys(data.region_details).length > 0
                        )
                      : liveFeed.event.data.filter(
                          (data) =>
                            data.region_details &&
                            Object.keys(data.region_details).length > 0 &&
                            data.region_details.REGION_NAME ===
                              categoryDetails.name
                        )
                  }
                  myEventsId={myEventsId}
                  setFlag={setFlag}
                  PanalTitle={
                    categoryDetails.name === "Global"
                      ? "Global Events"
                      : categoryDetails.name
                  }
                  colorCode={categoryDetails.color_code}
                  type={"events"}
                  categoriesList={categoriesList}
                  isArticles={false}
                  search={search.toLowerCase()}
                  joinedNeighborhood={joinedNeighborhood}
                  // width={CLIENT_ID===GIL_CLIENT_ID && props.type==="suggestedReads" && "93vw"}
                />
              </div>
            );
          })}
        </>
      </>
    </div>
  );
}

const mapActionToProps = {
  showLoader,
  updateSeeAll,
  updateIsChatFormOpen,
  updateChatFriend,
};

const mapStateToProps = (state) => ({
  userData: state.userData,
  seeAll: state.seeAll,
  accounts: state.accountList,
});

export default connect(mapStateToProps, mapActionToProps)(Events);
