import React, { Component } from 'react';
import { InputField, DropDown } from 'shared/components/form_elements';
import { Row, Col, Form, Button } from 'react-bootstrap';
import CustomDropDownList  from 'shared/components/custom_drop_down_list';
import { Utility } from 'utils';
import PostTemplate from '../post_template';
import { CAMPAIGN_TYPES_IDS } from 'config/constants';

export default class PostForm extends Component {

  resetFormData = (event) => {
    event.preventDefault();
    /*
      this.refs.sustainable_development_goal_id.refs.sustainable_development_goal_id.selectedIndex = 0;
      this.refs.is_global_feed.refs.is_global_feed.checked = false;
      this.refs.is_network_feed.refs.is_network_feed.checked = false;
      this.refs.is_channel_feed.refs.is_channel_feed.checked = false;
    */
    this.props.resetFormData();
  }

  render () {
    const formData = this.props.formData;
    let categories = [];

    if (!Utility.isEmpty(this.props.categories)) categories = [...this.props.categories ];

    categories.unshift({ id: '', name: 'Select where you would like to post' });

    return (
      <div className="pt-lg-5 pt-3 create-story-form">
        <Row>
          <Col xs={12}>
            <Form.Group controlId="heading">
              <Form.Label className="font-16-medium">Post Name</Form.Label>
              <InputField
                type="text"
                placeholder="Enter the name of the post"
                name="heading"
                ref="heading"
                required={true}
                value={formData.heading}
                handleChange={this.props.handleChange}
              />
            </Form.Group>
          </Col>
        </Row>
        <PostTemplate
          ref="post_template"
          imagePreviewUrl={this.props.imagePreviewUrl}
          videoPreviewUrl={this.props.videoPreviewUrl}
          handleFileChange={this.props.handleFileChange}
          removeUploadedFile={this.props.removeUploadedFile}
          changeDescription={this.props.changeDescription}
          description={this.props.formData.description}
        />
        <Row>
          <Col xs={12}>
            <Form.Group controlId="website_url">
              <Form.Label className="font-16-medium">Enter Website URL</Form.Label>
              <InputField
                type="text"
                placeholder="Enter website URL..."
                name="website_url"
                ref="website_url"
                value={formData.website_url}
                handleChange={this.props.handleChange}
              />
            </Form.Group>
          </Col>
        </Row>
        {/*

        <Row>
          <Col xl={7} xs={12}>
            <Form.Group controlId="category_id" className="pt-3">
              <Form.Label className="font-16-medium">Where do you want to post?</Form.Label>
              <DropDown
                optionId="id"
                optionValue="name"
                required={true}
                name="category_id"
                ref="category_id"
                options={categories}
                handleChange={this.props.handleChange}
              />
            </Form.Group>
          </Col>
        </Row>
        <Row>
          <Col xl={7} xs={12}>
            <Form.Group controlId="non_profits" className="pt-3">
              <Form.Label className="font-16-medium">What organization do you want benefit with this post? <span className="font-16-light-italic pl-1">(optional)</span></Form.Label>
              <DropDown
                optionId="id"
                optionValue="name"
                name="non_profits"
                ref="non_profits"
                placeholder="Select a benefiting community orgainization"
                options={this.props.nonProfits}
                handleChange={this.props.handleChange}
              />
              <CustomDropDownList
                dataList={this.props.campaignNonProfits}
                removeSelectedItem={this.props.removeSelectedNonprofits}
              />
            </Form.Group>
          </Col>
        </Row>
        { /*<Row>
          <Col xl={6} xs={12}>
            <Form.Group controlId="broadcast" className="pt-3">
              <Form.Label className="font-16-medium">Where do you want to display this post? <span className="font-16-light-italic pl-1">(optional)</span></Form.Label>
              <Checkbox
                ref="is_global_feed"
                checkboxId="is_global_feed"
                name="is_global_feed"
                label="Post to global feed (Public)"
                handleChange={this.props.handleChange}
              />
              <Checkbox
                ref="is_network_feed"
                checkboxId="is_network_feed"
                name="is_network_feed"
                label="Post to network feed (Available to organizations in your network)"
                handleChange={this.props.handleChange}
              />
              <Checkbox
                ref="is_channel_feed"
                checkboxId="is_channel_feed"
                name="is_channel_feed"
                label="Post to channel feed (Available only to your audience)"
                handleChange={this.props.handleChange}
              />
            </Form.Group>
          </Col>
        </Row>
        */}
        {/*<Row>
          <Col xl={7} xs={12}>
            <Form.Group controlId="social_media_channel" className="pt-3">
              <Form.Label className="font-16-medium">Post to</Form.Label>
              <DropDown
                optionId="id"
                optionValue="social_media_channel"
                name="ssocial_media_channel"
                ref="social_media_channel"
                placeholder="Select a social media channel..."
                required={true}
                options={}
                handleChange={this.props.handleChange}
              />
            </Form.Group>
          </Col>
        </Row>*/}
        <Col xs={12} className="p-0 pt-4">
          <Button variant="link" type="submit" className="mx-sm-5 mx-3 font-light" onClick={(e) => this.resetFormData(e)}> Cancel </Button>
          <Button variant="primary" onClick={(e) => this.props.submitFormData(CAMPAIGN_TYPES_IDS.post, true, e)}>
           {Utility.isEmpty(this.props.campId) ? 'Publish' : 'Update'}</Button>
        </Col>
      </div>
    );
  }
}
