import React, { useState, useEffect, Fragment } from "react";
import WelcomeSection from "./welcome_section";
// import SuggestedReads from "shared/components/v2/suggested_reads";
import SuggestedCourses from "shared/components/v2/suggested_courses";
// import SuggestedArticles from "shared/components/v2/suggested_articles";
import SuggestedNews from "shared/components/v2/suggested_news";
import * as Utility from "utils/utility";
import Community from "shared/components/v2/community";
import { GET_JOINED_NEIGHBORHOOD } from "../../../../config/constants/api_endpoints";
import {
  SUGGESTED_READS_LIMIT,
  // COMMUNITY_CARD_LIMIT,
} from "config/constants/config";
import { connect, useSelector, useDispatch } from "react-redux";
// import { GETMYALLCHATFRIENDS } from "config/constants/api_endpoints";
import {
  openNeighborhoodForm,
  showLoader,
  updateAccountList,
  updateUserData,
  updateRefreshCampaignAPI,
  updateCampaignFormOpen,
  updateIsChatFormOpen,
  updateSeeAll,
  updateFooterLink,
} from "shared/actions";
import {
  // updateCampaignSelectedTab,
  // updateIsCampSelFormOpen,
  updateChatFriend,
} from "shared/actions";
// import ChatCallout from "shared/components/chat_callout.js";
// import CreateCampaign from "scenes/users/components/create_campaign";
// import { NoRecordFound } from "shared/components/no_record_found";
// import SeeAll from "shared/components/v2/see_all";
import cookie from "react-cookies";
// import { InputField } from "shared/components/form_elements";
import { subscribeUser } from "subscription.js";
// import { PASSPORT_TOOL_ID } from "config/constants";
// import EditPassport from "scenes/users/components/pwa/passport/edit_possport";
import {
  // GET_USERS_BY_NETWORKS,
  // DEFAULT_GROUPS,
  // GET_USER_TAGS,
  // GET_TAGS_BY_CATEGORIES,
  GETALLJOINEDGROUPS,
  CAMPAIGN_STORIES,
  // CAMPAIGNS_TOP_STORIES,
  FEATURED_COMMUNITY,
} from "config/constants/api_endpoints";
import { CLIENT_ID } from "config/constants/config";
import { CAMPAIGN_TYPES_IDS, GIL_CLIENT_ID } from "config/constants";
// import Avatar from "react-avatar";
// import { ChevronUpIcon } from "shared/icons/icons";
// import SideCallout from "shared/components/templates/side_callout";
import { Invite } from "scenes/networks/components/invite.js";
import socket from "utils/sockets.js";
import { toast } from "react-toastify";
// import { MdPhoto } from "react-icons/md";

import SuggestedEvents from "shared/components/v2/suggested_events";

const HomePage = (props) => {
  const refreshCampaignApi = useSelector((state) => state.refreshCampaignApi);
  const dispatch = useDispatch();
  // const [search, setSearch] = useState("");
  // const [isNewConversation, openNewConversation] = useState(false);

  // const [allGroups, setAllGroups] = useState([]);
  const [showInviteForm, setShowInviteForm] = useState(false);
  // const [showMyCalling, setShowMyCalling] = useState(false);
  const [featuredCommunityData, setFeaturedCommunity] = useState([]);
  const [joinedNeighborhood, setJoinedNeighborhood] = useState([]);
  const [loadingCommunity, setLoadingCommunity] = useState(false);

  const [liveFeed, setLiveFeed] = useState({
    event: {
      data: [],
      loading: false,
    },
    news: {
      data: [],
      loading: false,
    },
    course: {
      data: [],
      loading: false,
    },
    trendingNews: {
      data: [],
      loading: false,
    },
  });

  useEffect(() => {
    // if (Utility.pwaModeEnabled()) {
    //   getGroupsYouHaveJoined();
    // }
    if (props.location.calledFrom === "direct_message") {
      props.updateIsChatFormOpen(true);
    }

    if (cookie.load("sessionToken") !== undefined && props.userData)
      subscribeUser(props.userData.id);

    if (props.location.calledFrom === "suggestedReadsSeeAll") {
      props.updateSeeAll({ isOpen: "article", calledFrom: "article" });
    }
    if (props.location.calledFrom === "communitySeeAll") {
      props.updateSeeAll({ isOpen: "community", calledFrom: "community" });
    }
    if (Utility.pwaModeEnabled()) dispatch(updateFooterLink("home"));

    socket.emit("change-online-status", props.userData.id);

    return () => props.updateCampaignFormOpen(false);
  }, [props.userData]);

  useEffect(() => {
    if (
      showInviteForm ||
      props.isChatFormOpen
      // isNewConversation ||
      // showMyCalling
    ) {
      document.querySelector("body").classList.add("overflow-hidden");
    } else {
      document.querySelector("body").classList.remove("overflow-hidden");
    }
    // if (!props.isChatFormOpen || !props.isCampaignFormOpen) {
    //   getGroupsYouHaveJoined();
    // }
    // if (!Utility.isEmpty(props.userData)) {
    //   loadStories(CAMPAIGN_TYPES_IDS.story);
    // }
  }, [
    showInviteForm,
    props.isChatFormOpen,
    // isNewConversation,
    // showMyCalling,
    props.isCampaignFormOpen,
  ]);

  useEffect(() => {
    if (!Utility.isEmpty(props.userData)) {
      loadStories(CAMPAIGN_TYPES_IDS.courses, "", false, "course");
      loadStories(CAMPAIGN_TYPES_IDS.story, "", false, "news");
      loadStories(CAMPAIGN_TYPES_IDS.story, "", true, "trendingNews");
      loadStories(CAMPAIGN_TYPES_IDS.event, "", false, "event");
      featuredCommunity();
    }
  }, [props.userData]);

  const loadStories = (
    campTypeId,
    categoryId = "",
    trendingNews = false,
    type
  ) => {
    liveFeed[type] = {
      data: [],
      loading: true,
    };
    setLiveFeed({ ...liveFeed });

    let params = trendingNews
      ? {
          publisher_network_id: CLIENT_ID,
          type: campTypeId,
          category_id: categoryId,
          ranking: 1,
        }
      : {
          publisher_network_id: CLIENT_ID,
          type: campTypeId,
          category_id: categoryId,
          user_id: props.userData.user_id,
          user_client_id: props.userData.id,
          is_featured: true,
        };

    if (props.userData.network === "clients" && !trendingNews)
      params = {
        ...params,
        corporate_id: props.userData.client_id,
      };

    Utility.sendRequest(CAMPAIGN_STORIES, 1, params, (err, res, body) => {
      body = JSON.parse(body);

      if (body.error) {
        toast.error(body.error.message, { containerId: "private" });
      } else {
        const Data = body.data.filter(
          (items) =>
            items.corporate_is_active === true ||
            items.corporate_is_active === null
        );
        liveFeed[type] = {
          data: Data,
          loading: false,
        };
        setLiveFeed({ ...liveFeed });
      }
    });
  };
  useEffect(() => {
    props.userData.user_id && getJoinednaighbouHoods();
  }, [props.userData.user_id]);

  const getJoinednaighbouHoods = () => {
    dispatch(showLoader(true));
    Utility.sendRequest(
      `${GET_JOINED_NEIGHBORHOOD}?user_id=${props.userData.user_id}&platform_id=${GIL_CLIENT_ID}`,
      1,
      {},
      (err, res, body) => {
        body = JSON.parse(body);
        dispatch(showLoader(false));

        if (body.error) {
          toast.error(body.error.message, { containerId: "private" });
        } else {
          setJoinedNeighborhood(
            body.map((item) => {
              return item.client_id;
            })
          );

          // console.log("body.map ", body);
          // dispatch(
          //   updateNeighborList(
          //     body.map((item) => {
          //       return item.client_id;
          //     })
          //   )
          // );
        }
      }
    );
  };
  const featuredCommunity = () => {
    const params = {
      platform_id: CLIENT_ID,
      user_id: props.userData.user_id,
      is_client_active: true,
      get_joined: true,
    };
    setLoadingCommunity(true);
    Utility.sendRequest(FEATURED_COMMUNITY, 1, params, (err, res, body) => {
      body = JSON.parse(body);

      if (body.error) {
        toast.error(body.error.message, { containerId: "private" });
      } else {
        setFeaturedCommunity(body.data);
        setLoadingCommunity(false);
      }
    });
  };

  // const handleTabSelectionClick = (arg) => {
  //   dispatch(updateIsCampSelFormOpen(false));
  //   dispatch(updateCampaignSelectedTab(arg));
  //   dispatch(updateCampaignFormOpen(true));
  // };

  // const getGroupsYouHaveJoined = () => {
  //   const params = {
  //     user_client_id: props.userData.id,
  //     search,
  //   };
  //   Utility.sendRequest(GETALLJOINEDGROUPS, 1, params, (err, res, body) => {
  //     body = JSON.parse(body);
  //     if (body.error) {
  //       // console.log(body.error);
  //     } else {
  //       setAllGroups(body.data);
  //     }
  //   });
  // };

  // const HomePwaCard = (props) => {
  //   return (
  //     <div className="hpc-outer mb-4 overflow-hidden px-3 px-lg-0">
  //       <div className="bg-primary flex-center ">
  //         <img
  //           src={require("assets/images/city_specific_images/gil-white.png")}
  //           alt=""
  //         />
  //       </div>
  //       <div className="hpc-inner">
  //         <button
  //           className="btn btn-sm btn-outline-primary p-0 w-100 text-uppercase border-radius-8"
  //           onClick={props.btnFuction}
  //         >
  //           {props.btnName}
  //         </button>
  //       </div>
  //     </div>
  //   );
  // };

  // useEffect(() => {
  //   dispatch(
  //     setSelectedLocation({
  //       country: country === "Select a Country" ? "" : country,
  //       region: regions.regions,
  //     })
  //   );
  //   localStorage.setItem(
  //     "country",
  //     country === "Select a Country" ? "" : country
  //   );
  //   localStorage.setItem("region", regions.regions);
  // }, [country, regions]);

  // console.log(
  //   "trendingNew",
  //   trendingNew
  // );
  useEffect(() => {}, [liveFeed]);

  return (
    <>
      <div
        className="h-md-100 scroll-y dashboard-page-container gil-dashboard"
        style={{ backgroundColor: "#F5F5F5" }}
      >
        {/* {Utility.pwaModeEnabled() && (
          <div className="pt-3 pb-0 border-bottom">
            <h1 className="font-24-bold mb-3 home-page-heading pl-3">
              Get Started
            </h1>
            <div className="d-flex overflow-auto pl-3 gs-outer">
              {Utility.isEmpty(props.userData) && (
                <HomePwaCard
                  btnName="Register"
                  btnFuction={() => {
                    props.history.push("/sign_up");
                  }}
                />
              )}
              {!Utility.isEmpty(props.userData) && (
                <>
                  <HomePwaCard
                    btnName="Create Group"
                    btnFuction={() => {
                      handleTabSelectionClick("group");
                    }}
                  />
                  <HomePwaCard
                    btnName="Setup Passport"
                    btnFuction={() => {
                      setShowMyCalling(true);
                    }}
                  />
                  <HomePwaCard
                    btnName="Invite Friends"
                    btnFuction={() => {
                      setShowInviteForm(true);
                    }}
                  />
                </>
              )}
            </div>
          </div>
        )} */}
        {showInviteForm && (
          <div className="pwa-invite-callout shar-form search-form">
            <Invite
              onClose={() => setShowInviteForm(false)}
              userData={props.userData}
              fromHomePage={true}
              changeLoader={(val) => props.showLoader(val)}
            />
          </div>
        )}
        <WelcomeSection
          isFormOpen={props.isCampaignFormOpen}
          setIsFormOpen={(arg) => props.updateCampaignFormOpen(arg)}
          showLoader={props.showLoader}
          history={props.history}
          feeds={
            liveFeed.trendingNews.data &&
            liveFeed.trendingNews.data.length !== 0 &&
            liveFeed.trendingNews.data
          }
          loadingTrending={liveFeed.trendingNews.loading}
        />

        <div
          className={"pt-3 pt-lg-0" /* home-page-padding ml-2 pt-3 pt-lg-0 */}
        >
          <SuggestedCourses
            refresh={refreshCampaignApi}
            heading="Featured Courses"
            limit={SUGGESTED_READS_LIMIT}
            showHeading={true}
            history={props.history}
            data={liveFeed.course.data}
            seeAll={true}
            isUserLoggedIn={!Utility.isEmpty(props.userData)}
            userData={props.userData}
            setSeeAll={(value) => {
              props.updateSeeAll({ isOpen: value, calledFrom: value });
            }}
            fromMedia={true}
            joinedNeighborhood={joinedNeighborhood}
            loadingCourse={liveFeed.course.loading}
          />

          <SuggestedNews
            refresh={refreshCampaignApi}
            heading="Featured Articles"
            limit={SUGGESTED_READS_LIMIT}
            showHeading={true}
            history={props.history}
            seeAll={true}
            isUserLoggedIn={!Utility.isEmpty(props.userData)}
            userData={props.userData}
            feeds={liveFeed.news.data}
            setSeeAll={(value) => {
              props.updateSeeAll({ isOpen: value, calledFrom: value });
            }}
            fromMedia={true}
            joinedNeighborhood={joinedNeighborhood}
            loadingFeeds={liveFeed.news.loading}
          />

          <SuggestedEvents
            data={liveFeed.event.data}
            history={props.history}
            joinedNeighborhood={joinedNeighborhood}
            loadingEvent={liveFeed.event.loading}
          />

          <Community
            props={props}
            data={featuredCommunityData}
            loadingCommunity={loadingCommunity}
            isFeatured={true}
            setSeeAll={(value) => {
              props.updateSeeAll({ isOpen: value, calledFrom: value });
            }}
          />
        </div>
      </div>
    </>
  );
};

const mapActionToProps = {
  showLoader,
  updateRefreshCampaignAPI,
  openNeighborhoodForm,
  updateAccountList,
  updateUserData,
  updateCampaignFormOpen,
  updateIsChatFormOpen,
  updateSeeAll,
  updateChatFriend,
};

const mapStateToProps = (state) => ({
  userData: state.userData,
  isLoading: state.isLoading,
  accountList: state.accountList,
  neighborhoodState: state.neighborhoodState,
  isCampaignFormOpen: state.isCampaignFormOpen,
  isChatFormOpen: state.isChatFormOpen,
  chatFriend: state.chatFriend,
  seeAll: state.seeAll,
  refreshCampaignApi: state.refreshCampaignApi,
});

export default connect(mapStateToProps, mapActionToProps)(HomePage);
