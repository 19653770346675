import React from 'react';
import SideCallout from "shared/components/templates/side_callout";
import { MemberGroupIcon, CircleFilledAddLargeIcon } from 'shared/icons/icons';
import { Form } from 'react-bootstrap';
import { InputField } from 'shared/components/form_elements';
import UploadFile from 'shared/components/file_upload';
import { SketchPicker } from 'react-color';
import { toast } from "react-toastify";
import { PRODUCT_TYPE, API_PATH } from 'config/constants/api_endpoints';
import * as Utility from 'utils/utility';
import axios from 'axios';

const validateFields = ['name', 'description', 'embedded_image_link'];

class ProductTypeForm extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      formData: {
        name: props.typeFormData.menu_name || '',
        color_code: props.typeFormData.color_code || '',
        product_type_id: props.typeFormData.id || "",
      },
      imageFile: '',
      imagePreviewUrl: props.typeFormData.image_url || '',
      formName: 'create_category',
    };
  }
  createParams = (formData) => {
    const userData = this.props.userData;

    return {
      name: formData.name,
      color_code: formData.color_code,
      created_by: userData.id,
      updated_by: userData.id,
      platform_id: userData.platform_id,
      user_client_id: userData.id,
    };
  }

  handleChange = (event) => {
    let formData = this.state.formData;

    if (event.target.name === 'color_code') this.validateColorField();

    formData = Utility.validateAndReturnFormData(this, formData, event, validateFields, this.state.formName);

    if (event.target.name === 'color_code') {
      if (event.target.value.length === 1 && event.target.value !== '#') formData.color_code = `#${formData.color_code}`;
    }

    this.setState({ formData });
  }

  validateColorField = () => {
    const element = Utility.getElementById('color_code');
    const error = Utility.getElementById(`color_codeError`);

    if (element.value.length > 7) {
      if (error) error.textContent = 'Only 6 characters allowed';
      return false;
    } else {
      if (error) error.textContent = null;
      return true;
    }
  }

  handleChangeComplete = (color) => {
    const formData = this.state.formData;
    formData.color_code = color.hex;
    this.openColorSelector();

    this.setState({ formData });
  };

  openColorSelector = () => {
    this.setState((state) => {
      return { showColorSelector: !state.showColorSelector };
    });
  }

  getImageUrl = async () => {
    let res = "";
    try {
      const resp = await axios.get(`${API_PATH}/getImageUrl`);
      res = resp.data.data;
    } catch (err) {
       console.error(err);
    }
    return res;
  }

  submit = async (e) => {
    e.preventDefault();
    const isFormValid = this.handleFormErrors();

    if (!isFormValid) {
      toast.error('Fill the necessary details', { containerId: 'public' });
      return;
    }
    this.props.showLoader(true);
   let imageURL;
    if(!Utility.isEmpty(this.state.imagePreviewUrl)){
       const res = await this.getImageUrl();
       imageURL = await Utility.uploadFile(this.state.imageFile, res);
    }
    const params = this.createParams(this.state.formData);
    params.image_url = imageURL || '';
    
    if (!Utility.isEmpty(this.props.typeFormData)) {
      this.updateType(this.state.formData.product_type_id, params);
    }
    else
      this.createProductType(params);
  }

  createProductType = (params) => {

    Utility.sendRequest(PRODUCT_TYPE, 2, params, async (err, res, body) => {
      body = JSON.parse(body);
      this.props.showLoader(false);
      if (body.error) {
        toast.error(body.error.message, { containerId: 'private' });
      } else {
        this.props.saveProductType();
        toast.success(body.message, { containerId: 'private' });
      }
    });
  }

  updateType = (id, params) => {

    Utility.sendRequest(`${PRODUCT_TYPE}/${id}`, 3, params, async (err, res, body) => {
      body = JSON.parse(body);
      this.props.showLoader(false);
      if (body.error) {
        toast.error(body.error.message, { containerId: 'private' });
      } else {
        this.props.saveProductType();
        toast.success(body.message, { containerId: 'private' });
      }
    });
  }

  handleFormErrors = () => {
    const isValid = Utility.showFormErrors(this, validateFields, this.state.formName);
    const colorField = this.validateColorField();

    return ((isValid && colorField) ? true : false);
  }

  handleFileChange = (name, previewURL, file) => {

    if (!file) {
      return;
    }   
      this.setState({ imagePreviewUrl: previewURL, imageFile: file }, () => {     
      });
    }


  removeUploadedFile = () => {
    this.setState({ imagePreviewUrl: '', imageFile: '' });
  }

  render() {
    return (
      <>
        <SideCallout
          closeCallOut={this.props.onClose}
          calloutHeading="Add/Edit Product Type"
          calloutHeadingSvg={<MemberGroupIcon fillClass="fill-black" />}
        >
          <div className="add-category-body">
            <Form className="shar-form search-form search-form-pwa sidebar-form pb-4 pb-md-0">
              <Form.Group className="mb-4 pb-1">
                <Form.Label className="font-bold display-8 mb-3">Product Type Name</Form.Label>
                <InputField
                  required={true}
                  type="text"
                  name="name"
                  ref="name"
                  value={this.state.formData.name}
                  placeholder="Enter Product Type Name..."
                  handleChange={this.handleChange}
                />
              </Form.Group>
              <Form.Group className="mb-4 pb-1" >
          <Form.Label className="font-bold display-8 mb-3">Add an Image <span className="text-red">*</span></Form.Label>
          <div className="align-items-center justify-content-center post-pic position-relative page-sidebar-upload create-article-upload">
            <label className="post-uplodad-label mb-0 cursor-pointer">
              <UploadFile
                name='image_url'
                inputId="image_url"
                removeText="Remove image"
                acceptTypes="image/x-png,image/gif,image/jpeg"
                uploadedFilePreviewUrl={this.state.imagePreviewUrl}
                handleFileChange={this.handleFileChange}
                removeUploadedFile={this.removeUploadedFile}
              />
              <div className="error errorIcon text-left pl-2 mb-3" id={'groupImageUrlError'} />
              <CircleFilledAddLargeIcon mainClass="add-icon-upper" fillClass="fill-primary" />
            </label>
          </div>
        </Form.Group>

              <Form.Group className="mb-4 pb-1">
                <Form.Label className="font-bold display-8 mb-3">Assign a color to Type</Form.Label>
                <div className="align-items-center mb-4 shar-color-picker-container" >
                  <InputField
                    type="text"
                    id="color_code"
                    name="color_code"
                    ref="color_code"
                    value={this.state.formData.color_code}
                    placeholder="#HEX"
                    handleChange={this.handleChange}
                  />
                  <div className="shar-color-picker cursor-pointer" onClick={this.openColorSelector} />
                  {
                    this.state.showColorSelector &&
                    <SketchPicker
                      color={this.state.formData.color_code}
                      onChangeComplete={this.handleChangeComplete}
                    />
                  }
                </div>
              </Form.Group>
              <div className="d-inline-flex flex-column" >
                <button
                  className="btn btn-theme btn-primary mt-3 font-16"
                  onClick={this.submit}
                >
                  Confirm
                </button>
                <button
                  onClick={() => { this.props.onClose() }}
                  className="btn btn-link mt-4 font-16 b-600"
                >
                  Cancel
                </button>
              </div>
            </Form>
          </div>
        </SideCallout>
      </>
    )
  }
}

export default ProductTypeForm;