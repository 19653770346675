/* eslint-disable no-debugger */
import React, { useState, useEffect } from "react";
import { Dropdown, Modal } from "react-bootstrap";
import { Utility } from "utils";
import DoneRoundedIcon from "@mui/icons-material/DoneRounded";
import CloseRoundedIcon from "@mui/icons-material/CloseRounded";
import { toast } from "react-toastify";
import { NoRecordFound } from "shared/components/no_record_found";
import {
  NOTIFICATIONS_IDS,
  NOTIFICATIONS_TYPES,
  CLIENT_ID,
} from "config/constants/config";
import {
  CLIENT_NOTIFICATIONS,
  API_BASE_URL,
  MARK_ALL_AS_READ,
  NOTIFICATION_MARK_AS_READ,
  USER_PROFILE,
  GETALLJOINEDGROUPS,
} from "config/constants/api_endpoints";
import { useHistory } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import {
  updateIsChatFormOpen,
  updateChatFriend,
  updateIsAdminAccessRequestFormOpen,
  updateSeeAll,
  updateIsAcceptedAdminRequestFormOpen,
} from "shared/actions";
import Avatar from "react-avatar";
import socket from "utils/sockets.js";
import ScheduleTime from "shared/components/ScheduleTime";
import { IconButton } from "@mui/material";
import {
  GET_FOLLOWERS_FOLLOWING,
  GET_USERS_OF_NEIGHBORHOOD,
} from "../../config/constants/api_endpoints";
import { showLoader } from "../actions";

export default function WebNotification(props) {
  const [notificationData, setNotificationData] = useState([]);
  const [openScheduleTime, setScheduleTime] = useState(false);
  const [openNotifications, setOpenNotifications] = useState(false);
  const { userData, seeAll, isChatFormOpen } = useSelector((state) => state);
  // const [neighborhoodMembers, setNeigborhoodMembers] = useState([]);
  const dispatch = useDispatch();
  const history = useHistory();

  useEffect(() => {
    if (openNotifications) {
      dispatch(updateSeeAll({ isOpen: null, calledFrom: null }));
      dispatch(updateIsChatFormOpen(false));
    }
  }, [openNotifications]);

  useEffect(() => {
    if (isChatFormOpen || seeAll.isOpen) {
      setOpenNotifications(false);
    }
  }, [isChatFormOpen, seeAll]);

  useEffect(() => {
    getAllNotifications();
    socket.on("notifications", listenForNotifications);
    socket.on("chats", () => console.log("new message Arrived"));
    // socket.on("Chat",);
    return () => socket.off("notifications", listenForNotifications);
  }, [userData.id]);

  useEffect(() => {
    if (!Utility.isEmpty(notificationData))
      socket.on("notifications", listenForNotifications);
    socket.on("chats", () => console.log("new message Arrived"));
  }, []);

  useEffect(() => {
    if (Utility.pwaModeEnabled() && openNotifications) {
      document.querySelector("body").style.overflow = "hidden";
      document.querySelector("body").scroll = "no";
    } else {
      document.querySelector("body").style.overflow = "auto";
      document.querySelector("body").scroll = "yes";
    }

    return () => {
      document.querySelector("body").style.overflow = "auto";
      document.querySelector("body").scroll = "yes";
    };
  }, [openNotifications]);

  const listenForNotifications = (newNotification) => {
    if (!Utility.isEmpty(newNotification)) getAllNotifications();
  };
  // const getUsersOfNeighborhood = async (neigborhood_client_id) => {
  //   const params = {
  //     platform_id: CLIENT_ID, //WEA
  //     client_id: neigborhood_client_id, //neighborhood
  //   };
  //   Utility.sendRequest(
  //     GET_USERS_OF_NEIGHBORHOOD,
  //     2,
  //     params,
  //     (err, res, body) => {
  //       body = JSON.parse(body);
  //       if (body.error) {
  //         toast.error(body.error.message, { containerId: "private" });
  //       } else {
  //         setNeigborhoodMembers(body.data);
  //       }
  //     }
  //   );
  // };
  const markAsRead = (id) => {
    Utility.sendRequest(
      NOTIFICATION_MARK_AS_READ,
      3,
      { id },
      (error, response, body) => {
        body = JSON.parse(body);

        if (body.error)
          toast.error(body.error.message, { containerId: "public" });
        else getAllNotifications();
      }
    );
  };

  const openSelectedGroup = (group_id) => {
    const params = {
      user_client_id: userData.id,
    };

    Utility.sendRequest(GETALLJOINEDGROUPS, 1, params, (err, res, body) => {
      body = JSON.parse(body);
      if (body.error) {
        console.log(body.error);
      } else {
        const group = body.data.filter((item) => item.group_id === group_id);
        if (group) dispatch(updateIsChatFormOpen(true));
        dispatch(updateChatFriend(group[0]));
      }
    });
  };

  function getAllNotifications() {
    const params = { ":id": userData.id };
    const baseURL = Utility.getAPIEndpointWithParams(
      CLIENT_NOTIFICATIONS,
      params
    );
    Utility.sendRequest(baseURL, 1, {}, (error, response, body) => {
      body = JSON.parse(body);

      if (!body.error) {
        setNotificationData(body.data);
      } else {
        toast.error(body.error.message, { containerId: "public" });
      }
    });
  }

  const handleFollow = (event, value, notificationData) => {
    event.stopPropagation();
    event.preventDefault();
    const params = {
      followerId: notificationData.ref_record_id,
      followingId: userData.id,
      is_active: value,
    };
    dispatch(showLoader(true));
    Utility.sendRequest(
      GET_FOLLOWERS_FOLLOWING,
      2,
      params,
      (error, response, body) => {
        body = JSON.parse(body);
        dispatch(showLoader(false));
        if (!body.error) {
          markAsRead(notificationData.id);
          getAllNotifications();
        }
      }
    );
  };

  const checkNotification = (notification_message, ref_record_id) => {
    if (notification_message.includes("Course"))
      return "/campaign/" + ref_record_id;
    else if (notification_message.includes("Article"))
      return "/news-details/" + ref_record_id;
    else if (notification_message.includes("Event"))
      return "/event-details/" + ref_record_id;
  };
  let additionalDetails = {};

  const addLinksToNoti = async (data) => {
    if (Object.keys(data.additional_details).length !== 0) {
      additionalDetails = JSON.parse(data.additional_details);
    }

    if (NOTIFICATIONS_IDS.NEW_DIRECT_MESSAGE === data.notification_id) {
      await Utility.sendRequest(
        USER_PROFILE,
        2,
        { userClientId: userData.id, publicUserClientId: data.ref_record_id },
        (error, response, body) => {
          body = JSON.parse(body);
          if (!body.error) {
            dispatch(updateChatFriend(body));
            dispatch(updateIsChatFormOpen(true));
            markAsRead(data.id);
          }
        }
      );
    } else if (
      NOTIFICATIONS_IDS.ADMIN_ACCESS_REQUEST === data.notification_id
    ) {
      await Utility.sendRequest(
        USER_PROFILE,
        2,
        { userClientId: userData.id, publicUserClientId: data.ref_record_id },
        (error, response, body) => {
          body = JSON.parse(body);
          if (!body.error) {
            const notificationCalloutData = {
              open: true,
              data: body,
            };
            dispatch(
              updateIsAdminAccessRequestFormOpen(notificationCalloutData)
            );
            markAsRead(data.id);
          }
        }
      );
    } else if (
      NOTIFICATIONS_IDS.ACCEPT_ADMIN_REQUEST === data.notification_id
    ) {
      await Utility.sendRequest(
        USER_PROFILE,
        2,
        { userClientId: userData.id, publicUserClientId: data.ref_record_id },
        (error, response, body) => {
          body = JSON.parse(body);
          if (!body.error) {
            const notificationCalloutData = {
              open: true,
              data: body,
            };
            dispatch(
              updateIsAcceptedAdminRequestFormOpen(notificationCalloutData)
            );
            markAsRead(data.id);
          }
        }
      );
    } else if (
      NOTIFICATIONS_IDS.ADD_MEMBER_PERMISSION === data.notification_id ||
      NOTIFICATIONS_IDS.EDIT_GROUP_PERMISSION === data.notification_id ||
      NOTIFICATIONS_IDS.GROUP_MESSAGE === data.notification_id ||
      NOTIFICATIONS_IDS.REMOVE_ADD_MEMBER_PERMISSION === data.notification_id ||
      NOTIFICATIONS_IDS.REMOVE_EDIT_GROUP_PERMISSION === data.notification_id
    ) {
      openSelectedGroup(data.ref_record_id);
      markAsRead(data.id);
    } else if (
      NOTIFICATIONS_IDS.ADDED_IN_NEIGHBOURHOOD === data.notification_id
    ) {
      markAsRead(data.id);
      if (
        Object.keys(additionalDetails).length !== 0 &&
        !additionalDetails.is_public
      ) {
        // await getUsersOfNeighborhood(additionalDetails.client_id);
        const params = {
          platform_id: CLIENT_ID, //WEA
          client_id: additionalDetails.client_id, //neighborhood
        };
        Utility.sendRequest(
          GET_USERS_OF_NEIGHBORHOOD,
          2,
          params,
          (err, res, body) => {
            body = JSON.parse(body);
            if (body.error) {
              toast.error(body.error.message, { containerId: "private" });
            } else {
              // setNeigborhoodMembers(body.data);
              if (
                body.data.length > 0 &&
                !body.data.some((obj) => obj.user_id === userData.user_id)
              ) {
                toast.error(`You are not the member of this neighbourhood`, {
                  containerId: "private",
                });
              } else {
                return history.push(
                  NOTIFICATIONS_TYPES[data.notification_type] + data.ref_record_id
                );
              }
            }
          }
        );
       
      } else {
        return history.push(
          NOTIFICATIONS_TYPES[data.notification_type] + data.ref_record_id
        );
      }
    } else {
      markAsRead(data.id);
      return history.push(
        NOTIFICATIONS_TYPES[data.notification_type] === "/campaign/"
          ? checkNotification(
              data.notification_message_plain,
              data.ref_record_id
            )
          : NOTIFICATIONS_TYPES[data.notification_type] + data.ref_record_id
      );
    }
  };

  const paintNotification = () => {
    return !Utility.isEmpty(notificationData) ? (
      notificationData.map((e) => {
        return (
          <div
            className={`${
              e.is_read ? "read" : "unread"
            } align-items-center flex-nowrap notification-item-inner`}
            key={e.id}
          >
            {!Utility.isEmpty(e.notification_image) ? (
              <img
                className="notification-img img-border rounded-circle"
                src={
                  e.notification_image ||
                  require("assets/images/users/user_no_image.png")
                }
                alt=""
              />
            ) : (
              <Avatar
                name={e.notification_message_plain}
                round={true}
                size={"50"}
                textSizeRatio={1.75}
                color="#007bb3"
                maxInitials={1}
              />
            )}
            <Dropdown.Item
              key={e.id}
              bsPrefix="text-dark w-100"
              onClick={() => addLinksToNoti(e)}
            >
              <div className="d-flex">
                <div className="w-80">
                  <div className="align-items-center ml-3">
                    <div
                      dangerouslySetInnerHTML={{
                        __html: e.notification_message,
                      }}
                      key={e.id}
                    />
                  </div>
                  <div className="ml-3 font-weight-bold position-relative">
                    <span className="font-12 time-ago">
                      {Utility.returnDuration(e.created_at)}
                    </span>
                  </div>
                </div>
                {e.notification_type === "follow" && (
                  <div className="w-20 d-flex h-fit-content ml-auto">
                    {e.is_request_active === null && (
                      <>
                        <IconButton
                          onClick={(event) => handleFollow(event, true, e)}
                        >
                          <DoneRoundedIcon />
                        </IconButton>
                        <IconButton
                          onClick={(event) => handleFollow(event, false, e)}
                        >
                          <CloseRoundedIcon />
                        </IconButton>
                      </>
                    )}
                    {/* {e.is_request_active === true && (
                      <div className="btn-markRead-styling btn-markRead-styling-desabled">
                        Accepted
                      </div>
                    )}
                    {e.is_request_active === false && (
                      <div className="btn-markRead-styling btn-markRead-styling-desabled">
                        Rejected
                      </div>
                    )} */}
                  </div>
                )}
              </div>
            </Dropdown.Item>
          </div>
        );
      })
    ) : (
      <NoRecordFound />
    );
  };

  const showBellIndicator = () => {
    let flag = false;
    !Utility.isEmpty(notificationData) &&
      notificationData.map((e) => {
        if (!e.is_read) flag = true;
      });
    return flag;
  };

  const handleMarkAllAsRead = () => {
    const params = {
      user_client_id: userData.id,
      is_read: true,
    };

    Utility.sendRequest(
      MARK_ALL_AS_READ,
      3,
      params,
      (error, response, body) => {
        body = JSON.parse(body);

        if (body.error)
          toast.error(body.error.message, { containerId: "public" });
        else getAllNotifications();
      }
    );
  };

  const openSetting = () => {
    setScheduleTime(true);
  };

  return (
    <>
      <div
        className={`notifications-outer position-relative ${
          Utility.pwaModeEnabled() ? "" : "web-notifications-outer"
        }`}
      >
        <div
          className={`${
            showBellIndicator() && "notification-indicator"
          } rounded-circle position-absolute`}
        />
        <Dropdown onToggle={(e) => setOpenNotifications(e)}>
          <Dropdown.Toggle variant="none" id="dropdown-basic">
            <div
              className={`SidepanalheaderIcon ${
                openNotifications && "SelectedSidePanal border-none"
              }`}
            >
              <img
                src={require("assets/images/icons/notification.svg")}
                alt="notifications bell"
              />
            </div>
          </Dropdown.Toggle>
          <Dropdown.Menu
            className={`notification-outer ${
              window.innerWidth <= 820 && " w-50 h-50 "
            } `}
          >
            <div className="d-lg-none notification-close-callout">
              <div
                onClick={() =>
                  document.querySelector("#dropdown-basic").click()
                }
                className="pwa-callout-decorator mb-4 mt-3"
              />
            </div>
            <div className="scroll-y notification-overflow">
              <div className="d-lg-none">
                <div className="d-flex align-items-center justify-content-between border-bottom">
                  <h1 className="font-21-bold d-flex mb-4 pb-2 px-4">
                    <img
                      src={require("assets/images/icons/notification.svg")}
                      alt="bell icon"
                      className="mr-4"
                    />
                    Notifications
                  </h1>
                </div>
              </div>
              <div className="d-flex f-weight-bold py-2 px-3">
                <p className="mb-0">Notifications</p>
                <div
                  onClick={handleMarkAllAsRead}
                  className="btn-markRead-styling"
                >
                  Mark All As Read
                </div>
              </div>

              {paintNotification()}
            </div>
          </Dropdown.Menu>
        </Dropdown>
      </div>
      {openScheduleTime && (
        <Modal
          className="modal-backdrop-darker"
          show={openScheduleTime}
          onHide={() => setScheduleTime(false)}
          animation={false}
        >
          <div className="ethos-onboarding ethos-purchase-inner ethos-modal-inner">
            <ScheduleTime
              isUpdate
              close={() => setScheduleTime(false)}
              skip={() => setScheduleTime(false)}
            />
          </div>
        </Modal>
      )}
    </>
  );
}
