import React, { useEffect } from 'react';
import CreateFormSelection from './create_form_selection';
import { updateCampaignFormOpen, updateCampaignSelectedTab, updateIsCampSelFormOpen } from 'shared/actions';
import { useSelector, useDispatch } from 'react-redux';

const PwaCampaign = (props) => {
  const dispatch = useDispatch();
  const isCampSelFormOpen = useSelector(state => state.isCampSelFormOpen);

  useEffect(() => {
    dispatch(updateCampaignFormOpen(false));
    const body = document.getElementsByTagName('body')[0];
    body.classList.toggle('overflow-hidden');
    return () => body.classList.remove('overflow-hidden');
  }, []);

  const closeIsSelectionFormOpen = () => {
    dispatch(updateIsCampSelFormOpen(false));
    dispatch(updateCampaignFormOpen(false));
  };

  const handleTabSelectionClick = (arg) => {
    dispatch(updateIsCampSelFormOpen(false));
    dispatch(updateCampaignSelectedTab(arg));
    dispatch(updateCampaignFormOpen(true));
  };

  return(
    <>
      {
        isCampSelFormOpen &&
        <CreateFormSelection
          position='position-fixed'
          handleArrowClick={handleTabSelectionClick}
          closeIsSelectionFormOpen={closeIsSelectionFormOpen}
        />
      }
    </>
  );
};

export default PwaCampaign;
