import React, { useState, useEffect } from "react";
import Avatar from "react-avatar";
import { useSelector } from "react-redux";
import { withRouter, useHistory, useParams } from "react-router-dom";
import { toast } from "react-toastify";

const NewsCard = ({
  data,
  Totalcourses,
  index,
  isVertical = false,
  trendingArticles = false,
  // joinedNeighborhood,
  handleArticleClick,
  classToOverride,
}) => {
  const { userNeighborhoods } = useSelector((state) => state);
  const { id } = useParams();
  const truncateText = (text, length) => {
    if (text.length > length) {
      return text.slice(0, length) + "...";
    }
    return text;
  };

  const handleClick = (check, corporate) => {
    if (userNeighborhoods && userNeighborhoods.length !== 0 && data) {
      if (
        userNeighborhoods.some((item) => item.client_id === data.corporate_id)
      ) {
        handleArticleClick(data);
      } else {
        if (data) {
          toast.error(
            data.is_public
              ? `Please join ${data.corporate_name || data.author_name}`
              : `You are not a part of ${
                  data.corporate_name || data.author_name
                }`,
            { containerId: "private" }
          );
        }
      }
    }

    // console.log(data, joinedNeighborhood, check, "************", corporate)
  };
  return (
    <div
      key={data.id}
      onClick={() => {
        if (data.id === data.id) {
          if (data.corporate_id === null) {
            handleArticleClick(data);
          } else {
            handleClick(data.id, data.corporate_id);
          }
        }
      }}
      style={{
        minWidth: trendingArticles && "500px",
        maxWidth: trendingArticles && "748px",
        width: trendingArticles && "50vw",
        height: trendingArticles && "250px",
      }}
      className={ `${classToOverride} ${
        isVertical ? "news-card-vertical" : "news-card"
      } cursor-pointer bg-white ${
        isVertical
          ? "ml-3"
          : Totalcourses && Totalcourses.length === index + 1 && "mr-2"
      } ${index === 0 && "ml-3"}  ${index > 0 && "ml-3"}`}
    >
      {data.image_url ? (
        <span className={`image-container ${isVertical ? "w-100" : "w-50"}`}>
          <img
            className={"course-image article-card-image"}
            src={data.image_url}
            alt="#"
          />
        </span>
      ) : (
        <div
          className={`d-flex align-items-center ${
            isVertical
              ? "h-50 justify-content-center"
              : "h-100 no-image-container-news"
          } ${trendingArticles && "w-50"}`}
        >
          {/* <Avatar
            name={data.author_name}
            round={true}
            size={"20"}
            textSizeRatio={1.75}
            color="#007bb3"
            maxInitials={1}
            className={"article-pic"}
          /> */}
          <Avatar
            name={data.author_name}
            round={true}
            size={"61"}
            textSizeRatio={1.75}
            color="#007bb3"
            maxInitials={1}
            className={"community-avatar"}
          />
        </div>
      )}
      <div
        className={
          trendingArticles
            ? "news-card-content pt-1"
            : isVertical
            ? "news-card-content  vertical-card-content overflow-hidden"
            : "news-card-content"
        }
      >
        {/* <span
          className={`course-text-course my-1 course-category-container`}
          //   style={{
          //     color: data.category_color,
          //   }}
        >
          #{data.campaign_category}
        </span> */}
        <div className={"course-title-container mt-3"}>
          {data.heading.length > 20
            ? data.heading.slice(0, 20) + "..."
            : data.heading}
        </div>

        <div
          className="mt-2 news-card-description"
          dangerouslySetInnerHTML={{
            __html:
              window.location.pathname === "/profile" ||
              (id && window.location.pathname === `/profile/${id}`)
                ? data.description.length > 50
                  ? data.description.slice(0, 30) + "..."
                  : data.description
                : data.description.length > 230
                ? data.description.slice(0, 230) + "..."
                : data.description,
          }}
        />

        {(data.region_details ||
          data.community_name ||
          data.region ||
          data.country) && (
          <div
            className={`${
              trendingArticles ? " mt-2" : "check-container mt-1"
            } d-flex align-items-center`}
          >
            {(data.region ||
              (data.region_details && data.region_details.REGION_NAME) ||
              data.country ||
              data.community_name) && (
              <input type="checkbox" id="region" className="checkbox-round" />
            )}
            <label htmlFor="region" className="mb-0 ml-1">
              {data.region
                ? data.region + "/"
                : data.region_details &&
                  data.region_details.REGION_NAME &&
                  data.region_details.REGION_NAME + "/"}
              {data.country
                ? truncateText(data.country, 16)
                : data.community_name && truncateText(data.community_name, 16)}
            </label>
          </div>
        )}
      </div>
    </div>
  );
};

export default NewsCard;
