import React, { useEffect, useState } from "react";
import Avatar from "react-avatar";
import * as Utility from "utils/utility";
import { GROUP_MEMBERS } from "../../../../../config/constants/api_endpoints";
const MessageSectionCard = (props) => {
  const htmlRegex = /<\/?[a-z][\s\S]*>/i;
  const [groupMembersData, setGroupMembersData] = useState([]);
  const getGroupMember = () => {
    const params = {
      groupId: props.Data.id,
    };

    Utility.sendRequest(GROUP_MEMBERS, 1, params, (err, res, body) => {
      body = JSON.parse(body);
      if (!body.error) {
        setGroupMembersData(body);
      }
    });
  };
  useEffect(() => {
    if (props.isGroup) {
      getGroupMember();
    }
  }, []);
  return (
    <div
      onClick={() => {
        props.onClick();
        props.setIsChatClicked(true);
      }
      }
      className={`message-card-container ${
        props.Data &&
        (props.Data.unseen_count > 0 || props.Data.unread_messages_count > 0)
          ? "message-card-container-active"
          : ""
      } `}
    >
      <div
        className={` ${
          props.isGroup
            ? "message-card-userInfoContainer-group"
            : "message-card-userInfoContainer-personal"
        }`}
      >
        {props.isGroup ? (
          groupMembersData.length > 0 && (
            <div className="d-flex align-items-center mx-1">
              {groupMembersData.slice(0, 3).map((items, index) => {
                return (
                  <Avatar
                    size={"40"}
                    key={index}
                    className="mx-1"
                    name={items.user_name && items.user_name}
                    round={true}
                    src={items.profile_image_url || ""}
                  />
                );
              })}
              {groupMembersData.length > 4 && (
                <p className="mb-0 ml-1 members-Count">{`+ ${
                  groupMembersData.length - 4
                } people`}</p>
              )}
            </div>
          )
        ) : (
          <Avatar
            name={props.Data && props.Data.name}
            round={true}
            src={props.Data && props.Data.profile_image_url}
            size={"40"}
          />
        )}
        <div
          className={`message-card-name  ${
            props.isGroup
              ? "message-card-name-group"
              : "message-card-name-personal"
          }`}
        >
          {props.Data && props.Data.name}
          {htmlRegex.test(
            props.Data &&
              props.returnDecryptedMessage(props.Data, props.isGroup)
          ) ? (
            <span
              dangerouslySetInnerHTML={{
                __html:
                  props.Data &&
                  props.returnDecryptedMessage(props.Data, props.isGroup),
              }}
              className="message-card-lastMesage"
            />
          ) : (
            <span className="message-card-lastMesage">
              {props.Data &&
                props.returnDecryptedMessage(props.Data, props.isGroup)}
            </span>
          )}
        </div>
      </div>
      <div className="message-card-messagesInfo">
        {props.Data &&
          (props.Data.unseen_count > 0 ||
            props.Data.unread_messages_count > 0) && (
            <p className="message-card-messagesInfo-unseen_count">
              {props.Data.unseen_count
                ? props.Data.unseen_count
                : props.Data.unread_messages_count}
            </p>
          )}
        <p className="message-card-messagesInfo-recent_message_time">
          {props.Data.recent_message_time &&
            Utility.returnDuration(props.Data.recent_message_time)}
        </p>
      </div>
    </div>
  );
};

export default MessageSectionCard;
