import React, { Component } from 'react';
import { Row, Col, Table, Spinner, Button } from 'react-bootstrap';
import { toast } from 'react-toastify';
// import { DropDown } from 'shared/components/form_elements';
import { Utility, Date } from 'utils';
import { MenuTitle } from 'shared/components/templates/menu_list';
import { CLIENT_STATEMENTS } from 'config/constants/api_endpoints';
// import { PayDayAlert } from 'scenes/finances/components/pay_day_alert';
import { Receipt } from 'shared/components/templates/receipt';
import { NoRecordFound } from 'shared/components/no_record_found';
import { ReceiptView } from 'scenes/finances/statements/receipt_view';
import moment from 'moment';

// const sortList = [
//   { id: '1', name: "Last 30 days" },
//   { id: '2', name: 'Last 6 months' },
//   { id: '3', name: 'Last 1 year' }
// ];

export default class StatementList extends Component {
  _isMounted = false;

  constructor(props) {
    super(props);

    this.state = {
      statements: [],
      showLoader: true,
      statementDetails: {},
      showReceipt: false,
    };
  }

  componentDidMount = () => {
    const params = {
      user_client_id: this.props.userData.id,
      order: 'created_at',
      order_type: 2,
      conn_to_another_city_id: this.props.userData.conn_to_another_city_id,
    };
    this._isMounted = true;

    Utility.sendRequest(CLIENT_STATEMENTS, 1, params, (err, res, body) => {
      body = JSON.parse(body);

      if (body.error) {
        toast.error(body.error.message, { containerId: 'private' });
      } else if (this._isMounted) {
        this.setState({ statements: body.data, showLoader: false });
      }
    });
  }

  componentWillUnmount = () => this._isMounted = false;

  showReceipt = (statementId) => {
    this.setState({ showReceipt: true });
    this.getStatementDetail(statementId);
  }

  hideReceipt = () => {
    this.setState({ showReceipt: false });
  }

  getStatementDetail = (statementId) => {
    const statementDetails = this.state.statements.find(statement => {
      return statement.invoiceData.id === statementId;
    });

    if (this._isMounted) this.setState({ statementDetails });
  }

  render() {
    const statement = this.state.statementDetails;
    const receiptHeader = Utility.isEmpty(statement) ? '' : `${Date.monthNameByNumber(statement.month)} ${statement.year}`;
    return (
      <>
        <Col xl={9} lg={8} xs={12} className="ModalOpen top-on-sm h-100 p-4" >
          <div className="d-block d-lg-none position-relative bg-white px-3 py-4 border-bottom back-button-sm">
            <Button variant="link" className="color-primary text-left align-items-center" onClick={() => this.props.updateSelectedMenuItem({})}>
              <img className="width-10 align-baseline mr-3" src={require("assets/images/chevron_left.svg")} alt="left-icon" />
              Back to Finances
            </Button>
          </div>
          <Row className="mx-0 p-sm-3">
            <div className="network-box-shadow h-100 mt-3 mt-sm-0">
              <div className="network-item-header d-flex align-items-center">
                <div className="round-circle mr-2">
                </div>
                <MenuTitle menuName={this.props.selectedMenuItem.menu_name}/>
              </div>
            </div>
            {/*<div className="ml-auto" >
              <Button varaint="primary" className="btn-toolbox text-center" >
                <img src={require("assets/images/tip_icon.svg")} alt="Sharemeister Logo Symbol" />
                Tools
              </Button>
            </div>*/}
          </Row>
          {/* payday Alert  */}
          {/* <PayDayAlert/> */}

          <div className="px-sm-4 py-2">
            <div className="d-flex align-items-center justify-content-end shar-form  ">
              {/* <span className="font-light-italic mr-3">Sort by</span>
               <Form.Group controlId="sort" className="mb-0" >
                <DropDown
                  optionId="id"
                  optionValue="name"
                  required={true}
                  name="sort"
                  options={sortList}
                  handleChange={this.handleChange}
                  />
              </Form.Group> */}
            </div>
            {/* Staments body  */}
            <div className="mt-4">
              <StatementsTable statements={this.state.statements} showLoader={this.state.showLoader} showReceipt={this.showReceipt}/>
              {
                Utility.isEmpty(this.state.statements) && !this.state.showLoader &&
                <NoRecordFound/>
              }
            </div>
          </div>
        </Col>
        {
          this.state.showReceipt &&
          <Receipt hideReceipt={this.hideReceipt} header={'Statement Details'}>
            <ReceiptView
              userData={this.props.userData}
              licenseData={this.state.statementDetails}
            />
          </Receipt>
        }
      </>
    );
  }
}

const StatementsTable = props => (
  <>
    {
      props.showLoader
      ? <center><Spinner animation="border" role="status"/></center>
      : <Table striped responsive className="shar-table">
          <thead>
            <tr>
              <th className="border-top-0 border-bottom-0">Date</th>
              <th className="border-top-0 border-bottom-0">Description</th>
              <th className="border-top-0 border-bottom-0">Status</th>
              <th className="border-top-0 border-bottom-0">Amount</th>
              <th className="border-top-0 border-bottom-0"></th>
            </tr>
          </thead>
          <tbody>
            {  props.statements.map((item) => {
                return (
                  <tr key={`${item.invoiceData.id}statement`}>
                    <td>
                      {moment(item.invoiceData.created_at).format("MM/DD/YYYY")}
                    </td>
                    <td>CITY LICENSE PURCHASE</td>
                    <td>{item.invoiceData.is_paid ? 'Paid' : 'Pending'}</td>
                    <td>{Utility.paintUptoTwoDecimalPlaces(item.invoiceData.total_invoice_amount)}</td>
                    <td className="cursor-pointer" onClick={() => props.showReceipt(item.invoiceData.id)}><img src={require("assets/images/icons/doc_icon.svg")} alt="Document icon" /></td>
                  </tr>
                );
              })
            }
          </tbody>
        </Table>
    }
  </>
);
