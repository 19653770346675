import React from "react";
import * as Utility from "utils/utility";
import useMediaQuery from '@mui/material/useMediaQuery';
import UserCommentContainer from "../../v2/campaign/components/UserCommentContainer";
import NewsContent from "./NewsContent";

const NewsLeftPanel = ({ campaign_id, description_1 }) => {
  const isTab = Utility.pwaModeEnabled();

  return (
    <div className={`newsLeftPanel background-sidebar-pages ${isTab ? "setFullWidth" : ""}`}>
      <NewsContent description_1={description_1} />
      {/* {!isTab && <div className="website-link-button mt-4 py-3 bg-white button-radius text-center">
        <button
          className="btn btn-primary p-3 course-button"
        // onClick={() => props.setSelectedItem(props.item)}
        >
          GO TO WEBSITE LINK
        </button>
      </div>} */}
      <div className="bg-white ePanelUserChatContainer mb-5">
        <UserCommentContainer
          ActiveLessonId={campaign_id}
          isRegion={true}
          isCampaign={true}
          isNews={true}
        />
      </div>
    </div>
  );
};

export default NewsLeftPanel;
