import React, { useState, useEffect } from "react";
import { Utility } from "utils";
import { CHATS } from "config/constants/api_endpoints";
import { toast } from "react-toastify";
import SideCallout from "shared/components/templates/side_callout";
import { useSelector, useDispatch } from "react-redux";
import { ChevronLeftIcon } from "shared/icons/icons";
import ChatCallout from "shared/components/chat_callout.js";
import { NoRecordFound } from "shared/components/no_record_found";
import PwaFooterChat from "shared/components/pwa_footer_chat";
import { updateIsChatFormOpen, updateChatFriend } from "shared/actions";
import { EditIcon } from "shared/icons/edit_icon";
import { Spinner } from "react-bootstrap";
import moment from "moment";
import { showLoader } from "shared/actions";
import CommunityChat from "./CommunityChat";

export default function PwaFooterChatMessage(props) {
  const [users, setUsers] = useState([]);
  const [selectedUser, setSelectedUser] = useState(null);
  const userData = useSelector((state) => state.userData);
  const isLoading = useSelector((state) => state.isLoading);
  const isChatFormOpen = useSelector((state) => state.isChatFormOpen);
  const [search, setSearch] = useState("");
  const [openMemberClick, setOpenMemberClick] = useState(false);
  const dispatch = useDispatch();

  useEffect(() => {
    recentChats();
  }, [search]);

  const recentChats = () => {
    const params = {
      from_user_client_id: userData.id,
      search_text: search.trim(),
    };

    dispatch(showLoader(true));
    Utility.sendRequest(CHATS, 1, params, (err, res, body) => {
      dispatch(showLoader(false));
      if (body.error) {
        toast.error(body.error.message, { containerId: "private" });
      } else {
        setUsers(JSON.parse(body).data);
      }
    });
  };

  const returnDecryptedMessage = (message, private_key) => {
    const senderPrivateKey = userData.private_key;
    const receiverPrivateKey = private_key;

    if (
      !Utility.isEmpty(senderPrivateKey) &&
      !Utility.isEmpty(receiverPrivateKey)
    ) {
      const sharedKey =
        senderPrivateKey.slice(0, 8) + receiverPrivateKey.slice(0, 8);
      const actualMessage = Utility.decryptSingleMessage(message, sharedKey);
      return actualMessage && actualMessage.length > 60
        ? actualMessage.substr(0, 60) + "..."
        : actualMessage;
    } else return "";
  };

  const handleRadioClick = (ev, e) => {
    if (e === selectedUser) {
      setSelectedUser(null);
      document.getElementById(e.id).checked = false;
    } else setSelectedUser(e);
  };

  return (
    <>
      <SideCallout
        position={props.position}
        closeCallOut={() => {}}
        calloutHeading=" Messages"
        calloutHeadingSvg={
          <ChevronLeftIcon
            handleClick={() => props.setIsChatOpen(false)}
            mainClass="article-page-back mr-3 mt-0"
          />
        }
        calloutCloseIcon={<GoIcon selectedUser={selectedUser} />}
        innerPosition="create-card-pwa-outer"
      >
        <div className="tiles-container-pwa-footer">
          {!Utility.isEmpty(users) ? (
            users.map((e) => {
              if (e.message && e.private_key) {
                return (
                  <div
                    className="align-items-center justify-content-between group-section-outer"
                    key={e.id}
                  >
                    <div className="w-100 align-items-center border-top border-bottom py-2">
                      <img
                        src={
                          e.image_url ||
                          require("assets/images/users/user_no_image.png")
                        }
                        className="rounded-circle img-product"
                        alt=""
                      />
                      <div className="lh-1 ml-10 mx-2 member-info-section-outer">
                        <div className="font-bold display-7">{e.name}</div>
                        <div className="display-7 text-truncate">
                          {returnDecryptedMessage(e.message, e.private_key)}
                        </div>
                      </div>
                      <div className="mt-n3 mr-20 ml-auto text-muted font-14 text-black flex-column align-items-center">
                        {moment(e.updated_at).format("hh:mm")}
                        <div className="mt-1">
                          <input
                            type="radio"
                            name="users"
                            id={e.id}
                            onClick={(ev) => handleRadioClick(ev, e)}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                );
              }
            })
          ) : isLoading ? (
            <center>
              <Spinner animation="border" role="status" />
            </center>
          ) : (
            <NoRecordFound />
          )}
        </div>
        <button className="float-edit" onClick={() => setOpenMemberClick(true)}>
          <EditIcon fillColor="white" />
        </button>
      </SideCallout>

      {/* {isChatFormOpen && <ChatCallout />} */}

      {openMemberClick && (
        <PwaFooterChat
          setIsChatOpen={setOpenMemberClick}
          recentChatsApiCall={recentChats}
        />
      )}
    </>
  );
}

function GoIcon(props) {
  const dispatch = useDispatch();

  return (
    <>
      {props.selectedUser && (
        <button
          className="btn btn-primary"
          onClick={() => {
            dispatch(updateIsChatFormOpen(true));
            dispatch(updateChatFriend(props.selectedUser));
          }}
        >
          Go
        </button>
      )}
    </>
  );
}
