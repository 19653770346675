import React, { useEffect, useState } from "react";
// import Regions from "./regions";
import MembersCard from "./membersCard";
import { Grid } from "@material-ui/core";
import { GrSearch } from "react-icons/gr";
import * as Utility from "utils/utility";
import { COUNTRIES_BY_REGION } from "config/constants/api_endpoints";
import { toast } from "react-toastify";
import { FILTER_OPTIONS } from "config/constants";
// import { useDispatch, useSelector } from "react-redux";
import { ROLE_IDS } from "../../../../../config/constants";
import { Skeleton } from '@mui/material';
import { Container, Row, Col } from 'react-bootstrap';

const Region_page = ({ communityLabel }) => {
  const [countriesData, setCountriesData] = useState([]);
  const [loading,setLoading] = useState(true);


  const [searchData, setSearchData] = useState([]);
  // const userData = useSelector((state) => state.userData);
  // const { userNeighborhoods } = useSelector((state) => state);
  // const dispatch = useDispatch();
  const firstNeighborhoodList = [
    "58fc1e70-3a08-11ec-a535-e778983839c6",
    "d6003e40-3cda-11ec-82f6-d7d08e291692",
    "a3488bc0-3a09-11ec-a535-e778983839c6",
    "60942bd0-3a0a-11ec-a535-e778983839c6",
    "fc008820-3a0a-11ec-a535-e778983839c6",
    "ba9fb4d0-3a0c-11ec-a535-e778983839c6",
    "1ed69d10-3a0d-11ec-a535-e778983839c6",
    "8c2ea630-eb4d-11ed-82b2-51a95db696e0",
    "50bcf290-eb4e-11ed-82b2-51a95db696e0",
  ];

  let debouncedFn = null;
  const [isJoined, setIsJoined] = useState(true);
  useEffect(() => {
    if (isJoined) {
      loadStories();
      setIsJoined(false);
    }
  }, [isJoined]);

  const loadStories = (campTypeId, categoryId = "") => {
    const params = {
      region_id: communityLabel.regionId,
      type: campTypeId,
      category_id: categoryId,
    };

    Utility.sendRequest(COUNTRIES_BY_REGION, 1, params, (err, res, body) => {
      body = JSON.parse(body);
      if (body.error) {
        toast.error(body.error.message, { containerId: "private" });
      } else {
        setCountriesData(body);
        setLoading(false);
      }
    });
  };

  const handleSearch = (ev) => {
    if (!debouncedFn) {
      debouncedFn = Utility.debounce(() => {
        const searchString = ev.target.value;
        setSearchData(searchString);
      }, 500);
    }
    debouncedFn();
  };

  useEffect(() => {
    loadStories(FILTER_OPTIONS[0].id);
    setLoading(true);
  }, [communityLabel.regionId]);

  const filteredCountriesData = [
    ...countriesData.filter((item) => {
      if (firstNeighborhoodList.includes(item.id)) return item.name;
    }),
    ...countriesData.filter((item) => {
      if (!firstNeighborhoodList.includes(item.id))
        return searchData.length
          ? item.name.toLowerCase().includes(searchData.toLowerCase())
          : item.name;
    }),
  ];
  useEffect(() => {
    console.log(filteredCountriesData, "filteredCountriesData");
  }, [filteredCountriesData]);

  return (
    <>
      <div
        className="regionPage pl-0 pt-5 position-relative"
        style={{ backgroundColor: "#F5F5F5", bottom: "30px" }}
      >
        <div className="col-12 pb-2  mt-0  mb-2 pl-5">
          <div
            className="d-flex justify-content-between w-100"
            style={{ paddingRight: "50px" }}
          >
            <p
              className="align-self-center m-0 font-weight-bold"
              style={{ fontSize: "20px" }}
            >
              {communityLabel.communitText}
            </p>
            <div className="SeactionSearchContainer search-communities">
              <div className="searchSectionWrapper bg-white">
                <input
                  className="SeactionSearch"
                  placeholder="Search Sub regions"
                  onChange={(e) => {
                    handleSearch(e);
                  }}
                />
                <span className="SeactionSearchIcon">
                  <GrSearch className="regionIcon" />
                </span>
              </div>
            </div>
          </div>
        </div>
        <div className="pl-5 pr-4">
          <Grid
            container
            // spacing={{ xs: 2, md: 3 }}
            columns={{ xs: 4, sm: 8, md: 12 }}
            className="py-0 my-0"
          >
            {loading ? (
              <Container>
                <Row>
                  <Col xs={12} sm={6} md={4}>
                    <Skeleton variant="rectangular" width="100%" height={240} />
                  </Col>
                  <Col xs={12} sm={6} md={4}>
                    <Skeleton variant="rectangular" width="100%" height={240} />
                  </Col>
                  <Col xs={12} sm={6} md={4}>
                    <Skeleton variant="rectangular" width="100%" height={240} />
                  </Col>
                </Row>
              </Container>
            ) : (
              <>
                {filteredCountriesData && filteredCountriesData.length > 0 ? (
                  filteredCountriesData
                    .filter((data) => data.role_id !== ROLE_IDS.USER)
                    .map((val, index) => (
                      <Grid
                        item
                        xs={6}
                        sm={6}
                        md={4}
                        key={index}
                        className="py-2"
                      >
                        <MembersCard
                          item={val}
                          name={val.name}
                          isJoined={isJoined}
                          setIsJoined={setIsJoined}
                          members_count={val.members_count}
                        />
                      </Grid>
                    ))
                ) : (
                  <div className="d-flex align-items-center justify-content-center my-55 w-100">
                    <div className="px-5">
                      <span className="font-bold display-6">
                        No Records Found
                      </span>
                    </div>
                  </div>
                )}
              </>
            )}
          </Grid>
        </div>
      </div>
    </>
  );
};

export default Region_page;
