import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { withRouter } from "react-router-dom";
import { showLoader } from "../../../shared/actions";
import * as Utility from "utils/utility";
import { toast } from "react-toastify";
import CourseCard from "../../profile/components/CourseCard";
import Avatar from "react-avatar";
import { CgClose } from "react-icons/cg";
import { CLIENT_ID, NAMES_MAPPING } from "../../../config/constants/config";
import {
  GET_USERS_BY_NETWORKS,
  SEND_FF_SHARE_NOTI,
  CAMPAIGN_DETAILS,
} from "../../../config/constants/api_endpoints";

import {
  updateIsChatFormOpen,
  updateChatFriend,
  updateSeeAll,
} from "shared/actions";

const ConfirmSubmitCourse = (props) => {
  const [campaignId, setCampaignId] = useState("");
  const [campaignDetails, setCampaignDetails] = useState(null);
  const userData = useSelector((state) => state.userData);
  const dispatch = useDispatch();

  const [showInvites, setShowInvites] = useState(false);
  const [search, setSearch] = useState("");
  const [invites, setInvites] = useState([]);
  const [usersList, setUsersList] = useState([]);
  const [neighbourhoods, setNeighbourhoods] = useState([]);
  const [loading, setLoading] = useState(false);
  const [filteredUsersList, setFilteredUsersList] = useState([]);

  const [filteredSearchList, setFilteredSearchList] = useState();

  function compare(a, b) {
    if (a.user_name < b.user_name) {
      return -1;
    }
    if (a.user_name > b.user_name) {
      return 1;
    }
    return 0;
  }

  useEffect(() => {
    if (props.match.params.id) {
      setCampaignId(props.match.params.id);
      getCampaignById(props.match.params.id);
    }
  }, [props.match.params.id]);

  useEffect(() => {
    getUserDetails();
  }, []);

  const setSearchData = () => {
    if (search.length > 0) {
      let searchFilteredData = usersList.filter((data) =>
        data.user_name.toLowerCase().includes(search.toLowerCase())
      );
      setFilteredSearchList(searchFilteredData);
      setFilteredUsersList(searchFilteredData.slice(0, 100));
    } else {
      setFilteredSearchList(usersList);
      setFilteredUsersList(usersList.slice(0, 100));
    }
  };

  useEffect(() => {
    setSearchData();
  }, [search]);

  const fetchMoreData = () => {
    if (filteredUsersList && filteredUsersList.length < usersList.length) {
      const length = filteredUsersList.length;
      setLoading(true);
      setTimeout(() => {
        let data = filteredUsersList.concat(
          filteredSearchList.slice(length, length + 100)
        );
        setFilteredUsersList(data);
        setLoading(false);
      }, 500);
    }
  };

  const handleScroll = (e) => {
    const bottom =
      (e.target.scrollHeight - e.target.scrollTop).toFixed(0) ===
      e.target.clientHeight.toFixed(0);
    if (bottom) {
      fetchMoreData();
    } else if (e.target.scrollTop === 0) {
      setFilteredUsersList(filteredSearchList.slice(0, 100));
    }
  };

  const getUserDetails = () => {
    const params = {
      client_id: CLIENT_ID,
      type: "all",
      forCommunity: true,
    };

    dispatch(showLoader(true));
    Utility.sendRequest(GET_USERS_BY_NETWORKS, 1, params, (err, res, body) => {
      body = JSON.parse(body);

      dispatch(showLoader(false));
      if (body.error) {
        toast.error(body.error.message, { containerId: "private" });
      } else {
        if (
          body.registrants &&
          CLIENT_ID === "0bc8dc7c-b5a3-4344-b313-819fc640f6b5"
        ) {
          const userList = [];
          const neighbourhood = [];
          body.registrants.sort(compare).forEach((element) => {
            if (element.network === "users") userList.push(element);
            else neighbourhood.push(element);
          });
          setUsersList(userList);
          setFilteredSearchList(body.registrants);
          setFilteredUsersList(body.registrants.slice(0, 100));
          setNeighbourhoods(neighbourhood);
        } else {
          if (body.registrants) setUsersList(body.registrants);
        }
      }
    });
  };

  const sendInvite = (newSelectedMembers) => {
    const params = {
      campaign_id: campaignId,
      user_client_id: userData.id,
      shared_user_client_id: [newSelectedMembers.id],
      client_id: CLIENT_ID,
    };

    Utility.sendRequest(SEND_FF_SHARE_NOTI, 2, params, (err, res, body) => {
      body = JSON.parse(body);

      if (body.error) {
        toast.error(body.error.message, { containerId: "private" });
      } else
        toast.success(
          `${Utility.capitalize(
            NAMES_MAPPING.CAMPAIGN.story.parent
          )} shared successfully.`,
          { containerId: "private" }
        );
    });
  };

  const getCampaignById = (id) => {
    dispatch(showLoader(true));
    const params = {
      user_client_id: userData.id,
      campaign_id: id,
      publisher_network_id: CLIENT_ID,
    };

    Utility.sendRequest(CAMPAIGN_DETAILS, 1, params, (err, res, body) => {
      body = JSON.parse(body);

      if (body.error) {
        toast.error(body.error.message, { containerId: "private" });
      } else {
        if (body.data.length > 0) {
          console.log(body.data[0]);
          setCampaignDetails(body.data[0]);
        }
      }
      dispatch(showLoader(false));
    });
  };

  const handleClick = (event, item) => {
    if (item) {
      if (!invites.find(({ id }) => id === item.id)) {
        sendInvite(item);
        setInvites([...invites, item]);
        setShowInvites(false);
      } else if (invites.length === 0) {
        sendInvite(item);
        setInvites([...invites, item]);
        setShowInvites(false);
      }
      if (invites.find(({ id }) => id === item.id)) {
        toast.warn("Event has already been shared with the participant");
      }
      event.preventDefault();
    }
  };

  const handleChatClick = (e, item) => {
    e.stopPropagation();
    dispatch(updateIsChatFormOpen(true));
    dispatch(updateChatFriend(item));
  };

  const handleRemoveValue = (i) => {
    const data = invites.filter((_, idx) => i !== idx);
    setInvites(data);
  };

  return (
    <div className="h-100">
      {campaignDetails && (
        <>
          <div className="d-flex justify-content-center course-confirm-card-container align-items-center">
            <CourseCard
              onClick={() => {
                props.history.push(`/campaign/${campaignId}`, {
                  from: props.location.pathname,
                  in_progress: campaignDetails.in_progress === null || campaignDetails.in_progress === false ,
                });
              }}
              data={campaignDetails}
            />
          </div>
          <div className="d-flex justify-content-center pt-5">
            <div className="form-control form-control-sm w-50 box-shadow-create h-auto p-0 m-left-5 mb-3 invites-bottom ">
              <div className="d-flex align-items-center w-100">
                <input
                  type="text"
                  className="form-control form-control-sm w-100 outline-none shadow-none m-0 border-none invites-input "
                  name="invites"
                  id="invites"
                  aria-describedby="helpId"
                  placeholder="Write a name or group"
                  onFocus={(e) => {
                    setShowInvites(true);
                    e.preventDefault();
                  }}
                  onChange={(e) => {
                    setSearch(e.target.value);
                    e.preventDefault();
                  }}
                  onKeyDown={(e) => {
                    if (
                      e.key === "Escape" ||
                      e.key === "Esc" ||
                      e.keyCode === 27
                    ) {
                      setShowInvites(false);
                      setSearch("");
                    }
                  }}
                  onClick={() => {
                    if (showInvites === false) {
                      setShowInvites(true);
                    }
                  }}
                />
                {showInvites && (
                  <div
                    onClick={(e) => {
                      e.preventDefault();
                      setShowInvites(false);
                    }}
                    className="cursor-pointer"
                  >
                    <CgClose size={36} stroke={5} />
                  </div>
                )}
              </div>
              <div
                className="invites-container scroll-y"
                onScroll={handleScroll}
              >
                {showInvites &&
                filteredUsersList &&
                filteredUsersList.length > 0
                  ? filteredUsersList.map((item, idx) => {
                      return (
                        <div
                          key={idx}
                          className="d-flex align-items-center invite-container ml-4 w-75 pl-4 my-2 cursor-pointer"
                          onClick={(event) => handleClick(event, item)}
                        >
                          <div className="d-flex align-items-center">
                            {item.profile_image_url ? (
                              <img
                                src={item.profile_image_url || ""}
                                className="img-fluid rounded-circle invites-image mr-4 "
                                alt=""
                              />
                            ) : (
                              <Avatar
                                name={item && item.user_name}
                                round={true}
                                size={60}
                                textSizeRatio={1.75}
                                color="#007bb3"
                                maxInitials={1}
                                className={"mr-4"}
                              />
                            )}
                            <div>
                              <div className="font-20-bold user-name d-flex align-items-center">
                                {item &&
                                  item.user_name &&
                                  item.user_name.trim()}
                              </div>
                              <div className="font-12 d-flex align-items-center invites-subtext">
                                {item.city ? item.city : ""}
                                {item.city && item.country ? ", " : ""}
                                {item.country ? item.country : ""}
                              </div>
                            </div>
                            {loading && <p>Loading...</p>}
                          </div>
                        </div>
                      );
                    })
                  : showInvites && <h4>No Records Found</h4>}
              </div>
            </div>
            {!showInvites &&
              invites.map((item, idx) => {
                return (
                  <div
                    key={idx}
                    className="d-flex align-items-center  justify-content-between mb-3 invite-container py-2 w-50 px-4 "
                  >
                    <div className="d-flex align-items-center">
                      {item.profile_image_url ? (
                        <img
                          src={item.profile_image_url || ""}
                          className="img-fluid rounded-circle invites-image mr-4 "
                          alt=""
                        />
                      ) : (
                        <Avatar
                          name={item && item.user_name}
                          round={true}
                          size={60}
                          textSizeRatio={1.75}
                          color="#007bb3"
                          maxInitials={1}
                          className={"mr-4"}
                        />
                      )}
                      <div>
                        <div className="font-20-bold user-name d-flex align-items-center">
                          {item && item.user_name && item.user_name.trim()}
                        </div>
                        <div className="font-12 d-flex align-items-center invites-subtext">
                          {item.city ? item.city : ""}
                          {item.city && item.country ? ", " : ""}
                          {item.country ? item.country : ""}
                        </div>
                      </div>
                    </div>
                    <div className="d-flex ">
                      <img
                        onClick={(e) => handleChatClick(e, item)}
                        src={require("assets/images/icons/chat_icon_dark.svg")}
                        className="mr-4 pb-1 invite-chat-icon cursor-pointer"
                        alt=""
                      />
                      {/* <div
                        onClick={(e) => {
                          handleRemoveValue(idx);
                          e.preventDefault();
                        }}
                        className="cursor-pointer"
                      >
                        <CgClose size={36} stroke={5} />
                      </div> */}
                    </div>
                  </div>
                );
              })}
          </div>
          <div className="d-flex justify-content-center  p-5">
            <button
              className="btn btn-primary go_to_article "
              onClick={() => {
                props.history.push(`/campaign/${campaignId}`, {
                  from: props.location.pathname,
                });
              }}
            >
              GO TO COURSE
            </button>
          </div>
        </>
      )}
    </div>
  );
};

export default withRouter(ConfirmSubmitCourse);
