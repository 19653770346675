import React, { Component } from "react";
import { toast, ToastContainer } from "react-toastify";
import { css } from "@emotion/core";
import { connect } from "react-redux";
// import ClipLoader from 'react-spinners/ClipLoader';
import CloseToast from "shared/components/close_toast";
import "react-toastify/dist/ReactToastify.css";
import { Backdrop, CircularProgress } from "@mui/material";

const override = css`
  display: block;
  margin: 0 auto;
  border-color: red;
  z-index: 100;
  position: absolute;
  top: 50vh;
  left: 50vw;
  font-size: 50px;
  color: white;
  transform: translate(-50%, -50%);
`;

class EmptyLayout extends Component {
  render() {
    let loader = "";

    if (this.props.isLoading) {
      loader = (
        <Backdrop
          sx={{ color: "#fff", zIndex: 999 }}
          open={true}
          // onClick={handleClose}
        >
          <CircularProgress color="inherit" />
        </Backdrop>
      );
    }
    return (
      <>
        {loader}
        <main className="main-container">
          <ToastContainer
            hideProgressBar={true}
            autoClose={6000}
            containerId={"public"}
            position={toast.POSITION.TOP_RIGHT}
            closeButton={<CloseToast />}
          />
          {this.props.children}
        </main>
      </>
    );
  }
}

const mapStateToProps = (state) => ({
  isLoading: state.isLoading,
});

export default connect(mapStateToProps, {})(EmptyLayout);
