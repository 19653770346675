export const DROPDOWN_ACTIONS = {
  networks: [{ id: 1, name: "Invite" }],
  finances: [{ id: 1, name: "Request or Pay" }],
  communications: [
    // { id: 1, name: "Compose" },
    // { id: 2, name: "Direct Message" },
  ],
  statistics: [{ id: 1, name: "Create Report" }],
  campaigns: [
    { id: "", name: "Create" },
    { id: "e4fb37c6-d02e-4cf3-a9f1-17a13cc6cdb9", name: "Story" }, // 1
    { id: "282b3bfe-51d6-4795-bb2f-ec2046f1f346", name: "Initiative" }, // 5
    { id: "6f7b9a0e-081b-4409-8440-e42ca9d97c28", name: "Post" }, // 2
    { id: "c5604d09-075b-4273-b3f6-57680232b486", name: "Event" }, // 6
  ],
  shops: [{ id: 2, name: "Add Product" }],
};

export const MENU = {
  networks: [
    { id: 1, menu_name: "Team" },
    { id: 2, menu_name: "Internal Networks" },
    { id: 3, menu_name: "External Networks" },
    { id: 4, menu_name: "Audience" },
  ],
  communications: [
    // { id: 1, menu_name: 'All Communications' }, // 1
    { id: 2, menu_name: "Notifications" }, // 2
    // { id: 3, menu_name: "Direct Messages" }, // 3
    { id: 4, menu_name: "Comments" }, // 4
  ],
  campaigns: [
    { id: 1, menu_name: "Live Feed", count: "none" },
    { id: 3, menu_name: "Need Approval" },
    { id: 4, menu_name: "My Campaigns" },
  ],
  finances: [
    { id: 1, menu_name: "Overview" },
    { id: 2, menu_name: "Licenses" },
    { id: 3, menu_name: "Statements" },
    { id: 4, menu_name: "Billing" },
  ],
  financesClients: [{ id: 5, menu_name: "Clients" }],
  statistics: [{ id: 1, menu_name: "Overview" }],
  tools: [
    { id: 1, menu_name: "Market Place" },
    { id: 2, menu_name: "ToolBox" },
  ],
  shops: [{ id: 1, menu_name: "Products" }],
  adminMenus: [
    { id: "sold1", menu_name: "Sold" },
    { id: "Custom1", menu_name: "Custom", count: 0 },
  ],
};

export const SUB_MENU_FILTERS = {
  communications: {
    1: [
      { id: 1, name: "All" },
      { id: 2, name: "Network" },
      { id: 3, name: "Audience" },
    ],
  },
};

export const EMAIL_REGEX_PATTERN = /[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,5}$/;

export const PUBLISHER_NETWORK_ID = 1;

export const BACKSPACE = 8;

export const REQUEST_TYPES = {
  1: "GET",
  2: "POST",
  3: "PUT",
  4: "DELETE",
};

export const PUBLIC_URL = [
  "/",
  "/future_of_community",
  "/who_we_serve",
  "/how_it_works",
  "/about_us",
  "/terms_of_services",
  "/contact",
  "/faq",
  "/privacy_policy",
  "/users/sign_up",
  "/users/sign_in",
  "/users/forgot_password",
  "/users/create_password",
  "/users/activate_user",
  "/gift_cards",
  "/sign_in",
  "/forgot_password",
  "/create_password",
  "/sign_up",
  "/home",
  "/profile_image",
  "/forgot_otp",
  "/update_forgot_password",
  "/forgot_passwords",
  "/splash_screen",
  "/select_account",
  "/event",
];

export const SALT_AND_IV_BYTE_SIZE = 16;

export const AES_IV = "qbgzadbbidewwmps";

export const VALID_STATUS_CODES = [400, 200, 201];

export const UPPERCASE_PATTERN = /[A-Z]/;

export const LOWERCASE_PATTERN = /[a-z]/;

export const NUMBER_PATTER = /[0-9]/;

export const SPECIAL_CHAR_PATTERN = /[!@#$%^&*]/;

export const PASSWORD_LENGTH = 8;

export const TOOLS = {
  gift_cards: 1,
  pledge: 2,
  share: 3,
  volunteer: 4,
  donate: 5,
  registration: 6,
  broadcasts: 7,
};

export const NETWORK_TYPES = {
  1: "Internal Network",
  2: "External Network",
};

export const TIME_FORMAT = "hh:mm A";

export const DATE_FORMAT = "MM/DD/YYYY";

export const MAX_IMAGE_SIZE = 5242880;

export const MAX_VIDEO_SIZE = 4294967296;

export const MAX_DOCUMENT_SIZE = 10485760;

export const TOOL_BAR_OPTION = {
  options: [
    "inline",
    "blockType",
    "fontSize",
    "list",
    "textAlign",
    "colorPicker",
    "link",
    "embedded",
    "emoji",
    "image",
    "history",
  ],
};
export const COMMENT_TOOL_BAR_OPTION = {
  options: [
    "inline",
    "blockType",
    "fontSize",
    "list",
    "textAlign",
    "link",
    "embedded",
    "emoji",
    "image",
    "history",
  ],
};

export const YOUTUBE_PATTERN =
  /^.*(youtu.be\/|v\/|u\/\w\/|embed\/|watch\?v=|&v=|\?v=|\?vi=)([^#&?]*).*/;

export const STORY_URL_PATTERN =
  /^(https?:\/\/)?(storymaps.esri.com|[a-z]+?.maps.arcgis.com)/;

export const URL_PATTERN =
  /^(http:\/\/www\.|https:\/\/www\.|http:\/\/|https:\/\/)?[a-z0-9]+([\-\.]{1}[a-z0-9]+)*\.[a-z]{2,5}(:[0-9]{1,5})?(\/.*)?$/;

export const S3_BASE_URL = process.env.REACT_APP_S3_BASE_URL;

export const SEARCH_MINIMUM_LENGTH = 3;

export const GROUP_MEMBERS_LIMIT = 5;

export const FORGOTPASSWORD = {
  send_email: 1,
  verify_otp: 2,
  update_password: 3,
};

export const FIELD_TYPE_VALUES = {
  radio: 0,
  checkbox: 1,
  "select-one": 2,
  slider: 3,
  number: 4,
  "select-many": 5,
  range: 6,
};

export const PERMISSIONS = {
  NETWORKS: "NETWORKS",
  CREATE_CATEGORY: "CREATE_CATEGORY",
  EDIT_CATEGORY: "EDIT_CATEGORY",
  DELETE_CATEGORY: "DELETE_CATEGORY",
  ADD_ADMIN: "ADD_ADMIN",
  CAMPAIGNS: "CAMPAIGNS",
  CREATE_CAMPAIGNS: "CREATE_CAMPAIGNS",
  EDIT_CAMPAIGNS: "EDIT_CAMPAIGNS",
  DELETE_CAMPAIGN: "DELETE_CAMPAIGNS",
};

export const ROLES = {
  USER: "Citizen",
  CLIENT_OWNER: "Neighbourhood",
  CLIENT_ADMIN: "Citizen",
  PARENT_OWNER: "City",
  PARENT_ADMIN: "Citizen",
  SUPER_OWNER: "City",
};

export const ROLE_IDS = {
  USER: "604698b9-997f-4d4b-a7e7-524cff62a4b9",
  CLIENT_OWNER: "9bed9123-06a7-4b1f-bf1f-4ed9e779fd79",
  CLIENT_ADMIN: "8aa1f764-8d6f-47c0-a049-d1239267dc74",
  PARENT_OWNER: "de16a4f7-dcc9-45ab-bbe1-1c9ba2cc38da",
  PARENT_ADMIN: "082df2b2-b048-4b99-a019-9870b339753b",
  SUPER_OWNER: "fd6dd9c1-96dd-4489-bfe3-ed823d5e5446",
};

export const CAMPAIGN_TYPES_IDS = {
  story: "6f8b1d1e-c537-4580-835b-8b6b58bfd4ec",
  notebook: "6f8b1d1n-c537-4580-100b-8b6b58bfd4nt",
  initiative: "30a6fd93-4642-46cb-afe8-4cc830521fb9",                                  
  post: "cdbd0beb-41e5-4894-b932-210665665a29",
  event: "93ab8d70-3d6b-4f3f-a702-8ead10a90bcf",
  courses: "39ab8d70-3d6b-4f3f-a702-8ead10a90bcf",
  lesson: "734d8758-24de-4185-90f8-99adb47b75f3",
  section: "e70c4cde-76f9-45b4-9352-1e2fd6ccf799",
  discussion: "4d883f93-a5b0-4d66-be12-4142e01aa776"
};

export const MESSAGE_TYPE_IDS = {
  TEXT_MESSAGES: "320b200e-56b3-4b5d-9cdf-df9278bf2b3a",
  CAMPAIGNS: "7030eef7-8a05-499b-8331-e367281d0425",
  MEDIA: "fdae04d8-5e3f-4376-b3d7-ffc77b9490ba",
};

export const RANKS = {
  "6f8b1d1e-c537-4580-835b-8b6b58bfd4ec": [1, 2, 3, 4, 5, 6, 7, 8, 9, 10],
  "93ab8d70-3d6b-4f3f-a702-8ead10a90bcf": [1, 2, 3],
};

export const FILTER_OPTIONS = [
  { id: CAMPAIGN_TYPES_IDS.story, name: "Stories" },
  { id: CAMPAIGN_TYPES_IDS.initiative, name: "Initiatives" },
  { id: CAMPAIGN_TYPES_IDS.post, name: "Posts" },
  { id: CAMPAIGN_TYPES_IDS.event, name: "Events" },
];

export const FOLLOWERS_FOLLOWING = {
  follower: "Followers",
  following: "Following",
};

export const PASSPORT_TOOL_ID = "90babd73-ed2a-49d5-9d10-9353195fdc9c";

export const CRM_MODULE_IDS = {
  NETWORK: "ac836894-a352-4460-a011-09828c38ec10",
  CAMPAIGN: "b6427f96-9cae-4ece-bb73-2dde4e57a734",
};

export const ACTION_ITEM = [
  { id: 2, name: "Edit", iconName: "edit_icon.svg" },
  { id: 1, name: "Remove", iconName: "trash.svg" },
];

export const STATUS = {
  approved: "approved",
  pending: "pending",
  declined: "declined",
};

// All Client IDs
export const SM_CLIENT_ID = "19d6dc5f-86b8-4f55-9f64-c8c93082c4e1";
export const GIL_CLIENT_ID = "0bc8dc7c-b5a3-4344-b313-819fc640f6b5";
export const NAME_SILHOUETTE_CLIENT_ID = "1931e809-09ff-4a1c-9718-67a1bd5ca265";

export const PAYMENT_RECEIVER_USER_CLIENT_ID =
  "a917e0e1-740e-11ea-adc8-b73a2b77d69d";

export const CITY_PURCHASE_AMOUNT = 500;

export const DEFAULT_TAX_PERCENTAGE = 7.5;

export const COURSE_BADGE_ID = "60adc235-f3e0-4118-8511-c68100ca7420";

export const PASSING_SCORE = 40;

export const LOCALSTORAGE = {
  USER_INFO: "user_info",
  SHIPPING_INFO: "shipping_address_info",
  PAYMENT_METHOD_INFO: "payment_method_info",
  EXT_USER_ID: "ext_user",
  EXT_TOPIC: "ext_topic",
  IS_SESSION_START: "is_session_start",
};

export const NS_PRODUCTS = {
  lady: "lady",
  gentleman: "rman",
  boy: "boy",
  girl: "girl",
};
