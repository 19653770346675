import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { updateIsChatFormOpen, updateChatFriend, updateSeeAll } from 'shared/actions';
import { Link } from 'react-router-dom';
import Avatar from "react-avatar";

export const FollowerInfo = (props) => {
  const city = props.city ? `${props.city},` : '';
  const state = props.state ? `${props.state},` : '';
  const country = props.country;
  let address = `${city} ${state} ${country}`;
  if (address.trim() === 'undefined' || address.trim() === 'null')
    address = "Content Creator";

  const userData = useSelector(state => state.userData);
  const seeAll = useSelector(state => state.seeAll);
  const isChatFormOpen = useSelector(state => state.isChatFormOpen);
  const dispatch = useDispatch();

  useEffect(() => {
    if(isChatFormOpen) {
    document.querySelector('body').style.overflow = 'hidden';
    document.querySelector('body').scroll = "no";
    }
    else {
      document.querySelector('body').style.overflow = 'auto';
      document.querySelector('body').scroll = "yes";
    }

    return () => {
      document.querySelector('body').style.overflow = 'auto';
      document.querySelector('body').scroll = "yes";
    }
  }, [isChatFormOpen]);

  return(
    <>
      <div className={`${props.fromSeeAll && 'vw-100'} align-items-center justify-content-between border-top border-bottom py-3`} >
      <Link to={{
        pathname: `/profile/${props.id}`,
        state: { otherUserProfile: true } }}
        className = "follower-info-outer"
        onClick={props.fromSeeAll ? () => updateSeeAll({ isOpen: null, calledFrom: seeAll }) : () => {}}
        >
        <div className="align-items-center follower-tab">
          { props.imageUrl ?
            <img
              src={props.imageUrl}
              className="rounded-circle img-product"  alt=""
            /> :
            <Avatar
              name={props.name}
              round={true}
              size={"60"}
              textSizeRatio={1.75}
              color="#007bb3"
              maxInitials={1}
            />
          }
          <div className="lh-1 ml-10 mx-2 follower-info-name" >
            <div className="font-bold display-7 mb-2 text-truncate" >{props.name}</div>
            <div className="font-medium font-14 text-truncate">{address}</div>
          </div>
        </div>
        </Link>
        {
          userData.id !== props.id &&
          <div className="mr-20">
          { !props.isFollowed &&
            <img
             src={require('assets/images/icons/person_plus.svg')}
             className="mr-4 cursor-pointer"
             alt=""
             onClick={() => props.setFollowerfollowing(null)}
             />
           }
           <img
            onClick={() => {
              props.selectedUser &&
              dispatch(updateIsChatFormOpen(true));
              dispatch(updateChatFriend(props.selectedUser));
            }}
            src={require('assets/images/icons/chat_icon_dark.svg')}
            className="community-chat-icon cursor-pointer"
            alt="" />
          </div>
        }
      </div>
    </>
  );
};
