import React, { useEffect, useState } from "react";
import { Utility } from "utils";
import { RedoIcon, TickFilledIcon } from "shared/icons/icons";
import RichTextEditor from "shared/components/rich_text_editor";
import { CLIENT_ID } from "config/constants/config";
import {
  USER_ASSIGNMENT,
  CREATE_DISCUSSION_COMMENT,
} from "../../../../../config/constants/api_endpoints";
import { has } from "lodash";
import { toast } from "react-toastify";
import { useDispatch } from "react-redux";
import { showLoader } from "../../../../actions";
import { CircleFilled, LessonTickFilledIcon } from "../../../../icons/icons";
function AssignmentInputPanal(props) {
  const dispatch = useDispatch();
  const [isEdit, setIsEdit] = useState(true);
  const [essayContent, setessayContent] = useState(null);
  const [questionData, setquestionData] = useState("");
  const [selectedOption, setselectedOption] = useState(null);
  const [selectedOptioninfo, setselectedOptioninfo] = useState(null);
  const [AnswerData, setAnswerData] = useState(null);
  const [shareAnswer, setShareAnswer] = useState(false);
  const changeEssay = (data) => {
    let plainEssayContent = data;
    plainEssayContent = plainEssayContent
      ? plainEssayContent.replace(/<[^>]+>/g, "")
      : "";
    setquestionData(plainEssayContent);
    setessayContent(data);
  };

  useEffect(() => {
    if (props.questionData.answers && props.questionData.answers !== null) {
      changeEssay(props.questionData.answers[0].essay_content);
      props.questionData.answers[0].essay_content !== null && setIsEdit(false);
    }
  }, []);

  const handlesaveAnswer = () => {
    const lesson_id = props.lessondata[0].campaign_id;
    const assignmentAnswer = essayContent;

    const assignmentQuestion = props.questionData.question_title;
    {
      shareAnswer &&
        shareAnswerInDiscussion(
          lesson_id,
          assignmentAnswer,
          assignmentQuestion
        );
    }
    const params = {
      assignment_id: props.assignmentData.id,
      question_id: props.questionData.id,
      option_id:
        props.questionData.question_type !== 3
          ? selectedOptioninfo.id
          : props.questionData.question_options_attributes[0].id,
      is_correct:
        props.questionData.question_type !== 3
          ? selectedOptioninfo.is_correct
          : props.questionData.question_options_attributes[0].is_correct,
      user_id: props.user_id,
      essay_content:
        props.questionData.question_type === 3 ? essayContent : null,
      plain_essay_content:
        props.questionData.question_type === 3 ? questionData : null,
    };
    dispatch(showLoader(true));
    const reqUrl =
      props.questionData.answers !== null &&
      (has(props.questionData.answers[0], "answer_id") ||
        (props.questionData.answers[0].answers !== null &&
          props.questionData.answers[0].answer_id))
        ? `${USER_ASSIGNMENT}/${props.questionData.answers[0].answer_id}`
        : USER_ASSIGNMENT;
    const method =
      props.questionData.answers &&
      has(props.questionData.answers[0], "answer_id")
        ? 3
        : 2;
    Utility.sendRequest(reqUrl, method, params, (err, res, body) => {
      body = JSON.parse(body);
      if (body.error) {
        toast.error(body.error.message, { containerId: "private" });
        dispatch(showLoader(false));
      } else {
        setAnswerData(
          body.data.essay_content ? body.data.essay_content : essayContent
        );
        props.handleLessonAssignment(lesson_id, "CourseAssignment");
        dispatch(showLoader(false));
        setIsEdit(false);
      }
    });
  };

  const shareAnswerInDiscussion = (
    lesson_id,
    assignmentAnswer,
    assignmentQuestion
  ) => {
    let params = {};
    params = {
      platform_id: CLIENT_ID,
      parent_id: lesson_id,
      description: `<span class=" fs-size-16 f-weight-bold"><p class='f-weight-normal'>${assignmentQuestion}</p> <span class="d-flex"> Answer : ${assignmentAnswer}</span> </span>`,
    };

    dispatch(showLoader(true));
    Utility.sendRequest(
      CREATE_DISCUSSION_COMMENT,
      2,
      params,
      async (err, res, body) => {
        body = JSON.parse(body);
        if (body.error) {
          toast.error(body.error.message, { containerId: "private" });
          dispatch(showLoader(false));
        } else {
          // props.getCommentData();
          // props.handleReply && props.handleReply();
          // props.changeDescription("", "comment");
          toast.info(body.message, { containerId: "private" });
          dispatch(showLoader(false));
        }
      }
    );
  };

  const onToggleClick = (evnt, i, items) => {
    setselectedOption(i);
    setselectedOptioninfo(items);
  };

  return (
    <div
      className={`assignment-common-styling course-sidecallout mb-5 overflow-hidden p-4`}
    >
      {isEdit === false ? (
        ((props.questionData.question_type === 3 &&
          props.questionData.answers &&
          props.questionData.answers !== null &&
          props.questionData.answers[0].essay_content !== null) ||
          AnswerData) && (
          <>
            <div
              className={`${
                (isEdit === false || props.questionData.answers !== null) &&
                "answered-assignment-wrapper"
              }`}
            >
              <div className="d-flex align-items-baseline justify-content-between mb-4">
                <div className="position-relative ml-2">
                  <div className="font-24 font-weight-bold">
                    {props.questionData.question_title}
                  </div>
                  <div className="text-primary-light b-600 font-14 mt-1">
                    {Utility.getQuestionType(props.questionData.question_type)}
                  </div>
                </div>
              </div>
              <div
                className="ml-2 answer-wrapper"
                dangerouslySetInnerHTML={{
                  __html: AnswerData
                    ? AnswerData
                    : props.questionData.answers[0].essay_content,
                }}
              ></div>
            </div>
            <div className="mt-3 save-answer-panal">
              <button
                className="btn btn-outline-primary btn-half-rounded px-5 font-weight-bold"
                onClick={() => setIsEdit(true)}
              >
                Edit
              </button>
            </div>
          </>
        )
      ) : (
        <>
          <div className="d-flex align-items-baseline justify-content-between mb-4">
            <div className="position-relative ml-2">
              <div className="font-24 font-weight-bold">
                {props.questionData.question_title}
              </div>
              <div className="text-primary-light b-600 font-14 mt-1">
                {Utility.getQuestionType(props.questionData.question_type)}
              </div>
            </div>
          </div>

          <ul className="list-unstyled ">
            {props.questionData.question_type !== 3 &&
              props.questionData.question_options_attributes &&
              props.questionData.question_options_attributes.map((item, i) => (
                <li className="mb-4" key={`key_${i}`}>
                  <div className="d-flex">
                    <label className="cursor-pointer mb-0">
                      <input
                        type="checkbox"
                        className="toolbox-card-checkbox"
                        checked={selectedOption === i}
                        onClick={(ev) => onToggleClick(ev, i, item)}
                      />
                      <div className="toolbox-card-checkbox-outer assignment-checkbox">
                        <TickFilledIcon fillClass="fill-primary" />
                      </div>
                    </label>
                    {props.questionData.question_type !== 3 ? (
                      <div className="font-18 ml-3 font-weight-bold">
                        {i + 1}.{" "}
                        {
                          props.questionData.question_options_attributes[i]
                            .option_value
                        }
                      </div>
                    ) : (
                      <div className="font-18 ml-3 font-weight-bold">
                        Answer
                      </div>
                    )}
                  </div>
                </li>
              ))}
            {props.questionData.question_type === 3 && (
              <RichTextEditor
                id="essayContent"
                courseClass="course-editor-wrapper"
                name="essayContent"
                data={AnswerData ? AnswerData : essayContent}
                changeDescription={changeEssay}
                placeholder="Write your answer here..."
              />
            )}
          </ul>
          <ul>
            <div
              onClick={() => setShareAnswer(!shareAnswer)}
              className="share-check-wrapper"
            >
              {shareAnswer ? (
                <LessonTickFilledIcon />
              ) : (
                <CircleFilled color={"lightgrey"} />
              )}

              <div className="share-text">
                Share my answer in the Discussion.
              </div>
            </div>
          </ul>
          <div className="save-answer-panal">
            <button
              className="btn btn-primary btn-half-rounded px-5 font-weight-bold"
              onClick={() => handlesaveAnswer()}
            >
              Save
            </button>
          </div>
        </>
      )}
    </div>
  );
}

export default AssignmentInputPanal;
