import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { toast } from "react-toastify";
import { connect } from "react-redux";
import moment from "moment";
import { Utility } from "utils";
import { CAMPAIGNS_TOP_STORIES } from "config/constants/api_endpoints";
import { CLIENT_ID, COMMUNITY_CARD_LIMIT } from "config/constants/config";
import { SM_CLIENT_ID, ROLE_IDS, GIL_CLIENT_ID } from "config/constants";
import { updateOpenCityPurchase } from "shared/actions";
import NewsCard from "../../../profile/components/NewsCard";
import { useHistory } from "react-router-dom";
import { Skeleton } from "@mui/material";
function WelcomeSection(props) {
  const handleArticleClick = (data) => {
    // setNewsOpen(false);
    // console.log(isNewsOpen,"clicked ho gaya")
    props.history.push({
      pathname: `/news-details/${data.parent_id || data.id}`,
      calledFrom: props.type,
      goBackLink: goBackLink(),
    });
  };
  const goBackLink = () => {
    if (props.type === "suggestedReads") {
      return "/home";
    } else if (props.type === "myArticles") return "/profile";
  };
  return (
    <>
      <div className="welcome-card-section pl-25">
        <h1 className="font-24-bold ml-1 mb-25 home-page-heading text-capitalize">
          {/* Welcome, {userData && (userData.user_name && userData.user_name.split(" ")[0])}! */}
          <span className="font-weight-bold d-flex align-items-center">
            <img src={require("assets/images/news.png")} height="20px" />
            <p className="m-auto pl-2">Trending News Article</p>
          </span>
        </h1>

        {props.loadingTrending ? (
          <Skeleton
            variant="rounded"
            // width={750}
            height={250}
            className="rounded-20 trendingNewsClass"
          />
        ) : (
          props.feeds &&
          props.feeds.length !== 0 && (
            <NewsCard
              onClick={() =>
                props.history.push({
                  pathname: `/news-details/${
                    props.feeds[0].parent_id || props.feeds[0].id
                  }`,
                })
              }
              key={props.feeds[0].id}
              data={props.feeds[0]}
              Totalcourses={props.feeds}
              trendingArticles={true}
              handleArticleClick={handleArticleClick}
            />
          )
        )}
      </div>
    </>
  );
}

const Carousel = (props) => {
  // const handleOnDragStart = (e) => e.preventDefault()

  const paintWelcomeStories = () => {
    const arr = [];
    props.data.map((e) => {
      arr.push(
        <div
          className="welcome-card overflow-hidden d-flex w-70 bg-white"
          key={1}
        >
          <Link to={`/campaign/${e.id}`}>
            <img src={e.image_url} className="welcome-card-img" alt="" />
          </Link>
          <div className="p-lg-4 p-2 d-flex w-100">
            <Link
              to={{
                pathname: `/profile/${e.user_client_id}`,
                state: { otherUserProfile: true },
              }}
            >
              <img
                src={e.author_image_url}
                className="img-border rounded-circle welcome-card-heading-img mr-2"
                alt=""
              />
            </Link>
            <div className="welcome-card-body ml-1 w-100 d-flex flex-column">
              <Link to={`/campaign/${e.id}`}>
                <div className="welcome-card-heading text-black">
                  <span className="display-7 font-bold">{e.heading}</span>
                  {props.userData &&
                    props.userData.platform_id === SM_CLIENT_ID && (
                      <span className="font-14 mb-2 font-bold text-primary article-category d-none show-in-sm">
                        {e.campaign_category}
                      </span>
                    )}
                </div>
                <div className="text-muted font-14 mt-2 text-black">
                  {moment(e.updated_at).format("ll")}
                </div>
                <div className="welcome-card-text text-black">
                  {e.description
                    .replace(/<[^>]+>/g, "")
                    .replace(/(\r\n|\n|\r)/gm, "")
                    .replace(/^(.{200}[^\s]*).*/, "$1") + " ..."}
                </div>
                <div className="mt-auto chip-sm ml-auto cursor-pointer">
                  <img
                    src={require("assets/images/icons/right_black.svg")}
                    alt=""
                  />
                </div>
              </Link>
            </div>
          </div>
        </div>
      );
    });
    return arr;
  };

  return paintWelcomeStories();
  /* <AliceCarousel
    //   items={paintWelcomeStories()}
    //   onDragStart={handleOnDragStart}
    //   fadeOutAnimation
    //   mouseTrackingEnabled
    //   buttonsDisabled
    //   dotsDisabled
    //   playButtonEnabled={false}
    //   disableAutoPlayOnAction
    //   >
    // </AliceCarousel>*/
};

const mapActionToProps = {
  updateOpenCityPurchase,
};

const mapStateToProps = (state) => ({
  userData: state.userData,
  openCityPurchase: state.openCityPurchase,
});

export default connect(mapStateToProps, mapActionToProps)(WelcomeSection);
