import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { updateUserData } from 'shared/actions';
import { useLocation } from "react-router-dom";
import ReactPlayer from 'react-player';

export const PwaLandingPage = (props) => {
  const dispatch = useDispatch();
  //const [isOnboardingScreen, setOnboardingScreen] = useState(true);
  //const location = useLocation();
  //let openOnBorading = true;
 // openOnBorading = location && location.state ? false : true;
  useEffect(() => {
    dispatch(updateUserData({}));
  }, []);

  const BeginScreen = () => {
    return (
      <>
        <div className="bg-primary text-light gil-splash-screen d-flex flex-column" >
          <div>
            <div className="position-relative" >
              <ReactPlayer
                playing={true}
                url={require("assets/videos/GIL Journey Passport Promo.mp4")}
                className='react-player'
                width='100%'
                controls={false}
                playsInline={true}
                loop={true}
              />
              <img src={require('assets/images/splash-bg-vid.svg')} className="position-absolute w-100"  style={{bottom: 0}} alt="" />
            </div>
            
            <div className="text-center mt-5 mb-4" >
              <img style={{ maxWidth: 250 }} src={require('assets/images/logos/main-logo-white.png')}  alt=""/>
            </div>
          </div>
          
            <div className="mt-auto text-center" >
              <div className="display-7 pb-2" >
                <span className="font-bold" >START</span> your journey...
              </div>
              <div className="px-4">
                <button className="btn btn-light text-primary my-4 w-100 br-15" style={{height: 58}} onClick={() => props.history.push('/sign_up')}> START</button>
              </div>
              <button className="btn btn-link btn-cancel text-light lg-btn w-100 mb-5 pt-2" onClick={() => props.history.push('/sign_in')}>LOGIN</button>
            </div>
        </div>
      </>
    );
  };

  // const messageTitle = `The Global Institute of Leadership supports 130 National` +
  //   ` Evangelical Alliances who represent and serve over 600 million global evangelicals.`;
  return (

    // isOnboardingScreen && openOnBorading ?
      <BeginScreen />
      // :
      // <div className="pwa-login-screen px-4 py-5 h-100 d-flex flex-column main-screen-view" >
      //   <div className="d-flex">
      //     <img
      //       src={require("assets/images/logos/main-logo.png")}
      //       className="col-10 mx-auto"
      //       alt="sharemeister partners"
      //     />
      //   </div>
      //   <div
      //     className="display-7 text-center my-5 " >
      //     {messageTitle}
      //   </div>

      //   <div className="text-center mt-auto">
      //     <button
      //       className="btn btn-primary btn-login lg-btn mb-3 mb-lg-5 w-100"
      //       onClick={() => props.history.push('/sign_up')}>
      //       CONTINUE
      //       </button>
      //     <button
      //       className="btn btn-link btn-cancel lg-btn w-100"
      //       onClick={() => props.history.push('/sign_in')}>
      //       LOGIN
      //       </button>
      //   </div>
      // </div>
  );
};


