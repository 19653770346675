import React, { useState, useEffect } from 'react';
import SideCallout from '../templates/side_callout';
import { TickFilledIcon } from 'shared/icons/icons';
import { Utility } from 'utils';
import { CloseIcon } from 'shared/icons/icons';
import { Link } from 'react-router-dom';
import { CLIENT_ID } from 'config/constants/config';
import { GIL_CLIENT_ID } from 'config/constants';
import { BADGES, USER_BADGES, CRM_MODULES, ALL_PARENT_CAMPAIGNS } from 'config/constants/api_endpoints';
import { useSelector } from 'react-redux';

export default function Success(props){
  const [badges, setBadges] = useState([]);
  const [userBadges, setUserBadges] = useState([]);
  const [campaignLength, setCampaignLength] = useState([]);
  const userData = useSelector(state => state.userData);

  useEffect(() => {
    getBadges();
    getUserBadges();
    getAllParentCampaigns();
  }, []);

  useEffect(() => {
    document.querySelector('body').style.overflow = 'hidden';
    document.querySelector('body').scroll = "no";

    return () => {
      document.querySelector('body').style.overflow = 'auto';
      document.querySelector('body').scroll = "yes";
    };
  });

  const getAllParentCampaigns = () => {
    const params = {
      module_id: CRM_MODULES.CAMPAIGNS,
      client_id: CLIENT_ID,
      user_client_id: userData.id,
      campaign_type_id: props.campaignSelectedTab
    };

    Utility.sendRequest(ALL_PARENT_CAMPAIGNS, 1, params, (err, res, body) => {
      body = JSON.parse(body);

      if (!body.error) {
        setCampaignLength(body.data.length);
      }
    });
  };

  const getBadges = () => {
    const params = {
      clientId: CLIENT_ID,
    };

    Utility.sendRequest(BADGES, 1, params, (error, response, body) => {
      body = JSON.parse(body);

      if (!body.error) setBadges(body);
    });
  };

  const getUserBadges = () => {
    const params = {
      userClientId: userData.id,
      clientId: CLIENT_ID,
    };

    Utility.sendRequest(USER_BADGES, 1, params, (error, response, body) => {
      body = JSON.parse(body);

      if (!body.error) setUserBadges(body.filter((item) => item.name !== 'Calling'));
    });
  };

  const returnBadges = () => {
    const gmBadge = [];
    const emptyBadge = [];

    if(!Utility.isEmpty(userBadges)) {
      userBadges.forEach(e => {
        e.have_badge ?
        gmBadge.push(
          <div className="earned-badge mr-3" >
            <img
              src={require('assets/images/profile/badges/gm-badge.svg')}
              alt=""
            />
          </div>
        ) :
        emptyBadge.push(
          <div className="earned-badge mr-3" >
            <img
              src={require('assets/images/profile/badges/empty-badge.svg')}
              alt=""
            />
          </div>
        );
      });
    }

    return (
      <>
        {gmBadge}
        {emptyBadge}
      </>
    );
  };

  return(
    <SideCallout
      position={`${props.position ? props.position : 'position-sidebar-fixed'} create-call-out`}
      innerPosition={props.innerPosition}
      closeCallOut={props.closeCallOut}
      calloutCloseIcon={<CloseIcon />}
      calloutHeadingSvg={<TickFilledIcon fillClass="fill-primary" />}
      calloutHeading={props.calloutHeading}>
      <div className="align-items-center justify-content-between group-section-outer my-4 pb-3" style={{paddingBottom: '0 !important'}} >
        <div className="w-100 align-items-center justify-content-center pb-lg-5 flex-nowrap" >
          <svg width={42} height={42} viewBox="0 0 42 42">
            <g fillRule="nonzero" fill="none">
              <path
                d="M21 .167C9.5.167.167 9.5.167 21 .167 32.5 9.5 41.833 21 41.833c11.5 0 20.833-9.333 20.833-20.833C41.833 9.5 32.5.167 21 .167zm-4.167 31.25L6.417 21l2.937-2.937 7.48 7.458L32.645 9.708l2.937 2.959-18.75 18.75z"
                fill="#272262"
              />
              <path d="M-4-4h50v50H-4z" />
            </g>
          </svg>
          <div className="ml-3 mx-2 success-text-width" >
            <div className="display-7 mb-2 font-medium" >
              You have successfully
              {
                props.createdOrUpdated
              }
              {/* {indefinite(props.successOf, { articleOnly: true })} */}
               {Utility.capitalize(props.successOf)}
            </div>
          </div>
        </div>
        { props.createdOrUpdated.trim() === 'created' &&
          GIL_CLIENT_ID === userData.client_id &&
          ((props.forCampaign && parseInt(campaignLength) === 1) ||
          (props.forGroup && parseInt(props.groupList.length) === 1)) &&
          <div className="text-center mx-auto" >
            <div className="font-21-bold mb-4 pb-2" >Well Done!</div>
            <div className="rounded-circle align-items-center justify-content-center  badge-display mb-2 mx-auto" >
              <img src={require('assets/images/profile/badges/gm-badge.svg')} alt="" />
            </div>
            <div className="display-6 font-medium mb-4 pb-3" >{Utility.capitalize(props.successOf)}</div>
            <div className="badges-earned-outer d-flex justify-content-center mb-4 pb-3" >
              { returnBadges() }
            </div>
            <div className="display-7 font-bold mb-4 pb-2" >
              You earned a badge for <br/> creating {`${props.successOf === 'group' ? 'a' : 'an' }`} {Utility.capitalize(props.successOf)}.</div>
          </div>
        }
      </div>
      <div className="success-card-outer" >
        {props.card}
      </div>
      <div className="text-center" >
        <Link
          to={{
          pathname: props.link,
          calledFrom: props.forTestimony ? 'Testimony' : '' }}>
          <button
          className="btn btn-primary btn-primary-pwa font-14 px-4 text-uppercase">
          GO TO {props.successOf}
          </button>
        </Link><br />
        { !props.forTestimony &&
          <div className="mt-4 pt-3 ml-2 display-7 font-medium">
            <span
              className="cursor-pointer font-medium"
              onClick={props.handleAddClick}>
                Add
            </span>
            /
            <span
              className="cursor-pointer font-medium"
              onClick={props.handleEditClick}
            >
              Edit&nbsp;
            </span>
            {props.successOf}
          </div>
        }
      </div>
    </SideCallout>
  );
}
