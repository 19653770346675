import React, { useState, useEffect } from 'react';
import Tabs from 'react-bootstrap/Tabs';
import Tab from 'react-bootstrap/Tab';
import { ShareIcon, ChevronUpIcon, CloseIcon } from 'shared/icons/icons';
import { InputField } from 'shared/components/form_elements';
import TileSelection from 'shared/components/tile_selection';
import { Utility } from 'utils';
import { useSelector } from 'react-redux';
import { GET_FOLLOWERS_FOLLOWING, EVENT_MEMBERS, SEND_FF_SHARE_NOTI, GROUPS, GROUP_CAMPAIGNS, MESSAGES } from 'config/constants/api_endpoints';
import { CLIENT_ID, NAMES_MAPPING, GROUPS_INVITE_FOLLOWER_LIMIT, GROUPS_INVITE_FOLLOWING_LIMIT, GROUPS_LIMIT } from 'config/constants/config';
import { STATUS, MESSAGE_TYPE_IDS } from 'config/constants';
import { toast } from 'react-toastify';
import { NoRecordFound } from 'shared/components/no_record_found';

export const Share = (props) => {
  const [groupSearch, setGroupSearch] = useState('');
  const [groupLimit, setGroupLimit] = useState('');
  const [groups, setGroups] = useState([]);
  const [alreadySelectedGroups, setAlreadySelectedGroups] = useState(props.groups);
  const userData = useSelector(state => state.userData);
  const [selectedTab, setSelectedTab] = useState('groups');

  const [followersMembersStates, setFollowersMemberState] = useState({
    followers: [],
    followersLimit: true,
    followersSearch: '',
  });

  const [followingMembersStates, setFollowingMemberState] = useState({
    following: [],
    followingLimit: true,
    followingSearch: '',
  });

  const [newSelectedMembers, setNewSelectedMembers] = useState([]);
  const [eventMembers, setEventMembers] = useState([]);

  let debouncedFn = null;

  useEffect(() => {
    getEventMember();
    getFollowersDetails();
    getFollowingDetails();
  }, [
    followersMembersStates.followersSearch,
    followersMembersStates.followersLimit,
    followingMembersStates.followingSearch,
    followingMembersStates.followingLimit
  ]);

  useEffect(() => {
    props.getCampaignDetails();
    return () => props.getCampaignDetails();
  }, []);

  useEffect(() => {
    getGroups();
  }, [groupSearch, groupLimit]);

  const handleFollowerSearch = (ev) => {
    ev.persist();
    if (!debouncedFn) {
      debouncedFn =  Utility.debounce(() => {
        const searchString = ev.target.value;
        setFollowersMemberState(prevState => {
          return {
            ...prevState,
            followingSearch: searchString
          };
        });
      }, 500);
    }
    debouncedFn();
  };

  const handleFollowingSearch = (ev) => {
    ev.persist();

    if (!debouncedFn) {
      debouncedFn =  Utility.debounce(() => {
        const searchString = ev.target.value;
        setFollowingMemberState(prevState => {
          return {
            ...prevState,
            followingSearch: searchString
          };
        });
      }, 500);
    }
    debouncedFn();
  };

  const handleGroupSearch = (ev) => {
    ev.persist();

    if (!debouncedFn) {
      debouncedFn =  Utility.debounce(() => {
        const searchString = ev.target.value;
        setGroupSearch(searchString);
      }, 500);
    }
    debouncedFn();
  };

  const removeFollowerLimit = () => {
    setFollowersMemberState(prevState => {
      return {
        ...prevState,
        followersLimit: false
      };
    });
  };

  const getFollowingDetails = () => {
    const params = {
      type: 'following',
      clientId: CLIENT_ID,
      userClientId : userData.id,
    };

    if(followingMembersStates.followingLimit)
      params.limit = GROUPS_INVITE_FOLLOWING_LIMIT;

    if(!Utility.isEmpty(followingMembersStates.followingSearch))
      params.search = followingMembersStates.followingSearch;

    Utility.sendRequest(GET_FOLLOWERS_FOLLOWING, 1, params, (error, response, body) => {
      body = JSON.parse(body);

      if (!body.error) {
        setFollowingMemberState(prevState => {
          return {
            ...prevState,
            following: body.following
          };
        });
      }
    });
  };

  const getFollowersDetails = () => {
    const params = {
      type: 'follower',
      clientId: CLIENT_ID,
      userClientId : userData.id,
    };

    if(followersMembersStates.followersLimit)
      params.limit = GROUPS_INVITE_FOLLOWER_LIMIT;

    if(!Utility.isEmpty(followersMembersStates.followersLimit))
      params.search = followersMembersStates.followersLimit;

    Utility.sendRequest(GET_FOLLOWERS_FOLLOWING, 1, params, (error, response, body) => {
      body = JSON.parse(body);

      if (!body.error) {
        setFollowersMemberState(prevState => {
          return {
            ...prevState,
            followers: body.follower
          };
        });
      }
    });
  };

  const share = (groupId) => {
    const params  = {
      groupId: groupId,
      campaignId: props.campaignId,
      sharedBy: props.userData.id
    };

    Utility.sendRequest(GROUP_CAMPAIGNS, 2, params, (err, res, body) => {
      body = JSON.parse(body);

      if (body.error) {
        toast.error(body.error.message, { containerId: 'private' });
      } else {
        toast.success(body.message, { containerId: 'private' });
        const arr = [...alreadySelectedGroups];
        arr.push(groupId);
        createMessageForGroup(groupId, props.campaignId);
        setAlreadySelectedGroups(arr);
      }
    });
  };

  const createMessageForGroup = (group_id, campaign_id) => {
    const params = {
      from_user_client_id: props.userData.id,
      group_id,
      campaign_id,
      message_type_id: MESSAGE_TYPE_IDS.CAMPAIGNS,
      user_id: props.userData.user_id,
    };
  
    Utility.sendRequest(MESSAGES, 2, params, (error, response, body) => {
      body = JSON.parse(body);
      if (body.error) {
        toast.error(body.error.message, { containerId: 'private' });
      }
    });  
  };


  const removeFollowingLimit = () => {
    setFollowingMemberState(prevState => {
      return {
        ...prevState,
        followingLimit: false
      };
    });
  };

  const getEventMember = () => {
    const params = {
      campaignId: props.campaignId,
    };

    Utility.sendRequest(EVENT_MEMBERS, 1, params, (err, res, body) => {
      body = JSON.parse(body);

      if (!body.error) {
        setEventMembers(body.map(e => e.member_id));
      }
    });
  };

  const sendInvite = () => {
    if (props.isEvent) {
      const params = {
        campaignId: props.campaignId,
        userClientIds: newSelectedMembers,
        status: STATUS.pending
      };

      Utility.sendRequest(EVENT_MEMBERS, 2, params, (err, res, body) => {
        body = JSON.parse(body);

        if (body.error) {
          toast.error(body.error.message, { containerId: 'private' });
        } else {
          if(body.data){
            toast.success(body.message, { containerId: 'private' });
          }
        }
      });
    } else {
      if(!Utility.isEmpty(newSelectedMembers)) {
        const params = {
          campaign_id: props.campaignId,
          user_client_id: userData.id,
          shared_user_client_id: newSelectedMembers,
          client_id: CLIENT_ID,
        };

        Utility.sendRequest(SEND_FF_SHARE_NOTI, 2, params, (err, res, body) => {
          body = JSON.parse(body);

          if (body.error) {
            toast.error(body.error.message, { containerId: 'private' });
          } else toast.success(`${Utility.capitalize(NAMES_MAPPING.CAMPAIGN.story.parent)} shared successfully.`, { containerId: 'private' });
        });
      }
    }
    props.closeForm();
  };

  const getGroups = () => {
    const params = {
      clientId: CLIENT_ID,
      search: groupSearch,
      userClientId: props.userData.id,
      limit: GROUPS_LIMIT,
      forMyGroups: true
    };

    Utility.sendRequest(GROUPS, 1, params, (err, res, body) => {
      body = JSON.parse(body);

      if (!body.error) {
        setGroups(body);
      }
    });
  };

  return(
    <>
      <div className="article-page-side h-100" >
        <div className="article-page-side-head py-3 px-4 align-items-center border-bottom" >
          <ShareIcon fillClass="fill-less-black" />
          <span className="display-8 font-bold ml-2" >Share</span>
          <ChevronUpIcon
            handleClick={() => props.switchRightSection('none')}
            mainClass="chevron-up-icon"
          />

            <CloseIcon
              mainClass="ml-auto cursor-pointer"
              handleClick={() => {
                props.switchRightSection('none');
                sendInvite();
              }}
            />
        </div>
        <div className="article-page-side-body py-3 px-4" >
        <div className="client-profile-inner p-0 article-page-tabs" >
        <Tabs id="sidebar-tabs" activeKey={selectedTab} onSelect={(k) => setSelectedTab(k)}>
          <Tab eventKey="groups" title="GROUPS">
            <div className="shar-form search-form search-form-pwa sidebar-form search-form-full" >
              <InputField
                handleChange={handleGroupSearch}
                type="text"
                className="input-form-size mb-4 mt-4"
                placeholder="Search Groups…"
              />
            </div>
            { Utility.isEmpty(groups) ?
              <NoRecordFound /> :
              <TileSelection
                inCallout={true}
                selected={alreadySelectedGroups}
                getSelectedIds={(groupId) => share(groupId)}
                close={() => props.switchRightSection('none')}
                showAll={() => setGroupLimit(false)}
                items={groups}
                isSearch={Utility.isEmpty(groupSearch)}
                forSharing={true}
                showClose={false}
              />
            }
          </Tab>
          <Tab eventKey='followers' title="FOLLOWERS">
            <div className="shar-form search-form search-form-pwa sidebar-form search-form-full pt-4" >
              <InputField
                handleChange={handleFollowerSearch}
                type="text"
                className="input-form-size mb-5"
                placeholder="Search Followers…"
              />
            </div>
            <TileSelection
              inCallout={true}
              selected={newSelectedMembers}
              getSelectedIds={setNewSelectedMembers}
              close={sendInvite}
              showAll={removeFollowerLimit}
              followersLimit={followersMembersStates.followersLimit}
              showClose={true}
              items={followersMembersStates.followers.filter(d => !eventMembers.includes(d.id))}
              isSearch={Utility.isEmpty(followersMembersStates.followersSearch)}
            />
          </Tab>
          <Tab eventKey='following' title="FOLLOWING">
            <div className="shar-form search-form search-form-pwa sidebar-form search-form-full pt-4" >
              <InputField
                handleChange={handleFollowingSearch}
                type="text"
                className="input-form-size mb-5"
                placeholder="Search Followings…"
              />
            </div>
            <TileSelection
              inCallout={true}
              selected={newSelectedMembers}
              getSelectedIds={setNewSelectedMembers}
              close={sendInvite}
              showAll={removeFollowingLimit}
              followersLimit={followingMembersStates.followingLimit}
              items={followingMembersStates.following.filter(d => !eventMembers.includes(d.id))}
              isSearch={Utility.isEmpty(followingMembersStates.followingSearch)}
              showClose={true}
            />
          </Tab>
        </Tabs>
          </div>
        </div>
      </div>
    </>
  );
};
