import React from "react";

const WeaFooter = () => {
  return (
    <footer className="pt-2">
      <ul className="d-flex justify-content-center mt-5 mb-3">
        <li className="mx-2">
          <a href="">
            <img
              src={require("assets/images/gil/instagram-icon.svg")}
              alt="instagram"
            />
          </a>
        </li>

        <li className="mx-2">
          <a href="">
            <img
              src={require("assets/images/gil/facebook-icon.svg")}
              alt="facebook"
            />
          </a>
        </li>
        <li className="mx-2">
          <a href="">
            <img
              src={require("assets/images/gil/linkedin-icon.svg")}
              alt="linkedin"
            />
          </a>
        </li>
      </ul>
      <div className="text-center pt-3 pb-2 copy-right-text">
        WEA PASSPORT 2022 All Rights Reserved.
      </div>
      <div className="text-center pt-2 pb-5 copy-right-text">
        Privacy Policy | Terms and Conditions
      </div>
    </footer>
  );
};
export default WeaFooter;
