import React from "react";
import { Form, Image } from "react-bootstrap";
import { Link } from "react-router-dom";
import { Utility } from "utils";
import { InputField } from "shared/components/form_elements";
import { ROLES } from "config/constants";
import { BackButton } from "shared/components/web";

export default class ForgotEmail extends React.Component {
  render() {
    const { firstName, lastName, role, profileImgUrl, email } = this.props;
    return (
      <React.Fragment>
        <div className="container">
          <div className="register-outer p-sm-5 p-4 text-center mx-auto my-sm-5 my-3">
            <div className="d-flex cursor-pointer">
              <BackButton action={() => this.props.goSignIn()} />
            </div>
            <Link to="/">
              <img
                src={require("assets/images/logos/main-logo.png")}
                className="register-logo mb-4"
                alt="sharemeister"
              />
            </Link>
            <h3 className="mb-5 forgot-password-head font-bold">
              Enter your email and we will <br /> send you a new password!
            </h3>
            <div className="new-landing-theme register-form">
              <div className="account-info-outer align-items-center form-element-outer mx-auto mb-4">
                <div className="account-info-pic mr-3">
                  {firstName ? (
                    <Image
                      className="profile-width rounded-circle golden-border"
                      src={
                        !Utility.isEmpty(profileImgUrl)
                          ? profileImgUrl
                          : require("assets/images/users/user_no_image.png")
                      }
                      alt="Profile Picture"
                    />
                  ) : (
                    ""
                  )}
                </div>
                <div className="text-left">
                  <h5 className="font-bold">
                    {firstName ? firstName : ""} {lastName ? lastName : ""}{" "}
                  </h5>
                  {ROLES[role]}
                </div>
              </div>
              <Form.Group>
                <InputField
                  className="forgot_password_email"
                  required={true}
                  placeholder="Email address"
                  type="email"
                  name="email"
                  ref="email"
                  value={email}
                  handleChange={this.props.handleEmailChange}
                />
              </Form.Group>
            </div>
            <div className="text-left form-element-outer mx-auto mt-5">
              <button
                className="btn btn-primary btn-login"
                onClick={(e) => this.props.forgotPasswordEmail(e)}
              >
                Send
              </button>
            </div>
            <div
              className="d-inline-block mx-auto text-primary font-bold display-7 cursor-pointer pt-5"
              onClick={() => this.props.goSignUp()}
            >
              Create a New Account
            </div>
          </div>
        </div>
      </React.Fragment>
    );
  }
}
