import React, { useEffect, useState } from "react";
import { Grid } from "@material-ui/core";
import Avatar from "react-avatar";
import { withRouter, useHistory } from "react-router-dom";
// import { GIL_CLIENT_ID } from "../../../config/constants";
// import { CLIENT_ID } from "config/constants/config";
import { BsFillCircleFill } from "react-icons/bs";
import NewsCard from "../../../scenes/profile/components/NewsCard";
import { CustomSlider } from "../Slider";
import { toast } from "react-toastify";

// import { ConstructionOutlined } from "@mui/icons-material";
const CourseContainer = (props) => {
  const [courseData, setCourseData] = useState(props.courseData);
  useEffect(() => {
    if (props.category === "featured") setCourseData(props.courseData);
    if (props.search && props.courseData) {
      if (props.search.trim() === "") {
        setCourseData(props.courseData);
      } else {
        const filteredData = props.courseData.filter(
          ({ heading, description }) =>
            heading.toLowerCase().includes(props.search.toLowerCase()) ||
            description.toLowerCase().includes(props.search.toLowerCase())
        );
        setCourseData(filteredData);
      }
    }
  }, [props.search, props.courseData]);

  const [cardClicked, setCardClicked] = useState(false);
  const [checkId, setCheckId] = useState("");
  const [corporateId, setCorporateId] = useState("");
  useEffect(() => {
    // console.log(courseData)
    const data = courseData.find((item) => {
      return item.id === checkId;
    });
    // console.log(data, props.joinedNeighborhood, checkId, "****************")
    if (props.joinedNeighborhood.length !== 0) {
      if (props.joinedNeighborhood.includes(corporateId) && data) {
        handleArticleClick(data);
      } else {
        if (data) {
          toast.error(
            `Please join ${data.corporate_name || data.author_name}`,
            { containerId: "private" }
          );
        }
      }
    }
  }, [cardClicked]);

  const handleClick = (checId, corporate_id) => {
    setCheckId(checId);
    setCorporateId(corporate_id);
    setCardClicked(!cardClicked);
  };
  const handleArticleClick = (data) => {
    props.history.push({
      pathname:
        !props.isArticles && props.PanalTitle !== "All News"
          ? `/campaign/${data.parent_id || data.id}`
          : `/news-details/${data.parent_id || data.id}`,
      calledFrom: props.type,
      state: {
        in_progress: data.in_progress === null || data.in_progress === false,
      },
      goBackLink: goBackLink(),
    });
  };
  const goBackLink = () => {
    if (props.type === "suggestedReads") {
      return "/home";
    } else if (props.type === "courses") return "/courses";
    else if (props.type === "myArticles") return "/profile";
  };
  // function sum(arr) {
  //   let sum = 0; // initialize sum
  //   for (let i = 0; i < arr.length; i++) sum += arr[i];
  //   return sum;
  // }

  useEffect(() => {
    if (courseData.length > 0 && courseData) {
      if (
        props.PanalTitle === "All Courses" ||
        props.PanalTitle === "All News"
      ) {
        props.setFlag(false);
      }
    } else {
      if (props.PanalTitle === "All Courses" || props.PanalTitle === "All News")
        props.setFlag(true);
    }
  }, [props.PanalTitle, courseData]);

  return (
    <>
      {courseData && courseData.length > 0 && (
        <div
          className="course-panal-container fullWidth"
          // style={{ width: props.width }}
        >
          {props.PanalTitle && (
            <h1
              className={`course-text-${
                props.category
              } ml-4 font-24-bold my-4 ${props.isArticles ? "pl-3" : "pl-4"}`}
              style={{ color: props.colorCode ? props.colorCode : "#000" }}
            >
              {props.PanalTitle}
            </h1>
          )}

          {props.PanalTitle === "All News" && (
            <Grid
              container
              spacing={0}
              // columns={{ xs: 12, sm: 6, md: 6, lg: 4, xl: 3 }}
              className="py-2 px-4 d-flex"
              justifyContent="center"
              alignItems="center"
            >
              {" "}
              {courseData
                .sort(function (a, b) {
                  return new Date(b.updated_at) - new Date(a.updated_at);
                })
                .map((data, index) => (
                  <Grid
                    item
                    xs={12}
                    sm={12}
                    md={12}
                    lg={6}
                    xl={4}
                    key={index}
                    className="py-2"
                  >
                    <NewsCard
                      handleArticleClick={handleArticleClick}
                      key={data.id}
                      data={data}
                      Totalcourses={courseData}
                      index={index}
                      joinedNeighborhood={props.joinedNeighborhood}
                      classToOverride="mx-auto"
                    />
                  </Grid>
                ))}
            </Grid>
          )}

          {
            props.PanalTitle !== "All News" &&
              (props.isArticles ? (
                //  <div className="d-flex event-page-outer pb-4 ml-2">
                <CustomSlider>
                  {courseData
                    .sort(function (a, b) {
                      return new Date(b.updated_at) - new Date(a.updated_at);
                    })
                    .map((data, index) => (
                      <NewsCard
                        handleArticleClick={handleArticleClick}
                        key={data.id}
                        data={data}
                        Totalcourses={courseData}
                        index={index}
                        joinedNeighborhood={props.joinedNeighborhood}
                      />
                    ))}
                </CustomSlider>
              ) : (
                //  <div className="card-container d-flex pb-4">
                <CustomSlider>
                  {courseData
                    .sort(function (a, b) {
                      return new Date(b.updated_at) - new Date(a.updated_at);
                    })
                    .map((data, index) => {
                      return (
                        <div
                          key={data.id}
                          onClick={(e) => {
                            courseData.map((course) => {
                              if (course.id === data.id) {
                                if (course.corporate_id === null) {
                                  handleArticleClick(data);
                                } else {
                                  handleClick(course.id, course.corporate_id);
                                }
                              }
                            });
                          }}
                          className={`course-card bg-white cursor-pointer ${
                            props.isHome ? index !== 0 && "ml-3" : "ml-3"
                          }`}
                          style={{ width: "253px" }}
                        >
                          {data.image_url ? (
                            <span className={"image-container"}>
                              <img
                                className={"course-image article-card-image"}
                                src={data.image_url}
                                alt="#"
                              />
                            </span>
                          ) : (
                            <div className=" d-flex no-image-container border-right-0 w-100 align-items-center no-image-course">
                              {/* <Avatar
                          name={data.author_name}
                          round={true}
                          size={"20"}
                          textSizeRatio={1.75}
                          color="#007bb3"
                          maxInitials={1}
                          className={"article-pic"}
                        /> */}
                              <Avatar
                                name={data.author_name}
                                round={true}
                                size={"61"}
                                textSizeRatio={1.75}
                                color="#007bb3"
                                maxInitials={1}
                                className={"community-avatar"}
                              />
                            </div>
                          )}
                          <div className="course-card-content">
                            {/* <span
                        className={`course-text-${props.category} course-category-container`}
                        style={{
                          color: "#422A66",
                          // props.colorCode
                          //   ? props.colorCode
                          //   : data.category_color,
                        }}
                      >
                        {/* #{data.campaign_category} */}
                            {/* </span> */}
                            <span
                              className={
                                "course-title-container mt-2 text-lowercase"
                              }
                            >
                              {data.heading.length > 20
                                ? data.heading.slice(0, 20) + "..."
                                : data.heading}
                            </span>
                          </div>
                          {!props.isArticles &&
                            (data.region_details || data.community_name) && (
                              // <span className="course-lesson-container">
                              //   {" "}
                              //   {sum(
                              //     data.sub_campaign_count.map((data) => {
                              //       return data !== null ? parseInt(data) : 0;
                              //     })
                              //   )}{" "}
                              //   Lesson
                              // </span>
                              <div className="d-flex align-items-center region-text course-lesson-container">
                                {((data.region_details &&
                                  data.region_details.REGION_NAME) ||
                                  data.community_name ||
                                  data.region ||
                                  data.client_name ||
                                  data.country) && (
                                  <BsFillCircleFill
                                    style={{ color: "#ffb366" }}
                                    className="mr-1 course-community-circle"
                                  />
                                )}
                                {/* {(data.region_details &&
                          Object.keys(data.region_details).length) > 0
                          ? data.region_details.REGION_NAME !== null
                            ? data.region_details.REGION_NAME
                            : "Neighbourhood"
                          : "Neighbourhood"} */}
                                {data.region_details &&
                                  data.region_details.REGION_NAME &&
                                  data.region_details.REGION_NAME + "/"}
                                {data.community_name && data.community_name}
                              </div>
                            )}
                        </div>
                      );
                    })}
                </CustomSlider>
              ))
            // </div>
          }
        </div>
      )}
    </>
  );
};

const CourseContainerCard = withRouter(CourseContainer);

export default CourseContainerCard;
