import ReactPlayer from "react-player";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import React from 'react';
import classNames from "classnames";
import c from './VideoUpload.module.scss';


export const VideoUpload = ({isVideoUpload, videoFile, setVideoFile, setIsVideoUpload, video_url, acceptType, id}) => {
    return(
        <div className="col-lg-12 p-0">
      {
        (videoFile !== null && videoFile !== undefined) || (isVideoUpload===false && video_url !== null && video_url !== undefined)
       ?
        <ReactPlayer controls={true} width="100%" height="11.25rem" url={(videoFile !== null && videoFile !== undefined) ? URL.createObjectURL(videoFile) : video_url} />
      :
      <label htmlFor="upload-video" className="w-100">
        <div
          className={classNames(c.uploadVideoContainer)}
        >
          <AddCircleOutlineIcon className={classNames(c.circleIcon)} />
        </div>
        <input
          className="d-none"
          id={id || "upload-video"}
          name={id || "upload-video"}
          type="file"
          accept={acceptType || "video/mp4"}
          onChange={(e) => {
            setVideoFile(e.target.files[0]);
            setIsVideoUpload(true);
          }}
        />
      </label>
      }

      {((isVideoUpload === true && videoFile !== null && videoFile !== undefined) || (isVideoUpload === false && video_url !== null && video_url !== undefined)) && (
        <div
          className={classNames(c.RemoveVideoText)}
          onClick={() => {
            setVideoFile(null);
            setIsVideoUpload(true);
          }}
        >
          Remove Video
        </div>
      )}
      {/* {(error && !isVideoUpload)  && (
        <Text className="text-admin-PersianRed mt-[3px] ml-[14px] text-xs leading-[1.66] tracking-[0.03333em] font-[Roboto]">
          {helperText || ""}
        </Text>
      )} */}
    </div>
    )
}