import React, { Component } from 'react';
import { Col, Form, Button, } from 'react-bootstrap';
import { toast } from "react-toastify";
import { InputField } from 'shared/components/form_elements';
import {  SCHOOLS } from 'config/constants/api_endpoints';
import { CLIENT_ID } from 'config/constants/config';
import * as Utility from 'utils/utility';
import { showLoader } from 'shared/actions';
import { connect } from 'react-redux';


const validateFields = ['name'];

 class CustomGroupForm extends Component {
  constructor(props){
    super(props);

    this.state = {
      formData: this.getFormData(),
      formName:'create_customGroup',
    };
  }

  componentDidMount = () => {
    this.setState({ formData: this.getFormData() });
  }

  componentDidUpdate = (prevProps) => {
    if (prevProps.data !== this.props.data) {
      this.setState({ formData: this.getFormData() });
    }
  }

  handleChange = (event) => {
    let formData = this.state.formData;
    formData = Utility.validateAndReturnFormData(this, formData, event, validateFields, this.state.formName);
    this.setState({ formData });
  }

  handleFormErrors = () => {
    const isValid = Utility.showFormErrors(this, validateFields, this.state.formName);
    return (isValid);
  }


  handleSubmit = (event) => {
    const isFormValid = this.handleFormErrors();
    if (!isFormValid) return;

    const params = {
      client_id: CLIENT_ID,
      is_active: true,
      name: this.state.formData.name
    }

    event.preventDefault();
    if (!Utility.showFormErrors(this, validateFields, this.state.formName)) return;
    this.props.showLoader(true);
    const request = this.props.isUpdateGroup ? 3 : 2 ;
    const route = this.props.isUpdateGroup ? `${SCHOOLS}/${this.props.data.id}` : `${SCHOOLS}`;

    Utility.sendRequest(route, request, params, async(err, response, body) => {
      body = JSON.parse(body);

      if (body.error) {
        toast.error(body.error.message, { containerId: 'private' });
        this.props.showLoader(false);
      } else {
        toast.success(body.message, { containerId: 'private' });
        this.props.getMenuDetails();
        this.props.showLoader(false);
        this.props.closeSummary();
      }
    });
  }

  resetFormData = () => {
      this.setState({ formData: this.getFormData() });
  }

  getFormData = () => {
    return {
      name: this.props.data ? this.props.data.name : ''
    };
  }
  render() {
    return (
      <React.Fragment>
        <Col xs={12} xl={9} lg={8}
          className="h-100 p-4 ModalOpen top-on-sm d-none d-lg-block" id="network-invitation">

            <Form className="mt-4 px-0 shar-form">
              <Form.Group className="mt-3">
                <Form.Label className="font-18-bold mt-2">What is the name of school?</Form.Label>
                <InputField
                  required={true}
                  value={this.state.formData.name}
                  type="text"
                  placeholder="Name"
                  name="name"
                  handleChange={this.handleChange}
                  ref="name"
                />
              </Form.Group>
              <Form.Group className="mt-4 mb-0" >
                <Button
                  className="btn-primary mt-3 sm-btn"
                  onClick={this.handleSubmit}
                  variant="btn-secondary" >Save
                </Button>
              </Form.Group>
              <Form.Group className="mt-4 mb-0" >
              {  <Button
                  variant="link"
                  className="mt-2 sm-btn btn-cancel  font-light"
                  onClick={() => this.resetFormData()}> Cancel
                </Button>
              }
              </Form.Group>
            </Form>
        </Col>
      </React.Fragment>
    );
  }
}

const mapActionToProps = { showLoader };

const mapStateToProps = (state) => ({
  userData: state.userData,
});

export default connect(mapStateToProps, mapActionToProps)(CustomGroupForm);
