import React from 'react';
import { Col, Row, Form } from 'react-bootstrap';
import { withRouter } from "react-router";
import Image from 'react-bootstrap/Image';
import { Checkbox } from 'shared/components/form_elements';
import { CloseIconSharp } from 'shared/icons/icons';

const WelcomeOverlaysContainer = (props) => {
  const redirectToHome = () => {
    props.history.push('/home');
  };
  return (
    <section className="welcome-page welcome-page-modal">
      <div className="modal-close-btn cursor-pointer" onClick = {() => redirectToHome()}>
        <CloseIconSharp />
      </div>
      <div className="welcome-page-inner">
        <div className="container">
          <Row>
            <Col md={3}>
              <div className="top-helping-image">
                <div className="first-image"><Image src={require("assets/images/welcome-triangle.png")} alt="Helping Image" />
                </div>
                <div className="second-image"><Image src={require("assets/images/welcome-circle.png")} alt="Helping Image" />
                </div>
              </div>
            </Col>
            <Col md={7}>
              <div className="welcome-detail">
                <div className="welcome-title text-black font-45 b-700 pb-4">{props.heading}</div>
                <div className="text-black font-25 b-600 my-4">{props.subHeading}</div>
                <p className="font-20 text-black">{props.mainText}</p>
                <button className="btn btn-theme btn-alternate my-5 font-18 hidden-btn">{props.btnText}</button>
                {/* <div className="welcome-modal-checkbox">
                  <Form className="shar-form">
                    <Checkbox
                      checkboxId="message"
                      name="message"
                      label="Thanks! I got the message"
                    />
                  </Form>
                </div> */}
                <div className="bottom-helping-image">
                  <Image src={require("assets/images/welcome-plus.png")} alt="Sharemeister Logo" />
                </div>
              </div>
            </Col>
          </Row>
        </div>
      </div>
    </section>
  );
};

const WelcomeOverlays = withRouter(WelcomeOverlaysContainer);

export { WelcomeOverlays };
