import React, { useRef } from "react";
import {
  AddEmojiIcon,
  AddVideoIcon,
  DocIcon,
  DocumentAdd,
  ImageAdd,
  SendComment,
} from "../../icons/icons";
import Picker from "emoji-picker-react";
import "./ChatEditor.scss";
import Avatar from "react-avatar";
import { withRouter } from "react-router-dom";
import CancelIcon from "@mui/icons-material/Cancel";
// import CommentTool from "./CommentTool";
import { connect } from "react-redux";
import { slice } from "lodash";
import { Utility } from "../../../utils";
import { CircularProgress } from "@mui/material";
class ChatEditor extends React.PureComponent {
  constructor(props) {
    super(props);
    this.inputRef = React.createRef();
    this.Click_Container = React.createRef();
    this.state = {
      textareaheight: 1,
      textAreaValue: "",
      showEmojiPicker: false,
      mediaValues: [],
    };
    this.EmojiRef = React.createRef();
  }
  componentDidMount = () => {
    this.getClipboardContents();
    if (this.props.message) {
      this.setState({
        textAreaValue: this.props.message
          .replaceAll("&nbsp;", " ")
          .replaceAll("<br>", "\n")
          .replaceAll("<span>", "")
          .replaceAll("</span>", ""),
      });
    }
  };
  componentDidUpdate = (prevState, prevProps) => {
    if (prevState.showEmojiPicker != this.state.showEmojiPicker) {
      document.addEventListener("mousedown", this.handleClickOutside);
      return () => {
        document.removeEventListener("mousedown", this.handleClickOutside);
      };
    }
  };

  getClipboardContents = async () => {
    try {
      const clipboardItems = await navigator.clipboard.read();
    } catch (err) {
      console.error(err.name, err.message);
    }
  };

  GetPreview = (items, type) => {
    switch (type) {
      case "image/png":
      case "image/jpeg":
      case "image":
        return (
          <div
            onClick={() =>
              window.open(
                typeof items === "string" && items.includes("http")
                  ? items
                  : URL.createObjectURL(items),
                "_blank"
              )
            }
            className="chat-media"
          >
            <div
              onClick={(event) => {
                event.stopPropagation();
                this.removeFile();
              }}
              className="remove-icon"
            >
              <CancelIcon />
            </div>
            <img
              src={
                typeof items === "string" && items.includes("http")
                  ? items
                  : URL.createObjectURL(items)
              }
              alt="cat"
            />
          </div>
        );
      case "video/mp4":
      case "video/x-m4v":
      case "video/*":
      case "video":
        return (
          <div className="chat-media">
            <div
              onClick={(event) => {
                event.stopPropagation();
                this.removeFile();
              }}
              className="remove-icon"
            >
              <CancelIcon />
            </div>
            <video
              onClick={() =>
                window.open(
                  typeof items === "string" && items.includes("http")
                    ? items
                    : URL.createObjectURL(items),
                  "_blank"
                )
              }
              width={100}
              controls={false}
            >
              <source
                src={
                  typeof items === "string" && items.includes("http")
                    ? items
                    : URL.createObjectURL(items)
                }
              />
            </video>
          </div>
        );
      case ".doc":
      case ".docx":
      case "application/pdf":
      case "application/msword":
      case "application/wps-office.docx":
      case "text/plain ":
      case "application/vnd.openxmlformats-officedocument.wordprocessingml.document":
      case "doct":
        return (
          <div
            onClick={() =>
              window.open(
                typeof items === "string" && items.includes("http")
                  ? items
                  : URL.createObjectURL(items),
                "_blank"
              )
            }
            className="chat-media doc doc-icons"
          >
            <div
              onClick={(event) => {
                event.stopPropagation();
                this.removeFile();
              }}
              className="remove-icon"
            >
              <CancelIcon />
            </div>
            <DocIcon />
          </div>
        );
    }
  };

  handleClickOutside = (event) => {
    if (
      this.state.showEmojiPicker &&
      !this.EmojiRef.current.contains(event.target)
    )
      this.setState({ showEmojiPicker: false });
  };

  removeFile = () => {
    this.props.setMedia(null);
  };

  handleAddmedia = (file) => {
    this.props.setMedia(file);
  };

  handleChange = (e, innerHTML, value, isEmoji) => {
    e.target.style.height = "inherit";
    e.target.style.height = `${e.target.scrollHeight}px`;
    this.setState({
      textAreaValue: value,
    });
    value && this.props.isChat && !isEmoji
      ? this.props.messageValue(
          `<span> ${value
            .trim()
            .replaceAll(/(\r\n|\r|\n)/g, "<br>")
            .replaceAll(" ", "&nbsp;")} </span>`
        )
      : this.props.messageValue(value);
  };

  handleAfterCall = () => {};

  handleKeyDown = (e) => {
    if (this.props.isChat) {
      if (!e.shiftKey && e.key === "Enter") {
        this.Click_Container.current.click();
        e.preventDefault();
      }
    }
  };

  render() {
    return (
      <div className="w-100">
        <div className="chat-media-container">
          {this.props.medias &&
            (this.props.uploading ? (
              <div className="p-4 m-1 bg-white">
                <CircularProgress className="text-primary" />
              </div>
            ) : (
              this.GetPreview(
                this.props.medias,
                this.props.typeOfMedia
                  ? this.props.typeOfMedia
                  : this.props.medias.type
              )
            ))}
        </div>
        <div className="chateditor-container">
          <div className="start-render mr-2">
            <Avatar
              src={this.props.userData && this.props.userData.profile_image_url}
              name={this.props.userData && this.props.userData.first_name}
              size={45}
              round={true}
            />
          </div>
          <div className="chateditor-writing">
            <textarea
              value={this.state.textAreaValue}
              ref={this.inputRef}
              id={this.props.editorId}
              className="chateditor-text-area"
              rows={this.state.textareaheight}
              placeholder="Start a conversation..."
              onKeyDown={this.handleKeyDown}
              onChange={(event) => {
                // if (
                //   !this.props.isChat ||
                //   (this.props.isChat && !/\n/.test(event.target.value))
                // ) {
                this.handleChange(
                  event,
                  event.target.innerHTML,
                  event.target.value
                );
                // }
              }}
            />
          </div>
          <div className="Chat-textEditor-tools">
            <div className="Chat-textEditor-tools-iconsContainer">
              <label
                className={`cursor-pointer mt-7px input-icons ${
                  this.props.medias && "disableSvg"
                }`}
                for="image-file-input"
              >
                <ImageAdd />
              </label>
              <input
                onChange={(event) => {
                  if (event.target.files[0]) {
                    this.handleAddmedia(event.target.files[0]);
                  }
                }}
                key={this.props.medias}
                disabled={this.props.medias}
                accept="image/png , image/jpeg"
                className="comment-file-input"
                id="image-file-input"
                type="file"
              />
            </div>
            <div className="Chat-textEditor-tools-iconsContainer">
              <label
                className={`cursor-pointer mt-7px input-icons ${
                  this.props.medias && "disableSvg"
                }`}
                for="document-file-input"
              >
                <DocumentAdd />
              </label>
              <input
                onChange={(event) => {
                  if (event.target.files[0]) {
                    this.handleAddmedia(event.target.files[0]);
                  }
                }}
                key={this.props.medias}
                disabled={this.props.medias}
                accept=".doc,.docx,application/pdf,application/msword,application/wps-office.docx,text/plain ,application/vnd.openxmlformats-officedocument.wordprocessingml.document "
                className="comment-file-input"
                id="document-file-input"
                type="file"
              />
            </div>
            <div className="Chat-textEditor-tools-iconsContainer">
              <label
                className={`cursor-pointer mt-7px input-icons ${
                  this.props.medias && "disableSvg"
                }`}
                for="video-file-input"
              >
                <AddVideoIcon />
              </label>
              <input
                onChange={(event) => {
                  if (event.target.files[0]) {
                    this.handleAddmedia(event.target.files[0]);
                  }
                }}
                key={this.props.medias}
                disabled={this.props.medias}
                accept="video/mp4,video/x-m4v,video/*"
                className="comment-file-input"
                id="video-file-input"
                type="file"
              />
            </div>

            {this.state.showEmojiPicker && (
              <div ref={this.EmojiRef} className="Emoji-picker">
                <Picker
                  onEmojiClick={(event, emojiObject) => {
                    this.handleChange(
                      event,
                      null,
                      this.props.message
                        .replaceAll("&nbsp;", " ")
                        .replaceAll("<br>", "\n")
                        .replaceAll("<span>", "")
                        .replaceAll("</span>", "") + emojiObject.emoji,
                      true
                    );
                  }}
                />
              </div>
            )}
            <div
              onClick={() => this.setState({ showEmojiPicker: true })}
              className={` cursor-pointer input-icons position-static`}
            >
              <AddEmojiIcon />
            </div>
            <div
              ref={this.Click_Container}
              id="submit_message_Container"
              onClick={() => {
                // this.props.messageValue(this.inputRef.current.innerHTML);
                this.setState({
                  textAreaValue: "",
                });
                this.props.onSubmitMessage();
              }}
              className=" cursor-pointer position-static input-icons input-icons-send-comments"
            >
              <SendComment />
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const mapActionToProps = {};

const mapStateToProps = (state) => ({
  userData: state.userData,
});

export default connect(mapStateToProps, mapActionToProps)(ChatEditor);

// export default withRouter(ChatEditor);
