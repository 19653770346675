import React, { useEffect, useState } from "react";
import Picker, { SKIN_TONE_MEDIUM_DARK } from "emoji-picker-react";
import * as Utility from "utils/utility";
import { uploadFile } from "utils/utility";
import { CLIENT_ID } from "config/constants/config";
import {
  CREATE_DISCUSSION_COMMENT,
  SIGNED_URL,
  UPDATE_DISCUSSION_COMMENT,
} from "../../../../../config/constants/api_endpoints";
import { toast } from "react-toastify";
import { useDispatch } from "react-redux";
import { showLoader } from "../../../../actions";
import { AddVideoIcon, CloseIcon } from "../../../../icons/icons";
import {
  SendComment,
  AddVideo,
  ImageAdd,
  DocumentAdd,
} from "shared/icons/icons";
import ChatEditor from "../../../ChatEditor";
import { has } from "lodash";

const CommentTool = (props) => {
  const [media, setMedia] = useState(null);
  const [MessageData, setMessagesData] = useState("");
  const [uploading, setUploading] = useState(false);
  const dispatch = useDispatch();

  const handleSaveComment = (mediaUrl = []) => {
    let params = {};
    if (media) {
      params = {
        platform_id: CLIENT_ID,
        parent_id: props.parent_id,
        description: props.isRegion
          ? MessageData
            ? MessageData
            : " "
          : props.commentData
          ? props.commentData
          : " ",
        image_url:
          media.type === "image/png" || media.type === "image/jpeg"
            ? [mediaUrl]
            : [],
        video_url:
          media.type === "video/mp4" ||
          media.type === "video/x-m4v" ||
          media.type === "video/*"
            ? [mediaUrl]
            : [],
        file_url:
          media.type === ".doc" ||
          media.type === ".txt" ||
          media.type === ".docx" ||
          media.type === "application/msword" ||
          media.type === "application/wps-office.docx" ||
          media.type === "application/pdf" ||
          media.type === "text/plain" ||
          media.type ===
            "application/vnd.openxmlformats-officedocument.wordprocessingml.document"
            ? [mediaUrl]
            : [],
      };
    } else {
      params = {
        platform_id: CLIENT_ID,
        parent_id: props.parent_id,
        description: props.isRegion ? MessageData : props.commentData,
      };
    }

    dispatch(showLoader(true));
    Utility.sendRequest(
      CREATE_DISCUSSION_COMMENT,
      2,
      params,
      async (err, res, body) => {
        body = JSON.parse(body);
        if (body.error) {
          toast.error(body.error.message, { containerId: "private" });
          dispatch(showLoader(false));
        } else {
          props.getCommentData();
          props.handleReply && props.handleReply();
          setMedia("");
          !props.isRegion && props.changeDescription("", "comment");
          toast.info(body.message, { containerId: "private" });
          if (has(props, "handleStartDiscuss")) {
            props.handleStartDiscuss();
          }
          dispatch(showLoader(false));
        }
      }
    );
  };
  const handleSubmitComment = async () => {
    if (media) {
      dispatch(showLoader(true));
      setUploading(true);
      Utility.sendRequest(SIGNED_URL, 1, {}, async (err, res, body) => {
        body = JSON.parse(body);
        if (body.error) {
          toast.error(body.error.message, { containerId: "private" });
          dispatch(showLoader(false));
        } else {
          const Uploadrespose = await uploadFile(media, body.imageSignedUrl);
          if (Uploadrespose) {
            handleSaveComment(Uploadrespose);
            setUploading(false);
          }

          dispatch(showLoader(false));
        }
      });
    } else {
      handleSaveComment();
    }
  };

  const handleEditComment = () => {
    const params = {
      id: props.Data.id,
      description: props.isRegion ? MessageData : props.commentData,
    };
    dispatch(showLoader(true));
    Utility.sendRequest(
      UPDATE_DISCUSSION_COMMENT,
      3,
      params,
      (err, res, body) => {
        body = JSON.parse(body);
        if (body.error) {
          toast.error(body.error.message, { containerId: "private" });
          dispatch(showLoader(false));
        } else {
          props.getCommentData();
          if (has(props, "handleStartDiscuss")) {
            props.handleStartDiscuss();
          }
          props.handleEdit();
          toast.info(body.message, { containerId: "private" });
          dispatch(showLoader(false));
        }
      }
    );
  };

  const handlleClickSend = () => {
    if (props.purpose === "Reply") {
      handleSubmitComment();
    } else if (props.purpose === "Edit") {
      handleEditComment();
    } else {
      handleSubmitComment();
    }

    if (props.isDiscussion) {
      if (props.isNewComment) {
        props.setScrollComments(true);
      }
    }
  };

  const handleFileUpload = (event) => {
    setMedia(event.target.files[0]);
  };

  useEffect(() => {
    if (props.purpose === "Edit") {
      setMessagesData(props.commentData);
    }
  }, [props.commentData]);

  return (
    <>
      <div
        className={`CommentTool-wrapper ${
          props.isCampaign && props.isRegion
            ? "commentToolPosition"
            : props.isRegion && "bg-white"
        } ${
          props.isCampaign &&
          props.isRegion &&
          (props.purpose === "Edit" || props.purpose === "Reply") &&
          "pb-0"
        }`}
      >
        {!props.isRegion ? (
          <div>
            {media && (
              <div className="d-flex media-container">
                {media.name}
                <div
                  onClick={() => setMedia(null)}
                  className="close-media-icon ml-auto mr-1"
                >
                  <CloseIcon />
                </div>
              </div>
            )}
            <div className="d-flex">
              <div>
                <label className="cursor-pointer ml-3" for="video-file-input">
                  {/* <img
                className="ml-3"
                src={require("assets/images/city_specific_images/video_add.svg")}
                alt="#"
              /> */}
                  <AddVideo />
                </label>
                <input
                  onChange={handleFileUpload}
                  accept="video/mp4,video/x-m4v,video/*"
                  className="comment-file-input"
                  id="video-file-input"
                  type="file"
                />
              </div>
              <div>
                <label className="cursor-pointer ml-3" for="image-file-input">
                  {/* <img
                className="ml-3"
                src={require("assets/images/city_specific_images/image_add.svg")}
                alt="#"
              /> */}
                  <ImageAdd />
                </label>
                <input
                  onChange={handleFileUpload}
                  accept="image/png , image/jpeg"
                  className="comment-file-input"
                  id="image-file-input"
                  type="file"
                />
              </div>
              <div>
                <label
                  className="cursor-pointer ml-3"
                  for="document-file-input"
                >
                  <DocumentAdd />
                </label>
                <input
                  accept=".doc,.docx,application/pdf,application/msword,application/wps-office.docx,text/plain ,application/vnd.openxmlformats-officedocument.wordprocessingml.document "
                  className="comment-file-input"
                  id="document-file-input"
                  type="file"
                  onChange={handleFileUpload}
                />
              </div>

              {/* <div>
            <label className="cursor-pointer" for="file-input">
              <img
                className="ml-3"
                src={require("assets/images/city_specific_images/discussion_add.svg")}
                alt="#"
              />
            </label>
            <input
              accept="image/png , image/jpeg"
              className="comment-file-input"
              id="file-input"
              type="file"
            />
          </div>
          <div>
            <label className="cursor-pointer">
              <img
                onClick={() => setSelectEmoji(!selectEmoji)}
                className="ml-3"
                src={require("assets/images/city_specific_images/emojis_add.svg")}
                alt="#"
              />
            </label>
            {selectEmoji && (
              <Picker
                onEmojiClick={onEmojiClick}
                disableAutoFocus={true}
                skinTone={SKIN_TONE_MEDIUM_DARK}
                groupNames={{ smileys_people: "PEOPLE" }}
                native
              />
            )}
          </div> */}

              <div
                className="ml-auto cursor-pointer send-comments"
                onClick={() => handlleClickSend()}
              >
                {/* <img
              src={require("assets/images/city_specific_images/send_comment.svg")}
              alt="#"
            /> */}
                <SendComment />
              </div>
              {/*<div>
        <img
          className="ml-4"
          src={require("assets/images/city_specific_images/documents_add.svg")}
          alt="#"
        />
      </div>
      <div>
        <img
          className="ml-4"
          src={require("assets/images/city_specific_images/discussion_add.svg")}
          alt="#"
        />
      </div>
      <div>
        <img
          className="ml-4"
          src={require("assets/images/city_specific_images/emojis_add.svg")}
          alt="#"
        />
      </div> */}
            </div>
          </div>
        ) : (
          <div className="w-100">
            <ChatEditor
              editorId={`editorForMessaging`}
              onSubmitMessage={() => {
                handlleClickSend();
                setMessagesData("");
              }}
              userData={props.userData}
              message={MessageData}
              medias={media}
              setMedia={setMedia}
              uploading={uploading}
              messageValue={setMessagesData}
            />
          </div>
        )}
      </div>
    </>
  );
};

export default CommentTool;
