import React, { Component } from "react";
import { Row } from "react-bootstrap";
import { Utility, String } from "utils";
import {
  CAMPAIGNS_COUNT,
  CAMPAIGNS,
  MODULE_TYPES,
  CAMPAIGN_STORIES,
  CATEGORIES,
  UPDATE_CAMPAIGNS_RANKING,
} from "config/constants/api_endpoints";
import { MuiPickersUtilsProvider } from "@material-ui/pickers";
import {
  showLoader,
  updateSelectedMenuItem,
  updateCampaignSelectedTab,
  updateSelectedGroup,
} from "shared/actions";
import { connect } from "react-redux";
import { toast } from "react-toastify";
import Menu from "shared/components/menu";
import FormContainer from "./components/form_container";
import ItemDetail from "shared/components/templates/item_detail/item_detail";
import TableList from "shared/components/templates/table_list";
import MomentUtils from "@date-io/moment";
import { CLIENT_ID, CLIENT_NAME } from "config/constants/config";
import {
  CAMPAIGN_TYPES_IDS,
  CRM_MODULE_IDS,
  SM_CLIENT_ID,
  SEARCH_MINIMUM_LENGTH,
  GIL_CLIENT_ID,
} from "config/constants";
import { WelcomeOverlays } from "shared/components/welcome_overlays";
import { CampaignCategory } from "./components/campaign_category";
import { MenuSummary } from "shared/components/templates/menu_summary";
import CourseContainer from "./courses";
import { ROLE_IDS } from "../../config/constants";
import { Pagination } from "@mui/material";
import th from "date-fns/locale/th/index";

class CampaignContainer extends Component {
  _isMounted = false;

  constructor(props) {
    super(props);
    props.showLoader(Utility.isEmpty(this.props.userData));
    this.getCampaignsTypes();

    this.state = {
      selectedActionItem: {},
      menus: this.getMenu(),
      campId: null,
      campaignTypes: [],
      selectedMenuItemName: null,
      feeds: {},
      showFilterComponent: true,
      loader: {},
      isEdit: false,
      createForm: false,
      campaignCategoryList: [],
      selectedMenuItemId: null,
      createEditCourse: false,
      openCatgoryForm: false,
      currentPage: 1,
      totalPage: 1,
    };

    this.props.updateSelectedMenuItem({});
  }

  componentDidMount = () => {
    this._isMounted = true;
    if (!Utility.isEmpty(this.props.userData)) {
      this.getMenuCounts();
      // this.filterMenus();
    }
  };

  componentDidUpdate = (prevProps) => {
    Utility.showComponent(true, "menu-list");
    if (
      Utility.isEmpty(prevProps.userData) &&
      !Utility.isEmpty(this.props.userData)
    ) {
      this.getMenuCounts();
      this.setState({ menus: this.getMenu() });
    }
  };

  getMenu = () => {
    this.getCampaignsTypes();
  };

  componentWillUnmount = () => (this._isMounted = false);

  getCampaignsTypes = () => {
    const { platform_id } = this.props.userData;
    const params = {
      module_id: CRM_MODULE_IDS.CAMPAIGN,
      order: "created_at",
      client_id: CLIENT_ID,
    };

    this.props.showLoader(true);

    Utility.sendRequest(MODULE_TYPES, 1, params, (err, res, body) => {
      body = JSON.parse(body);

      if (body.error) {
        toast.error(body.error.message, { containerId: "private" });
      } else {
        let moduleData;
        moduleData = body.data;

        const menus = moduleData.filter((item) => {
          return item.id !== "";
          this.props.showLoader(false);
        });
        moduleData.unshift({ id: "", name: "Create" });
        const dropDownData = moduleData;
        const index = dropDownData.findIndex((x) => x.name === "courses");
        if (index > 0) {
          dropDownData[index].name = "course";
        }
        this.setState(
          {
            menus: menus,
            campaignTypes: dropDownData,
            selectedActionItem: menus[0],
          },
          () => {
            if (!this.state.selectedMenuItemId) {
              const SelectedTab = localStorage.getItem("SelectedTab");

              let SelectedMenuData;
              if (SelectedTab) {
                SelectedMenuData = menus.find(
                  (data) => data.name === SelectedTab
                );
              }
              if (SelectedMenuData && SelectedTab) {
                this.setState(
                  {
                    selectedMenuItemName: SelectedMenuData.name,
                    selectedMenuItemId: SelectedMenuData.id,
                  },
                  () => {
                    this.props.updateSelectedMenuItem(SelectedMenuData);
                    this.loadStories(SelectedMenuData.id);
                    this.props.showLoader(false);
                  }
                );
                localStorage.removeItem("SelectedTab");
              } else {
                this.setState(
                  {
                    selectedMenuItemName: menus[0].name,
                    selectedMenuItemId: menus[0].id,
                  },
                  () => {
                    this.props.updateSelectedMenuItem(menus[0]);
                    this.loadStories(menus[0].id);
                    this.props.showLoader(false);
                  }
                );
              }
            }
          }
        );
      }
    });
  };

  filterMenus = () => {
    let menus = this.state.menus;

    if (!this.props.userData.Client.is_global_media_partner) {
      menus = menus.filter((item) => {
        return item.id !== 3;
      });
      this.setState({ menus });
    }
  };

  closePreviousForm = () => {
    const elem = document.getElementsByClassName("btn-close btn btn-link");
    if (elem && elem.length > 0) elem[0].click();
  };

  handleChange = async (actionItem) => {
    await this.props.updateCampaignSelectedTab(actionItem.id);
    if (actionItem.name === "course") {
      this.closePreviousForm();
      this.setState({
        createEditCourse: true,
        selectedActionItem: actionItem,
        openCatgoryForm: false,
        createForm: false,
      });
    } else {
      this.setState(
        { selectedActionItem: actionItem, campId: null, createForm: true },
        () => {
          Utility.showComponent(
            true,
            `create-${actionItem.name.toLowerCase()}`
          );
        }
      );
    }
  };

  updateMenus = (menuId, count) => {
    const menus = this.state.menus;
    if (menus && menus.length > 0) {
      menus.forEach((menu) => {
        if (menu.id === menuId) menu.count = count;
      });
    }
    if (this._isMounted) this.setState({ menus });
  };

  getMenuCounts = (campaignTypeId = CAMPAIGN_TYPES_IDS.story) => {
    this.unApprovedCampaignsCount(campaignTypeId);
  };

  myCampaignsCount = (campaignTypeId = CAMPAIGN_TYPES_IDS.story) => {
    const params = {
      campaign_type_id: campaignTypeId,
      client_id: CLIENT_ID,
      user_client_id: this.props.userData.id,
    };

    Utility.sendRequest(CAMPAIGNS_COUNT, 1, params, (err, res, body) => {
      body = JSON.parse(body);

      if (body.error) {
        toast.error(body.error.message, { containerId: "private" });
      } else {
        this.updateMenus(4, body.count);
      }
    });
  };

  unApprovedCampaignsCount = (campaignTypeId) => {
    const params = {
      campaign_type_id: campaignTypeId,
      is_approved: false,
      client_id: CLIENT_ID,
    };

    Utility.sendRequest(CAMPAIGNS_COUNT, 1, params, (err, res, body) => {
      body = JSON.parse(body);

      if (body.error) {
        toast.error(body.error.message, { containerId: "private" });
      } else {
        this.updateMenus(3, body.count);
      }
    });
  };

  handleMenuItemClick = async (selectedMenuItem) => {
    const { selectedMenuItemId } = this.state;
    this.setState({ currentPage: 1 });
    if (selectedMenuItemId !== selectedMenuItem.id) {
      await this.props.updateCampaignSelectedTab(selectedMenuItem.id);
      this.props.updateSelectedMenuItem(selectedMenuItem);
      this.props.updateSelectedGroup(selectedMenuItem);
      this.setState(
        {
          selectedActionItem: {},
          selectedMenuItemName: selectedMenuItem.name,
          createEditCourse: false,
          createForm: false,
          categorySection: false,
          selectedMenuItemId: selectedMenuItem.id,
        },
        () => {
          this.loadStories(selectedMenuItem.id);
        }
      );
    } else {
      if (selectedMenuItemId === CAMPAIGN_TYPES_IDS.courses) {
        this.setState({ createEditCourse: false });
      }
    }
  };

  categoryClick = async (selectedCategoryItem) => {
    const { selectedMenuItemId } = this.state;
    this.loadStories(selectedMenuItemId, selectedCategoryItem.id);
  };

  loadStories = (campTypeId, categoryId = "", search = "") => {
    // this.props.showLoader(true);
    this.state.loader[campTypeId] = true;
    this.setState(
      {
        campaignCategoryList: [],
      },
      () => {
        const params = {
          publisher_network_id: CLIENT_ID,
          type: campTypeId,
          user_client_id: this.props.userData.id,
          user_id: this.props.userData.user_id,
          limit: search ? 1000 : 10,
          page_number: this.state.currentPage || 1,
        };
        if (categoryId) {
          params["category_id"] = categoryId;
        }
        if (search) {
          params["search"] = search;
        }
        if (
          [ROLE_IDS.CLIENT_ADMIN, ROLE_IDS.CLIENT_OWNER].includes(
            this.props.userData.role_id
          )
        ) {
          params["corporate_id"] = this.props.userData.client_id;
        }
        this.getCategoryList();
        Utility.sendRequest(CAMPAIGN_STORIES, 1, params, (err, res, body) => {
          body = JSON.parse(body);
          this.state.loader[campTypeId] = false;

          if (body.error) {
            toast.error(body.error.message, { containerId: "private" });
            // this.props.showLoader(false);
          } else {
            const dataVal = body.data.res.filter(
              (items) =>
                items.corporate_is_active === true ||
                items.corporate_is_active === null
            );
            this.state.feeds[campTypeId] = Utility.deepClone(dataVal);
            this.setState({ totalPage: body.data.total_pages });

            // this.setState(
            //   {
            //     // feeds: { campTypeId: Utility.deepClone(dataVal) },
            //     isLoading: false,
            //   },
            //   () => {
            //     // this.props.showLoader(false);
            //   }
            // );
          }
        });
      }
    );
  };

  closeSummary = () => {
    this.props.updateSelectedMenuItem({});
    this.setState({
      selectedActionItem: {},
      isEdit: false,
      campId: "",
      createEditCourse: false,
    });
  };

  editStory = (campId, campTypeId) => {
    const selectedActionItem = this.state.campaignTypes.find(
      (item) => item.id === campTypeId
    );
    if (selectedActionItem.name === "course") {
      this.props.updateCampaignSelectedTab(selectedActionItem.id);
      this.setState({
        selectedActionItem: selectedActionItem,
        campId: campId,
        createEditCourse: true,
      });
    } else {
      // console.log(selectedActionItem)
      this.setState({
        selectedActionItem: selectedActionItem,
        campId: campId,
        isEdit: true,
        createForm: true,
      });
    }
  };

  editRanking = (id, ranking) => {
    const params = {
      campaign_id: id,
      ranking: ranking,
    };
    this.props.showLoader(true);
    Utility.sendRequest(
      UPDATE_CAMPAIGNS_RANKING,
      3,
      params,
      (error, response, body) => {
        body = JSON.parse(body);
        this.props.showLoader(false);
        if (!body.error) {
          this.loadStories(this.props.campaignSelectedTab);
        } else {
          toast.error(body.error.message, { containerId: "public" });
        }
      }
    );
  };

  closeForm = () => {
    this.setState({
      isEdit: false,
      campId: "",
      createEditCourse: false,
    });
  };

  backToPreviousMenu = (previousMenuItem) => {
    this.props.updateSelectedMenuItem(previousMenuItem);
    this.setState({ selectedActionItem: {} });
  };

  getTableTitlesForCampaigns = () => {
    if (
      this.props.userData.role_id === ROLE_IDS.CLIENT_ADMIN ||
      this.props.userData.role_id === ROLE_IDS.CLIENT_OWNER ||
      this.props.userData.role_id === ROLE_IDS.USER
    ) {
      if (
        this.state.selectedMenuItemId ===
          "93ab8d70-3d6b-4f3f-a702-8ead10a90bcf" &&
        this.state.selectedMenuItemName === "event" &&
        CLIENT_ID === "0bc8dc7c-b5a3-4344-b313-819fc640f6b5"
      )
        return ["Name", "Action"];
      else if (
        this.state.selectedMenuItemId ===
          "6f8b1d1e-c537-4580-835b-8b6b58bfd4ec" &&
        CLIENT_ID === "0bc8dc7c-b5a3-4344-b313-819fc640f6b5" &&
        this.state.selectedMenuItemName === "Story"
      )
        return ["Name", "Action"];
      else if (
        this.state.selectedMenuItemId ===
          "39ab8d70-3d6b-4f3f-a702-8ead10a90bcf" &&
        CLIENT_ID === "0bc8dc7c-b5a3-4344-b313-819fc640f6b5" &&
        this.state.selectedMenuItemName === "course"
      )
        return ["Name", "Action"];
      else return ["Name", "Category"];
    }
    if (
      this.props.userData.role_id === ROLE_IDS.PARENT_ADMIN ||
      this.props.userData.role_id === ROLE_IDS.PARENT_OWNER ||
      this.props.userData.role_id === ROLE_IDS.SUPER_OWNER
    ) {
      if (
        this.state.selectedMenuItemId ===
          "93ab8d70-3d6b-4f3f-a702-8ead10a90bcf" &&
        this.state.selectedMenuItemName === "event" &&
        CLIENT_ID === "0bc8dc7c-b5a3-4344-b313-819fc640f6b5"
      )
        return ["Name", "Ranking", "Action"];
      else if (
        this.state.selectedMenuItemId ===
          "6f8b1d1e-c537-4580-835b-8b6b58bfd4ec" &&
        CLIENT_ID === "0bc8dc7c-b5a3-4344-b313-819fc640f6b5" &&
        this.state.selectedMenuItemName === "Story"
      )
        return ["Name", "Ranking", "Action"];
      else if (
        this.state.selectedMenuItemId ===
          "39ab8d70-3d6b-4f3f-a702-8ead10a90bcf" &&
        CLIENT_ID === "0bc8dc7c-b5a3-4344-b313-819fc640f6b5" &&
        this.state.selectedMenuItemName === "course"
      )
        return ["Name", "Ranking", "Action"];
      else return ["Name"];
    }
  };

  filterList = (event) => {
    if (event.target.value.length === 1)
      this.setState({ isSearchEmpty: false });

    if (
      event.target.value.length >= SEARCH_MINIMUM_LENGTH ||
      (event.target.value.length === 0 && !this.state.isSearchEmpty)
    ) {
      this.loadStories(
        this.state.selectedMenuItemId,
        "",
        event.target.value.trim()
      );

      if (event.target.value.length === 0) {
        this.setState({ isSearchEmpty: true });
        this.loadStories(this.state.selectedMenuItemId, "", "");
      }
    }
  };

  openCategory = () => {
    this.setState(
      (state) => {
        return {
          categorySection: !state.categorySection,
          createEditCourse: false,
        };
      },
      () => {
        this.getCategoryList();
      }
    );
  };

  getCategoryList = () => {
    const params = {
      client_id: CLIENT_ID,
      campaign_type_id: this.state.selectedMenuItemId,
      is_active: true,
    };

    Utility.sendRequest(CATEGORIES, 1, params, (err, res, body) => {
      body = JSON.parse(body);

      if (body.error) {
        toast.error(body.error.message, { containerId: "private" });
      } else {
        this.setState({ campaignCategoryList: body.data });
      }
    });
  };

  catgoryForm = () => {
    this.setState(
      (state) => {
        return {
          openCatgoryForm: !state.openCatgoryForm,
          createEditCourse: false,
        };
      },
      () => {
        Utility.showComponent(true, "menu-summary");
      }
    );
  };

  removeCampaign = (id) => {
    Utility.sendRequest(CAMPAIGNS + "/" + id, 4, {}, (err, res, body) => {
      body = JSON.parse(body);
      if (body.error) {
        toast.error(body.error.message, { containerId: "private" });
      } else {
        this.loadStories(this.props.campaignSelectedTab);
      }
    });
  };

  handlePagintion = (event, value) => {
    if (this.state.currentPage !== value) {
      this.setState({
        currentPage: value,
      });
      this.loadStories(this.state.selectedMenuItemId);
    }
  };

  render() {
    const a = { id: 3, name: "Campaigns", image_url: "campaigns.png" };
    const { is_super_owner, platform_id } = this.props.userData;
    const selectedActionItem = { ...this.state.selectedActionItem };
    const {
      campId,
      isEdit,
      createForm,
      createEditCourse,
      selectedMenuItemName,
    } = this.state;
    if (!Utility.isEmpty(selectedActionItem)) {
      selectedActionItem.name = selectedActionItem.name.toLowerCase();
    }
    if (is_super_owner || platform_id !== SM_CLIENT_ID) {
      return (
        <>
          <Row className="no-gutters h-100">
            {!Utility.isEmpty(this.props.userData) &&
              !Utility.isEmpty(this.state.menus) && (
                <>
                  <Menu
                    selectedModule={a}
                    clientName={CLIENT_NAME}
                    userName={`${this.props.userData.first_name} ${this.props.userData.last_name}`}
                    dropdownItems={this.state.campaignTypes}
                    handleMenuItemClick={this.handleMenuItemClick}
                    handleDropdownAction={this.handleChange}
                    selectedMenuItemId={
                      this.state.selectedMenuItemId
                        ? this.state.selectedMenuItemId
                        : Utility.isEmpty(selectedActionItem)
                        ? 1
                        : null
                    }
                    menus={this.state.menus}
                    isMenuAction={true}
                    showZeroInCount={false}
                    showCount={false}
                    isShowButton={true}
                    categoryButton={true}
                    showCategoryList={true}
                    openCategory={this.openCategory}
                    campaignCategoryList={this.state.campaignCategoryList}
                    categorySection={this.state.categorySection}
                    catgoryForm={this.catgoryForm}
                    categoryClick={this.categoryClick}
                  />
                </>
              )}

            {!Utility.isEmpty(selectedActionItem) &&
              createForm &&
              !createEditCourse && (
                <MuiPickersUtilsProvider utils={MomentUtils}>
                  <FormContainer
                    showLoader={this.props.showLoader}
                    closeSummary={this.closeSummary}
                    unApprovedCampaignsCount={this.unApprovedCampaignsCount.bind(
                      this
                    )}
                    loadStories={this.loadStories}
                    backToPreviousMenu={this.backToPreviousMenu}
                    myCampaignsCount={this.myCampaignsCount}
                    formType={selectedActionItem}
                    campId={this.state.campId}
                    sdGoals={this.props.sdGoals}
                    handleMenuItemClick={this.handleMenuItemClick}
                    menus={this.state.menus}
                  />
                </MuiPickersUtilsProvider>
              )}

            {this.state.menus &&
              !createEditCourse &&
              !this.state.openCatgoryForm && (
                <ItemDetail
                  showFilterComponent={this.state.showFilterComponent}
                  filterList={this.filterList}
                  headerTitle={String.capitalizeFirstLetter(
                    selectedMenuItemName
                  )}
                >
                  <TableList
                    data={this.state.feeds[this.state.selectedMenuItemId]}
                    loader={this.state.loader[this.state.selectedMenuItemId]}
                    openChat={this.openChat}
                    onToggleClick={this.onToggleClick}
                    editStory={this.editStory}
                    editRanking={this.editRanking}
                    setAdminAfterToggle={this.state.changeAdminValue}
                    showLoader={this.state.showLoader}
                    tableTitles={this.getTableTitlesForCampaigns()}
                    userData={this.props.userData}
                    selectedMenuItemName={selectedMenuItemName}
                    selectedActionItem={selectedActionItem}
                    selectedMenuItemId={this.state.selectedMenuItemId}
                    history={this.props.history}
                    removeCampaign={this.removeCampaign}
                  />
                  {this.state.totalPage > 0 && (
                    <div
                      className={`d-flex align-items-center justify-content-center`}
                    >
                      <Pagination
                        count={this.state.totalPage}
                        page={this.state.currentPage}
                        onChange={this.handlePagintion}
                      />
                    </div>
                  )}
                </ItemDetail>
              )}
            {!this.state.openCatgoryForm && createEditCourse && (
              <CourseContainer
                openCategory={this.openCategory}
                selectedMenuItemId="39ab8d70-3d6b-4f3f-a702-8ead10a90bcf"
                handleMenuItemClick={this.handleMenuItemClick}
                menus={this.state.menus}
                editCampaignId={campId}
                setIsFormOpen={() => this.closeForm()}
                loadStories={this.loadStories}
              />
            )}
            {this.state.openCatgoryForm && !createEditCourse && (
              <MenuSummary>
                <CampaignCategory
                  selectedGroup={this.props.selectedGroup}
                  selectedActionItem={this.props.selectedActionItem}
                  selectedCampaignType={this.props.selectedCampaignType}
                  userData={this.props.userData}
                  closeSummary={this.catgoryForm}
                  getCategoryList={this.getCategoryList}
                  categoryId={this.state.categoryId}
                  loadStories={this.loadStories}
                  columnValue={9}
                  campaignTypeId={this.state.selectedMenuItemId}
                />
              </MenuSummary>
            )}
          </Row>
        </>
      );
    } else {
      return (
        <WelcomeOverlays
          heading="Welcome to Campaigns!"
          subHeading="Create manage and distribute various types of campaigns"
          mainText="Ask for a customized DEMO to learn how all the available features of this module can be used to serve the requirements of your digital application."
          btnText="See example!"
        />
      );
    }
  }
}

const mapActionToProps = {
  showLoader,
  updateSelectedMenuItem,
  updateCampaignSelectedTab,
  updateSelectedGroup,
};

const mapStateToProps = (state) => ({
  userData: state.userData,
  sdGoals: state.sdGoals,
  selectedMenuItem: state.selectedMenuItem,
  campaignSelectedTab: state.campaignSelectedTab,
  selectedGroup: state.selectedGroup,
});

export default connect(mapStateToProps, mapActionToProps)(CampaignContainer);
