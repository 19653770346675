import { connect, useSelector } from "react-redux";
import { useParams, withRouter } from "react-router-dom";
import AddIcon from "../../../assets/images/icons/add_icon_black.svg";
import { updateSeeAll } from "shared/actions";
import NewsCard from "./NewsCard";
import { CustomSlider } from "../../../shared/components/Slider";
import React, { useState } from "react";
import { Skeleton } from "@mui/material";

const ProfileNews = ({ profileNews, ...props }) => {
  const accounts = useSelector((state) => state.accountList);
  const [joinedNeighborhood, setJoinedNeighborhood] = useState([]);
  const [showAll, setShowAll] = useState(false);
  const params = useParams();
  const handleArticleClick = (data) => {
    props.history.push({
      pathname: `/news-details/${data.parent_id || data.id}`,
      calledFrom: props.type,
      goBackLink: goBackLink(),
    });
  };

  const goBackLink = () => {
    if (props.type === "suggestedReads") {
      return "/home";
    } else if (props.type === "myArticles") return "/profile";
  };

  return (
    <div className="MainContainerScrollItem pr-0 ff-Inter-Bold overflow-hidden">
      <div className="PanalHeader pr-3 d-flex align-items-center justify-content-between">
        <p className="section-head ff-Inter-Bold d-flex align-items-center pl-5">
          {params && params.id ? "News" : "My News"}
        </p>
        {accounts && accounts.Clients && accounts.Clients.length > 0 && (
          <img
            className="addIconStyling cursor-pointer"
            src={AddIcon}
            alt="Addicon"
            onClick={() => props.history.push("/create-article")}
          />
        )}
      </div>

      <div className="newsContainer pr-0 ff-Inter-Bold scroll-y">
        {props.loading ? (
          // <div className="d-flex">
          [1, 2, 3, 4].map((item) => (
            <Skeleton
              variant="rounded"
              width={260}
              height={270}
              key={item}
              className="mr-3 rounded-20 "
            />
          ))
        ) : // </div>
        profileNews &&
          profileNews.filter((item) =>
            props.isNeighbourhood ? item : item.liked_by_current_user
          ).length > 0 ? (
          [...profileNews]
            .filter((item) =>
              props.isNeighbourhood ? item : item.liked_by_current_user
            )
            .slice(0, showAll ? profileNews.length + 1 : 4)
            .map((data, index) => {
              return (
                <NewsCard
                  key={data.id}
                  data={data}
                  Totalcourses={profileNews}
                  index={index}
                  joinedNeighborhood={joinedNeighborhood}
                  handleArticleClick={handleArticleClick}
                  isVertical={true}
                />
              );
              // <CourseCard />
            })
        ) : (
          <div className="d-flex align-items-center justify-content-center">
            <div className="px-5">
              <span className="font-bold display-6">No News found</span>
            </div>
          </div>
        )}
      </div>
      {profileNews &&
        profileNews.filter((item) =>
          props.isNeighbourhood ? item : item.liked_by_current_user
        ).length > 4 && (
          <>
            {!showAll && (
              <h6
                className="text-primary text-center mt-3 cursor-pointer font-14"
                onClick={() => setShowAll(true)}
              >
                SEE MORE
              </h6>
            )}
            {showAll && (
              <h6
                className="text-primary text-center mt-3 cursor-pointer font-14"
                onClick={() => setShowAll(false)}
              >
                SEE LESS
              </h6>
            )}{" "}
          </>
        )}

      {/* </div> */}
    </div>
  );
};

const mapActionToProps = {
  updateSeeAll,
};

const mapStateToProps = (state) => ({
  userData: state.userData,
});

export default withRouter(
  connect(mapStateToProps, mapActionToProps)(ProfileNews)
);
