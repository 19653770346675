import React, { Component } from 'react';
import { Row, Col, Form } from 'react-bootstrap';
import { connect } from 'react-redux';
import { InputField } from 'shared/components/form_elements.js';
import { Utility } from 'utils';
import { USER_AUTH } from "config/constants/api_endpoints";
import { CREATE_PASSWORD } from 'config/constants/api_endpoints.js';
import { PUBLISHER_NETWORK_ID } from 'config/constants';
import { updateUserData } from 'shared/actions';
import { toast } from 'react-toastify';
import cookie from 'react-cookies';
import PasswordValidationFrame from 'shared/components/password_validation_frame';

const validateFields = ['password'];

 class CreatePassword extends Component {
  constructor(props) {
    super(props);

    // const searchHash = new URLSearchParams(this.props.location.search);
    this.state = {
      formData: {
        password: '',
        // secure_token: searchHash.get('auth_token'),
        // user_id: searchHash.get('u')
      },
      confirm_password: '',
    };
  }

  authUser = (body) => {
    cookie.save('sessionToken', body.token, { path: '/' });
    Utility.sendRequest(USER_AUTH, 1, body.token, (error, response, body) => {
      body = JSON.parse(body);
      if (!body.error) {
        this.props.updateUserData(body);
        // this.props.history.push('/profile');
      } else {
        toast.error(body.error.message, { containerId: 'public' });
      }
    });
  }

  handleChangePassword = (e) => {
   let formData = this.state.passwordData;
   let password = {}, showPasswordValidation = false;
   formData = Utility.validateAndReturnFormData(this, formData, e, validateFields);
   if (!Utility.isEmpty(formData.password)) {
     password = Utility.validatePassword(formData.password);
     showPasswordValidation = true;
   }

   this.setState({ formData, showPasswordValidation, password });
  }

  handleChangeCPassword = (e) => {
    // if (!Utility.isEmpty(e.target.value)) {
    //   password = Utility.validatePassword(formData.password);
    // }
   this.setState({ confirm_password:e.target.value });
  }

  verifyPassword = () => {
   return this.state.passwordData.password === this.state.confirm_password;
  }

  updatePassword = (e) => {
   e.preventDefault();
    if (!Utility.showFormErrors(this, validateFields)) {
      return;
    }
    // TODO: check password Regex
    if (this.verifyPassword()) {
      const formData = this.state.passwordData;
      formData.email = this.state.emailData.email;
      formData.client_id = PUBLISHER_NETWORK_ID.toString();
      // delete formData.confirm_password;

      Utility.sendRequest(CREATE_PASSWORD, 2, formData, (error, response, body) => {
        body = JSON.parse(body);
        if (body.error) {
          toast.error(body.error.message, { containerId: 'public' });
        } else {
          toast.success(body.message, { containerId: 'public' });
          this.authUser(body);
        }
      });
    } else {
      toast.error('Password and Confirm Password does not match', { containerId: 'public' });
   }
  }

  render() {
    return (
      <React.Fragment>
      <div className="container">
        <div className="register-outer p-sm-5 p-4 text-center mx-auto my-sm-5 my-3" >
                <img src={require("assets/images/logos/main-logo.png")} className="register-logo mb-4" alt="sharemeister" />
                <h3 className="mb-5 font-bold" >Update your Password!</h3>
                <div className="new-landing-theme register-form" >
                <div className="account-info-outer align-items-center form-element-outer mx-auto mb-4" >
                  <div className="account-info-pic mr-3" >
                      <img src={require("assets/images/users/user_no_image.png")} className="rounded-circle" alt="profile image" />
                  </div>
                  <div className="text-left" >
                    <h5 className="font-bold" >Aaron Berger</h5>
                    <p>Citizen</p>
                  </div>
                </div>
                <Form.Group>
                  <InputField required={true}
               type="password"
               placeholder="Password"
               name="password"
               ref="password"
               handleChange={this.handleChangePassword}
               />
               {
                 this.props.showPasswordValidation &&
                 <PasswordValidationFrame password={ Utility.validatePassword(this.state.password)}/>
               }
                </Form.Group>
                <Form.Group>
                  <InputField required={true}
                    type="password" placeholder="Confirm Password" name="confirm_password"
                    handleChange={this.handleChangeCPassword}
                    />
                </Form.Group>
                </div>
                <div className="text-left form-element-outer mx-auto mt-5" >
                    <button className="btn btn-primary btn-login" onClick={(e) => this.updatePassword(e)}>Update</button>
                </div>
                <div className="d-inline-block mx-auto text-primary font-bold display-7 cursor-pointer pt-5"onCick={() => this.props.history.push('/users/sign_in')} >Create a New Account</div>
          </div>
      </div>
      </React.Fragment>
    );
  }
}

const mapActionToProps =  { updateUserData };
const mapStateToProps = (state) => ({
  userData: state.userData
});
export default connect(mapStateToProps, mapActionToProps)(CreatePassword);
