import React from "react";
import { Tabs } from "@mui/material";

export const CustomSlider = (props) => {
  return (
    <Tabs
      id="custom-tab"
      variant="scrollable"
      scrollButtons={true}
      classes={{
        flexContainer: "pb-3",
        //   document.getElementById("custom-tab") &&
        //   (document.getElementById("custom-tab").childElementCount === 2
        //     ? "pl-5"
        //     : "pl-0"),

        scrollButtons: "button-class-slider",
      }}
      aria-label="visible arrows tabs example"
    >
      {props.children}
    </Tabs>
  );
};
