import React, { Component } from 'react';
import { toast } from 'react-toastify';
import { Utility } from 'utils';
import { CLIENT_PAYMENT_DETAILS, USER_AUTH } from 'config/constants/api_endpoints';
import { Form, Button, Spinner } from 'react-bootstrap';
import StripeCardElement from 'shared/components/v2/campaign/components/stripe_card_elements.js';
import { connect } from "react-redux";
import { updateUserData } from 'shared/actions';

class CreditCardUpdate extends Component {
  constructor(props) {
    super(props);

    this.state = {
      accountData: {
        name_on_card: '',
        zip_code: ''
      },
      isLoading: false,
      showLoader: false,
      isCardSaved: false,
      savedCardData: {},
    };
  }

  componentDidMount = () =>  {
    this.isClientCardSaved(this.props.userData.id);
  }

  isClientCardSaved = (clientId) => {
    this.setState({ isLoading: true });

    Utility.sendRequest(CLIENT_PAYMENT_DETAILS + '/' + clientId, 1, {}, (err, res, body) => {
      body = JSON.parse(body);

      if(!Utility.isEmpty(body)) {
        this.setState({ isCardSaved: true, savedCardData: body });
      }

      this.setState({ isLoading: false });
    });
  }

  getUserData = () => {
    Utility.sendRequest(USER_AUTH, 1, {}, (error, response, body) => {
      body = JSON.parse(body);

      if (!body.error) {
        this.props.updateUserData(body);
      } else {
        toast.error(body.error.message, { containerId: 'public' });
      }
    });
  }

  handleSubmit = (stripe, cardNumber) => {
    var self = this;
    stripe.createToken(cardNumber).then(function(result) {
      if (result.error) {
        const errorElement = document.getElementById('card-errors');
        if (errorElement) errorElement.textContent = result.error.message;
      } else {
        self.submitApi(result);
      }
    })
    .catch((err) => {
    });
  };

  submitApi = (result) => {
    this.setState({ showLoader: true });

    const userData = this.props.userData;
    const paymentDetails = {
      user_id: userData.user_id,
      user_client_id: userData.id,
      payment_profile_id: this.state.savedCardData.payment_profile_id,
      client_id: userData.platform_id,
      user_email: userData.email,
      token: result.token.id,
      cc_last_4_digits: result.token.card.last4,
      cc_type: result.token.card.brand,
      is_active: true,
    };

    Utility.sendRequest(CLIENT_PAYMENT_DETAILS, 3, paymentDetails, (error, response, body) => {
      body = JSON.parse(body);

      if (body.error) {
        toast.error(body.error.message, { containerId: 'private' });
      } else {
        this.setState({ isCardSaved: true, savedCardData: body });

        toast.success('Card details updated successfully.', { containerId: 'private' });
      }
      this.setState({ showLoader: false }, () => this.props.handleClose(), this.getUserData());
    });
  }

  render() {
    return(
      <>
        {
          !this.state.isLoading &&
          <Form className="card-detail" id="payment-form">
            <StripeCardElement
              paymentForm='payment-form'
              setFullName={() => {}}
              setZipCode={() => {}}
              handleSubmit={this.handleSubmit}
            />
            <Button
              disabled={this.state.showLoader}
              type="submit"
              variant="primary"
              className={`btn btn-theme btn-primary px-4 btn-get-started position-relative mt-3`} >
              {this.state.showLoader
                ? <>
                    <Spinner
                      as="span"
                      animation="border"
                      size="sm"
                      role="status"
                      aria-hidden="true"
                    />
                    {` loading...`}
                  </>
                : 'UPDATE'
              }
            </Button>
          </Form>
        }
      </>
    );
  }
}

const mapActionToProps =  { updateUserData };

const mapStateToProps = (state) => ({
    userData: state.userData,
  });

export default connect(mapStateToProps, mapActionToProps)(CreditCardUpdate);
