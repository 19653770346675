import React, { useEffect } from "react";
import { useSelector } from "react-redux";
import AddIcon from "../../../assets/images/icons/add_icon_black.svg";
import { ROLE_IDS } from "../../../config/constants";
import LockIcon from "@mui/icons-material/Lock";
import { useLocation } from "react-router-dom";
const RightSharedSection = ({
  id,
  is_Followed,
  isPublic,
  heading,
  onClick,
  User,
  isOverLay = true,
  children,
  classToOverride,
  showAddIcon
}) => {
  const { userData } = useSelector((state) => state);
  const location = useLocation();
  return (
    <div
      className={`${classToOverride} RightSectionCardMainContainer position-relative ff-Inter-Bold overflow-hidden w-100`}
    >
      {/* {location.pathname.includes("profile") && (
        <>
          {isOverLay &&
            User.id !== userData.id &&
            User.role_id === ROLE_IDS.USER &&
            (is_Followed === false || is_Followed === null) && (
              <div className="overlay-Lock">
                <LockIcon />
              </div>
            )}
        </>
      )} */}
      <span className="right-section-header-container">
        <p className="profile-right-section-head ff-Inter-Bold">{heading}</p>
        {id &&
          (userData.role_id === ROLE_IDS.CLIENT_ADMIN ||
            userData.role_id === ROLE_IDS.CLIENT_OWNER) &&
          !isPublic && !showAddIcon && (
            <img
              onClick={onClick}
              className="addIconStyling mr-3 cursor-pointer"
              src={AddIcon}
              alt="Addicon"
            />
          )}
      </span>
      {children}
    </div>
  );
};

export default RightSharedSection;
