import React, { useEffect, useState } from "react";
import InforCard from "../../../../scenes/profile/components/InforCard";
import RightSharedSection from "../../../../scenes/profile/components/RightSharedSection";
import { NoRecordFound } from "shared/components/no_record_found";
import Create_course from "../../cards/create_course";
import { useDispatch, useSelector } from "react-redux";
import { updateChatFriend, updateIsChatFormOpen } from "../../../actions";
import { withRouter } from "react-router-dom";
import { useMediaQuery } from "@mui/material";

const EventRightPanel = (props) => {
  const [selectedItem, setSelectedItem] = useState("");
  const [seeMoreSpeaker, setSeeMoreSpeaker] = useState(false);
  const [seeMoreAttendee, setSeeMoreAttendee] = useState(false);
  const speakerList = props.eventDetails.organizers_list;
  const [attendeeList, setAttendeeList] = useState([]);
  const isTablet = useMediaQuery("(max-width: 900px)");
  useEffect(() => {
    const Data = props.attendeeList.map((item) => {
      return {
        ...item.UserClient.User,
        id: item.member_id,
        email: item.UserClient.email,
        dob: null,
        user_id: item.UserClient.user_id,
      };
    });
    setAttendeeList([...Data, ...props.eventDetails.non_profits_list]);
  }, [props.attendeeList]);

  const dispatch = useDispatch();
  const accounts = useSelector((state) => state.accountList);
  return (
    <div
      className={`${
        !isTablet
          ? "eventRightPanel background-sidebar-pages"
          : "d-flex flex-column justify-content-around"
      } `}
    >
      <RightSharedSection heading={"Speakers"} classToOverride="mt-4">
        <div
          id="eventSpeaker"
          className="news-viewers-section news-viewers-section-less"
        >
          {speakerList && speakerList.length > 0 ? (
            seeMoreSpeaker ? (
              speakerList.map((data, index) => {
                return (
                  <div className="mt-3 mx-3">
                    <InforCard
                      ImageSection={data.profile_image_url}
                      Heading={`${data.first_name ? data.first_name : ""} ${
                        data.last_name ? data.last_name : ""
                      }`}
                      subHeading={`${
                        data.user_city ? `${data.user_city},` : ""
                      } ${data.creator_state ? `${data.creator_state},` : ""} ${
                        data.creator_country ? data.creator_country : ""
                      }`}
                      isLike={""}
                      leftSectionId={
                        props.userData.network !== "clients" ? "chat" : ""
                      }
                      onSectionClick={() => {
                        window.scrollTo({
                          top: 0,
                          left: 0,
                          behavior: "smooth",
                        });
                        props.history.push({
                          pathname: `/profile/${data.id}`,
                        });
                      }}
                      onClick={() => {
                        dispatch(updateIsChatFormOpen(true));
                        dispatch(updateChatFriend(data));
                      }}
                    />
                  </div>
                );
              })
            ) : (
              speakerList.slice(0, 3).map((data, index) => {
                return (
                  <div className="mt-3 mx-3">
                    <InforCard
                      ImageSection={data.profile_image_url}
                      Heading={`${data.first_name ? data.first_name : ""} ${
                        data.last_name ? data.last_name : ""
                      }`}
                      subHeading={`${
                        data.user_city ? `${data.user_city},` : ""
                      } ${data.creator_state ? `${data.creator_state},` : ""} ${
                        data.creator_country ? data.creator_country : ""
                      }`}
                      isLike={""}
                      leftSectionId={
                        props.userData.network !== "clients" ? "chat" : ""
                      }
                      onSectionClick={() => {
                        window.scrollTo({
                          top: 0,
                          left: 0,
                          behavior: "smooth",
                        });
                        props.history.push({
                          pathname: `/profile/${data.id}`,
                        });
                      }}
                      onClick={() => {
                        dispatch(updateIsChatFormOpen(true));
                        dispatch(updateChatFriend(data));
                      }}
                    />
                  </div>
                );
              })
            )
          ) : (
            <NoRecordFound />
          )}
        </div>
        <div
          className="chapterSection font-18 d-flex justify-content-center mt-3 cursor-pointer align-items-center"
          onClick={() => {
            if (seeMoreSpeaker) {
              document
                .getElementById("eventSpeaker")
                .classList.remove("news-viewers-section-more");
              document
                .getElementById("eventSpeaker")
                .classList.add("news-viewers-section-less");
              setSeeMoreSpeaker(false);
            } else {
              document
                .getElementById("eventSpeaker")
                .classList.remove("news-viewers-section-less");
              document
                .getElementById("eventSpeaker")
                .classList.add("news-viewers-section-more");
              setSeeMoreSpeaker(true);
            }
          }}
        >
          {speakerList &&
            speakerList.length > 3 &&
            (seeMoreSpeaker ? "See Less" : "See More")}
        </div>
      </RightSharedSection>
      <RightSharedSection heading={"Attendees"} classToOverride="mt-4">
        <div
          id="eventAttendee"
          className="news-viewers-section news-viewers-section-less"
        >
          {attendeeList && attendeeList.length > 0 ? (
            seeMoreAttendee ? (
              attendeeList.map((data, index) => {
                return (
                  <div className="mt-3 mx-3">
                    <InforCard
                      ImageSection={data.profile_image_url}
                      Heading={`${data.first_name ? data.first_name : ""} ${
                        data.last_name ? data.last_name : ""
                      }`}
                      subHeading={`${
                        data.user_city ? `${data.user_city},` : ""
                      } ${data.creator_state ? `${data.creator_state},` : ""} ${
                        data.creator_country ? data.creator_country : ""
                      }`}
                      isLike={""}
                      leftSectionId=""
                      onSectionClick={() => {
                        window.scrollTo({
                          top: 0,
                          left: 0,
                          behavior: "smooth",
                        });
                        props.history.push({
                          pathname: `/profile/${data.id}`,
                        });
                      }}
                    />
                  </div>
                );
              })
            ) : (
              attendeeList.slice(0, 3).map((data, index) => {
                return (
                  <div className="mt-3 mx-3">
                    <InforCard
                      ImageSection={data.profile_image_url}
                      Heading={`${data.first_name ? data.first_name : ""} ${
                        data.last_name ? data.last_name : ""
                      }`}
                      subHeading={`${
                        data.user_city ? `${data.user_city},` : ""
                      } ${data.creator_state ? `${data.creator_state},` : ""} ${
                        data.creator_country ? data.creator_country : ""
                      }`}
                      isLike={""}
                      leftSectionId=""
                      onSectionClick={() => {
                        window.scrollTo({
                          top: 0,
                          left: 0,
                          behavior: "smooth",
                        });
                        props.history.push({
                          pathname: `/profile/${data.id}`,
                        });
                      }}
                    />
                  </div>
                );
              })
            )
          ) : (
            <NoRecordFound />
          )}
        </div>
        <div
          className="chapterSection font-18 d-flex justify-content-center mt-3 cursor-pointer align-items-center"
          onClick={() => {
            if (seeMoreAttendee) {
              document
                .getElementById("eventAttendee")
                .classList.remove("news-viewers-section-more");
              document
                .getElementById("eventAttendee")
                .classList.add("news-viewers-section-less");
              setSeeMoreAttendee(false);
            } else {
              document
                .getElementById("eventAttendee")
                .classList.remove("news-viewers-section-less");
              document
                .getElementById("eventAttendee")
                .classList.add("news-viewers-section-more");
              setSeeMoreAttendee(true);
            }
          }}
        >
          {attendeeList &&
            attendeeList.length > 3 &&
            (seeMoreAttendee ? "See Less" : "See More")}
        </div>
      </RightSharedSection>
      <RightSharedSection heading={"Organizer"} classToOverride="mt-4">
        <div className="mt-3 mx-3">
          <InforCard
            ImageSection={props.eventDetails.creator_profile_image_url}
            Heading={
              props.eventDetails.creator_name
                ? props.eventDetails.creator_name
                : ""
            }
            subHeading={
              props.eventDetails.creator_address1
                ? props.eventDetails.creator_address1
                : ""
            }
            isLike={""}
            leftSectionId=""
          />
        </div>
      </RightSharedSection>
    </div>
  );
};

export default withRouter(EventRightPanel);
