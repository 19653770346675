import React, { Component } from 'react';
import { Row, Col, Form, Button } from 'react-bootstrap';
import { InputField, DropDown, Checkbox } from 'shared/components/form_elements';
import { DateTimePicker } from '@material-ui/pickers';
import { Utility } from 'utils';
import UploadFile from 'shared/components/file_upload';
import RichTextEditor  from 'shared/components/rich_text_editor';
import CustomDropDownList  from 'shared/components/custom_drop_down_list';
import PayoutScheduleCheckbox from 'shared/components/payout_schedule_checkbox';
import * as moment from 'moment';
import { CAMPAIGN_TYPES_IDS } from 'config/constants';

export default class StoryForm extends Component {
  resetFormData = (event) => {
    event.preventDefault();

    document.getElementById('agree-terms').checked = false;
    /*
      this.refs.non_profits.refs.non_profits.selectedIndex = 0;
      this.refs.publisher_network_id.refs.publisher_network_id.selectedIndex = '';
      this.refs.category_id.refs.category_id.selectedIndex = 0;
      this.refs.is_global_feed.refs.is_global_feed.checked = false;
      this.refs.is_network_feed.refs.is_network_feed.checked = false;
      this.refs.is_channel_feed.refs.is_channel_feed.checked = false;
      this.refs.schedule_now.refs.schedule_now.checked = false;
    */

    this.props.resetFormData();
  }

  render () {
    const formData = this.props.formData;
    const scheduleDate = moment(this.props.schedule_start);
    const now = moment();
    let isPrePost = true;
    let categories = [];

    if (now.diff(scheduleDate) > 0) isPrePost = false;
    if (!Utility.isEmpty(this.props.categories)) categories = [...this.props.categories ];

    categories.unshift({ id: '', name: 'Select where you would like to post this story' });

    return (
      <div className="pt-lg-5 pt-3 create-story-form">
        <Row>
          <Col xs={12}>
            <Form.Group controlId="heading">
              <Form.Label className="font-16-medium">What is your story headline?</Form.Label>
              <InputField
                required={true}
                value={formData.heading}
                type="text"
                placeholder="Enter the headline"
                name="heading"
                ref="heading"
                handleChange={this.props.handleChange}
              />
            </Form.Group>
          </Col>
        </Row>
        <Row>
          <Col xs={12}>
            <Form.Group controlId="sub_heading" className="pt-3">
              <Form.Label className="font-16-medium">What is your sub headline? <span className="font-16-light-italic pl-1">(optional)</span></Form.Label>
              <InputField
                required={true}
                value={formData.sub_heading}
                type="text"
                placeholder="Enter the sub headline"
                name="sub_heading"
                ref="sub_heading"
                handleChange={this.props.handleChange}
              />
            </Form.Group>
          </Col>
        </Row>
        <Row>
          <Col xs={12}>
            <Form.Group controlId="image_url" className="pt-3">
              <Form.Label className="font-16-medium">Select an image or video to upload</Form.Label>
              <Row className="target-input mx-0">
                <div className="d-inline w-md-100 mr-lg-4">
                  <label className="add-file-height">
                    <UploadFile
                      inputId="image_url"
                      selectTitle="Select an image to upload"
                      removeText="Remove image"
                      categoryName="publisher_campaign_image"
                      acceptTypes="image/x-png,image/gif,image/jpeg"
                      uploadedFilePreviewUrl={this.props.imagePreviewUrl}
                      handleFileChange={this.props.handleFileChange}
                      removeUploadedFile={this.props.removeUploadedFile}
                    />
                  </label>
                  <div className="error errorIcon" id={'image_urlError'} />
                  <div className="text-center font-16-medium pb-2">or</div>
                  <InputField
                    type="url"
                    placeholder="Paste embedded image link here…"
                    name="embedded_image_link"
                    ref="embedded_image_link"
                    value={formData.embedded_image_link}
                    handleChange={this.props.handleChange}
                  />
                </div>
                <div className="d-inline w-md-100 mt-lg-0 mt-4">
                  <label className="add-file-height">
                    <UploadFile
                      inputId="video_url"
                      selectTitle="Select a video to upload (optional)"
                      removeText="Remove video"
                      acceptTypes="video/mp4"
                      categoryName="publisher_campaign_video"
                      uploadedFilePreviewUrl={this.props.videoPreviewUrl}
                      handleFileChange={this.props.handleFileChange}
                      removeUploadedFile={this.props.removeUploadedFile}
                    />
                  </label>
                  <div className="error errorIcon" id={'video_urlError'} />
                  <div className="text-center font-16-medium pb-2">or</div>
                  <InputField
                    type="text"
                    placeholder="Paste embedded video link here…"
                    name="embedded_video_link"
                    ref="embedded_video_link"
                    value={formData.embedded_video_link}
                    handleChange={this.props.handleChange}
                  />
                </div>
              </Row>
            </Form.Group>
          </Col>
        </Row>
        <Row>
          <Col xs={12} className="story-content-input" >
            <Form.Group className="pt-3">
              <Form.Label className="font-16-medium">Enter the content for your story</Form.Label>
                <RichTextEditor
                  id="description"
                  name="description"
                  data={formData.description}
                  changeDescription={this.props.changeDescription}
                />
            </Form.Group>
          </Col>
         </Row>
        {/*
          <Row>
            <Col xl={7} xs={12}>
              <Form.Group controlId="publisher_network_id" className="pt-3">
                <Form.Label className="font-16-medium">What network do you want to post?</Form.Label>
                <DropDown
                  optionId="id"
                  optionValue="name"
                  required={true}
                  name="publisher_network_id"
                  ref="publisher_network_id"
                  options={this.props.publisherNetworks}
                  disabled={this.props.campId ? true : false }
                  handleChange={ (e) => this.props.getCategories(e.target.value, 1, e)}
                />
              </Form.Group>
            </Col>
          </Row> */}

        <Row>
          <Col xl={7} xs={12}>
            <Form.Group controlId="category_id" className="pt-3">
              <Form.Label className="font-16-medium">Where do you want to post this?</Form.Label>
              <DropDown
                optionId="id"
                optionValue="name"
                required={true}
                name="category_id"
                ref="category_id"
                options={categories}
                handleChange={this.props.handleChange}
              />
            </Form.Group>
          </Col>
        </Row>
        <Row>
          <Col xl={7} xs={12}>
            <Form.Group controlId="hashtags" className="pt-3">
              <Form.Label className="font-16-medium">Add Tags <span className="font-16-light-italic pl-1">(optional)</span></Form.Label>
              <InputField
                required={true}
                type="text"
                placeholder="Enter tags separated by commas…"
                name="hashtags"
                ref="hashtags"
                value={formData.hashtags}
                handleChange={this.props.handleChange}
              />
            </Form.Group>
          </Col>
        </Row>
        <Row>
          <Col xl={7} xs={12}>
            <Form.Group controlId="storymap_url" className="pt-3">
              <Form.Label className="font-16-medium">Would you like to include storymap URL? <span className="font-16-light-italic pl-1">(optional)</span></Form.Label>
              <InputField
                type="url"
                placeholder="Enter story URL"
                name="storymap_url"
                ref="storymap_url"
                value={formData.storymap_url}
                handleChange={this.props.handleChange}
              />
            </Form.Group>
          </Col>
        </Row>
        {/*}
        <Row>
          <Col xl={7} xs={12}>
            <Form.Group controlId="non_profits" className="pt-3">
              <Form.Label className="font-16-medium">Who is the beneficiary of this story? <span className="font-16-light-italic pl-1">(optional)</span></Form.Label>
              <DropDown
                optionId="id"
                optionValue="name"
                name="non_profits"
                ref="non_profits"
                options={this.props.nonProfits}
                handleChange={this.props.handleChange}
              />
              <CustomDropDownList
                dataList={this.props.campaignNonProfits}
                removeSelectedItem={this.props.removeSelectedNonprofits}
              />
            </Form.Group>
          </Col>
        </Row>
        */}
        {/*
          <Row>
            <Col xl={6} xs={12}>
              <Form.Group controlId="broadcast" className="pt-3">
                <Form.Label className="font-16-medium">Where do you want to broadcast? <span className="font-16-light-italic pl-1">(optional)</span></Form.Label>

                  <Checkbox
                    ref="is_global_feed"
                    placeholder="Post to global feed"
                    checkboxId="is_global_feed"
                    name="is_global_feed"
                    label="Post to global feed (Public)"
                    handleChange={this.props.handleChange}
                  />
                  <Checkbox
                    ref="is_network_feed"
                    placeholder="Post to network feed"
                    checkboxId="is_network_feed"
                    name="is_network_feed"
                    label="Post to network feed (Available to organizations in your network)"
                    handleChange={this.props.handleChange}
                  />
                  <Checkbox
                    ref="is_channel_feed"
                    placeholder="Post to channel feed"
                    checkboxId="is_channel_feed"
                    name="is_channel_feed"
                    label="Post to channel feed (Available only to your audience)"
                    handleChange={this.props.handleChange}
                  />
              </Form.Group>
            </Col>
          </Row>
        */}
        <Row>
          <Col xl={6} xs={12}>
            <Form.Group controlId="schedule_now" className="pt-3">
              <Form.Label className="font-16-medium">When would you like to publish story?</Form.Label>
              <Checkbox
                ref="schedule_now"
                placeholder="Right Now"
                checkboxId="schedule_now"
                name="schedule_now"
                label="Right Now"
                checked={this.props.isScheduleNow}
                disabled={(this.props.campId && !isPrePost) ? true : false}
                handleChange={(event) => { this.props.handleScheduleDateCheckbox(event, 'schedule_now'); }}
              />
            </Form.Group>
          </Col>
        </Row>
        <Row>
          <Col lg={6} xs={12}>
            <Form.Group id="schedule_start" className="mb-0 events-none" ref="schedule_start">
              <DateTimePicker
                variant="inline"
                disabled={this.props.isScheduleNow}
                value={this.props.scheduleStart}
                disablePast={this.props.campId && this.props.isScheduleNow ? false : true}
                onChange={(event) => this.props.handleScheduleDate(event, 'schedule_start')}
              />
            </Form.Group>
          </Col>
        </Row>
        <Col xs={12} className="p-0 pt-4">
          <PayoutScheduleCheckbox
            sourceId="create-story"
            linkName="payout schedule"
          />
          <Button variant="link" type="submit" className="mx-sm-5 mx-3 font-light" onClick={(e) => this.resetFormData(e)}> Cancel </Button>
          <Button variant="primary" onClick={(e) => this.props.submitFormData(CAMPAIGN_TYPES_IDS.story, false,  e)}>{ Utility.isEmpty(this.props.campId) ? 'Publish' : 'Update' }</Button>
        </Col>
      </div>
    );
  }
}
