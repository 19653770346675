import { Modal } from "@mui/material";
import React from "react";
import classNames from "classnames";
import c from "./CustomModal.module.scss";
import CloseIcon from "@mui/icons-material/Close";

const CustomModal = ({ title, open, onClose, children, classToOverride }) => {
  return (
    <Modal
      open={open}
      onClose={() => onClose()}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <div className={classNames("bg-white p-2", c.modal, classToOverride)}>
        <div className="d-flex align-items-center justify-content-between">
          <div className={c.modalTitle}>{title}</div>
          <div
            className={classNames(c.cursorPointer)}
            onClick={() => onClose()}
          >
            <CloseIcon />
          </div>
        </div>
        {children}
      </div>
    </Modal>
  );
};
export default CustomModal;
