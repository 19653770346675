import Tooltip from "@material-ui/core/Tooltip";
import React from "react";

const regionCard = ({
  tag,
  colour,
  text,
  text_colour,
  borderState,
  handleSelect,
  setCommunityLabel,
  id,
}) => {
  return (
    <div
      className={`circle-communities pt-5 font-weight-bold m-2 position-relative d-flex flex-column my-3 ${
        text.length < 14 ? "small-region-name" : "long-region-name"
      }`}
      style={{
        background: colour, // colours come from BE,
        border: borderState === text ? `2px solid ${text_colour}` : "",
        // boxShadow: "0px 0px 10px rgba(0, 0, 0, 0.5)",
        // opacity: borderState === text ? 1 : 0.5,
      }}
      onClick={() => {
        handleSelect(tag, text);
        setCommunityLabel({ communitText: text, regionId: id });
      }}
    >
      {/* <Tooltip title={text}>
      <p className="regionCard mx-auto mb-0 text-wrap text-truncate" style={{ color: text_colour, fontSize : "12px" }}>
        {text}
      </p>
      </Tooltip> */}
      {/* {
      text && text.length > 18 ? (
        <Tooltip title={text}>
          <p
            className="regionCard-community mx-auto text-wrap"
            style={{ color: "white", fontSize: "20px" }}
          >
            {(text.includes("/") && text.length > 17)
              ? `${text.replace("/", "/ ").substring(0, 17)}...`
              : `${text.substring(0, 17)}...`}
          </p>
        </Tooltip>
      ) : ( */}

        <p
          className="regionCard-community mx-auto text-wrap"
          style={{ color: "white", fontSize: "18px" }}
        >
          {(text.includes("/") && text.length > 10) ? text.replace("/", "/ ") : text}
        </p>

      {/* )} */}
    </div>
  );
};

export default regionCard;
