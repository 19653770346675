import React, { Component } from 'react';
import { connect } from 'react-redux';
import { updateSelectedGroup } from 'shared/actions';
import { MenuTitle } from 'shared/components/templates/menu_list';
import { Row, Col } from 'react-bootstrap';
import UserLicensesSummary, { ConfirmPayemt } from 'shared/components/user_licenses_summary';
import { GET_INVOICE_LICENSE_DATA } from 'config/constants/api_endpoints';
import { Utility } from 'utils';

class Licenses extends Component {
  constructor(props) {
    super(props);

    this.state = {
      licenseData: {},
    };
  }

  componentDidMount = () => {
    Utility.sendRequest(GET_INVOICE_LICENSE_DATA, 1, {
      user_client_id: this.props.userData.id,
      conn_to_another_city_id: this.props.userData.conn_to_another_city_id,
      }, (err, res, body) => {
      body = JSON.parse(body);

      if(!Utility.isEmpty(body)) {
        this.setState({ licenseData: body });
      }
    });
  }

  fetchLicenseSummary = () => {
    if(this.props.userData.is_super_owner)
      return <h6 className="mt-3"><center>No License Found</center></h6>;
    else
      return <UserLicensesSummary
        licenseData={this.state.licenseData}
        userData={this.props.userData}
        accountList={this.props.accountList}
        fromFinanceModule={true}
        fromLicense={true}
      />;
  }

  render() {
    return  (
      <>
        <Col xl={9} lg={8} xs={12} className="ModalOpen top-on-sm h-100 p-4 overflow-auto scroll-y" >
          <Row className="mx-0 p-sm-3">
            <div className="network-box-shadow h-100 mt-3 mt-sm-0">
              <div className="network-item-header d-flex align-items-center">
                <div className="round-circle mr-2">
                </div>
                <MenuTitle menuName={this.props.selectedMenuItem.menu_name}/>
              </div>
            </div>
          </Row>
          {this.fetchLicenseSummary()}
        </Col>
      </>
    );
  }
}

const mapStateToProps = (state) => ({
  userData: state.userData,
  selectedMenuItem: state.selectedMenuItem,
  selectedGroup: state.selectedGroup,
  accountList: state.accountList,
});

const mapActionToProps = { updateSelectedGroup };

export default connect(mapStateToProps, mapActionToProps)(Licenses);
