import React, { Fragment, useState } from "react";
import Discussion from "../../../../shared/components/v2/campaign/components/Discussion";
import RichTextEditor from "../../../../shared/components/rich_text_editor";
import CommentTool from "../../../../shared/components/v2/campaign/components/CommentTool";
import { COMMENT_TOOL_BAR_OPTION } from "../../../../config/constants";
import { useDispatch } from "react-redux";
import { GET_DISCUSSION_COMMENTS } from "../../../../config/constants/api_endpoints";
import { showLoader } from "../../../../shared/actions";
import * as Utility from "utils/utility";
import { toast } from "react-toastify";
import { CLIENT_ID } from "../../../../config/constants/config";
import UserCommentContainer from "../../../../shared/components/v2/campaign/components/UserCommentContainer";
export const DiscussionsChat = () => {
  const [comment, setComment] = useState("");
  const [postAndComments, setPostAndComments] = useState([]);
  const dispatch = useDispatch();

  return (
    <div>
      {/* {" "}
      {postAndComments.map((item, index) => (
        <Fragment key={index}>
          <Discussion getCommentData={getCommentData} commentData={item} />
        </Fragment>
      ))}
      <div className="comment-input w-75 mx-auto">
        <RichTextEditor
          id="comment"
          name="comment"
          placeholder="Start a Conversation ....."
          data={comment}
          changeDescription={changeDescription}
          isComment={true}
          toolbar={COMMENT_TOOL_BAR_OPTION}
        />
        <CommentTool
          parent_id={"56dfcc90-17cf-11ed-b194-8d7223a7383b"}
          getCommentData={getCommentData}
          commentData={comment}
          changeDescription={changeDescription}
        />
      </div> */}
      <UserCommentContainer
        ActiveLessonId={"56dfcc90-17cf-11ed-b194-8d7223a7383b"}
        isRegion={true}
        isCampaign={false}
      />
    </div>
  );
};
