import { Pagination, PaginationItem } from "@mui/material";
import { MdChevronLeft, MdChevronRight } from "react-icons/md";
import React from "react";
import { makeStyles } from "@mui/styles";

const useStyles = makeStyles((theme) => ({
  selected: {
    backgroundColor: "transparent",
    color: "#6f42c1",
  },
}));

const ProductPagination = ({ size, value, count, handleChangePage }) => {
  const classes = useStyles();
  return (
    <Pagination
      count={count}
      page={value}
      onChange={(event, page) => {
        handleChangePage(page);
      }}
      className="pagination-box"
      size={size}
      renderItem={(item) => (
        <PaginationItem
          components={{
            previous: MdChevronLeft,
            next: MdChevronRight,
          }}
          classes={classes}
          {...item}
        />
      )}
    />
  );
};

export default ProductPagination;
