import { Search } from "@mui/icons-material";
import React, { useEffect, useState } from "react";
import _ from "lodash";
import { AddChatBubbleIcon, FilterListIcon } from "../../../icons/icons";
import * as Utility from "utils/utility";
import { Spinner } from "react-bootstrap";
import {
  openNeighborhoodForm,
  showLoader,
  updateAccountList,
  updateUserData,
  updateRefreshCampaignAPI,
  updateCampaignFormOpen,
  updateIsChatFormOpen,
  updateSeeAll,
} from "shared/actions";
import { CLIENT_ID } from "config/constants/config";
import { updateChatFriend } from "shared/actions";
import {
  GET_USERS_BY_NETWORKS,
  GET_USERS_OF_NEIGHBORHOOD,
} from "../../../../config/constants/api_endpoints";
import { connect, useSelector } from "react-redux";
import Avatar from "react-avatar";
import { toast } from "react-toastify";
import { ROLE_IDS } from "../../../../config/constants";
import { useHistory } from "react-router-dom";

const LeftSection = (props) => {
  const [usersList, setUsersList] = useState([]);
  const [neighborhoodMembers, setNeighborhoodMembers] = useState([]);
  const [filteredUsersList, setFilteredUsersList] = useState();
  const [currentPage, setCurrentPage] = useState(1);
  const [isSearch, setIsSearch] = useState(false);
  const [filteredNeighborhoodMembers, setFilteredNeighborhoodMembers] =
    useState([]);
  const [filteredSearchList, setFilteredSearchList] = useState();
  const [searchString, setSearchString] = useState("");
  const [loading, setLoading] = useState(false);
  const history = useHistory();
  const accountList = useSelector((state) => state.accountList);
  useEffect(() => {
    // if (props.activeChatDetail && !props.activeChatDetail.is_discussion) {
    getUserAllChatsUser("", currentPage);
    // props.showLoader(true);
    // }
  }, []);

  const setSearchData = () => {
    if (searchString.length > 0) {
      let searchFilteredData = usersList.filter((data) =>
        data.user_name.toLowerCase().includes(searchString.toLowerCase())
      );
      setFilteredSearchList(searchFilteredData);
      setFilteredUsersList(searchFilteredData.slice(0, 100));
    } else {
      setFilteredSearchList(usersList);
      setFilteredUsersList(usersList.slice(0, 100));
    }
  };

  useEffect(() => {
    if (usersList.length > 0) {
      setSearchData();
    }
  }, [searchString]);

  // const fetchMoreData = () => {
  //   if (filteredUsersList && filteredUsersList.length < usersList.length) {
  //     const length = filteredUsersList.length;
  //     setLoading(true);
  //     setTimeout(() => {
  //       let data = filteredUsersList.concat(
  //         filteredSearchList.slice(length, length + 100)
  //       );
  //       setFilteredUsersList(data);
  //       setLoading(false);
  //     }, 500);
  //   }
  // };

  const handleScroll = (e) => {
    const bottom =
      (e.target.scrollHeight - e.target.scrollTop).toFixed(0) ===
      e.target.clientHeight.toFixed(0);

    if (bottom && !loading) {
      getUserAllChatsUser("", currentPage + 1, 40);
      // fetchMoreData();
      // } else if (e.target.scrollTop < 10) {
      //   getUserAllChatsUser("", currentPage - 1, 20);
      //   // setFilteredUsersList(filteredSearchList.slice(0, 100));
    }
  };

  const getUserAllChatsUser = (search = "", page_number, limit) => {
    const params = {
      client_id: CLIENT_ID,
      type: "users",
      forCommunity: true,
      page_number: page_number,
      limit: 20,
    };
    if (search) {
      params["search"] = search;
    }
    setLoading(true);

    // if (search) params.search = search;
    Utility.sendRequest(GET_USERS_BY_NETWORKS, 1, params, (err, res, body) => {
      body = JSON.parse(body);
      if (body.error) {
        console.log(body.error);
      } else {
        setCurrentPage(page_number);
        if (search) {
          setUsersList([...body.registrants]);
          setIsSearch(true);
          document.getElementById("userListContainer").scrollTo(0, 0);
        } else {
          if (isSearch) {
            setUsersList([...body.registrants]);
            setIsSearch(false);
          } else {
            setUsersList([...usersList, ...body.registrants]);
          }
        }
        props.showLoader(false);
      }
      setLoading(false);
    });
  };

  const getUsersOfNeighborhood = (client_id) => {
    const params = {
      platform_id: CLIENT_ID,
      client_id: client_id,
    };

    Utility.sendRequest(
      GET_USERS_OF_NEIGHBORHOOD,
      2,
      params,
      (err, res, body) => {
        body = JSON.parse(body);

        if (body.error) {
          toast.error(body.error.message, { containerId: "private" });
        } else {
          body.data &&
            setNeighborhoodMembers(
              body.data.map((item) => item.user_client_id)
            );
        }
      }
    );
  };

  useEffect(() => {
    if (
      props.selectedUser &&
      Object.keys(props.selectedUser).length !== 0 &&
      props.selectedUser.network === "clients"
    ) {
      if (props.selectedUser.is_discussion) {
        getUsersOfNeighborhood(props.selectedUser.corporate_id);
      } else {
        getUsersOfNeighborhood(props.selectedUser.client_id);
      }
    }
  }, [props.selectedUser]);

  useEffect(() => {
    if (
      props.activeScreenName === "Chat" &&
      props.activeChatDetail &&
      Object.keys(props.activeChatDetail).length !== 0 &&
      (props.activeChatDetail.role_id === ROLE_IDS.CLIENT_ADMIN ||
        props.activeChatDetail.role_id === ROLE_IDS.CLIENT_OWNER ||
        props.activeChatDetail.is_discussion)
    ) {
      if (usersList && neighborhoodMembers) {
        const user_list = usersList.filter((item) => {
          return neighborhoodMembers.includes(item.id);
        });

        const citizen =
          accountList &&
          Array.isArray(accountList.Users) &&
          accountList.Users.length > 0
            ? accountList.Users[0]
            : [];
        setFilteredNeighborhoodMembers([{ ...citizen }, ...user_list]);

        // setFilteredNeighborhoodMembers(
        //   usersList.filter((item) => {
        //     return neighborhoodMembers.includes(item.id);
        //   })
        // );
      }
    } else {
      setFilteredNeighborhoodMembers(usersList);
    }
  }, [neighborhoodMembers, usersList, props.activeScreenName]);

  const handleActiveChange = (items) => {
    if (props.activeChatDetail && props.activeChatDetail.is_discussion) {
      // props.handleSwitchAccount()
      // console.log(props.userData.id);
      // console.log(items.id);
      getProfileOrSwitchProfile(items.id);
    } else {
      props.setActiveScreenName("Chat");
      props.setActiveChatDetail(items);
    }
  };

  const getProfileOrSwitchProfile = (id) => {
    if (id === props.userData.id) return history.push("/profile");

    const account_list = [
      ...accountList.Users,
      ...accountList.Clients,
      ...accountList.Parents,
      ...accountList.Admins,
    ];
    const profile = account_list.find((item) => item.id === id);

    if (profile) props.handleSwitchAccount(id, profile, false);
    else
      history.push({
        pathname: `/profile/${id}`,
        state: { otherUserProfile: true },
      });
  };

  const paintUserData = (items) => {
    return (
      <div
        className={`d-flex CommunitySection-left-userData  align-items-center justify-contant-between cursor-pointer ${
          props.activeChatDetail &&
          props.activeChatDetail.id === items.id &&
          "CommunitySection-left-userData-selected"
        }`}
        onClick={() => {
          handleActiveChange(items);
          props.setIsChatClicked(true);
          // props.setActiveScreenName("Chat");
          // props.setActiveChatDetail(items);
          //come back
        }}
      >
        <div className="d-flex align-items-center CommunitySection-left-userNameAvatar">
          <Avatar
            name={items.user_name}
            round={true}
            src={items.profile_image_url}
            size={"27"}
          />
          <div className="CommunitySection-left-userName">
            {items.user_name}
          </div>
        </div>
        {!(props.activeChatDetail && props.activeChatDetail.is_discussion) && (
          <span
            onClick={(event) => {
              event.stopPropagation();
              props.addMember(items);
              props.setActiveScreenName("Landing");
            }}
            className="mr-2 cursor-pointer"
          >
            <AddChatBubbleIcon />
          </span>
        )}
      </div>
    );
  };

  function debounce(fn, delay) {
    let timer;
    return function () {
      let context = this,
        args = arguments;
      clearTimeout(timer);
      timer = setTimeout(() => {
        fn.apply(context, args);
      }, delay);
    };
  }

  const handleInputChange = (value) => {
    setSearchString(value);
    debounce(getUserAllChatsUser(value, 1), 3000);
  };

  return (
    <div className="h-100 CommunitySection-left">
      <div className="CommunitySection-left-header pl-3 pb-2">
        {props.activeChatDetail && props.activeChatDetail.is_discussion
          ? "Discussion"
          : "Communications"}
      </div>
      <div className="SeactionSearchContainer mt-20 pl-3 justify-content-start">
        <div className="searchSectionWrapper py-1 w-80">
          <input
            onChange={(event) =>
              props.selectedUser.network !== "clients"
                ? handleInputChange(event.target.value)
                : setSearchString(event.target.value)
            }
            className="SeactionSearch"
            placeholder="Search Citizens"
          />
          <span className="SeactionSearchIcon">
            <Search />
          </span>
        </div>
        {/* <div className="ml-2">
          <FilterListIcon />
        </div> */}
      </div>
      <div
        id="userListContainer"
        className="CommunitySection-left-userDataContainer scroll-y"
        onScroll={props.selectedUser.network !== "clients" && handleScroll}
      >
        {props.selectedUser.network === "clients" &&
          filteredNeighborhoodMembers &&
          filteredNeighborhoodMembers.length > 0 &&
          filteredNeighborhoodMembers
            .filter((item) =>
              item.user_name.toLowerCase().includes(searchString.toLowerCase())
            )
            .map((items) => {
              return paintUserData(items);
            })}
        {props.selectedUser.network !== "clients" &&
          (searchString === ""
            ? usersList &&
              usersList.length > 0 &&
              usersList.map((items, index) => paintUserData(items))
            : filteredUsersList &&
              filteredUsersList.length > 0 &&
              filteredUsersList.map((items, index) => paintUserData(items)))}

        {loading && (
          <div className="d-flex justify-content-center align-items-center">
           <Spinner animation="border" role="status" />
          </div>
        )}
      </div>
    </div>
  );
};

const mapActionToProps = {
  showLoader,
  updateRefreshCampaignAPI,
  openNeighborhoodForm,
  updateAccountList,
  updateUserData,
  updateCampaignFormOpen,
  updateIsChatFormOpen,
  updateSeeAll,
  updateChatFriend,
};

const mapStateToProps = (state) => ({
  userData: state.userData,
  isLoading: state.isLoading,
  accountList: state.accountList,
  neighborhoodState: state.neighborhoodState,
  isCampaignFormOpen: state.isCampaignFormOpen,
  isChatFormOpen: state.isChatFormOpen,
  chatFriend: state.chatFriend,
  seeAll: state.seeAll,
  refreshCampaignApi: state.refreshCampaignApi,
});

export default connect(mapStateToProps, mapActionToProps)(LeftSection);
