import React, { useState, useEffect } from "react";
import { NEIGHBORHOOD_BY_TAG } from "../../../../../config/constants/api_endpoints";
import { Utility } from "../../../../../utils";
import { toast } from "react-toastify";
import { Grid } from "@material-ui/core";
import { GrSearch } from "react-icons/gr";
import MembersCard from "./membersCard";
import { ROLE_IDS } from "../../../../../config/constants";
import { useSelector } from "react-redux";
import { Skeleton } from '@mui/material';
import { Container, Row, Col } from 'react-bootstrap';



const GetAllSubCategories = ({ communityLabel, selectedTag }) => {
  const [countriesData, setCountriesData] = useState([]);
  const userData = useSelector((state) => state.userData);
  const [searchData, setSearchData] = useState([]);
  let debouncedFn = null;
  const [isJoined, setIsJoined] = useState(true);
  const [loading,setLoading] = useState(true);
  useEffect(() => {
    if (isJoined) {
      loadStories();
      setIsJoined(false);
    }
  }, [isJoined]);

  const loadStories = () => {
    Utility.sendRequest(
      NEIGHBORHOOD_BY_TAG,
      1,
      {
        tag: selectedTag,
        sub_tag: communityLabel.regionId,
        user_id: userData.user_id,
      },
      (err, res, body) => {
        body = JSON.parse(body);
        if (body.error) {
          toast.error(body.error.message, { containerId: "private" });
        } else {
          setCountriesData(body);
          setLoading(false);
        }
      }
    );
  };

  const handleSearch = (ev) => {
    if (!debouncedFn) {
      debouncedFn = Utility.debounce(() => {
        const searchString = ev.target.value;
        setSearchData(searchString);
      }, 500);
    }
    debouncedFn();
  };

  useEffect(() => {
    loadStories();
    setLoading(true);
  }, [communityLabel.regionId]);
  const filteredCountriesData =
    Array.isArray(countriesData) &&
    countriesData.filter((item) =>
      searchData.length
        ? item.name.toLowerCase().includes(searchData.toLowerCase())
        : item.name
    );


  return (
    <div
      className="regionPage pl-0 pt-5 position-relative"
      style={{ backgroundColor: "#F5F5F5", bottom: "30px" }}
    >
      <div className="col-12 pb-2  mt-0  mb-2 pl-5">
        <div
          className="d-flex justify-content-between w-100"
          style={{ paddingRight: "50px" }}
        >
          <p
            className="align-self-center m-0 font-weight-bold"
            style={{ fontSize: "20px" }}
          >
            {communityLabel.communitText}
          </p>
          <div className="SeactionSearchContainer search-communities">
            <div className="searchSectionWrapper bg-white">
              <input
                className="SeactionSearch"
                placeholder="Search Sub regions"
                onChange={(e) => {
                  handleSearch(e);
                }}
              />
              <span className="SeactionSearchIcon">
                <GrSearch className="regionIcon" />
              </span>
            </div>
          </div>
        </div>
      </div>
      <div className="pl-5 pr-4">
        <Grid
          container
          // spacing={{ xs: 2, md: 3 }}
          columns={{ xs: 4, sm: 8, md: 12 }}
          className="py-0 my-0"
        >
          {loading ? (
       <Container >
       <Row>
        <Col xs={12} sm={6} md={4}>
          <Skeleton variant="rectangular" width="100%" height={240} />
        </Col>
        <Col xs={12} sm={6} md={4}>
          <Skeleton variant="rectangular" width="100%" height={240} />
        </Col>
        <Col xs={12} sm={6} md={4} >
          <Skeleton variant="rectangular" width="100%" height={240} />
        </Col>
      </Row>
     </Container>
      ):
      <>
          {filteredCountriesData && filteredCountriesData.length > 0 ? (
            filteredCountriesData
              .filter((data) => data.role_id !== ROLE_IDS.USER)
              .map((val, index) => (
                <Grid item xs={6} sm={6} md={4} key={index} className="py-2">
                  <MembersCard
                    item={val}
                    name={val.name}
                    isJoined={isJoined}
                    setIsJoined={setIsJoined}
                    members_count={val.members_count}
                  />
                </Grid>
              ))
          ) : (
            <div className="d-flex align-items-center justify-content-center my-55 w-100">
              <div className="px-5">
                <span className="font-bold display-6">No Records Found</span>
              </div>
            </div>
          )}
          </>
}
        </Grid>
      </div>
    </div>
  );
};

export default GetAllSubCategories;
