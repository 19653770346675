import React, { memo } from "react";
import DetailListHeader from "./detail_list_header";

let ItemDetail = (props) => {
  return (
    <>
      <div className="h-100 scroll-y top-on-sm ModalOpen p-4 d-none d-lg-block col-xl-9 col-lg-8 col-12">
        <div className="p-sm-3">
          <DetailListHeader
            selectedUsers={props.selectedUsers}
            selectedClients={props.selectedClients}
            activateDeactivateUser={props.activateDeactivateUser}
            emails={props.emails}
            client_emails={props.client_emails}
            showFilterComponent={props.showFilterComponent}
            filterList={props.filterList}
            isShowActiveUser={props.isShowActiveUser}
            handleChangeActiveCatrgory={props.handleChangeActiveCatrgory}
            headerTitle={props.headerTitle}
            getMembersOfNeighborhood={props.getMembersOfNeighborhood}
          />
          <div className="mt-4 registants-table">{props.children}</div>
        </div>
      </div>
    </>
  );
};

export default ItemDetail = memo(ItemDetail);
