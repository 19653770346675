import React, { useState, memo } from 'react';
import moment from 'moment';
import { Image, Form } from 'react-bootstrap';
import { Utility } from 'utils';
import { withRouter } from "react-router";
import { CAMPAIGN_TYPES_IDS, RANKS } from 'config/constants';
import { CAMPAIGNS, SET_CAMPAIGN_HEADLINE } from 'config/constants/api_endpoints';
import { toast } from 'react-toastify';
import CreateCampaign from '../../scenes/users/components/create_campaign';
import Avatar from 'react-avatar';

const SuggestedReads = (props) => {
  const [rankComponent, setRankComponent] = useState([]);
  const [isEdit, setIsEdit] = useState(false);
  const [editCampaignId, setEditCampaignId] = useState(null);

  const paintUpdateAt = (updatedAt) => {
    let date = moment(updatedAt).format('ll');
    const time = moment(updatedAt).format("hh:mm A");
    date = date.split(',')[0];
    return `Updated at ${time}, ${date}`;
  };

  const paintRank = (e, card) => {
    e.stopPropagation();
    const temp = [];
    if(rankComponent.length && card.id === rankComponent[0].cardId){
      setRankComponent([]);
    }else{
      temp.push(
        {
          cardId: card.id
        },
        <Rank
          feed={card}
          setPriority={props.setPriority}
          campType={props.campType}
          userData={props.userData}
          loadStories={props.loadStories}
          setRankComponent={setRankComponent}
          setRefresh={props.setRefresh}
          handleEdit={handleEdit}
        />
      );
      setRankComponent(temp);
    }
  };

  const handleArticleClick = (e) => {
    props.history.push({
      pathname: `/campaign/${e.parent_id || e.id}`,
      calledFrom: props.type,
      goBackLink: goBackLink(),
    });
  };

  const handleAuthorClick = (ev, id) => {
    ev.stopPropagation();

    props.history.push({
      pathname: `/profile/${id}`,
      goBackLink: goBackLink(),
      state: { otherUserProfile: true },
    });
  };

  const handleEdit = (ev, id) => {
    ev.stopPropagation();

    setEditCampaignId(id);
    setIsEdit(true);
  };

  const paintCard = (card) => {
    return(
      <>
        <div className={`px-3 py-4 ${rankComponent.length && rankComponent[0].cardId === card.id ? 'rank-dropdown-opner' : ''}`}>
          <div className={`font-14 mb-20 font-bold`} style={{ color: card.category_color }}>{card.campaign_category}

          {(props.showMenuIcon && parseInt(card.priority) > 0) && <span className='font-12 mt-1 float-right text-dark'>Rank {card.priority}</span>}
          </div>
          <div className="font-bold display-6 article-title pb-1 text-dark" >{card.heading}</div>
          <div className="d-flex mt-3" >
            { card.author_image_url ?
              <Image
                src={card.author_image_url ?
                  card.author_image_url :
                  require("assets/images/users/user_no_image.png")}
                onClick={(ev) => handleAuthorClick(ev, card.user_client_id)}
                className="article-pic rounded-circle"
                alt=""
              />
              :
              <Avatar
                  name={card.author_name}
                  round={true}
                  size={"20"}
                  textSizeRatio={1.75}
                  color='#007bb3'
                  maxInitials={1}
                  className = {'article-pic'}
                />
            }

            <div>
              <div className="font-bold font-12 text-dark text-capitalize">{card.author_name}</div>
              <div className="font-12 text-secondary" >{paintUpdateAt(card.updated_at)}</div>
            </div>
            {
              props.showMenuIcon &&
              <div className="ml-auto cursor-pointer">
                <img
                  src={require('assets/images/icons/menu_vertical_icon.svg')}
                  className='client-profile-more-icon px-2'
                  alt="menu-icon"
                  onClick={(e) => paintRank(e, card)}
                />
              </div>
            }
          </div>
        </div>
      </>
    );
  };

  const goBackLink = () => {
    if (props.type === 'suggestedReads') {
      return '/home';
    }
    else if (props.type === 'myArticles')
      return '/profile';
  };

  return(
    <>
      {
        props.stories.map((e, index) => {
          return(
            props.loadedFrom !== "campaignModule" ?
            <div className={`article-outer overflow-hidden mb-3 cursor-pointer ${ props.fromSuccess && 'mr-auto'}`} onClick={() => handleArticleClick(e)} key={index}>
                <div className="article-main-img-wrap">
                  <Image
                    src={e.image_url || e.embedded_image_link}
                    alt=""
                  />
                </div>
                {paintCard(e)}
                {rankComponent.length > 0 && rankComponent[1]}
            </div> :
            <div
              className="article-outer mb-3 cursor-pointer"
              onClick={() => props.handleCampaignIdChange(e.id)}
              key={index}>
                <div className="article-main-img-wrap" >
                  <Image
                    src={e.image_url}
                    alt=""
                  />
                </div>
              {paintCard(e)}
            </div>
          );
        })
      }

      { isEdit &&
        <CreateCampaign
          position='position-fixed'
          isFormOpen={isEdit}
          loadedFrom='CampaignModule'
          editCampaignId={editCampaignId}
          setIsFormOpen={() => setIsEdit(false)}
        />
      }
    </>
  );
};

const Rank = (props) => {
  const setPriority = (e, campId) => {
    const params = {
      priority: e.target.value,
      campign_type_id: props.campType.id
    };

    e.stopPropagation();

    Utility.sendRequest(`${CAMPAIGNS}/${campId}?campaign_type_id=${props.campType.id}&publisher_network_id=${props.userData.client_id}`, 3, params, (err, res, body) => {
      body = JSON.parse(body);

      if (body.error) {
        toast.error(body.error.message, { containerId: 'private' });
      } else {
        props.setRankComponent([]);
        props.loadStories(props.campType.id);
      }
    });
  };

  const makeItHeadline = (e, campId) => {
    const params = {
      campaign_id: campId
    };

    e.stopPropagation();

    if (!props.feed.is_headline) {
      Utility.sendRequest(SET_CAMPAIGN_HEADLINE, 2, params, (err, res, body) => {
        body = JSON.parse(body);

        if (body.error) {
          toast.error(body.error.message, { containerId: 'private' });
        } else {
          props.setRankComponent([]);
          props.loadStories(props.campType.id);
        }
      });
    }
  };

  return (
    <>
      <div className="feed-element p-3 bg-white position-absolute feed-options ">
      {
        (props.campType.id === CAMPAIGN_TYPES_IDS.story || props.campType.id === CAMPAIGN_TYPES_IDS.event) &&
        <div className="shar-form feed-story-form">
          <Form.Control
            as="select"
            onClick={(e) => setPriority(e, props.feed.id)}
            defaultValue={props.feed.priority}>
          <option value="0">Rank {props.campType.name}</option>
          {
            RANKS[props.campType.id].map((item, index) => {
              return (
                <option value={index + 1} key={`${index + 1}rank`}>
                Rank {index + 1}
                </option>
              );
            })
          }
          </Form.Control>
          <div className="radio my-2">
            <label onClick={(e) => makeItHeadline(e, props.feed.id)}>
              <input type="radio" className="mr-2" checked={props.feed.is_headline ? true : false}/>
              Mark as headline
            </label>
            <button
              className="btn btn-primary w-100"
              onClick={(ev) => props.handleEdit(ev, props.feed.id)}>
              Edit
            </button>
          </div>
        </div>
      }
      </div>
    </>
  );
};

const SuggestedReadsCard = withRouter(SuggestedReads);

export { SuggestedReadsCard };
