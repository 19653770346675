import React, { useState, useEffect } from "react";
import { LeftPanel } from "./leftPanel";
import { RightPanel } from "./rightPanel";
import { CLIENT_ID } from "config/constants/config";
import {
  CAMPAIGNS_TOP_STORIES,
  CAMPAIGN_STORIES,
  GET_USERS_BY_NETWORKS,
} from "config/constants/api_endpoints";
import * as Utility from "utils/utility";
import { showLoader } from "shared/actions";
import { connect, useSelector } from "react-redux";
import { toast } from "react-toastify";
import { CAMPAIGN_TYPES_IDS } from "config/constants";
import RegionHeader from "./sidebar/regionHeader";
import RegionSidebar from "./sidebar/regionSidebar";
import WelcomeModal from "../../shared/components/cards/welcome_region";
import { useDispatch } from "react-redux";
import CampaignSelectionForm from "../users/components/create_campaign/campaign_selection_form";

export const Region = (props) => {
  const [courses, setCourses] = useState([]);
  const [events, setEvents] = useState([]);
  const [news, setNews] = useState([]);
  const [user, setUsers] = useState([]);
  const [displayData, setDisplayData] = useState(courses ? courses : []);
  const [openCreate, setOpenCreate] = useState(false);
  const [discussionsData, setDiscussionsData] = useState({
    news: "",
    courses: "",
  });
  const [displayTitle, setDisplayTitle] = useState("");
  const [selectedItem, setSelectedItem] = useState("");
  const [panel, setPanel] = useState(0);
  const [neighbourhoods, setNeighbourhoods] = useState([]);
  const [selectedIcon, setSelectedIcon] = useState(0);
  const [selectedDiscussion, setSelectedDiscussion] = useState(0);

  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const userData = useSelector((state) => state.userData);
  const dispatch = useDispatch();

  useEffect(() => {
    getAllCourses();
    loadStories(CAMPAIGN_TYPES_IDS.story);
    loadStories(CAMPAIGN_TYPES_IDS.event);
    getUserDetails();
    displayPanel();
    handleOpen();
  }, []);

  const isCampaignFormOpen = useSelector((state) => state.isCampaignFormOpen);

  const location = useSelector((state) => state.setLocationData);

  const loadStories = (campTypeId, categoryId = "") => {
    const params = {
      publisher_network_id: CLIENT_ID,
      type: campTypeId,
      category_id: categoryId,
    };

    Utility.sendRequest(CAMPAIGN_STORIES, 1, params, (err, res, body) => {
      body = JSON.parse(body);

      if (body.error) {
        toast.error(body.error.message, { containerId: "private" });
      } else {
        const Data = body.data.filter(
          (items) =>
            items.corporate_is_active === true || items.corporate_is_active === null
        );
        if (CAMPAIGN_TYPES_IDS.event === campTypeId) setEvents(Data);
        else if (CAMPAIGN_TYPES_IDS.story === campTypeId) {
          setNews(Data);
          if (panel === 0) {
            let temp = discussionsData;
            temp.news = Data;
            setDiscussionsData(temp);
          }
        }
      }
    });
  };

  const getUserDetails = () => {
    const params = {
      client_id: CLIENT_ID,
      type: "all",
      forCommunity: true,
    };

    dispatch(showLoader(true));
    Utility.sendRequest(GET_USERS_BY_NETWORKS, 1, params, (err, res, body) => {
      body = JSON.parse(body);

      dispatch(showLoader(false));
      if (body.error) {
        toast.error(body.error.message, { containerId: "private" });
      } else {
        if (
          body.registrants &&
          CLIENT_ID === "0bc8dc7c-b5a3-4344-b313-819fc640f6b5"
        ) {
          const userList = [];
          const neighbourhood = [];
          body.registrants.sort(compare).forEach((element) => {
            if (element.network === "users") userList.push(element);
            else neighbourhood.push(element);
          });
          setUsers(userList);
          setNeighbourhoods(neighbourhood);
        } else {
          if (body.registrants) setUsers(body.registrants);
        }
      }
    });
  };

  function compare(a, b) {
    if (a.user_name < b.user_name) {
      return -1;
    }
    if (a.user_name > b.user_name) {
      return 1;
    }
    return 0;
  }

  const getAllCourses = () => {
    const params = {
      publisher_network_id: CLIENT_ID,
      is_course: true,
    };
    dispatch(showLoader(true));
    Utility.sendRequest(CAMPAIGNS_TOP_STORIES, 1, params, (err, res, body) => {
      body = JSON.parse(body);
      dispatch(showLoader(false));
      if (body.error) {
        toast.error(body.error.message, { containerId: "private" });
      } else {
        console.log("CAMPAIGNS_TOP_STORIES 4");
        setCourses(body);
        if (panel === 1) setDisplayData(body);
        if (panel === 0) {
          let temp = discussionsData;
          temp.courses = body;
          setDiscussionsData(temp);
        }
      }
    });
  };

  useEffect(() => {
    displayPanel();
  }, []);

  useEffect(() => {
    displayPanel();
  }, [panel]);

  const displayPanel = () => {
    switch (panel) {
      case 0:
        setDiscussionsData({ news: news, courses: courses });
        setDisplayData([]);
        setDisplayTitle("Discussions");
        break;
      case 1:
        setDisplayData(courses);
        setDisplayTitle("Courses");
        break;
      case 2:
        setDisplayData(news);
        setDisplayTitle("News");
        break;
      case 3:
        setDisplayData(events);
        setDisplayTitle("Events");
        break;
      case 4:
        setDisplayData(user);
        setDisplayTitle("Community");
        break;
    }
  };

  return (
    <>
      {isCampaignFormOpen && (
        <CampaignSelectionForm
          position="position-fixed create-campaign-margin"
          userData={userData}
          setSelectedItem={setSelectedItem}
          selectedItem={selectedItem}
        />
      )}
      <div className="d-flex flex-row mt-5 mt-3 ml-3 mr-3 min-h-100">
        <RegionHeader
          url={require("assets/images/icons/region-Icon.png")}
          location={location.region || localStorage.getItem("region") || ""}
          region={location.country || localStorage.getItem("country") || ""}
        />
        <div className="d-flex flex-column">
          <RegionSidebar setSelectedIcon={setPanel} />
        </div>
        {panel === 0 && (
          <LeftPanel
            title={displayTitle}
            data={[
              {
                id: 0,
                heading: location.region || localStorage.getItem("region"),
              },
            ]}
            history={props.history}
            setSelectedIcon={setSelectedIcon}
            setSelectedDiscussion={setSelectedDiscussion}
            selectedDiscussion={selectedDiscussion}
            setOpenCreate={setOpenCreate}
            count={20}
          />
        )}
        <RightPanel
          title={displayTitle}
          data={displayData}
          neighbourhoods={neighbourhoods}
          discussionsData={discussionsData}
          history={props.history}
          panel={panel}
          setSelectedIcon={setSelectedIcon}
          selectedIcon={selectedIcon}
          setSelectedItem={setSelectedItem}
          openCreate={openCreate}
          setOpenCreate={setOpenCreate}
          location={location.region || localStorage.getItem("region") || ""}
          region={location.country || localStorage.getItem("country") || ""}
        />
        <WelcomeModal
          handleOpen={handleOpen}
          handleClose={handleClose}
          open={open}
          setOpen={setOpen}
          location={location.region || localStorage.getItem("region") || ""}
          region={location.country || localStorage.getItem("country") || ""}
        />
      </div>
    </>
  );
};

const mapActionToProps = {
  showLoader,
};

const mapStateToProps = () => ({});

export default connect(mapStateToProps, mapActionToProps)(Region);
