import React from "react";
import CourseCard from "../../../profile/components/CourseCard";
import { Spinner } from "react-bootstrap";
import NewsCard from "../../../profile/components/NewsCard";

const DiscussionsNews = (props) => {
  const data = props.data.filter(({ heading }) =>
    heading.toLowerCase().includes(props.search.toLowerCase())
  );
  const goBackLink = () => {
    if (props.type === "suggestedReads") {
      return "/home";
    } else if (props.type === "myArticles") return "/profile";
  };

  const handleArticleClick = (data) => {
    props.history.push({
      pathname: `/campaign/${data.parent_id || data.id}`,
      calledFrom: props.type,
      goBackLink: goBackLink(),
    });
  };
  return (
    <div className="d-flex flex-wrap justify-content-between pl-3 pr-5 pt-3 ">
      {data.length > 0 ? (
        data ? (
          data.map((data, index) => (
            <div className=" my-3" key={data.id}>
              <NewsCard
                onClick={() => handleArticleClick(data)}
                key={data.id}
                data={data}
                Totalcourses={props.feeds}
                index={index}
                isVertical={true}
              />
            </div>
          ))
        ) : (
          <Spinner animation="border" size="sm" role="status" />
        )
      ) : (
          <h4 className="m-3 mx-auto">No record found</h4>
      )}
    </div>
  );
};

export default DiscussionsNews;
