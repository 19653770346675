import React, { Fragment, useEffect, useState } from "react";
import RegionCard from "../Regions/region_card";
import { CustomSlider } from "../../../../../shared/components/Slider";
import Region_page from "../Regions/Region_page";
import { Utility } from "../../../../../utils";
import { toast } from "react-toastify";
import {
  GET_WEA_LIST,
  GET_REGIONS_LIST,
} from "../../../../../config/constants/api_endpoints";
import GetAllSubCategories from "../Regions/getAllSubCategories";
import { BiArrowBack } from "react-icons/bi";
import { Grid,Skeleton } from "@mui/material";

const Community_page = () => {
  const [borderState, setBorderState] = useState();
  const [selectedTag, setSelectedTag] = useState("");
  // const [id, setId] = useState();
  const handleSelect = (tag, text) => {
    setSelectedTag(tag);
    setBorderState(text);
  };
  // const [Organization, setOrganization] = useState([]);
  // const [Topics, setTopics] = useState([]);
  // const [Wea, setWea] = useState([]);
  // const [region, setRegions] = useState([]);
  const [communityLabel, setCommunityLabel] = useState({
    communitText: "Community",
    regionId: 1,
  });
  const [communities, setCommunities] = useState({
    regions: { data: [], loading: true },
    organisations: { data: [], loading: true },
    topics: { data: [], loading: true },
    wea: { data: [], loading: true },
  });

  const getAllRegions = () => {
    Utility.sendRequest(GET_REGIONS_LIST, 1, {}, (err, response, body) => {
      body = JSON.parse(body);
      if (!body.error) {
        communities["regions"] = {
          data: body.data,
          loading: false,
        };
        setCommunities({ ...communities });
      } else {
        toast.error(JSON.parse(body).error.message, {
          containerId: "private",
        });
      }
    });
  };

  const getWeaList = (tag_type, type) => {
    Utility.sendRequest(
      GET_WEA_LIST,
      1,
      { tag_type: tag_type },
      (err, response, body) => {
        body = JSON.parse(body);
        if (!body.error) {
          communities[type] = {
            data: body.data,
            loading: false,
          };
          setCommunities({ ...communities });
        } else {
          toast.error(JSON.parse(body).error.message, {
            containerId: "private",
          });
        }
      }
    );
  };

  useEffect(() => {
    getWeaList("wea_tag", "wea");
    getWeaList("organisation_tag", "organisations");
    getWeaList("topic_tag", "topics");
    getAllRegions();
  }, []);
  return (
    <div
      className="regionPage pl-0 pt-5 position-relative"
      style={{ backgroundColor: "#F5F5F5", bottom: "30px" }}
    >
      {(selectedTag === "Regions" || selectedTag === "") && (
        <>
          <div
            className={`d-flex flex-row pr-3 ${selectedTag === "" && "pl-5"}`}
          >
            {selectedTag && (
              <div
                className="pl-5 cursor-pointer pr-2 pt-2"
                onClick={() => {
                  setSelectedTag("");
                  setBorderState("");
                }}
              >
                <BiArrowBack size={25} />{" "}
              </div>
            )}
            <div className="font-24-bold mb-3 mb-lg-4 ">Regions:</div>
          </div>

          <CustomSlider>
            {communities.regions.loading ? (
              <div className="d-flex justify-content-around">
              {[1, 2, 3, 4, 5, 6, 7].map((item) => (
                <div className="m-2" key={item}>
                  <Skeleton
                    variant="circular" // Corrected variant value to "circle"
                    width={150}
                    height={150}
                  />
                </div>
              ))}
            </div>
            ) : (
              <div className="d-flex flex-row pl-2 community-tab-design">
                {/* <div
                className="circleImage shadow bg-white m-2"
                // style={{
                //   border: borderState === "Community" ? "4px solid grey" : "",
                //   opacity: borderState == "Community" ? 1 : 0.5,
                //   cursor: "pointer",
                // }}
                // onClick={() => {
                //   setSelectedTag("Regions");
                //   setBorderState("Community");
                //   setCommunityLabel({
                //     communitText: "Community",
                //     regionId: 1,
                //   });
                // }}
              >
                <img
                  className="imageRegion text-center"
                  src={require("assets/images/icons/region-Icon.png")}
                />
              </div> */}
                {communities.regions.data.map((item) => (
                  <Fragment key={item}>
                    <RegionCard
                      tag="Regions"
                      colour={item.color}
                      text={item.name}
                      text_colour={item.color && item.color.split(" ")[0]}
                      borderState={borderState}
                      handleSelect={handleSelect}
                      setCommunityLabel={setCommunityLabel}
                      id={item.id}
                    />
                  </Fragment>
                ))}
              </div>
            )}
          </CustomSlider>
        </>
      )}
      {(selectedTag === "Organization" || selectedTag === "") && (
        <>
          <div
            className={`d-flex flex-row pr-3 ${selectedTag === "" && "pl-5"}`}
          >
            {selectedTag && (
              <div
                className="pl-5 cursor-pointer pr-2 pt-2"
                onClick={() => {
                  setSelectedTag("");
                  setBorderState("");
                }}
              >
                <BiArrowBack size={25} />{" "}
              </div>
            )}
            <div className="font-24-bold mb-3 mb-lg-4">Organizations:</div>
          </div>

          <CustomSlider>
            {communities.organisations.loading ? (
              <div class="d-flex flex-row justify-content-around">
                {[1, 2, 3, 4, 5, 6, 7].map((item) => (
                   <div className="m-2" key={item}>
                   <Skeleton
                     variant="circular" // Corrected variant value to "circle"
                     width={150}
                     height={150}
                   />
                 </div>
                ))}
              </div>
            ) : (
              <div className="d-flex flex-row pl-2 community-tab-design">
                {/* <div
                className="circleImage shadow bg-white m-2"
                // style={{
                //   border:
                //     borderState === "Organization" ? "4px solid grey" : "",
                //   opacity: borderState == "Organization" ? 1 : 0.5,
                //   cursor: "pointer",
                // }}
                // onClick={() => {
                //   setSelectedTag("Organization");
                //   setBorderState("Organization");
                //   setCommunityLabel({
                //     communitText: "Organization",
                //     regionId: 1,
                //   });
                // }}
              >
                <img
                  className="imageRegion text-center"
                  src={require("assets/images/icons/region-Icon.png")}
                />
              </div> */}
                {communities.organisations.data.map((item) => (
                  <Fragment key={item}>
                    <RegionCard
                      colour={item.color}
                      text={item.name}
                      text_colour={item.color && item.color.split(" ")[0]}
                      tag="Organization"
                      handleSelect={handleSelect}
                      borderState={borderState}
                      // handleSelect={handleSelect}
                      setCommunityLabel={setCommunityLabel}
                      id={item.id}
                    />
                  </Fragment>
                ))}
              </div>
            )}
          </CustomSlider>
        </>
      )}
      {(selectedTag === "Topics" || selectedTag === "") && (
        <>
          <div
            className={`d-flex flex-row pr-3 ${selectedTag === "" && "pl-5"}`}
          >
            {selectedTag && (
              <div
                className="pl-5 cursor-pointer pr-2 pt-2"
                onClick={() => {
                  setSelectedTag("");
                  setBorderState("");
                }}
              >
                <BiArrowBack size={25} />{" "}
              </div>
            )}
            <div className="font-24-bold mb-3 mb-lg-4">Topics:</div>
          </div>

          <CustomSlider>
            {communities.topics.loading ? (
              <div class="d-flex flex-row justify-content-around">
                {[1, 2, 3, 4, 5, 6, 7].map((item) => (
                   <div className="m-2" key={item}>
                   <Skeleton
                     variant="circular" // Corrected variant value to "circle"
                     width={150}
                     height={150}
                   />
                 </div>
                ))}
              </div>
            ) : (
              <div className="d-flex flex-row pl-2 community-tab-design">
                {/* <div
                className="circleImage shadow bg-white m-2"
                // style={{
                //   border: borderState === "Topics" ? "4px solid grey" : "",
                //   opacity: borderState == "Topics" ? 1 : 0.5,
                //   cursor: "pointer",
                // }}
                // onClick={() => {
                //   setSelectedTag("Topics");
                //   setBorderState("Topics");
                //   setCommunityLabel({
                //     communitText: "Topics",
                //     regionId: 1,
                //   });
                // }}
              >
                <img
                  className="imageRegion text-center"
                  src={require("assets/images/icons/region-Icon.png")}
                />
              </div> */}
                {communities.topics.data.map((item) => (
                  <Fragment key={item}>
                    <RegionCard
                      tag="Topics"
                      colour={item.color}
                      text={item.name}
                      text_colour={item.color && item.color.split(" ")[0]}
                      handleSelect={handleSelect}
                      borderState={borderState}
                      // handleSelect={handleSelect}
                      setCommunityLabel={setCommunityLabel}
                      id={item.id}
                    />
                  </Fragment>
                ))}
              </div>
            )}
          </CustomSlider>
        </>
      )}
      {(selectedTag === "Wea" || selectedTag === "") && (
        <>
          <div
            className={`d-flex flex-row pr-3 ${selectedTag === "" && "pl-5"}`}
          >
            {selectedTag && (
              <div
                className="pl-5 cursor-pointer pr-2 pt-2"
                onClick={() => {
                  setSelectedTag("");
                  setBorderState("");
                }}
              >
                <BiArrowBack size={25} />{" "}
              </div>
            )}
            <div className="font-24-bold mb-3 mb-lg-4 ">WEA:</div>
          </div>

          <CustomSlider>
            {communities.wea.loading ? (
              <div class="d-flex flex-row justify-content-around">
              {[1, 2, 3, 4, 5, 6, 7].map((item) => (
                 <div className="m-2" key={item}>
                 <Skeleton
                   variant="circular" // Corrected variant value to "circle"
                   width={150}
                   height={150}
                 />
               </div>
              ))}
            </div>
            ) : (
              <div className="d-flex flex-row pl-2 community-tab-design">
                {/* <div
                className="circleImage shadow bg-white m-2"
                // style={{
                //   border: borderState === "Wea" ? "4px solid grey" : "",
                //   opacity: borderState == "Wea" ? 1 : 0.5,
                //   cursor: "pointer",
                // }}
                // onClick={() => {
                //   setSelectedTag("Wea");
                //   setBorderState("Wea");
                //   setCommunityLabel({
                //     communitText: "Wea",
                //     regionId: 1,
                //   });
                // }}
              >
                <img
                  className="imageRegion text-center"
                  src={require("assets/images/icons/region-Icon.png")}
                />
              </div> */}
                {communities.wea.data.map((item) => (
                  <Fragment key={item}>
                    <RegionCard
                      tag="Wea"
                      colour={item.color}
                      text={item.name}
                      text_colour={item.color && item.color.split(" ")[0]}
                      handleSelect={handleSelect}
                      borderState={borderState}
                      // handleSelect={handleSelect}
                      setCommunityLabel={setCommunityLabel}
                      id={item.id}
                    />
                  </Fragment>
                ))}
              </div>
            )}
          </CustomSlider>
        </>
      )}
      {selectedTag === "Regions" && (
        <Region_page communityLabel={communityLabel} />
      )}
      {(selectedTag === "Organization" ||
        selectedTag === "Topics" ||
        selectedTag === "Wea") && (
        <GetAllSubCategories
          communityLabel={communityLabel}
          selectedTag={selectedTag}
        />
      )}
    </div>
  );
};

export default Community_page;
