import React, { useEffect, useState } from "react";
import { TickFilledIcon } from "shared/icons/icons";
import { Dropdown, Button, Form } from "react-bootstrap";
import { CircleOutlinedAddIcon, ChevronDownIcon } from "shared/icons/icons";
import QuestionsList from "./question_list";
import { CloseIcon } from "shared/icons/icons";
import { Utility } from "utils";
import { InputField } from "shared/components/form_elements";
import { IconButton } from "@mui/material";
import { Close } from "@mui/icons-material";
import { Spinner } from "react-bootstrap";

export default function CreateAssignments(props) {
  const [lessons, setLessons] = useState([...props.lessonList]);

  return (
    <div className="w-100 create-assignments-modal">
      <div className="d-flex justify-content-between border-bottom align-items-center">
        <div className="align-items-center font-weight-bold font-18 my-4">
          <CircleOutlinedAddIcon mainClass="mr-3" />{" "}
          {props.edit_assignment ? " Edit Assignment" : "Create Assignment"}
        </div>
        <div className="cursor-pointer" onClick={props.openAssignment}>
          <CloseIcon />
        </div>
      </div>
      <Form className="shar-form search-form search-form-pwa sidebar-form py-4">
        <div className="d-flex justify-content-between">
          <div>
            <Form.Group className="mb-2 pb-1">
              <Form.Label className="font-bold display-8 mb-3">
                {props.campaign_id ? "Selected Lesson" : "Select Lesson"}
              </Form.Label>
              {props.campaign_id ? (
                <div className="w-50 d-flex justify-content-between align-items-center">
                  <p className="name-assignmentheading mb-0">
                    {lessons &&
                      !Utility.isEmpty(props.campaign_id) &&
                      lessons.find((e) => {
                        return e.id === props.campaign_id ? e.heading : "";
                      }) &&
                      lessons.find((e) => {
                        return e.id === props.campaign_id ? e.heading : "";
                      }).heading}
                  </p>
                  <IconButton onClick={() => props.handleCampaignId("")}>
                    <Close />
                  </IconButton>
                </div>
              ) : (
                <Dropdown className="article-dropdown course-categories-dropdown form-control py-0">
                  <Dropdown.Toggle
                    variant="none"
                    className="py-0 text-left mb-0 ml-0 px-0 align-items-center justify-content-between w-100"
                    id="category_id"
                  >
                    {lessons && !Utility.isEmpty(props.campaign_id)
                      ? lessons.map((e) => {
                          return e.id === props.campaign_id ? e.heading : "";
                        })
                      : "Select Lesson"}
                    <ChevronDownIcon />
                  </Dropdown.Toggle>
                  <Dropdown.Menu className="position-relative border-0 mt-0 pt-0 float-none">
                    {lessons &&
                      lessons
                        .filter((item) => {
                          return (
                            !props.assignments.some(
                              (dataValue) => item.heading === dataValue.lesson
                            ) || item.id === props.edit_assignment_campId
                          );
                        })
                        .sort((a, b) => {
                          return a.seq_id - b.seq_id;
                        })
                        .map((e) => {
                          return (
                            <Dropdown.Item
                              key={e.id}
                              id="lesson_id"
                              name="lesson_id"
                              eventKey={["lesson_id", e.id]}
                              onSelect={props.handleDropdown}
                            >
                              {e.heading}
                            </Dropdown.Item>
                          );
                        })}
                  </Dropdown.Menu>
                  <div
                    className="error errorIcon mb-3"
                    id={"category_idError"}
                  />
                </Dropdown>
              )}
              <p className="mx-2 text-danger mb-0">{props.lesson_name_error}</p>
            </Form.Group>
            <Form.Group className="mb-2 pb-1">
              <Form.Label className="font-bold display-8 mb-3">
                Add Assignment Title
              </Form.Label>
              <InputField
                type="text"
                placeholder="Assignments Title Goes Here"
                value={props.assignment_name}
                handleChange={props.setAssignment}
              />
              <p className="mx-2 text-danger mb-0">
                {props.assignment_name_error}
              </p>
            </Form.Group>

            <div className="d-flex justify-content-between">
              <div>
                <div className="msq-activator mb-4">
                  <InputField
                    type="text"
                    placeholder="Add Essay Question"
                    readonly={true}
                  />
                  <CircleOutlinedAddIcon
                    mainClass="ml-2"
                    handleClick={() => props.openQuiz(3)}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
        {props.openQuizForm && (
          <div className="mcq-set mb-5">
            <div className="text-primary-light font-16 font-weight-bold">
              {props.question_type_name}
            </div>
            <div className="ml-2 my-4">
              <InputField
                type="text"
                placeholder="What is the Question?"
                value={props.question_title}
                handleChange={(e) => props.questionChange(e)}
              />
              <p className="mx-2 text-danger mb-0">
                {props.question_name_error}
              </p>
            </div>
            <div className="ml-2">
              {props.question_type_name === "Essay" ? (
                <div className="font-16 mb-4">
                  Add a question for{" "}
                  <span className="text-primary-light font-weight-bold">
                    essay.
                  </span>
                </div>
              ) : (
                <div className="font-16 mb-4">
                  Check the{" "}
                  <span className="text-primary-light font-weight-bold">
                    Correct Answer
                  </span>
                </div>
              )}
              <div className="mcq-set-questions">
                {props.question_options_attributes &&
                  props.question_type_name !== "Essay" &&
                  props.question_options_attributes.map((item, i) => (
                    <div
                      className="d-flex flex-nowrap align-items-center mb-4"
                      key={`key_${i}`}
                    >
                      <label className="cursor-pointer mb-0">
                        <input
                          type="checkbox"
                          className="toolbox-card-checkbox"
                          required
                          checked={
                            props.question_options_attributes[i].is_correct
                          }
                          onClick={(ev) => props.onToggleClick(ev, i)}
                        />
                        <div className="toolbox-card-checkbox-outer">
                          <TickFilledIcon fillClass="fill-primary" />
                        </div>
                      </label>
                      <div className="font-weight-bold font-18 mx-3">
                        {i + 1}.
                      </div>
                      <InputField
                        required={true}
                        type="text"
                        placeholder="answer goes here"
                        handleChange={(e) => props.handleChange(e, i)}
                        value={
                          props.question_options_attributes[i].option_value
                        }
                      />
                    </div>
                  ))}
                <p className="mx-2 text-danger mb-0">
                  {props.question_correct_answer_error}
                </p>
                <p className="mx-2 text-danger mb-0">
                  {props.question_answer_error}
                </p>
              </div>
              <div className="d-flex flex-column align-items-center">
                <Button
                  disabled={props.isSavingQuiz}
                  variant="primary"
                  className="my-4 font-weight-bold"
                  onClick={props.submitQuiz}
                >
                  {props.isSavingQuiz ? (
                    <Spinner animation="border" role="status" />
                  ) : (
                    "Save"
                  )}
                </Button>
                <Button
                  className="btn btn-link"
                  variant="link"
                  onClick={props.closeQuiz}
                >
                  Cancel
                </Button>
              </div>
            </div>
          </div>
        )}
        <p className="mx-2 text-danger mb-0">{props.question_error}</p>
        {props.question_lists.length > 0 && (
          <QuestionsList
            {...props}
            DeleteQuestion={props.deleteQuiz}
            editQuiz={props.editQuiz}
            deletingQuiz={props.isDeletingAssignment}
          />
        )}
        <div className="text-center">
          <Button
            disabled={props.isSavingAssignment}
            variant="primary"
            className="my-4 font-weight-bold px-4 py-3"
            onClick={props.saveAssignment}
          >
            {props.isSavingAssignment ? (
              <Spinner animation="border" role="status" />
            ) : (
              "SAVE ALL CHANGES"
            )}
          </Button>
        </div>
      </Form>
    </div>
  );
}
