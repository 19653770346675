import React, { useState, useEffect } from "react";
import { toast } from "react-toastify";
import { useSelector } from "react-redux";
import * as Utility from "utils/utility";
import { CLIENT_ID } from "config/constants/config";
import { CAMPAIGN_TYPES_IDS } from "config/constants";
import {
  showLoader,
  updateSeeAll,
  updateIsChatFormOpen,
  updateChatFriend,
} from "shared/actions";
import { connect } from "react-redux";

import CourseContainerCard from "../CourseContainer";
// import Create_course from "../../cards/create_course";
import CampaignSelectionForm from "../../../../scenes/users/components/create_campaign/campaign_selection_form";
import { useDispatch } from "react-redux";
import { CAMPAIGN_STORIES } from "config/constants/api_endpoints";
import {
  REGIONS_LIST,
  GET_WEA_LIST,
} from "../../../../config/constants/api_endpoints";
import { DropDown } from "../../form_elements";
import { AiFillCloseCircle } from "react-icons/ai";
// import { GrSearch } from "react-icons/gr";
import { Button } from "react-bootstrap";
import { Grid, Skeleton } from "@mui/material";
import ProductPagination from "../campaign/components/ProductPagination";
// import WeaFooter from "../../../../config/WeaFooter";
// import { CustomSlider } from "../../../../shared/components/Slider";
function News(props) {
  const [flag, setFlag] = useState(true);
  const userData = useSelector((state) => state.userData);
  const refreshCampaignApi = useSelector((state) => state.refreshCampaignApi);
  const [campaignsTopStories, setCampaignsTopStories] = useState([]);
  // const [filteredTopStories, setFilteredTopStories] = useState([]);
  // const [campaignsFeaturedStories, setCampaignsFeaturedStories] = useState([]);
  // const [filteredFeaturedStories, setFilteredFeaturedStories] = useState([]);
  const [search, setSearch] = useState("");
  const [categoriesList, setCategoriesList] = useState([]);
  const [topicOptions, setTopicOptions] = useState([]);
  const [selectedItem, setSelectedItem] = useState("");
  const [joinedNeighborhood, setJoinedNeighborhood] = useState([]);
  const [isFilterApplied, setIsFilterApplied] = useState(false);
  const [region, setRegion] = useState("");
  const [topic, setTopic] = useState("");
  const [isOptionsSelected, setOptionSelected] = useState(true);
  const [loading, setLoading] = useState(false);
  const [page, setPage] = useState(1);
  const [totalPage, setTotalPage] = useState(1);
  let debouncedFn = null;

  // useEffect(() => {
  //   props.userData.user_id && getJoinednaighbouHoods();
  // }, [props.userData.user_id]);
  // const dispatch = useDispatch();

  // API for getting featured/All courses, news, events
  const loadAllStories = async ({
    user_client_id,
    campTypeId,
    isFeatured,
    userId,
    start_at,
    end_at,
    region_id,
    topic_id,
    get_public_private,
  }) => {
    // dispatch(showLoader(true));
    setLoading(true);
    const params = {
      publisher_network_id: CLIENT_ID,
      type: campTypeId,
      is_featured: isFeatured,
      user_id: userId,
      start_at: start_at,
      end_at: end_at,
      region_id: region_id,
      topic_id: topic_id,
      get_public_private: get_public_private,
      limit: 10,
      page_number: page,
      search: search,
    };
    await Utility.sendRequest(CAMPAIGN_STORIES, 1, params, (err, res, body) => {
      body = JSON.parse(body);
      setLoading(false);
      // dispatch(showLoader(true));
      if (body.error) {
        toast.error(body.error.message, { containerId: "private" });
        // dispatch(showLoader(false));
      } else {
        const data = body.data.res.filter(
          (items) =>
            items.corporate_is_active === true ||
            items.corporate_is_active === null
        );

        setTotalPage(body.data.total_pages);
        setCampaignsTopStories(data);
        // setFilteredTopStories(Data);
        // dispatch(showLoader(false));
      }
    });
  };
  const handleCloseFilter = () => {
    const campTypeId = CAMPAIGN_TYPES_IDS.story;
    setIsFilterApplied(false);
    loadAllStories({
      campTypeId: campTypeId,
      userId: userData.user_id,
      get_public_private: true,
    });
    setRegion("");
    setTopic("");
  };
  const getRegions = () => {
    const params = {};
    Utility.sendRequest(REGIONS_LIST, 1, params, (err, res, body) => {
      body = JSON.parse(body);
      if (body.error) {
        toast.error(body.error.message, { containerId: "private" });
      } else {
        setCategoriesList(body.data);
      }
    });
  };
  const handleFilters = () => {
    setIsFilterApplied(true);
    const campTypeId = CAMPAIGN_TYPES_IDS.story;
    loadAllStories({
      campTypeId: campTypeId,
      region_id: region,
      topic_id: topic,
      userId: userData.user_id,
      get_public_private: true,
    });
  };
  // const accounts = useSelector((state) => state.accountList);
  useEffect(() => {
    getCampaignsTopStoriesDetails();
  }, [refreshCampaignApi]);

  useEffect(() => {
    getRegions();
    getTopics();
  }, []);

  // const goBackLink = () => {
  //   //props.updateSeeAll({ isOpen: props.type, calledFrom: props.type });
  //   return `/topics/${props.category_name}`;
  // };
  const getTopics = () => {
    Utility.sendRequest(
      GET_WEA_LIST,
      1,
      { tag_type: "topic_tag" },
      (err, response, body) => {
        body = JSON.parse(body);
        if (!body.error) {
          setTopicOptions(body.data);
        } else {
          toast.error(JSON.parse(body).error.message, {
            containerId: "private",
          });
        }
      }
    );
  };
  const getCampaignsTopStoriesDetails = () => {
    const campTypeId = CAMPAIGN_TYPES_IDS.story;
    loadAllStories({
      campTypeId: campTypeId,
      userId: userData.user_id,
      get_public_private: true,
    });
    // loadAllStories({
    //   user_client_id: userData.id,
    //   campTypeId: campTypeId,
    //   isFeatured: true,
    //   userId: userData.id,

    // });
  };
  useEffect(() => {
    if (region === "" && topic === "") {
      setOptionSelected(true);
    } else {
      setIsFilterApplied(false);
      setOptionSelected(false);
    }
  }, [region, topic]);
  const handleRegionChange = (event) => {
    setRegion(event.target.value);
  };
  const handleTopicChange = (event) => {
    setTopic(event.target.value);
  };

  useEffect(() => {
    loadAllStories({
      campTypeId: CAMPAIGN_TYPES_IDS.story,
      userId: userData.user_id,
      get_public_private: true,
    });
  }, [page, search]);

  // const handelInfiniteScroll = async () => {
  //   console.log("scrollHeight" + document.documentElement.scrollHeight);
  //   console.log("innerHeight" + window.innerHeight);
  //   console.log("scrollTop" + document.documentElement.scrollTop);
  //   try {
  //     if (
  //       window.innerHeight + document.documentElement.scrollTop + 1 >=
  //       document.documentElement.scrollHeight
  //     ) {
  //       // setLoading(true);
  //       setPage((prev) => prev + 1);
  //     }
  //   } catch (error) {
  //     console.log(error);
  //   }
  // };

  const handlePageNumberChange = (value) => {
    setPage(value);
  };

  const handleSearch = (ev) => {
    ev.persist();

    if (!debouncedFn) {
      debouncedFn = Utility.debounce(() => {
        const searchString = ev.target.value;
        setSearch(searchString);
      }, 500);
    }
    debouncedFn();
  };

  // useEffect(() => {
  //   window.addEventListener("scroll", handelInfiniteScroll, true);
  //   return () =>
  //     window.removeEventListener("scroll", handelInfiniteScroll, true);
  // }, []);

  return (
    <>
      <div
        className="pwa-callout-body pl-1 pt-4 courses-page-container pb-2 newsLeftPadding"
        // onScroll={onScroll}
      >
        {selectedItem === "news" && (
          <CampaignSelectionForm
            position="position-fixed create-campaign-margin"
            userData={userData}
            setSelectedItem={setSelectedItem}
            selectedItem={selectedItem}
          />
        )}
        <>
          <div className="d-flex w-100 pl-3 mt-4">
            <div
              className={`pt-3 d-flex align-items-center mb-3 mb-lg-4  pl-4 pl-md-4  pr-0 w-25`}
            >
              <img
                src={require("assets/images/icons/courses.png")}
                // height={20}
                width={22}
              />
              <span className="ml-2 font-24-bold">News</span>
            </div>
            {/* {accounts && accounts.Clients && accounts.Clients.length > 0 && (
              <div className="w-75 d-flex justify-content-end mr-4 mt-4">
                <Create_course
                  setSelectedItem={() => void 0}
                  heading={"NEWS"}
                  item="news"
                  onClick={() => props.history.push("/create-article")}
                />
              </div>
            )} */}
          </div>
          <div className="d-flex justify-content-between align-items-center w-100">
            <div className="w-100 searchBar-news">
              {/* <div className="SeactionSearchContainer w-100 position-relative pl-4">
                <div className="searchSectionWrapper bg-white mb-5">
                  <input
                    className="SeactionSearch"
                    placeholder={`Search News`}
                    onChange={(ev) => setSearch(ev.target.value)}
                  />

                  <span className="SeactionSearchIcon">
                    <GrSearch className="regionIcon" />
                  </span>
                </div>
              </div> */}
              <div className="SeactionSearchContainer d-flex position-relative">
                <DropDown
                  className="news-filter-dropdown cursor-pointer"
                  placeholder="Region"
                  optionId="id"
                  optionValue="name"
                  required={true}
                  name="region"
                  // ref={region}
                  value={region}
                  options={[
                    {
                      id: "",
                      name: "Select Region",
                    },
                    ...categoriesList,
                  ]}
                  handleChange={handleRegionChange}
                />
                <DropDown
                  className="news-filter-dropdown  cursor-pointer"
                  placeholder="Topic"
                  optionId="id"
                  optionValue="name"
                  required={true}
                  name="topic"
                  // ref="category_id"
                  options={[
                    {
                      id: "",
                      name: "Select Topic",
                    },
                    ...topicOptions,
                  ]}
                  value={topic}
                  handleChange={handleTopicChange}
                />
                {!isFilterApplied ? (
                  <Button
                    className="delete-doc-modal-btn mx-3"
                    disabled={isOptionsSelected}
                    variant="primary"
                    onClick={handleFilters}
                  >
                    Filter
                  </Button>
                ) : (
                  <AiFillCloseCircle
                    className="text-danger news-icon-cancel"
                    size={50}
                    onClick={handleCloseFilter}
                  />
                )}
                <div className="news-filter w-25">
                  <input
                    className="SeactionSearch-news-filter"
                    placeholder={`Search News....`}
                    // onChange={(ev) => setSearch(ev.target.value)}
                    onChange={(ev) => handleSearch(ev)}
                  />

                  {/* <span className="SeactionSearchIcon">
                    <GrSearch className="regionIcon"  />
                  </span> */}
                </div>
              </div>
            </div>
          </div>
          <>
            {/* {flag && !props.loading && (
              <div className="d-flex align-items-center justify-content-center margin my-55">
                <div className="px-5 py-5">
                  <span className="font-bold display-6">No Records Found</span>
                </div>
              </div>
            )} */}
            <div className="mt-4">
              {/* <CourseContainerCard
                courseData={campaignsFeaturedStories}
                PanalTitle={"Featured News"}
                category="featured"
                setFlag={setFlag}
                type={"news"}
                categoriesList={categoriesList}
                isArticles={true}
                search={search.toLowerCase()}
                joinedNeighborhood={joinedNeighborhood}
              /> */}
              {loading ? (
                <Grid
                  container
                  spacing={0}
                  // columns={{ xs: 12, sm: 6, md: 6, lg: 4, xl: 3 }}
                  className="py-2 px-4 d-flex"
                  justifyContent="center"
                  alignItems="center"
                >
                  {[1, 2, 3, 4, 5, 6].map((item, index) => (
                    <Grid
                      item
                      xs={12}
                      sm={12}
                      md={12}
                      lg={6}
                      xl={6}
                      key={index}
                      className="py-2"
                    >
                      <Skeleton
                        variant="rounded"
                        width={540}
                        height={250}
                        key={item}
                        className="mx-auto rounded-20 "
                      />
                    </Grid>
                  ))}
                </Grid>
              ) : campaignsTopStories && campaignsTopStories.length > 0 ? (
                <CourseContainerCard
                  courseData={campaignsTopStories}
                  PanalTitle={"All News"}
                  type={"news"}
                  setFlag={setFlag}
                  categoriesList={categoriesList}
                  isArticles={false}
                  // search={search.toLowerCase()}
                  joinedNeighborhood={joinedNeighborhood}
                  loading={loading}
                />
              ) : (
                <div className="d-flex align-items-center justify-content-center margin my-55">
                  <div className="px-5 py-5">
                    <span className="font-bold display-6">
                      No Records Found
                    </span>
                  </div>
                </div>
              )}
            </div>
            {totalPage !== 0 && (
              <ProductPagination
                value={page}
                count={totalPage}
                handleChangePage={(value) => handlePageNumberChange(value)}
                size="small"
              />
            )}
            {/* {categoriesList.map((categoryDetails) => {
              return (
                <div key={categoryDetails.id} className="mt-4">
                  <CourseContainerCard
                    courseData={
                      categoryDetails.name === "Global"
                        ? campaignsTopStories.filter(
                            (data) =>
                              data.region_details &&
                              Object.keys(data.region_details).length > 0
                          )
                        : campaignsTopStories.filter(
                            (data) =>
                              data.region_details &&
                              Object.keys(data.region_details).length > 0 &&
                              data.region_details.REGION_NAME ===
                                categoryDetails.name
                          )
                    }
                    PanalTitle={
                      categoryDetails.name === "Global"
                        ? "Global News"
                        : categoryDetails.name
                    }
                    setFlag={setFlag}
                    colorCode={categoryDetails.color_code}
                    type={"news"}
                    categoriesList={categoriesList}
                    isArticles={true}
                    search={search.toLowerCase()}
                    joinedNeighborhood={joinedNeighborhood}
                    // width={CLIENT_ID===GIL_CLIENT_ID && props.type==="suggestedReads" && "93vw"}
                  />
                </div>
              );
            })} */}
          </>
        </>
      </div>
      {/* <WeaFooter /> */}
    </>
  );
}

const mapActionToProps = {
  showLoader,
  updateSeeAll,
  updateIsChatFormOpen,
  updateChatFriend,
};

const mapStateToProps = (state) => ({
  userData: state.userData,
  seeAll: state.seeAll,
});

export default connect(mapStateToProps, mapActionToProps)(News);
