import React, { useState } from 'react';
import * as Utility from 'utils/utility';
import { CloseIconSharp, ChevronDownIcon } from 'shared/icons/icons';

const Sub_productlist = (props) => {

  let allAttributes = [];
  let allColors = [];
  let allImages = [];
  if (!Utility.isEmpty(props.attributes)) {
    let attributes = JSON.parse(props.attributes);
    allAttributes = attributes;
  }

  if (!Utility.isEmpty(props.colors)) {
    let allcolor = JSON.parse(props.colors);
    allColors = allcolor;
  }

  if (!Utility.isEmpty(props.images)) {
    let images = JSON.parse(props.images);
    allImages = images;
  }

  const [isClicked, setIsClicked] = useState(false);

  return (
    <>
      <div className="shop-tag-outer">
        <div className="shop-tag-label" >Sub Product Name</div>
        <div className="shop-tag-input align-items-center">{props.subProducts.name}</div>

        <button className={`btn btn-link btn-sub-product mr-3 ${isClicked ? 'btn-open' : ''}`} onClick={() => setIsClicked(!isClicked)}><ChevronDownIcon /></button>
      </div>

      {
        isClicked &&

        <div className="ml-3">
          <div className="shop-tag-outer">
            <div className="shop-tag-label" >Video &amp; Images</div>
            <div className="shop-tag-elements" >
              {!Utility.isEmpty(allImages) ?
                allImages.map(items => {
                  return (
                    <>
                      <img className="shop-tag-thumb" src={items.image_url} />
                    </>
                  )
                })
                :
                <div className="shop-tag-thumb" />
              }
            </div>
          </div>


          <div className="shop-tag-outer">
            <div className="shop-tag-label" >Color</div>
            <div className="shop-tag-elements" >
            {!Utility.isEmpty(allColors) ?
              allColors.map(items => {
                return (                 
                    <div style={{backgroundColor: items}} className="shop-tag-color">{items}</div>
                )
              })
              :
                <div className="shop-tag-color">No Colors..</div>
            }
            </div>
          </div>

          <div className="shop-tag-outer">
            <div className="shop-tag-label" >Product Description</div>
            <div className="shop-tag-input shop-tag-textarea" dangerouslySetInnerHTML={{__html: props.subProducts.description}}></div>
          </div>

          <div className="shop-tag-outer">
            <div className="shop-tag-label" >Inventory</div>
            <div className="shop-tag-input align-items-center" >Unlimited Supply</div>
          </div>

          <div className="shop-tag-outer">
            <div className="shop-tag-label" >Price</div>
            <div className="shop-tag-input align-items-center">{props.subProducts.price}</div>
          </div>

          <div className="font-italic font-bold mt-3 font-18" >Additional Details</div>
          {
            !Utility.isEmpty(allAttributes) ?
              allAttributes.map(items => {
                return (
                  <div className="shop-tag-outer">
                    <div className="shop-tag-label" >{items.attributeName}</div>
                    <div className="shop-tag-input align-items-center" >{items.attributeDescription}</div>
                  </div>
                )
              })
              :
              <div className="shop-tag-outer">
                <div className="shop-tag-label" >Field Name</div>
                <div className="shop-tag-input align-items-center" >Field Description...</div>
              </div>
          }


          <div className="shop-tag-outer">
            <div className="shop-tag-label" >Product Tags</div>
            <div className="d-flex flex-wrap px-4 pt-4" >
              {!Utility.isEmpty(props.productTags) ?
                props.productTags.map(item => {
                  return (
                    <div className="shop-tag-info bg-primary text-white">
                      <CloseIconSharp mainClass="mr-2 cursor-pointer" fillClass="fill-white" />
                      {item.tag_name}
                    </div>
                  )
                })
                :
                <div className="shop-tag-info bg-primary text-white">No Tags..</div>
              }
            </div>
          </div>
        </div>
      }
    </>
  );
}

export default Sub_productlist;