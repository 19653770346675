import React from 'react';
import SideCallout from "shared/components/templates/side_callout";
import { MemberGroupIcon } from 'shared/icons/icons';
import { Form } from 'react-bootstrap';
import { InputField } from 'shared/components/form_elements';
import { SketchPicker } from 'react-color';
import { toast } from "react-toastify";
import { CATEGORIES } from 'config/constants/api_endpoints';
import { CRM_MODULES } from 'config/constants/config';
import * as Utility from 'utils/utility';

const validateFields = ['name', 'description'];

class CategoryForm extends React.Component {

  constructor(props) {
    super(props);

    this.state = {
      formData: {
        name: this.props.categoryFormData.name_for_shops || '',
        color_code: this.props.categoryFormData.color_code || '',
        product_type_id: this.props.productTypeId || ''
      },
      formName: 'create_category',
    };
  }

  createParams = (formData) => {
    const userData = this.props.userData;

    return {
      name: Math.random().toString(36).substring(2,8),
      color_code: formData.color_code,
      created_by: userData.id,
      updated_by: userData.id,
      is_active: true,
      is_global: false,
      client_id: userData.client_id,
      owner_id: userData.id,
      crm_module_id: CRM_MODULES.SHOPS,
      product_type_id: formData.product_type_id,
      name_for_shops: formData.name
    };
  }

  handleChange = (event) => {
    let formData = this.state.formData;

    if (event.target.name === 'color_code') this.validateColorField();

    formData = Utility.validateAndReturnFormData(this, formData, event, validateFields, this.state.formName);

    if (event.target.name === 'color_code') {
      if (event.target.value.length === 1 && event.target.value !== '#') formData.color_code = `#${formData.color_code}`;
    }

    this.setState({ formData });
  }

  validateColorField = () => {
    const element = Utility.getElementById('color_code');
    const error = Utility.getElementById(`color_codeError`);

    if (element.value.length > 7) {
      if (error) error.textContent = 'Only 6 characters allowed';
      return false;
    } else {
      if (error) error.textContent = null;
      return true;
    }
  }

  handleChangeComplete = (color) => {
    const formData = this.state.formData;
    formData.color_code = color.hex;
    this.openColorSelector();

    this.setState({ formData });
  };

  openColorSelector = () => {
    this.setState((state) => {
      return { showColorSelector: !state.showColorSelector };
    });
  }

  submit = (e) => {
    e.preventDefault();
    const isFormValid = this.handleFormErrors();

    if (!isFormValid) {
      toast.error('Fill the necessary details', { containerId: 'public' });
      return;
    }
    if (!Utility.isEmpty(this.props.categoryFormData)) {
      this.updateCategory(this.props.categoryFormData.id);
    } else {
      const params = this.createParams(this.state.formData);
      this.createCategory(params);
    }
  }

  createCategory = (params) => {
    Utility.sendRequest(CATEGORIES, 2, params, async (err, res, body) => {
      body = JSON.parse(body);

      if (body.error) {
        toast.error(body.error.message, { containerId: 'private' });
      } else {
        this.props.onClose();
        toast.success(body.message, { containerId: 'private' });
      }
    });
  }

  updateCategory = (id) => {
    const params = {
      name: Math.random().toString(36).substring(2,8),
      color_code: this.state.formData.color_code,
      name_for_shops: this.state.formData.name
    };

    Utility.sendRequest(`${CATEGORIES}/${id}`, 3, params, async (err, res, body) => {
      body = JSON.parse(body);

      if (body.error) {
        toast.error(body.error.message, { containerId: 'private' });
      } else {
        this.props.onClose();
        toast.success(body.message, { containerId: 'private' });
      }
    });
  }

  openColorSelector = () => {
    this.setState((state) => {
      return { showColorSelector: !state.showColorSelector };
    });
  }

  handleFormErrors = () => {
    const isValid = Utility.showFormErrors(this, validateFields, this.state.formName);
    const colorField = this.validateColorField();

    return ((isValid && colorField) ? true : false);
  }

  render() {
    return (
      <>
        <SideCallout
          closeCallOut={this.props.onClose}
          calloutHeading="Add/Edit Product Category"
          calloutHeadingSvg={<MemberGroupIcon fillClass="fill-black" />}
        >
          <div className="add-category-body">
            <Form className="shar-form search-form search-form-pwa sidebar-form pb-4 pb-md-0">
              <Form.Group className="mb-4 pb-1">
                <Form.Label className="font-bold display-8 mb-3">Product Category Name</Form.Label>
                <InputField
                  required={true}
                  type="text"
                  name="name"
                  ref="name"
                  value={this.state.formData.name}
                  placeholder="Enter Category Name..."
                  handleChange={this.handleChange}
                />
              </Form.Group>
              <Form.Group className="mb-4 pb-1">
                <Form.Label className="font-bold display-8 mb-3">Assign a color to category</Form.Label>
                <div className="align-items-center mb-4 shar-color-picker-container" >
                  <InputField
                    type="text"
                    id="color_code"
                    name="color_code"
                    ref="color_code"
                    value={this.state.formData.color_code}
                    placeholder="#HEX"
                    handleChange={this.handleChange}
                  />
                  <div className="shar-color-picker cursor-pointer" onClick={this.openColorSelector} />
                  {
                    this.state.showColorSelector &&
                    <SketchPicker
                      color={this.state.formData.color_code}
                      onChangeComplete={this.handleChangeComplete}
                    />
                  }
                </div>
              </Form.Group>
              <div className="d-inline-flex flex-column" >
                <button
                  className="btn btn-theme btn-primary mt-3 font-16"
                  onClick={this.submit}
                >
                  Confirm
                </button>
                <button
                  onClick={() => { this.props.onClose() }}
                  className="btn btn-link mt-4 font-16 b-600"
                >
                  Cancel
                </button>
              </div>
            </Form>
          </div>
        </SideCallout>
      </>
    )
  }
}

export default CategoryForm;