import React, { Component } from 'react';
import { GraduationCapIcon, TickFilledIcon } from 'shared/icons/icons';
import moment from 'moment';
import _ from 'lodash';

export class CourseDetails extends Component{
  constructor(props){
    super(props);
    this.state = {
      campaignId: props.campaignId,
    };
  }

  render() {
    let totalScore = 0;
    let totalLessons = 0;
    let completedLessonCount = 0;

    const { sectionList, campaignId, userAssignmentDetails } = this.props;

    const list = sectionList && _.flattenDeep(sectionList.map(section => section.lessons));
    const completedLesson = list && userAssignmentDetails &&  userAssignmentDetails.filter(assignment => list.find(lesson => {if(lesson !== null) return lesson.id === assignment.campaign_id;}));

    completedLessonCount = completedLesson && completedLesson.length;

    if (completedLesson) {
     completedLesson.map(assignment => assignment.score).forEach(score => totalScore += parseFloat(score));
     totalScore = (totalScore / (list.length * 100)) * 100;
    }

    sectionList.forEach(section => { if(section.lessons === null) totalLessons += 0; else totalLessons += section.lessons.length;});

    const data = this.props.courseDetails.length > 0 && this.props.courseDetails[this.props.index];
    const selectedCampaign = this.props.campaignDetails.length > 0 && this.props.campaignDetails[this.props.index];
    const activeClass = campaignId == selectedCampaign.campaign_id ? 'course-sidecallout-active' : '';
    return (
      <>
        <div className="article-page-side cs-page-side h-100" >
          <div className="article-page-side-head py-3 px-4 align-items-center border-bottom" >
            <GraduationCapIcon />
            <span className="display-8 font-bold ml-2">Course Syllabus</span>
            <div className="cs-lesson-indicators ml-auto text-right">
              <span>Lesson {completedLessonCount} of {totalLessons}</span>
              <ul className="list-unstled d-flex mt-2">
               { Array(totalLessons).fill({}).map((i, index) =>  {
                 return (
                  <li key={index} className={(index + 1) <= completedLessonCount && 'active'} />
                 );
               })}
              </ul>
            </div>
          </div>
          <div className="py-3 px-4" >
            <div className="font-18 font-weight-bold mb-3" >Course</div>
            <div onClick={() => this.props.refreshCourse()} className={`course-sidecallout mb-5 d-flex overflow-hidden cursor-pointer ${activeClass}`}>
              <div className="position-relative p-4">
                <label className="cursor-pointer position-absolute course-sidecallout-checkbox">
                  <input type="checkbox" className="toolbox-card-checkbox" checked={completedLessonCount === totalLessons} />
                  <div className="toolbox-card-checkbox-outer">
                    <TickFilledIcon  fillClass="fill-primary" />
                  </div>
                </label>
                <div className="font-18 font-weight-bold mt-1">{data.heading}</div>
                <div className="text-primary-light b-600" >{completedLessonCount} of  {totalLessons} Lesson Completed</div>
              </div>
              <div className="mcq-set-right mcq-set-main flex-center ml-auto" >
                <div className="rounded-circle lesson-score font-weight-bold flex-center flex-column" >
                  <span className="font-16">{totalScore > 0 && totalScore < 100 ? totalScore.toFixed(1) : totalScore || 0}%</span>
                  <span className="font-12">Score</span>
                </div>
              </div>
            </div>
            {sectionList && sectionList.length > 0 && userAssignmentDetails && sectionList.map((item) =>
              <>
              <div className="font-18 font-weight-bold mb-3" >{item.heading}</div>
              {item.lessons && item.lessons.length > 0 && item.lessons.map((details) => {
                  const isCompleted = userAssignmentDetails.find(completedLesson => completedLesson.campaign_id === details.id);
                  let score = isCompleted && parseFloat(isCompleted.score);
                  score = score > 0 && score < 100 ? score.toFixed(1) : score;
                return (
                <>
                {isCompleted ?
                <div className={`course-sidecallout mb-5 d-flex overflow-hidden cursor-pointer ${selectedCampaign.campaign_id == details.id ? 'course-sidecallout-active' : ''}`} key={details.id} onClick={() => this.props.loadLession(details)}>
                  <div className="position-relative p-4">
                      <label className="cursor-pointer position-absolute course-sidecallout-checkbox">
                        <input type="checkbox" className="toolbox-card-checkbox" checked={true}/>
                        <div className="toolbox-card-checkbox-outer">
                          <TickFilledIcon  fillClass="fill-primary" />
                        </div>
                      </label>
                      <div className="font-18 font-weight-bold mt-1">{details.heading}</div>
                      <div className="text-primary-light b-600" >Completed on {moment(isCompleted.updated_at).format('LL')}</div>
                    </div>
                    <div className="mcq-set-right mcq-set-main flex-center ml-auto" >
                      <div className="rounded-circle lesson-score font-weight-bold flex-center flex-column" >
                        <span className="font-16">{score}%</span>
                        <span className="font-12">Score</span>
                      </div>
                    </div>
                </div>
                :
                <div className={`course-sidecallout mb-5 d-flex overflow-hidden cursor-pointer ${selectedCampaign.campaign_id == details.id ? 'course-sidecallout-active' : ''}`} key={details.id} onClick={() => this.props.loadLession(details)}>
                  <div className="position-relative p-4">
                    <label className="cursor-pointer position-absolute course-sidecallout-checkbox">
                      <input type="checkbox" className="toolbox-card-checkbox" disabled={true} />
                      <div className="toolbox-card-checkbox-outer">
                        <TickFilledIcon  fillClass="fill-primary" />
                      </div>
                    </label>
                    <div className="font-18 font-weight-bold mt-1">{details.heading}</div>
                    <div className="text-primary-light b-600" >Pending</div>
                  </div>
                  <div className="mcq-set-right mcq-set-dark flex-center ml-auto" >
                    <span className="font-12 b-600 text-white">Start</span>
                  </div>
                </div>}
                </>
              );})}
              </>
            )}
           </div>
        </div>
      </>);
  }
}
