import React from "react";
import { DropDown } from "shared/components/form_elements";

const activeInactiveOption = [
  { id: "active", name: "Active", parent_menu_id: 1 },
  { id: "inActive", name: "Inactive", parent_menu_id: 1 },
];

export const DetailListSorting = (props) => {
  return (
    <>
      <div className="shar-form selectbox-sm">
        <DropDown
          optionId="id"
          value={props.value}
          handleChange={(e) => props.handleChangeActiveCatrgory(e.target.value)}
          optionValue="name"
          options={activeInactiveOption}
        />
      </div>
    </>
  );
};
