import React, { useEffect, useState } from "react";
import { connect, useSelector } from "react-redux";
import { toast } from "react-toastify";
import { showLoader } from "shared/actions";
import EventCard from "../../../scenes/profile/components/EventCard";
import * as Utility from "utils/utility";
import { useHistory } from "react-router-dom";
import { MEMBER_ALL_EVENT } from "../../../config/constants/api_endpoints";
import { CustomSlider } from "../Slider";
import { Skeleton } from "@mui/material";

const SuggestedEvents = (props) => {
  const history = useHistory();
  const userData = useSelector((state) => state.userData);
  const [allMyEvents, setAllMyEvents] = useState([]);
  const refreshEventsApi = useSelector((state) => state.refreshEventsApi);
  const [myEventsId, setMyEventsId] = useState([]);

  const getMyEvents = () => {
    const params = {};
    Utility.sendRequest(
      `${MEMBER_ALL_EVENT}/${userData.id}`,
      1,
      params,
      (err, res, body) => {
        body = JSON.parse(body);
        if (body.error) {
          toast.error(body.error.message, { containerId: "private" });
        } else {
          // For Setting My Events data
          setAllMyEvents(
            body.filter(
              (items) =>
                items.corporate_is_active === true ||
                items.corporate_is_active === null
            )
          );
        }
      }
    );
  };

  useEffect(() => {
    getMyEvents();
  }, [refreshEventsApi]);

  useEffect(() => {
    setMyEventsId(allMyEvents.map((item) => item.id));
  }, [allMyEvents]);

  return (
    <div className="slider-mob px-lg-0 mt-5 px-1">
      <h1 className="font-24-bold mb-25 home-page-heading">
        <span className="font-weight-bold home-page-headings  d-flex align-items-center">
          <img src={require("assets/images/news.png")} height="20px" />
          <p className="pl-2 m-auto">Featured Events</p>
        </span>

        <span
          className="see-all-text display-7"
          onClick={() => history.push("/events")}
        >
          See All
        </span>
      </h1>

      {/* <div className="container-overflow w-100">
        <div className="event-container d-flex flex-row mt-3 pb-4"> */}
      <CustomSlider>
        {props.loadingEvent ? (
          <div className="d-flex ml-1">
            {[1, 2, 3, 4].map((item) => (
              <Skeleton
                variant="rounded"
                width={320}
                height={250}
                key={item}
                className="mr-3 rounded-20 "
              />
            ))}
          </div>
        ) : props.data.length !== 0 ? (
          props.data.map((e, index) => (
            <div key={e.id} className="mr-3">
              <EventCard
                key={e.id}
                rsvpState={myEventsId && myEventsId.includes(e.id)}
                items={e}
                index={index}
                eventsData={props.data}
                joinedNeighborhood={props.joinedNeighborhood}
              />
            </div>
          ))
        ) : (
          <div className="d-flex align-items-center justify-content-center mt-5 ">
            <div className="px-5">
              <span className="font-bold display-6">No Records Found</span>
            </div>
          </div>
        )}
      </CustomSlider>
    </div>
    //   </div>
    // </div>
  );
};

const mapActionToProps = {
  showLoader,
};

const mapStateToProps = (state) => ({
  userData: state.userData,
});

export default connect(mapStateToProps, mapActionToProps)(SuggestedEvents);
