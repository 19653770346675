import React from "react";

const UserAboutSection = ({ description, title }) => {
  return (
    <div className="AboutMainContainer ff-Inter-Bold overflow-hidden">
      <p className="section-head ff-Inter-Bold">{title}</p>
      <p className="about-description">{description}</p>
    </div>
  );
};

export default UserAboutSection;
