import React from "react";
import Avatar from "react-avatar";
import {
  updateIsChatFormOpen,
  updateChatFriend,
  updateSeeAll,
} from "shared/actions";
import { withRouter } from "react-router-dom";
import { connect, useDispatch, useSelector } from "react-redux";
import { Utility } from "utils";
import {
  SWITCH_ACCOUNT,
  USER_AUTH,
} from "../../../../../config/constants/api_endpoints";
import { toast } from "react-toastify";
import cookie from "react-cookies";
import {
  updateIsNeighborhoodFormOpen,
  openNeighborhoodForm,
  showLoader,
  updateUserData,
} from "../../../../../shared/actions";

const membersCard = ({ item, name, members_count, ...props }) => {
  const accountList = useSelector((state) => state.accountList);
  const dispatch = useDispatch();

  const userProfile = (id) => {
    props.updateSeeAll({
      isOpen: null,
    });

    getProfileOrSwitchProfile(id);
  };
  const getProfileOrSwitchProfile = (id) => {
    if (id === props.userData.id) return props.history.push("/profile");

    const account_list = [
      ...accountList.Users,
      ...accountList.Clients,
      ...accountList.Parents,
      ...accountList.Admins,
    ];
    const profile = account_list.find((item) => item.id === id);

    if (profile) switchAccount(id, profile);
    else
      props.history.push({
        pathname: `/profile/${id}`,
        state: { otherUserProfile: true },
      });
  };

  const switchAccount = (id, data) => {
    dispatch(updateIsNeighborhoodFormOpen(false));
    dispatch(openNeighborhoodForm({}));
    dispatch(showLoader(true));

    Utility.sendRequest(SWITCH_ACCOUNT, 1, { id }, (error, response, body) => {
      body = JSON.parse(body);

      if (body.error) {
        toast.error(body.error.message, { containerId: "public" });
        dispatch(showLoader(false));
      } else {
        cookie.save("sessionToken", body.token, { path: "/" });

        Utility.sendRequest(USER_AUTH, 1, { id }, (error, response, body) => {
          body = JSON.parse(body);
          dispatch(showLoader(false));

          if (!body.error) {
            dispatch(updateUserData(body));

            if (window.location.pathname !== "/profile") {
              props.history.push({
                pathname: "/profile",
                state: { data },
              });
            }
          } else {
            toast.error(body.error.message, { containerId: "public" });
          }
        });
      }
    });
    // }
  };
  return (
    <>
      <div className="mx-2">
        <section className="mx-auto member-card-container">
          <div
            className="card member-card-wrapper cursor-pointer testimonial-card mt-2 mb-3 shadow rounded-6"
            onClick={() => {
              userProfile(item.user_client_id);
            }}
          >
            <div className="image mx-auto white pt-3">
              {item && item.image_url ? (
                <img
                  src={item.image_url}
                  className={`rounded-circle ${
                    item && item.image_url && "border"
                  }`}
                  alt={name + "flag"}
                  height="100px"
                  width="100px"
                />
              ) : (
                <Avatar
                  name={name}
                  round={true}
                  size="100"
                  className="profile-page-userAvatar"
                  textSizeRatio={1.75}
                  color="#007bb3"
                  maxInitials={1}
                />
              )}
            </div>
            <div className="card-body text-center">
              <h4 className="card-title" style={{ lineHeight: "1.2" }}>
                {name}
              </h4>
              {(members_count && members_count !== 0) && (
                <p className="membersParagraph font-weight-bolder mt-2">
                  <i className="card-title "></i>
                  {members_count} members
                </p>
              )}
            </div>
          </div>
        </section>
      </div>
    </>
  );
};

const mapActionToProps = {
  updateIsChatFormOpen,
  updateChatFriend,
  updateSeeAll,
};

const mapStateToProps = (state) => ({
  userData: state.userData,
  seeAll: state.seeAll,
});

export default withRouter(
  connect(mapStateToProps, mapActionToProps)(membersCard)
);
