import React, { useEffect, useState } from "react";
import AddIcon from "../../../assets/images/icons/add_icon_black.svg";
import CourseCard from "./CourseCard";
import EventCard from "./EventCard";
import { Utility } from "utils";
import { useSelector } from "react-redux";
import { CustomSlider } from "../../../shared/components/Slider";
import { useParams } from "react-router-dom";
import { Skeleton } from "@mui/material";

const ProfileEvents = ({
  profileEvents,
  history,
  isClickable,
  isClickCheck,
  loading
}) => {
  const params = useParams();
  const accounts = useSelector((state) => state.accountList);
  const [showAll, setShowAll] = useState(false);
  return (
    <div className="MainContainerScrollItem pr-0 ff-Inter-Bold overflow-hidden">
      <div className="PanalHeader pr-3 d-flex align-items-center justify-content-between">
        <p className="section-head ff-Inter-Bold d-flex align-items-center pl-5">
          {params && params.id ? "Events" : "My Events"}
        </p>
        {accounts && accounts.Clients && accounts.Clients.length > 0 && (
          <img
            className="addIconStyling cursor-pointer"
            src={AddIcon}
            alt="Addicon"
            onClick={() => history.push("/create-event")}
          />
        )}
      </div>
      {/* <div className="card-container d-flex"> */}

      <div className="eventContainer pr-0 ff-Inter-Bold scroll-y">
        {loading ? (
          [1, 2, 3, 4].map((item) => (
            <Skeleton
              variant="rounded"
              width={320}
              height={250}
              key={item}
              className="mr-3 rounded-20 "
            />
          ))
        ) : !Utility.isEmpty(profileEvents) ? (
          profileEvents
            .slice(0, showAll ? profileEvents.length + 1 : 4)
            .map((items, index) => {
              return (
                <EventCard
                  key={items.id}
                  isNoLeftMargin={true}
                  items={items}
                  TotalEvents={profileEvents}
                  index={index}
                  isClickable={isClickable}
                  isClickCheck={isClickCheck}
                  eventsData={profileEvents}
                />
              );
            })
        ) : (
          // <></>
          <div className="d-flex align-items-center justify-content-center">
            <div className="px-5">
              <span className="font-bold display-6">No events found</span>
            </div>
          </div>
        )}
      </div>
      {profileEvents && profileEvents.length > 4 && (
        <>
          {!showAll && (
            <h6
              className="text-primary text-center mt-3 cursor-pointer font-14"
              onClick={() => setShowAll(true)}
            >
              SEE MORE
            </h6>
          )}
          {showAll && (
            <h6
              className="text-primary text-center mt-3 cursor-pointer font-14"
              onClick={() => setShowAll(false)}
            >
              SEE LESS
            </h6>
          )}{" "}
        </>
      )}
      {/* </div> */}
    </div>
  );
};

export default ProfileEvents;
