import React from 'react';

 let Card = (props) => {
  return(
    <div className="giftCard-container">
      <div className="giftCard-donate text-center border-radius-20">
        <div className="giftCard-donate-header p-4">
          <img src={require("assets/images/handle.png")} alt="giftCard handle" />
          <h1 className="my-3" style={{ color: props.colorCode }}>DONATE <br/> GIFT CARD</h1>
          <p className="mb-1">You have selected a</p>
          <h3 className="mb-1 font-medium">{props.selectedBrand.brand_name}</h3>
          <p className="mb-1">to benefit</p>
          <p className="font-14 font-italic mb-1">{props.selectedBeneficiary }</p>
        </div>
        <div className="giftCard-donate-body border-radius-20">
          <img src={props.selectedBrand.brand_image_url} alt="brand" className="w-100 border-radius-20" />
        </div>
      </div>
    </div>
  );
};

export default Card = React.memo(Card);
