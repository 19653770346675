import React, { Component } from "react";
import { Form, Spinner } from "react-bootstrap";
import { InputField, Checkbox } from "shared/components/form_elements";
import { Link } from "react-router-dom";
import { toast } from "react-toastify";
import { connect } from "react-redux";
import { Utility } from "utils";
import ReportIcon from "@mui/icons-material/Report";
import { LOGIN, USER_AUTH } from "config/constants/api_endpoints";
import { CLIENT_ID } from "config/constants/config";
import { updateUserData, showLoader } from "shared/actions";
import cookie from "react-cookies";
import SelectAccount from "./select_account";
import { BackButton } from "shared/components/web";
import CloseToast from "shared/components/close_toast";
import { ShowIcon } from "shared/icons/icons";
import "react-toastify/dist/ReactToastify.css";
import { Modal } from "@material-ui/core";
import { SENDMAIL_TO_ADMIN } from "../../../config/constants/api_endpoints";
import ActuveteAccountModal from "./ActuveteAccountModal";

const validateFields = ["email", "password"];

class SignIn extends Component {
  constructor(props) {
    super(props);

    this.state = {
      is_active_modal: false,
      formData: {
        email: localStorage.getItem("email") || "",
        password: Utility.loadStates("password") || "",
        client_id: CLIENT_ID.toString(),
      },
      adminContectFrom: {
        Name: "",
        Email: "",
        Discription: "",
      },
      adminContectFromError: {
        Name: "",
        Email: "",
        Discription: "",
      },
      localStorage: [],
      showLoader: false,
      isLogin: false,
      inputType: "password",
      remember_me: localStorage.getItem("remember_me") || false,
    };
  }

  handleOpen = () => {
    this.setState({
      is_active_modal: true,
    });
  };

  validateContactForm = (name) => {
    let isValid = "";
    if (!this.state.adminContectFrom[name].trim()) {
      isValid = `${name} is a Required Field`;
    } else if (
      name === "Email" &&
      !/^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/.test(
        this.state.adminContectFrom[name]
      )
    ) {
      isValid = "Enter A valid email";
    }
    return isValid;
  };

  componentDidMount() {
    const localData = Utility.getLocalStorage("users");
    if (localData.length > 0) {
      this.setState({ isLogin: false });
    } else {
      this.setState({ isLogin: true });
    }

    if (!Utility.isEmpty(this.props.userData)) this.props.history.push("/");
  }

  handleChangeInFrom = (event) => {
    const name = event.target.name;
    const value = event.target.value;
    let CurrentValue = { ...this.state.adminContectFrom };
    CurrentValue[name] = value;
    const Error = this.validateContactForm(name);
    this.setState({
      adminContectFromError: {
        ...this.state.adminContectFromError,
        [name]: Error,
      },
    });
    this.setState({
      adminContectFrom: CurrentValue,
    });
  };

  handleChange = (e) => {
    let formData = this.state.formData;

    formData = Utility.validateAndReturnFormData(
      this,
      formData,
      e,
      validateFields
    );

    this.setState({ formData });
  };

  handleRememberMe = (e) => {
    if (e.target.checked === true) {
      if (this.state.formData.email && this.state.formData.password) {
        localStorage.setItem("remember_me", e.target.checked);
        localStorage.setItem("email", this.state.formData.email);
        Utility.saveState(this.state.formData.password, "password");
      }
    } else {
      localStorage.removeItem("remember_me");
      localStorage.removeItem("email");
      localStorage.removeItem("password");
    }
  };

  resetPassword = () => {
    const formData = this.state.formData;
    formData.password = "";

    this.setState({ formData });
  };

  checkLocal = (data) => {
    if (typeof localStorage !== "undefined") {
      let localData = Utility.getLocalStorage("users");

      if (localData.length > 0) {
        const id = localData.find((user) => user.id === data.id);
        if (!id) {
          localData.push(data);
          Utility.setLocalStorage("users", localData);
        }
      } else {
        Utility.setLocalStorage("users", []);
        localData = Utility.getLocalStorage("users");
        localData.push(data);
        Utility.setLocalStorage("users", localData);
      }
    }
  };

  signIn = () => {
    this.setState({ isLogin: true });
  };

  login = (e) => {
    e.preventDefault();

    if (!Utility.showFormErrors(this, validateFields)) return;
    this.setState({ showLoader: true });

    const formData = { ...this.state.formData };

    Utility.sendRequest(LOGIN, 2, formData, async (error, response, body) => {
      body = JSON.parse(body);

      if (body.error) {
        this.setState({ showLoader: false });
        this.resetPassword();

        if (body.error.message.includes("The user is not active")) {
          this.handleOpen();
        } else {
          toast.error(body.error.message, { containerId: "public" });
        }
        // if (body.error.code === 1012) this.setState({ showResendConfirmationLink: true });
      } else {
        cookie.save("sessionToken", body.token, { path: "/" });

        Utility.sendRequest(
          USER_AUTH,
          1,
          body.token,
          (error, response, body) => {
            body = JSON.parse(body);

            if (!body.error) {
              this.props.updateUserData(body);
              this.props.history.push("/home");
              this.checkLocal(body);
              // cookie.save('usersSession', body.token);
            } else {
              toast.error(body.error.message, { containerId: "public" });
            }
          }
        );
      }
    });
  };

  onLogoClick = () => {
    this.props.history.push("/");
  };

  goLogIn = () => {
    this.setState({ isLogin: false });
  };

  toggleShowPassword = () => {
    this.state.inputType === "text"
      ? this.setState({ inputType: "password" })
      : this.setState({ inputType: "text" });
  };

  handleClose = () => {
    this.setState({
      is_active_modal: false,
    });
  };

  render() {
    const { isLogin } = this.state;
    return (
      <React.Fragment>
        <Modal
          open={this.state.is_active_modal}
          onClose={this.handleClose}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <ActuveteAccountModal
            user={this.state.formData}
            handleClose={this.handleClose}
          />
        </Modal>

        {isLogin ? (
          <div className="container">
            <div className="register-outer p-sm-5 p-4 text-center mx-auto my-sm-5 my-3">
              <div className="d-flex cursor-pointer">
                <BackButton action={() => this.goLogIn()} />
              </div>
              <img
                src={require("assets/images/logos/main-logo.png")}
                className="register-logo mb-4 cursor-pointer"
                alt="sharemeister"
                onClick={this.goLogIn}
              />
              <h3 className="mb-5 font-bold">Please confirm Credentials</h3>
              <Form>
                <div className="new-landing-theme register-form">
                  <Form.Group>
                    <InputField
                      required={true}
                      name="email"
                      className="form-control"
                      ref="email"
                      value={this.state.formData.email}
                      handleChange={this.handleChange}
                      type="email"
                      placeholder="Email"
                    />
                  </Form.Group>
                  <Form.Group className="position-relative form-element-outer mx-auto">
                    <InputField
                      required={true}
                      name="password"
                      className="form-control"
                      ref="password"
                      value={this.state.formData.password}
                      handleChange={this.handleChange}
                      type={this.state.inputType}
                      placeholder="Password"
                    />
                    <b
                      className="show-icon"
                      onClick={() => this.toggleShowPassword()}
                    >
                      <ShowIcon
                        mainClass={`sm-show-icon ${
                          this.state.inputType === "text"
                            ? "sm-show-icon-active"
                            : ""
                        }`}
                      />
                    </b>
                  </Form.Group>
                </div>
                <Link
                  className="form-element-outer mx-auto display-8 text-left mt-n2 d-block"
                  to={{
                    pathname: "/users/forgot_password",
                    state: { email: this.state.formData.email },
                  }}
                >
                  Forgot Password?
                </Link>
                <Form.Group>
                  <Checkbox
                    ref="remember_me"
                    placeholder="Remember Me"
                    checkboxId="remember_me"
                    checked={this.state.remember_me}
                    name="remember_me"
                    label="Remember Me"
                    handleChange={this.handleRememberMe}
                  />
                </Form.Group>
                <div className="text-left form-element-outer mx-auto mt-5">
                  <button
                    type="submit"
                    className="btn btn-primary btn-login"
                    onClick={(e) => this.login(e)}
                    disabled={this.state.showLoader}
                  >
                    {this.state.showLoader ? (
                      <Spinner animation="border" role="status" />
                    ) : (
                      "Login"
                    )}
                  </button>
                </div>
              </Form>
              <Link
                className="d-inline-block mx-auto text-primary font-bold display-7 cursor-pointer pt-5"
                to={{ pathname: "/users/sign_up" }}
              >
                Create a New Account
              </Link>
            </div>
          </div>
        ) : (
          <SelectAccount signIn={this.signIn} />
        )}
      </React.Fragment>
    );
  }
}

const mapActionToProps = { updateUserData, showLoader };
const mapStateToProps = (state) => ({
  userData: state.userData,
});
export default connect(mapStateToProps, mapActionToProps)(SignIn);
