import React, { useEffect, useState } from "react";
import useMediaQuery from '@mui/material/useMediaQuery';
import { Link } from "react-router-dom";
import ChatEditor from "../../../ChatEditor";
import { Navbar } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import * as Utility from "utils/utility";
import {
  CREATE_DISCUSSION_COMMENT,
  GET_DISCUSSION_COMMENTS,
  GROUP_MEMBERS,
  GROUP_MESSAGES_MARK_READ,
  MESSAGES,
  MESSAGE_IMAGE_URL,
  MESSAGE_MARK_AS_READ,
  SIGNED_URL,
  UPDATE_DISCUSSION_COMMENT,
} from "../../../../../config/constants/api_endpoints";
import { toast } from "react-toastify";
import { MESSAGE_TYPE_IDS } from "../../../../../config/constants";
import Avatar from "react-avatar";
import moment from "moment";
import { DocIcon } from "../../../../icons/icons";
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import {
  showLoader,
  updateIsChatFormOpen,
  updatenewMessage,
} from "../../../../actions";

import { HiLockClosed } from "react-icons/hi";
import { uploadFile } from "../../../../../utils/utility";
import { CLIENT_ID } from "../../../../../config/constants/config";
import Discussion from "../../../v2/campaign/components/Discussion";
import UserCommentContainer from "../../../v2/campaign/components/UserCommentContainer";

function Chat(props) {
  const htmlRegex = /<\/?[a-z][\s\S]*>/i;
  const [MessageData, setMessagesData] = useState("");
  const [mediasList, setMediaList] = useState(null);
  const { userData } = useSelector((state) => state);
  const [isMount, setIsMount] = useState(true);
  const [Messages, setMessages] = useState([]);
  const [DiscussionMessages, setDiscussionMessages] = useState([]);
  const [LatestMessage, setLatestMessage] = useState("");
  const [chatPrivateKry, setChatPrivateKry] = useState("");
  const [scrollToReplyBox, setScrollToReplyBox] = useState(false);
  const dispatch = useDispatch();
  const isTab = useMediaQuery('(max-width:820px)');

  const [groupMembersData, setGroupMembersData] = useState([]);
  const getGroupMember = () => {
    const params = {
      groupId: props.activeChatDetail.id,
    };

    Utility.sendRequest(GROUP_MEMBERS, 1, params, (err, res, body) => {
      body = JSON.parse(body);
      if (!body.error) {
        setGroupMembersData(body);
      }
    });
  };

  useEffect(() => {
    if (props.activeChatDetail && !props.activeChatDetail.is_discussion) {
      if (Object.keys(props.activeChatDetail).includes("group_private_key")) {
        getGroupMember();
      }
    }
    props.activeChatDetail.is_discussion ? getCommentData() : getAllMessages();
    let interval = setInterval(
      () =>
        props.activeChatDetail.is_discussion
          // ? getCommentData()
          ? () => { }
          : getAllMessages(),
      2000
    );
    return () => clearInterval(interval);
  }, [props.activeChatDetail]);

  const getCommentData = () => {
    const params = { lesson_id: props.activeChatDetail.id };

    Utility.sendRequest(
      GET_DISCUSSION_COMMENTS,
      1,
      params,
      (err, res, body) => {
        body = JSON.parse(body);
        if (body.error) {
          toast.error(body.error.message, { containerId: "private" });
        } else {
          setDiscussionMessages([...body.data]);
        }
      }
    );
  };

  const getAllMessages = () => {
    let params;

    if (Object.keys(props.activeChatDetail).includes("group_private_key")) {
      params = {
        to_user_client_id: props.activeChatDetail.id,
        from_user_client_id: userData.id,
        group_id: props.activeChatDetail.id,
        isGroup: true,
      };
    } else {
      params = {
        to_user_client_id: props.activeChatDetail.id,
        from_user_client_id: userData.id,
        group_id: props.activeChatDetail.id,
        isGroup: false,
      };
    }

    Utility.sendRequest(MESSAGES, 1, params, (error, response, res) => {
      const body = JSON.parse(res);
      let messages = [];
      let chatPrivateKey = "";
      if (body.error) {
        toast.error(body.error.message, { containerId: "private" });
      } else {
        if (body && body.data && body.data.length > 0) {
          if (
            Object.keys(props.activeChatDetail).includes("group_private_key")
          ) {
            messages = Utility.decryptAllMessages(
              body.data[0].messages,
              "",
              "",
              props.activeChatDetail.group_private_key
            );
          } else {
            messages = Utility.decryptMessages(
              body.data[0].messages,
              body.data[0].chat_private_key,
              props.activeChatDetail.private_key,
              userData.private_key
            );

            chatPrivateKey = body.data[0].chat_private_key;
          }
        }
      }
      const FormatedData = messages;
      if (FormatedData.length > 0) {
        let DateSave = "";
        for (var i = 0; i < FormatedData.length - 1; i++) {
          const savedDate = `${moment(FormatedData[i].created_at).format(
            "MMMM"
          )} ${moment(FormatedData[i].created_at).format("DD")} , ${moment(
            FormatedData[i].created_at
          ).format("YYYY")}`;
          if (FormatedData[i].created_at && savedDate !== DateSave) {
            FormatedData[i].is_newDate = true;
            FormatedData[i].FormatedDate = savedDate;
            DateSave = savedDate;
          } else {
            FormatedData[i].is_newDate = false;
            FormatedData[i].FormatedDate = "";
          }
        }
      }
      setMessages(FormatedData);
      setChatPrivateKry(chatPrivateKey);
    });
  };

  useEffect(() => {
    if (props.activeChatDetail && !props.activeChatDetail.is_discussion) {
      let lastMessage = "";
      if (Messages[Messages.length - 1]) {
        lastMessage = Messages[Messages.length - 1].id;
      }
      if (
        props.activeChatDetail.unseen_count > 0 ||
        lastMessage !== LatestMessage
      ) {
        scrollMessages();
        markAsRead();
        setLatestMessage(lastMessage);
      }
      if (
        props.activeChatDetail.unread_messages_count > 0 ||
        lastMessage !== LatestMessage
      ) {
        scrollMessages();
        markAsGroupRead();
        setLatestMessage(lastMessage);
      }
    }
  }, [Messages]);
  useEffect(() => {
    if (props.activeChatDetail && !props.activeChatDetail.is_discussion) {
      getAllMessages();
    }

  }, []);

  const handleSaveComment = (
    campaign_id,
    comment_data,
    mediaUrl = [],
    mediaListData
  ) => {
    let params = {};
    const mediaListLocal = mediaListData ? mediaListData : mediasList;

    if (mediaListLocal) {
      params = {
        platform_id: CLIENT_ID,
        parent_id: campaign_id ? campaign_id : props.activeChatDetail.id,
        description: comment_data
          ? comment_data
          : MessageData
            ? MessageData
            : " ",
        image_url:
          mediaListLocal.type === "image/png" ||
            mediaListLocal.type === "image/jpeg"
            ? [mediaUrl]
            : [],
        video_url:
          mediaListLocal.type === "video/mp4" ||
            mediaListLocal.type === "video/x-m4v" ||
            mediaListLocal.type === "video/*"
            ? [mediaUrl]
            : [],
        file_url:
          mediaListLocal.type === ".doc" ||
            mediaListLocal.type === ".txt" ||
            mediaListLocal.type === ".docx" ||
            mediaListLocal.type === "application/msword" ||
            mediaListLocal.type === "application/wps-office.docx" ||
            mediaListLocal.type === "application/pdf" ||
            mediaListLocal.type === "text/plain" ||
            mediaListLocal.type ===
            "application/vnd.openxmlformats-officedocument.wordprocessingml.document"
            ? [mediaUrl]
            : [],
      };
    } else {
      params = {
        platform_id: CLIENT_ID,
        parent_id: campaign_id ? campaign_id : props.activeChatDetail.id,
        description: comment_data ? comment_data : MessageData,
      };
    }
    dispatch(showLoader(true));
    Utility.sendRequest(
      CREATE_DISCUSSION_COMMENT,
      2,
      params,
      async (err, res, body) => {
        body = JSON.parse(body);
        if (body.error) {
          toast.error(body.error.message, { containerId: "private" });
          dispatch(showLoader(false));
        } else {
          dispatch(showLoader(false));
          // body.message = message;
          setMessagesData("");
          setMediaList(null);
          getCommentData();
        }
      }
    );
  };

  const handleEditComment = (
    campaign_id,
    comment_data,
    mediaUrl = [],
    mediaListData
  ) => {
    let params = {};
    const mediaListLocal = mediaListData ? mediaListData : mediasList;

    params = {
      id: campaign_id ? campaign_id : props.activeChatDetail.id,
      description: comment_data
        ? comment_data
        : MessageData
          ? MessageData
          : " ",
      image_url:
        mediaListLocal &&
          (mediaListLocal.type === "image/png" ||
            mediaListLocal.type === "image/jpeg")
          ? [mediaUrl]
          : [],
      video_url:
        mediaListLocal &&
          (mediaListLocal.type === "video/mp4" ||
            mediaListLocal.type === "video/x-m4v" ||
            mediaListLocal.type === "video/*")
          ? [mediaUrl]
          : [],
      file_url:
        mediaListLocal &&
          (mediaListLocal.type === ".doc" ||
            mediaListLocal.type === ".txt" ||
            mediaListLocal.type === ".docx" ||
            mediaListLocal.type === "application/msword" ||
            mediaListLocal.type === "application/wps-office.docx" ||
            mediaListLocal.type === "application/pdf" ||
            mediaListLocal.type === "text/plain" ||
            mediaListLocal.type ===
            "application/vnd.openxmlformats-officedocument.wordprocessingml.document")
          ? [mediaUrl]
          : [],
    };

    dispatch(showLoader(true));
    Utility.sendRequest(
      UPDATE_DISCUSSION_COMMENT,
      3,
      params,
      (err, res, body) => {
        body = JSON.parse(body);
        if (body.error) {
          toast.error(body.error.message, { containerId: "private" });
          dispatch(showLoader(false));
        } else {
          dispatch(showLoader(false));
          getCommentData();
        }
      }
    );
  };

  const handleSubmitComment = async (
    campaign_id,
    commentDataLocal,
    mediaListData,
    isEdit
  ) => {
    if (mediaListData || mediasList) {
      const MediaView = mediaListData ? mediaListData : mediasList;
      dispatch(showLoader(true));
      Utility.sendRequest(SIGNED_URL, 1, {}, async (err, res, body) => {
        body = JSON.parse(body);
        if (body.error) {
          toast.error(body.error.message, { containerId: "private" });
          dispatch(showLoader(false));
        } else {
          if (MediaView) {
            const Uploadrespose = await uploadFile(
              MediaView,
              body.imageSignedUrl
            );
            if (Uploadrespose) {
              if (isEdit) {
                handleEditComment(
                  campaign_id,
                  commentDataLocal,
                  Uploadrespose,
                  MediaView
                );
              } else {
                handleSaveComment(
                  campaign_id,
                  commentDataLocal ? commentDataLocal : " ",
                  Uploadrespose,
                  MediaView
                );
              }
            }
          } else {
            const Uploadrespose = await uploadFile(
              mediasList,
              body.imageSignedUrl
            );
            if (Uploadrespose) {
              if (isEdit) {
                handleEditComment(campaign_id, undefined, Uploadrespose);
              } else {
                handleSaveComment(campaign_id, undefined, Uploadrespose);
              }
            }
          }
          dispatch(showLoader(false));
        }
      });
    } else {
      if (isEdit) {
        handleEditComment(campaign_id, commentDataLocal);
      } else {
        handleSaveComment(campaign_id, commentDataLocal);
      }
    }
  };

  const uploadMedia = (type) => {
    Utility.sendRequest(
      MESSAGE_IMAGE_URL,
      1,
      {},
      async (error, response, body) => {
        body = JSON.parse(body);
        let newType;
        if (body.error) {
          console.log(body.error);
        } else {
          switch (type) {
            case "image/png":
            case "image/jpeg":
            case "video/mp4":
              newType = "image";
            case "video/x-m4v":
            case "video/*":
              newType = "image";
            case ".doc":
            case ".docx":
            case "application/pdf":
            case "application/msword":
            case "application/wps-office.docx":
            case "text/plain ":
            case "application/vnd.openxmlformats-officedocument.wordprocessingml.document":
              newType = "document";
          }
          await uploadFiles(body.path, newType);
        }
      }
    );
  };

  const GetPreview = (media, mediatype) => {
    switch (mediatype) {
      case "image/png":
      case "image/jpeg":
        return (
          <div
            onClick={() => window.open(media, "_blank")}
            className="chat-media"
          >
            <img src={media} alt="cat" />
          </div>
        );
      case "video/mp4":
      case "video/x-m4v":
      case "video/*":
        return (
          <div className="chat-media">
            <video
              onClick={() => window.open(media, "_blank")}
              width={100}
              controls={false}
            >
              <source src={media} />
            </video>
          </div>
        );
      case ".doc":
      case ".docx":
      case "application/pdf":
      case "application/msword":
      case "application/wps-office.docx":
      case "text/plain ":
      case "application/vnd.openxmlformats-officedocument.wordprocessingml.document":
        return (
          <div
            onClick={() => window.open(media, "_blank")}
            className="chat-media doc doc-icons"
          >
            <DocIcon />
          </div>
        );
    }
  };

  const uploadFiles = async (imageSignedURL, type) => {
    dispatch(showLoader(true));
    if (
      type === "image" ||
      (type === "video" && !Utility.isEmpty(mediasList.size))
    ) {
      const imageURL = await Utility.uploadFile(mediasList, imageSignedURL);
      if (imageURL) {
        sendMessage(imageURL, mediasList.type, MESSAGE_TYPE_IDS.MEDIA);
      }
    }
    if (type == "document" && !Utility.isEmpty(mediasList.size)) {
      const documentURL = await Utility.uploadFile(mediasList, imageSignedURL);
      if (documentURL) {
        sendMessage(documentURL, mediasList.type, MESSAGE_TYPE_IDS.MEDIA);
      }
    }
  };

  const markAsGroupRead = () => {
    Utility.sendRequest(
      `${GROUP_MESSAGES_MARK_READ}?group_id=${props.activeChatDetail.id}&user_client_id=${userData.id}`,
      3,
      {},
      (error, response, body) => {
        body = JSON.parse(body);
        if (body.error)
          toast.error(body.error.message, { containerId: "private" });
        else {
          if (isMount === true) {
            dispatch(updatenewMessage(true));
            setIsMount(false);
          }
        }
      }
    );
  };

  const markAsRead = () => {
    const params = {
      is_read: true,
      qs: {
        to_user_client_id: userData.id,
        from_user_client_id: props.activeChatDetail.id,
      },
    };

    Utility.sendRequest(
      MESSAGE_MARK_AS_READ,
      3,
      params,
      (error, response, body) => {
        body = JSON.parse(body);
        if (body.error)
          toast.error(body.error.message, { containerId: "private" });
        else {
          if (isMount === true) {
            dispatch(updatenewMessage(true));
            setIsMount(false);
          }
        }
      }
    );
  };

  const sendMessage = (mediaurl = "", mediaType, type) => {
    // e.preventDefault();
    const message = MessageData;
    if ((!message || message.trim() === "") && !mediasList) return;

    setMessagesData("");
    let encryptedMessage;
    let senderPrivateKey;
    let receiverPrivateKey;

    if (!Object.keys(props.activeChatDetail).includes("group_private_key")) {
      senderPrivateKey = userData.private_key;
      receiverPrivateKey = props.activeChatDetail.private_key;

      if (
        chatPrivateKry &&
        chatPrivateKry !== "" &&
        senderPrivateKey !== chatPrivateKry
      ) {
        receiverPrivateKey = senderPrivateKey;
        senderPrivateKey = chatPrivateKry;
      }

      if (message)
        encryptedMessage = Utility.encryptedMessage(
          message,
          senderPrivateKey,
          receiverPrivateKey
        );
    } else {
      if (message) {
        encryptedMessage = Utility.encryptedMessage(
          message,
          "",
          "",
          props.activeChatDetail.group_private_key
        );
      }
    }
    const params = {
      from_user_client_id: userData.id,
      new_message: chatPrivateKry === "", //this.props.newMessage,
      media: mediaurl,
      message: encryptedMessage,
      media_mime: mediaType ? mediaType : "",
      message_type_id: type ? type : MESSAGE_TYPE_IDS.TEXT_MESSAGES,
      user_id: userData.user_id,
      document_name: mediasList ? mediasList.name : "",
      source: "network",
      chat_private_key: senderPrivateKey,
    };
    if (Object.keys(props.activeChatDetail).includes("group_private_key")) {
      params.group_id = props.activeChatDetail.id;
    } else {
      params.to_user_client_id = props.activeChatDetail.id;
    }
    Utility.sendRequest(MESSAGES, 2, params, (error, response, body) => {
      body = JSON.parse(body);

      if (body.error) {
        setMessagesData(message);
        dispatch(showLoader(false));
        toast.error(body.error.message, { containerId: "private" });
      } else {
        dispatch(showLoader(false));
        body.message = message;
        setMessagesData("");
        setMediaList(null);
        getAllMessages();
      }
    });
  };

  const scrollMessages = () => {
    var element = document.getElementById("chat-container");
    if (element !== null) element.scrollTop = element.scrollHeight;
  };

  useEffect(() => {
    if (scrollToReplyBox) {
      if (document.getElementById("replyContainerId")) {
        document.getElementById("replyContainerId").scrollIntoView({
          behavior: "smooth",
          block: "nearest",
          inline: "start",
        });
      }
    }
  }, [scrollToReplyBox]);
  return (
    <div>
      <Navbar className={`chat-header d-flex flex-row Message-section-Header align-items-center ${isTab ? "tabSectionMessageHeader" : ""}`}>
        <div
          onClick={() => {
            props.setIsChatClicked(false);
            props.activeChatDetail.is_discussion
              ? dispatch(updateIsChatFormOpen(false))
              : props.setActiveScreenName("Landing")
          }
          }
          className=" cursor-pointer d-inline-flex my-3 align-items-center"
        >
          <ArrowBackIcon />
        </div>
        <div className="d-inline-flex  ">
          {props.activeChatDetail && props.activeChatDetail.is_discussion ? (
            <>
              <div className="d-inline-flex flex-column ">
                <div className="font-weight-bold ml-3 font-18">
                  {props.activeChatDetail && props.activeChatDetail.heading}
                </div>
              </div>
            </>
          ) : (
            !Object.keys(props.activeChatDetail).includes(
              "group_private_key"
            ) && (
              <Avatar
                name={
                  props.activeChatDetail && props.activeChatDetail.user_name
                    ? props.activeChatDetail.user_name
                    : props.activeChatDetail.community_name
                      ? props.activeChatDetail.community_name
                      : props.activeChatDetail.first_name
                        ? props.activeChatDetail.first_name
                        : props.activeChatDetail.name
                }
                round={true}
                src={
                  props.activeChatDetail &&
                    props.activeChatDetail.profile_image_url
                    ? props.activeChatDetail.profile_image_url
                    : props.activeChatDetail.logo_url
                      ? props.activeChatDetail.logo_url
                      : props.activeChatDetail.banner_image_url
                }
                size={"40"}
              />
            )
          )}
        </div>
        {!props.activeChatDetail.is_discussion && (
          <div className="d-inline-flex flex-column ">
            <div className="font-weight-bold ml-3 ">
              {props.activeChatDetail.user_name
                ? props.activeChatDetail.user_name
                : props.activeChatDetail.community_name
                  ? props.activeChatDetail.community_name
                  : props.activeChatDetail.first_name
                    ? `${props.activeChatDetail.first_name} ${props.activeChatDetail.last_name}`
                    : props.activeChatDetail.name}
            </div>
          </div>
        )}

        {Object.keys(props.activeChatDetail).includes("group_private_key") &&
          groupMembersData.length > 0 && (
            <div className="d-flex justify-content-center w-75">
              <div className="d-flex align-items-center justify-contant-center mx-1">
                {groupMembersData.slice(0, 3).map((items, index) => {
                  return (
                    <Avatar
                      size={"40"}
                      key={index}
                      className="mx-1"
                      name={items && items.user_name}
                      round={true}
                      src={items.profile_image_url || ""}
                    />
                  );
                })}
                {groupMembersData.length > 4 && (
                  <p className="mb-0 ml-1 members-Count">{`+ ${groupMembersData.length - 4
                    } people`}</p>
                )}
              </div>
            </div>
          )}
        <div className="message-card-messagesInfo">
          {props.activeChatDetail &&
            props.activeChatDetail.unseen_count > 0 && (
              <p className="message-card-messagesInfo-unseen_count">
                {props.activeChatDetail.unseen_count}
              </p>
            )}
          <p className="message-card-messagesInfo-recent_message_time">
            {props.activeChatDetail.recent_message_time &&
              Utility.returnDuration(
                props.activeChatDetail.recent_message_time
              )}
          </p>
        </div>
      </Navbar>
      {props.activeChatDetail.is_discussion ? (
        <UserCommentContainer
          isRegion={false}
          isChatDiscussion={true}
          isDiscussion={true}
          ActiveLessonId={props.activeChatDetail.id}
        />
      ) : (
        <>
          <div id="chat-container" className="chat-container chatContainerTab scroll-y">
            <div>
              {props.activeChatDetail.is_discussion
                ? DiscussionMessages &&
                DiscussionMessages.length > 0 &&
                DiscussionMessages.map((data) => {
                  return (
                    <>
                      <div key={data.id}>
                        <Discussion
                          commentData={data}
                          iscommentReply={false}
                          isReply={false}
                          isCampaign={true}
                          isChatDiscussion={true}
                          handleSubmitComment={handleSubmitComment}
                          getCommentData={getCommentData}
                          scrollToReplyBox={scrollToReplyBox}
                          setScrollToReplyBox={setScrollToReplyBox}
                        />
                        {data.reply &&
                          data.reply.length > 0 &&
                          data.reply.map((comments, index) => {
                            return (
                              <div
                                className="post-comments ml-auto"
                                key={index}
                              >
                                <Discussion
                                  commentData={comments}
                                  iscommentReply={false}
                                  isReply={false}
                                  isCampaign={true}
                                  isChatDiscussion={true}
                                  handleSubmitComment={handleSubmitComment}
                                  getCommentData={getCommentData}
                                  scrollToReplyBox={scrollToReplyBox}
                                  setScrollToReplyBox={setScrollToReplyBox}
                                />
                                {comments.reply &&
                                  comments.reply.length > 0 &&
                                  comments.reply.map((reply, index) => {
                                    return (
                                      <div
                                        className="comment-replies"
                                        key={index}
                                      >
                                        <Discussion
                                          commentData={reply}
                                          hideReplis={true}
                                          iscommentReply={false}
                                          isReply={false}
                                          isCampaign={true}
                                          isChatDiscussion={true}
                                          handleSubmitComment={
                                            handleSubmitComment
                                          }
                                          getCommentData={getCommentData}
                                          scrollToReplyBox={scrollToReplyBox}
                                          setScrollToReplyBox={
                                            setScrollToReplyBox
                                          }
                                        />
                                      </div>
                                    );
                                  })}
                              </div>
                            );
                          })}
                      </div>
                    </>
                  );
                })
                : Messages &&
                Messages.length > 0 &&
                Messages.map((items) => {
                  return (
                    <div>
                      {items.is_newDate && (
                        <p className="Conversation-Date">
                          {items.FormatedDate}
                        </p>
                      )}
                      <div
                        className={`chatMessage  ${items.from_user_client_id === userData.id
                          ? "chatMessage-me"
                          : "chatMessage-other"
                          }`}
                      >
                        <Avatar
                          className="chatMessage-avatar"
                          name={items && items.sender_name}
                          round={true}
                          src={items && items.profile_image_url}
                          size={"30"}
                        />
                        <div className={`chatMessage-message`}>
                          <div
                            className={`chatMessage-message-text ${items.from_user_client_id === userData.id
                              ? "chatMessage-message-text-me"
                              : "chatMessage-message-text-other"
                              }`}
                          >
                            {items.media &&
                              GetPreview(items.media, items.media_mime)}
                            {items.message &&
                              Utility.validURL(items.message) ? (
                              <Link
                                to={""}
                                className="comment_body"
                                onClick={() =>
                                  window.open(items.message, "_blank")
                                }
                              >
                                {items.message}
                              </Link>
                            ) : htmlRegex.test(items.message) ? (
                              <p
                                dangerouslySetInnerHTML={{
                                  __html: items.message,
                                }}
                                className="mb-0 display-linebreak word-break-all"
                              />
                            ) : (
                              <p className="mb-0 display-linebreak word-break-all">
                                {items.message}
                              </p>
                            )}
                          </div>
                          <div className="font-12 text-secondary">
                            {Utility.returnDuration(items.created_at)}
                          </div>
                        </div>
                      </div>
                    </div>
                  );
                })}
            </div>
            {props.activeChatDetail &&
              !props.activeChatDetail.is_discussion && (
                <div className="stickyNote">
                  <span className="text-container">
                    <HiLockClosed className="EncryptMsgTopIcon" />
                    <p>
                      Messages are end-to-end encrypted. No one outside of this
                      chat, not even WEA Passport, can read them.
                    </p>
                  </span>
                </div>
              )}
          </div>

          <div className="EditorContainer coversationBox">
            <ChatEditor
              editorId="ChatMessageBox"
              onSubmitMessage={(event) => {
                if (props.activeChatDetail.is_discussion) {
                  handleSubmitComment();
                } else {
                  if (!mediasList) {
                    sendMessage(event);
                  } else {
                    uploadMedia(mediasList.type);
                  }
                }
              }}
              isChat={true}
              message={MessageData}
              medias={mediasList}
              setMedia={setMediaList}
              messageValue={setMessagesData}
            />
          </div>
        </>
      )}
    </div>
  );
}

export default Chat;
