import React from 'react';
import { Link } from 'react-router-dom';
import Avatar from 'react-avatar';

export default function GroupCard(props) {
  return (
    <div className="my-group overflow-hidden position-relative">
      <Link to={{
        pathname: props.link,
        isPublicGroup: props.publicGroup,
        calledFrom: props.calledFrom,
        goBackLink: props.goBackLink,
      }}>
        {props.imageUrl ?
          <img src={props.imageUrl} className="my-group-img" alt="img" />
          :
          <Avatar
            name={props.name}
            round={true}
            size={"225"}
            className="pcu-img"
            textSizeRatio={1.75}
            color="#007bb3"
            maxInitials={1}
          />
        }
        {
          props.fromEditGroups &&
          <>
            <span onClick={(e) => { e.preventDefault(); props.openEditGroupForm(props.id); }} className="ml-auto cursor-pointer position-absolute flex-center my-group-edit rounded-circle">
              <img src={require('assets/images/icons/menu_vertical_icon.svg')} alt="" />
            </span>
          </>
        }
        <div className="px-3 py-4">
          <div className="display-7 font-bold mb-1 text-dark" >{props.name}</div>
          <div className="font-13 font-medium text-dark" >
            {
              props.description &&
              props.description.replace(/^(.{10}[^\s]*).*/, "$1") + ' ...'
            }
          </div>
        </div>
      </Link>
    </div>
  );
}
