import { Avatar } from "@material-ui/core";
import ReactAvatar from "react-avatar";
import React, { useEffect } from "react";
import { useState } from "react";
import { MdMoreHoriz, MdMoreVert, MdReplyAll } from "react-icons/md";
import { useDispatch } from "react-redux";
import * as Utility from "utils/utility";
import { toast } from "react-toastify";
import MediaView from "scenes/campaigns/components/media_view";
import {
  API_PATH,
  CREATE_DISCUSSION_FLAG,
  CREATE_DISCUSSION_LIKE,
  DELETE_DISCUSSION_COMMENT,
  FLAG_DICUSSION_COMMENT,
} from "../../../../../config/constants/api_endpoints";
import cookie from "react-cookies";
import { showLoader } from "../../../../actions";
import {
  DownArrowfilled,
  DownArrowUnfilled,
  FilledLikeIcon,
  LikeComment,
  MoreDrop,
  ReplyAllIcon,
  UnfilledLike,
  UpArrowFilled,
  UpArrowunFilled,
} from "../../../../icons/icons";
import axios from "axios";
import moment from "moment";
import { Link } from "react-router-dom";
import { DocumentAdd } from "shared/icons/icons";
import { URL_PATTERN } from "../../../../../config/constants";

const UserComment = (props) => {
  const [isMenu, setIsMenu] = useState(false);
  const dispatch = useDispatch();

  const [isLiked, setIsLiked] = useState(props.commentData.is_liked);

  const handleReport = (e, id) => {
    const params = { comment_id: id };
    dispatch(showLoader(true));
    Utility.sendRequest(FLAG_DICUSSION_COMMENT, 2, params, (err, res, body) => {
      body = JSON.parse(body);
      if (body.error) {
        toast.error(body.error.message, { containerId: "private" });
        dispatch(showLoader(false));
      } else {
        toast.info(body.message, { containerId: "private" });

        dispatch(showLoader(false));
      }
    });
  };

  const handleLike = (isLiked, id) => {
    const params = { comment_id: id, is_liked: isLiked };
    dispatch(showLoader(true));
    Utility.sendRequest(CREATE_DISCUSSION_LIKE, 2, params, (err, res, body) => {
      body = JSON.parse(body);
      if (body.error) {
        toast.error(body.error.message, { containerId: "private" });
        dispatch(showLoader(false));
      } else {
        toast.info(body.message, { containerId: "private" });
        setIsLiked(body.data.is_liked);
        dispatch(showLoader(false));
      }
    });
  };

  const handleDelete = (id) => {
    dispatch(showLoader(true));
    const params = { id: id };
    axios
      .delete(`${API_PATH}${DELETE_DISCUSSION_COMMENT}`, {
        headers: {
          "content-type": "application/json",
          Authorization: `Bearer ${cookie.load("sessionToken")}`,
        },
        data: params,
      })
      .then((res) => {
        dispatch(showLoader(false));
        toast.info(res.message, { containerId: "private" });
        props.getCommentData();
      })
      .catch((err) => {
        dispatch(showLoader(false));
        toast.info(err.response.data.error.message, { containerId: "private" });
      });
  };

  const DownloadButton = (url) => {
    const downloadFile = () => {
      window.location.href = url;
    };
    return (
      <div className="d-flex flex-row align-items-baseline">
        <div>
          <DocumentAdd />
        </div>
        <button onClick={downloadFile} className="m-1 mb-3 btn btn-link">
          Download File
        </button>
      </div>
    );
  };

  return (
    <>
      {/* {props.commentData.description.replace(/<[^>]+>/g, "").trim() !== "" ? ( */}
      <div
        className={`commentBodyContainer d-flex ${
          props.isDiscussion ? "" : props.containerClassToOverRide
        }`}
      >
        <div
          className={`w-90   ${
            props.isDiscussion ? "" : props.secondaryContainerClasses
          }`}
        >
          <div className="commentheader">
            <div
              className={`${props.isChatDiscussion ?  "ChatcommentUserData" : "commentUserData"}  `}
              style={{
                width: props.isRegion && "98%"
              }}
            >
              <>
                <div
                  className={`d-flex flex-row ${
                    props.isDiscussion && "align-items-center"
                  } justify-content-between`}
                >
                  <div
                    className={` d-flex flex-row ${
                      props.isDiscussion ? "align-items-center" : ""
                    }`}
                  >
                    {!props.commentData.owner_details[0].profile_image_url ? (
                      <ReactAvatar
                        name={props.commentData.owner_details[0].name}
                        round={true}
                        size={"15"}
                        textSizeRatio={1.75}
                        color="#007bb3"
                        className={`${
                          props.isDiscussion ? "" : "text-avatar"
                        } mr-1`}
                        maxInitials={1}
                      />
                    ) : (
                      <Avatar
                        src={
                          props.commentData.owner_details[0].profile_image_url
                        }
                        className={`userAvtar ${
                          props.isDiscussion ? "" : "text-avatar"
                        }`}
                      />
                    )}
                    <div className="d-flex flex-column comment-name ml-1">
                      <span className="text-capitalize ">
                        {props.commentData.owner_details[0].network ===
                          "clients" ||
                        props.commentData.owner_details[0].network === "parents"
                          ? props.commentData.owner_details[0].name
                          : props.commentData.owner_details[0].name}
                      </span>
                      {props.isRegion && (
                        <div className="font-13 font-weight-light">
                          {moment(props.commentData.created_at).fromNow()}
                        </div>
                      )}
                    </div>
                  </div>
                  {!props.hideReplis && props.isRegion && (
                    <div
                      className="ReplyIcon"
                      onClick={() => props.handleReply()}
                    >
                      <ReplyAllIcon />
                    </div>
                  )}
                </div>
              </>
            </div>
            {!props.isRegion && (
              <div className="commentTime">
                {moment(props.commentData.created_at).fromNow()}
              </div>
            )}
          </div>
          <span className={props.isDiscussion ? "disCommContainer" : "w-100"}>
            {Utility.validURL(props.commentData.description) ? (
              <Link
                to={""}
                className="comment_body"
                onClick={() =>
                  window.open(props.commentData.description, "_blank")
                }
              >
                {props.commentData.description}
              </Link>
            ) : /<(?=.*? .*?\/ ?>|br|hr|input|!--|wbr)[a-z]+.*?>|<([a-z]+).*?<\/\1>/i.test(
                props.commentData.description
              ) ? (
              <div
                className={`${
                  props.isDiscussion ? "disCommentBody" : "comment_body"
                }`}
                dangerouslySetInnerHTML={{
                  __html: props.commentData.description,
                }}
              ></div>
            ) : (
              <div className="comment_body">
                {props.commentData.description}
              </div>
            )}
            {(props.commentData.image_url.length > 0 ||
              props.commentData.video_url.length > 0) && (
              <div className="comment-media">
                <MediaView
                  width={"100%"}
                  innerClassName="comment-react-player"
                  outerClassName="player-wrapper"
                  imageClassName="d-block w-100 article-page-carousel-main-img"
                  imageUrl={
                    props.commentData.image_url &&
                    props.commentData.image_url[0]
                  }
                  videoUrl={
                    props.commentData.video_url &&
                    props.commentData.video_url[0]
                  }
                  isCarousel={true}
                />
              </div>
            )}

            {props.commentData.file_url.length > 0 && (
              <div className="ml-33p">
                {DownloadButton(props.commentData.file_url)}
              </div>
            )}
          </span>
        </div>
        {!props.isCampaign && (
          <div className="commentLike">
            <div className="reactionContainer">
              <span
                className="cursor-pointer"
                onClick={() => {
                  handleLike(
                    props.commentData.is_liked
                      ? !props.commentData.is_liked
                      : !isLiked,
                    props.commentData.id
                  );
                }}
              >
                {isLiked ? <FilledLikeIcon /> : <LikeComment />}
              </span>
            </div>
          </div>
        )}
        <div className="comment_head">
          <div className="dropdown">
            <div className="commentMenu">
              {!props.hideReplis && !props.isRegion && (
                <div
                  className="ReplyIcon cursor-pointer"
                  onClick={() => props.handleReply()}
                >
                  <ReplyAllIcon />
                </div>
              )}
              <span
                onClick={() => {
                  handleLike(
                    props.commentData.is_liked
                      ? !props.commentData.is_liked
                      : !isLiked,
                    props.commentData.id
                  );
                }}
              >
                {props.isCampaign &&
                  props.isRegion &&
                  (isLiked ? (
                    <div className="filledLikeIcon">
                      <FilledLikeIcon className="width-20" />
                    </div>
                  ) : (
                    <LikeComment className="width-20" />
                  ))}
              </span>
              <div
                className="cursor-pointer moreDrop"
                onClick={() => setIsMenu(!isMenu)}
              >
                <MoreDrop />
              </div>
            </div>
            {isMenu && (
              <div className={`${props.commentData.is_owned ? "menu-container" : "menu-containerReport"} position-absolute`}>
                {props.commentData.is_owned && (
                  <div
                    className="menu_item_data  edit"
                    onClick={() => props.handleEdit()}
                  >
                    <>Edit</>
                    <MdMoreHoriz
                      onClick={(e) => {
                        e.stopPropagation();
                        setIsMenu(!isMenu);
                      }}
                    />
                  </div>
                )}
                <div className="menu_item_data report cursor-pointer">
                  <p onClick={(e) => handleReport(e, props.commentData.id)}>
                    Report
                  </p>{" "}
                  {!props.commentData.is_owned && (
                    <MdMoreHoriz onClick={() => setIsMenu(!isMenu)} />
                  )}
                </div>
                {props.commentData.is_owned && (
                  <div
                    className="menu_item_data delete"
                    onClick={() => handleDelete(props.commentData.id)}
                  >
                    Delete
                  </div>
                )}
              </div>
            )}
          </div>
        </div>
      </div>
      {/* ) : (
        (props.commentData.image_url.length > 0 ||
          props.commentData.video_url.length > 0 ||
          props.commentData.file_url.length > 0) && (
          <div className="comment-only-media  d-flex mt-3">
            <MediaView
              width="100%"
              innerClassName="comment-react-player"
              outerClassName="player-wrapper"
              imageClassName="d-block w-100 article-page-carousel-main-img"
              imageUrl={require("assets/images/articles/article.jpg")}
              videoUrl={require("assets/videos/2 Purpose.mp4")}
              isCarousel={true}
            />
            <div className="comment_media_head">
              <div className="dropdown">
                <div className="commentMenu">
                  {!props.hideReplis && (
                    <div
                      className="ReplyIcon"
                      onClick={() => props.handleReply()}
                    >
                      <ReplyAllIcon />
                    </div>
                  )}
                  <div
                    className="cursor-pointer moreDrop"
                    onClick={() => setIsMenu(!isMenu)}
                  >
                    <MoreDrop />
                  </div>
                </div>
                {isMenu && (
                  <div className="menu-container position-absolute">
                    {props.commentData.is_owned && (
                      <div
                        className="menu_item_data  edit"
                        onClick={() => props.handleEdit()}
                      >
                        <>Edit</>{" "}
                        <MdMoreHoriz onClick={() => setIsMenu(!isMenu)} />
                      </div>
                    )}
                    <div className="menu_item_data report cursor-pointer">
                      <p onClick={(e) => handleReport(e, props.commentData.id)}>
                        Report
                      </p>{" "}
                      {!props.commentData.is_owned && (
                        <MdMoreHoriz onClick={() => setIsMenu(!isMenu)} />
                      )}
                    </div>
                    {props.commentData.is_owned && (
                      <div
                        className="menu_item_data delete"
                        onClick={() => handleDelete(props.commentData.id)}
                      >
                        Delete
                      </div>
                    )}
                  </div>
                )}
              </div>
            </div>
          </div> */}
      {/* {!props.isCampaign && (
        <div className="commentFooter">
          <div className="reactionContainer">
            <span
              onClick={() => {
                handleLike(
                  props.commentData.is_liked
                    ? !props.commentData.is_liked
                    : !isLiked,
                  props.commentData.id
                );
              }}
            >
              {isLiked ? <FilledLikeIcon /> : <LikeComment />}
            </span>
          </div>
        </div>
      )} */}
    </>
  );
};

export default UserComment;
