import React, { Component }  from 'react';
import { Row, Col, Button } from 'react-bootstrap';
import Slider from './banner_slider.js';
import { MdStar, MdStarHalf, MdStarBorder } from 'react-icons/md';
import { Utility } from 'utils';
import ToolDescription from './tool_description';
import ToolsModal from 'shared/components/tools';

export default class MarketPlace extends Component {

  openDescription = () => {
    Utility.showComponent(true, 'tool-description');
  }

  openTools = () => {
    Utility.showComponent(true, 'tools');
  }

  render () {
    return (
      <>
        <Col xl={9} lg={8} xs={12} className="h-md-100 top-on-sm ModalOpen d-none d-lg-block" id="market-place" >
          {/* Heading */}
          <div className="m-4 p-sm-3" >
            <div className="align-items-center justify-content-between">
              <div className="align-items-center">
                  <div className="round-circle d-flex-align-center" />
                  <span className="font-30-bold pl-2">Market Place</span>
              </div>
              <Button varaint="primary" className="btn-toolbox text-center" onClick={this.openTools} >
                <img src={require("assets/images/tip_icon.svg")} alt="Sharemeister Logo Symbol" />
                Tools
              </Button>
            </div>
          </div>
          {/* Slider */}
          <Slider />
          {/* Tools List */}
          <Row className="no-gutters px-sm-3 pt-sm-3 mx-4 mt-4 market-place-apps scroll-y">
            <h4 className="pb-sm-3 pb-4 w-100 display-sm-7">Products Marketplace</h4>
            <Col xl={4} sm={6} className="p-sm-3 mb-sm-0 mb-3" >
              <div className="border-bottom-dotted d-flex pb-4">
                <div className="mr-3">
                  <img src={require('assets/images/tools/market_place/app_thumb_placeholder.svg')} alt="sharemeister-app" className="market-place-img"  />
                </div>
                <div>
                  <h5 className="display-sm-8 font-bold" >Product Name</h5>
                  <div className="display-9">Network Name</div>
                  <div className="shar-product-ratings display-7 mb-2">
                    <MdStar />
                    <MdStar />
                    <MdStar />
                    <MdStarHalf />
                    <MdStarBorder />
                  </div>
                  <div className="align-items-center">
                    <Button variant="primary-outlined" className="market-place-add-btn" onClick={this.openDescription} >Add</Button>
                    <span className="display-11 ml-2" >In-App Purchases</span>
                  </div>
                </div>
              </div>
            </Col>
            <Col xl={4} sm={6} className="p-sm-3 mb-sm-0 mb-3" >
              <div className="border-bottom-dotted d-flex pb-4">
                <div className="mr-3">
                  <img src={require('assets/images/tools/market_place/app_thumb_placeholder.svg')} alt="sharemeister-app" className="market-place-img"  />
                </div>
                <div>
                  <h5 className="display-sm-8 font-bold" >Product Name</h5>
                  <div className="display-9">Network Name</div>
                  <div className="shar-product-ratings display-7 mb-2">
                    <MdStar />
                    <MdStar />
                    <MdStar />
                    <MdStarHalf />
                    <MdStarBorder />
                  </div>
                  <div className="align-items-center">
                    <Button variant="primary-outlined" className="market-place-add-btn" >Add</Button>
                    <span className="display-11 ml-2" >In-App Purchases</span>
                  </div>
                </div>
              </div>
            </Col>
            <Col xl={4} sm={6} className="p-sm-3 mb-sm-0 mb-3" >
              <div className="border-bottom-dotted d-flex pb-4">
                <div className="mr-3">
                  <img src={require('assets/images/tools/market_place/app_thumb_placeholder.svg')} alt="sharemeister-app" className="market-place-img"  />
                </div>
                <div>
                  <h5 className="display-sm-8 font-bold" >Product Name</h5>
                  <div className="display-9">Network Name</div>
                  <div className="shar-product-ratings display-7 mb-2">
                    <MdStar />
                    <MdStar />
                    <MdStar />
                    <MdStarHalf />
                    <MdStarBorder />
                  </div>
                  <div className="align-items-center">
                    <Button variant="primary-outlined" className="market-place-add-btn" >Add</Button>
                    <span className="display-11 ml-2" >In-App Purchases</span>
                  </div>
                </div>
              </div>
            </Col>
            <Col xl={4} sm={6} className="p-sm-3 mb-sm-0 mb-3" >
              <div className="border-bottom-dotted d-flex pb-4">
                <div className="mr-3">
                  <img src={require('assets/images/tools/market_place/app_thumb_placeholder.svg')} alt="sharemeister-app" className="market-place-img"  />
                </div>
                <div>
                  <h5 className="display-sm-8 font-bold" >Product Name</h5>
                  <div className="display-9">Network Name</div>
                  <div className="shar-product-ratings display-7 mb-2">
                    <MdStar />
                    <MdStar />
                    <MdStar />
                    <MdStarHalf />
                    <MdStarBorder />
                  </div>
                  <div className="align-items-center">
                    <Button variant="primary-outlined" className="market-place-add-btn" >Add</Button>
                    <span className="display-11 ml-2" >In-App Purchases</span>
                  </div>
                </div>
              </div>
            </Col>
            <Col xl={4} sm={6} className="p-sm-3 mb-sm-0 mb-3" >
              <div className="border-bottom-dotted d-flex pb-4">
                <div className="mr-3">
                  <img src={require('assets/images/tools/market_place/app_thumb_placeholder.svg')} alt="sharemeister-app" className="market-place-img"  />
                </div>
                <div>
                  <h5 className="display-sm-8 font-bold" >Product Name</h5>
                  <div className="display-9">Network Name</div>
                  <div className="shar-product-ratings display-7 mb-2">
                    <MdStar />
                    <MdStar />
                    <MdStar />
                    <MdStarHalf />
                    <MdStarBorder />
                  </div>
                  <div className="align-items-center">
                    <Button variant="primary-outlined" className="market-place-add-btn" >Add</Button>
                    <span className="display-11 ml-2" >In-App Purchases</span>
                  </div>
                </div>
              </div>
            </Col>
            <Col xl={4} sm={6} className="p-sm-3 mb-sm-0 mb-3" >
              <div className="border-bottom-dotted d-flex pb-4">
                <div className="mr-3">
                  <img src={require('assets/images/tools/market_place/app_thumb_placeholder.svg')} alt="sharemeister-app" className="market-place-img"  />
                </div>
                <div>
                  <h5 className="display-sm-8 font-bold" >Product Name</h5>
                  <div className="display-9">Network Name</div>
                  <div className="shar-product-ratings display-7 mb-2">
                    <MdStar />
                    <MdStar />
                    <MdStar />
                    <MdStarHalf />
                    <MdStarBorder />
                  </div>
                  <div className="align-items-center">
                    <Button variant="primary-outlined" className="market-place-add-btn" >Add</Button>
                    <span className="display-11 ml-2" >In-App Purchases</span>
                  </div>
                </div>
              </div>
            </Col>
            <Col xl={4} sm={6} className="p-sm-3 mb-sm-0 mb-3" >
              <div className="border-bottom-dotted d-flex pb-4">
                <div className="mr-3">
                  <img src={require('assets/images/tools/market_place/app_thumb_placeholder.svg')} alt="sharemeister-app" className="market-place-img"  />
                </div>
                <div>
                  <h5 className="display-sm-8 font-bold" >Product Name</h5>
                  <div className="display-9">Network Name</div>
                  <div className="shar-product-ratings display-7 mb-2">
                    <MdStar />
                    <MdStar />
                    <MdStar />
                    <MdStarHalf />
                    <MdStarBorder />
                  </div>
                  <div className="align-items-center">
                    <Button variant="primary-outlined" className="market-place-add-btn" >Add</Button>
                    <span className="display-11 ml-2" >In-App Purchases</span>
                  </div>
                </div>
              </div>
            </Col>
            <Col xl={4} sm={6} className="p-sm-3 mb-sm-0 mb-3" >
              <div className="border-bottom-dotted d-flex pb-4">
                <div className="mr-3">
                  <img src={require('assets/images/tools/market_place/app_thumb_placeholder.svg')} alt="sharemeister-app" className="market-place-img"  />
                </div>
                <div>
                  <h5 className="display-sm-8 font-bold" >Product Name</h5>
                  <div className="display-9">Network Name</div>
                  <div className="shar-product-ratings display-7 mb-2">
                    <MdStar />
                    <MdStar />
                    <MdStar />
                    <MdStarHalf />
                    <MdStarBorder />
                  </div>
                  <div className="align-items-center">
                    <Button variant="primary-outlined" className="market-place-add-btn" >Add</Button>
                    <span className="display-11 ml-2" >In-App Purchases</span>
                  </div>
                </div>
              </div>
            </Col>
          </Row>
        </Col>
        {/* Tools description */}
        <ToolDescription />
        <ToolsModal />
      </>
    );
  }
}
