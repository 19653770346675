import React, { useEffect, useState } from "react";
import EventLeftPanel from "./eventLeftPanel";
import EventRightPanel from "./eventRightPanel";
import EventsHeader from "./EventsHeader";
import {
  CAMPAIGN_DETAILS,
  CAMPAIGN_IS_LIKED,
  CAMPAIGN_SENTIMENT_DETAILS,
  EVENT_MEMBERS,
} from "../../../config/constants/api_endpoints";
import { CLIENT_ID } from "../../../config/constants/config";
import { useSelector } from "react-redux";
import { CAMPAIGN_TYPES_IDS } from "../../../config/constants";
import { Utility } from "utils";
import { toast } from "react-toastify";
import { useLocation } from "react-router-dom";
import { useDispatch } from "react-redux";
import { useMediaQuery, Card, CardContent, Skeleton } from "@mui/material";
import { showLoader } from "../../actions";

const EventDetails = () => {
  const userData = useSelector((state) => state.userData);
  const [eventDetails, setEventDetails] = useState({});
  const [isLiked, setLiked] = useState(false);
  const isTablet = useMediaQuery("(max-width: 1200px)");
  const [isEventDetailsLoading, setIsEventDetailsLaoding] = useState(false);
  const [isCampaignLikeLoading, setIsCampaignLikeLoading] = useState(false);

  // const campaign_id = "391288d0-3c7c-11ec-a4d5-69c2978f0ae2";
  const location = useLocation();
  const dispatch = useDispatch();
  const [attendeeList, setAttendeeList] = useState([]);
  const campaign_id = location.pathname.split("/")[2];

  useEffect(() => {
    getEventMember();
  }, []);

  const getEventMember = () => {
    const params = {
      campaignId: campaign_id,
    };

    Utility.sendRequest(`${EVENT_MEMBERS}`, 1, params, (err, res, body) => {
      body = JSON.parse(body);

      if (!body.error) {
        setAttendeeList(
          body.map((e) => {
            return e;
          })
        );
      }
    });
  };

  const getCampaignDetails = () => {
    // this.props.showLoader(true);
    dispatch(showLoader(true));
    setIsEventDetailsLaoding(true);
    const params = {
      user_client_id: userData.id,
      campaign_id: campaign_id,
      publisher_network_id: CLIENT_ID,
    };

    Utility.sendRequest(CAMPAIGN_DETAILS, 1, params, (err, res, body) => {
      body = JSON.parse(body);

      if (body.error) {
        toast.error(body.error.message, { containerId: "private" });
      } else {
        if (body.data.length > 0) {
          // TODO
          // Hiding sections data for courses.
          const campaigns_data = body.data.filter(
            (item) => item.campaign_type_id !== CAMPAIGN_TYPES_IDS.section
          );
          setEventDetails(campaigns_data[0]);
        }
      }

      //   this.props.showLoader(false);
      dispatch(showLoader(false));
      setIsEventDetailsLaoding(false);
    });
  };

  const getSentimentDetails = () => {
    const params = {
      user_client_id: userData.id,
      campaign_id: campaign_id,
      client_id: CLIENT_ID,
    };

    Utility.sendRequest(
      CAMPAIGN_SENTIMENT_DETAILS,
      2,
      params,
      (err, res, body) => {
        body = JSON.parse(body);
        if (body.error) {
          toast.error(body.error.message, { containerId: "private" });
        } else {
          // this.setState({ isLiked: body.is_active }, () => handleLikesCount());
          setLiked(body.is_active);
          getCampaignDetails();
          getCampaignLiked();
        }
      }
    );
  };

  const getCampaignLiked = () => {
    // this.props.showLoader(true);

    setIsCampaignLikeLoading(true);
    const params = {
      campaign_id: campaign_id,
      user_client_id: userData.id,
      client_id: CLIENT_ID,
    };

    Utility.sendRequest(CAMPAIGN_IS_LIKED, 1, params, (err, res, body) => {
      body = JSON.parse(body);
      if (body.error) {
        toast.error(body.error.message, { containerId: "private" });
      } else {
        setLiked(body.is_active);
      }

      // this.props.showLoader(false);
      setIsCampaignLikeLoading(false);
    });
  };

  useEffect(() => {
    getCampaignDetails();
    getCampaignLiked();
  }, []);

  return (
    Object.keys(eventDetails).length !== 0 && (
      <div className="background-sidebar-pages">
        {eventDetails && (
          <EventsHeader
            eventDetails={eventDetails}
            isLiked={isLiked}
            getSentimentDetails={getSentimentDetails}
            isCampaignLikeLoading={isCampaignLikeLoading}
          />
        )}
        <div
          className={`${
            !isTablet
              ? "d-flex justify-content-between eventDetailsContainer  background-sidebar-pages"
              : "eventDetailsContainer  background-sidebar-pages"
          }`}
        >
          {isEventDetailsLoading ? (
            <Card
              style={{
                borderRadius: "10px",
                margin: "0 auto",
                width: isTablet ? "700px" : "800px",
              }}
            >
              <Skeleton
                animation="wave"
                variant="rectangular"
                width="100%"
                height={350}
              />
              <CardContent>
                <Skeleton animation="wave" variant="text" height={30} />
                <Skeleton animation="wave" variant="text" height={30} />
                <Skeleton animation="wave" variant="text" height={30} />
                <Skeleton animation="wave" variant="text" height={30} />
              </CardContent>
            </Card>
          ) : (
            <EventLeftPanel
              campaign_id={campaign_id}
              eventDetails={eventDetails}
              getEventMember={getEventMember}
              userData={userData}
              attendeeList={attendeeList}
            />
          )}
          {!isTablet ? (
            <EventRightPanel
              attendeeList={attendeeList}
              getEventMember={getEventMember}
              eventDetails={eventDetails}
              userData={userData}
            />
          ) : (
            ""
          )}
        </div>
      </div>
    )
  );
};

export default EventDetails;
