import React, { Component, Fragment } from "react";
import cookie from "react-cookies";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import {
  updateUserData,
  showLoader,
  updateAccountList,
  userQualities,
  updateCampaignFormOpen,
  openNeighborhoodForm,
  updateIsCitizenSidebarOpen,
  updateSeeAll,
  updateIsNeighborhoodFormOpen,
  openOrClosePlusIcon,
  updateCampaignSelectedTab,
  updateIsCampSelFormOpen,
  updatenewMessage,
  updateIsChatFormOpen,
  updateChatFriend,
  headerSearchBar,
} from "shared/actions";
import { connect } from "react-redux";
import { Utility } from "utils";
import {
  GET_ACCOUNTS,
  USER_AUTH,
  SWITCH_ACCOUNT,
  CLIENT_NOTIFICATIONS,
} from "config/constants/api_endpoints";
import {
  ALL_PARENT_CAMPAIGNS,
  GET_TAGS_BY_CATEGORIES,
  GROUPS,
  GET_MY_DONATIONS,
  GET_USERS_BY_NETWORKS,
} from "config/constants/api_endpoints";
import { css } from "@emotion/core";
import { toast } from "react-toastify";
// import ClipLoader from "react-spinners/ClipLoader";
import { Dropdown, Image } from "react-bootstrap";
import { NETWORK, CLIENT_ID } from "config/constants/config";
import { NeighborhoodSignUp } from "scenes/users/components/neighborhood_sign_up";
import {
  ROLE_IDS,
  SM_CLIENT_ID,
  CAMPAIGN_TYPES_IDS,
  PASSPORT_TOOL_ID,
  GIL_CLIENT_ID,
} from "config/constants";
import WebNotification from "./web_notification";
import {
  PersonOutlinedIcon,
  AddIcon,
  TickBoldIcon,
  AddBoldSmallIcon,
} from "shared/icons/icons";
import CampaignSelectionForm from "../../scenes/users/components/create_campaign/campaign_selection_form";
import { ChatSqaureIcon } from "shared/icons/icons";
import Avatar from "react-avatar";
import socket from "utils/sockets.js";
import SideCallout from "shared/components/templates/side_callout";
import { Invite } from "scenes/networks/components/invite.js";
import { Link } from "react-router-dom";
import { HeaderDropIcon } from "../icons/icons";

import { Search } from "@mui/icons-material";
import PeopleAltIcon from "@mui/icons-material/PeopleAlt";
import CommunityChat from "./CommunityChat";
import Communities from "./v2/communities";
import { BsPlusCircle } from "react-icons/bs";
import Create_course from "./cards/create_course";
import News_Confirmation from "./v2/news_confirmation";
import { getNeighborhoodsOfUser } from "../../services/profile";
import {
  API_PATH,
  GETMYALLCHATFRIENDS,
  GET_USER_QUALITY,
} from "../../config/constants/api_endpoints";
import { has } from "lodash";
import Axios from "axios";
import { Backdrop, CircularProgress, Skeleton } from "@mui/material";

const override = css`
  display: block;
  margin: 0 auto;
  border-color: red;
  z-index: 100;
  position: absolute;
  top: 50%;
  left: 50%;
  font-size: 50px;
  color: white;
  transform: translate(-50%, -50%);
`;

class Header extends Component {
  constructor(props) {
    super(props);
    this.state = {
      pwaEnable: false,
      isEdit: false,
      searchValue: "",
      isUnreadMessages: false,
      isNeighborhood: false,
      isUserLogedIn: cookie.load("sessionToken") !== undefined ? true : false,
      accountList: {},
      notificationData: {},
      loader: {
        auth: false,
        switchAcc: false,
        getAllAcc: false,
      },
      showArticles: false,
      showMyCalling: false,
      switchAccountId: "",
      showGroups: false,
      showMyEvents: false,
      showMyCourses: false,
      articleId: null,
      showMyDonations: false,
      showInviteForm: false,
      isEditPassportFormOpen: false,
      showMyFollowersFollowing: false,
      showAllPeople: false,
      isFocused: false,
      userDropSearch: "",
    };

    if (this.state.isUserLogedIn) this.getAllAccount();
  }

  getUserQualities = () => {
    const params = {};
    Utility.sendRequest(
      `${GET_USER_QUALITY}?user_client_id=${this.props.userData.id}`,
      1,
      params,
      (error, response, body) => {
        body = JSON.parse(body);
        // this.props.showLoader(false);

        if (!body.error) {
          if (body.data && Object.keys(body.data).includes("user_qualities")) {
            if (has(body.data.user_qualities, "length")) {
              console.log(this.props.userQualities);
              this.props.userQualities(body.data.user_qualities);
            }
          } else {
            this.props.userQualities([]);
          }
        }
      }
    );
  };

  getUserData = async () => {
    const locationData = new URLSearchParams(this.props.location.search);

    if (
      locationData.get("type") === "chat" &&
      locationData.get("token") &&
      locationData.get("token") !== null
    ) {
      try {
        cookie.save("sessionToken", locationData.get("token"), { path: "/" });
        this.setState((prevState) => ({
          loader: { ...prevState.loader, auth: true },
        }));
        const response = await Axios.get(`${API_PATH}${USER_AUTH}`, {
          headers: { Authorization: `Bearer ${locationData.get("token")}` },
        });
        if (response)
          this.setState((prevState) => ({
            loader: { ...prevState.loader, auth: false },
          }));
        this.setState({
          isUserLogedIn: true,
        });
        this.getAllAccount();

        this.props.updateUserData(response.data);
        this.checkLocal(response.data);
      } catch (err) {
        console.log("error in USER_AUTH | layout");
      }

      this.props.updateIsChatFormOpen(true);
    }
  };

  componentDidMount = () => {
    this.enablePwa();
    this.getUserAllChatsUser();
    this.getUserQualities();
    this.getAllNotifications();
    socket.on("chats", () => {
      this.getUserAllChatsUser();
    });
    socket.on("notifications", this.listenForNotifications);

    let lastKnownScrollY = 0;
    let currentScrollY = 0;
    let ticking = false;
    const idOfHeader = "header";
    let eleHeader = null;
    const classes = {
      pinned: "header-pin",
      unpinned: "header-unpin",
    };

    this.getUserData();

    // const onScroll = () => {
    //   currentScrollY = window.pageYOffset;
    //   requestTick();
    // };

    // const requestTick = () => {
    //   if (!ticking) {
    //     requestAnimationFrame(update);
    //   }
    //   ticking = true;
    // };

    // const update = () => {
    //   if (currentScrollY < lastKnownScrollY) {
    //     pin();
    //   } else if (currentScrollY > lastKnownScrollY && lastKnownScrollY > 100) {
    //     unpin();
    //   }
    //   lastKnownScrollY = currentScrollY;
    //   ticking = false;
    // };

    // const pin = () => {
    //   if (eleHeader.classList.contains(classes.unpinned)) {
    //     eleHeader.classList.remove(classes.unpinned);
    //     eleHeader.classList.add(classes.pinned);
    //   }
    // };

    // const unpin = () => {
    //   if (
    //     eleHeader.classList.contains(classes.pinned) ||
    //     !eleHeader.classList.contains(classes.unpinned)
    //   ) {
    //     eleHeader.classList.remove(classes.pinned);
    //     eleHeader.classList.add(classes.unpinned);
    //   }
    // };

    // window.addEventListener("scroll", () => {
    //   eleHeader = document.getElementById(idOfHeader);
    //   onScroll();
    // });
  };

  componentDidUpdate = (prevState, prevProps) => {
    this.props.history.listen(() => {
      if (this.props.seeAll) {
        this.props.headerSearchBar("");
        this.setState({
          searchValue: "",
        });
        this.props.updateSeeAll({ isOpen: null, calledFrom: null });
      }
      if (this.props.isChatFormOpen) {
        this.props.updateIsChatFormOpen(false);
      }
    });
    if (
      prevProps.newMessages !== this.props.newMessages &&
      this.props.newMessages === true
    ) {
      this.getUserAllChatsUser();
      // this.getGroups();
    }
    if (prevState.showInviteForm !== this.state.showInviteForm) {
      if (this.state.showInviteForm || this.state.showMyCalling) {
        document.querySelector("body").classList.add("overflow-hidden");
      } else {
        document.querySelector("body").classList.remove("overflow-hidden");
      }
    }

    if (
      (this.props.userData && this.props.userData.id) !==
      (prevProps.userData && prevProps.userData.id)
    ) {
      this.props.updateIsCitizenSidebarOpen(false);
    }

    if (prevProps.isPlusIconClicked !== this.props.isPlusIconClicked) {
      if (this.props.isPlusIconClicked) {
        document.querySelector("body").classList.add("overflow-hidden");
      } else {
        document.querySelector("body").classList.remove("overflow-hidden");
      }
    }

    // this.getUserData();
  };

  handleEventId = (id) => {
    this.setState({
      articleId: id,
    });
  };

  getGroups = () => {
    const params = {
      clientId: GIL_CLIENT_ID,
      userClientId: this.props.userData.id,
      limit: "",
      forMyGroups: true,
    };

    Utility.sendRequest(GROUPS, 1, params, (err, res, body) => {
      body = JSON.parse(body);
      if (body.error) {
        toast.error(body.error.message, { containerId: "private" });
      } else {
        if (body && body.some((item) => item.unread_messages_count > 0)) {
          this.setState({
            isUnreadMessages: true,
          });
        } else {
          this.setState({
            isUnreadMessages: false,
          });
        }
        this.props.updatenewMessage(false);
      }
    });
  };

  getUserAllChatsUser = () => {
    const params = {
      user_client_id: this.props.userData.id,
    };
    Utility.sendRequest(GETMYALLCHATFRIENDS, 1, params, (err, res, body) => {
      body = JSON.parse(body);
      if (body.error) {
        console.log(body.error);
      } else {
        if (
          body.uniqueArr &&
          body.uniqueArr.some((item) => item.unseen_count > 0)
        ) {
          this.setState({
            isUnreadMessages: true,
          });
        } else {
          this.setState({
            isUnreadMessages: false,
          });
        }
        this.props.updatenewMessage(false);
      }
    });
  };

  listenForNotifications = (newNotification) => {
    if (!Utility.isEmpty(newNotification)) this.getAllNotifications();
  };

  enablePwa = () => {
    const pwaEnable = Utility.pwaModeEnabled();

    this.setState({ pwaEnable });
  };

  getAllAccount = () => {
    // debugger
    // this.props.showLoader(true);
    this.setState((prevState) => ({
      loader: { ...prevState.loader, getAllAcc: true },
    }));
    Utility.sendRequest(GET_ACCOUNTS, 1, {}, (error, response, body) => {
      body = JSON.parse(body);
      // this.props.showLoader(false);
      this.setState((prevState) => ({
        loader: { ...prevState.loader, getAllAcc: false },
      }));

      if (!body.error) {
        if (this.props.userData.platform_id === SM_CLIENT_ID) {
          const smData = body;
          smData.Clients = [];
          this.setState({ accountList: smData });
          this.props.updateAccountList(smData);
        } else {
          this.setState({ accountList: body });
          this.props.updateAccountList(body);
          getNeighborhoodsOfUser({
            userData: this.props.userData,
            accountList: body,
          });
        }
      } else {
        toast.error(body.error.message, { containerId: "public" });
      }
    });
  };

  gotToProfile = () => {
    this.props.history.push("/profile");
  };

  gotToHome = () => {
    if (this.state.pwaEnable) this.showMenu();
    this.props.history.push("/");
  };

  action = (check) => {
    if (check === "Login") {
      this.props.history.push(
        this.state.pwaEnable ? "/sign_in" : "/users/sign_in"
      );
      return;
    }

    this.props.updateUserData({});
    this.props.showLoader(true);
    Utility.clearSessionAndRedirectToHomePage();
  };

  switchAccount = (id, data, dropdown = true) => {
    this.setState({ isEdit: false });
    this.setState({ isNeighborhood: false });
    this.props.updateIsNeighborhoodFormOpen(false);
    this.props.openNeighborhoodForm({});
    if (id)
      this.setState({
        switchAccountId: id,
      });
    // this.props.showLoader(true);
    this.setState((prevState) => ({
      loader: { ...prevState.loader, switchAcc: true },
    }));

    Utility.sendRequest(SWITCH_ACCOUNT, 1, { id }, (error, response, body) => {
      body = JSON.parse(body);
      this.setState((prevState) => ({
        loader: { ...prevState.loader, switchAcc: false },
      }));

      if (body.error) {
        toast.error(body.error.message, { containerId: "public" });
        // this.props.showLoader(false);
      } else {
        cookie.save("sessionToken", body.token, { path: "/" });
        this.setState((prevState) => ({
          loader: { ...prevState.loader, auth: true },
        }));
        Utility.sendRequest(USER_AUTH, 1, { id }, (error, response, body) => {
          body = JSON.parse(body);
          this.setState((prevState) => ({
            loader: { ...prevState.loader, auth: false },
          }));
          // this.props.showLoader(false);

          if (!body.error) {
            this.props.updateUserData(body);

            if (window.location.pathname !== "/profile") {
              this.props.history.push({
                pathname: "/profile",
                state: { data },
              });
            }
            this.props.updateIsChatFormOpen(false);
            this.props.updateChatFriend({});
            if (dropdown) this.closeDropDown();
          } else {
            toast.error(body.error.message, { containerId: "public" });
          }
        });
      }
    });
    // }
  };

  getAllNotifications = () => {
    const params = { ":id": this.props.userData.id };
    const baseURL = Utility.getAPIEndpointWithParams(
      CLIENT_NOTIFICATIONS,
      params
    );
    Utility.sendRequest(baseURL, 1, {}, (error, response, body) => {
      body = JSON.parse(body);

      if (!body.error) {
        this.setState({ notificationData: body.data });
      } else {
        toast.error(body.error.message, { containerId: "public" });
      }
    });
  };

  openNeighborhoodForm = (userData) => {
    const element = document.getElementsByClassName("btn-primary");
    const data = {
      showForm: !userData && true,
      edit: userData ? true : false,
      id: userData && userData.id,
      roleId: userData && userData.role_id,
    };

    if (element) element[0].click();
    this.props.openNeighborhoodForm(data);
    this.props.updateIsNeighborhoodFormOpen(this.state.isNeighborhood);
  };

  editCloseForm = () => {
    this.setState({ isEdit: false });
    this.setState({ isNeighborhood: false });
    this.props.updateIsNeighborhoodFormOpen(false);
    this.props.openNeighborhoodForm({});
  };

  showMenu = () => {
    const body = document.getElementsByTagName("body")[0];

    if (body.classList.contains("overflow-hidden"))
      body.classList.remove("overflow-hidden");
  };

  showBellIndicator = () => {
    let flag = false;
    !Utility.isEmpty(this.state.notificationData) &&
      this.state.notificationData.map((e) => {
        if (!e.is_read) flag = true;
      });
    return flag;
  };

  closeDropDown = () => {
    const elem = document.getElementById("dropdown-basic-header");
    if (elem) elem.click();
  };

  setPlusIcon = () => {
    if (!this.props.isPlusIconClicked) this.props.openOrClosePlusIcon(true);
    else this.props.openOrClosePlusIcon(false);
  };

  handleTabSelectionClick = (arg) => {
    this.props.updateIsCampSelFormOpen(false);
    this.props.updateCampaignSelectedTab(arg);
    this.props.updateCampaignFormOpen(true);
    this.props.openOrClosePlusIcon(false);
  };

  openEditPassportForm = (id) => {
    this.getTagsByCategories(id);
    this.setState({ isEditPassportFormOpen: true });
  };

  handleChatClick = (item) => {
    this.props.updateIsChatFormOpen(!this.props.isChatFormOpen);
    this.props.updateChatFriend(item);
  };

  handleSearch = (ev) => {
    ev.persist();
    this.setState({
      searchValue: ev.target.value,
    });

    if (!this.debouncedFn) {
      this.debouncedFn = Utility.debounce(() => {
        const searchString = ev.target.value;
        this.props.headerSearchBar(searchString);
      }, 500);
    }
    this.debouncedFn();
  };
  HomeIcon() {
    return (
      <svg
        width="25"
        height="24"
        viewBox="0 0 25 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M19.4286 13.7143C20.3715 13.7143 21.1429 14.4869 21.1429 15.4286C21.1429 16.3703 20.3715 17.1429 19.4286 17.1429C18.4858 17.1429 17.7144 16.3703 17.7144 15.4286C17.7144 14.4869 18.4858 13.7143 19.4286 13.7143Z"
          fill="#232323"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M21.7144 24H17.1429V20.5714C17.1429 19.3097 18.1669 18.2857 19.4287 18.2857C20.6904 18.2857 21.7144 19.3097 21.7144 20.5714V24Z"
          fill="#232323"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M12.5715 13.7143C13.5143 13.7143 14.2857 14.4869 14.2857 15.4286C14.2857 16.3703 13.5143 17.1429 12.5715 17.1429C11.6286 17.1429 10.8572 16.3703 10.8572 15.4286C10.8572 14.4869 11.6286 13.7143 12.5715 13.7143Z"
          fill="#232323"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M14.8572 24H10.2858V20.5714C10.2858 19.3097 11.3098 18.2857 12.5715 18.2857C13.8332 18.2857 14.8572 19.3097 14.8572 20.5714V24Z"
          fill="#232323"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M5.71429 13.7143C6.65714 13.7143 7.42857 14.4869 7.42857 15.4286C7.42857 16.3703 6.65714 17.1429 5.71429 17.1429C4.77143 17.1429 4 16.3703 4 15.4286C4 14.4869 4.77143 13.7143 5.71429 13.7143Z"
          fill="#232323"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M8.00002 24H3.42859V20.5714C3.42859 19.3097 4.45259 18.2857 5.7143 18.2857C6.97602 18.2857 8.00002 19.3097 8.00002 20.5714V24Z"
          fill="#232323"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M23.2863 12.3214L12.5721 3.74995L1.85663 12.3214L0.429199 10.5362L12.5721 0.821945L24.7138 10.5362L23.2863 12.3214Z"
          fill="#232323"
        />
      </svg>
    );
  }

  EventIcon() {
    return (
      <svg
        width="25"
        height="25"
        viewBox="0 0 25 25"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M0 12.5C0 5.59476 5.59476 0 12.5 0C19.4052 0 25 5.59476 25 12.5C25 19.4052 19.4052 25 12.5 25C5.59476 25 0 19.4052 0 12.5ZM9.46069 18.8004L18.3317 13.7097C19.1583 13.251 19.1583 12.0565 18.3317 11.5927L9.46069 6.1996C8.65927 5.75605 7.66129 6.33065 7.66129 7.25806V17.7419C7.66129 18.6744 8.66431 19.244 9.46069 18.8004Z"
          fill="#ff9900"
        />
      </svg>
    );
  }

  MediaIcon() {
    return (
      <svg
        width="25"
        height="25"
        viewBox="0 0 25 25"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M6.94444 6.94444C7.32798 6.94444 7.63889 6.63353 7.63889 6.25V2.08333C7.63889 1.6998 7.32798 1.38889 6.94444 1.38889C6.56091 1.38889 6.25 1.6998 6.25 2.08333V6.25C6.25 6.63353 6.56091 6.94444 6.94444 6.94444Z"
          fill="#272262"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M15.625 4.16666C15.625 7.04314 17.9569 9.37499 20.8333 9.37499C21.817 9.37601 22.7806 9.09657 23.6111 8.56944V20.9653C23.6167 21.2931 23.4918 21.6098 23.2639 21.8455C23.0359 22.0812 22.7237 22.2167 22.3958 22.2222H2.60417C2.27632 22.2167 1.96408 22.0812 1.73616 21.8455C1.50824 21.6098 1.38332 21.2931 1.3889 20.9653V5.42361C1.38325 5.08975 1.5129 4.76781 1.74835 4.53105C1.9838 4.2943 2.30502 4.16286 2.6389 4.16666H5.41667V6.24999C5.41667 7.09376 6.10068 7.77777 6.94445 7.77777C7.78822 7.77777 8.47223 7.09376 8.47223 6.24999V4.16666H15.625ZM11.2986 18.2292L7.6389 14.5417L11.2986 18.2292ZM7.6389 14.5417C7.30229 14.1714 7.31284 13.603 7.66294 13.2455L7.6389 14.5417ZM7.66294 13.2455C8.01305 12.888 8.58119 12.8656 8.95834 13.1944L7.66294 13.2455ZM8.95834 13.1944L11.3056 15.5417L8.95834 13.1944ZM11.3056 15.5417L16.6667 10.1805L11.3056 15.5417ZM16.6667 10.1805C16.9061 9.94113 17.255 9.84763 17.5821 9.93526L16.6667 10.1805ZM17.5821 9.93526C17.9092 10.0229 18.1646 10.2784 18.2522 10.6054L17.5821 9.93526ZM18.2522 10.6054C18.3398 10.932 18.2466 11.2805 18.0079 11.5198L18.2522 10.6054Z"
          fill="#232323"
        />
        <circle cx="20.8333" cy="4.16667" r="3.47222" fill="#232323" />
      </svg>
    );
  }

  GroupIcon() {
    return (
      <svg
        width="25"
        height="24"
        viewBox="0 0 25 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M5.68182 14.7413V17.0432C4.27159 17.4435 3.40909 17.9662 3.40909 18.5411C3.40909 19.768 7.31062 20.7666 12.1874 20.8077L12.5 20.809C17.5216 20.809 21.5909 19.7942 21.5909 18.5411C21.5909 17.9965 20.8168 17.4987 19.5363 17.1074L19.3182 17.0432V14.7424C22.7364 15.5509 25 16.9479 25 18.5411C25 21.0472 19.4023 23.0769 12.5 23.0769C5.59659 23.0769 0 21.0472 0 18.5411C0 16.9479 2.26364 15.5509 5.68182 14.7413ZM18.1818 6.80367C19.4364 6.80367 20.4545 7.81969 20.4545 9.07156V13.6073H15.9091V9.07156C15.9091 7.81969 16.9273 6.80367 18.1818 6.80367ZM6.81818 6.80367C8.07273 6.80367 9.09091 7.81969 9.09091 9.07156V13.6073H4.54545V9.07156C4.54545 7.81969 5.56364 6.80367 6.81818 6.80367ZM12.5 4.53578C13.7545 4.53578 14.7727 5.5518 14.7727 6.80367V11.3395H10.2273V6.80367C10.2273 5.5518 11.2455 4.53578 12.5 4.53578ZM18.1818 2.26789C19.1193 2.26789 19.8864 3.03444 19.8864 3.96881C19.8864 4.90318 19.1193 5.66973 18.1818 5.66973C17.2443 5.66973 16.4773 4.90318 16.4773 3.96881C16.4773 3.03444 17.2443 2.26789 18.1818 2.26789ZM6.81818 2.26789C7.75568 2.26789 8.52273 3.03444 8.52273 3.96881C8.52273 4.90318 7.75568 5.66973 6.81818 5.66973C5.88068 5.66973 5.11364 4.90318 5.11364 3.96881C5.11364 3.03444 5.88068 2.26789 6.81818 2.26789ZM12.5 0C13.4375 0 14.2045 0.766547 14.2045 1.70092C14.2045 2.63529 13.4375 3.40184 12.5 3.40184C11.5625 3.40184 10.7955 2.63529 10.7955 1.70092C10.7955 0.766547 11.5625 0 12.5 0Z"
          fill="#232323"
        />
      </svg>
    );
  }

  community() {
    return (
      <svg
        width="29"
        height="25"
        viewBox="0 0 29 22"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M23.206 0.0502167C24.6245 0.0502167 25.7851 1.21712 25.7851 2.6395C25.7851 4.06188 24.6245 5.22879 23.206 5.22879C21.7875 5.22879 20.627 4.06188 20.627 2.6395C20.627 1.21712 21.7875 0.0502167 23.206 0.0502167Z"
          fill="#ff9900"
        />
        <path
          d="M26.6448 17.3121H19.7673V10.4074C19.7673 8.50164 21.3079 6.95497 23.2061 6.95497C25.1043 6.95497 26.6448 8.50164 26.6448 10.4074V17.3121Z"
          fill="#ff9900"
        />
        <path
          d="M14.6092 3.50259C16.0277 3.50259 17.1883 4.6695 17.1883 6.09188C17.1883 7.51426 16.0277 8.68117 14.6092 8.68117C13.1907 8.68117 12.0302 7.51426 12.0302 6.09188C12.0302 4.6695 13.1907 3.50259 14.6092 3.50259Z"
          fill="#ff9900"
        />
        <path
          d="M18.0479 20.7645H11.1704V13.8597C11.1704 11.954 12.711 10.4074 14.6091 10.4074C16.5073 10.4074 18.0479 11.954 18.0479 13.8597V20.7645Z"
          fill="#ff9900"
        />
        <path
          d="M6.0124 0.0502167C7.43088 0.0502167 8.59145 1.21712 8.59145 2.6395C8.59145 4.06188 7.43088 5.22879 6.0124 5.22879C4.59392 5.22879 3.43335 4.06188 3.43335 2.6395C3.43335 1.21712 4.59392 0.0502167 6.0124 0.0502167Z"
          fill="#ff9900"
        />
        <path
          d="M9.45108 17.3121H2.57361V10.4074C2.57361 8.50164 4.11416 6.95497 6.01234 6.95497C7.91053 6.95497 9.45108 8.50164 9.45108 10.4074V17.3121Z"
          fill="#ff9900"
        />
        <rect
          x="0.69043"
          y="17.1238"
          width="27.619"
          height="4.14286"
          fill="#ff9900"
        />
      </svg>
    );
  }

  isValidToShow = () => {
    const currentAccountList = this.props.accountList;
    let value = false;
    if (this.props.userData.role_id === ROLE_IDS.USER) {
      if (
        this.props.accountList &&
        this.props.accountList.Clients &&
        this.props.accountList.Clients.length > 0
      ) {
        value = true;
      }
    }
    if (this.props.userData.role_id !== ROLE_IDS.USER) {
      value = true;
    }

    return value;
  };

  handleClientSearch = (ev) => {
    this.setState({
      userDropSearch: ev.target.value,
    });
  };

  handleFocus = () => this.setState({ isFocused: true });

  handleBlur = () => this.setState({ isFocused: false });

  render() {
    let loader = "";

    const profileImgUrl =
      !Utility.isEmpty(this.props.accountList) &&
      this.props.accountList.Users &&
      !Utility.isEmpty(this.props.accountList.Users[0])
        ? this.props.accountList.Users[0].profile_image_url
        : "";
    const name =
      !Utility.isEmpty(this.props.accountList) &&
      this.props.accountList.Users &&
      !Utility.isEmpty(this.props.accountList.Users[0])
        ? this.props.accountList.Users[0].user_name
        : "";
    const id = !Utility.isEmpty(this.props.userData)
      ? this.props.userData.id
      : "";

    console.log(this.state.loader.auth, "Auth");

    if (this.props.isLoading) {
      loader = (
        <Backdrop
          sx={{ color: "#fff", zIndex: 999 }}
          open={true}
          // onClick={handleClose}
        >
          <CircularProgress color="inherit" />
        </Backdrop>
        // </div>
        // <div className="sweet-loading loader">
        /* <ClipLoader
            css={override}
            sizeUnit={"px"}
            size={80}
            color={"#0db6ea"}
            loading={true}
          /> */
      );
    }

    return (
      <>
        {loader}
        <header
          id="header"
          className={`header px-lg-4 px-sm-3 ${
            this.state.activeClass ? "scrolled" : ""
          } ${this.state.pwaEnable ? "" : ""}`}
        >
          <Link
            className="header-logo align-items-center cursor-pointer"
            onClick={() => {
              this.props.updateSeeAll({ isOpen: null, calledFrom: null });
              if (this.state.pwaEnable) this.showMenu();
            }}
            to={"/home"}
          >
            {/* {this.state.pwaEnable ? ( */}
            <img
              // onClick={() => {
              //   this.props.updateSeeAll({ isOpen: null, calledFrom: null });
              //   if (this.state.pwaEnable) this.showMenu();
              //   this.props.history.push("/");
              // }}
              src={require("assets/images/logos/main-logo-white.png")}
              alt="Home"
            />
            {/* ) : ( */}
            {/* <Image
                onClick={() =>
                  this.props.updateSeeAll({ isOpen: null, calledFrom: null })
                }
                src={require("assets/images/logos/main-logo.png")}
                alt="Home"
              /> */}
            {/* )} */}
          </Link>
          {!this.state.pwaEnable &&
            this.props.userData.role_id !== ROLE_IDS.USER &&
            !(this.props.seeAll.isOpen === "community") && (
              <div className="col-5 ml-5">
                <div className="d-flex justify-content-around neighbourhood-nav">
                  <Link
                    className={`pwa-footer-link bottom-border-header flex-center  flex-column  ${
                      !(
                        this.props.seeAll.isOpen || this.props.isChatFormOpen
                      ) &&
                      window.location.pathname === "/home" &&
                      this.props.seeAll.isOpen !== "community" &&
                      "sidebar-selected-item-neighborhood"
                    }`}
                    to={"/home"}
                    onClick={() => {
                      this.props.updateSeeAll({
                        isOpen: null,
                        calledFrom: null,
                      });
                    }}
                  >
                    <div className="region-Icon">
                      <img src={require("assets/images/icons/HomeIcon.svg")} />
                    </div>
                    {/* <div className="pwa-footer-menu" style={{ top: 7 }}>
                    Home
                  </div> */}
                    <div className="icon-label-nav text-white mt-1">Home</div>
                  </Link>
                  <Link
                    // onClick={() => {
                    //   changeLinks("home");
                    // }}
                    className={`pwa-footer-link flex-center bottom-border-header flex-column  text-white ${
                      !(
                        this.props.seeAll.isOpen || this.props.isChatFormOpen
                      ) &&
                      window.location.pathname === "/community-page" &&
                      this.props.seeAll.isOpen !== "community" &&
                      "sidebar-selected-item-neighborhood"
                    }`}
                    to={"/community-page"}
                    onClick={() => {
                      this.props.updateSeeAll({
                        isOpen: null,
                        calledFrom: null,
                      });
                    }}
                  >
                    <div className="region-Icon ">
                      <img
                        src={require("assets/images/icons/region-icon.svg")}
                        height="23px"
                        width="23px"
                      />
                    </div>

                    <div className="icon-label-nav text-white mt-1">
                      Communities
                    </div>
                  </Link>

                  <Link
                    to="/courses"
                    className={`pwa-footer-link flex-center bottom-border-header flex-column ${
                      !(
                        this.props.seeAll.isOpen || this.props.isChatFormOpen
                      ) &&
                      window.location.pathname === "/courses" &&
                      this.props.seeAll.isOpen !== "community" &&
                      "sidebar-selected-item-neighborhood"
                    }
                `}
                  >
                    <div className="region-Icon">{this.EventIcon()}</div>

                    <div className="icon-label-nav text-white mt-1">
                      Courses
                    </div>
                  </Link>

                  <Link
                    // onClick={() =>
                    //   dispatch(openOrClosePlusIcon(!plusIconClicked))
                    // }
                    className={`pwa-footer-link flex-center bottom-border-header  flex-column todo-link ${
                      !(
                        this.props.seeAll.isOpen || this.props.isChatFormOpen
                      ) &&
                      window.location.pathname === "/events" &&
                      this.props.seeAll.isOpen !== "community" &&
                      "sidebar-selected-item-neighborhood"
                    }`}
                    to={"/events"}
                    onClick={() => {
                      this.props.updateSeeAll({
                        isOpen: null,
                        calledFrom: null,
                      });
                    }}
                  >
                    <div className="region-Icon">
                      <img
                        src={require("assets/images/icons/events.svg")}
                        height="23px"
                        width="23px"
                      />
                    </div>
                    {/* <div style={{ top: -8 }} className="pwa-footer-menu">
                    To Do
                  </div> */}
                    <div className="icon-label-nav text-white mt-1">Events</div>
                  </Link>

                  <Link
                    className={`pwa-footer-link flex-center bottom-border-header flex-column ${
                      !(
                        this.props.seeAll.isOpen || this.props.isChatFormOpen
                      ) &&
                      window.location.pathname === "/news" &&
                      this.props.seeAll.isOpen !== "community" &&
                      "sidebar-selected-item-neighborhood"
                    }`}
                    to={"/news"}
                  >
                    <div className="region-Icon">
                      <img
                        src={require("assets/images/icons/news.svg")}
                        height="23px"
                        width="23px"
                      />
                    </div>
                    <div className="icon-label-nav text-white mt-1">News</div>
                  </Link>
                  <div
                    className={`pwa-footer-link flex-center bottom-border-header  flex-column cursor-pointer ${
                      !this.props.isChatFormOpen &&
                      this.props.seeAll.isOpen === "community" &&
                      "sidebar-selected-item-neighborhood"
                    }`}
                    // to={"/groups"}
                    onClick={() => {
                      this.props.updateSeeAll({
                        isOpen: "community",
                        calledFrom: "community",
                      });
                    }}
                  >
                    <div className="region-Icon">{this.community()}</div>
                    <div className="icon-label-nav text-white mt-1">
                      Community
                    </div>
                  </div>
                </div>
              </div>
            )}

          {
            // (this.props.userData.role_id === ROLE_IDS.USER || this.props.seeAll.isOpen === "community") &&
            <div
              className={
                this.state.pwaEnable
                  ? `col-6 search-bar-width pb-2 ml-0 mt-0  ${
                      this.props.userData.role_id === ROLE_IDS.USER ||
                      this.props.seeAll.isOpen === "community"
                        ? ""
                        : "d-none"
                    }`
                  : `col-4 pb-2 ml-5 mt-0  ${
                      this.props.userData.role_id === ROLE_IDS.USER ||
                      this.props.seeAll.isOpen === "community"
                        ? ""
                        : "d-none"
                    }`
              }
            >
              {/* <div className="d-flex">
                <Form className="shar-form search-form form-inline">
                  <div className="input-group">
                  <InputField
                      required={true}
                      type="text"
                      className='h-25 headerSearchBar'
                      placeholder="Search Community"
                      handleChange={(ev) => this.handleSearch(ev)}
                      handleClick={() => {
                        this.props.updateSeeAll({
                          isOpen: "community",
                          calledFrom: "community",
                        });
                      }}
                    />
                    <div className="input-group-append">
                      <span className="input-group-text bg-white" id="basic-addon2">
                      <AiOutlineSearch/>
                      </span>
                    </div>
                  </div>
                </Form>
              </div> */}
              <div
                className="SeactionSearchContainer m-0"
                style={{ height: "30px" }}
              >
                <div
                  className={`searchSectionWrapperHeader bg-white d-flex flex-row align-items-center w-75 mt-1 pr-1 ${
                    this.state.isFocused && "focus-border"
                  }`}
                  tabIndex="1"
                >
                  <span
                    className="SeactionSearchIcon cursor-pointer mr-1"
                    onClick={() => {
                      this.props.history.push("/home");
                      this.props.updateSeeAll({
                        isOpen: null,
                        calledFrom: null,
                      });
                    }}
                  >
                    <PeopleAltIcon />
                  </span>
                  <input
                    className="SeactionSearch w-75"
                    // style={{ width: "85%" }}
                    placeholder="Search Community"
                    onFocus={this.handleFocus}
                    onBlur={this.handleBlur}
                    type="text"
                    value={this.state.searchValue}
                    onChange={(ev) => this.handleSearch(ev)}
                    onClick={() => {
                      this.props.updateSeeAll({
                        isOpen: "community",
                        calledFrom: "community",
                      });
                    }}
                  />

                  <span className="SeactionSearchIcon hidden-tab">
                    <Search />
                  </span>
                </div>
              </div>
            </div>
          }
          {this.state.pwaEnable && (
            <>
              <div className="d-flex flex-row float-right pl-2 h-10 m-r-4 align-items-center align-content-center m-b-1 pwa-neighbourhood">
                {(this.props.userData.platform_id !== SM_CLIENT_ID ||
                  this.props.userData.is_super_owner === true) && (
                  <span
                    onClick={() => {
                      this.props.updateIsChatFormOpen(false);
                      this.props.updateSeeAll({
                        isOpen: "create",
                        calledFrom: "create",
                      });
                    }}
                    // className={`ml-auto `}
                  >
                    <div
                      className={`${
                        this.props.seeAll &&
                        this.props.seeAll.isOpen === "create" &&
                        "SelectedSidePanal border-none"
                      } SidepanalheaderIcon`}
                    >
                      <AddBoldSmallIcon
                        mainClass="create-circle-icon cursor-pointer"
                        fillClass="fill-primary"
                        className="pl-5"
                      />
                    </div>
                  </span>
                )}
                {this.props.userData.network !== "clients" &&
                  !(
                    this.props.userData.role_id === ROLE_IDS.CLIENT_ADMIN ||
                    this.props.userData.role_id === ROLE_IDS.CLIENT_OWNER ||
                    this.props.userData.role_id === ROLE_IDS.PARENT_OWNER
                  ) && (
                    <div>
                      <span
                        className="cursor-pointer"
                        id="chat-container-header"
                        onClick={() => {
                          this.props.updateSeeAll({
                            isOpen: null,
                            calledFrom: null,
                          });
                          this.props.updateIsChatFormOpen(
                            !this.props.isChatFormOpen
                          );
                        }}
                      >
                        <div
                          className={`${
                            this.props.isChatFormOpen &&
                            !this.props.chatFriend.is_discussion &&
                            "SelectedSidePanal border-none"
                          } SidepanalheaderIcon ChatHeaderContainer`}
                        >
                          <ChatSqaureIcon
                            mainClass="create-circle-icon cursor-pointer"
                            fillClass="fill-agenda"
                          />
                          {this.state.isUnreadMessages && (
                            <div className="unReadIndicator"></div>
                          )}
                        </div>
                      </span>
                    </div>
                  )}
                <div>
                  <WebNotification className="pl-5" />
                </div>
              </div>
            </>
          )}
          {/* HERE IT IS */}
          <div className="d-flex align-content-center align-items-center">
            <div className="h-10 align-content-center hidden-tab m-b-1 d-flex m-r-8 justify-content-center align-items-center">
              {!this.state.pwaEnable && (
                <>
                  {(this.props.userData.platform_id !== SM_CLIENT_ID ||
                    this.props.userData.is_super_owner === true) && (
                    <span
                      onClick={() => {
                        this.props.updateIsChatFormOpen(false);
                        this.props.updateSeeAll({
                          isOpen: "create",
                          calledFrom: "create",
                        });
                      }}
                      // className={`ml-auto `}
                    >
                      <div
                        className={`${
                          this.props.seeAll &&
                          this.props.seeAll.isOpen === "create" &&
                          "SelectedSidePanal border-none"
                        } SidepanalheaderIcon`}
                      >
                        <AddBoldSmallIcon
                          mainClass="create-circle-icon cursor-pointer"
                          fillClass="fill-primary"
                        />
                      </div>
                    </span>
                  )}

                  {this.props.userData.network !== "clients" &&
                    !(
                      this.props.userData.role_id === ROLE_IDS.CLIENT_ADMIN ||
                      this.props.userData.role_id === ROLE_IDS.CLIENT_OWNER ||
                      this.props.userData.role_id === ROLE_IDS.PARENT_OWNER
                    ) && (
                      <span
                        className="cursor-pointer"
                        id="chat-container-header"
                        onClick={() => {
                          this.props.updateSeeAll({
                            isOpen: null,
                            calledFrom: null,
                          });
                          this.props.updateIsChatFormOpen(
                            !this.props.isChatFormOpen
                          );
                        }}
                      >
                        <div
                          className={`${
                            this.props.isChatFormOpen &&
                            !this.props.chatFriend.is_discussion &&
                            "SelectedSidePanal border-none"
                          } SidepanalheaderIcon ChatHeaderContainer`}
                        >
                          <ChatSqaureIcon
                            mainClass="create-circle-icon cursor-pointer"
                            fillClass="fill-agenda"
                          />
                          {this.state.isUnreadMessages && (
                            <div className="unReadIndicator"></div>
                          )}
                        </div>
                      </span>
                    )}
                  <WebNotification />
                </>
              )}
            </div>
            <div className="h-10 d-flex align-content-center m-b-2 align-items-baseline">
              <Dropdown
                className={`${
                  this.props.location.pathname === "/profile"
                    ? "activeProfile"
                    : "inActiveProfile"
                } client-profile-toggle overflow-hidden d-flex align-content-center align-items-center relative`}
              >
                {this.state.isUserLogedIn && (
                  <>
                    <Dropdown.Toggle
                      id="dropdown-basic-header"
                      className="py-0 rounded-none min-h-8 "
                    >
                      <div className="align-items-center">
                        <div className="client-profile-dropdown">
                          {!Utility.isEmpty(profileImgUrl) ? (
                            <Image
                              className="rounded-circle client-profile-dropdown-img golden-border"
                              src={
                                !Utility.isEmpty(profileImgUrl)
                                  ? profileImgUrl
                                  : require("assets/images/users/user_no_image.png")
                              }
                              alt="Profile Picture"
                            />
                          ) : (
                            <Avatar
                              name={name}
                              round={true}
                              size={"30"}
                              textSizeRatio={1.75}
                              color="#007bb3"
                              maxInitials={1}
                            />
                          )}

                          {Utility.isEmpty(name) ? (
                            <Skeleton
                              variant="text"
                              height={40}
                              width={200}
                              className="ml-1"
                              animation="wave"
                            />
                          ) : (
                            <span className="font-bold ml-3">{name}</span>
                          )}
                        </div>
                        <div className="mr-2 toggle-icon ml-auto">
                          {/* <img
                            src={require("assets/images/icons/chevron_down.svg")}
                            alt="drodown-icon"
                          /> */}
                          {this.state.pwaEnable ? (
                            <MoreVertIcon />
                          ) : (
                            <HeaderDropIcon />
                          )}
                          {/* <IoIosArrowDown /> */}
                        </div>
                      </div>
                    </Dropdown.Toggle>

                    <Dropdown.Menu>
                      <div className="client-profile-toggle-outer">
                        {((this.props.accountList &&
                          this.props.accountList.Admins &&
                          this.props.accountList.Admins.some(
                            (data) =>
                              data.role_id === ROLE_IDS.PARENT_ADMIN ||
                              data.role_id === ROLE_IDS.PARENT_OWNER ||
                              data.role_id === ROLE_IDS.SUPER_OWNER
                          )) ||
                          (this.props.accountList &&
                            this.props.accountList.Parents &&
                            this.props.accountList.Parents.some(
                              (data) =>
                                data.role_id === ROLE_IDS.PARENT_ADMIN ||
                                data.role_id === ROLE_IDS.PARENT_OWNER ||
                                data.role_id === ROLE_IDS.SUPER_OWNER
                            ))) && (
                          <div
                            className="SeactionSearchContainer m-0"
                            style={{ height: "30px" }}
                          >
                            <div
                              className={`searchSectionWrapper bg-white d-flex flex-row align-items-center w-90 mt-3 pr-1 ${
                                this.state.isFocused && "focus-border"
                              }`}
                              tabIndex="1"
                            >
                              <input
                                className="SeactionSearch w-90"
                                placeholder="Search account"
                                type="text"
                                value={this.state.userDropSearch}
                                onChange={(ev) => this.handleClientSearch(ev)}
                              />

                              <span className="SeactionSearchIcon">
                                <Search />
                              </span>
                            </div>
                          </div>
                        )}
                        {Object.keys(NETWORK).map((item, i) => {
                          return (
                            <Fragment key={i}>
                              <div className="d-flex justify-content-between network-type-name pl-1 pr-3">
                                {(!Utility.isEmpty(
                                  this.props.accountList[item]
                                ) ||
                                  (ROLE_IDS.USER ===
                                    this.props.userData.role_id &&
                                    item === "Clients")) && (
                                  <span className="font-12 font-semi-bold px-2 b-600">
                                    {item === "Clients" &&
                                    this.props.userData.platform_id ===
                                      SM_CLIENT_ID
                                      ? ""
                                      : NETWORK[item]}
                                  </span>
                                )}
                                {ROLE_IDS.USER ===
                                  this.props.userData.role_id &&
                                  this.props.userData.platform_id !==
                                    SM_CLIENT_ID &&
                                  item === "Clients" && (
                                    <AddBoldSmallIcon
                                      mainClass="pwa-profile-action-icon"
                                      fillClass="fill-primary"
                                      handleClick={() => {
                                        this.setState(
                                          { isNeighborhood: true },
                                          () => this.openNeighborhoodForm()
                                        );
                                      }}
                                    />
                                  )}
                                {ROLE_IDS.USER ===
                                  this.props.userData.role_id &&
                                  this.props.userData.platform_id ===
                                    SM_CLIENT_ID &&
                                  item === "Parents" && (
                                    <AddBoldSmallIcon
                                      mainClass="pwa-profile-action-icon"
                                      fillClass="fill-primary"
                                      handleClick={() =>
                                        this.openNeighborhoodForm()
                                      }
                                    />
                                  )}
                              </div>

                              {ROLE_IDS.USER === this.props.userData.role_id &&
                                this.props.userData.platform_id !==
                                  SM_CLIENT_ID &&
                                Utility.isEmpty(this.props.accountList[item]) &&
                                item === "Clients" && (
                                  <Dropdown.Item
                                    className="client-profile-toggle-item align-items-center"
                                    onClick={() => {
                                      this.setState(
                                        { isNeighborhood: true },
                                        () => this.openNeighborhoodForm()
                                      );
                                    }}
                                  >
                                    <AddIcon mainClass="mr-3 ml-q4" />
                                    <span className="font-medium display-8">
                                      Create a Neighborhood Account
                                    </span>
                                  </Dropdown.Item>
                                )}
                              {ROLE_IDS.USER === this.props.userData.role_id &&
                                this.props.userData.platform_id ===
                                  SM_CLIENT_ID &&
                                Utility.isEmpty(this.props.accountList[item]) &&
                                item === "Parents" && (
                                  <Dropdown.Item
                                    className="client-profile-toggle-item align-items-center"
                                    onClick={() => this.openNeighborhoodForm()}
                                  >
                                    <AddIcon mainClass="mr-3 ml-4" />
                                    <span className="font-medium display-8">
                                      Create a City Account
                                    </span>
                                  </Dropdown.Item>
                                )}

                              {!Utility.isEmpty(this.props.accountList) &&
                                this.props.accountList[item]
                                  .filter((items) => {
                                    return items.user_name &&
                                      this.state.userDropSearch
                                      ? items.user_name
                                          .toLowerCase()
                                          .includes(
                                            this.state.userDropSearch.toLowerCase()
                                          )
                                      : item;
                                  })
                                  .map((data, index) => {
                                    return (
                                      <div
                                        onClick={() =>
                                          this.switchAccount(data.id, data)
                                        }
                                        className={`client-profile-toggle-item ${
                                          id !== data.id ? "" : "active"
                                        }`}
                                        key={index}
                                      >
                                        {this.state.loader.switchAcc &&
                                        data.id ===
                                          this.state.switchAccountId ? (
                                          <div className="d-flex justify-content-center align-items-center">
                                            <CircularProgress
                                              size={30}
                                              style={{
                                                color: "#272262",
                                              }}
                                            />
                                          </div>
                                        ) : (
                                          <div className="align-items-center">
                                            <div className="client-profile-dropdown">
                                              <TickBoldIcon
                                                fillClass="fill-primary"
                                                strokeClass="stroke-primary"
                                                mainClass={
                                                  id !== data.id
                                                    ? "visibility-hidden"
                                                    : ""
                                                }
                                              />
                                              {!Utility.isEmpty(
                                                data.profile_image_url
                                              ) ? (
                                                <Image
                                                  className="rounded-circle golden-border client-profile-dropdown-img ml-2"
                                                  src={
                                                    !Utility.isEmpty(
                                                      data.profile_image_url
                                                    )
                                                      ? data.profile_image_url
                                                      : require("assets/images/users/user_no_image.png")
                                                  }
                                                  alt="Profile Picture"
                                                />
                                              ) : (
                                                <Avatar
                                                  name={data.user_name}
                                                  round={true}
                                                  size={"39"}
                                                  textSizeRatio={1.75}
                                                  color="#007bb3"
                                                  maxInitials={1}
                                                  className="ml-1"
                                                />
                                              )}
                                              <span
                                                className={`ml-3 drop-userName text-capitalize ${
                                                  id !== data.id
                                                    ? "font-medium b-500"
                                                    : "font-bold"
                                                }`}
                                              >
                                                {data.user_name}
                                              </span>
                                            </div>
                                            {/* <div className="ml-auto mr-2">
                                            <img
                                              src={require("assets/images/icons/menu_vertical_icon.svg")}
                                              className={`client-profile-more-icon px-2 ${
                                                id !== data.id
                                                  ? "visibility-hidden"
                                                  : ""
                                              }`}
                                              alt="menu-icon"
                                              onClick={(e) => {
                                                e.stopPropagation();
                                                this.setState({ isEdit: true });
                                                this.openNeighborhoodForm(data);
                                              }}
                                            />
                                          </div> */}
                                          </div>
                                        )}
                                      </div>
                                    );
                                  })}
                            </Fragment>
                          );
                        })}
                      </div>
                      <Dropdown.Item
                        className="font-medium b-500 mt-auto client-profile-toggle-item align-items-center px-3 mb-0"
                        onClick={() => this.action("Logout")}
                      >
                        <PersonOutlinedIcon mainClass="mr-4 ml-2" />
                        Logout
                      </Dropdown.Item>
                    </Dropdown.Menu>
                  </>
                )}
                {!this.state.isUserLogedIn && (
                  <Dropdown.Item
                    className="p-3"
                    onClick={() => this.action("Login")}
                  >
                    <Image
                      className="mr-2 header-dropdown-icon"
                      src={require("assets/images/icons/logout_icon.svg")}
                      alt="Logout"
                    />
                    Login
                  </Dropdown.Item>
                )}
              </Dropdown>
            </div>
          </div>
        </header>
        {this.state.isEdit && (
          <div className="header-NeighborhoodSignUp-callout">
            <NeighborhoodSignUp
              accountList={this.props.accountList}
              userData={this.props.userData}
              history={this.props.history}
              neighborhoodState={this.props.neighborhoodState}
              edit={this.state.isEdit}
              id={this.props.neighborhoodState.id}
              closeForm={this.editCloseForm}
              showLoader={this.props.showLoader}
              updateAccountList={this.props.updateAccountList}
              updateUserData={this.props.updateUserData}
            />
          </div>
        )}
        {this.props.isNeighborhoodFormOpen && this.state.isNeighborhood && (
          <div className="header-NeighborhoodSignUp-callout">
            <NeighborhoodSignUp
              userData={this.props.userData}
              history={this.props.history}
              neighborhoodState={this.props.neighborhoodState}
              id={this.props.neighborhoodState.id}
              edit={false}
              openNeighborhoodForm={this.props.openNeighborhoodForm}
              isNeighborhoodFormOpen={this.props.isNeighborhoodFormOpen}
              closeForm={this.editCloseForm}
              accountList={this.props.accountList}
              showLoader={this.props.showLoader}
              updateAccountList={this.props.updateAccountList}
              updateUserData={this.props.updateUserData}
            />
          </div>
        )}
        {!this.state.pwaEnable && this.props.isCampaignFormOpen && (
          <CampaignSelectionForm
            position="position-fixed create-campaign-margin"
            userData={this.props.userData}
          />
        )}
        {/* {this.props.isChatFormOpen && (
          <div className="header-NeighborhoodSignUp-callout">
            <ChatCallout key={this.state.toolboxMessageId} />
          </div>
        )}
        {this.state.isChatCall && (
          <SideCallout
            calloutHeading="Chat"
            closeCallOut={() => {
              this.setState({ isChatCall: false });
            }}
            calloutHeadingSvg={<ChatSqaureIcon />}
            innerPosition="chatCall"
            // position="position-fixed gil-pwa-callout gil-pwa-callout-passport"
            // calloutHeadingImg="assets/images/profile/passport-icon.svg"
            // calloutCloseIcon={<ChevronUpIcon />}
          >
            <ChatComp
              userData={this.props.userData}
              updateIsChatFormOpen={this.props.updateIsChatFormOpen}
              updateChatFriend={this.props.updateChatFriend}
            />
          </SideCallout>
        )} */}
        {this.props.isChatFormOpen && (
          <div className="header-NeighborhoodSignUp-callout">
            <CommunityChat
              userData={this.props.userData}
              key={this.state.toolboxMessageId}
              handleSwitchAccount={this.switchAccount}
            />
          </div>
        )}
        {this.props.seeAll.isOpen === "Successfull-create-article" && (
          <News_Confirmation
            articleId={this.state.articleId}
            handleEventId={this.handleEventId}
          />
        )}

        {this.props.seeAll.isOpen === "create" && (
          <SideCallout
            position={`position-absolute assignment-callout background-sidebar-pages ${
              window.location.pathname.includes("/news-details/") ||
              window.location.pathname.includes("/event-details/")
                ? "communitySideCallout"
                : "newSideCalloutStyle"
            }`}
            isCreatePage={true}
            closeCallOut={() =>
              this.props.updateSeeAll({ isOpen: null, calledFrom: null })
            }
            calloutHeading={"What do you want to do?"}
            calloutHeadingSvg={<BsPlusCircle className="regionIcon" />}
          >
            <div className="d-flex flex-column align-items-center background-sidebar-pages">
              {[
                { id: 0, item: "MANAGE PROFILE", path: "/profile" },
                { id: 1, item: "START A CONVERSATION", path: "/" },
                { id: 2, item: "CREATE AN ARTICLE", path: "/create-article" },
                { id: 3, item: "CREATE AN EVENT", path: "/create-event" },
                { id: 4, item: "CREATE A COURSE", path: "/course-create" },
              ]
                .filter((data) => {
                  return [
                    ROLE_IDS.CLIENT_OWNER,
                    ROLE_IDS.CLIENT_ADMIN,
                  ].includes(this.props.userData.role_id)
                    ? data.id !== 1
                    : data;
                })
                .map((item) => {
                  if (
                    item.id == 1 ||
                    item.id === 0 ||
                    ((item.id === 2 || item.id === 4 || item.id === 3) &&
                      this.isValidToShow())
                  ) {
                    return (
                      <Fragment key={item.id}>
                        <Create_course
                          onClick={() => {
                            if (item.id !== 1)
                              this.props.history.push(item.path);
                            else {
                              this.props.updateSeeAll({
                                isOpen: null,
                                calledFrom: null,
                              });
                              this.props.updateIsChatFormOpen(
                                !this.props.isChatFormOpen
                              );
                            }
                          }}
                          heading={item.item}
                          item="course"
                          isHeader={true}
                          className="w-40 mb-3 p-2"
                        />
                      </Fragment>
                    );
                  }
                })}
            </div>
          </SideCallout>
        )}
        {this.props.seeAll.isOpen === "community" && (
          <SideCallout
            position={`position-absolute assignment-callout ${
              window.location.pathname.includes("/news-details/") ||
              window.location.pathname.includes("/event-details/")
                ? "communitySideCallout"
                : "newSideCalloutStyle"
            }`}
            temperoryClass="pl-0"
            closeCallOut={() =>
              this.props.updateSeeAll({ isOpen: null, calledFrom: null })
            }
            calloutHeading={"Community"}
            calloutHeadingSvg={
              <img src={require("assets/images/community.png")} height="15px" />
            }
            isProfilePage={false}
            isCommunity={true}
          >
            <Communities
              heading={
                Utility.pwaModeEnabled() ? "Community Members" : "Community"
              }
              closeCallout={() =>
                this.props.updateSeeAll({
                  isOpen: null,
                  calledFrom: null,
                })
              }
            />
          </SideCallout>
        )}

        {/* {this.state.showInviteForm && ( */}
        {/* <div className="pwa-invite-callout shar-form search-form">
            <Invite
              onClose={() => {
                this.props.openOrClosePlusIcon(false);
                this.setState({ showInviteForm: false });
              }}
              userData={this.props.userData}
              fromHomePage={true}
              changeLoader={(val) => this.props.showLoader(val)}
            />
          </div> */}
        {/* )} */}
      </>
    );
  }
}

const mapActionToProps = {
  showLoader,
  updateUserData,
  updateIsNeighborhoodFormOpen,
  updateAccountList,
  updateCampaignFormOpen,
  updateIsCitizenSidebarOpen,
  openNeighborhoodForm,
  updateSeeAll,
  openOrClosePlusIcon,
  updateCampaignSelectedTab,
  updatenewMessage,
  updateIsCampSelFormOpen,
  userQualities,
  updateChatFriend,
  updateIsChatFormOpen,
  headerSearchBar,
};

const mapStateToProps = (state) => ({
  userData: state.userData,
  isNeighborhoodFormOpen: state.isNeighborhoodFormOpen,
  isLoading: state.isLoading,
  userQualitiesData: state.userQualities,
  neighborhoodState: state.neighborhoodState,
  isChatFormOpen: state.isChatFormOpen,
  accountList: state.accountList,
  newMessages: state.newMessages,
  seeAll: state.seeAll,
  chatFriend: state.chatFriend,
  isCampaignFormOpen: state.isCampaignFormOpen,
  isPlusIconClicked: state.isPlusIconClicked,
  HeaderSearchBar: state.HeaderSearchBar,
});

export default connect(mapStateToProps, mapActionToProps)(Header);
