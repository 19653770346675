import React from "react";
import { Table } from "react-bootstrap";
// import { Spinner } from "react-bootstrap";
import { NoRecordFound } from "shared/components/no_record_found";
import TableRowsComm from "./table_rows_comm";
import { Utility } from "utils";
import { Skeleton } from "@mui/material";

export default function TableListComm(props) {
  // const showLoader = props.showLoader;
  console.log(props.loader, "showLoader");
  return (
    <>
      <Table striped responsive size="sm" className="shar-table">
        <TableTitles tableTitles={props.tableTitles} />
        {props.loader ? (
          <tbody className="w-100">
            {[1, 2, 3, 4, 5, 6, 7].map((item) => (
              <tr>
                {[...Array(props.tableTitles.length).keys()].map((item) => (
                  <td>
                    <Skeleton
                      variant="rectangular"
                      className="w-100"
                      height={60}
                      key={item}
                    />
                  </td>
                ))}
              </tr>
            ))}
          </tbody>
        ) : (
          props.data && (
            <TableRowsComm
              data={props.data}
              onActionClick={props.handleAction}
              userData={props.userData}
              actions={props.actions}
              handleClick={props.handleClick}
              selectedTab={props.selectedTab}
              addLinksToNoti={props.addLinksToNoti}
            />
          )
        )}
      </Table>
      {Utility.isEmpty(props.data) && !props.loader && <NoRecordFound />}
      {/* {showLoader && (
        <span className="justify-content-center">
          <Spinner animation="border" role="status" />
        </span>
      )} */}
    </>
  );
}

export const TableTitles = (props) => {
  return (
    <>
      <thead>
        <tr>
          {props.tableTitles.map((item, index) => {
            return (
              <th className="border-top-0 border-bottom-0" key={index}>
                {item}
              </th>
            );
          })}
        </tr>
      </thead>
    </>
  );
};
