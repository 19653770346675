import { Button } from "react-bootstrap";
import CustomModal from "../../shared/customModal";
import React, { useState } from "react";
import classNames from "classnames";
import { CLIENT_ID } from "../../../config/constants/config";
import { useSelector } from "react-redux";
import { Utility } from "../../../utils";
import { REMOVE_USERS_FROM_NEIGHBORHOOD } from "../../../config/constants/api_endpoints";
import { toast } from "react-toastify";
import { getNeighborhoodsOfUser } from "../../../services/profile";
// import { getNeighborhoodsOfUser } from "../../../services/profile";

const LeaveCommunity = ({ client_id }) => {
  const [isDeleteModal, setIsDeleteModal] = useState(false);
  const userData = useSelector((state) => state.userData);

  const removeUserFromNeighborhood = () => {
    const params = {
      platform_id: CLIENT_ID,
      client_id: client_id,
      user_id: userData.user_id,
    };
    Utility.sendRequest(
      REMOVE_USERS_FROM_NEIGHBORHOOD,
      3,
      params,
      (err, res, body) => {
        body = JSON.parse(body);
        if (body.error) {
          toast.error(body.error.message, { containerId: "private" });
        } else {
          setIsDeleteModal(false);
          //   console.log(body);
            getNeighborhoodsOfUser(userData);
          // window.location.reload();
        }
      }
    );
  };

  return (
    <>
      <Button
        onClick={() => setIsDeleteModal(true)}
        className="leave-community"
      >
        Leave Community
      </Button>

      <CustomModal
        title={"Leave community"}
        open={isDeleteModal}
        classToOverride="col-lg-4"
        onClose={() => setIsDeleteModal(false)}
      >
        <div className={classNames("mt-3")}>
          Are you sure you want to leave this community?
        </div>
        <div className="d-flex justify-content-around mt-5">
          <Button
            onClick={() => {
              removeUserFromNeighborhood();
            }}
            className={classNames("CustomProfileButtonStyle p-0")}
          >
            Leave Community
          </Button>
          <Button
            variant="danger"
            onClick={() => {
              setIsDeleteModal(false);
            }}
            className={classNames("CustomProfileButtonStyle p-0")}
          >
            Cancel
          </Button>
        </div>
      </CustomModal>
    </>
  );
};
export default LeaveCommunity;
