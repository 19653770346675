import React, { useState } from "react";
import {
  CommunityIcon,
  CoursesIcon,
  DocumentsIcon,
  ImageIcon,
  NewsIcon,
  VideoIcon,
} from "../../../../shared/icons/icons";
import { Form, Col, Button, Image, Row } from "react-bootstrap";
import { FaSistrix } from "react-icons/fa";
import DiscussionsImages from "./discussionsImages";
import DiscussionsVideos from "./discussionsVideo";
import DiscussionsDocs from "./discussionsDocuments";
import { BsThreeDotsVertical } from "react-icons/bs";
import DiscussionsCommunities from "./discussionsCommunities";
import DiscussionsCourses from "./discussionsCourses";
import DiscussionsNews from "./discussionsNews";
import { DiscussionsChat } from "./discussionChat";
import * as Utility from "../../../../utils/utility";
import DiscussionsCreate from "./discussionsCreate";

const Discussions = (props) => {
  const [selectedIconName, setSelectedIconName] = useState("");
  const [search, setSearch] = useState("");
  let debouncedFn = null;

  const handleSearch = (ev) => {
    ev.persist();
    if (!debouncedFn) {
      debouncedFn = Utility.debounce(() => {
        const searchString = ev.target.value;
        setSearch(searchString);
      }, 500);
    }
    debouncedFn();
  };

  const handleClose = () => {
    props.setOpenCreate(false);
  };

  const topBarData = [
    {
      id: 1,
      name: "Community",
      icon: <CommunityIcon w={20} h={16} />,
      count: "1.1k",
    },
    { id: 2, name: "Videos", icon: <VideoIcon w={20} h={16} />, count: "50" },
    { id: 3, name: "Images", icon: <ImageIcon w={20} h={16} />, count: "50" },
    {
      id: 4,
      name: "Documents",
      icon: <DocumentsIcon w={20} h={16} />,
      count: "10",
    },
    {
      id: 5,
      name: "Courses",
      icon: <CoursesIcon w={20} h={16} />,
      count: "50",
    },
    { id: 6, name: "News", icon: <NewsIcon w={20} h={16} />, count: "101" },
  ];

  const images = [
    {
      id: 0,
      name: "Image1",
      url: "https://sharemeister-crm-dev.s3.us-west-2.amazonaws.com/user_media/33df1219-ddb9-48c1-a469-462392359836/1661230297832image",
    },
    {
      id: 1,
      name: "Image2",
      url: "https://sharemeister-crm-dev.s3.us-west-2.amazonaws.com/user_media/33df1219-ddb9-48c1-a469-462392359836/1661230297832image",
    },
    {
      id: 2,
      name: "Image3",
      url: "https://sharemeister-crm-dev.s3.us-west-2.amazonaws.com/user_media/33df1219-ddb9-48c1-a469-462392359836/1661230297832image",
    },
    {
      id: 3,
      name: "Image4",
      url: "https://sharemeister-crm-dev.s3.us-west-2.amazonaws.com/user_media/33df1219-ddb9-48c1-a469-462392359836/1661230297832image",
    },
    {
      id: 4,
      name: "Image5",
      url: "https://sharemeister-crm-dev.s3.us-west-2.amazonaws.com/user_media/33df1219-ddb9-48c1-a469-462392359836/1661230297832image",
    },
    {
      id: 5,
      name: "Image6",
      url: "https://sharemeister-crm-dev.s3.us-west-2.amazonaws.com/user_media/33df1219-ddb9-48c1-a469-462392359836/1661230297832image",
    },
  ];
  const videos = [
    {
      id: 0,
      name: "Video1",
      url: "https://sharemeister-crm-dev.s3.us-west-2.amazonaws.com/user_media/cbf123e0-b486-4508-b6ca-aea936be5db0/1661232193932image",
      thumbnail:
        "https://sharemeister-crm-dev.s3.us-west-2.amazonaws.com/user_media/33df1219-ddb9-48c1-a469-462392359836/1661230297832image",
    },
    {
      id: 1,
      name: "Video2",
      url: "https://sharemeister-crm-dev.s3.us-west-2.amazonaws.com/user_media/cbf123e0-b486-4508-b6ca-aea936be5db0/1661232193932image",
      thumbnail:
        "https://sharemeister-crm-dev.s3.us-west-2.amazonaws.com/user_media/33df1219-ddb9-48c1-a469-462392359836/1661230297832image",
    },
    {
      id: 2,
      name: "Video3",
      url: "https://sharemeister-crm-dev.s3.us-west-2.amazonaws.com/user_media/cbf123e0-b486-4508-b6ca-aea936be5db0/1661232193932image",
      thumbnail:
        "https://sharemeister-crm-dev.s3.us-west-2.amazonaws.com/user_media/33df1219-ddb9-48c1-a469-462392359836/1661230297832image",
    },
    {
      id: 3,
      name: "Video4",
      url: "https://sharemeister-crm-dev.s3.us-west-2.amazonaws.com/user_media/cbf123e0-b486-4508-b6ca-aea936be5db0/1661232193932image",
      thumbnail:
        "https://sharemeister-crm-dev.s3.us-west-2.amazonaws.com/user_media/33df1219-ddb9-48c1-a469-462392359836/1661230297832image",
    },
    {
      id: 4,
      name: "Video5",
      url: "https://sharemeister-crm-dev.s3.us-west-2.amazonaws.com/user_media/cbf123e0-b486-4508-b6ca-aea936be5db0/1661232193932image",
      thumbnail:
        "https://sharemeister-crm-dev.s3.us-west-2.amazonaws.com/user_media/33df1219-ddb9-48c1-a469-462392359836/1661230297832image",
    },
    {
      id: 5,
      name: "Video6",
      url: "https://sharemeister-crm-dev.s3.us-west-2.amazonaws.com/user_media/cbf123e0-b486-4508-b6ca-aea936be5db0/1661232193932image",
      thumbnail:
        "https://sharemeister-crm-dev.s3.us-west-2.amazonaws.com/user_media/33df1219-ddb9-48c1-a469-462392359836/1661230297832image",
    },
  ];
  const docs = [
    {
      id: 0,
      name: "Doc1",
      url: "https://sharemeister-crm-dev.s3.us-west-2.amazonaws.com/user_media/cbf123e0-b486-4508-b6ca-aea936be5db0/1661232193932image",
    },
    {
      id: 1,
      name: "Doc2",
      url: "https://sharemeister-crm-dev.s3.us-west-2.amazonaws.com/user_media/cbf123e0-b486-4508-b6ca-aea936be5db0/1661232193932image",
    },
    {
      id: 2,
      name: "Doc3",
      url: "https://sharemeister-crm-dev.s3.us-west-2.amazonaws.com/user_media/cbf123e0-b486-4508-b6ca-aea936be5db0/1661232193932image",
      thumbnail:
        "https://sharemeister-crm-dev.s3.us-west-2.amazonaws.com/user_media/33df1219-ddb9-48c1-a469-462392359836/1661230297832image",
    },
    {
      id: 3,
      name: "Doc4",
      url: "https://sharemeister-crm-dev.s3.us-west-2.amazonaws.com/user_media/cbf123e0-b486-4508-b6ca-aea936be5db0/1661232193932image",
    },
    {
      id: 4,
      name: "Doc5",
      url: "https://sharemeister-crm-dev.s3.us-west-2.amazonaws.com/user_media/cbf123e0-b486-4508-b6ca-aea936be5db0/1661232193932image",
    },
    {
      id: 5,
      name: "Doc6",
      url: "https://sharemeister-crm-dev.s3.us-west-2.amazonaws.com/user_media/cbf123e0-b486-4508-b6ca-aea936be5db0/1661232193932image",
    },
  ];

  const selectedComponent = () => {
    if (props.selectedIcon === 0)
      return (
        <div>
          <DiscussionsChat />
        </div>
      );
    else if (props.selectedIcon === 1)
      return (
        <div>
          <DiscussionsCommunities search={search} />
        </div>
      );
    else if (props.selectedIcon === 2)
      return (
        <div>
          <DiscussionsVideos videos={videos} search={search} />
        </div>
      );
    else if (props.selectedIcon === 3)
      return (
        <div>
          <DiscussionsImages images={images} search={search} />
        </div>
      );
    else if (props.selectedIcon === 4)
      return (
        <div>
          <DiscussionsDocs docs={docs} search={search} />
        </div>
      );
    else if (props.selectedIcon === 5)
      return (
        <div>
          <DiscussionsCourses data={props.data.courses} search={search} />
        </div>
      );
    else if (props.selectedIcon === 6)
      return (
        <div>
          <DiscussionsNews
            data={props.data.news}
            history={props.history}
            search={search}
          />
        </div>
      );
    // else if (props.selectedIcon === 7)
    //   return (
    //     <div>
    //       <DiscussionsNews
    //         data={props.data.news}
    //         history={props.history}
    //         search={search}
    //       />
    //     </div>
    //   );
    else return <></>;
  };

  return (
    <div className="w-100">
      <div className="d-flex w-100 justify-content-between px-1 align-items-center">
        <div className="d-flex">
          <img
            src={require("assets/images/logos/JPLogoSmall.png")}
            alt="JP Logo"
            style={{ width: "12.25px", height: "18.5px", marginTop: 2 }}
            className="ml-3 mr-2"
          />
          <h5 style={{ color: "#383838" }}>{props.location} Groupchat</h5>
        </div>
        <div className="d-flex align-items-center Topbar mr-3" id="Topbar">
          {topBarData.map(({ id, icon, name, count }) => {
            return (
              <div
                className={
                  props.selectedIcon === id
                    ? "topbar-items-border d-flex align-items-center pt-3 px-2 topbar-items cursor-pointer"
                    : "d-flex align-items-center pt-3 px-2 topbar-items cursor-pointer"
                }
                key={id}
                id={id}
                onClick={() => {
                  props.setSelectedIcon(id);
                  setSelectedIconName(name);
                }}
              >
                {icon}
                &nbsp;{count}
              </div>
            );
          })}
          <BsThreeDotsVertical size={16} className="mt-1" />
        </div>
      </div>
      {props.selectedIcon !== 0 && (
        <div className="item-search position-relative d-flex mt-4">
          <div className="shared-search px-3 ml-3">
            <input
              type="text"
              className="form-control-1"
              // onKeyUp={handleKeyUp}
              placeholder={"Search" + " " + selectedIconName}
              onChange={(ev) => {
                handleSearch(ev);
              }}
            />
            <span className="icon-position-1">
              <FaSistrix />
            </span>
          </div>
        </div>
      )}
      <div className="pl-3 min-h">{selectedComponent()}</div>
      <DiscussionsCreate
        handleClose={handleClose}
        openCreate={props.openCreate}
       />
    </div>
  );
};

export default Discussions;
