import React, { memo, useEffect, useState } from "react";
import { toast } from "react-toastify";
import DetailListTitle from "./detail_list_title";
import { DetailListSorting } from "./detail_list_sorting";
import { SearchBar } from "shared/components/templates/menu_list";
import { useLocation } from "react-router-dom";
import { useSelector } from "react-redux";
import { ROLE_IDS } from "../../../../config/constants";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import { Tooltip } from "@mui/material";
import { Button } from "react-bootstrap";
import CustomModal from "../../../../components/shared/customModal";
import { Autocomplete, TextField } from "@mui/material";
import { Utility } from "../../../../utils";
import { CLIENT_ID } from "../../../../config/constants/config";

import {
  GET_USERS_BY_NETWORKS,
  ADD_USER_TO_NEIGHBORHOOD,
  GET_USERS_OF_NEIGHBORHOOD,
} from "../../../../config/constants/api_endpoints";
import { store } from "../../../../store";
import { showLoader } from "../../../actions";

let DetailListHeader = (props) => {
  const location = useLocation();
  const userData = useSelector((state) => state.userData);
  const [openAddMemebrsModal, setOpenMembersModal] = useState(false);
  const [searchValue, setSearchValue] = useState("");
  const [filteredOptions, setFilteredOptions] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [otherMembers, setOtherMembers] = useState([]);
  const [neigborhoodMembers, setNeigborhoodsMembers] = useState([]);

  let debouncedFn = null;
  const handleSearch = (event) => {
    event.persist();
    if (searchValue.length === 0) {
      setFilteredOptions([]);
    }
    setSearchValue(event.target.value);
    if (!debouncedFn) {
      debouncedFn = Utility.debounce(() => {
        if (searchValue.length !== 0) getAllUSers();
      }, 500);
    }
    debouncedFn();
  };
  const getAllUSers = () => {
    setIsLoading(true);
    const params = {
      client_id: CLIENT_ID,
      type: "users",
      forCommunity: true,
    };
    if (searchValue) params.search = searchValue;

    Utility.sendRequest(GET_USERS_BY_NETWORKS, 1, params, (err, res, body) => {
      body = JSON.parse(body);
      if (body.error) {
        toast.error(body.error.message, { containerId: "private" });
        setIsLoading(false);
      } else {
        if (body.registrants) {
          setFilteredOptions(
            body.registrants.filter(
              (registrant) =>
                registrant.is_public === true &&
                !neigborhoodMembers.some(
                  (member) => member.user_id === registrant.user_id
                )
            )
          );
          setIsLoading(false);
        }
      }
    });
  };

  useEffect(() => {
    getUsersOfNeighborhood();
  }, [openAddMemebrsModal]);

  const getUsersOfNeighborhood = () => {
    const params = {
      platform_id: CLIENT_ID, //WEA
      client_id: userData.client_id, //neighborhood
    };
    Utility.sendRequest(
      GET_USERS_OF_NEIGHBORHOOD,
      2,
      params,
      (err, res, body) => {
        body = JSON.parse(body);
        if (body.error) {
          toast.error(body.error.message, { containerId: "private" });
        } else {
          setNeigborhoodsMembers(body.data);
        }
      }
    );
  };
  const handleChangeAddMemebrs = (event, values) => {
    const updatedMembers = values ? values.map((item) => item.user_id) : [];
    setOtherMembers([]);
    setOtherMembers(updatedMembers);
  };
  const addMembers = async () => {
    setOpenMembersModal(false);
    setFilteredOptions([]);
    setSearchValue("");
    const params = {
      client_id: CLIENT_ID,
      neighbourhood_id: userData.client_id,
      user_id: otherMembers,
      user_client_id: userData.id,
    };
    store.dispatch(showLoader(true));
    Utility.sendRequest(
      ADD_USER_TO_NEIGHBORHOOD,
      2,
      params,
      (err, res, body) => {
        body = JSON.parse(body);
        store.dispatch(showLoader(false));
        if (body.error) {
          toast.error(body.error.message, { containerId: "private" });
        } else {
          toast.success(body.result, { containerId: "private" });
          props.getMembersOfNeighborhood(userData.client_id);
          store.dispatch(showLoader(false));
          return body;
        }
      }
    );
  };
  return (
    <div className="d-flex flex-wrap justify-content-between">
      <CustomModal
        open={openAddMemebrsModal}
        onClose={() => {
          setOpenMembersModal(false);
          setFilteredOptions([]);
          setSearchValue("");
        }}
        classToOverride="uploadDocumentModal"
      >
        <>
          <div className="text-center">
            <h1 className="font-30-bold mb-4">Add Members</h1>
          </div>
          <Autocomplete
            loading={isLoading}
            multiple
            // disableCloseOnSelect
            id="joinedNeighbourhood"
            options={
              searchValue.length !== 0 && filteredOptions.length !== 0
                ? filteredOptions
                : []
            }
            getOptionLabel={(option) => option.user_name}
            onChange={handleChangeAddMemebrs}
            renderOption={(props, option) => {
              return (
                <li
                  className="community-option-container"
                  key={option.user_id}
                  {...props}
                >
                  <p className="community-option-container-text">
                    {option.user_name}
                  </p>
                </li>
              );
            }}
            name="joinedNeighbourhood"
            className="field-common-styling-networks w-75 mx-auto"
            renderInput={(params) => (
              <TextField
                {...params}
                className="autoComplete-textfield-styling w-100 mx-auto"
                placeholder="Search Members..."
                variant="outlined"
                onChange={handleSearch}
              />
            )}
          />
          <div className="text-center mb-4">
            <Button
              className="add-members mb-2 mt-4 w-75 mx-auto mx-3"
              // variant="primary"
              onClick={() => {
                addMembers();
              }}
            >
              Add People
            </Button>
          </div>
        </>
      </CustomModal>
      <DetailListTitle headerTitle={props.headerTitle} />
      <div className="align-items-center mt-2 mt-sm-0">
        {((props.selectedUsers && props.selectedUsers.length > 0) ||
          (props.selectedClients && props.selectedClients.length > 0)) &&
          (props.isShowActiveUser !== "active" ? (
            <div
              onClick={() => {
                if (props.selectedUsers.length > 0)
                  props.activateDeactivateUser(
                    props.emails,
                    props.selectedUsers,
                    true,
                    "users"
                  );
                if (props.selectedClients.length > 0)
                  props.activateDeactivateUser(
                    props.client_emails,
                    undefined,
                    undefined,
                    "",
                    props.selectedClients,
                    true
                  );
              }}
              className="btn-markRead-styling mr-3"
            >
              MARK ALL ACTIVE
            </div>
          ) : (
            <div
              onClick={() => {
                if (props.selectedUsers.length > 0)
                  props.activateDeactivateUser(
                    props.emails,
                    props.selectedUsers,
                    false,
                    "users"
                  );
                if (props.selectedClients.length > 0)
                  props.activateDeactivateUser(
                    props.client_emails,
                    undefined,
                    undefined,
                    "",
                    props.selectedClients,
                    false
                  );
              }}
              className=" btn-markRead-styling btn-new-style mr-3"
            >
              MARK ALL INACTIVE
            </div>
          ))}
        {!userData.is_public && location.pathname.includes("networks") && (
          <Tooltip title="Add Members" placement="left">
            <div
              className="d-inline-block mr-4 cursor-pointer"
              onClick={() => setOpenMembersModal(true)}
            >
              <AddCircleIcon className="text-primary" fontSize="large" />
            </div>
          </Tooltip>
        )}
        <div className="d-inline-block mr-4">
          {props.showFilterComponent && (
            <SearchBar placeholder="Search" handleKeyUp={props.filterList} />
          )}
        </div>

        {location.pathname.includes("networks") &&
          [
            ROLE_IDS.PARENT_ADMIN,
            ROLE_IDS.PARENT_OWNER,
            ROLE_IDS.SUPER_OWNER,
          ].includes(userData.role_id) && (
            <DetailListSorting
              value={props.isShowActiveUser}
              handleChangeActiveCatrgory={props.handleChangeActiveCatrgory}
            />
          )}
      </div>
    </div>
  );
};

export default DetailListHeader = memo(DetailListHeader);
