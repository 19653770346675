import { useState, useEffect } from "react";
import { Utility } from 'utils';

const useForm = (formFields, callback, validate, validateFields) => {
  const [values, setValues] = useState(formFields);
  const [errors, setErrors] = useState({});
  const [isSubmitting, setIsSubmitting] = useState(false);

  const handleChange = event => {
    const { name, id } = event.target;
    setValues({
      ...values,
      [name]: Utility.getValueFromEventByType(event)
    });

    if(validateFields.includes(id))
      document.getElementById(id + 'Error').innerText = '';
  };

  const handleSubmit = event => {
    event.preventDefault();
    setErrors(validate(values));
    setIsSubmitting(true);
  };

  useEffect(() => {
    if (!Object.keys(errors).length && isSubmitting) {
      callback();
    } else {
      Object.keys(errors).map(e => {
        if(validateFields.includes(e)){
          const errorNode = document.getElementById(e + 'Error');
          if(errors[e] && errorNode)
            errorNode.innerText = errors[e];
          else
            errorNode.innerText = '';
        }
      });
    }
  }, [errors]);

  const hideErrors = () => {
    Object.keys(values).map(e => {
      if(validateFields.includes(e)){
        const errorNode = document.getElementById(e + 'Error');
        errorNode.innerText = '';
      }
    });
  };

  return {
    handleChange,
    handleSubmit,
    values,
    setValues,
    hideErrors,
  };
};

export default useForm;
