import React from "react";
import { AiOutlineArrowLeft } from "react-icons/ai";
import { FaBookReader } from "react-icons/fa";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import { HiDotsHorizontal } from "react-icons/hi";
import Tooltip from "@material-ui/core/Tooltip";
import {
  updateIsCourseDropdown,
  updateIsCourseSidebar,
  updateSeeAll,
} from "../../../actions";
import { Utility } from "../../../../utils";
import { CircleFilled } from "../../../icons/icons";

class CourseHeader extends React.Component {
  constructor(props) {
    super(props);
  }
  handleStartCourse = (e) => {
    e.preventDefault();
    this.props.updateIsCourseDropdown(true);
    this.props.updateIsCourseSidebar(true);
  };
  render() {
    return (
      <div className="d-flex coursePageHeader">
        <div className="d-flex bd-highlight pt-1 pb-1 w-75 align-items-baseline">
          <div
            className="p-1 py-2 bd-highlight cursor-pointer"
            onClick={() => this.props.history.goBack()}
          >
            <AiOutlineArrowLeft
              style={{ width: "20px", height: "20px", color: "#FFFFFF" }}
              className="CourseHeaderIcon"
            />
          </div>
          <div
            className="d-flex cursor-pointer"
            onClick={this.props.handleHomeCourse}
          >
            <div className="p-1 py-2 bd-highlight">
              <FaBookReader
                style={{ width: "20px", height: "20px", color: "#ff9900" }}
                className="CourseHeaderIcon"
              />
            </div>
            <div className="p-1 py-1  bd-highlight">
              <h6 className="pt-1 courseHeaderHeading text-white">
                {this.props.courseDetails[0] !== undefined &&
                  this.props.courseDetails[0].heading}
              </h6>
            </div>
          </div>
        </div>
        <div
          className="d-flex bd-highlight pt-1 pb-1 px-1 w-25 align-items-center"
          style={this.props.isLesson ? { borderLeftStyle: "ridge" } : {}}
        >
          <div className="ps-1 bd-highlight">
            {this.props.isLesson && (
              <div className="d-flex align-items-center">
                {" "}
                <CircleFilled color={"lightgrey"} />
                <Tooltip title={this.props.pageHeading}>
                  <h6 className="d-inline p-1 pt-2 courseHeaderHeading text-white">
                    {Utility.pwaModeEnabled()
                      ? this.props.pageHeading.length > 10
                        ? this.props.pageHeading.substring(0, 11) + "..."
                        : this.props.pageHeading
                      : this.props.pageHeading.length > 24
                      ? this.props.pageHeading.substring(0, 25) + "..."
                      : this.props.pageHeading}
                  </h6>
                </Tooltip>
              </div>
            )}
          </div>

          {/* {this.props.activePanal === "lesson" ? (
            <div className="ml-auto bd-highlight mr-2">
              <HiDotsHorizontal />
            </div>
          ) : (
            <div className="ml-auto bd-highlight mr-2">
              <button
                onClick={this.handleStartCourse}
                className="btn btn-primary CourseHeaderBtn"
              >
                Start Course
              </button>
            </div>
          )} */}
        </div>
      </div>
    );
  }
}

const mapActionToProps = {
  updateIsCourseDropdown,
  updateIsCourseSidebar,
  updateSeeAll,
};

export default connect(null, mapActionToProps)(CourseHeader);
