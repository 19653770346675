/* eslint-disable react/no-direct-mutation-state */
import React, { Component } from "react";
import {
  updateUserData,
  // showLoader,
  updatePublicUserData,
  updateIsChatFormOpen,
  userQualities,
  updateChatFriend,
  updateSeeAll,
  openNeighborhoodForm,
  updateAccountList,
} from "shared/actions";
import { connect } from "react-redux";
import { CircleFilledAddLargeIcon } from "shared/icons/icons";
import { Utility } from "utils";
import Tooltip from "@material-ui/core/Tooltip";
import {
  CLIENTS,
  SIGNED_URL,
  USER_AUTH,
} from "../../config/constants/api_endpoints";
import {
  CAMPAIGN_STORIES,
  GET_FOLLOWERS_FOLLOWING,
  USER_PROFILE,
} from "config/constants/api_endpoints";
import { toast } from "react-toastify";
import { CLIENT_ID } from "config/constants/config";
import GodCalling from "./components/GodCalling";
import { ROLE_IDS, CAMPAIGN_TYPES_IDS } from "config/constants";
import UserProfileSection from "./components/UserProfileSection";
import UserAboutSection from "./components/UserAboutSection";

import ProfileCourse from "./components/ProfileCourse";
import ProfileNews from "./components/ProfileNews";
import ProfileEvents from "./components/ProfileEvents";
import RightSharedSection from "./components/RightSharedSection";
import { GrSearch } from "react-icons/gr";
import { BsCloudUpload } from "react-icons/bs";
import InforCard from "./components/InforCard";
import ProfileActivity from "./components/ProfileActivity";
import { AiOutlineInfoCircle } from "react-icons/ai";
import { EditProfileCallout } from "../users/components/EditProfileCallOut/EditProfileCallout";
import { Button, Dropdown, Tab, Tabs } from "react-bootstrap";

import {
  CAMPAIGNS,
  FAVORITE_CAMPAIGNS,
  FEATURED_COMMUNITY,
  FEATURED_FEEDS,
  GET_USERS_OF_NEIGHBORHOOD,
  GET_USER_QUALITY,
  MEMBER_ALL_EVENT,
  UPDATE_USER_QUALITY,
  USER_ACTIVITY,
  WEA_QUALITY_MASTER,
} from "../../config/constants/api_endpoints";
import Introduction from "../../components/profile/Introduction";
import LeaveCommunity from "../../components/profile/LeaveCommunity";
import { getNeighborhoodsOfUser } from "../../services/profile";
import { has } from "lodash";
import CustomModal from "../../components/shared/customModal";
import { uploadMedia } from "../../services/uploadMedia";
// import FileViewer from "react-file-viewer";
import DeleteIcon from "@mui/icons-material/Delete";
import { GIL_CLIENT_ID } from "../../config/constants";
import { CircularProgress, Skeleton } from "@mui/material";
import EditOutlinedIcon from "@mui/icons-material/EditOutlined";

// import { SearchIcon } from "../../shared/components/header";

class ProfileContainer extends Component {
  constructor(props) {
    super(props);
    this.state = {
      myArticles: [],
      currentSelectKey: "about",
      listFollowerActive: false,
      userData: {},
      isPublic: false,
      passportData: [],
      selectedGodQualities: [],
      selectedOrganizationQualities: [],
      selectedStrengthQualitites: [],
      selectedAllQualitites: [],
      groupsData: [],
      profileEvents: [],
      suggestedMembers: [],
      is_following_expand: false,
      is_follower_expand: false,
      DiscussionSearch: "",
      following: [],
      follower: [],
      myCourses: [],
      GodQualities: [],
      myNews: [],
      uploadedFiles: [],
      selectedFile: { name: "", url: "" },
      loader: {
        userQuality: false,
        events: false,
        course: false,
        news: false,
        discussion: false,
        userActivity: false,
        followerFollowing: false,
        communityMembers: false,
        featuredCommunity: false,
        favoriteCampaigns: false,
      },
      is_Followed: false,
      seeMoreNetworks: false,
      seeMoreAdmins: false,
      showGroupNameCreation: false,
      seeMoreCommunity: false,
      seeMoreEvents: false,
      seeMoreArticles: false,
      seeMoreCourses: false,
      isEdit: false,
      showPassportCallout: true,
      isClickable: false,
      dicussionTopicName: "",
      profileDisussion: [],
      featuredCommunity: [],
      communityMembers: [],
      favoriteCampaigns: [],
      userActivities: [],
      isDiscussionSeeMore: false,
      isNeighborhoodJoined: false,
      pwaEnable: false,
      open: false,
      isDisable: true,
      isLoading: false,
      uploadedDocuments: [],
      isDocumentsSeeMore: false,
      openDeleteConfirmationModal: false,
      removeDocumentId: "",
      isEditDocNameEnable: false,
      documentName: "",
      newDocName: "",
    };
  }
  setOpen = (value) => {
    this.setState({ open: value });
  };

  handleClose = () => {
    this.setOpen(false);
    this.setState({ selectedFile: { name: "", url: "" } });
    this.setState({
      uploadedFiles: [],
      isLoading: false,
    });
    this.setState({
      openDeleteConfirmationModal: false,
    });
    this.setState({
      isEditDocNameEnable: false,
    });
    this.setState({
      removeDocumentId: "",
    });
  };
  handleCloseEditDocModal = () => {
    this.setState({
      isEditDocNameEnable: false,
    });
  };
  handleFollow = (value) => {
    this.setState({
      is_Followed: value,
    });
  };
  enablePwa = () => {
    const pwaEnable = Utility.pwaModeEnabled();

    this.setState({ pwaEnable });
  };
  authUser = () => {
    Utility.sendRequest(
      USER_AUTH,
      1,
      localStorage.token,
      (error, response, body) => {
        body = JSON.parse(body);

        if (!body.error) {
          this.props.updateUserData(body);
        } else {
          toast.error(body.error.message, { containerId: "public" });
        }
      }
    );
  };
  handleDownload = async (fileUrl, fileName) => {
    try {
      const response = await fetch(fileUrl);
      const blob = await response.blob();
      const url = URL.createObjectURL(blob);
      const a = document.createElement("a");
      a.href = url;
      a.download = fileName;
      document.body.appendChild(a);
      a.click();
      document.body.removeChild(a);
      URL.revokeObjectURL(url);
    } catch (error) {
      console.error("Error occurred during file download:", error);
    }
  };
  uploadFiles = () => {
    // this.props.showLoader(true);
    const params = {
      new_documents: this.state.uploadedFiles,
    };
    Utility.sendRequest(CLIENTS, 3, params, (error, response, body) => {
      body = JSON.parse(body);
      if (!body.error) {
        toast.success("Document uploaded successfully", {
          containerId: "public",
        });
        this.authUser();
        // this.props.showLoader(false);
      } else {
        toast.error(body.error.message, { containerId: "public" });
        // this.props.showLoader(false);
      }
    });
    this.setState({
      uploadedFiles: [],
    });
  };
  removeFiles = (documentId) => {
    // this.props.showLoader(true);
    const params = {
      remove_document: [documentId],
    };
    Utility.sendRequest(CLIENTS, 3, params, (error, response, body) => {
      body = JSON.parse(body);
      // this.props.showLoader(false);
      // this.setState({ apiResponse: true });
      if (!body.error) {
        toast.success("Document removed successfully", {
          containerId: "public",
        });
        this.authUser();
        // this.props.showLoader(false);
      } else {
        toast.error(body.error.message, { containerId: "public" });
        // this.props.showLoader(false);
      }
    });
  };
  handleDeleteModal = (documentId) => {
    this.setState({
      openDeleteConfirmationModal: true,
    });
    this.setState({
      removeDocumentId: documentId,
    });
  };
  handleDeleteDocuments = () => {
    this.removeFiles(this.state.removeDocumentId);
    this.setState({
      openDeleteConfirmationModal: false,
    });
    this.setState({
      removeDocumentId: "",
    });
  };
  componentDidMount = () => {
    // const {
    //   match: { params },
    // } = this.props;
    if (this.props.userData) getNeighborhoodsOfUser(this.props.userData);
    // }
    this.enablePwa();
    const params = this.props.match
      ? this.props.match.params
      : { id: this.props.id };

    if (params.id) {
      this.setState({
        isPublic: true,
      });
    } else {
      this.setState({
        isPublic: false,
      });
    }
    if (!Utility.isEmpty(params.id)) {
      this.profileData(params.id);
    } else {
      this.setState({ userData: this.props.userData });
      if (!Utility.isEmpty(this.props.userData)) {
        this.getFavoriteCampiagns(this.props.userData);
        if (
          this.props.userData.role_id === ROLE_IDS.CLIENT_ADMIN ||
          this.props.userData.role_id === ROLE_IDS.CLIENT_OWNER
        ) {
          this.loadFeaturedStories(
            CAMPAIGN_TYPES_IDS.courses,
            this.props.userData.user_id,
            this.props.userData.id,
            this.props.userData.client_id
          );
          this.loadFeaturedStories(
            CAMPAIGN_TYPES_IDS.story,
            this.props.userData.user_id,
            this.props.userData.id,
            this.props.userData.client_id
          );

          this.loadFeaturedStories(
            CAMPAIGN_TYPES_IDS.event,
            this.props.userData.user_id,
            this.props.userData.id,
            this.props.userData.client_id
          );
        } else {
          this.loadFeaturedStories(
            CAMPAIGN_TYPES_IDS.courses,
            this.props.userData.user_id,
            this.props.userData.id
          );
          this.loadFeaturedStories(
            CAMPAIGN_TYPES_IDS.story,
            this.props.userData.user_id,
            this.props.userData.id,
            undefined
          );
          this.getEvents(this.props.userData.id);
        }

        if (this.props.userData.role_id === ROLE_IDS.USER) {
          this.getUserDiscussion(
            CAMPAIGN_TYPES_IDS.discussion,
            this.props.userData.user_id
          );
        } else {
          this.loadFeaturedStories(
            CAMPAIGN_TYPES_IDS.discussion,
            this.props.userData.user_id,
            this.props.userData.id
          );
        }

        if (this.props.userData.network === "users")
          this.getUserActivities(this.props.userData);
        // this.getAllCategories(this.props.userData);

        this.getApiResult(this.props.userData.id, "following", CLIENT_ID);
        this.getApiResult(this.props.userData.id, "follower", CLIENT_ID);
        if (this.state.userData.client_id)
          this.getUsersOfNeighborhood(this.state.userData.client_id);

        // this.getUserQualities();

        this.getUserQualities(this.props.userData.id);
        this.getQualityMaster();
        if (this.state.userData.user_id)
          this.getfeaturedCommunity(this.state.userData.user_id);
      }
    }

    if (this.props.seeAll.calledFrom === "community")
      this.props.updateSeeAll({
        isOpen: null,
        calledFrom: this.props.seeAll.calledFrom,
      });
    else
      this.props.updateSeeAll({
        isOpen: this.props.seeAll.calledFrom,
        calledFrom: this.props.seeAll.calledFrom,
      });
  };
  getApiResult = (userClientId, type, client_id) => {
    this.setState((prevState) => ({
      loader: { ...prevState.loader, followerFollowing: true },
    }));
    const params = {
      userClientId: userClientId,
      type: type,
      clientId: client_id,
    };
    // this.props.showLoader(true);
    if (userClientId && client_id) {
      Utility.sendRequest(
        GET_FOLLOWERS_FOLLOWING,
        1,
        params,
        (error, response, body) => {
          body = JSON.parse(body);
          this.setState((prevState) => ({
            loader: { ...prevState.loader, followerFollowing: false },
          }));

          // this.props.showLoader(false);
          this.setState({ apiResponse: true });
          if (!body.error) {
            this.setState({ [type]: body[type] });
          }
        }
      );
    }
  };

  setClickableState = (value) => {
    this.setState({ isClickable: value });
  };
  setIsNeighborhoodJoined = (value) => {
    this.setState({ isNeighborhoodJoined: value });
  };
  loadFeaturedStories = async (campTypeId, userId, user_client_id, corp_id) => {
    if (campTypeId === CAMPAIGN_TYPES_IDS.courses)
      this.setState((prevState) => ({
        loader: { ...prevState.loader, course: true },
      }));
    else if (campTypeId === CAMPAIGN_TYPES_IDS.story)
      this.setState((prevState) => ({
        loader: { ...prevState.loader, news: true },
      }));
    else if (campTypeId === CAMPAIGN_TYPES_IDS.discussion) {
      this.setState((prevState) => ({
        loader: { ...prevState.loader, discussion: true },
      }));
    } else if (campTypeId === CAMPAIGN_TYPES_IDS.event) {
      this.setState((prevState) => ({
        loader: { ...prevState.loader, events: true },
      }));
    }
    let params = {
      publisher_network_id: CLIENT_ID,
      type: campTypeId,
      user_id: userId,
    };

    if (user_client_id) {
      params = {
        ...params,
        user_client_id: user_client_id,
      };
    }

    if (corp_id) {
      params = {
        ...params,
        corporate_id: corp_id,
      };
    } else {
      if (this.props.userData.network === "clients")
        params = {
          ...params,
          corporate_id: this.props.userData.client_id,
        };
    }

    if (
      ROLE_IDS.USER === this.state.userData.role_id &&
      campTypeId !== CAMPAIGN_TYPES_IDS.story &&
      campTypeId !== CAMPAIGN_TYPES_IDS.discussion
    ) {
      params["is_featured"] = true;
    }
    await Utility.sendRequest(CAMPAIGN_STORIES, 1, params, (err, res, body) => {
      body = JSON.parse(body);

      if (body.error) {
        toast.error(body.error.message, { containerId: "private" });
      } else {
        const Data = body.data.filter(
          (items) =>
            items.corporate_is_active === true ||
            items.corporate_is_active === null
        );
        if (campTypeId === CAMPAIGN_TYPES_IDS.courses) {
          this.setState({ myCourses: Data });
          this.setState((prevState) => ({
            loader: { ...prevState.loader, course: false },
          }));
        } else if (campTypeId === CAMPAIGN_TYPES_IDS.story) {
          this.setState((prevState) => ({
            loader: { ...prevState.loader, news: false },
          }));
          this.setState({ myArticles: Data });
        } else if (campTypeId === CAMPAIGN_TYPES_IDS.discussion) {
          this.setState({ profileDisussion: Data });
          this.setState((prevState) => ({
            loader: { ...prevState.loader, discussion: false },
          }));
        } else if (campTypeId === CAMPAIGN_TYPES_IDS.event) {
          this.setState({ profileEvents: Data });
          this.setState((prevState) => ({
            loader: { ...prevState.loader, events: false },
          }));
        }
      }
    });
  };

  getEvents = (id) => {
    const params = {
      // publisher_network_id: ETHOS_CLIENT_ID,
      // type: "93ab8d70-3d6b-4f3f-a702-8ead10a90bcf"
    };
    // this.props.showLoader(true);
    Utility.sendRequest(
      `${MEMBER_ALL_EVENT}/${id}`,
      1,
      params,
      (err, res, body) => {
        body = JSON.parse(body);

        // this.props.showLoader(false);
        if (body.error) {
          toast.error(body.error.message, { containerId: "private" });
        } else {
          this.setState({
            profileEvents: body.filter(
              (items) =>
                items.corporate_is_active === true ||
                items.corporate_is_active === null
            ),
          });
        }
      }
    );
  };

  getUserDiscussion = async (campTypeId, userId, corp_id) => {
    let params = {
      publisher_network_id: CLIENT_ID,
      type: campTypeId,
      user_id: userId,
    };
    this.setState((prevState) => ({
      loader: { ...prevState.loader, discussion: true },
    }));

    if (corp_id) {
      params = {
        ...params,
        corporate_id: corp_id,
      };
    } else {
      if (this.props.userData.network === "clients")
        params = {
          ...params,
          corporate_id: this.props.userData.client_id,
        };
    }

    await Utility.sendRequest(FEATURED_FEEDS, 1, params, (err, res, body) => {
      body = JSON.parse(body);
      this.setState((prevState) => ({
        loader: { ...prevState.loader, discussion: false },
      }));

      if (body.error) {
        toast.error(body.error.message, { containerId: "private" });
      } else {
        this.setState({
          profileDisussion: body.data.filter(
            (items) =>
              items.corporate_is_active === true ||
              items.corporate_is_active === null
          ),
        });
      }
    });
  };

  getUserQualities = (id) => {
    const params = {};
    this.setState((prevState) => ({
      loader: { ...prevState.loader, userQuality: true },
    }));
    Utility.sendRequest(
      `${GET_USER_QUALITY}?user_client_id=${id}`,
      1,
      params,
      (error, response, body) => {
        body = JSON.parse(body);
        // this.props.showLoader(false);
        this.setState((prevState) => ({
          loader: { ...prevState.loader, userQuality: false },
        }));
        if (!body.error) {
          if (body.data && Object.keys(body.data).includes("user_qualities")) {
            if (has(body.data.user_qualities, "length"))
              this.setState({ selectedGodQualities: body.data.user_qualities });
          } else {
            this.setState({ selectedGodQualities: [] });
          }
        }
      }
    );
  };

  setUserQualities = (userQualities) => {
    let userData;
    const param = this.props.match
      ? this.props.match.params
      : { id: this.props.id };
    userData = param.id ? this.props.publicUserData : this.props.userData;
    // this.props.showLoader(true);
    const params = {
      client_id: CLIENT_ID,
      user_client_id: this.state.userData.id,
      user_qualities: userQualities,
    };
    this.props.userQualities(userQualities);
    Utility.sendRequest(
      UPDATE_USER_QUALITY,
      2,
      params,
      (error, response, body) => {
        body = JSON.parse(body);
        // this.props.showLoader(false);

        if (!body.error) {
          // this.setState({ myCourses: body.data });
          this.getUserQualities(userData.id);
        }
      }
    );
  };

  addGodSelectedQualities = (id, name, section) => {
    const tempQualities = [...this.state.selectedGodQualities];
    const foundIndex = tempQualities.findIndex(
      (data) => data.id === id && data.section === section
    );
    // console.log(
    //   tempQualities,
    //   tempQualities.findIndex(
    //     (data) => data.id === id && data.section === section
    //   )
    // );
    if (foundIndex < 0) {
      tempQualities.push({
        id: id,
        title: name,
        section: section,
      });
    } else {
      tempQualities[foundIndex] = {
        id: id,
        title: name,
        section: section,
      };
    }
    this.setState({
      selectedGodQualities: tempQualities,
    });
    this.setUserQualities(tempQualities);
  };

  addOrganizationQualities = (id, name, section) => {
    const tempQualities = this.state.selectedGodQualities;
    if (
      !tempQualities.find((data) => data.id === id && data.section === section)
    )
      tempQualities.push({
        id: id,
        title: name,
        section: section,
      });
    this.setState({
      selectedGodQualities: tempQualities,
    });
    this.setUserQualities(tempQualities);
  };

  addStrengthQualities = (id, name, section) => {
    const tempQualities = this.state.selectedGodQualities;
    if (
      !tempQualities.find((data) => data.id === id && data.section === section)
    )
      tempQualities.push({
        id: id,
        title: name,
        section: section,
      });
    this.setState({
      selectedGodQualities: tempQualities,
    });
    this.setUserQualities(tempQualities);
  };

  removeStrengthQualities = (id) => {
    let tempQualities = this.state.selectedStrengthQualitites;
    if (tempQualities.find((data) => data.id === id))
      tempQualities = tempQualities.filter((data) => data.id !== id);
    this.setState({
      selectedStrengthQualitites: tempQualities,
    });
    this.setUserQualities(tempQualities);
  };

  handleUnfollow = (followingId, followerId, clientId) => {
    const params = { followerId, followingId, clientId };
    params["is_active"] = false;

    Utility.sendRequest(
      GET_FOLLOWERS_FOLLOWING,
      2,
      params,
      (err, res, body) => {
        body = JSON.parse(body);
        if (body.error) {
          toast.error(body.error.message, { containerId: "private" });
        } else {
          this.getApiResult(this.props.userData.id, "follower", CLIENT_ID);
        }
      }
    );
  };

  addStrengthQualities = (id, name, section) => {
    const tempQualities = this.state.selectedGodQualities;
    if (
      !tempQualities.find((data) => data.id === id && data.section === section)
    )
      tempQualities.push({
        id: id,
        title: name,
        section: section,
      });
    this.setState({
      selectedGodQualities: tempQualities,
    });
    this.setUserQualities(tempQualities);
  };

  removeStrengthQualities = (id) => {
    let tempQualities = this.state.selectedStrengthQualitites;
    if (tempQualities.find((data) => data.id === id))
      tempQualities = tempQualities.filter((data) => data.id !== id);
    this.setState({
      selectedStrengthQualitites: tempQualities,
    });
  };

  removeOrganizationQualities = (id) => {
    let tempQualities = this.state.selectedOrganizationQualities;
    if (tempQualities.find((data) => data.id === id))
      tempQualities = tempQualities.filter((data) => data.id !== id);
    this.setState({
      selectedOrganizationQualities: tempQualities,
    });
  };

  removeGodSelectedQualities = (id, section) => {
    const tempQualities = this.state.selectedGodQualities;
    const removedSectionTempQualities = tempQualities.filter(
      (item) => item.id !== id && item.section === section
    );
    const restTempQualities = tempQualities.filter(
      (item) => item.section !== section
    );
    this.setState({
      selectedGodQualities:
        removedSectionTempQualities.concat(restTempQualities),
    });
    this.setUserQualities(
      removedSectionTempQualities.concat(restTempQualities)
    );
  };

  removeOrganizationQualities = (id) => {
    let tempQualities = this.state.selectedOrganizationQualities;
    if (tempQualities.find((data) => data.id === id))
      tempQualities = tempQualities.filter((data) => data.id !== id);
    this.setState({
      selectedOrganizationQualities: tempQualities,
    });
  };

  removeGodSelectedQualities = (id, section) => {
    const tempQualities = this.state.selectedGodQualities;
    const removedSectionTempQualities = tempQualities.filter(
      (item) => item.id !== id && item.section === section
    );
    const restTempQualities = tempQualities.filter(
      (item) => item.section !== section
    );
    this.setState({
      selectedGodQualities:
        removedSectionTempQualities.concat(restTempQualities),
    });
    this.setUserQualities(
      removedSectionTempQualities.concat(restTempQualities)
    );
  };

  componentDidUpdate = (prevProps, prevStates) => {
    let userData = {};
    const params = this.props.match
      ? this.props.match.params
      : { id: this.props.id };
    if (params.id !== prevProps.id && this.state.isPublic !== true) {
      this.setState({
        isPublic: true,
      });
    } else if (this.state.isPublic !== false && !(params.id || prevProps.id)) {
      this.setState({
        isPublic: false,
      });
    }
    if (
      !Utility.isEmpty(params.id) &&
      prevProps.match &&
      prevProps.match.params.id !== params.id
    ) {
      this.profileData(params.id);
    }
    if (
      prevStates.myArticles.length !== this.state.myArticles.length ||
      prevProps.userData.role !== this.props.userData.role ||
      prevProps.userData !== this.props.userData ||
      prevStates.userData.id !== this.state.userData.id ||
      (this.props.match &&
        prevProps.match.params.id !== this.props.match.params.id)
    ) {
      // const {
      //   match: { params },
      // } = this.props;
      const params = this.props.match
        ? this.props.match.params
        : { id: this.props.id };
      userData = params.id ? this.props.publicUserData : this.props.userData;
      this.setState({
        userData: userData,
        currentSelectKey: "about",
      });

      this.getFavoriteCampiagns(this.state.userData);
      this.getApiResult(userData.id, "following", CLIENT_ID);
      this.getApiResult(userData.id, "follower", CLIENT_ID);

      if (this.state.userData.client_id)
        this.getUsersOfNeighborhood(this.state.userData.client_id);
      if (
        prevStates.userData.id !== userData.id ||
        prevStates.isPublic != this.state.isPublic
      ) {
        // if (this.props.userData.role_id === ROLE_IDS.USER) {
        //   this.getUserDiscussion(
        //     CAMPAIGN_TYPES_IDS.discussion,
        //     userData.user_id
        //   );
        // } else {
        //   this.loadFeaturedStories(
        //     CAMPAIGN_TYPES_IDS.discussion,
        //     userData.user_id,
        //     userData.id
        //   );
        // }
        if (
          (this.state.userData.role_id === ROLE_IDS.CLIENT_ADMIN ||
            this.state.userData.role_id === ROLE_IDS.CLIENT_OWNER) &&
          !this.state.isPublic
        ) {
          this.loadFeaturedStories(
            CAMPAIGN_TYPES_IDS.courses,
            this.state.userData.user_id,
            this.state.userData.id,
            this.state.userData.client_id
          );
          if (prevStates.userData.user_id !== this.state.userData.user_id) {
            this.loadFeaturedStories(
              CAMPAIGN_TYPES_IDS.story,
              this.state.userData.user_id,
              this.state.userData.id,
              undefined
            );
          } else {
            this.loadFeaturedStories(
              CAMPAIGN_TYPES_IDS.story,
              this.state.userData.user_id,
              this.state.userData.id,
              this.state.userData.client_id
            );
          }
          this.getEvents(userData.id);
        } else {
          this.loadFeaturedStories(
            CAMPAIGN_TYPES_IDS.courses,
            this.state.userData.user_id,
            this.state.userData.id,
            this.state.userData.client_id
          );
          this.loadFeaturedStories(
            CAMPAIGN_TYPES_IDS.story,
            this.state.userData.user_id,
            this.state.userData.id,
            this.state.userData.client_id
          );
          this.getEvents(userData.id);
        }
        if (userData.network === "users") this.getUserActivities(userData);
      }
      // this.getAllCategories(userData);

      if (userData.user_id) this.getfeaturedCommunity(userData.user_id);
      this.getUserQualities(userData.id);
      this.getQualityMaster();
    }

    if (prevProps.refreshCampaignApi !== this.props.refreshCampaignApi) {
      this.getFavoriteCampiagns(this.state.userData);
      this.loadFeaturedStories(
        CAMPAIGN_TYPES_IDS.story,
        userData.user_id,
        userData.id,
        undefined
      );
    }

    if (
      this.props.match &&
      prevProps.match.params.id !== this.props.match.params.id &&
      prevStates.isPublic === true
    ) {
      if (this.state.userData.role_id === ROLE_IDS.USER) {
        this.getUserDiscussion(
          CAMPAIGN_TYPES_IDS.discussion,
          this.state.userData.user_id
        );
      } else {
        this.loadFeaturedStories(
          CAMPAIGN_TYPES_IDS.discussion,
          this.state.userData.user_id,
          this.state.userData.id
        );
      }
    }

    if (
      this.props.match &&
      this.props.match.params.id === undefined &&
      prevStates.userData.id !== undefined &&
      prevStates.userData.id !== this.state.userData.id
    ) {
      getNeighborhoodsOfUser(userData);

      if (this.state.userData.role_id === ROLE_IDS.USER) {
        this.getUserDiscussion(
          CAMPAIGN_TYPES_IDS.discussion,
          this.state.userData.user_id
        );
      } else {
        this.loadFeaturedStories(
          CAMPAIGN_TYPES_IDS.discussion,
          this.state.userData.user_id,
          this.state.userData.id
        );
      }
    }
  };

  getQualityMaster = () => {
    Utility.sendRequest(WEA_QUALITY_MASTER, 1, {}, (error, response, body) => {
      body = JSON.parse(body);
      if (!body.error) {
        this.setState({ GodQualities: body.data });
        // this.props.showLoader(false);
      }
    });
  };

  profileData = (id) => {
    const params = {
      userClientId: this.props.userData.id,
      publicUserClientId: id,
    };

    // this.props.showLoader(true);
    Utility.sendRequest(USER_PROFILE, 2, params, (error, response, body) => {
      body = JSON.parse(body);

      // this.props.showLoader(false);

      if (!body.error) {
        this.props.updatePublicUserData(body);

        this.setState({ userData: body, currentSelectKey: "about" });
      } else {
        toast.error(body.error.message, { containerId: "public" });
      }

      if (!Utility.isEmpty(body)) {
        this.getFavoriteCampiagns(body);

        this.getUserQualities(body.id);
        this.getQualityMaster();
        this.getApiResult(body.id, "following", CLIENT_ID);
        this.getApiResult(body.id, "follower", CLIENT_ID);

        if (body.client_id) this.getUsersOfNeighborhood(body.client_id);

        if (body.user_id) this.getfeaturedCommunity(body.user_id);
        if (body.network === "users") {
          this.getUserActivities(body);
          this.getUserDiscussion(
            CAMPAIGN_TYPES_IDS.discussion,
            body.user_id,
            body.client_id
          );

          this.loadFeaturedStories(
            CAMPAIGN_TYPES_IDS.courses,
            body.user_id,
            body.id
          );
          this.loadFeaturedStories(
            CAMPAIGN_TYPES_IDS.story,
            body.user_id,
            body.id,
            undefined
          );
          this.getEvents(body.id);
        } else if (body.network === "clients") {
          this.loadFeaturedStories(
            CAMPAIGN_TYPES_IDS.courses,
            body.user_id,
            body.id,
            body.client_id
          );
          this.loadFeaturedStories(
            CAMPAIGN_TYPES_IDS.discussion,
            body.user_id,
            body.id,
            body.client_id
          );
          // if (body.role_id === ROLE_IDS.USER) {
          //   this.getUserDiscussion(
          //     CAMPAIGN_TYPES_IDS.discussion,
          //     body.user_id,
          //     body.client_id
          //   );
          // } else {
          //   this.loadFeaturedStories(
          //     CAMPAIGN_TYPES_IDS.discussion,
          //     body.user_id,
          //     body.id,
          //     body.client_id
          //   );
          // }
          this.loadFeaturedStories(
            CAMPAIGN_TYPES_IDS.story,
            body.user_id,
            body.id,
            body.client_id
          );
          if (
            body.role_id === ROLE_IDS.USER ||
            body.role_id === ROLE_IDS.PARENT_ADMIN ||
            body.role_id === ROLE_IDS.PARENT_OWNER
          ) {
            this.getEvents(body.id);
          } else {
            this.loadFeaturedStories(
              CAMPAIGN_TYPES_IDS.event,
              body.user_id,
              body.id,
              body.client_id
            );
          }
        }
      }
    });
  };

  getFavoriteCampiagns = (userData) => {
    const params = {
      user_client_id: userData.id,
      client_id: CLIENT_ID,
    };
    this.setState((prevState) => ({
      loader: { ...prevState.loader, favoriteCampaigns: true },
    }));
    if (params.user_client_id) {
      Utility.sendRequest(
        FAVORITE_CAMPAIGNS,
        1,
        params,
        (error, response, body) => {
          body = JSON.parse(body);
          if (!body.error) {
            const Data = body[0].data;
            this.setState((prevState) => ({
              loader: { ...prevState.loader, favoriteCampaigns: false },
            }));

            Object.keys(Data).forEach((item) => {
              Data[item] = Data[item]
                ? Data[item].filter(
                    (items) =>
                      items.corporate_is_active === true ||
                      items.corporate_is_active === null
                  )
                : Data[item];
            });
            this.setState({ favoriteCampaigns: Data });
          }
        }
      );
    }
  };

  handleKey = (key) => {
    this.setState({ currentSelectKey: key });
  };

  joinCommToast = () => {
    toast.error("Join the community", {
      containerId: "private",
    });
    if (document.getElementById("profileContainer")) {
      document.getElementById("profileContainer").scrollIntoView({
        behavior: "smooth",
      });
    }
  };

  openNeighborhoodForm = (userData) => {
    const data = {
      showForm: true,
      edit: userData ? true : false,
      id: userData && userData.id,
      roleId: userData && userData.role_id,
    };

    this.props.openNeighborhoodForm(data);
  };

  getfeaturedCommunity = (id) => {
    const params = {
      platform_id: CLIENT_ID,
      user_id: id,
      is_client_active: true,
    };

    this.setState((prevState) => ({
      loader: { ...prevState.loader, featuredCommunity: true },
    }));

    Utility.sendRequest(FEATURED_COMMUNITY, 1, params, (err, res, body) => {
      body = JSON.parse(body);
      this.setState((prevState) => ({
        loader: { ...prevState.loader, featuredCommunity: false },
      }));

      if (body.error) {
        toast.error(body.error.message, { containerId: "private" });
      } else {
        const temp = body.data.filter((item) => item.role_id !== ROLE_IDS.USER);
        this.setState({ featuredCommunity: temp });
      }
    });
  };
  getUserActivities = (userData) => {
    const params = {
      user_client_id: userData.id,
      client_id: userData.client_id,
    };
    this.setState((prevState) => ({
      loader: { ...prevState.loader, userActivity: true },
    }));
    if (params.user_client_id && params.client_id) {
      Utility.sendRequest(USER_ACTIVITY, 1, params, (err, res, body) => {
        body = JSON.parse(body);
        this.setState((prevState) => ({
          loader: { ...prevState.loader, userActivity: false },
        }));

        if (body.error) {
          toast.error(body.error.message, { containerId: "private" });
        } else {
          this.setState({ userActivities: body.data });
        }
      });
    }
  };

  getUsersOfNeighborhood = (client_id) => {
    const params = {
      platform_id: CLIENT_ID,
      client_id: client_id,
    };
    // this.setState({ showLoader: true });
    this.setState((prevState) => ({
      loader: { ...prevState.loader, communityMembers: true },
    }));
    Utility.sendRequest(
      GET_USERS_OF_NEIGHBORHOOD,
      2,
      params,
      (err, res, body) => {
        body = JSON.parse(body);
        this.setState((prevState) => ({
          loader: { ...prevState.loader, communityMembers: false },
        }));
        // this.setState({ showLoader: false });
        if (body.error) {
          toast.error(body.error.message, { containerId: "private" });
        } else {
          this.setState({ communityMembers: body.data });
        }
      }
    );
  };

  editCloseForm = () => {
    this.setState({ isEdit: false });
    this.props.openNeighborhoodForm({});
  };

  userProfile = (id) => {
    this.props.updateSeeAll({
      isOpen: null,
    });

    if (id === this.props.userData.id)
      return this.props.history.push("/profile");
    else {
      this.props.history.push({
        pathname: `/profile/${id}`,
        state: { otherUserProfile: true },
      });
    }
  };

  closePassportCallOut = () => {
    this.setState({ showPassportCallout: false });
  };

  haveAnyTags = () => {
    var flag = false;
    this.state.passportData.map((e) => {
      if (!Utility.isEmpty(e.Tags)) flag = true;
    });
    return flag;
  };

  handleChatClick = (e, data) => {
    this.props.updateSeeAll({
      ...this.props.seeAll,
      isOpen: null,
    });
    e.stopPropagation();
    this.props.updateIsChatFormOpen(true);
    this.props.updateChatFriend(data);
  };

  handleJoinAndLeaveNeighbourhood = () => {
    getNeighborhoodsOfUser(this.state.userData);
  };

  handleSetDiscussionName = (e) => {
    this.setState({
      showGroupNameCreation: !this.state.showGroupNameCreation,
    });
  };

  handleCreateDisussion = (event) => {
    event.preventDefault();
    if (this.state.dicussionTopicName && this.state.dicussionTopicName.trim()) {
      // this.props.showLoader(true);
      const params = {
        author_name: this.props.userData.user_name,
        author_email: this.props.userData.email,
        author_id: this.props.userData.user_id,
        created_by: this.props.userData.user_id,
        heading: this.state.dicussionTopicName,
        sub_heading: " ",
        corporate_id: this.props.userData.client_id,
        description: " ",
        description_1: " ",
        user_client_id: this.props.userData.id,
        client_id: CLIENT_ID,
        campaign_type_id: CAMPAIGN_TYPES_IDS.discussion,
        plain_description: " ",
        updated_by: this.props.userData.user_id,
        schedule_start: "",
        publisher_network_id: CLIENT_ID,
        location: "",
        video_url: "",
        embedded_video_link: "",
        image_url: "",
        document_url: "",
        embedded_image_link: "",
        imageSignedURL: "",
        videoSignedURL: "",
        documentSignedURL: "",
        parent_image_url: "",
        parent_heading: "",
        category_id: "dc3a01b7-eacd-465a-9dc4-61121llbzzeb",
        ranking: null,
        parent_id: null,
        non_profits_id: null,
        meeting_url: "",
        organizers: [],
        is_public: true,
        groups: [],
      };
      Utility.sendRequest(CAMPAIGNS, 2, params, async (err, res, body) => {
        body = JSON.parse(body);
        if (body.error) {
          toast.error(body.error.message, { containerId: "private" });
        } else {
          this.setState({
            dicussionTopicName: "",
            showGroupNameCreation: false,
          });
          if (this.props.userData.role_id === ROLE_IDS.USER) {
            this.getUserDiscussion(
              CAMPAIGN_TYPES_IDS.discussion,
              this.props.userData.user_id
            );
          } else {
            this.loadFeaturedStories(
              CAMPAIGN_TYPES_IDS.discussion,
              this.props.userData.user_id,
              this.props.userData.id
            );
          }
          toast.success(body.message, { containerId: "private" });
        }
        // this.props.showLoader(false);
      });
    } else {
      toast.error("Please enter the DiscussionTopic", {
        containerId: "private",
      });
    }
  };
  handleEditDocName = (docName) => {
    this.setState({
      documentName: docName,
    });
    this.setState({
      isEditDocNameEnable: true,
    });
    this.setState({
      newDocName: docName,
    });
  };
  handleSaveEditedDocName = () => {
    this.state.uploadedFiles.find(
      (item) => item.name === this.state.documentName
    ).name = this.state.newDocName;
    this.setState({
      isEditDocNameEnable: false,
    });
  };

  // handleImageUpload = async (evt) => {
  //   if (evt.target.files != null) {
  //     // for (let i = 0; i < evt.target.files.length; i += 1) {
  //       // eslint-disable-next-line no-await-in-loop
  //       this.props.showLoader(true);
  //       Utility.sendRequest(SIGNED_URL, 1, {}, async (err, res, body) => {
  //         this.props.showLoader(false);
  //         body = JSON.parse(body);
  //         if (body.error) {
  //           toast.error(body.error.message, { containerId: "private" });
  //         } else {
  //           this.setState({ isDisable: false });
  //           console.log(body, "body");
  //           // this.state.uploadedFiles.push({
  //           //   ...evt.target.files[i],
  //           //   imageUrl: body.imageSignedUrl,
  //           // });
  //           this.setState({
  //             uploadedFiles: [
  //               ...this.state.uploadedFiles,
  //               { ...evt.target.files[i], imageUrl: body.imageSignedUrl },
  //             ],
  //           });
  //           // const Uploadrespose = await uploadFile(media, body.imageSignedUrl);
  //           // if (Uploadrespose) {
  //           //   handleSaveComment(Uploadrespose);
  //           //   setUploading(false);
  //           // }
  //         }
  //       });
  //     // }
  //   }
  // };

  handleImageUpload = async (e) => {
    this.setState({
      isLoading: true,
    });
    for (let i = 0; i < e.target.files.length; i += 1) {
      if (!this.state.open) {
        this.setState({
          uploadedFiles: [],
        });
        break;
      }
      const name =
        e.target.files[i].name.split(".").pop() === "rtf"
          ? e.target.files[i].name.split(".")[0] + ".doc"
          : e.target.files[i].name;

      // eslint-disable-next-line no-await-in-loop
      const fileUrl = await uploadMedia(
        e.target.files[i],
        true,
        name,
        e.target.files[i].name.split(".").pop() === "rtf" && "doc"
      );
      this.setState((prevState) => ({
        uploadedFiles: [
          ...prevState.uploadedFiles,
          {
            name: name,
            url: fileUrl,
            date: e.target.files[i].date,
            type: e.target.files[i].name.split(".").pop(),
            size: "",
            platform_id: GIL_CLIENT_ID,
          },
        ],
      }));
    }
    this.setState({
      isLoading: false,
    });
  };

  render() {
    const params = this.props.match
      ? this.props.match.params
      : { id: this.props.id };
    // const params = { id: "ac8bd4e0-a36d-11eb-b2d1-07e90fe25379" };
    const { id, client_id } = this.state.userData;
    const userData = !Utility.isEmpty(this.state.userData)
      ? this.state.userData
      : "";
    const profileImgUrl = !Utility.isEmpty(userData)
      ? userData.profile_image_url
      : "";
    const country = !Utility.isEmpty(userData.country) ? userData.country : "";
    const city = !Utility.isEmpty(userData.creator_city)
      ? `${userData.creator_city}, `
      : "";
    const bannerImage =
      ROLE_IDS.PARENT_OWNER === userData.role_id ||
      ROLE_IDS.CLIENT_OWNER === userData.role_id
        ? userData.client_banner_image_url
        : userData.user_banner_image_url;

    console.log(this.state.loader, "User quality");

    return (
      <>
        <CustomModal
          open={this.state.isEditDocNameEnable}
          onClose={this.handleCloseEditDocModal}
          classToOverride="uploadDocumentEditModal"
        >
          <>
            <div className="text-center doc-name-edit">
              <input
                type="text"
                className="w-100 font-14 font-weight-bold doc-edit"
                value={this.state.newDocName}
                onChange={(event) =>
                  this.setState({
                    newDocName: event.target.value,
                  })
                }
              />
            </div>
            <div className="text-center mb-4">
              <Button
                className="delete-doc-modal mb-2 mt-4 w-25 mx-3"
                variant="secondary"
                onClick={() => {
                  this.setState({
                    isEditDocNameEnable: false,
                  });
                }}
              >
                Cancel
              </Button>
              <Button
                className="delete-doc-modal mb-2 mt-4 w-25 mx-3"
                variant="primary"
                disabled={this.state.newDocName.length === 0}
                onClick={() => {
                  this.handleSaveEditedDocName();
                }}
              >
                Rename
              </Button>
            </div>
          </>
        </CustomModal>
        <CustomModal
          open={this.state.openDeleteConfirmationModal}
          onClose={this.handleClose}
          classToOverride="uploadDocumentModal"
        >
          <>
            <div className="text-center">
              <AiOutlineInfoCircle className="text-warning mb-2" size={80} />
              <h1 className="font-30-bold text-primary mb-2">Are you sure?</h1>
              <p>Are you sure to delete this document?</p>
            </div>
            <div className="text-center mb-4">
              <Button
                className="delete-doc-modal mb-2 mt-4 w-25 mx-3"
                variant="secondary"
                onClick={() => {
                  this.setState({
                    openDeleteConfirmationModal: false,
                  });
                }}
              >
                Cancel
              </Button>
              <Button
                className="delete-doc-modal mb-2 mt-4 w-25 mx-3"
                variant="primary"
                onClick={() => {
                  this.handleDeleteDocuments();
                }}
              >
                Yes
              </Button>
            </div>
          </>
        </CustomModal>
        <CustomModal
          open={this.state.open}
          onClose={this.handleClose}
          classToOverride="uploadDocumentModal"
        >
          <>
            {this.state.isLoading ? (
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  height: "300px",
                }}
              >
                <CircularProgress
                  size={50}
                  style={{
                    color: "#272262",
                  }}
                />
              </div>
            ) : this.state.selectedFile.name !== "" &&
              this.state.selectedFile.url !== "" ? (
              <>
                {" "}
                {["jpeg", "png", "jpg"].includes(
                  this.state.selectedFile.type
                ) && (
                  <div className="w-50 h-100 mx-auto">
                    <img
                      src={this.state.selectedFile.url}
                      alt={this.state.selectedFile.name}
                      width={"100%"}
                    />
                  </div>
                )}
                {[
                  "doc",
                  "docx",
                  "ppt",
                  "pptx",
                  "xls",
                  "xlsx",
                  "pdf",
                  "txt",
                  "rtf",
                  "odt",
                ].includes(this.state.selectedFile.type) && (
                  <iframe
                    src={
                      ["pdf", "txt"].includes(this.state.selectedFile.type)
                        ? this.state.selectedFile.url
                        : `https://view.officeapps.live.com/op/embed.aspx?src=${this.state.selectedFile.url}`
                    }
                    width="100%"
                    height="500px"
                    frameBorder="0"
                  />
                )}
              </>
            ) : (
              <>
                <div className="text-center">
                  <BsCloudUpload className="text-primary mb-2" size={50} />
                  <h1 className="font-24-bold text-primary">Upload files</h1>
                </div>
                <div className="w-75 my-3 mx-auto">
                  <div className="w-auto align-items-center justify-content-center post-pic position-relative page-sidebar-upload create-article-upload">
                    <input
                      type="file"
                      id="myFileInput"
                      className="uploadDocumentInput"
                      name="upload"
                      accept=".pdf, .docx, .doc, .pptx, .ppt, .txt,.xls,.xlsx,.jpeg,.jpg,.png,.rtf,.odt"
                      onChange={(e) => {
                        this.handleImageUpload(e);
                      }}
                      multiple
                    />

                    <label
                      htmlFor="myFileInput"
                      className="post-uplodad-label mb-0 cursor-pointer"
                    >
                      <CircleFilledAddLargeIcon
                        mainClass="add-icon-upper"
                        fillClass="fill-primary"
                      />
                    </label>
                  </div>
                </div>
                <div className="d-flex flex-column mx-auto my-3">
                  {this.state.uploadedFiles.map((item) => (
                    <div
                      key={item.name}
                      className="m-2 Profile-InfoCard bg-white d-flex justify-content-between w-75 mx-auto align-items-center"
                    >
                      <div>
                        {item.name.length > 40 ? (
                          <Tooltip title={item.name} placement="top">
                            <p className="font-weight-bold m-0">
                              {item.name.length > 40 &&
                                item.name.substring(0, 35) + "..."}
                            </p>
                          </Tooltip>
                        ) : (
                          <p className="font-weight-bold m-0">{item.name}</p>
                        )}
                      </div>
                      <div>
                        {
                          //  (
                          <Tooltip title="Edit" placement="top">
                            <EditOutlinedIcon
                              // size={25}
                              className="cursor-pointer text-primary mr-1"
                              onClick={() => this.handleEditDocName(item.name)}
                            />
                          </Tooltip>
                        }
                        <Tooltip title="Remove" placement="top">
                          <DeleteIcon
                            className="cursor-pointer text-danger"
                            onClick={() => {
                              this.setState({
                                uploadedFiles: this.state.uploadedFiles.filter(
                                  (data) => data.name !== item.name
                                ),
                              });
                            }}
                          />
                        </Tooltip>
                      </div>
                    </div>
                  ))}
                  <Button
                    className="leave-community mb-2 mt-4 w-50 mx-auto"
                    disabled={
                      this.state.uploadedFiles &&
                      this.state.uploadedFiles.length === 0
                    }
                    onClick={() => {
                      this.setOpen(false);
                      this.uploadFiles();
                    }}
                  >
                    Upload
                  </Button>
                </div>
              </>
            )}
          </>
        </CustomModal>
        <div className="client-profile-changed">
          <div
            className={`client-profile-outer scroll-y ${
              this.state.pwaEnable ? "ml-0" : "ml-2"
            }`}
          >
            <div className="d-flex w-100 display-profile profileBottomTab">
              <div
                className={
                  this.state.isPublic === false ||
                  this.state.is_Followed === true ||
                  params.id === this.props.userData.id ||
                  this.state.userData.network === "clients"
                    ? `profile-width`
                    : `w-100 ml-3 py-3`
                }
              >
                {/* HERE 1 */}
                <UserProfileSection
                  handleFollow={this.handleFollow}
                  bannerImage={bannerImage}
                  setIsNeighborhoodJoined={this.setIsNeighborhoodJoined}
                  GodQualities={this.state.GodQualities}
                  profileImgUrl={profileImgUrl}
                  HandleStartChat={() => {
                    this.props.updateIsChatFormOpen(true);
                    this.props.updateChatFriend(this.props.publicUserData);
                  }}
                  userData={this.state.userData}
                  params={params}
                  isPublic={this.state.isPublic}
                  handleClickEdit={() => {
                    this.setState({ isEdit: true });
                    this.openNeighborhoodForm(userData);
                  }}
                  location={window.location.pathname}
                  setClickableState={this.setClickableState}
                />
                {this.state.userData.network === "clients" &&
                  (this.state.userData.video_url ||
                    this.state.userData.video_desc) && (
                    <Introduction
                      video_url={this.state.userData.video_url}
                      video_description={this.state.userData.video_desc}
                    />
                  )}

                {(!this.state.isPublic ||
                  this.state.is_Followed === true ||
                  params.id === this.props.userData.id) &&
                  this.state.userData &&
                  this.state.userData.bio_details && (
                    <UserAboutSection
                      description={this.state.userData.bio_details}
                      title={"About"}
                    />
                  )}
                {(!this.state.isPublic ||
                  this.state.is_Followed === true ||
                  params.id === this.props.userData.id ||
                  params.id === this.props.publicUserData.id) &&
                  this.state.selectedGodQualities &&
                  this.state.selectedGodQualities.length !== 0 && (
                    <>
                      {this.state.selectedGodQualities.find(
                        (item) => item.section === "LeaderQuality"
                      ) &&
                        this.state.selectedGodQualities.find(
                          (item) => item.section === "LeaderQuality"
                        ).title && (
                          <UserAboutSection
                            description={
                              this.state.selectedGodQualities.find(
                                (item) => item.section === "LeaderQuality"
                              ).title
                            }
                            title={
                              this.state.userData.role_id === ROLE_IDS.USER ||
                              this.state.userData.role_id ===
                                ROLE_IDS.PARENT_OWNER
                                ? "How do you describe God's calling in your life?"
                                : "What is the mission of the organization?"
                            }
                          />
                        )}
                    </>
                  )}

                {(!this.state.isPublic ||
                  this.state.is_Followed === true ||
                  params.id === this.props.userData.id ||
                  params.id === this.props.publicUserData.id) && (
                  <GodCalling
                    heading={
                      this.state.userData.role_id === ROLE_IDS.USER ||
                      this.state.userData.role_id === ROLE_IDS.PARENT_OWNER
                        ? "Where do you want to grow?"
                        : "What are the areas of growth for your organization?"
                    }
                    // subHeading="You have 2 common areas with Brad Smith."
                    isPublic={this.state.isPublic}
                    user={userData.user_name}
                    GodQualities={this.state.GodQualities}
                    section="Leader qualities"
                    isEditing={false}
                    isProfile={true}
                    selectedGodQualities={
                      this.state.selectedGodQualities &&
                      this.state.selectedGodQualities.length !== 0 &&
                      this.state.selectedGodQualities.filter(
                        (data) => data.section === "Leader qualities"
                      )
                    }
                    addSelectedQualities={() => {
                      void 0;
                    }}
                    removeSelectedQualities={() => {
                      void 0;
                    }}
                    loading={this.state.loader.userQuality}
                  />
                )}

                {(!this.state.isPublic ||
                  this.state.is_Followed === true ||
                  params.id === this.props.userData.id ||
                  params.id === this.props.publicUserData.id) && (
                  <GodCalling
                    heading={
                      this.state.userData.role_id === ROLE_IDS.USER ||
                      this.state.userData.role_id === ROLE_IDS.PARENT_OWNER
                        ? "What are your core strengths that might help others?"
                        : "What are the core strengths of your organization that might others?"
                    }
                    // subHeading="You have 2 common areas with Brad Smith."
                    user={userData.user_name}
                    GodQualities={this.state.GodQualities}
                    section="Organization and Community grow"
                    isEditing={false}
                    isProfile={true}
                    selectedGodQualities={
                      this.state.selectedGodQualities &&
                      this.state.selectedGodQualities.length !== 0 &&
                      this.state.selectedGodQualities.filter(
                        (data) =>
                          data.section === "Organization and Community grow"
                      )
                    }
                    addSelectedQualities={() => {
                      void 0;
                    }}
                    removeSelectedQualities={() => {
                      void 0;
                    }}
                    isPublic={this.state.isPublic}
                    loading={this.state.loader.userQuality}
                  />
                )}

                {(!this.state.isPublic ||
                  this.state.is_Followed === true ||
                  params.id === this.props.userData.id ||
                  this.state.isNeighborhoodJoined) && (
                  <ProfileNews
                    profileNews={this.state.myArticles}
                    isClickable={this.state.isClickable}
                    isClickCheck={true}
                    isNeighbourhood={this.state.userData.network === "clients"}
                    loading={this.state.loader.news}
                  />
                )}
                {(!this.state.isPublic ||
                  this.state.is_Followed === true ||
                  params.id === this.props.userData.id ||
                  this.state.isNeighborhoodJoined) && (
                  <ProfileEvents
                    profileEvents={this.state.profileEvents}
                    history={this.props.history}
                    isClickable={this.state.isClickable}
                    isClickCheck={true}
                    loading={this.state.loader.events}
                  />
                )}
                {(!this.state.isPublic ||
                  this.state.is_Followed === true ||
                  params.id === this.props.userData.id ||
                  this.state.isNeighborhoodJoined) && (
                  <ProfileCourse
                    profileCourses={this.state.myCourses}
                    isClickable={this.state.isClickable}
                    isClickCheck={true}
                    isNeighbourhood={this.state.userData.network === "clients"}
                    loading={this.state.loader.course}
                  />
                )}
                {this.state.userData.network === "users" &&
                  (!this.state.isPublic ||
                    this.state.is_Followed === true ||
                    params.id === this.props.userData.id) && (
                    <ProfileActivity
                      is_Followed={this.state.is_Followed}
                      profileActivities={this.state.userActivities}
                      userData={this.state.userData}
                      history={this.props.history}
                      loading={this.state.loader.userActivity}
                    />
                  )}
              </div>
              {(this.state.isPublic === false ||
                this.state.is_Followed === true ||
                params.id === this.props.userData.id ||
                this.state.userData.network === "clients") && (
                <div className="width-discussion widthTabMargin">
                  {this.state.isPublic &&
                    this.state.is_Followed === true &&
                    params.id !== this.props.userData.id &&
                    this.state.userData.role_id === ROLE_IDS.USER && (
                      <RightSharedSection
                        is_Followed={this.state.is_Followed}
                        User={this.state.userData}
                        classToOverride="py-1 mb-2"
                      >
                        <div className="w-100 p-2">
                          <Button
                            onClick={() => {
                              this.props.updateIsChatFormOpen(true);
                              this.props.updateChatFriend({
                                ...this.props.publicUserData,
                                statusConversation: "]versation",
                              });
                            }}
                            className="w-100 CustomProfileButtonStyle"
                          >
                            START CONVERSATION
                          </Button>
                        </div>
                      </RightSharedSection>
                    )}
                  {!this.state.isPublic && !Utility.pwaModeEnabled() && (
                    <RightSharedSection
                      isOverLay={false}
                      is_Followed={this.state.is_Followed}
                      User={this.state.userData}
                      classToOverride="py-1 mb-2"
                    >
                      <div
                        onClick={() =>
                          this.props.history.push(
                            `/profile/${this.props.userData.id}`
                          )
                        }
                        className="w-100 text-center font-16 font-weight-bold font-color-primary cursor-pointer"
                      >
                        Go to Public View
                      </div>
                    </RightSharedSection>
                  )}
                  {this.state.isPublic &&
                    !Utility.pwaModeEnabled() &&
                    this.props.userData.id === params.id && (
                      <RightSharedSection
                        isOverLay={false}
                        is_Followed={this.state.is_Followed}
                        User={this.state.userData}
                        classToOverride="py-1 mb-2"
                      >
                        <div
                          onClick={() => this.props.history.push(`/profile`)}
                          className="w-100 text-center font-16 font-weight-bold font-color-primary cursor-pointer"
                        >
                          Go to Private View
                        </div>
                      </RightSharedSection>
                    )}
                  {/* DISCUSSION SECTION */}

                  {(this.state.is_Followed === true ||
                    params.id === this.props.userData.id ||
                    this.state.userData.network === "clients" ||
                    this.state.isPublic === false) && (
                    <RightSharedSection
                      onClick={this.handleSetDiscussionName}
                      id="dicussion"
                      is_Followed={this.state.is_Followed}
                      User={this.state.userData}
                      isPublic={this.state.isPublic}
                      heading={"Discussion"}
                      classToOverride={Utility.pwaModeEnabled() && "mt-3"}
                    >
                      {this.state.showGroupNameCreation && (
                        <>
                          <div className="SeactionSearchContainer">
                            <div className="searchSectionWrapper mx-3">
                              <input
                                onChange={(event) =>
                                  this.setState({
                                    dicussionTopicName: event.target.value,
                                  })
                                }
                                className="SeactionSearch"
                                placeholder="Enter Dicussion Topic "
                              />
                            </div>
                          </div>
                          <div className="SeactionSearchContainer">
                            <div className="mx-3 w-100">
                              <Button
                                onClick={(event) =>
                                  this.handleCreateDisussion(event)
                                }
                                className="w-100 create-dicussion-btnd"
                              >
                                Create Discussion
                              </Button>
                            </div>
                          </div>
                        </>
                      )}
                      <div className="SeactionSearchContainer">
                        <div className="searchSectionWrapper mx-3">
                          <input
                            onChange={(event) => {
                              this.setState({
                                DiscussionSearch: event.target.value,
                              });
                            }}
                            className="SeactionSearch"
                            placeholder="search"
                          />
                          <span className="SeactionSearchIcon">
                            <GrSearch className="regionIcon" />
                          </span>
                        </div>
                      </div>
                      <div className="myDiscussionSeeMore scroll-y pb-3">
                        {this.state.profileDisussion &&
                        this.state.profileDisussion.length <= 0 &&
                        !this.state.loader.discussion ? (
                          <>
                            <p className="font-bold display-6 text-center mt-4">
                              No Discussion Initiated
                            </p>
                          </>
                        ) : this.state.loader.discussion ? (
                          [1, 2, 3, 4].map((item) => (
                            <Skeleton
                              variant="text"
                              className="mx-3 rounded-20"
                              height={50}
                              key={item}
                            />
                          ))
                        ) : (
                          this.state.profileDisussion
                            .filter((data) =>
                              data.heading
                                .toLowerCase()
                                .includes(
                                  this.state.DiscussionSearch.toLowerCase()
                                )
                            )
                            .slice(
                              0,
                              this.state.isDiscussionSeeMore ||
                                this.state.DiscussionSearch
                                ? this.state.profileDisussion.length
                                : this.state.userData.role_id === ROLE_IDS.USER
                                ? 2
                                : 3
                            )
                            .map((data, index) => {
                              return (
                                <div
                                  key={data.id}
                                  onClick={() => {
                                    if (
                                      this.props.userNeighborhoods.length !== 0
                                    ) {
                                      if (
                                        this.props.userNeighborhoods.some(
                                          (item) =>
                                            item.client_id === data.corporate_id
                                        )
                                      ) {
                                        this.props.updateIsChatFormOpen(true);
                                        this.props.updateChatFriend({
                                          ...data,
                                          is_discussion: true,
                                          network: "clients",
                                        });
                                      } else {
                                        if (data) {
                                          toast.error(
                                            `Please join ${
                                              data.corporate_name ||
                                              data.author_name
                                            }`,
                                            { containerId: "private" }
                                          );
                                        }
                                      }
                                    }
                                  }}
                                  className="sub-chapter-item event-sub-chapter-item cursor-pointer"
                                >
                                  <div className="section-data">
                                    <div className="chapterSection d-flex  align-items-center">
                                      <div className="dicussion-check mr-3">
                                        <div className="unCheckBoxreplica" />
                                      </div>
                                      <div className="dicussion-nameContainer">
                                        {data && data.heading}
                                        {this.state.userData.role_id ===
                                          ROLE_IDS.USER && (
                                          <p className="discussion-corporateName m-0">
                                            {data && data.corporate_name}
                                          </p>
                                        )}
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              );
                            })
                        )}
                      </div>
                      <div
                        onClick={() =>
                          this.setState({
                            isDiscussionSeeMore:
                              !this.state.isDiscussionSeeMore,
                          })
                        }
                        className="chapterSection font-18 d-flex justify-content-center mt-2 cursor-pointer align-items-center"
                      >
                        {this.state.isDiscussionSeeMore
                          ? "See Less"
                          : "See More"}
                      </div>
                    </RightSharedSection>
                  )}
                  {(this.state.userData.role_id === ROLE_IDS.CLIENT_OWNER ||
                    this.state.userData.role_id === ROLE_IDS.CLIENT_ADMIN ||
                    this.state.userData.role_id === ROLE_IDS.USER) &&
                    (this.state.isPublic === false ||
                      this.state.is_Followed === true ||
                      params.id === this.props.userData.id ||
                      this.state.userData.is_public === false) &&
                    this.state.communityMembers &&
                    this.state.communityMembers.length > 0 && (
                      <RightSharedSection
                        is_Followed={this.state.is_Followed}
                        User={this.state.userData}
                        heading={
                          this.state.userData.role_id === ROLE_IDS.USER
                            ? `${
                                this.state.userData.user_name.split(/\s+/)[0]
                              }'s Network`
                            : "Community's Members"
                        }
                        classToOverride="mt-4"
                      >
                        {this.state.userData.role_id === ROLE_IDS.USER ? (
                          <Tabs
                            className="ff-tab-container"
                            id="uncontrolled-tab-example"
                          >
                            <Tab
                              eventKey="following"
                              title={`Following (${this.state.following.length})`}
                            >
                              <div className="scroll-y py-2 my-2 follow-following-list">
                                {this.state.loader.followerFollowing ? (
                                  [1, 2, 3].map((item) => (
                                    <Skeleton
                                      variant="text"
                                      height={100}
                                      className="mx-3 rounded-20"
                                      key={item}
                                    />
                                  ))
                                ) : this.state.following &&
                                  this.state.following.length > 0 ? (
                                  this.state.following.map((item) => {
                                    return (
                                      <div className="mt-3 mx-3">
                                        <InforCard
                                          ImageSection={item.profile_image_url}
                                          Heading={item.user_name}
                                          onSectionClick={() => {
                                            window.scrollTo({
                                              top: 0,
                                              left: 0,
                                              behavior: "smooth",
                                            });
                                            this.userProfile(item.id);
                                          }}
                                          onClick={(event) => {
                                            this.handleChatClick(event, {
                                              ...item,
                                              id: item.id,
                                              user_name: item.user_name,
                                              private_key: item.private_key,
                                            });
                                          }}
                                        />
                                      </div>
                                    );
                                  })
                                ) : (
                                  <div className="px-5 text-center">
                                    <span className="font-bold display-6">
                                      No Data Found
                                    </span>
                                  </div>
                                )}
                              </div>
                            </Tab>
                            <Tab
                              className="ff-tab"
                              eventKey="followers"
                              title={`Followers (${this.state.follower.length})`}
                            >
                              <div className="scroll-y py-2 my-2 follow-following-list">
                                {this.state.loader.followerFollowing ? (
                                  [1, 2, 3].map((item) => (
                                    <Skeleton
                                      variant="text"
                                      height={100}
                                      className="mx-3 rounded-20"
                                      key={item}
                                    />
                                  ))
                                ) : this.state.follower &&
                                  this.state.follower.length > 0 ? (
                                  this.state.follower.map((item) => {
                                    return (
                                      <div className="mt-3 mx-3">
                                        <InforCard
                                          ImageSection={item.profile_image_url}
                                          Heading={item.user_name}
                                          showLikeSvg={this.state.isPublic}
                                          leftSectionId={"close"}
                                          isUnfollow={true}
                                          onSectionClick={() => {
                                            window.scrollTo({
                                              top: 0,
                                              left: 0,
                                              behavior: "smooth",
                                            });
                                            this.userProfile(item.id);
                                          }}
                                          onClick={(event) => {
                                            event.stopPropagation();
                                            this.handleUnfollow(
                                              this.props.userData.id,
                                              item.id,
                                              CLIENT_ID
                                            );
                                          }}
                                        />
                                      </div>
                                    );
                                  })
                                ) : (
                                  <div className="px-5 text-center">
                                    <span className="font-bold display-6">
                                      No Data Found
                                    </span>
                                  </div>
                                )}
                              </div>
                            </Tab>
                          </Tabs>
                        ) : (
                          <>
                            {this.state.loader.communityMembers
                              ? [1, 2, 3].map((item) => (
                                  <Skeleton
                                    variant="text"
                                    height={100}
                                    className="mx-3 rounded-20"
                                    key={item}
                                  />
                                ))
                              : this.state.communityMembers &&
                                this.state.communityMembers.length > 0 &&
                                (this.state.seeMoreNetworks
                                  ? this.state.communityMembers.map(
                                      (data, index) => {
                                        return (
                                          <div className="mt-3 mx-3">
                                            <InforCard
                                              ImageSection={
                                                data.profile_image_url
                                              }
                                              Heading={data.full_name}
                                              onSectionClick={() => {
                                                window.scrollTo({
                                                  top: 0,
                                                  left: 0,
                                                  behavior: "smooth",
                                                });
                                                this.userProfile(
                                                  data.user_client_id
                                                );
                                              }}
                                              onClick={(event) => {
                                                event.stopPropagation();
                                                this.handleChatClick(
                                                  event,
                                                  data
                                                );
                                              }}
                                              isLike={data.true}
                                              leftSectionId=""
                                            />
                                          </div>
                                        );
                                      }
                                    )
                                  : this.state.communityMembers
                                      .slice(0, 3)
                                      .map((data, index) => {
                                        return (
                                          <div className="mt-3 mx-3">
                                            <InforCard
                                              ImageSection={
                                                data.profile_image_url
                                              }
                                              Heading={data.full_name}
                                              onSectionClick={() => {
                                                window.scrollTo({
                                                  top: 0,
                                                  left: 0,
                                                  behavior: "smooth",
                                                });
                                                this.userProfile(
                                                  data.user_client_id
                                                );
                                              }}
                                              onClick={(event) => {
                                                event.stopPropagation();
                                                this.handleChatClick(
                                                  event,
                                                  data
                                                );
                                              }}
                                              isLike={data.true}
                                              leftSectionId=""
                                            />
                                          </div>
                                        );
                                      }))}

                            <div
                              className={`chapterSection font-18 d-flex justify-content-center mt-3 cursor-pointer align-items-center ${
                                (this.state.userData.role_id === ROLE_IDS.USER
                                  ? this.state.following &&
                                    this.state.following.length <= 3
                                  : this.state.communityMembers &&
                                    this.state.communityMembers.length <= 3) &&
                                "hidden"
                              }`}
                              onClick={() => {
                                if (
                                  (this.state.communityMembers &&
                                    this.state.communityMembers.length > 3) ||
                                  (this.state.following &&
                                    this.state.following.length > 3)
                                ) {
                                  this.setState({
                                    seeMoreNetworks:
                                      !this.state.seeMoreNetworks,
                                  });
                                }
                              }}
                            >
                              {this.state.seeMoreNetworks
                                ? "See Less"
                                : "See More"}
                            </div>
                          </>
                        )}

                        {/* <div
                        onClick={() => {
                          this.setState({
                            is_following_expand:
                              !this.state.is_following_expand,
                          });
                        }}
                        className={`cursor-pointer ml-3 follow-drop ${
                          this.state.is_following_expand ? "bg-active" : ""
                        }`}
                      >
                        Following
                        <div className="ml-auto">
                          {this.state.is_following_expand ? (
                            <ExpandLessRoundedIcon />
                          ) : (
                            <ExpandMoreRoundedIcon />
                          )}
                        </div>
                      </div>
                      {this.state.is_following_expand && (
                        <div className="scroll-y py-2 my-2 follow-following-list">
                          {this.state.following &&
                          this.state.following.length > 0 ? (
                            this.state.following.map((item) => {
                              return (
                                <div className="mt-3 mx-3">
                                  <InforCard
                                    ImageSection={item.profile_image_url}
                                    Heading={item.user_name}
                                    onSectionClick={() => {
                                      window.scrollTo({
                                        top: 0,
                                        left: 0,
                                        behavior: "smooth",
                                      });
                                      this.userProfile(item.id);
                                    }}
                                    onClick={(event) => {
                                      this.handleChatClick(event, {
                                        ...item,
                                        id: item.id,
                                        user_name: item.user_name,
                                        private_key: item.private_key,
                                      });
                                    }}
                                  />
                                </div>
                              );
                            })
                          ) : (
                            <div className="px-5">
                              <span className="font-bold display-6">
                                No Data Found
                              </span>
                            </div>
                          )}
                        </div>
                      )} */}

                        {/* <div
                        onClick={() => {
                          this.setState({
                            is_follower_expand: !this.state.is_follower_expand,
                          });
                        }}
                        className={`cursor-pointer ml-3 follow-drop ${
                          this.state.is_follower_expand ? "bg-active" : ""
                        }`}
                      >
                        Followers
                        <div className="ml-auto">
                          {this.state.is_following_expand ? (
                            <ExpandLessRoundedIcon />
                          ) : (
                            <ExpandMoreRoundedIcon />
                          )}
                        </div>
                      </div>
                      {this.state.is_follower_expand && (
                        <div className="scroll-y py-2 my-2 follow-following-list">
                          {this.state.follower &&
                          this.state.follower.length > 0 ? (
                            this.state.follower.map((item) => {
                              return (
                                <div className="mt-3 mx-3">
                                  <InforCard
                                    ImageSection={item.profile_image_url}
                                    Heading={item.user_name}
                                    onSectionClick={() => {
                                      window.scrollTo({
                                        top: 0,
                                        left: 0,
                                        behavior: "smooth",
                                      });
                                      this.userProfile(item.id);
                                    }}
                                    onClick={(event) => {
                                      this.handleChatClick(event, {
                                        ...item,
                                        id: item.id,
                                        user_name: item.user_name,
                                        private_key: item.private_key,
                                      });
                                    }}
                                  />
                                </div>
                              );
                            })
                          ) : (
                            <div className="px-5">
                              <span className="font-bold display-6">
                                No Data Found
                              </span>
                            </div>
                          )}
                        </div>
                      )} */}
                      </RightSharedSection>
                    )}

                  {this.state.userData.role_id !== ROLE_IDS.USER &&
                    this.state.communityMembers &&
                    this.state.communityMembers.length > 0 && (
                      <RightSharedSection
                        User={this.state.userData}
                        heading={"Community's Admin"}
                        is_Followed={this.state.is_Followed}
                        classToOverride="mt-4"
                      >
                        <div
                          className={
                            this.state.seeMoreAdmins
                              ? "myNetworkSeeMore scroll-y pb-3"
                              : " pb-3"
                          }
                        >
                          {this.state.loader.communityMembers
                            ? [1, 2, 3].map((item) => (
                                <Skeleton
                                  variant="text"
                                  height={100}
                                  className="mx-3 rounded-20"
                                  key={item}
                                />
                              ))
                            : this.state.communityMembers &&
                              this.state.communityMembers.length > 0 &&
                              (this.state.seeMoreAdmins
                                ? this.state.communityMembers
                                    .filter((item) => item.is_admin)
                                    .map((data, index) => {
                                      return (
                                        <div className="mt-3 mx-3">
                                          <InforCard
                                            ImageSection={
                                              data.profile_image_url
                                            }
                                            Heading={data.full_name}
                                            leftSectionId="chat"
                                            onSectionClick={() => {
                                              window.scrollTo({
                                                top: 0,
                                                left: 0,
                                                behavior: "smooth",
                                              });
                                              this.userProfile(
                                                data.user_client_id
                                              );
                                            }}
                                            onClick={(event) => {
                                              this.handleChatClick(event, {
                                                ...data,
                                                id: data.user_client_id,
                                                user_name: data.full_name,
                                                private_key: data.private_key,
                                              });
                                            }}
                                            isLike={data.true}
                                          />
                                        </div>
                                      );
                                    })
                                : this.state.communityMembers
                                    .filter((item) => item.is_admin)
                                    .slice(0, 3)
                                    .map((data, index) => {
                                      return (
                                        <div className="mt-3 mx-3">
                                          <InforCard
                                            ImageSection={
                                              data.profile_image_url
                                            }
                                            Heading={data.full_name}
                                            onSectionClick={() => {
                                              window.scrollTo({
                                                top: 0,
                                                left: 0,
                                                behavior: "smooth",
                                              });
                                              this.userProfile(
                                                data.user_client_id
                                              );
                                            }}
                                            onClick={(event) => {
                                              event.stopPropagation();
                                              this.handleChatClick(event, {
                                                ...data,
                                                id: data.user_client_id,
                                                user_name: data.full_name,
                                                private_key: data.private_key,
                                              });
                                            }}
                                            isLike={data.true}
                                            leftSectionId="chat"
                                          />
                                        </div>
                                      );
                                    }))}
                          {this.state.communityMembers &&
                            this.state.communityMembers.filter(
                              (item) => item.is_admin
                            ).length === 0 && (
                              <p className="font-bold display-6 text-center mt-4">
                                No record found
                              </p>
                            )}
                        </div>

                        <div
                          className={`chapterSection font-18 d-flex justify-content-center mt-3 cursor-pointer align-items-center ${
                            this.state.userData.role_id !== ROLE_IDS.USER &&
                            this.state.communityMembers &&
                            this.state.communityMembers.filter(
                              (item) => item.is_admin
                            ).length <= 3 &&
                            "hidden"
                          }`}
                          onClick={() => {
                            if (
                              this.state.communityMembers &&
                              this.state.communityMembers.filter(
                                (item) => item.is_admin
                              ).length > 3
                            ) {
                              this.setState({
                                seeMoreAdmins: !this.state.seeMoreAdmins,
                              });
                            }
                          }}
                        >
                          {this.state.seeMoreAdmins ? "See Less" : "See More"}
                        </div>
                      </RightSharedSection>
                    )}

                  {this.state.userData.role_id !== ROLE_IDS.CLIENT_OWNER &&
                    (this.state.isPublic === false ||
                      params.id === this.props.userData.id ||
                      this.state.is_Followed === true) &&
                    this.state.userData.role_id !== ROLE_IDS.CLIENT_ADMIN &&
                    this.state.featuredCommunity &&
                    this.state.featuredCommunity.length > 0 && (
                      <RightSharedSection
                        User={this.state.userData}
                        is_Followed={this.state.is_Followed}
                        heading={`${
                          this.state.userData &&
                          this.state.userData.user_name &&
                          this.state.userData.user_name.split(/\s+/)[0]
                        }'s Community`}
                        classToOverride="mt-4"
                      >
                        <div
                          className={
                            this.state.seeMoreCommunity
                              ? "myNetworkSeeMore scroll-y pb-3"
                              : " pb-3"
                          }
                        >
                          {this.state.loader.featuredCommunity
                            ? [1, 2, 3].map((item) => (
                                <Skeleton
                                  variant="text"
                                  height={100}
                                  className="mx-3 rounded-20"
                                  key={item}
                                />
                              ))
                            : this.state.featuredCommunity &&
                              this.state.featuredCommunity.length > 0 &&
                              (this.state.seeMoreCommunity
                                ? this.state.featuredCommunity.map(
                                    (data, index) => {
                                      return (
                                        <div className="mt-3 mx-3">
                                          <InforCard
                                            ImageSection={
                                              data.logo_url
                                                ? data.logo_url
                                                : data.banner_image_url
                                            }
                                            Heading={data.community_name}
                                            subHeading={`${data.region_name}`}
                                            onSectionClick={() => {
                                              window.scrollTo({
                                                top: 0,
                                                left: 0,
                                                behavior: "smooth",
                                              });
                                              this.userProfile(
                                                data.user_client_id
                                              );
                                            }}
                                            onClick={(event) => {
                                              event.stopPropagation();
                                              this.handleChatClick(event, data);
                                            }}
                                            isLike={data.true}
                                            leftSectionId=""
                                          />
                                        </div>
                                      );
                                    }
                                  )
                                : this.state.featuredCommunity
                                    .slice(0, 3)
                                    .map((data, index) => {
                                      return (
                                        <div className="mt-3 mx-3">
                                          <InforCard
                                            ImageSection={
                                              data.logo_url
                                                ? data.logo_url
                                                : data.banner_image_url
                                            }
                                            Heading={data.community_name}
                                            subHeading={`${data.region_name}`}
                                            onSectionClick={() => {
                                              window.scrollTo({
                                                top: 0,
                                                left: 0,
                                                behavior: "smooth",
                                              });
                                              this.userProfile(
                                                data.user_client_id
                                              );
                                            }}
                                            onClick={(event) => {
                                              event.stopPropagation();
                                              this.handleChatClick(event, data);
                                            }}
                                            isLike={data.true}
                                            leftSectionId=""
                                          />
                                        </div>
                                      );
                                    }))}
                        </div>
                        <div
                          className={`chapterSection font-18 d-flex justify-content-center mt-3 cursor-pointer align-items-center ${
                            this.state.featuredCommunity &&
                            this.state.featuredCommunity.length <= 3 &&
                            "hidden"
                          }`}
                          onClick={() => {
                            if (
                              this.state.featuredCommunity &&
                              this.state.featuredCommunity.length > 3
                            ) {
                              this.setState({
                                seeMoreCommunity: !this.state.seeMoreCommunity,
                              });
                            }
                          }}
                        >
                          {this.state.seeMoreCommunity
                            ? "See Less"
                            : "See More"}
                        </div>
                      </RightSharedSection>
                    )}
                  {this.state.favoriteCampaigns.Course &&
                    this.state.favoriteCampaigns.Course.length > 0 &&
                    (this.state.userData.network === "clients" ||
                      this.state.isPublic === false ||
                      params.id === this.props.userData.id ||
                      this.state.is_Followed === true) && (
                      <RightSharedSection
                        User={this.state.userData}
                        is_Followed={this.state.is_Followed}
                        heading={"Favorite Courses"}
                        classToOverride="mt-4"
                      >
                        <div
                          className={
                            this.state.seeMoreCourses
                              ? "myNetworkSeeMore scroll-y pb-3"
                              : "pb-3"
                          }
                        >
                          {this.state.loader.favoriteCampaigns
                            ? [1, 2, 3].map((item) => (
                                <Skeleton
                                  variant="text"
                                  height={100}
                                  className="mx-3 rounded-20"
                                  key={item}
                                />
                              ))
                            : this.state.seeMoreCourses
                            ? this.state.favoriteCampaigns.Course.map(
                                (data, index) => {
                                  return (
                                    <div className="mt-3 mx-3">
                                      <InforCard
                                        ImageSection={data.image_url}
                                        Heading={data.heading}
                                        subHeading={data.sub_heading}
                                        isLike={true}
                                        leftSectionId="like"
                                        onSectionClick={() => {
                                          window.scrollTo({
                                            top: 0,
                                            left: 0,
                                            behavior: "smooth",
                                          });
                                          this.props.history.push(
                                            `/campaign/${data.campaign_id}`
                                          );
                                        }}
                                      />
                                    </div>
                                  );
                                }
                              )
                            : this.state.favoriteCampaigns.Course.slice(
                                0,
                                3
                              ).map((data, index) => {
                                return (
                                  <div className="mt-3 mx-3">
                                    <InforCard
                                      ImageSection={data.image_url}
                                      Heading={data.heading}
                                      subHeading={data.sub_heading}
                                      isLike={true}
                                      leftSectionId="like"
                                      onSectionClick={() => {
                                        window.scrollTo({
                                          top: 0,
                                          left: 0,
                                          behavior: "smooth",
                                        });
                                        this.props.history.push(
                                          `/campaign/${data.campaign_id}`
                                        );
                                      }}
                                    />
                                  </div>
                                );
                              })}
                        </div>
                        <div
                          className={`chapterSection font-18 d-flex justify-content-center mt-3 cursor-pointer align-items-center ${
                            this.state.favoriteCampaigns.Course &&
                            this.state.favoriteCampaigns.Course.length <= 3 &&
                            "hidden"
                          }`}
                          onClick={() => {
                            if (
                              this.state.favoriteCampaigns.Course &&
                              this.state.favoriteCampaigns.Course.length > 3
                            ) {
                              this.setState({
                                seeMoreCourses: !this.state.seeMoreCourses,
                              });
                            }
                          }}
                        >
                          {this.state.seeMoreCourses ? "See Less" : "See More"}
                        </div>
                      </RightSharedSection>
                    )}
                  {/* document section */}
                  {(!this.state.isPublic ||
                    this.state.is_Followed === true ||
                    params.id === this.props.userData.id ||
                    this.state.isNeighborhoodJoined) &&
                    this.state.userData.role_id !== ROLE_IDS.USER &&
                    this.state.userData.role_id !== ROLE_IDS.PARENT_ADMIN &&
                    this.state.userData.role_id !== ROLE_IDS.PARENT_OWNER &&
                    this.state.userData.role_id !== ROLE_IDS.CLIENT_ADMIN && (
                      <RightSharedSection
                        showAddIcon={
                          this.state.userData.id !== this.props.userData.id
                        }
                        User={this.state.userData}
                        heading={"Documents"}
                        is_Followed={this.state.is_Followed}
                        classToOverride="mt-4"
                        id="documents"
                        onClick={() => {
                          this.setOpen(true);
                        }}
                      >
                        <div
                          className={
                            this.state.isDocumentsSeeMore
                              ? "myDocumentsSeeMore scroll-y pb-3"
                              : "pb-3"
                          }
                        >
                          {this.state.userData.documents &&
                          this.state.userData.documents.length !== 0 ? (
                            this.state.userData.documents
                              .slice(
                                0,
                                this.state.isDocumentsSeeMore
                                  ? this.state.userData.documents.length
                                  : 4
                              )
                              .map((e) => {
                                return (
                                  <div
                                    key={e.id}
                                    className="mx-3 mt-3"
                                    onClick={() => {
                                      this.setOpen(true);
                                      this.setState({
                                        selectedFile: {
                                          name: e.name,
                                          type: e.type,
                                          url: e.url,
                                        },
                                      });
                                    }}
                                  >
                                    <InforCard
                                      ImageSection={require("assets/images/document.png")}
                                      Heading={e.name}
                                      leftSectionId="download"
                                      rightSectionId={
                                        this.state.userData.id ===
                                          this.props.userData.id && "delete"
                                      }
                                      onLeftSectionClick={(event) => {
                                        event.stopPropagation();
                                        this.handleDownload(e.url, e.name);
                                      }}
                                      onRightSectionClick={(event) => {
                                        event.stopPropagation();
                                        this.handleDeleteModal(e.id);
                                      }}
                                    />
                                  </div>
                                );
                              })
                          ) : (
                            <p className="font-bold display-6 text-center mt-4">
                              No documents found
                            </p>
                          )}
                        </div>
                        <div
                          onClick={() =>
                            this.setState({
                              isDocumentsSeeMore:
                                !this.state.isDocumentsSeeMore,
                            })
                          }
                          className={`chapterSection font-18 d-flex justify-content-center mt-3 cursor-pointer align-items-center ${
                            this.state.userData.documents &&
                            this.state.userData.documents.length <= 4 &&
                            "hidden"
                          }`}
                        >
                          {this.state.isDocumentsSeeMore
                            ? "See Less"
                            : "See More"}
                        </div>
                      </RightSharedSection>
                    )}

                  {/* document section */}

                  {/* {this.state.favoriteCampaigns.Article &&
                  this.state.favoriteCampaigns.Article.length > 0 && (
                    <RightSharedSection
                      heading={"Favorite News"}
                      classToOverride="mt-4"
                    >
                      <div
                        className={
                          this.state.seeMoreArticles
                            ? "myNetworkSeeMore scroll-y pb-3"
                            : " pb-3"
                        }
                      >
                        {this.state.seeMoreArticles
                          ? this.state.favoriteCampaigns.Article.map(
                              (data, index) => {
                                return (
                                  <div className="mt-3 mx-3">
                                    <InforCard
                                      ImageSection={data.image_url}
                                      Heading={data.heading}
                                      subHeading={data.sub_heading}
                                      isLike={true}
                                      leftSectionId="like"
                                      onSectionClick={() => {
                                        window.scrollTo({
                                          top: 0,
                                          left: 0,
                                          behavior: "smooth",
                                        });
                                        this.props.history.push(
                                          `/news-details/${data.campaign_id}`
                                        );
                                      }}
                                    />
                                  </div>
                                );
                              }
                            )
                          : this.state.favoriteCampaigns.Article.slice(
                              0,
                              3
                            ).map((data, index) => {
                              return (
                                <div className="mt-3 mx-3">
                                  <InforCard
                                    ImageSection={data.image_url}
                                    Heading={data.heading}
                                    subHeading={data.sub_heading}
                                    isLike={true}
                                    leftSectionId="like"
                                    onSectionClick={() => {
                                      window.scrollTo({
                                        top: 0,
                                        left: 0,
                                        behavior: "smooth",
                                      });
                                      this.props.history.push(
                                        `/news-details/${data.campaign_id}`
                                      );
                                    }}
                                  />
                                </div>
                              );
                            })}
                      </div>
                      <div
                        className={`chapterSection font-18 d-flex justify-content-center mt-3 cursor-pointer align-items-center ${
                          this.state.favoriteCampaigns.Article &&
                          this.state.favoriteCampaigns.Article.length <= 3 &&
                          "hidden"
                        }`}
                        onClick={() => {
                          if (
                            this.state.favoriteCampaigns.Article &&
                            this.state.favoriteCampaigns.Article.length > 3
                          ) {
                            this.setState({
                              seeMoreArticles: !this.state.seeMoreArticles,
                            });
                          }
                        }}
                      >
                        {this.state.seeMoreArticles ? "See Less" : "See More"}
                      </div>
                    </RightSharedSection>
                  )} */}
                  {this.state.favoriteCampaigns.Event &&
                    this.state.favoriteCampaigns.Event.length > 0 && (
                      <RightSharedSection
                        is_Followed={this.state.is_Followed}
                        User={this.state.userData}
                        heading={"Favorite Events"}
                        classToOverride="mt-4"
                      >
                        <div
                          className={
                            this.state.seeMoreEvents
                              ? " eventsSeeMore scroll-y pb-3"
                              : " pb-3"
                          }
                        >
                          {this.state.loader.favoriteCampaigns
                            ? [1, 2, 3].map((item) => (
                                <Skeleton
                                  variant="text"
                                  height={100}
                                  className="mx-3 rounded-20"
                                  key={item}
                                />
                              ))
                            : this.state.seeMoreEvents
                            ? this.state.favoriteCampaigns.Event.map(
                                (data, index) => {
                                  return (
                                    <div className="mt-3 mx-3">
                                      <InforCard
                                        ImageSection={""}
                                        Heading={data.heading}
                                        subHeading={data.sub_heading}
                                        isLike={true}
                                        leftSectionId="like"
                                        onSectionClick={() => {
                                          window.scrollTo({
                                            top: 0,
                                            left: 0,
                                            behavior: "smooth",
                                          });
                                          this.props.history.push(
                                            `/news-details/${data.campaign_id}`
                                          );
                                        }}
                                        start_at={data.start_at}
                                        isEvent={true}
                                      />
                                    </div>
                                  );
                                }
                              )
                            : this.state.favoriteCampaigns.Event.slice(
                                0,
                                3
                              ).map((data, index) => {
                                return (
                                  <div className="mt-3 mx-3">
                                    <InforCard
                                      ImageSection={""}
                                      Heading={data.heading}
                                      subHeading={data.sub_heading}
                                      isLike={true}
                                      leftSectionId="like"
                                      start_at={data.start_at}
                                      onSectionClick={() => {
                                        window.scrollTo({
                                          top: 0,
                                          left: 0,
                                          behavior: "smooth",
                                        });
                                        this.props.history.push(
                                          `/event-details/${data.campaign_id}`
                                        );
                                      }}
                                      isEvent={true}
                                    />
                                  </div>
                                );
                              })}
                        </div>
                        <div
                          className={`chapterSection font-18 d-flex justify-content-center mt-3 cursor-pointer align-items-center ${
                            this.state.favoriteCampaigns.Event &&
                            this.state.favoriteCampaigns.Event.length <= 3 &&
                            "hidden"
                          }`}
                          onClick={() => {
                            if (
                              this.state.favoriteCampaigns.Event &&
                              this.state.favoriteCampaigns.Event.length > 3
                            ) {
                              this.setState({
                                seeMoreEvents: !this.state.seeMoreEvents,
                              });
                            }
                          }}
                        >
                          {this.state.seeMoreEvents ? "See Less" : "See More"}
                        </div>
                      </RightSharedSection>
                    )}

                  {this.props.userData.role_id === ROLE_IDS.USER &&
                    this.state.isPublic === true &&
                    this.state.userData.network === "clients" &&
                    this.props.userNeighborhoods.some((neighborhood_item) => {
                      return (
                        neighborhood_item.client_id ===
                          this.state.userData.client_id &&
                        neighborhood_item.created_by === "OTHER"
                      );
                    }) && (
                      <div className="my-4">
                        <LeaveCommunity
                          client_id={this.state.userData.client_id}
                        />
                      </div>
                    )}
                  {!this.state.isPublic && Utility.pwaModeEnabled() && (
                    <RightSharedSection
                      isOverLay={false}
                      is_Followed={this.state.is_Followed}
                      User={this.state.userData}
                      classToOverride="py-3 mb-2"
                    >
                      <div
                        onClick={() =>
                          this.props.history.push(
                            `/profile/${this.props.userData.id}`
                          )
                        }
                        className="w-100 text-center font-20 p-4 font-weight-bold rounded font-color-primary cursor-pointer bg-primary text-light"
                      >
                        Go to Public View
                      </div>
                    </RightSharedSection>
                  )}
                  {this.state.isPublic &&
                    Utility.pwaModeEnabled() &&
                    this.props.userData.id === params.id && (
                      <RightSharedSection
                        isOverLay={false}
                        is_Followed={this.state.is_Followed}
                        User={this.state.userData}
                        classToOverride="py-3 mb-2"
                      >
                        <div
                          onClick={() => this.props.history.push(`/profile`)}
                          className="w-100 text-center font-20 p-4 font-weight-bold rounded font-color-primary cursor-pointer bg-primary text-light"
                        >
                          Go to Private View
                        </div>
                      </RightSharedSection>
                    )}
                </div>
              )}
            </div>

            {/* {this.props.seeAll.isOpen && ( */}
            {/* <SeeAll
                closeCallout={() =>
                  this.props.updateSeeAll({ isOpen: null, calledFrom: null })
                }
                calledFrom={this.props.seeAll.calledFrom}
                width={"100%"}
                selectedUserId={params.id}
              /> */}
            {/* )} */}
          </div>
        </div>
        {/* {this.props.isChatFormOpen && <ChatCallout />} */}

        {!Utility.isEmpty(this.props.neighborhoodState) &&
          this.props.neighborhoodState.showForm &&
          this.state.isEdit && (
            <EditProfileCallout
              userData={userData}
              GodQualities={this.state.GodQualities}
              history={this.props.history}
              userProfileCall={() => this.profileData(this.state.userData.id)}
              neighborhoodState={this.props.neighborhoodState}
              edit={this.props.neighborhoodState.edit}
              selectedGodQualities={this.state.selectedGodQualities}
              selectedOrganizationQualities={
                this.state.selectedOrganizationQualities
              }
              selectedStrengthQualitites={this.state.selectedStrengthQualitites}
              addGodSelectedQualities={this.addGodSelectedQualities}
              removeGodSelectedQualities={this.removeGodSelectedQualities}
              addOrganizationQualities={this.addOrganizationQualities}
              removeOrganizationQualities={this.removeOrganizationQualities}
              addStrengthQualities={this.addStrengthQualities}
              removeStrengthQualities={this.removeStrengthQualities}
              id={this.props.neighborhoodState.id}
              openNeighborhoodForm={this.props.openNeighborhoodForm}
              closeForm={this.editCloseForm}
              showLoader={false}
              updateAccountList={this.props.updateAccountList}
              setUserQualities={this.setUserQualities}
              updateUserData={this.props.updateUserData}
            />
          )}

        {/* {this.props.location.state &&
          this.props.location.state.fromSignUp &&
          this.state.showPassportCallout && ( */}
        {/* <SideCallout
              closeCallOut={this.closePassportCallOut}
              position="position-fixed mt-5"
              calloutHeading="Passport"
              calloutHeadingImg="/static/media/passport-icon.28c76055.svg"
            >
              <Passport
                userData={userData}
                getUserBadges={this.getUserBadges}
                getAllCategories={this.getAllCategories}
                history={this.props.history}
                getUpdatedUserDetails={this.getUpdatedUserDetails}
                fromSignUp={
                  this.props.location.state &&
                  this.props.location.state.fromSignUp
                }
              />
            </SideCallout> */}
        {/* )} */}
        {/* <WeaFooter /> */}
      </>
    );
  }
}

const mapActionToProps = {
  // showLoader,
  userQualities,
  updateUserData,
  updatePublicUserData,
  updateChatFriend,
  updateIsChatFormOpen,
  openNeighborhoodForm,
  updateAccountList,
  updateSeeAll,
};

const mapStateToProps = (state) => ({
  userData: state.userData,
  publicUserData: state.publicUserData,
  neighborhoodState: state.neighborhoodState,
  isLoading: state.isLoading,
  isChatFormOpen: state.isChatFormOpen,
  seeAll: state.seeAll,
  refreshCampaignApi: state.refreshCampaignApi,
  userNeighborhoods: state.userNeighborhoods,
});

//  const profileContainerWrapper = withRouter(ProfileContainer);
export default connect(mapStateToProps, mapActionToProps)(ProfileContainer);
