import React, { memo, useEffect, useState } from "react";
import { NETWORK_TYPES, NETWORK } from "config/constants/config";
import { Utility } from "utils";
import { PERMISSIONS, ROLE_IDS } from "config/constants";
import { Link, useHistory, useLocation } from "react-router-dom";
import { Dropdown, Form } from "react-bootstrap";
import { ChevronDownIcon } from "shared/icons/icons";
import { CLIENT_ID } from "config/constants/config";
import { useSelector } from "react-redux";
import { GIL_CLIENT_ID } from "config/constants";
import Select from "react-select";
import moment from "moment";
import { CAMPAIGN_TYPES_IDS, ROLES } from "../../../config/constants";
import { toast } from "react-toastify";
import {
  GET_WEA_LIST,
  UPDATE_CATEGORIES,
} from "../../../config/constants/api_endpoints";
let TableRows = (props) => {
  const location = useLocation();
  return (
    <>
      <tbody>
        {props.data &&
          props.data.map((item, index) => {
            let networkType = "";

            /* Just to remove rank dropdown in network module */
            if (item.role_id === ROLE_IDS.SUPER_OWNER) {
            } else if (
              props.selectedViewData &&
              props.selectedViewData.active === "NeighborhoodUsers"
            ) {
              return (
                <NetWorkComponent
                  item={item}
                  selectedUsers={props.selectedUsers}
                  addUser={props.addUser}
                  selectedClients={props.selectedClients}
                  index={index}
                  networkType={networkType}
                  payload={props}
                  userData={props.userData}
                />
              );
            } else if (item.hasOwnProperty("network") && item.network) {
              /* Just to remove rank dropdown in network module */
              let network = item.network;
              network = `${network.charAt(0).toUpperCase()}${network.slice(1)}`;
              networkType = NETWORK[network];
              return (
                <NetWorkComponent
                  item={item}
                  selectedUsers={props.selectedUsers}
                  addUser={props.addUser}
                  selectedClients={props.selectedClients}
                  index={index}
                  networkType={networkType}
                  payload={props}
                  userData={props.userData}
                />
              );
            } else {
              if (
                (props.userData.role === "CLIENT_OWNER" ||
                  props.userData.role === "CLIENT_ADMIN") &&
                location.pathname === "/networks"
                // props.selectedMenuItemName !== "course"
              ) {
                return (
                  <NetWorkComponent
                    item={item}
                    addUser={props.addUser}
                    index={index}
                    selectedUsers={props.selectedUsers}
                    selectedClients={props.selectedClients}
                    networkType={"Citizen"}
                    payload={props}
                    userData={props.userData}
                    removeUserFromNeighborhood={
                      props.removeUserFromNeighborhood
                    }
                    alterAdminStatusInNeighborhood={
                      props.alterAdminStatusInNeighborhood
                    }
                  />
                );
              } else {
                return (
                  <CampaignComponent
                    item={item}
                    index={index}
                    payload={props}
                  />
                );
              }
            }
          })}
      </tbody>
    </>
  );
};

const ToggleComponent = (props) => {
  let showToggle = true;
  let isDisabled = true;

  if (
    props.setAdminAfterToggle &&
    props.setAdminAfterToggle.id === props.data.id
  ) {
    props.data.is_admin = props.setAdminAfterToggle.value;
  }

  if (props.data && props.data.network) {
    let networkType = props.data.network;
    networkType = networkType.charAt(0).toUpperCase() + networkType.slice(1);

    showToggle = NETWORK_TYPES[networkType] === NETWORK_TYPES.Users;
  }

  if (props.userData.permissions.includes(PERMISSIONS.ADD_ADMIN))
    isDisabled = false;

  return (
    <div className="align-items-center">
      {showToggle && (
        <div className="toggle-switch toggle-switch-sm ml-2 align-items-center">
          <input
            disabled={isDisabled}
            type="checkbox"
            id={`adminStatus${props.index}`}
            checked={props.data.is_admin ? "checked" : ""}
            onChange={
              props.userData.role === "CLIENT_OWNER" ||
              props.userData.role === "CLIENT_ADMIN" ||
              props.type === "NeighborhoodUsers"
                ? (e) => {
                    props.alterAdminStatusInNeighborhood(
                      props.data.user_id,
                      props.data.is_admin,
                      props.data.client_id
                    );
                  }
                : (e) => props.setToggleState(e.target.checked, props.data)
            }
          />
          <label
            htmlFor={`adminStatus${props.index}`}
            className="d-inline-flex mb-0"
          >
            <span className="toggle-track" />
          </label>
        </div>
      )}
    </div>
  );
};

const ImageComponent = (props) => {
  return (
    <img
      src={
        !Utility.isEmpty(props.image_url)
          ? props.image_url
          : require("assets/images/" + props.default_img)
      }
      alt="registrant-img"
      className="registrant-img golden-border"
    />
  );
};

const NetWorkComponent = (props) => {
  const { item, index, payload } = props;
  useEffect(() => {
    setSchoolArray([...getSchoolArr()]);
  }, [item]);
  const [subCategory, setSubCategory] = useState([]);
  const [selectedSubCategory, setSelectedSubCategory] = useState("");
  const [category, setCategory] = useState([]);
  // eslint-disable-next-line no-unused-vars
  const getWeaList = (tag_name) => {
    Utility.sendRequest(
      GET_WEA_LIST,
      1,
      { tag_type: tag_name },
      (err, response, body) => {
        body = JSON.parse(body);
        if (!body.error) {
          body.data.unshift({ id: "", name: "Select SubTag" });
          setSubCategory(body.data);
        } else {
          toast.error(JSON.parse(body).error.message, {
            containerId: "private",
          });
        }
      }
    );
  };
  const [subTagId, setSubTagId] = useState("");
  const userData = useSelector((state) => state.userData);
  const updateCategories = () => {
    const params = {
      user_client_id: item.id,
      tag: isSelectedCategory ? selectedCategory : item.tag,
      sub_tag: subTagId,
      platform_id: userData.platform_id,
    };
    Utility.sendRequest(
      UPDATE_CATEGORIES,
      3,
      params,
      (err, response, body) => {}
    );
  };
  const [selectedCategory, setSelectedCategory] = useState("");
  const [isSelectedCategory, isSetSelectedCategory] = useState(false);
  const selectedMenuItem = useSelector((state) => state.selectedMenuItem);
  useEffect(() => {
    if (subTagId !== "") {
      updateCategories();
    }
  }, [subTagId, selectedCategory]);
  const getSchoolArr = () => {
    // return props.payload && props.payload.subCategoriesData && props.payload.subCategoriesData.filter((school)=>  item.school_id && item.school_id.includes(school.id)).map((school)=>{
    return (
      (props.payload &&
        props.payload.subCategoriesData &&
        props.payload.subCategoriesData
          .filter(
            (school) => item.school_id && item.school_id.includes(school.id)
          )
          .map((school) => {
            return {
              value: school.id,
              label: school.name,
            };
          })) ||
      []
    );
  };

  const getMatchedSubCategories = () => {
    if (selectedCategory === "") {
      if (item && item.tag && item.tag.toLowerCase() === "organization") {
        getWeaList("organisation_tag");
      } else if (item && item.tag && item.tag.toLowerCase() === "wea") {
        getWeaList("wea_tag");
      } else if (item && item.tag && item.tag.toLowerCase() === "topics") {
        getWeaList("topic_tag");
      }
    } else {
      if (selectedCategory === "Organization") {
        getWeaList("organisation_tag");
      } else if (selectedCategory === "Wea") {
        getWeaList("wea_tag");
      } else if (selectedCategory === "Topics") {
        getWeaList("topic_tag");
      }
    }
  };

  const [intialSubCategory, setInitialSubCategory] = useState(false);
  const [schoolArray, setSchoolArray] = useState([...getSchoolArr()]);
  useEffect(() => {
    setSelectedSubCategory("");
  }, [selectedCategory]);
  const history = useHistory();
  switch (
    props.payload &&
    props.payload.selectedViewData &&
    props.payload.selectedViewData.active
  ) {
    case "NeighborhoodUsers":
      return (
        <tr key={item.user_id}>
          <td
            className="cursor-pointer"
            onClick={() => {
              if (item.user_client_id) {
                history.push(`profile/${item.user_client_id}`);
              }
            }}
          >
            {item.user_name || item.heading || item.full_name}
          </td>
          <td>{item.email}</td>
          <td>{moment(item.date_of_joining).format("MM-DD-YYYY")}</td>
          <td>
            <ToggleComponent
              index={index}
              data={item}
              setToggleState={payload.onToggleClick}
              setAdminAfterToggle={payload.setAdminAfterToggle}
              userData={payload.userData}
              alterAdminStatusInNeighborhood={
                props.payload.alterAdminStatusInNeighborhood
              }
              type="NeighborhoodUsers"
            />
          </td>
          <td>
            <button
              value={item.user_id}
              disabled={item.is_admin}
              onClick={(e) => {
                payload.removeUserFromNeighborhood(
                  e.target.value,
                  item.external_id,
                  item.client_id
                );
              }}
            >
              Remove
            </button>
          </td>
        </tr>
      );
    default:
      return (
        <tr key={index}>
          {[ROLE_IDS.PARENT_OWNER, ROLE_IDS.USERS].includes(userData.role_id) &&
            props.userData.actual_id === props.userData.id && (
              <td>
                <input
                  type="checkbox"
                  checked={
                    props.selectedUsers.includes(item.user_id) ||
                    props.selectedClients.includes(item.id)
                  }
                  onClick={(event) => props.addUser(event, item)}
                  id={item.id}
                />
              </td>
            )}
          <td>
            {props.payload.selectedMenuItemName === "Neighborhoods" ? (
              <div
                className="cursor-pointer"
                onClick={() =>
                  props.payload.handleSetSelectedViewData({
                    active: "NeighborhoodUsers",
                    neighborhood_id: item.client_id,
                  })
                }
              >
                {item.user_name || item.heading || item.full_name}
              </div>
            ) : (
              <Link
                onMouseDown={() => {
                  if (payload.selectedMenuItemName) {
                    localStorage.setItem(
                      "SelectedTab",
                      payload.selectedMenuItemName
                    );
                  }
                }}
                to={{
                  pathname:
                    payload.selectedMenuItemName === "event"
                      ? `/event-details/${item.id}`
                      : payload.selectedMenuItemName === "Story"
                      ? `/news-details/${item.id}`
                      : payload.selectedMenuItemName === "initiative" ||
                        payload.selectedMenuItemName === "Post"
                      ? "/campaigns"
                      : `/profile/${
                          props.userData.role_id === ROLE_IDS.CLIENT_ADMIN ||
                          props.userData.role_id === ROLE_IDS.CLIENT_OWNER
                            ? item.user_client_id
                            : item.id
                        }`,
                  state: { otherUserProfile: true },
                }}
              >
                <div className="align-items-center">
                  {item.hasOwnProperty("profile_image_url") ||
                    (item.hasOwnProperty("image_url") && (
                      <ImageComponent
                        image_url={item.profile_image_url || item.image_url}
                        default_img={"users/user_no_image.png"}
                      />
                    ))}
                  <span className="font-weight-bold text-dark ml-2 cursor-pointer">
                    {item.user_name || item.heading || item.full_name}
                  </span>
                </div>
              </Link>
            )}
          </td>
          {![ROLE_IDS.PARENT_OWNER].includes(userData.role_id) && (
            <td>{item.email}</td>
          )}
          <td>{props.networkType}</td>
          {(userData.role_id === ROLE_IDS.PARENT_OWNER ||
            userData.role_id === ROLE_IDS.PARENT_ADMIN) &&
            selectedMenuItem.menu_name === "Neighborhoods" && (
              <td>{item.is_public === true ? "Public" : "Private"}</td>
            )}
          {props.selectedMenuItemName !== "All Applications" &&
            props.userData.actual_id === props.userData.id && (
              <>
                <>
                  {props.userData.role === "PARENT_OWNER" &&
                  selectedMenuItem.menu_name === "Neighborhoods" ? (
                    <>
                      {/* <td>
                       {item.max_licenses &&
                       `${item.user_count} of ${item.max_licenses}`}
                       </td> */}
                    </>
                  ) : (
                    <td>
                      <ToggleComponent
                        index={index}
                        data={item}
                        setToggleState={payload.onToggleClick}
                        setAdminAfterToggle={payload.setAdminAfterToggle}
                        userData={payload.userData}
                        alterAdminStatusInNeighborhood={
                          props.alterAdminStatusInNeighborhood
                        }
                      />
                    </td>
                  )}
                </>

                {[ROLE_IDS.CLIENT_OWNER, ROLE_IDS.CLIENT_ADMIN].includes(
                  userData.role_id
                ) && (
                  <td>
                    <button
                      value={item.user_id}
                      disabled={item.is_admin}
                      onClick={(e) => {
                        payload.removeUserFromNeighborhood(
                          e.target.value,
                          item.external_id,
                          item.client_id
                        );
                      }}
                    >
                      Remove
                    </button>
                  </td>
                )}

                {[ROLE_IDS.PARENT_OWNER, ROLE_IDS.USERS].includes(
                  userData.role_id
                ) &&
                  props.userData.actual_id === props.userData.id && (
                    <td>
                      <Dropdown>
                        <Dropdown.Toggle
                          variant="table-dropdown"
                          id="dropdown-basic"
                        >
                          <span>
                            {([
                              ROLE_IDS.PARENT_OWNER,
                              ROLE_IDS.PARENT_ADMIN,
                              ROLE_IDS.USER,
                            ].includes(item.role_id) &&
                              item.is_user_account_active === true) ||
                            ([
                              ROLE_IDS.CLIENT_OWNER,
                              ROLE_IDS.CLIENT_ADMIN,
                            ].includes(item.role_id) &&
                              item.uc_is_active === true)
                              ? "Active"
                              : "Inactive"}
                          </span>
                          <ChevronDownIcon />
                        </Dropdown.Toggle>
                        <Dropdown.Menu>
                          <Dropdown.Item
                            onClick={() =>
                              props.payload.activateDeactivateUser(
                                item.email,
                                item.user_id,
                                true,
                                item.network,
                                item.id,
                                true
                              )
                            }
                          >
                            Active
                          </Dropdown.Item>
                          <Dropdown.Item
                            onClick={() =>
                              props.payload.activateDeactivateUser(
                                item.email,
                                item.user_id,
                                false,
                                item.network,
                                item.id,
                                false
                              )
                            }
                          >
                            Inactive
                          </Dropdown.Item>
                        </Dropdown.Menu>
                      </Dropdown>
                    </td>
                  )}
              </>
            )}
          {selectedMenuItem.menu_name === "Neighborhoods" &&
            [ROLE_IDS.PARENT_OWNER, ROLE_IDS.USERS].includes(
              userData.role_id
            ) &&
            props.userData.actual_id === props.userData.id && (
              <>
                {item.region_id === null ? (
                  <td>
                    <Dropdown>
                      <Dropdown.Toggle
                        variant="table-dropdown"
                        id="dropdown-basic"
                      >
                        <span>
                          {selectedCategory === ""
                            ? item.tag
                            : selectedCategory}
                        </span>
                        <ChevronDownIcon />
                      </Dropdown.Toggle>
                      <Dropdown.Menu>
                        <Dropdown.Item
                          onClick={() => {
                            setSelectedCategory("Organization");
                            getWeaList("organisation_tag");
                            setInitialSubCategory(true);
                            isSetSelectedCategory(true);
                          }}
                        >
                          Organization
                        </Dropdown.Item>
                        <Dropdown.Item
                          onClick={() => {
                            setSelectedCategory("Wea");
                            getWeaList("wea_tag");
                            setInitialSubCategory(true);
                            isSetSelectedCategory(true);
                          }}
                        >
                          WEA
                        </Dropdown.Item>
                        <Dropdown.Item
                          onClick={() => {
                            setSelectedCategory("Topics");
                            setInitialSubCategory(true);
                            getWeaList("topic_tag");
                            isSetSelectedCategory(true);
                          }}
                        >
                          Topics
                        </Dropdown.Item>
                      </Dropdown.Menu>
                    </Dropdown>
                  </td>
                ) : (
                  <td className="text-center">-</td>
                )}

                {item.region_id === null ? (
                  <td>
                    <Dropdown
                      onClick={() => {
                        getMatchedSubCategories();
                      }}
                    >
                      <Dropdown.Toggle
                        variant="table-dropdown"
                        id="dropdown-basic"
                      >
                        <span>
                          {!intialSubCategory
                            ? item.sub_tag_name
                            : selectedSubCategory}
                        </span>
                        <ChevronDownIcon />
                      </Dropdown.Toggle>
                      <Dropdown.Menu>
                        {subCategory.map((category, idx) => {
                          return (
                            <Dropdown.Item
                              key={idx}
                              //  on
                              onClick={() => {
                                setSubTagId(category.id);
                                setSelectedSubCategory(category.name);
                                setInitialSubCategory(true);
                                // if (!intialSubCategory)
                                //   setSelectedCategory(item.tag);
                              }}
                            >
                              {category.name}
                            </Dropdown.Item>
                          );
                        })}
                      </Dropdown.Menu>
                    </Dropdown>
                  </td>
                ) : (
                  <td className="text-center">-</td>
                )}
              </>
            )}
        </tr>
      );
  }
};

const CampaignComponent = (props) => {
  const { item, index, payload } = props;
  return (
    <tr key={index} className="">
      <td>
        <Link
          onMouseDown={() => {
            if (payload.selectedMenuItemName) {
              localStorage.setItem("SelectedTab", payload.selectedMenuItemName);
            }
          }}
          to={{
            pathname:
              payload.selectedMenuItemName === "event"
                ? `/event-details/${item.id}`
                : payload.selectedMenuItemName === "Story"
                ? `/news-details/${item.id}`
                : payload.selectedMenuItemName === "initiative" ||
                  payload.selectedMenuItemName === "Post"
                ? "/campaigns"
                : `/campaign/${item.id}`,
            state: { otherUserProfile: true },
          }}
        >
          <div className="align-items-center">
            {item.hasOwnProperty("image_url") && (
              <ImageComponent
                image_url={
                  item.image_url ? item.image_url : item.embedded_image_link
                }
                default_img={"users/user_no_image.png"}
              />
            )}
            <span className="font-weight-bold text-dark ml-2 cursor-pointer">
              {item.heading}
            </span>
          </div>
        </Link>
      </td>
      {/* <td>{item.campaign_category}</td> */}
      {(payload.userData.role_id === ROLE_IDS.PARENT_OWNER ||
        payload.userData.role_id === ROLE_IDS.SUPER_OWNER) &&
        (payload.selectedMenuItemId === CAMPAIGN_TYPES_IDS.courses ||
          payload.selectedMenuItemId === CAMPAIGN_TYPES_IDS.event ||
          payload.selectedMenuItemId === CAMPAIGN_TYPES_IDS.story) && (
          <td>
            <div className="d-flex">
              {
                <Dropdown>
                  <Dropdown.Toggle variant="table-dropdown" id="dropdown-basic">
                    {!Utility.isEmpty(item.ranking)
                      ? item.ranking
                      : "Select ranking"}
                    <ChevronDownIcon />
                  </Dropdown.Toggle>
                  <Dropdown.Menu>
                    {["Select", 1, 2, 3, 4, 5, 6, 7, 8, 9, 10].map((e) => {
                      return (
                        <Dropdown.Item
                          key={e}
                          onClick={() =>
                            payload.editRanking(
                              item.id,
                              e === "Select" ? null : e
                            )
                          }
                        >
                          {e}
                        </Dropdown.Item>
                      );
                    })}
                  </Dropdown.Menu>
                </Dropdown>
              }
            </div>
          </td>
        )}

      {[
        "39ab8d70-3d6b-4f3f-a702-8ead10a90bcf",
        "93ab8d70-3d6b-4f3f-a702-8ead10a90bcf",
        "6f8b1d1e-c537-4580-835b-8b6b58bfd4ec",
      ].includes(payload.selectedMenuItemId) &&
        CLIENT_ID === GIL_CLIENT_ID &&
        ["course", "event", "Story"].includes(payload.selectedMenuItemName) && (
          <td className="action-td-chat">
            <div className="d-flex">
              {
                <Dropdown>
                  <Dropdown.Toggle variant="table-dropdown" id="dropdown-basic">
                    <span>{"------"}</span>
                    <ChevronDownIcon />
                  </Dropdown.Toggle>

                  <Dropdown.Menu>
                    <Dropdown.Item
                      onClick={() => {
                        payload.editStory(item.id, payload.selectedMenuItemId);
                      }}
                    >
                      Edit
                    </Dropdown.Item>
                    <Dropdown.Item
                      onClick={() => {
                        if (CLIENT_ID == GIL_CLIENT_ID) {
                          window.confirm(
                            "Sure You want to delete this Course?"
                          ) &&
                            payload.remove(item.id, payload.selectedMenuItemId);
                        } else {
                          payload.remove(item.id, payload.selectedMenuItemId);
                        }
                      }}
                    >
                      Delete
                    </Dropdown.Item>
                  </Dropdown.Menu>
                </Dropdown>
              }
            </div>
          </td>
        )}
    </tr>
  );
};

export default TableRows = memo(TableRows);
