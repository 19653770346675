import React from 'react';
import { STATUS } from 'config/constants';

export default function InviteCard(props) {
  return(
      <div className="invitation-card additional-card overflow-hidden mb-4" >
            <div className="additional-card-head position-relative" >
                <img src={props.data.image_url}  alt=""  />
            </div>
            <div className="p-3 invitation-card-body d-flex flex-column" >
              <div className="font-12" >
                You are invited to join
                <span className="text-primary font-bold font-14" > {props.data.name} </span>
              </div>
              {
                props.showButton &&
                <>
                  <div className="text-center mt-4 pt-2" >
                    <button
                      onClick={() => props.handleClick(props.data.id, STATUS.approved, props.data.name)}
                      className="btn btn-primary btn-primary-pwa btn-rounded-3 font-14" >
                      ACCEPT
                    </button>
                  </div>
                  <div className="text-center mt-2 pt-2" >
                    <span
                      onClick={() => props.handleClick(props.data.id, STATUS.declined, props.data.name)}
                      className="text-warning font-weight-bold cursor-pointer" >
                        DECLINE
                      </span>
                  </div>
                </>
              }
            </div>
      </div>
  );
}
