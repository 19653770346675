import React from "react";
import UserCommentContainer from "../../v2/campaign/components/UserCommentContainer";
import ContentContainer from "./contentContainer";
import DetailsContainer from "./detailsContainer";
import EventRightPanel from "../eventRightPanel";
import { useMediaQuery } from "@mui/material";

const EventLeftPanel = (props) => {
  const isTablet = useMediaQuery("(max-width: 900px)");
  return (
    <div className="eventLeftPanel background-sidebar-pages">
      <DetailsContainer
        getEventMember={props.getEventMember}
        eventDetails={props.eventDetails}
        userData={props.userData}
      />
      <ContentContainer eventDetails={props.eventDetails} />
      {!isTablet ? (
        ""
      ) : (
        <EventRightPanel
          attendeeList={props.attendeeList}
          getEventMember={props.getEventMember}
          eventDetails={props.eventDetails}
          userData={props.userData}
        />
      )}

      <div className="bg-white ePanelUserChatContainer mb-5">
        <UserCommentContainer
          ActiveLessonId={props.campaign_id}
          isRegion={true}
          isCampaign={true}
        />
      </div>
    </div>
  );
};

export default EventLeftPanel;
