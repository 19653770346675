import axios from "axios";
import {
  API_PATH,
  MEDIA_SIGNED_URL,
} from "../../config/constants/api_endpoints";
import { showLoader } from "../../shared/actions";
import { store } from "../../store";

export const uploadMedia = async (file, isProfile, fileName, fileType) => {
  if (file) {
    const response = await axios.get(`${API_PATH}${MEDIA_SIGNED_URL}`, {
      params: { fileName: fileName || file.name },
    });

    const imgSignedUrl =
      response && response.data && response.data.imageSignedUrl;
    if (!isProfile) store.dispatch(showLoader(true));
    const result = await axios.put(imgSignedUrl, file, {
      headers: {
        "Content-type": fileType || file.type,
      },
    });
    if (!isProfile) store.dispatch(showLoader(false));
    if (result && result.status === 200) {
      const url = imgSignedUrl.substr(
        0,
        imgSignedUrl.indexOf("?X-Amz-Algorithm=")
      );
      return url;
    }
  }
};
