import React from 'react';
import { Col, Table, Spinner } from 'react-bootstrap';
import { Utility, Date } from 'utils';

export const ReceiptView = props => (
  <Col lg={7}>
    <div className="receipt-screen p-3">
    {
      Utility.isEmpty(props.accountDetails)
      ? <center><Spinner animation="border" role="status"/></center>
      : <>
          <img src={require("assets/images/icons/receipt_doc_icon.svg")} alt="Document icon" />
          <div className="display-6 font-bold my-3">Receipt Description:</div>
          <div className="font-14">{props.accountDetails.description || 'None'}</div>
          <Table borderless responsive size="sm" className="font-14 my-4 receipt-table" >
            <tbody>
              <tr>
                <td className="font-bold">Date:</td>
                <td>{Date.formatDateToString(props.accountDetails.date)}</td>
              </tr>
              <tr>
                <td className="font-bold">Client Name:</td>
                <td>{props.accountDetails.name}</td>
              </tr>
              <tr>
                <td className="font-bold">Impact:</td>
                <td>${Utility.toLocaleString(props.accountDetails.impact)}</td>
              </tr>
              <tr>
                <td className="font-bold">Paid Amount:</td>
                <td>${Utility.toLocaleString((props.accountDetails.deposit === '0.00'
                                              ? props.accountDetails.withdrawal
                                              : props.accountDetails.deposit))}</td>
              </tr>
              <tr>
                <td className="font-bold">Receipt #:</td>
                <td className="word-break-all" >{props.accountDetails.receipt_number}</td>
              </tr>
              <tr>
                <td className="font-bold">Transaction:</td>
                <td>
                  <ul>
                    <li>Amount: ${Utility.toLocaleString(props.accountDetails.impact)}</li>
                    <li>Fee: ${Utility.toLocaleString(props.accountDetails.fee)}</li>
                  </ul>
                </td>
              </tr>
            </tbody>
          </Table>
          {/* <div className="receipt-screen-icons align-items-center justify-content-between">
            <img src={require("assets/images/icons/download_icon.svg")} alt="download icon" />
            <img src={require("assets/images/icons/full_screen_icon.svg")} alt="expand icon" />
          </div> */}
        </>
    }
    </div>
    {/* <div className="mt-3 text-center">
      <Button variant="primary-outlined">Statement Details</Button>
    </div> */}
  </Col>
);
