import React, { useEffect } from "react";
import { AiOutlineHeart, AiTwotoneHeart } from "react-icons/ai";
import { useHistory, useLocation } from "react-router-dom";
import { NewsIcon } from "../../../icons/icons";
import { Skeleton } from "@mui/material";

const NewsHeader = ({ heading, likes_count, getSentimentDetails, isLiked, newsLikeLoading }) => {
  const history = useHistory();

  const { pathname, state } = useLocation();
  useEffect(() => {
    if (!localStorage.getItem("RouteFrom")) {
      state &&
        state.from === "/create-article" &&
        localStorage.setItem("RouteFrom", state.from);
    } else {
      localStorage.getItem("RouteFrom") === "/create-article" &&
        localStorage.setItem("RouteFrom", "/create-article");
    }
    return () => {
      localStorage.removeItem("RouteFrom");
    };
  }, []);

  return (
    <div className="bg-white w-full d-flex align-items-center justify-content-between backArrowRow">
      <div className="d-flex">
        <img
          src={require("assets/images/icons/back-arrow.svg")}
          className="ml-3 cursor-pointer"
          alt="back-arrow"
          onClick={() => {
            localStorage.getItem("RouteFrom") === "/create-article"
              ? history.push("/news")
              : history.goBack();
          }}
        />
        <span className="mx-3 regionIcon">
          <NewsIcon />
        </span>
        <p className="mt-auto mb-auto b-700">{heading}</p>
      </div>
      <div className="d-flex">
        {/* <img
          src={require("assets/images/icons/heart-black.svg")}
          className="mr-2"
          onClick={() => getSentimentDetails()}
          alt="heart-black"
        /> */}
        {newsLikeLoading ? (
          <Skeleton variant="circular" width={40} height={40} />
        ) : (
        isLiked ? (
          <AiTwotoneHeart
            onClick={() => getSentimentDetails()}
            className="width-30 mr-2 text-danger"
          />
        ) : (
          <AiOutlineHeart
            onClick={() => getSentimentDetails()}
            className="width-30 mr-2"
          />
        ))}
        <p className="mr-3 mt-auto mb-auto">{likes_count}</p>
      </div>
    </div>
  );
};

export default NewsHeader;
