import React, { Component } from "react";
import cookie from "react-cookies";
import NewUploadFile from "shared/components/NewFileUpload";
import NewProfileUploadFile from "shared/components/NewProfileUpload";
import MenuItem from "@mui/material/MenuItem";
import { parsePhoneNumber } from "react-phone-number-input";
import { Image, Spinner } from "react-bootstrap";
import { InputField, DropDown } from "shared/components/form_elements";
import { CloseIcon, HollowAvatar } from "shared/icons/icons";
import { Utility } from "utils";
import {
  STATES,
  COUNTRIES,
  NEIGHBORHOOD_SIGN_UP,
  CLIENTS,
  SWITCH_ACCOUNT,
  USER_AUTH,
  GET_ACCOUNTS,
  USERS,
  SEND_VERIFICATION_CODE,
  MESSAGES,
} from "config/constants/api_endpoints";
import { CLIENT_ID } from "config/constants/config";
import { ROLE_IDS } from "config/constants";
import { toast } from "react-toastify";
import { MoreDropWhite } from "../../../../shared/icons/icons";
import { Menu } from "@material-ui/core";
import { Textarea } from "../../../../shared/components/form_elements";
import GodCalling from "../../../profile/components/GodCalling";
import {
  NewProfileUploadView,
  RemoveUploadedFile,
} from "../../../../shared/components/NewProfileUpload";
import UploadFile from "../../../../shared/components/file_upload";
import ReactPlayer from "react-player";
import { VideoUpload } from "../../../../components/shared/VideoUpload";
import { uploadMedia } from "../../../../services/uploadMedia";
import { Autocomplete, TextField, CircularProgress } from "@mui/material";
const validateFields = [
  "email",
  "name",
  "country_id",
  "state_id",
  "zip_code",
  "address1",
  "city",
  "phone",
  "first_name",
  "last_name",
];
const validateUpdateFields = ["first_name", "name"];

export class EditProfileCallout extends Component {
  constructor(props) {
    super(props);

    this.state = {
      leaderQuilities: [],
      organizationQualities: [],
      newSelected: [],
      newSelectedLeaderShip: [],
      allQualities: [],
      formData: {},
      formName: "neighborhood_form",
      imagePreviewUrl: "",
      imageFile: undefined,
      bannerFile: "",
      bannerPreviewUrl: "",
      isUserRegistered: false,
      showConfirmationScreen: false,
      countries: [],
      essayValue: "",
      // userQualities: [{
      //   id:"871796b6-ec05-42cb-a6a1-c4b74fe307b1",
      //   question:"Leader Quality",
      //   answer:""
      // },{
      //   id:"9e4d07e5-ba85-4ffa-a1fc-c888f21ba785",
      //   question:"Community Grow Quality",
      //   answer:""
      // },{
      //   id:"e4e2b281-1042-44a5-92ed-29249154c475",
      //   question:"Core strength Quality",
      //   answer:""
      // }],
      states: [],
      logoUrl: "",
      region: [
        {
          name: "Region",
          value: "",
        },
      ],
      gender: [
        {
          name: "Gender",
          value: "",
        },
        {
          name: "male",
          value: "male",
        },
        { name: "female", value: "female" },
        {
          name: "other",
          name: "other",
        },
      ],
      spinner: false,
      verifyCodeParams: {},
      resendVerificationParams: {},
      userClientId: "",
      verifyPhoneNumber: false,
      is_mobile_verified: false,
      showTos: false,
      showPp: false,
      showAup: false,
      showDa: false,
      showModal: false,
      newUserClientDetails: {},
      showSmTosModal: false,
      isCropping: false,
      countryId: "",
      MenuRender: null,
      isVideoUpload: false,
      videoFilePreview: null,
    };
  }

  componentDidMount = () => {
    if (
      this.props.selectedGodQualities &&
      this.props.selectedGodQualities.find(
        (item) => item.section === "LeaderQuality"
      )
    ) {
      this.setState({
        essayValue: this.props.selectedGodQualities.find(
          (item) => item.section === "LeaderQuality"
        ).title,
      });
    }
    this.getAllCountries();
    if (
      !Utility.isEmpty(this.props.userData) &&
      !Utility.isEmpty(this.props.id) &&
      this.props.edit &&
      ROLE_IDS.USER !== this.props.neighborhoodState.roleId
    )
      this.getClientData();
    if (
      !Utility.isEmpty(this.props.userData) &&
      this.props.edit &&
      ROLE_IDS.USER === this.props.neighborhoodState.roleId
    )
      this.setDefaultUserData();

    if (!this.props.edit) this.setDefaultClientData();
  };

  componentDidUpdate = (prevProps) => {
    if (
      prevProps.neighborhoodState !== this.props.neighborhoodState &&
      !Utility.isEmpty(this.props.userData) &&
      this.props.edit &&
      ROLE_IDS.CLIENT_OWNER === this.props.neighborhoodState.roleId
    )
      this.getClientData();

    if (
      prevProps.neighborhoodState !== this.props.neighborhoodState &&
      !Utility.isEmpty(this.props.userData) &&
      this.props.edit &&
      ROLE_IDS.USER === this.props.neighborhoodState.roleId
    )
      this.setDefaultUserData();

    if (
      prevProps.neighborhoodState !== this.props.neighborhoodState &&
      !this.props.edit
    )
      this.setDefaultClientData();
  };

  getClientData = () => {
    Utility.sendRequest(
      `${CLIENTS}/${this.props.id}`,
      1,
      {},
      (error, response, body) => {
        body = JSON.parse(body);

        if (!body.error) {
          this.getStateByCountry(body.country_id);
          this.setDefaultClientData(body);
        } else {
          toast.error(body.error.message, { containerId: "public" });
        }
      }
    );
  };

  setDefaultUserData = () => {
    const { userData } = this.props;
    const mobileNo = !Utility.isEmpty(userData.country_code)
      ? `${userData.phone_cellular.replace(/\s/g, "")}`
      : "";
    const data = {
      first_name: userData.first_name,
      last_name: userData.last_name,
      city: userData.user_city,
      email: userData.email,
      country_code: userData.country_code,
      phone: userData.phone_cellular ? userData.phone_cellular : mobileNo,
      zip_code: userData.user_zip_code,
      country_id: userData.user_country_id,
      state_id: userData.user_state_id,
      headline: userData.user_profile_heading,
      about: userData.bio_details,
      gender: userData.gender,
      address1: userData.user_address1,
      password: userData.password,
      image_url: userData.profile_image_url,
      website: userData.website,
    };

    if (userData.user_country_id)
      this.getStateByCountry(userData.user_country_id);
    this.setState({
      formData: data,
      imagePreviewUrl: userData.profile_image_url,
      bannerPreviewUrl: userData.user_banner_image_url,
    });
  };

  setDefaultClientData = (data) => {
    const userData = !Utility.isEmpty(data);
    const setFormData = {
      name: userData ? data.name : "",
      website: userData ? data.website : "https://www.",
      city: userData ? data.city : "",
      email: userData ? data.email : "",
      zip_code: userData ? data.zip_code : "",
      country_id: userData ? data.country_id : "",
      suite: userData ? data.suite : "",
      headline: userData ? data.user_profile_heading : "",
      about: userData
        ? data.UserClients &&
          data.UserClients.length !== 0 &&
          data.UserClients[0].bio_details
        : "",
      gender: userData ? data.gender : "",
      state_id: userData ? data.state_id : "",
      address1: userData ? data.address1 : "",
      password: userData ? data.password : "",
      phone: userData ? data.phone : "",
      image_url: userData ? data.image_url : "",
      logo_url: userData ? data.logo_url : "",
      banner_image_url: userData ? data.banner_image_url : "",
      video_url: userData ? data.UserClients[0].video_url : "",
      video_desc: userData ? data.UserClients[0].video_desc : "",
    };

    this.setState({
      formData: setFormData,
      imagePreviewUrl: setFormData.image_url,
      bannerPreviewUrl: setFormData.banner_image_url,
    });
  };

  handleChange = (e) => {
    let formData = this.state.formData;
    let password = {},
      showPasswordValidation = false;
    let addPassword = this.props.edit ? validateUpdateFields : validateFields;

    if (
      formData.password ||
      formData.existing_password ||
      formData.confirm_password ||
      !this.props.edit
    ) {
      addPassword = [
        "password",
        "existing_password",
        "confirm_password",
        ...addPassword,
      ];
    }

    formData = Utility.validateAndReturnFormData(
      this,
      formData,
      e,
      addPassword,
      this.state.formName
    );

    if (e.target.name === "country_id") {
      this.getStateByCountry(e.target.value);
    }

    if (!Utility.isEmpty(formData.password)) {
      password = Utility.validatePassword(formData.password);
      showPasswordValidation = true;
    }

    if (e.target.name === "password" || e.target.name === "confirm_password")
      this.validatePassword(e.target.name);

    this.setState({ formData, showPasswordValidation, password });
  };

  validatePassword = (type) => {
    const password = Utility.getElementById("password");
    const confirmPassword = Utility.getElementById("confirm_password");
    const error = Utility.getElementById(`${"confirm_password"}Error`);

    if (!Utility.isEmpty(confirmPassword.value)) {
      if (password.value === confirmPassword.value) {
        error.textContent = null;
        return;
      }
    } else {
      error.textContent = null;
      return;
    }

    if (
      type === "confirm_password" ||
      (!Utility.isEmpty(confirmPassword.value) && type === "password")
    ) {
      error.textContent = `Confirm password doesn't match`;
    }
  };

  getAllCountries = () => {
    Utility.sendRequest(
      COUNTRIES,
      1,
      { limit: 300, order: "name" },
      (err, response, body) => {
        body = JSON.parse(body);
        if (!body.error) {
          body.data.unshift({ id: "", name: "Country" });

          this.setState({ countries: body.data });

          // const US_id = body.data.find(
          //   (element) => element.name === "United States"
          // ).id;
          // this.setState({ countryId: US_id });
          // if (US_id) {
          //   this.getStateByCountry(US_id);
          // }
        } else {
          toast.error(JSON.parse(body).error.message, {
            containerId: "private",
          });
        }
      }
    );
  };

  handleClick = (event) => {
    this.setState({ MenuRender: event.currentTarget });
  };

  handleClose = () => {
    this.setState({ MenuRender: null });
  };

  getStateByCountry = (countryId) => {
    Utility.sendRequest(
      STATES,
      1,
      { country_id: countryId, limit: 200, order: "name" },
      (err, response, body) => {
        body = JSON.parse(body);

        if (!body.error) {
          body.data.unshift({ id: "", name: "State" });

          this.setState({ states: body.data });
        } else {
          toast.error(JSON.parse(body).error.message, {
            containerId: "private",
          });
        }
      }
    );
  };

  handleFileChange = (inputId, previewURL, file, name) => {
    const formData = { ...this.state.formData };
    if (name === "image_url") {
      this.setState(
        { imagePreviewUrl: previewURL, imageFile: file, formData },
        () => {
          Utility.isFilePathEmpty(
            "image_url",
            this.state.imagePreviewUrl,
            this.state.formName
          );
        }
      );
    } else if (name === "banner_image_url") {
      this.setState(
        { bannerPreviewUrl: previewURL, bannerFile: file, formData },
        () => {
          Utility.isFilePathEmpty(
            "image_url",
            this.state.bannerPreviewUrl,
            this.state.formName
          );
        }
      );
    }
  };

  removeUploadedFile = (inputId, name, e) => {
    e.preventDefault();
    if (name === "image_url") {
      const formData = { ...this.state.formData };
      formData.image_url = "";
      this.setState({ imageFile: "", imagePreviewUrl: "", formData });
    } else if (name === "banner_image_url") {
      this.setState({ bannerFile: "", bannerPreviewUrl: "" });
    }
  };

  uploadFiles = async (id, imageSignedURL, bannerSignedURL) => {
    let imageURL = this.state.imagePreviewUrl;
    let bannerURL = this.state.bannerPreviewUrl;

    if (this.state.imageFile && !Utility.isEmpty(this.state.imageFile.size)) {
      imageURL = await Utility.uploadFile(this.state.imageFile, imageSignedURL);
    }

    if (!Utility.isEmpty(this.state.bannerFile.size)) {
      bannerURL = await Utility.uploadFile(
        this.state.bannerFile,
        bannerSignedURL
      );
    }

    this.updateClientLogoUrl(id, imageURL, bannerURL);
  };

  updateClientLogoUrl = (id, imageURL, bannerURL) => {
    const params = {
      id,
      image_url: imageURL,
      logo_url: imageURL,
      banner_image_url: bannerURL,
    };

    this.setState({ logoUrl: imageURL });

    Utility.sendRequest(CLIENTS, 3, params, (err, res, body) => {
      body = JSON.parse(body);

      if (body.error) {
        toast.error(body.error.message, { containerId: "private" });
      } else {
        if (this.props.edit) this.apiCalling(this.props.edit);
      }
    });
  };

  createSignUpParams = (formData) => {
    return {
      first_name: formData.first_name,
      last_name: formData.last_name,
      website: formData.website,
      name: formData.name || formData.first_name + " " + formData.last_name,
      city: formData.city,
      email: formData.email,
      zip_code: formData.zip_code,
      parent_id: this.props.forCityAccount ? null : CLIENT_ID,
      country_id: formData.country_id || this.state.countryId,
      suite: formData.suite,
      state_id: formData.state_id,
      heading: formData.headline,
      bio_details: formData.about,
      gender: formData.gender,
      address1: formData.address1,
      password: formData.password,
      phone: formData.phone,
      user_id: this.props.userData.user_id,
      source: "web",
      imageFile: this.state.imageFile,
      bannerFile: this.state.bannerFile,
      is_active: this.props.forCityAccount ? true : false,
    };
  };

  updateNeighborhoodParams = (formData) => {
    return {
      first_name: formData.first_name,
      last_name: formData.last_name,
      website: formData.website,
      name: formData.name || formData.first_name + " " + formData.last_name,
      city: formData.city,
      email: formData.email,
      heading: formData.headline,
      bio_details: formData.about,
      zip_code: formData.zip_code,
      suite: formData.suite,
      country_id: formData.country_id,
      state_id: formData.state_id,
      address1: formData.address1,
      gender: formData.gender,
      password: formData.password,
      phone: formData.phone,
      phone_cellular: formData.phone,
      existing_password: formData.existing_password,
      imageFile: this.state.imageFile,
      bannerFile: this.state.bannerFile,
      video_url: formData.video_url,
      video_desc: formData.video_desc,
    };
  };

  updateCitizenParams = (formData) => {
    const mobileNo = !Utility.isEmpty(formData.country_code)
      ? formData.phone_cellular.slice(
          formData.country_code.length,
          formData.phone_cellular.length
        )
      : undefined;
    //HERE COME BACK
    return {
      first_name: formData.first_name,
      last_name: formData.last_name,
      city: formData.city,
      email: formData.email,
      zip_code: formData.zip_code,
      country_id: formData.country_id,
      heading: formData.headline,
      bio_details: formData.about,
      state_id: formData.state_id,
      address1: formData.address1,
      gender: formData.gender,
      password: formData.password,
      phone: formData.phone ? formData.phone : mobileNo,
      phone_cellular: formData.phone ? formData.phone : mobileNo,
      country_code: formData.country_code,
      existing_password: formData.existing_password,
      imageFile: this.state.imageFile,
      bannerFile: this.state.bannerFile,
      website: formData.website,
    };
  };

  signUp = (params) => {
    Utility.sendRequest(
      NEIGHBORHOOD_SIGN_UP,
      2,
      params,
      async (error, response, body) => {
        body = JSON.parse(body);

        this.setState({ spinner: false });

        if (!body.error) {
          this.setState({ spinner: true });
          if (
            (!Utility.isEmpty(this.state.imagePreviewUrl) &&
              body.imageSignedURL) ||
            (!Utility.isEmpty(this.state.bannerPreviewUrl) &&
              body.bannerSignedURL)
          ) {
            await this.uploadFiles(
              body.UserClients[0].id,
              body.imageSignedURL,
              body.bannerSignedURL
            );
          }

          const verifyCodeParams = {
            email: body.email,
            client_id: this.props.forCityAccount ? body.id : body.parent_id,
            for_city_account: this.props.forCityAccount,
            phone_cellular: this.state.formData.phone,
            country_code: this.state.formData.country_code,
          };

          const resendVerificationParams = {
            email: body.email,
            client_id: body.id,
          };

          body.image_url = this.state.logoUrl;
          this.setState({
            isUserRegistered: true,
            verifyCodeParams,
            resendVerificationParams,
            userClientId: body.UserClients[0].id,
            newUserClientDetails: body,
          });
          this.setState({ spinner: false });
          this.startChat(
            body.UserClients[0],
            `Hi ${body.name}. What idea do you want to scale?`
          );
        } else {
          toast.error(body.error.message, { containerId: "public" });
        }
      }
    );
  };

  getAllAccount = () => {
    // this.props.showLoader(true);

    return new Promise((resolve, reject) => {
      Utility.sendRequest(GET_ACCOUNTS, 1, {}, (error, response, body) => {
        body = JSON.parse(body);

        // this.props.showLoader(false);

        if (!body.error) {
          this.props.updateAccountList(body);
          resolve(true);
        } else {
          toast.error(body.error.message, { containerId: "public" });
          reject(false);
        }
      });
    });
  };

  updateClientDetails = (params) => {
    Utility.sendRequest(CLIENTS, 3, params, async (error, response, body) => {
      body = JSON.parse(body);

      this.setState({ spinner: true });

      if (!body.error) {
        if (
          !Utility.isEmpty(this.state.imagePreviewUrl && body.imageSignedURL) ||
          !Utility.isEmpty(this.state.bannerPreviewUrl && body.bannerSignedURL)
        ) {
          await this.uploadFiles(
            this.props.userData.id,
            body.imageSignedURL,
            body.bannerSignedURL
          );
        } else {
          this.apiCalling(true);
        }

        this.setState({ spinner: false });

        toast.success(body.message, { containerId: "public" });
      } else {
        toast.error(body.error.message, { containerId: "public" });
      }
    });
  };

  handleFormErrors = (fields) => {
    const isValid = Utility.showFormErrors(this, fields, this.state.formName);
    let validatePassword = true;
    if (fields.includes("password")) {
      if (!Utility.isEmpty(this.state.password)) {
        validatePassword = Object.values(this.state.password).map((value) => {
          return value;
        });
        if (validatePassword.includes(false)) validatePassword = false;
      }
    }

    return isValid && validatePassword ? true : false;
  };
  submit = async (e) => {
    e.preventDefault();
    // this.props.addGodSelectedQualities(
    //   "essay",
    //   this.state.essayValue,
    //   "LeaderQuality"
    // );
    const formData = Utility.deepClone(this.state.formData);
    let params = {};
    let addPassword = this.props.edit ? validateUpdateFields : validateFields;
    // const prevNumber = !Utility.isEmpty(this.props.userData.country_code) ? `${this.props.userData.country_code}${this.props.userData.phone_cellular}` : '';
    let prevNumber = !Utility.isEmpty(this.props.userData.country_code)
      ? `${this.props.userData.phone_cellular}`
      : "";
    prevNumber = prevNumber.replace(" ", "");

    if (
      formData.password ||
      formData.existing_password ||
      formData.confirm_password ||
      !this.props.edit
    ) {
      addPassword = [
        "password",
        "existing_password",
        "confirm_password",
        ...addPassword,
      ];
    }

    const isFormValid = this.handleFormErrors(addPassword);

    if (!isFormValid && !this.props.edit) {
      toast.error("Fill all the necessary details", { containerId: "public" });
      return;
    }

    if (formData.password !== formData.confirm_password) {
      toast.error(`Confirm password doesn't match`, { containerId: "public" });
      return;
    }

    this.setState({ spinner: true });

    if (!this.props.edit) {
      params = this.createSignUpParams(formData);
      let newParams = {};
      if (!formData.country_id) {
        let { country_id, state_id, ...params1 } = params;
        newParams = { ...params1 };
      } else if (formData.country_id && !formData.state_id) {
        let { state_id, ...params2 } = params;
        newParams = { ...params2 };
      } else {
        newParams = { ...params };
      }
      this.signUp(newParams);
    } else {
      let tempQualities = [...this.props.selectedGodQualities];
      this.state.leaderQuilities.map((value) => {
        tempQualities.push({
          id: value.value,
          title: value.name,
          section: value.section,
        });
      });
      this.state.organizationQualities.map((value) => {
        tempQualities.push({
          id: value.value,
          title: value.name,
          section: value.section,
        });
      });
      this.setNewSelected([]);
      this.setNewSelectedLeaderShip([]);
      this.props.setUserQualities(tempQualities);
      // console.log(tempQualities);
      if (ROLE_IDS.USER !== this.props.neighborhoodState.roleId) {
        params = this.updateNeighborhoodParams(formData);
        params["neighborUserClientId"] = this.props.userData.id;
        if (
          this.state.isVideoUpload === true &&
          this.state.videoFilePreview !== null &&
          this.state.videoFilePreview !== undefined
        ) {
          const url = await uploadMedia(this.state.videoFilePreview);
          params = { ...params, video_url: url };
        }
        let newUserParams = {};
        if (!formData.country_id) {
          let { country_id, state_id, ...userParams1 } = params;
          newUserParams = { ...userParams1 };
        } else if (formData.country_id && !formData.state_id) {
          let { state_id, ...userParams2 } = params;
          newUserParams = { ...userParams2 };
        } else {
          newUserParams = { ...params };
        }
        this.updateClientDetails(newUserParams);
      } else {
        if (formData.phone_cellular) {
          if (
            formData.phone_cellular &&
            formData.phone_cellular !== prevNumber
          ) {
            if (!this.state.is_mobile_verified) {
              this.setState({ spinner: false });
              toast.error("Click on update to verify your new number", {
                containerId: "public",
              });
              return;
            }
          }
        }
        params = this.updateCitizenParams(formData);
        let newCitizenParams = {};
        if (!formData.country_id) {
          let { country_id, state_id, ...citizenParams1 } = params;
          newCitizenParams = { ...citizenParams1 };
        } else if (formData.country_id && !formData.state_id) {
          let { state_id, ...citizenParams2 } = params;
          newCitizenParams = { ...citizenParams2 };
        } else {
          newCitizenParams = { ...params };
        }

        this.updateCitizenData(newCitizenParams);
      }
    }
  };

  showConfirmationScreen = () => {
    this.apiCalling();
    if (this.props.forCityAccount) {
      this.props.setCityFormData(this.state.formData);
      this.props.closeForm();
      this.props.showPurchaseCityAccount(this.state.newUserClientDetails);
    } else this.setState({ showConfirmationScreen: true });
  };

  switchAccount = () => {
    Utility.sendRequest(
      SWITCH_ACCOUNT,
      1,
      { id: this.state.userClientId },
      (error, response, body) => {
        body = JSON.parse(body);

        if (body.error) {
          toast.error(body.error.message, { containerId: "public" });
        } else {
          cookie.save("sessionToken", body.token, { path: "/" });

          Utility.sendRequest(
            USER_AUTH,
            1,
            { id: this.state.userClientId },
            (error, response, body) => {
              body = JSON.parse(body);

              // this.props.showLoader(false);

              if (!body.error) {
                this.props.updateUserData(body);
                this.props.history.push("/profile");
                this.props.closeForm();
              } else {
                toast.error(body.error.message, { containerId: "public" });
              }
            }
          );
        }
      }
    );
  };

  getUpdatedUserDetails = () => {
    return new Promise((resolve, reject) => {
      Utility.sendRequest(USER_AUTH, 1, {}, (error, response, body) => {
        body = JSON.parse(body);

        // this.props.showLoader(false);

        if (!body.error) {
          this.props.updateUserData(body);
          resolve(true);
        } else {
          reject(false);
          toast.error(body.error.message, { containerId: "public" });
        }
      });
    });
  };

  setValue = (value) => {
    const element = Utility.getElementById("country_code_error");
    if (element) element.textContent = "";

    const formData = { ...this.state.formData };
    formData.phone_cellular = value;

    if (!Utility.isEmpty(value)) {
      const data = parsePhoneNumber(value);

      if (data) {
        const error = Utility.getElementById("phone_cellular_error");
        if (error) error.textContent = "";

        formData.country_code = `+${data.countryCallingCode}`;

        this.setState({ formData });
      }
    }
  };

  backToForm = () => {
    this.setState({
      isUserRegistered: false,
      showConfirmationScreen: false,
      is_mobile_verified: true,
      verifyPhoneNumber: false,
    });
  };

  verifyPhoneNumber = () => {
    const verifyCodeParams = {
      phone_cellular: this.state.formData.phone_cellular.slice(
        this.state.formData.country_code.length,
        this.state.formData.phone_cellular.length
      ),
      country_code: this.state.formData.country_code,
      client_id: CLIENT_ID,
      email: this.state.formData.email,
      updatePhoneNumber: true,
    };
    const resendVerificationParams = {
      phone_cellular: this.state.formData.phone_cellular.slice(
        this.state.formData.country_code.length,
        this.state.formData.phone_cellular.length
      ),
      country_code: this.state.formData.country_code,
      client_id: CLIENT_ID,
      email: this.state.formData.email,
    };

    Utility.sendRequest(
      SEND_VERIFICATION_CODE,
      3,
      verifyCodeParams,
      async (error, response, body) => {
        body = JSON.parse(body);

        if (!body.error) {
          this.setState({
            verifyPhoneNumber: true,
            verifyCodeParams,
            resendVerificationParams,
          });
        } else {
          toast.error(body.error.message, { containerId: "public" });
        }
      }
    );
  };

  uploadUserFiles = async (id, imageSignedURL, bannerSignedURL) => {
    let imageURL = this.state.imagePreviewUrl;
    let bannerURL = this.state.bannerPreviewUrl;

    if (
      this.state.imageFile &&
      !Utility.isEmpty(this.state.imageFile.size) &&
      !Utility.isEmpty(imageSignedURL)
    ) {
      imageURL = await Utility.uploadFile(this.state.imageFile, imageSignedURL);
    }

    if (
      !Utility.isEmpty(this.state.bannerFile.size) &&
      !Utility.isEmpty(bannerSignedURL)
    ) {
      bannerURL = await Utility.uploadFile(
        this.state.bannerFile,
        bannerSignedURL
      );
    }

    this.updateUserLogoUrl(id, imageURL, bannerURL);
  };

  updateUserLogoUrl = (id, imageURL, bannerURL) => {
    const params = {
      id,
      profile_image_url: imageURL,
      banner_image_url: bannerURL,
    };

    Utility.sendRequest(USERS, 3, params, async (err, res, body) => {
      body = JSON.parse(body);

      if (body.error) {
        toast.error(body.error.message, { containerId: "private" });
      } else {
        this.apiCalling(true);
      }
    });
  };

  updateCitizenData = (params) => {
    Utility.sendRequest(USERS, 3, params, async (error, response, body) => {
      body = JSON.parse(body);
      this.setState({ spinner: true });
      if (!body.error) {
        if (
          !Utility.isEmpty(this.state.imagePreviewUrl && body.imageSignedURL) ||
          !Utility.isEmpty(this.state.bannerPreviewUrl && body.bannerSignedURL)
        ) {
          await this.uploadUserFiles(
            this.props.userData.id,
            body.imageSignedURL,
            body.bannerSignedURL
          );
        } else {
          this.apiCalling(true);
        }

        this.setState({ spinner: false });

        toast.success(body.message, { containerId: "public" });
      } else {
        toast.error(body.error.message, { containerId: "public" });
      }
    });
  };

  apiCalling = async (closeForm) => {
    let next;

    await this.getAllAccount()
      .then((success) => {
        next = success;
      })
      .catch((error) => {
        next = error;
      });
    await this.getUpdatedUserDetails()
      .then((success) => {
        next = success;
      })
      .catch((error) => {
        next = error;
      });
    this.props.userProfileCall();
    if (closeForm) this.props.closeForm();
  };

  removeStrengthQualities = (id) => {
    let tempQualities = this.state.selectedStrengthQualitites;
    if (tempQualities.includes(id))
      tempQualities = tempQualities.filter((data) => data !== id);
    this.setState({
      selectedStrengthQualitites: tempQualities,
    });
  };

  updateUserSelectedQualities = () => {};

  sendMessage = (supportUser, message, friend) => {
    if (!message || message.trim() === "") return;

    const senderPrivateKey = supportUser.private_key;
    const receiverPrivateKey = friend.private_key;

    const encryptedMessage = Utility.encryptedMessage(
      message,
      senderPrivateKey,
      receiverPrivateKey
    );

    const params = {
      from_user_client_id: supportUser.id,
      to_user_client_id: friend.id,
      new_message: this.props.chatPrivateKey === "",
      media: "",
      message: encryptedMessage,
      user_id: supportUser.user_id,
      source: friend.isAudience ? "audience" : "network",
      chat_private_key: senderPrivateKey,
    };

    Utility.sendRequest(MESSAGES, 2, params, (error, response, body) => {
      body = JSON.parse(body);

      if (body.error) {
        toast.error(body.error.message, { containerId: "private" });
      } else {
        body.message = message;
      }
    });
  };
  setSelectedLeaderShip = (value) => {
    this.setState({ leaderQuilities: value });
  };

  setNewSelected = (value) => {
    this.setState({ newSelected: value });
  };
  setNewSelectedLeaderShip = (value) => {
    this.setState({ newSelectedLeaderShip: value });
  };
  setSelectedOrganization = (value) => {
    this.setState({ organizationQualities: value });
  };
  startChat = (userClientObj, message) => {
    if (!Utility.isEmpty(this.props.supportUsers)) {
      this.sendMessage(this.props.supportUsers[0], message, userClientObj);
    }
  };
  paintAccountType = () => {
    if (ROLE_IDS.USER === this.props.neighborhoodState.roleId) return "Citizen";
    else if (ROLE_IDS.CLIENT_OWNER === this.props.neighborhoodState.roleId)
      return "Neighborhood";
    else if (ROLE_IDS.PARENT_OWNER === this.props.neighborhoodState.roleId)
      return "City";
    else if (this.props.isNeighborhoodFormOpen === true) return "Neighborhood";
    else return "City";
  };
  setCropping = (bool) => {
    this.setState({
      isCropping: bool,
    });
  };

  render() {
    const userData = this.props.userData;
    const profileImgUrl = !Utility.isEmpty(this.props.userData)
      ? this.props.userData.profile_image_url
      : "";
    const cityImgUrl = !Utility.isEmpty(userData) ? userData.city_logo_url : "";
    const logoUrl = this.state.logoUrl;
    let countryName = "";

    if (this.state.formData.country_id) {
      this.state.countries.forEach((item) => {
        if (item.id === this.state.formData.country_id) countryName = item.name;
      });
    }
    return (
      <>
        <div className="col-5 px-0 position-fixed create-campaign-margin bg-white w-100 EditProfile-page-sidebar-outer gil-pwa-callout pwa-account-callout">
          <div className="EditProfile-page-sidebar-head align-items-center">
            <HollowAvatar />
            <span className="font-bold display-8 ml-2">Manage Profile</span>

            <div
              // onClick={this.props.closeForm}
              className="ml-auto d-flex align-items-center cursor-pointer"
            >
              <button
                onClick={this.submit}
                className="btn btn-primary mr-3 CourseHeaderBtn"
                disabled={this.state.spinner}
              >
                {this.state.spinner ? (
                 <Spinner animation="border" role="status" />
                ) : (
                  "Update Profile"
                )}
              </button>
              <div onClick={this.props.closeForm}>
                <CloseIcon />
              </div>
            </div>
          </div>
          <div
            style={{ flexDirection: "column" }}
            className="EditProfile-page-sidebar-body d-flex align-items-center justify-content-center"
          >
            <div className="EditProfile-page-sidebar-form-container">
              <div className="EditProfile-page-profileImage-container">
                <div className="profileMainContainer h-100 position-relative">
                  {!Utility.isEmpty(this.state.bannerPreviewUrl) ? (
                    <>
                      <img
                        src={this.state.bannerPreviewUrl}
                        className="profile-page-banner-container"
                      />
                      <div
                        onClick={this.handleClick}
                        className="remove-profile-banner cursor-pointer"
                      >
                        <MoreDropWhite />
                      </div>
                      <Menu
                        id="basic-menu"
                        anchorEl={this.state.MenuRender}
                        open={Boolean(this.state.MenuRender)}
                        onClose={this.handleClose}
                        MenuListProps={{
                          "aria-labelledby": "basic-button",
                        }}
                        style={{ zIndex: 2100 }}
                      >
                        <MenuItem>
                          <NewUploadFile
                            name="banner_image_url"
                            inputId="image_url"
                            purposeFor="change"
                            removeText="Remove image"
                            setCropping={this.setCropping}
                            categoryName="user_banner_image"
                            fromEditUser={true}
                            acceptTypes="image/x-png,image/gif,image/jpeg"
                            uploadedFilePreviewUrl={this.state.bannerPreviewUrl}
                            handleFileChange={this.handleFileChange}
                            removeUploadedFile={this.removeUploadedFile}
                          />
                        </MenuItem>
                        <MenuItem
                          className="p-2"
                          onClick={(e) =>
                            this.removeUploadedFile(
                              "image_url",
                              "banner_image_url",
                              e
                            )
                          }
                        >
                          Remove Image
                        </MenuItem>
                      </Menu>
                    </>
                  ) : (
                    <div className="No-banner-Container pt-3 pl-3">
                      <div className=" d-flex w-fit flex-column cursor-pointer align-items-center justify-content-center">
                        <NewUploadFile
                          name="banner_image_url"
                          inputId="image_url"
                          removeText="Remove image"
                          categoryName="user_banner_image"
                          setCropping={this.setCropping}
                          fromEditUser={true}
                          acceptTypes="image/x-png,image/gif,image/jpeg"
                          uploadedFilePreviewUrl={this.state.bannerPreviewUrl}
                          handleFileChange={this.handleFileChange}
                          removeUploadedFile={this.removeUploadedFile}
                        />
                      </div>
                    </div>
                  )}
                  <div className="profile-page-userAvatar-container">
                    {!Utility.isEmpty(this.state.imagePreviewUrl) ? (
                      <>
                        <Image
                          className="rounded-circle w-fit profile-page-userImage"
                          src={this.state.imagePreviewUrl}
                        />
                        <RemoveUploadedFile
                          inputId="image_url"
                          removeText={"Remove Profile"}
                          removeUploadedFile={this.removeUploadedFile}
                          name={"image_url"}
                        />
                      </>
                    ) : (
                      <div className="No-profile-container  d-flex flex-column align-items-center justify-content-center">
                        <NewProfileUploadFile
                          name="image_url"
                          inputId="image_url"
                          removeText="Remove image"
                          categoryName="neighborhood_branc_image"
                          acceptTypes="image/x-png,image/gif,image/jpeg"
                          handleFileChange={this.handleFileChange}
                          uploadedFilePreviewUrl={this.state.imagePreviewUrl}
                          removeUploadedFile={this.removeUploadedFile}
                          setCropping={this.setCropping}
                          fromEditUser={true}
                        />
                      </div>
                    )}
                  </div>
                </div>
              </div>
              <div className="edit-profile-formContainer">
                <div className="edit-profile-formContainer-form">
                  <p className="edit-profile-formContainer-form-form_heading">
                    Basic Information
                  </p>
                  <div className="edit-profile-formContainer-form-fields-container">
                    {this.props.userData.role_id === ROLE_IDS.USER ? (
                      <>
                        <div className="d-flex">
                          <div className="w-50 p-r-20">
                            <InputField
                              required={true}
                              type="text"
                              className="field-common-styling"
                              name="first_name"
                              ref="first_name"
                              value={this.state.formData.first_name}
                              placeholder="First Name"
                              handleChange={this.handleChange}
                            />
                          </div>
                          <div className="w-50 p-l-20">
                            <InputField
                              required={true}
                              type="text"
                              className="field-common-styling"
                              ref="last_name"
                              name="last_name"
                              value={this.state.formData.last_name}
                              placeholder="Last Name"
                              handleChange={this.handleChange}
                            />
                          </div>
                        </div>
                        <div className="w-full mt-15">
                          <InputField
                            required={true}
                            type="text"
                            className="field-common-styling w-100"
                            name="alias_name"
                            ref="alias_name"
                            value={this.state.formData.alias_name}
                            placeholder="Alias Name (To protect your Identity)"
                            handleChange={this.handleChange}
                          />
                        </div>
                        <div className="w-100 mt-15">
                          <DropDown
                            id="gender"
                            required={true}
                            optionId="value"
                            optionValue="name"
                            name="gender"
                            className="field-common-styling w-100"
                            ref="gender"
                            value={this.state.formData.gender}
                            options={this.state.gender}
                            handleChange={this.handleChange}
                          />
                        </div>
                      </>
                    ) : (
                      <>
                        <div className="w-100">
                          <InputField
                            required={true}
                            type="text"
                            className="field-common-styling"
                            name="name"
                            ref="name"
                            value={this.state.formData.name}
                            placeholder="Community's Name"
                            handleChange={this.handleChange}
                          />
                        </div>
                      </>
                    )}
                    <div className="w-full mt-15">
                      <DropDown
                        id="region"
                        required={true}
                        optionId="value"
                        optionValue="name"
                        name="region"
                        className="field-common-styling w-100"
                        ref="region"
                        value={this.state.formData.region}
                        options={this.state.region}
                        handleChange={this.handleChange}
                      />
                    </div>

                    <div className="w-full mt-15">
                      <InputField
                        required={true}
                        type="text"
                        className="field-common-styling w-100"
                        name="address1"
                        ref="address1"
                        value={this.state.formData.address1}
                        placeholder="Street Address"
                        handleChange={this.handleChange}
                      />
                    </div>
                    {/* <div className="w-full mt-15">
                      <InputField
                        required={true}
                        type="text"
                        className="field-common-styling w-100"
                        name="suite"
                        ref="suite"
                        value={this.state.formData.suite}
                        placeholder="Street Address"
                        handleChange={this.handleChange}
                      />
                    </div> */}
                    <div className="w-full mt-15">
                      <DropDown
                        id="country_id"
                        required={true}
                        optionId="id"
                        optionValue="name"
                        name="country_id"
                        ref="country_id"
                        value={
                          this.state.formData.country_id
                            ? this.state.formData.country_id
                            : this.state.countryId
                        }
                        options={this.state.countries}
                        handleChange={this.handleChange}
                        className="field-common-styling w-100"
                      />
                    </div>
                    <div className="d-flex mt-15">
                      <div className="w-50 pr-2">
                        <InputField
                          required={true}
                          type="text"
                          className="field-common-styling w-100"
                          name="city"
                          ref="city"
                          value={this.state.formData.city}
                          placeholder="City"
                          handleChange={this.handleChange}
                        />
                      </div>
                      <div className="w-50 pl-2">
                        <DropDown
                          id="state_id"
                          required={true}
                          optionId="id"
                          optionValue="name"
                          name="state_id"
                          className="field-common-styling w-100"
                          ref="state_id"
                          value={this.state.formData.state_id}
                          options={this.state.states}
                          handleChange={this.handleChange}
                        />
                      </div>
                    </div>
                    <div className="w-50 mt-15 pr-2">
                      <InputField
                        required={true}
                        type="text"
                        className="field-common-styling w-100"
                        name="zip_code"
                        ref="zip_code"
                        value={this.state.formData.zip_code}
                        onKeyPress={Utility.handleAlphaNumeric}
                        handleChange={this.handleChange}
                        placeholder="Zip Code"
                      />
                    </div>
                    <p className="edit-profile-formContainer-form-form_heading mt-30">
                      Personal Information
                    </p>
                    <div className="w-full mt-15">
                      <InputField
                        required={true}
                        type="text"
                        className="field-common-styling w-100"
                        name="headline"
                        ref="headline"
                        value={this.state.formData.headline}
                        placeholder="Headline"
                        handleChange={this.handleChange}
                      />
                    </div>
                    <div className="w-full mt-15">
                      <Textarea
                        required={true}
                        type="text"
                        className="field-common-styling w-100 field-common-text-area"
                        name="about"
                        ref="about"
                        value={this.state.formData.about}
                        placeholder="About"
                        handleChange={this.handleChange}
                      />
                    </div>
                    {/* <div className="d-flex align-items-center">
                      <div className="field-common-check-styling d-flex align-items-center mt-3">
                        <input type="checkbox" id="checkbox" />
                        <label for="checkbox"></label>
                        <div className="field-common-check-label ml-4">
                          Yes, keep my profile PRIVATE
                        </div>
                      </div>
                    </div> */}
                    <p className="edit-profile-formContainer-form-form_heading mt-30">
                      Registration Information
                    </p>
                    <div className="w-full mt-15">
                      <InputField
                        required={true}
                        className="field-common-styling w-100"
                        type="email"
                        name="email"
                        ref="email"
                        placeholder="Email"
                        value={this.state.formData.email}
                        handleChange={this.handleChange}
                      />
                    </div>
                    <div className="w-full mt-15">
                      <InputField
                        required={true}
                        type="text"
                        className="field-common-styling w-100"
                        ref="phone"
                        name="phone"
                        value={this.state.formData.phone}
                        placeholder="Phone Number"
                        handleChange={this.handleChange}
                        onKeyPress={Utility.handleNumber}
                      />
                    </div>
                    {/* <div className="d-flex align-items-center">
                      <div className="w-50 p-r-20">
                        <InputField
                          required={true}
                          className="field-common-styling w-100"
                          id="password"
                          type="password"
                          name="password"
                          ref="password"
                          placeholder={"Password"}
                          handleChange={this.handleChange}
                        />
                      </div>
                      <div className="w-50 p-l-20">
                        <InputField
                          required={true}
                          className="field-common-styling w-100"
                          type="password"
                          id="confirm_password"
                          name="confirm_password"
                          ref="confirm_password"
                          placeholder={
                            this.props.edit
                              ? "Confirm New Password..."
                              : "Confirm Password..."
                          }
                          handleChange={this.handleChange}
                        />
                      </div>
                    </div>

                  */}

                    {this.props.userData.network === "clients" && (
                      <>
                        <p className="edit-profile-formContainer-form-form_heading mt-30">
                          Introduction
                        </p>
                        <div className="w-full mt-15">
                          <Textarea
                            required={true}
                            type="text"
                            className="field-common-styling w-100 field-common-text-area"
                            name="video_desc"
                            ref="video_desc"
                            value={this.state.formData.video_desc}
                            placeholder="Video Description"
                            handleChange={this.handleChange}
                          />
                        </div>

                        <VideoUpload
                          isVideoUpload={this.state.isVideoUpload}
                          setIsVideoUpload={(value) => {
                            this.setState({ isVideoUpload: value });
                          }}
                          setVideoFile={(value) => {
                            this.setState({ videoFilePreview: value });
                          }}
                          videoFile={this.state.videoFilePreview}
                          video_url={this.state.formData.video_url}
                        />
                      </>
                    )}

                    {/* {userData.network === "users" && (
                      <>
                        <p className="edit-profile-formContainer-form-form_heading mt-30">
                          How is God calling you to grow as a person and a
                          leader?
                        </p>
                        <div className="w-full mt-15">
                          <InputField
                            required={true}
                            className="field-common-styling w-100"
                            type="text"
                            name="LeaderQuality"
                            // ref="email"
                            // placeholder="Email"
                            value={this.state.essayValue}
                            handleChange={(e) =>
                              this.setState({
                                essayValue: e.target.value,
                              })
                            }
                          />
                        </div>
                      </>
                    )}
                    {userData.network !== "users" && (
                      <>
                        <p className="edit-profile-formContainer-form-form_heading mt-30">
                          How do you want to see your organization and community
                          grow?
                        </p>
                        <div className="w-full mt-15">
                          <InputField
                            required={true}
                            className="field-common-styling w-100"
                            type="text"
                            name={"CommunityGrowQuality"}
                            value={this.state.essayValue}
                            handleChange={(e) =>
                              this.setState({
                                essayValue: e.target.value,
                              })
                            }
                          />
                        </div>
                      </>
                    )} */}

                    {/* <p className="edit-profile-formContainer-form-form_heading !fw-light mt-30">
                      You registered a Citizens account on{" "}
                      <b>{`</July 31, 2022>.`}</b>
                    </p>
                    <p className="edit-profile-formContainer-form-form_heading !fw-light mt-30">
                      You are a Curator or Admin for following communities.
                    </p>
                    {[
                      {
                        profile: require("assets/images/profile/AngolaProfile.png"),
                        heading: "News Name Goes Here",
                        region: "Start of description copy goes here…",
                        isLike: true,
                      },
                      {
                        profile: require("assets/images/profile/AngolaProfile.png"),
                        heading: "News Name Goes Here",
                        region: "Start of description copy goes here…",
                        isLike: true,
                      },
                      {
                        profile: require("assets/images/profile/AngolaProfile.png"),
                        heading: "News Name Goes Here",
                        region: "Start of description copy goes here…",
                        isLike: false,
                      },
                    ].map((data, index) => {
                      return (
                        <div className="mt-3 mx-3">
                          <InforCard
                            ImageSection={data.profile}
                            Heading={data.heading}
                            subHeading={`${data.region}`}
                            isLike={data.isLike}
                            leftSectionId="close"
                          />
                        </div>
                      );
                    })} */}
                  </div>
                </div>
              </div>
            </div>
            <div
              className={`AboutMainContainer ff-Inter-Bold d-flex flex-column ${
                Utility.pwaModeEnabled() ? "w-90" : "w-60"
              }`}
            >
              <>
                <p className="edit-profile-formContainer-form-form_heading mt-30">
                  {userData.role_id === ROLE_IDS.USER ||
                  userData.role_id === ROLE_IDS.PARENT_OWNER
                    ? "How do you describe God's calling in your life?"
                    : "What is the mission of the organization?"}
                </p>
                <div className="w-full mt-15">
                  <InputField
                    required={true}
                    className="field-common-styling w-100"
                    type="text"
                    name="LeaderQuality"
                    // ref="email"
                    // placeholder="Email"
                    value={this.state.essayValue}
                    handleChange={(e) => {
                      this.setState({
                        essayValue: e.target.value,
                      });
                      if (
                        this.props.selectedGodQualities.find(
                          (item) => item.section === "LeaderQuality"
                        )
                      ) {
                        this.props.selectedGodQualities.find(
                          (item) => item.section === "LeaderQuality"
                        ).title = e.target.value;
                      } else {
                        this.props.selectedGodQualities.push({
                          id: "10",
                          title: e.target.value,
                          section: "LeaderQuality",
                        });
                      }
                    }}
                  />
                </div>
              </>
            </div>

            <div className="d-flex flex-column align-items-center w-100">
              <GodCalling
                allQualities={this.props.GodQualities.filter(
                  (item) =>
                    !this.props.selectedGodQualities.some(
                      (obj) =>
                        obj.id === item.id &&
                        obj.title === item.title &&
                        obj.section === "Leader qualities"
                    )
                )}
                selectedValue={this.state.leaderQuilities}
                setSelectedValue={this.setSelectedLeaderShip}
                newSelected={this.state.newSelected}
                setNewSelected={this.setNewSelected}
                from="edit"
                heading={
                  userData.role_id === ROLE_IDS.USER ||
                  userData.role_id === ROLE_IDS.PARENT_OWNER
                    ? "Where do you want to grow?"
                    : "What are the areas of growth for your organization?"
                }
                user={userData.user_name}
                GodQualities={this.props.selectedGodQualities.filter(
                  (data) => data.section === "Leader qualities"
                )}
                // subHeading="You have 2 common areas with Brad Smith."
                classtoOverride={`${
                  Utility.pwaModeEnabled() ? "w-90" : "w-60"
                } mt-15`}
                section="Leader qualities"
                selectedGodQualities={this.props.selectedGodQualities.filter(
                  (data) => data.section === "Leader qualities"
                )}
                addSelectedQualities={(id, name, section) =>
                  this.props.addGodSelectedQualities(id, name, section)
                }
                removeSelectedQualities={(id, section) =>
                  this.props.removeGodSelectedQualities(id, section)
                }
              />

              <GodCalling
                allQualities={this.props.GodQualities.filter(
                  (item) =>
                    !this.props.selectedGodQualities.some(
                      (obj) =>
                        obj.id === item.id &&
                        obj.title === item.title &&
                        obj.section === "Organization and Community grow"
                    )
                )}
                selectedValue={this.state.organizationQualities}
                setSelectedValue={this.setSelectedOrganization}
                from="edit"
                heading={
                  userData.role_id === ROLE_IDS.USER ||
                  userData.role_id === ROLE_IDS.PARENT_OWNER
                    ? "What are your core strengths that might help others?"
                    : "What are the core strengths of your organization that might others?"
                }
                user={userData.user_name}
                newSelected={this.state.newSelectedLeaderShip}
                setNewSelected={this.setNewSelectedLeaderShip}
                GodQualities={
                  this.props.selectedGodQualities
                    ? [...this.props.selectedGodQualities].filter(
                        (data) =>
                          data.section === "Organization and Community grow"
                      )
                    : []
                }
                // subHeading="You have 2 common areas with Brad Smith."
                classtoOverride={`${
                  Utility.pwaModeEnabled() ? "w-90" : "w-60"
                } mt-15`}
                section="Organization and Community grow"
                selectedGodQualities={
                  this.props.selectedGodQualities
                    ? [...this.props.selectedGodQualities].filter(
                        (data) =>
                          data.section === "Organization and Community grow"
                      )
                    : []
                }
                addSelectedQualities={(id, name, section) =>
                  this.props.addGodSelectedQualities(id, name, section)
                }
                removeSelectedQualities={(id, section) =>
                  this.props.removeGodSelectedQualities(id, section)
                }
              />

              {/* <GodCalling
                classtoOverride="w-60"
                user={userData.user_name}
                heading="What are the core strengths of your community and organization that might help others?"
                // subHeading="You have 2 common areas with Brad Smith."
                GodQualities={this.props.GodQualities}
                section="Strength of Organization and Community grow"
                selectedGodQualities={this.props.selectedGodQualities.filter(
                  (data) =>
                    data.section ===
                    "Strength of Organization and Community grow"
                )}
                addSelectedQualities={(id, name, section) =>
                  this.props.addGodSelectedQualities(id, name, section)
                }
                removeSelectedQualities={(id, section) =>
                  this.props.removeGodSelectedQualities(id, section)
                }
              /> */}
            </div>
          </div>
        </div>
      </>
    );
  }
}
