import React from 'react';
import { Form, Spinner } from 'react-bootstrap';
import { InputField } from "shared/components/form_elements";
import { CLIENT_NAME } from 'config/constants/config';

export default class Otp extends React.Component{
  render(){
    return(
      <div className="container">
        <div className="register-outer p-sm-5 p-4 text-center mx-auto my-sm-5 my-3" >
          <img src={require("assets/images/logos/main-logo.png")} className="register-logo mb-4" alt="sharemeister" />
          <h3 className="mb-5 font-bold otp-text-color" >Enter verification code</h3>
          <Form className="pwa-form" >
            <div className="new-landing-theme register-form" >
              <div className="error errorIcon text-center" id="otp_error" />
                <Form.Group className="otp-input d-flex justify-content-center web-otp-inputs mx-auto">
                  <InputField
                  id="otp1"
                  type="tel"
                  name="otp1"
                  ref="otp1"
                  className = "otp-input-box-color"
                  maxLength={1}
                  placeholder=" "
                  onKeyUp={(e) => this.props.onKeyUp(e, "otp1")}
                  handleChange={(e) => this.props.handleOtpChange(e, "otp1")}
                />
                <InputField
                  id="otp2"
                  type="tel"
                  name="otp2"
                  ref="otp2"
                  className = "otp-input-box-color"
                  maxLength={1}
                  placeholder=" "
                  onKeyUp={(e) => this.props.onKeyUp(e, "otp2")}
                  handleChange={(e) => this.props.handleOtpChange(e, "otp2")}
                />
                <InputField
                  id="otp3"
                  type="tel"
                  name="otp3"
                  className = "otp-input-box-color"
                  ref="otp3"
                  maxLength={1}
                  placeholder=" "
                  onKeyUp={(e) => this.props.onKeyUp(e, "otp3")}
                  handleChange={(e) => this.props.handleOtpChange(e, "otp3")}
                />
                <InputField
                  id="otp4"
                  type="tel"
                  name="otp4"
                  className = "otp-input-box-color"
                  ref="otp4"
                  maxLength={1}
                  placeholder=" "
                  onKeyUp={(e) => this.props.onKeyUp(e, "otp4")}
                  handleChange={(e) => this.props.handleOtpChange(e, "otp4")}
                />
                </Form.Group>
                <p className="display-9 text-center mb-4 otp-text-color" >Your 4 digit code was sent to <br /> <span className="text-primary" >{this.props.email}</span>  </p>
                <p className="display-9 text-center otp-text-color" >By verifying your code you consent to receive notifications from {CLIENT_NAME}.</p>
            </div>
            <div className="text-center mt-auto bottom-text-position">
              <p className="display-8 mb-5 otp-text-color" >I did not receive a code <br /><span className="text-primary font-bold cursor-pointer" onClick ={(e) => this.props.resendVerificationCode(e) }>RESEND</span> </p>
              <button
                type="submit"
                className="btn btn-primary btn-login"
                onClick={(e) => this.props.handleOtpVerify(e)}
              >
                {
                  this.props.showLoader
                  ? <Spinner animation="border" role="status"/>
                  : 'Verify'
                }
              </button>
            </div>
          </Form>
        </div>
      </div>
    );
  }
}
