import React from 'react';
import { Form, Image } from 'react-bootstrap';
import { Utility } from 'utils';
import { InputField } from "shared/components/form_elements";
import { ROLES } from 'config/constants';
import PasswordValidationFrame from 'shared/components/password_validation_frame';

export default class UpdatePassword extends React.Component{
  render(){
    const { firstName, lastName, role, profileImgUrl, password } = this.props;
    return (
      <React.Fragment>
      <div className="container">
        <div className="register-outer p-sm-5 p-4 text-center mx-auto my-sm-5 my-3" >
                <img src={require("assets/images/logos/main-logo.png")} className="register-logo mb-4" alt="sharemeister" />
                <h3 className="mb-5 forgot-password-head font-bold" >Update your Password!</h3>
                <div className="new-landing-theme register-form" >
                <div className="account-info-outer align-items-center form-element-outer mx-auto mb-4" >
                  <div className="account-info-pic  mr-3" >
                      {firstName ? <Image className="profile-width golden-border rounded-circle" src={!Utility.isEmpty(profileImgUrl) ? profileImgUrl : require("assets/images/users/user_no_image.png")} alt="Profile Picture" /> : ''}
                  </div>
                  <div className="text-left" >
                    <h5 className="font-bold" >{firstName ? firstName : ''} {lastName ? lastName : ''}</h5>
                    {ROLES[role]}
                  </div>
                </div>
                <Form.Group>
                  <InputField required={true}
               type="password"
               placeholder="Enter password"
               name="password"
               ref="password"
               className="forgotPassword-input-box-color"
               handleChange={(e) => this.props.handleChangePassword(e, 'password')}
               />
               {
                 this.props.showPasswordValidation &&
                 <PasswordValidationFrame password={ Utility.validatePassword(password)}/>
               }
           </Form.Group>
           <Form.Group controlId="formGroupPassword2">
             <InputField required={true}
               type="password" 
               className="forgotPassword-input-box-color" placeholder="Confirm password" name="confirm_password"
               handleChange={(e) => this.props.handleChangePassword(e, 'confirm_password')}
               />
                </Form.Group>
                </div>
                <div className="text-left form-element-outer mx-auto mt-5" >
                    <button className="btn btn-primary btn-login" onClick={(e) => this.props.updatePassword(e)}>Update</button>
                </div>
                <div className="d-inline-block mx-auto text-primary font-bold display-7 cursor-pointer pt-5" onClick ={() => this.props.goSignUp()}>Create a New Account</div>
          </div>
      </div>
      </React.Fragment>
    );
  }
  }
