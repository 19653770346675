import React, { useState } from "react";
import { Link } from "react-router-dom";
import { Utility } from "utils";
import { MAX_IMAGE_SIZE, MAX_VIDEO_SIZE } from "config/constants";
import Tooltip from "@material-ui/core/Tooltip";
import { CircleOutlinedAddIcon } from "shared/icons/icons";
import ReactCrop from "react-image-crop";
import "react-image-crop/dist/ReactCrop.css";

let UploadFile = (props) => {
  const previewURL = props.uploadedFilePreviewUrl || "";
  const inputId = props.inputId;
  const acceptTypes = props.acceptTypes;
  const categoryName = props.categoryName;
  const name = props.name;

  const handleFileChange = (event, inputId) => {
    const reader = new FileReader();
    const originalFile = event.target.files[0];
    const file = new File([originalFile], categoryName);
    const err = document.getElementById(
      props.source !== "post" ? inputId + "Error" : "postTemplateError"
    );
    if (inputId === "image_url" && file.size > MAX_IMAGE_SIZE) {
      if (err) {
        err.textContent =
          "Image must be less than " + MAX_IMAGE_SIZE / 1048576 + "MB.";
        event.target.value = "";
      }

      return;
    } else if (inputId === "video_url" && file.size > MAX_VIDEO_SIZE) {
      if (err) {
        err.textContent =
          "Video must be less than " + MAX_VIDEO_SIZE / 1048576 + "MB.";
        document.getElementById("video_url").value = "";
      }
      return;
    }
    props.handleFileChange(
      inputId,
      URL.createObjectURL(inputId === "image_url" ? file : file),
      inputId !== "image_url" ? file : originalFile,
      name
    );
  };

  if (Utility.isEmpty(previewURL) || props.source === "post") {
    return (
      <UploadView
        refName={props.refName}
        acceptTypes={acceptTypes}
        inputId={inputId}
        title={props.selectTitle}
        handleFileChange={props.handleFileChange}
        source={props.source}
        setCropping={props.setCropping}
        name={name}
        categoryName={categoryName}
        fromEditUser={props.fromEditUser}
        oldHandleFileChange={handleFileChange}
      />
    );
  } else {
    return (
      <div className="text-center">
        <Preview acceptTypes={acceptTypes} previewURL={previewURL} />
        <RemoveUploadedFile
          inputId={inputId}
          removeText={props.removeText}
          removeUploadedFile={props.removeUploadedFile}
          name={name}
        />
      </div>
    );
  }
};

export const UploadView = (props) => {
  const inputId = props.inputId;
  const [src, setSrc] = useState(null);
  const [isCropping, setIsCropping] = useState(false);
  const [file, setFile] = useState(null);
  const [originalFile, setOriginalFile] = useState(null);
  const [crop, setCrop] = useState({
    unit: "%",
    width: 30,
    aspect: 1 / 1,
  });
  const [img, setImg] = useState(null);
  const handleFileChange = (event) => {
    props.oldHandleFileChange(event, props.inputId);
  };
  const onSelectFile = (e) => {
    setIsCropping(true);
    const reader = new FileReader();
    const originalFile = e.target.files[0];
    const file = new File([originalFile], props.categoryName);
    setFile(file);
    setOriginalFile(file);
    const err = document.getElementById(
      props.source !== "post" ? inputId + "Error" : "postTemplateError"
    );
    if (inputId === "image_url" && file.size > MAX_IMAGE_SIZE) {
      if (err) {
        err.textContent =
          "Image must be less than " + MAX_IMAGE_SIZE / 1048576 + "MB.";
        e.target.value = "";
      }

      return;
    } else if (inputId === "video_url" && file.size > MAX_VIDEO_SIZE) {
      if (err) {
        err.textContent =
          "Video must be less than " + MAX_VIDEO_SIZE / 1048576 + "MB.";
        document.getElementById("video_url").value = "";
      }
      return;
    }
    props.setCropping(true);
    if (e.target.files && e.target.files.length > 0) {
      const reader = new FileReader();
      reader.addEventListener("load", () =>
        // this.setState({ src: reader.result })
        setSrc(reader.result)
      );
      reader.readAsDataURL(e.target.files[0]);
    }
  };
  const dataURLtoFile = (dataurl) => {
    const arr = dataurl.split(",");
    const mime = arr[0].match(/:(.*?);/)[1];
    const bstr = atob(arr[1]);
    let n = bstr.length;
    let u8arr = new Uint8Array(n);
    while (n--) {
      u8arr[n] = bstr.charCodeAt(n);
    }
    return new File([u8arr], "profile.jpeg", { type: mime });
  };
  const getCroppedImg = async () => {
    const canvas = document.createElement("canvas");
    const scaleX = img.naturalWidth / img.width;
    const scaleY = img.naturalHeight / img.height;
    canvas.width = crop.width;
    canvas.height = crop.height;
    const ctx = canvas.getContext("2d");

    const pixelRatio = window.devicePixelRatio;
    canvas.width = crop.width * pixelRatio;
    canvas.height = crop.height * pixelRatio;
    ctx.setTransform(pixelRatio, 0, 0, pixelRatio, 0, 0);
    ctx.imageSmoothingQuality = "high";

    ctx.drawImage(
      img,
      crop.x * scaleX,
      crop.y * scaleY,
      crop.width * scaleX,
      crop.height * scaleY,
      0,
      0,
      crop.width,
      crop.height
    );

    const base64Image = canvas.toDataURL("image/jpeg");
    // props.handleFileChange(base64Image, props.inputId);
    // props.setCropping(false);
    // setSrc(null);
    setIsCropping(false);
    const newFile = await dataURLtoFile(base64Image);
    props.handleFileChange(inputId, base64Image, newFile, props.name);
  };

  return (
    <div className="upload-img-panel fw-100 text-center text-gray-300">
      {Utility.isEmpty(props.source) && (
        <>
          <div className="upload-wrap">
            <div className="upload-icon-sec mb-1">
              <Link
                to={{}}
                onClick={() => document.getElementById(inputId).click()}
              >
                <i className="fa fa-plus-square-o" aria-hidden="true"></i>
              </Link>
            </div>
          </div>
          <h5>{props.selectTitle} </h5>
        </>
      )}

      <input
        type="file"
        id={inputId}
        accept={props.acceptTypes}
        onChange={props.fromEditUser ? onSelectFile : handleFileChange}
        style={{ display: "none" }}
        disabled={isCropping ? true : false}
      />

      {Utility.isEmpty(props.source) && (
        <div className="add">
          <div className="items-central">
            <Tooltip
              title={"Upload " + (inputId === "image_url" ? "image" : "video")}
            >
              <div className="text-center">
                <CircleOutlinedAddIcon
                  mainClass="width-20"
                  fillClass="fill-primary"
                />
              </div>
            </Tooltip>
            <div className="placeholder-font pt-3 text-center">
              {props.title}
            </div>
          </div>
        </div>
      )}
      {src && (
        <div
          className="image-crop"
          style={{
            position: "fixed",
            top: "0px",
            left: "0px",
            zIndex: "999",
            backgroundColor: "white",
            height: "100vh",
            width: "100vw",
            padding: "20px",
            overflow: "auto",
          }}
        >
          <div className="image-crop-comp" style={{ textAlign: "center" }}>
            <ReactCrop
              src={src}
              crop={crop}
              ruleOfThirds
              onImageLoaded={setImg}
              onChange={setCrop}
            />
          </div>
          <div>
            <button
              className="btn btn-primary mt-2"
              disabled={!(crop.width > 0)}
              onClick={getCroppedImg}
            >
              Crop
            </button>
          </div>
        </div>
      )}
    </div>
  );
};

const Preview = (props) => {
  return (
    <div className="upload-img-panel fw-100 text-center text-gray-300">
      {props.acceptTypes.includes("video") && (
        <>
          <VideoPreview previewURL={props.previewURL} />
        </>
      )}
      {props.acceptTypes.includes("image") && (
        <ImagePreview previewURL={props.previewURL} />
      )}
    </div>
  );
};

export const VideoPreview = (props) => {
  return (
    <div className="upload-thum-video w-50 m-auto">
      <video width="100%" controls>
        <source src={props.previewURL} type="video/mp4" id="video_here" />
        Your browser does not support HTML5 video.
      </video>
    </div>
  );
};

export const ImagePreview = (props) => {
  return (
    <div className="upload-thum-img">
      <div className="upload-wrap w-50 m-auto">
        <img src={props.previewURL} alt="Preview" />
      </div>
    </div>
  );
};

const RemoveUploadedFile = (props) => {
  return (
    <small>
      <Link
        to={{}}
        onClick={props.removeUploadedFile.bind(this, props.inputId, props.name)}
      >
        <i>{props.removeText}</i>
      </Link>
    </small>
  );
};

export default UploadFile = React.memo(UploadFile);
