import {CommunityHomeIcon, CommunicationIcon, NetworksIcon, CampaignIcon, StatisticsIcon, FinancesIcon, ShopModuleIcon} from 'shared/icons/icons';


export const crmData = [
  {
    alt_text: "Something here...",
    button_label: "Home",
    description: "",
    id: 1,
    name: "Home",
    icon_component: CommunityHomeIcon,
  },
  {
    alt_text: "Something here...",
    button_label: "Start a Conversation",
    description: "Engage in multiple forms of conversation with your social community...",
    id: 1,
    name: "Communications",
    subTitle: "Start a conversation...",
    icon_component: CommunicationIcon ,
  },
  {
    alt_text: "Something here...",
    button_label: "Organize Network",
    description: "Invite and organize your social community into teams, internal networks, external↵            partners and audiences.",
    id: 2,
    name: "Networks",
    subTitle: "Organize your community...",
    icon_component: NetworksIcon,
  },
  {
    alt_text: "Something here...",
    button_label: "Start a Campaign",
    description: "Create, manage and distribute campaigns within your social community to audiences↵             across multiple channels.",
    id: 3,
    name: "Campaigns",
    subTitle: "Create a campaign...",
    icon_component: CampaignIcon,
  },
  // {
  //   alt_text: "Something here...",
  //   button_label: "View Statistics",
  //   description: "Real-time statistics give you a powerful, immediate view of your audience's engagement.",
  //   id: 5,
  //   name: "Statistics",
  //   subTitle: "Create a report...",
  //   icon_component: StatisticsIcon ,
  // },
  {
    alt_text: "Something here...",
    button_label: "View Finances",
    description: "Manage the financial flow of transactions that occur within your social community. ",
    id: 6,
    name: "Finances",
    subTitle: "Request or pay...",
    icon_component: FinancesIcon,
  },
  // {
  //   alt_text: "Something here...",
  //   button_label: "Manage Shops",
  //   description: "Create, manage, and distribute products with your social community to audiences across↵             network channels.",
  //   id: 7,
  //   name: "Shops",
  //   subTitle: "Create a product or service...",
  //   icon_component: ShopModuleIcon ,
  // }
];
