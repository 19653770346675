import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { Utility } from "utils";
import { MAX_IMAGE_SIZE, MAX_VIDEO_SIZE } from "config/constants";
import Tooltip from "@material-ui/core/Tooltip";
import { CircleOutlinedAddIcon, CloseIcon } from "shared/icons/icons";
import ReactCrop from "react-image-crop";
import "react-image-crop/dist/ReactCrop.css";
import { AddProfileImage } from "../icons/icons";

let NewProfileUploadFile = (props) => {
  const inputId = props.inputId;
  const acceptTypes = props.acceptTypes;
  const categoryName = props.categoryName;
  const name = props.name;

  const handleFileChange = (event, inputId) => {
    const reader = new FileReader();
    const originalFile = event.target.files[0];
    const file = new File([originalFile], categoryName);
    const err = document.getElementById(
      props.source !== "post" ? inputId + "Error" : "postTemplateError"
    );
    if (inputId === "image_url" && file.size > MAX_IMAGE_SIZE) {
      if (err) {
        err.textContent =
          "Image must be less than " + MAX_IMAGE_SIZE / 1048576 + "MB.";
        event.target.value = "";
      }

      return;
    } else if (inputId === "video_url" && file.size > MAX_VIDEO_SIZE) {
      if (err) {
        err.textContent =
          "Video must be less than " + MAX_VIDEO_SIZE / 1048576 + "MB.";
        document.getElementById("video_url").value = "";
      }

      return;
    }
    reader.onloadend = () => {
      props.handleFileChange(
        inputId,
        reader.result,
        inputId !== "image_url" ? file : originalFile,
        name
      );
    };
    reader.readAsDataURL(inputId === "image_url" ? file : originalFile);
  };

  return (
    <NewProfileUploadView
      refName={props.refName}
      acceptTypes={acceptTypes}
      inputId={inputId}
      title={props.selectTitle}
      handleFileChange={props.handleFileChange}
      source={props.source}
      setCropping={props.setCropping}
      name={name}
      categoryName={categoryName}
      fromEditUser={props.fromEditUser}
      oldHandleFileChange={handleFileChange}
    />
  );
};

export const RemoveUploadedFile = (props) => {
  return (
    <small>
      <Link
        to={{}}
        onClick={props.removeUploadedFile.bind(this, props.inputId, props.name)}
      >
        <i>{props.removeText}</i>
      </Link>
    </small>
  );
};

export const NewProfileUploadView = (props) => {
  const inputId = props.inputId;
  const [src, setSrc] = useState(null);
  const [isCropping, setIsCropping] = useState(false);
  const [file, setFile] = useState(null);
  const [originalFile, setOriginalFile] = useState(null);
  const [crop, setCrop] = useState({
    unit: "%",
    width: 30,
    aspect: 1 / 1,
  });
  const [img, setImg] = useState(null);
  const handleFileChange = (event) => {
    event.stopPropagation();
    props.oldHandleFileChange(event, props.inputId);
  };
  const onSelectFile = (e) => {
    e.stopPropagation();
    setIsCropping(true);
    const originalFile = e.target.files[0];
    const file = new File([originalFile], props.categoryName);
    setFile(file);
    setOriginalFile(file);
    const err = document.getElementById(
      props.source !== "post" ? inputId + "Error" : "postTemplateError"
    );
    if (inputId === "image_url" && file.size > MAX_IMAGE_SIZE) {
      if (err) {
        err.textContent =
          "Image must be less than " + MAX_IMAGE_SIZE / 1048576 + "MB.";
        e.target.value = "";
      }

      return;
    } else if (inputId === "video_url" && file.size > MAX_VIDEO_SIZE) {
      if (err) {
        err.textContent =
          "Video must be less than " + MAX_VIDEO_SIZE / 1048576 + "MB.";
        document.getElementById("video_url").value = "";
      }
      return;
    }
    props.setCropping(true);
    if (e.target.files && e.target.files.length > 0) {
      const reader = new FileReader();
      reader.addEventListener("load", () =>
        // this.setState({ src: reader.result })
        setSrc(reader.result)
      );
      reader.readAsDataURL(e.target.files[0]);
    }
  };
  const dataURLtoFile = (dataurl) => {
    const arr = dataurl.split(",");
    const mime = arr[0].match(/:(.*?);/) && arr[0].match(/:(.*?);/)[1];
    const bstr = atob(arr[1]);
    let n = bstr.length;
    const u8arr = new Uint8Array(n);
    while (n--) {
      u8arr[n] = bstr.charCodeAt(n);
    }
    return new File([u8arr], "profile.jpeg", { type: mime });
  };
  const getCroppedImg = async () => {
    const canvas = document.createElement("canvas");
    const scaleX = img.naturalWidth / img.width;
    const scaleY = img.naturalHeight / img.height;
    canvas.width = crop.width;
    canvas.height = crop.height;
    const ctx = canvas.getContext("2d");

    const pixelRatio = window.devicePixelRatio;
    canvas.width = crop.width * pixelRatio;
    canvas.height = crop.height * pixelRatio;
    ctx.setTransform(pixelRatio, 0, 0, pixelRatio, 0, 0);
    ctx.imageSmoothingQuality = "high";

    ctx.drawImage(
      img,
      crop.x * scaleX,
      crop.y * scaleY,
      crop.width * scaleX,
      crop.height * scaleY,
      0,
      0,
      crop.width,
      crop.height
    );

    const base64Image = canvas.toDataURL("image/jpeg");
    setIsCropping(false);
    const newFile = await dataURLtoFile(base64Image);
    props.handleFileChange(inputId, base64Image, newFile, props.name);
  };

  return (
    <div className="upload-img-panel fw-100 text-center text-gray-300">
      {Utility.isEmpty(props.source) && (
        <div className="add">
          <input
            type="file"
            id="image_profile"
            key={src}
            accept={props.acceptTypes}
            onChange={props.fromEditUser ? onSelectFile : handleFileChange}
            style={{ display: "none" }}
            disabled={isCropping ? true : false}
          />
          <label htmlFor="image_profile" className="items-central">
            {props.purposeFor === "change" ? (
              "Change Image"
            ) : (
              <div className="text-center">
                <AddProfileImage />
                <p className="mt-3 fw-bolder">Add an Image</p>
              </div>
            )}
          </label>
        </div>
      )}
      {src && (
        <div
          className="image-crop closeIconTop"
          style={{
            position: "fixed",
            top: "0px",
            left: "0px",
            zIndex: "999",
            backgroundColor: "white",
            height: "100vh",
            width: "100vw",
            padding: "20px",
            overflow: "auto",
          }}
        >
          <div
            // onClick={this.props.closeForm}
            onClick={() => {
              setIsCropping(false);
              setSrc(null);
            }}
            className="cursor-pointer float-right mr-4 cropped-closeIcon closeIconTop"
          >
            <CloseIcon />
          </div>
          <div className="image-crop-comp mt-5" style={{ textAlign: "center" }}>
            <ReactCrop
              src={src}
              crop={crop}
              ruleOfThirds
              onImageLoaded={setImg}
              onChange={setCrop}
              imageStyle={{ maxWidth: "500px", maxHeight: "300px" }}
            />
          </div>
          <div>
            <button
              className="btn btn-primary my-4"
              disabled={crop.width < 0}
              onClick={getCroppedImg}
            >
              Crop
            </button>
          </div>
        </div>
      )}
    </div>
  );
};

export default NewProfileUploadFile = React.memo(NewProfileUploadFile);
