import { toast } from "react-toastify";
import { GIL_CLIENT_ID } from "../../config/constants";
import { GET_JOINED_NEIGHBORHOOD } from "../../config/constants/api_endpoints";
import { setUserNeighborhoods } from "../../shared/actions";
import { store } from "../../store";
import { Utility } from "../../utils";

export const getNeighborhoodsOfUser = ({ userData = {}, accountList = {} }) => {
  try {
    const account_list =
      Object.keys(accountList).length > 0
        ? accountList
        : store.getState().accountList;
    const user_data =
      Object.keys(userData).length > 0 ? userData : store.getState().userData;
    
    if (Object.keys(user_data).length > 0 && Object.keys(account_list).length>0) {
      Utility.sendRequest(
        `${GET_JOINED_NEIGHBORHOOD}?user_id=${user_data.user_id}&platform_id=${GIL_CLIENT_ID}`,
        1,
        {},
        (err, res, body) => {
          body = JSON.parse(body);
          if (body.error) {
            toast.error(body.error.message, { containerId: "private" });
          } else {
            const neighborhood = body.map((body_item) => {
              return {
                client_id: body_item.client_id,
                created_by: account_list.Clients.some(
                  (item) => item.client_id === body_item.client_id
                )
                  ? "SELF"
                  : "OTHER",
              };
            });
            store.dispatch(setUserNeighborhoods(neighborhood));
          }
        }
      );
    }
  } catch (err) {
    console.log("Error in Service Profile | getNeighborhoodsOfUser");
  }
};
