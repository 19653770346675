import React, { useState } from "react";
import * as Utility from "utils/utility";
import { withRouter } from "react-router-dom";
import useMediaQuery from '@mui/material/useMediaQuery';
import InforCard from "../../../../scenes/profile/components/InforCard";
import RightSharedSection from "../../../../scenes/profile/components/RightSharedSection";

const NewsRightPanel = (props) => {
  const [seeMore, setSeeMore] = useState(false);
  const isTab = Utility.pwaModeEnabled();
  return (
    <div className={`newsRightPanel background-sidebar-pages ${isTab ? "setFullWidth" : ""}`}>
      <RightSharedSection heading={"Viewers"} classToOverride="mt-30">
        <div
          id="newsViewer"
          className="news-viewers-section news-viewers-section-less"
        >
          {props.viewers &&
            props.viewers.length > 0 &&
            (seeMore
              ? props.viewers
                .filter((data) => data.network !== "clients")
                .map((data, index) => {
                  return (
                    <div
                      className="mt-3 mx-3"
                      key={index}
                      onClick={() =>
                        props.history.push(`/profile/${data.user_client_id}`)
                      }
                    >
                      <InforCard
                        ImageSection={data.image_url}
                        Heading={data.user_name}
                        subHeading={data.country}
                        isLike={data.isLike}
                        leftSectionId=""
                      />
                    </div>
                  );
                })
              : props.viewers
                .filter((data) => data.network !== "clients")
                .slice(0, 3)
                .map((data, index) => {
                  return (
                    <div
                      className="mt-3 mx-3"
                      key={index}
                      onClick={() =>
                        props.history.push(`/profile/${data.user_client_id}`)
                      }
                    >
                      <InforCard
                        ImageSection={data.image_url}
                        Heading={data.user_name}
                        subHeading={data.country}
                        isLike={data.isLike}
                        leftSectionId=""
                      />
                    </div>
                  );
                }))}
        </div>
        <div
          className="chapterSection font-18 d-flex justify-content-center mt-3 cursor-pointer align-items-center"
          onClick={() => {
            if (seeMore) {
              document
                .getElementById("newsViewer")
                .classList.remove("news-viewers-section-more");
              document
                .getElementById("newsViewer")
                .classList.add("news-viewers-section-less");
              setSeeMore(false);
            } else {
              document
                .getElementById("newsViewer")
                .classList.remove("news-viewers-section-less");
              document
                .getElementById("newsViewer")
                .classList.add("news-viewers-section-more");
              setSeeMore(true);
            }
          }}
        >
          {props.viewers &&
            props.viewers.length > 3 &&
            (seeMore ? "See Less" : "See More")}
        </div>
      </RightSharedSection>
    </div>
  );
};

export default withRouter(NewsRightPanel);
