import React, { useEffect, useState, useRef } from "react";
import { Discussion } from "./Discussion";
import { Discussions } from "./CommentMockdata";
import { MdForum } from "react-icons/md";
import RichTextEditor from "../../../rich_text_editor";
import * as Utility from "utils/utility";
import { COMMENT_TOOL_BAR_OPTION } from "../../../../../config/constants";
import CommentTool from "./CommentTool";
import { toast } from "react-toastify";
import { GET_DISCUSSION_COMMENTS } from "../../../../../config/constants/api_endpoints";
import { showLoader } from "../../../../actions";
import { useDispatch } from "react-redux";
import ChatEditor from "../../../ChatEditor";
import { useSelector } from "react-redux";
import MapsUgcRoundedIcon from "@mui/icons-material/MapsUgcRounded";
import CloseIcon from "@mui/icons-material/Close";
import { IconButton } from "@mui/material";

const UserCommentContainer = (props) => {
  const [commentData, setcommentData] = useState("");
  const [StartDiscuss, setStartDiscuss] = useState(false);
  const [postAndComments, setPostAndComments] = useState([]);
  const [callAgain, setCallAgain] = useState(false);
  const [scrollComments, setScrollComments] = useState(true);
  const [scrollToReplyBox, setScrollToReplyBox] = useState(false);
  const formName = "add_primary_product";
  const isInitialMount = useRef(false);
  const [isInitialReady, isSetInitialReady] = useState(false);

  const changeDescription = (data, id) => {
    var dataHash = commentData;
    dataHash = data;
    Utility.isRichTextEmpty(id, data, formName);
    setcommentData(dataHash);
  };
  const dispatch = useDispatch();

  const getCommentData = () => {
    const params = { lesson_id: props.ActiveLessonId };
    // if (!isInitialReady) 
    // dispatch(showLoader(true));
      Utility.sendRequest(
        GET_DISCUSSION_COMMENTS,
        1,
        params,
        (err, res, body) => {
          body = JSON.parse(body);
          if (body.error) {
            toast.error(body.error.message, { containerId: "private" });
            // dispatch(showLoader(false));
            isSetInitialReady(true);
          } else {
            setPostAndComments(body.data);
            // dispatch(showLoader(false));
            setCallAgain(!callAgain);
            isSetInitialReady(true);
            if (!isInitialMount.current) {
              isInitialMount.current = true;
            }
            if (Array.isArray(postAndComments) && Array.isArray(body.data)) {
              postAndComments.length !== body.data.length &&
                setScrollComments(true);
            }
          }
        }
      );
  };

  useEffect(() => {
    getCommentData();
  }, [props.ActiveLessonId]);
  useEffect(() => {
    if (isInitialReady) {
      const interval = setInterval(() => {
        getCommentData();
      }, 4000);
      return () => clearInterval(interval);
    }
  }, [postAndComments]);

  useEffect(() => {
    return () => {
      if (scrollComments) {
        if (document.getElementById("commentsEnd")) {
          document.getElementById("commentsEnd").scrollIntoView({
            behavior: "smooth",
            block: "nearest",
            inline: "start",
          });
          setCallAgain(true);
          setScrollComments(false);
        }
      }
    };
  }, [postAndComments, callAgain, scrollComments]);

  useEffect(() => {
    if (StartDiscuss) {
      if (document.getElementById("ChatBoxEditorContainer")) {
        document.getElementById("ChatBoxEditorContainer").scrollIntoView({
          behavior: "smooth",
          block: "nearest",
          inline: "start",
        });
      }
    }
  }, [StartDiscuss]);

  useEffect(() => {
    if (scrollToReplyBox) {
      if (document.getElementById("replyContainerId")) {
        document.getElementById("replyContainerId").scrollIntoView({
          behavior: "smooth",
          block: "nearest",
          inline: "start",
        });
      }
    }
  }, [scrollToReplyBox]);

  useEffect(() => {
    if (
      isInitialMount.current &&
      props.isRegion &&
      props.isCampaign &&
      !props.isNews
    ) {
      scrollToBottom();
    }
  });

  const handleFileInput = (e) => {
    return;
  };

  const scrollToBottom = () => {
    let containerDiv = document.getElementById("commentsContainer");
    if (containerDiv && props.isRegion && props.isCampaign)
      containerDiv.scrollTop = containerDiv.scrollHeight;
  };

  const handleStartDiscuss = () => {
    setStartDiscuss(!StartDiscuss);
  };

  return (
    <>
      <div className="discussion_container">
        {!props.isDiscussion && (
          <div
            className={`course_panal_header  d-flex ${
              props.isDiscussion ? "chatBoxHeader" : ""
            }`}
          >
            <MdForum className="courseSidebarIcon" />
            <div className="header_title">Discussions</div>

            <button
              className="btn btn-primary btn-half-rounded ml-auto px-5 font-weight-bold"
              onClick={() => handleStartDiscuss()}
            >
              {StartDiscuss ? "Exit" : "Enter Comment"}
            </button>
          </div>
        )}
        <div
          className={`${
            props.isDiscussion
              ? `chatBox ${StartDiscuss ? "chatBox-wrapper-class" : ""}`
              : ""
          } ${
            props.isChatDiscussion ? (StartDiscuss ? "vh-59" : "vh-84") : ""
          }`}
        >
          {StartDiscuss && props.isCampaign && props.isRegion && (
            <div
              className={`comment-input w-100 mx-auto ${
                props.isRegion && (props.isCampaign ? "pl-0 pr-0" : "pl-4 pr-5")
              }`}
              id="revertBox"
            >
              {!props.isRegion && (
                <RichTextEditor
                  id="comment"
                  name="comment"
                  placeholder="Start a Conversation ....."
                  data={commentData}
                  changeDescription={changeDescription}
                  isComment={true}
                  toolbar={COMMENT_TOOL_BAR_OPTION}
                />
              )}
              <CommentTool
                parent_id={props.ActiveLessonId}
                getCommentData={getCommentData}
                commentData={commentData}
                changeDescription={changeDescription}
                isRegion={props.isRegion}
                isCampaign={props.isCampaign}
                isNewComment={false}
                scrollComments={scrollComments}
                setScrollComments={setScrollComments}
              />
            </div>
          )}
          <div
            className={`${props.isDiscussion ? "chatBoxChats" : ""} ${
              props.isRegion &&
              postAndComments.length !== 0 &&
              (props.isCampaign
                ? "campaign-discussion-height "
                : "discussion-height")
            } chatBoxTabBottom`}
            id="commentsContainer"
          >
            {postAndComments && postAndComments.length > 0 ? (
              postAndComments.map((data, index) => {
                return (
                  <div key={index}>
                    <Discussion
                      getCommentData={getCommentData}
                      commentData={data}
                      iscommentReply={false}
                      isReply={false}
                      isRegion={props.isRegion}
                      isCampaign={props.isCampaign}
                      isDiscussion={props.isDiscussion}
                      scrollToReplyBox={scrollToReplyBox}
                      setScrollToReplyBox={setScrollToReplyBox}
                    />

                    {data.reply &&
                      data.reply.length > 0 &&
                      data.reply.map((comments, index) => {
                        return (
                          <div className="post-comments ml-auto" key={index}>
                            <Discussion
                              getCommentData={getCommentData}
                              commentData={comments}
                              isRegion={props.isRegion}
                              iscommentReply={false}
                              isReply={true}
                              isCampaign={props.isCampaign}
                              isDiscussion={props.isDiscussion}
                              scrollToReplyBox={scrollToReplyBox}
                              setScrollToReplyBox={setScrollToReplyBox}
                            />
                            {comments.reply &&
                              comments.reply.length > 0 &&
                              comments.reply.map((reply, index) => {
                                return (
                                  <div className="comment-replies" key={index}>
                                    <Discussion
                                      hideReplis={true}
                                      isReply={false}
                                      iscommentReply={true}
                                      getCommentData={getCommentData}
                                      commentData={reply}
                                      isRegion={props.isRegion}
                                      isCampaign={props.isCampaign}
                                      isDiscussion={props.isDiscussion}
                                      scrollToReplyBox={scrollToReplyBox}
                                      setScrollToReplyBox={setScrollToReplyBox}
                                    />
                                  </div>
                                );
                              })}
                          </div>
                        );
                      })}
                  </div>
                );
              })
            ) : (
              <div className="header_title no-data-styling font-16 text-center noDiscussion mt-5">
                No Discussion Found
              </div>
            )}
            {props.isDiscussion && (
              <div id="commentsEnd">
                <p className="text-transparent"></p>
              </div>
            )}
          </div>
        </div>
        {props.isDiscussion && (
          <div id="commentsEnd_big">
            <p className="text-transparent"></p>
          </div>
        )}
        {!props.isCampaign && StartDiscuss && (
          <div
            id="ChatBoxEditorContainer"
            className={`comment-input  position-relative mx-auto ${
              Utility.pwaModeEnabled() ? "w-100" : "w-75"
            } ${props.isRegion && "pl-4 pr-5"} ${
              props.isChatDiscussion ? "px-204" : ""
            } ${props.isDiscussion ? "commentAnchor" : ""}`}
          >
            {!props.isRegion && (
              <RichTextEditor
                id="comment"
                name="comment"
                placeholder="Start a Conversation ....."
                data={commentData}
                changeDescription={changeDescription}
                isComment={true}
                toolbar={COMMENT_TOOL_BAR_OPTION}
              />
            )}
            <CommentTool
              parent_id={props.ActiveLessonId}
              getCommentData={getCommentData}
              commentData={commentData}
              changeDescription={changeDescription}
              isRegion={props.isRegion}
              isDiscussion={props.isDiscussion}
              isNewComment={true}
              scrollComments={scrollComments}
              handleStartDiscuss={handleStartDiscuss}
              setScrollComments={setScrollComments}
           
            />
            {StartDiscuss && (
              <div className="position-absolute closeIconComment">
                <IconButton
                  className="comment-button"
                  onClick={() => handleStartDiscuss()}
                >
                  <CloseIcon />
                </IconButton>
              </div>
            )}
          </div>
        )}

        {props.isDiscussion && !StartDiscuss && (
          <div className={`comment-button-wrapper`}>
            <IconButton
              className="comment-button"
              onClick={() => handleStartDiscuss()}
            >
              <MapsUgcRoundedIcon />
            </IconButton>
          </div>
        )}
      </div>
    </>
  );
};

export default UserCommentContainer;
