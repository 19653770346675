import React, { Component } from "react";
import { toast } from "react-toastify";
import MyCommunity from "shared/components/my_community";
import { Utility } from "utils";
import { GET_USERS_BY_NETWORKS } from "config/constants/api_endpoints";
import { CLIENT_ID, COMMUNITY_CARD_LIMIT } from "config/constants/config";
import { GET_FOLLOWERS_FOLLOWING } from "config/constants/api_endpoints";
import { connect } from "react-redux";
import { showLoader } from "shared/actions";
import { CustomSlider } from "../Slider";
import { ROLE_IDS } from "../../../config/constants";
import { Skeleton } from "@mui/material";

class Community extends Component {
  constructor(props) {
    super(props);

    this.state = {
      users: [],
      id: this.props.props.userData.id,
    };
  }

  componentDidMount = () => {
    if (!this.props.isFeatured) this.getUserDetails();
  };

  getUserDetails = () => {
    const params = {
      client_id: CLIENT_ID,
      type: "all",
      limit: COMMUNITY_CARD_LIMIT,
      forCommunity: true,
      page_number: 1,
    };

    Utility.sendRequest(GET_USERS_BY_NETWORKS, 1, params, (err, res, body) => {
      body = JSON.parse(body);

      if (body.error) {
        toast.error(body.error.message, { containerId: "private" });
      } else {
        if (body.registrants)
          this.setState({
            users: body.registrants,
          });
      }
    });
  };

  setDetailsFollowerfollowing = (followingId) => {
    const { id } = this.state;
    if (id !== followingId) {
      const params = {
        followerId: this.state.id,
        followingId: followingId,
        clientId: CLIENT_ID,
      };

      Utility.sendRequest(
        GET_FOLLOWERS_FOLLOWING,
        2,
        params,
        (err, res, body) => {
          body = JSON.parse(body);
          if (body.error) {
            toast.error(body.error.message, { containerId: "private" });
          } else {
            this.getUserDetails();
          }
        }
      );
    }
  };

  handleOnClick = (id) => {
    this.setDetailsFollowerfollowing(id);
  };

  cardList = () => {
    const { users } = this.state;
    if (this.props.isFeatured) {
      return this.props.data
        .filter(
          (item) =>
            item.role_id === ROLE_IDS.CLIENT_OWNER ||
            item.role_id === ROLE_IDS.CLIENT_ADMIN
        )
        .filter(
          (value, index, self) =>
            index === self.findIndex((t) => t.id === value.id)
        )
        .map((user) => (
          <div key={user.id} className="mr-2">
            <MyCommunity
              key={user.id}
              item={user}
              handleOnClick={this.handleOnClick}
              showCharFollowButtons={true}
              isFeatured={false}
              profilePage={true}
            />
          </div>
        ));
    } else
      return users
        .filter(
          (value, index, self) =>
            index === self.findIndex((t) => t.id === value.id)
        )
        .map((user) => (
          <MyCommunity
            key={user.id}
            item={user}
            handleOnClick={this.handleOnClick}
            showCharFollowButtons={true}
          />
        ));
  };

  render() {
    const users = this.props.isFeatured ? this.props.data : this.state.users;
    console.log("Testing", users, this.props.loadingCommunity);

    return (
      <div className="px-1 slider-mob px-lg-0 mb-2 my-5 ">
        <h1 className="font-24-bold  home-page-heading">
          <span className="font-weight-bold home-page-headings d-flex align-items-center mb-2">
            <img
              src={require("assets/images/featuredCommunity.png")}
              height="19px"
            />
            <p className="pl-2 m-auto">My Community</p>
          </span>

          {users.length > 3 && (
            <span
              className="see-all-text display-7 ml-auto"
              onClick={() => this.props.setSeeAll("community")}
            >
              See All
            </span>
          )}
        </h1>
        {/* <h1 className="font-24-bold mb-25 home-page-heading ml-0">
          <span className="m-1">
            {" "}
            <img src={require("assets/images/featuredCommunity.png")} height="20px"/>
          </span>
          Featured Community
          {users.length > 3 && (
            <span
              className="see-all-text display-7 ml-auto"
              onClick={() => this.props.setSeeAll("community")}
            >
              See All
            </span>
          )}
        </h1> */}
        <CustomSlider>
          {this.props.loadingCommunity ? (
            <div className="d-flex">
              {[1, 2, 3, 4].map((item) => (
                <Skeleton
                  variant="rounded"
                  width={200}
                  height={140}
                  key={item}
                  className="mr-3 rounded-20 "
                />
              ))}
            </div>
          ) : users.length === 0 ? (
            <div className="d-flex align-items-center justify-content-center mt-5">
              <div className="px-5">
                <span className="font-bold display-6">No Records Found</span>
              </div>
            </div>
          ) : (
            this.cardList()
          )}
        </CustomSlider>
      </div>
    );
  }
}

const mapActionToProps = { showLoader };

const mapStateToProps = (state) => ({
  userData: state.userData,
});

export default connect(mapStateToProps, mapActionToProps)(Community);
