import React, { Component } from "react";
import CourseContainerCard from "./CourseContainer";
import { CourseLessonIcon, CourseLessonIconHome } from "../../icons/icons";
import { Skeleton } from "@mui/material";
import { CustomSlider } from "../Slider";

export default class SuggestedCourses extends Component {
  constructor(props) {
    super(props);
    this.state = {
      campaignCourses: [],
    };
  }

  // componentDidMount = () => {
  //   this.getCampaignCourses();
  // };

  // componentDidUpdate = (nextProps) => {
  //   if (this.props.refresh !== nextProps.refresh) this.getCampaignCourses();

  //   if (this.props.userData !== nextProps.userData) this.getCampaignCourses();
  // };

  // getCampaignCourses = (seeAll = false) => {
  //   const params = {
  //     publisher_network_id: CLIENT_ID,
  //     limit: this.props.limit,
  //     is_course: true,
  //     show_featured: true,
  //   };

  //   if (seeAll) {
  //     delete params.range;
  //   }

  //   if (!Utility.isEmpty(this.props.userData)) {
  //     Utility.sendRequest(
  //       CAMPAIGNS_TOP_STORIES,
  //       1,
  //       params,
  //       (err, res, body) => {
  //         body = JSON.parse(body);

  //         if (body.error) {
  //           toast.error(body.error.message, { containerId: "private" });
  //         } else {
  //           console.log("campaigns_top_stories 7")
  //           if (body.length) this.setState({ campaignCourses: body });
  //         }
  //       }
  //     );
  //   }
  // };

  render() {
    return (
      <div className=" slider-mob px-lg-0 mt-3 px-1">
        {this.props.showHeading && (
          <h1 className="font-24-bold mb-25 home-page-heading">
            {this.props.showHeading && (
              <span className="font-weight-bold home-page-headings">
                <CourseLessonIconHome />
                {this.props.heading}
              </span>
            )}
            {this.props.seeAll && this.props.fromMedia && (
              <span
                className="see-all-text display-7"
                onClick={() => this.props.history.push("/courses")}
              >
                See All
              </span>
            )}
          </h1>
        )}

        {this.props.loadingCourse ? (
          <CustomSlider>
            {" "}
            <div className="d-flex">
              {[1, 2, 3, 4].map((item) => (
                <Skeleton
                  variant="rounded"
                  width={260}
                  height={270}
                  key={item}
                  className="mr-3 rounded-20 "
                />
              ))}
            </div>
          </CustomSlider>
        ) : this.props.data && this.props.data.length !== 0 ? (
          <CourseContainerCard
            isHome={true}
            courseData={this.props.data}
            PanalTitle={""}
            colorCode={""}
            type={""}
            width="95vw"
            search=""
            categoriesList={""}
            joinedNeighborhood={this.props.joinedNeighborhood}
          />
        ) : (
          <div className="d-flex  my-5 py-4">
            <div className="px-5">
              <span className="font-bold display-6">No Records Founds</span>
            </div>
          </div>
        )}
      </div>
    );
  }
}
