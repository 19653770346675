import React from "react";
import CourseCard from "../../../profile/components/CourseCard";
import { Spinner } from "react-bootstrap";

const DiscussionsCourses = (props) => {
  const data = props.data.filter(({ heading }) =>
    heading.toLowerCase().includes(props.search.toLowerCase())
  );
  return (
    <div className="d-flex flex-wrap justify-content-between">
      {data.length > 0 ? (
        data.map((item) => (
          <div className="mr-5 ml-2 my-3" key={item.id}>
            <CourseCard data={item} key={item.id} />
          </div>
        ))
      ) : (
          <h4 className="m-3 mx-auto">No record found</h4>
      )}
    </div>
  );
};

export default DiscussionsCourses;
