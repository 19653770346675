import React, { Component } from "react";
import { Button, Form } from "react-bootstrap";
import ReactCrop from "react-image-crop";
import "react-image-crop/dist/ReactCrop.css";
import { Utility } from "utils";
import { CircleFilledAddLargeIcon } from "shared/icons/icons";
import { InputField } from "shared/components/form_elements";
import UploadFile from "shared/components/file_upload";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import { showLoader } from "shared/actions";
import { GIL_CLIENT_ID, ROLE_IDS } from "../../../config/constants";
import { GET_CLIENTS_SUMMARY } from "../../../config/constants/api_endpoints";
import { Spinner } from "react-bootstrap";
import { connect } from "react-redux";
import { Autocomplete, TextField } from "@mui/material";
import {
  ImagePreview,
  VideoPreview,
} from "../../../shared/components/file_upload";
import { withRouter } from "react-router-dom";

class CourseForm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      createrId: [],
      accountListData: {},
      crop: {
        width: 50,
        height: 50,
        unit: "%",
      },
      src: null,
      FileDataValue: null,
      originalFile: null,
      img: null,
      cropping: false,
      joinedNeignBourhood: [],
    };
  }

  componentDidMount = () => {
    this.handleNeighbourhoodDetails();
  };

  componentDidUpdate = (prevProps, prevStates) => {
    if (
      this.props.accountList !== prevProps.accountList &&
      this.props.userData.role_id === ROLE_IDS.USER
    ) {
      this.handleNeighbourhoodDetails();
    }
  };

  getCroppedImg = async () => {
    const canvas = document.createElement("canvas");
    const scaleX = this.state.img.naturalWidth / this.state.img.width;
    const scaleY = this.state.img.naturalHeight / this.state.img.height;
    canvas.width = this.state.crop.width;
    canvas.height = this.state.crop.height;
    const ctx = canvas.getContext("2d");
    const pixelRatio = window.devicePixelRatio;
    canvas.width = this.state.crop.width * pixelRatio;
    canvas.height = this.state.crop.height * pixelRatio;
    ctx.setTransform(pixelRatio, 0, 0, pixelRatio, 0, 0);
    ctx.imageSmoothingQuality = "high";

    ctx.drawImage(
      this.state.img,
      this.state.crop.x * scaleX,
      this.state.crop.y * scaleY,
      this.state.crop.width * scaleX,
      this.state.crop.height * scaleY,
      0,
      0,
      this.state.crop.width,
      this.state.crop.height
    );

    const base64Image = canvas.toDataURL("image/png");
    this.setState({ cropping: false });
    const newFile = await this.dataURLtoFile(base64Image);
    this.props.handleFileChange("image_url", base64Image, newFile);
  };

  dataURLtoFile = (dataurl) => {
    const arr = dataurl.split(",");
    const mime = arr[0].match(/:(.*?);/)[1];
    const bstr = atob(arr[1]);
    let n = bstr.length;
    let u8arr = new Uint8Array(n);
    while (n--) {
      u8arr[n] = bstr.charCodeAt(n);
    }
    return new File([u8arr], "profile.jpeg", { type: mime });
  };

  handleNeighbourhoodDetails = () => {
    let response = [];

    if (
      this.props.userData &&
      this.props.userData.role_id !== ROLE_IDS.CLIENT_ADMIN &&
      this.props.userData.role_id !== ROLE_IDS.CLIENT_OWNER
    ) {
      if (
        this.props.accountList &&
        this.props.accountList.Clients &&
        this.props.accountList.Clients.length > 0 &&
        this.props.userData.role_id === ROLE_IDS.USER
      ) {
        const allAccounts = [...this.props.accountList.Clients];

        let tempBody = allAccounts.filter(
          (value, index, self) =>
            index === self.findIndex((t) => t.id === value.id)
        );
        response = [
          ...tempBody.map((items) => {
            if (items.client_id === this.props.userData.client_id) {
              this.props.handleCreaterId([
                ...this.props.createrId,
                items.client_id,
              ]);
            }
            return {
              name: items.user_name,
              value: items.client_id ? items.client_id : items.id,
            };
          }),
        ];
        this.setState({
          joinedNeignBourhood: response,
        });
        // setJoinedNeighbourhood(response);
      }
      if (
        this.props.userData &&
        (this.props.userData.role_id === ROLE_IDS.PARENT_ADMIN ||
          this.props.userData.role_id === ROLE_IDS.PARENT_OWNER)
      ) {
        const params = {
          client_id: GIL_CLIENT_ID,
          type: "clients",
        };
        Utility.sendRequest(
          GET_CLIENTS_SUMMARY,
          1,
          params,
          async (error, response, body) => {
            body = JSON.parse(body);
            response = body.clients.map((data) => {
              return {
                name: data.user_name,
                value: data.client_id,
              };
            });

            this.setState({
              joinedNeignBourhood: [...response],
            });
          }
        );
      }
    } else {
      if (this.props.userData) {
        this.setState({
          joinedNeignBourhood: [
            {
              name: this.props.userData.user_name,
              value: this.props.userData.client_id,
            },
          ],
        });
      }
    }
  };

  handleFile = (e) => {
    e.stopPropagation();
    this.props.handleFileChange(
      "image_url",
      URL.createObjectURL(e.target.files[0]),
      e.target.files[0]
    );
    // this.setState({ cropping: true });
    if (e.target.files && e.target.files.length > 0) {
      const reader = new FileReader();
      reader.addEventListener("load", () => {
        this.setState({ src: reader.result });
      });
      reader.readAsDataURL(e.target.files[0]);
    }
    this.setState({
      cropping: true,
      crop: {
        width: 50,
        height: 50,
        unit: "%",
      },
    });
  };

  paintImageInput = () => (
    <Form.Group className="mb-4 pb-1">
      <Form.Label className="font-bold display-8 mb-3">
        Add a Media <span className="text-red">*</span>
      </Form.Label>
      <p className="f-weight-bold" style={{ fontSize: "10px" }}>
        the Image size should be 1467 x 645
      </p>
      <div className="align-items-center justify-content-center post-pic position-relative page-sidebar-upload create-article-upload">
        <>
          {!(
            this.props.formStates.imagePreviewUrl ||
            this.props.formStates.videoPreviewUrl
          ) ? (
            <input
              type="file"
              id="media_url"
              style={{ display: "none" }}
              accept="video/*, image/*"
              onChange={(event) =>
                event.target.files &&
                event.target.files[0].type.includes("video")
                  ? this.props.handleFileChange(
                      "video_url",
                      URL.createObjectURL(event.target.files[0]),
                      event.target.files[0]
                    )
                  : this.handleFile(event)
              }
            />
          ) : (
            <>
              {this.props.formStates.imagePreviewUrl && (
                <>
                  {this.state.cropping &&
                  this.props.location.pathname.includes("course-create") ? (
                    <>
                      <div
                        className="image-crop"
                        style={{
                          position: "fixed",
                          top: "0px",
                          left: "0px",
                          zIndex: "999",
                          backgroundColor: "white",
                          height: "100vh",
                          width: "100vw",
                          padding: "20px",
                          overflow: "auto",
                        }}
                      >
                        <div
                          className="image-crop-comp"
                          style={{ textAlign: "center" }}
                        >
                          <ReactCrop
                            src={this.state.src}
                            crop={this.state.crop}
                            ruleOfThirds
                            maxHeight={645}
                            onImageLoaded={(value) =>
                              this.setState({
                                img: value,
                              })
                            }
                            onChange={(value) =>
                              this.setState({
                                crop: value,
                              })
                            }
                          />
                        </div>
                        <div className="d-flex">
                          <button
                            disabled={
                              !(
                                this.state.crop.width > 0 &&
                                this.state.crop.height > 0
                              )
                            }
                            className="mx-auto btn btn-primary mt-2"
                            onClick={this.getCroppedImg}
                          >
                            Crop
                          </button>
                        </div>
                      </div>
                    </>
                  ) : (
                    <>
                      {" "}
                      <ImagePreview
                        previewURL={this.props.formStates.imagePreviewUrl}
                      />
                      <p
                        className="cursor-pointer f-weight-bold"
                        onClick={() =>
                          this.props.removeUploadedFile("image_url")
                        }
                      >
                        Remove Image
                      </p>
                    </>
                  )}
                </>
              )}
              {this.props.formStates.videoPreviewUrl && (
                <>
                  <VideoPreview
                    previewURL={this.props.formStates.videoPreviewUrl}
                  />
                  <p
                    className="cursor-pointer f-weight-bold"
                    onClick={() => this.props.removeUploadedFile()}
                  >
                    Remove Video
                  </p>
                </>
              )}
            </>
          )}
          <div className="error errorIcon mb-3" id={"image_urlError"} />
          {!(
            this.props.formStates.imagePreviewUrl ||
            this.props.formStates.videoPreviewUrl
          ) && (
            <label
              htmlFor="media_url"
              className="post-uplodad-label mb-0 cursor-pointer"
            >
              <CircleFilledAddLargeIcon
                mainClass="add-icon-upper"
                fillClass="fill-primary"
              />
            </label>
          )}
        </>
      </div>
      <div className="my-2 ml-1 display-8 font-medium">Or</div>
      <InputField
        required={true}
        type="text"
        name="embedded_image_link"
        ref="embedded_image_link"
        value={this.props.newCampaign.embedded_image_link}
        handleChange={this.props.handleChange}
        placeholder="Embed Image URL..."
      />
      <div className="my-2 ml-1 display-8 font-medium">Or</div>
      <InputField
        type="text"
        name="embedded_video_link"
        value={this.props.newCampaign.embedded_video_link}
        handleChange={this.props.handleChange}
        placeholder="Embed YouTube URL..."
      />
    </Form.Group>
  );

  paintVideoInput = () => (
    <Form.Group className="mb-4 pb-1">
      <Form.Label className="font-bold display-8 mb-3">Add a Video</Form.Label>
      <div className="align-items-center justify-content-center post-pic position-relative page-sidebar-upload create-article-upload">
        <label className="post-uplodad-label mb-0 cursor-pointer">
          <UploadFile
            name="video_url"
            inputId="video_url"
            removeText="Remove video"
            acceptTypes="video/*"
            uploadedFilePreviewUrl={this.props.formStates.videoPreviewUrl}
            handleFileChange={this.props.handleFileChange}
            removeUploadedFile={this.props.removeUploadedFile}
          />
          <div className="error errorIcon" id={"video_urlError"} />
          {!this.props.formStates.videoPreviewUrl && (
            <CircleFilledAddLargeIcon
              mainClass="add-icon-upper"
              fillClass="fill-primary"
            />
          )}
        </label>
      </div>
      <div className="my-2 ml-1 display-8 font-medium">Or</div>
      <InputField
        type="text"
        name="embedded_video_link"
        value={this.props.newCampaign.embedded_video_link}
        handleChange={this.props.handleChange}
        placeholder="Embed YouTube URL..."
      />
    </Form.Group>
  );

  render() {
    const props = this.props;
    return this.state.cropping &&
      !this.props.location.pathname.includes("course-create") ? (
      <>
        <div
          className="image-crop"
          style={{
            position: "absolute",
            top: "0px",
            left: "0px",
            zIndex: "999",
            backgroundColor: "white",
            height: "100%",
            width: "100%",

            padding: "20px",
            overflow: "auto",
          }}
        >
          <div>
            <div className="image-crop-comp" style={{ textAlign: "center" }}>
              <ReactCrop
                src={this.state.src}
                crop={this.state.crop}
                maxHeight={645}
                ruleOfThirds
                onImageLoaded={(value) =>
                  this.setState({
                    img: value,
                  })
                }
                onChange={(value) => {
                  this.setState({
                    crop: value,
                  });
                }}
              />
            </div>
            <div className="d-flex">
              <button
                className="mx-auto btn btn-primary mt-2"
                onClick={this.getCroppedImg}
              >
                Crop
              </button>
            </div>
          </div>
        </div>
      </>
    ) : (
      <div className="py-5 page-sidebar-outer-article">
        <Form className="shar-form search-form search-form-pwa sidebar-form">
          <Form.Group className="mb-4 pb-1">
            <Form.Label className="font-bold display-8 mb-3">
              Select Neighborhood<span className="text-red">*</span>
            </Form.Label>

            <div className="posted-by-container">
              <Autocomplete
                multiple
                disableCloseOnSelect
                id="joinedNeighbourhood"
                options={this.state.joinedNeignBourhood}
                value={
                  this.state.joinedNeignBourhood &&
                  this.state.joinedNeignBourhood.filter((data) => {
                    return (
                      this.props.createrId &&
                      this.props.createrId.includes(data.value)
                    );
                  })
                }
                getOptionLabel={(option) => option.name}
                onChange={(event, newValue) => {
                  this.props.handleCreaterId([
                    ...newValue.map((item) => item.value),
                  ]);
                }}
                renderOption={(props, option) => {
                  return (
                    <li
                      className="community-option-container"
                      key={option.value}
                      {...props}
                    >
                      <input
                        className="community-option-container-checkbox"
                        type="checkbox"
                        checked={
                          this.props.createrId &&
                          this.props.createrId.includes(option.value)
                        }
                      />
                      <p className="community-option-container-text">
                        {option.name}
                      </p>
                    </li>
                  );
                }}
                name="joinedNeighbourhood"
                className="field-common-styling ml-0 w-100"
                renderInput={(params) => (
                  <TextField
                    {...params}
                    className={`${
                      this.props.createrId &&
                      this.props.createrId.length > 0 &&
                      "autoComplete-textfield-styling-hideplaceHolder"
                    } autoCompleteTextField autoComplete-textfield-styling`}
                    placeholder={
                      this.props.createrId && this.props.createrId.length > 0
                        ? ""
                        : `Select A Community${
                            [
                              ROLE_IDS.CLIENT_ADMIN,
                              ROLE_IDS.CLIENT_OWNER,
                              ROLE_IDS.USER,
                            ].includes(props.userData.role_id)
                              ? "*"
                              : ""
                          }`
                    }
                    variant="outlined"
                  />
                )}
              />
            </div>
            <div className="error errorIcon mb-3" id={"category_idError"} />
          </Form.Group>

          <Form.Group className="mb-4 pb-1">
            <Form.Label className="font-bold display-8 mb-3">
              Add Course Name
              <span className="text-red">*</span>
            </Form.Label>
            <InputField
              required={true}
              name="heading"
              ref="heading"
              id="heading"
              type="text"
              placeholder="Create a new Course"
              value={props.newCampaign.heading}
              handleChange={props.handleChange}
            />
            {/* <div className="error errorIcon mb-3" id={"Heading_idError"} /> */}
          </Form.Group>
          {/* {this.printMedia()} */}
          {this.paintImageInput()}

          {/* {this.paintVideoInput()} */}

          <Form.Group className="mb-4 pb-1">
            <Form.Label className="font-bold display-8 mb-3">
              Enter Course Description
              <span className="font-light-italic">(Details)</span>
            </Form.Label>
            {/* <RichTextEditor
                  id="description"
                  name="description"
                  placeholder="Add Text..."
                  data={props.newCampaign.description}
                  changeDescription={props.changeDescription}
                /> */}
            <ReactQuill
              id="description"
              name="description"
              theme="snow"
              value={this.props.newCampaign.description}
              onChange={props.changeDescription}
              style={{ minHeight: "300px" }}
            />
          </Form.Group>
          <div className="d-flex">
            {(props.formStates.isCreate || props.formStates.isNewChap) &&
            props.location.pathname === "/course-create" ? (
              <Button
                disabled={props.isCreateCourseLoading}
                type="submit"
                variant="primary"
                onClick={props.submit}
                className={`btn btn-primary mr-3 btn-primary-pwa btn-post-size font-18 my-4 ml-1 font-weight-bold`}
              >
                {props.isCreateCourseLoading ? (
                  <Spinner animation="border" role="status" />
                ) : (
                  "Save"
                )}
              </Button>
            ) : (
              <Button
                disabled={props.isCreateCourseLoading}
                type="submit"
                variant="primary"
                onClick={(e) => props.submit(e, "close")}
                className={`btn btn-primary btn-primary-pwa btn-post-size font-18 my-4 ml-1 font-weight-bold`}
              >
                {props.isCreateCourseLoading ? (
                  <Spinner animation="border" role="status" />
                ) : (
                  "Save & Close"
                )}
              </Button>
            )}
          </div>
          <div className="">
            <div
              className="display-8 font-medium-italic cursor-pointer btn btn-post-size"
              onClick={() => {
                if (props.calledFrom !== "User") {
                  props.setIsFormOpen(false);
                  props.handleMenuItemClick(props.item);
                } else {
                  props.history.goBack();
                }
              }}
            >
              Cancel
            </div>
          </div>
        </Form>
      </div>
    );
  }
}

const mapActionToProps = {
  showLoader,
};

const mapStateToProps = (state) => ({
  userData: state.userData,
  accountList: state.accountList,
});

export default connect(
  mapStateToProps,
  mapActionToProps
)(withRouter(CourseForm));
