import React from 'react';
import SideCallout from "shared/components/templates/side_callout";
import AddOrEditProduct from './add_or_edit_products';

const Edit_Sub_Product = (props) => {
  return (
    
    <>
      <SideCallout
        closeCallOut={props.closeSubProductForm}
        position="position-fixed sub-product-callout"
        calloutHeading="Add Sub Products">

        <AddOrEditProduct
          isSubproductEdit={true}
          mainProduct= {props.mainProduct}
          closeBtn={props.closeBtn}
          cancelForm= {props.cancelForm}
          allCategories={props.allCategories}
          closeSubProductForm = {props.closeSubProductForm}
          productId= {props.selectedProductId} 
          selectedSubProduct = {props.selectedSubProductItem}
          setSubProductList={props.setSubProductList}
          getSubProductDetails ={props.getSubProductDetails}
          editSubProductDetails = {props.editSubProductDetails}
          SubProductItem= {true}
        />
      </SideCallout>
    </>
  )
}

export default Edit_Sub_Product;