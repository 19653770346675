import React from 'react';
import { Button, Row, Col } from 'react-bootstrap';
import { MdClose } from 'react-icons/md';

export const MenuSummary = props => {
  return (
    <Col xl={props.children.props.columnValue || 6} lg={4} xs={12} className="h-md-100 ModalOpen" id="menu-summary">
      <div className="d-block d-lg-none position-relative bg-white px-3 py-4 border-bottom back-button-sm">
        <Button variant="link" className="color-primary text-left align-items-center" onClick={props.goToBack}><img className="width-10 align-baseline mr-3" src={require("assets/images/chevron_left.svg")} alt="left-icon" />
        </Button>
      </div>
      <div className="scroll-y h-100 position-relative tab-view-height p-4">
        <div className="close-button">
          {
            props.closeSummary &&
            <Button variant="link"
              className="close-btn"
              onClick={props.closeSummary}>
                <MdClose/>
            </Button>
          }
        </div>
        {props.children}
      </div>
    </Col>
  );
};

export const MenuRow = props => (
  <Row className="no-gutters pr-sm-3 py-sm-3">
    <Col xs={12}>
      <div className="profile member-info network-card-header">
        {props.children}
      </div>
    </Col>
    {/*
      <Col lg={7} xs={12}>
        <div className="font-30-bold interaction-padding">Shared Interactions</div>
        <div className="network-view">
          <div className="select-add-network">
            <Dropdown className="custom-dropdown">
              <Dropdown.Toggle variant="" id="dropdown-basic"  className="border-transparent">
                All Interactions
              </Dropdown.Toggle>
              <Dropdown.Menu className="lists-position">
                <Dropdown.Item href="#/action-1">Action</Dropdown.Item>
                <Dropdown.Item href="#/action-2">Another action</Dropdown.Item>
                <Dropdown.Item href="#/action-3">Something else</Dropdown.Item>
              </Dropdown.Menu>
            </Dropdown>

            <div className="add-network pr-lg-5 pr-3"><span className="display-6"><MdAddCircleOutline/></span></div>
          </div>
          <div className="px-3">
            <div className="border-bottom-dotted"></div>
          </div>
          <div className="members-list">
            <ul className="mx-3">
              <li>
                <a href="#" className="text-networks">
                  <div className="d-flex member pl-1">
                    <div className="fix-width">
                      <div className="round-circle-medium bg-grey">
                        <div className="round-circle-sm bg-blue"></div>
                      </div>
                    </div>
                    <div className="networks-name">
                      <div className="pl-2">
                        <span className="font-20-bold">General</span>
                        <span className="pl-2 font-12 text-light-grey">11:27AM</span>
                      </div>
                      <div className="pl-2">
                        <span className="font-14">Good morning gentlem…</span>
                      </div>
                    </div>
                  </div>
                </a>
              </li>
              <li>
                <a href="#" className="text-networks">
                  <div className="d-flex member pl-1">
                    <div className="fix-width">
                      <div className="round-circle-medium bg-grey">
                        <div className="round-circle-sm bg-red"></div>
                      </div>
                    </div>
                    <div className="networks-name" >
                      <div className="pl-2">
                        <span className="font-20-bold">Engine Failure Alert</span>
                        <span className="pl-2 font-12 text-light-grey">11:27AM</span>
                      </div>
                      <div className="pl-2">
                        <span className="font-14">At vero eos et accusamus et iusto odio…</span>
                      </div>
                    </div>
                  </div>
                </a>
              </li>
              <li>
                <a href="#" className="text-networks">
                  <div className="d-flex member pl-1">
                    <div className="fix-width">
                      <div className="round-circle-medium bg-grey">
                        <div className="round-circle-sm bg-blue"></div>
                      </div>
                    </div>
                    <div className="networks-name">
                      <div className="pl-2">
                        <span className="font-20-bold">Engine Owner</span>
                        <span className="pl-2 font-12 text-light-grey">11:27AM</span>
                      </div>
                      <div className="pl-2">
                        <span className="font-14">Good morning gentlem…</span>
                      </div>
                    </div>
                  </div>
                </a>
              </li>
            </ul>
          </div>
        </div>
      </Col>
    */}
  </Row>
);
