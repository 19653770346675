import React from 'react';
import SideCallout from "shared/components/templates/side_callout";
import AddOrEditProduct from './add_or_edit_products';
const Add_Sub_Product = (props) => {
  return (
    <>
      <SideCallout
        closeCallOut={props.closeBtn}
        position="position-fixed sub-product-callout"
        calloutHeading="Add Sub Products">

        <AddOrEditProduct
          isSubproduct={true}
          mainProduct= {props.mainProduct}
          allCategories={props.allCategories}
          closeBtn={props.closeBtn}
          cancelForm= {props.cancelForm}
          setSubProductList={props.setSubProductList}
          deleteSubProductItem = {props.deleteSubProductItem}
          getSubProductDetails={props.getSubProductDetails}
          productId= {props.selectedProductId}
          Add= {true}
          SubProductItem= {true}
        />
      </SideCallout>
    </>
  )
}

export default Add_Sub_Product;
