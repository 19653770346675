import React from "react";
import ReactPlayer from "react-player";
// import PlayCircleFilledIcon from '@mui/icons-material/PlayCircleFilled';
import classNames from "classnames";
import c from "./introduction.module.scss";

const Introduction = ({video_description, video_url}) => {
  return (
    <div className="AboutMainContainer pr-0 ff-Inter-Bold overflow-hidden">
      <div className="PanalHeader pr-3 ">
        <p className="section-head ff-Inter-Bold">
          Introduction
        </p>
        { 
          video_description &&
            <div>
              {video_description}
            </div>
        }
        {
          video_url &&
          <div className={classNames(c.reactPlayerContainer)}>
            <ReactPlayer
              config={{ file: { attributes: { controlsList: "nodownload" } } }}
              // light="https://sharemeister-crm.s3.us-west-2.amazonaws.com/ae-assets/Thumbnail+2.jpg"
              // url={"https://sharemeister-crm.s3.us-west-2.amazonaws.com/ae-assets/COMPRESSED+Fashion+Highlight+AE.mp4"}
              url={video_url}
              controls={true}
              playing={false}
              width="100%"
              height="100%"
              className={classNames(c.reactPlayer)}
            />
          </div>
        }
      </div>
    </div>
  );
};
export default Introduction;
