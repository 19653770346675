import React from "react";
import { useEffect, useState } from "react";
import { useParams, withRouter } from "react-router-dom";
import { toast } from "react-toastify";
import CourseCard from "./CourseCard";
import { CustomSlider } from "../../../shared/components/Slider";

import { useSelector } from "react-redux";
import { has } from "lodash";
import { Skeleton } from "@mui/material";
const ProfileCourse = ({ profileCourses, ...props }) => {
  const { userNeighborhoods } = useSelector((state) => state);
  const params = useParams();
  const [showAll, setShowAll] = useState(false);
  const handleClick = (data) => {
    if (userNeighborhoods && userNeighborhoods.length !== 0) {
      if (
        userNeighborhoods.some((item) => item.client_id === data.corporate_id)
      ) {
        handleArticleClick(data);
      } else {
        if (data) {
          toast.error(
            data.is_public
              ? `Please join ${data.corporate_name || data.author_name}`
              : `You are not a part of ${
                  data.corporate_name || data.author_name
                }`,
            { containerId: "private" }
          );
        }
      }
    }
  };
  const handleArticleClick = (data) => {
    props.history.push({
      pathname: `/campaign/${data.parent_id || data.id}`,
      calledFrom: props.type,
      state: {
        in_progress: data.in_progress === null || data.in_progress === false,
      },
      goBackLink: goBackLink(),
    });
  };
  const goBackLink = () => {
    if (props.type === "suggestedReads") {
      return "/home";
    } else if (props.type === "myArticles") return "/profile";
  };

  // const checkId = [];
  return (
    <div className="MainContainerScrollItem pr-0 ff-Inter-Bold overflow-hidden">
      <div className="PanalHeader pr-3 d-flex align-items-center justify-content-between">
        <p className="section-head ff-Inter-Bold d-flex align-items-center pl-5">
          {params && params.id ? "Courses" : "My Courses"}
        </p>
        {/* <img className="addIconStyling" src={AddIcon} alt="Addicon" /> */}
      </div>
      {/* <div className="card-container d-flex"> */}
      <div className="eventContainer pr-0 ff-Inter-Bold scroll-y">
        {props.loading ? (
          [1, 2, 3, 4].map((item) => (
            <Skeleton
              variant="rounded"
              width={260}
              height={270}
              key={item}
              className="mr-3 rounded-20 "
            />
          ))
        ) : profileCourses &&
          has(profileCourses, "length") &&
          [...profileCourses].filter((item) =>
            props.isNeighbourhood ? item : item.in_progress
          ).length > 0 ? (
          [...profileCourses]
            .filter((item) => (props.isNeighbourhood ? item : item.in_progress))
            .slice(0, showAll ? profileCourses.length + 1 : 4)
            .map((data, index) => {
              return (
                <CourseCard
                  isNoLeftMargin={true}
                  onClick={() => {
                    if (data.corporate_id === null) {
                      handleArticleClick(data);
                    } else {
                      handleClick(data);
                    }
                  }}
                  key={data.id}
                  data={data}
                  Totalcourses={profileCourses}
                  index={index}
                />
              );
            })
        ) : (
          <div className="d-flex align-items-center justify-content-center w-100">
            <div className="px-5">
              <span className="font-bold display-6">No Course found</span>
            </div>
          </div>
        )}
      </div>
      {[...profileCourses].filter((item) =>
        props.isNeighbourhood ? item : item.in_progress
      ).length > 4 && (
        <>
          {!showAll && (
            <h6
              className="text-primary text-center mt-3 cursor-pointer font-14"
              onClick={() => setShowAll(true)}
            >
              SEE MORE
            </h6>
          )}
          {showAll && (
            <h6
              className="text-primary text-center mt-3 cursor-pointer font-14"
              onClick={() => setShowAll(false)}
            >
              SEE LESS
            </h6>
          )}{" "}
        </>
      )}
      {/* </div> */}
    </div>
  );
};
export default withRouter(ProfileCourse);
