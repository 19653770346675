import React from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { STATUS } from "config/constants";
import {
  updateIsChatFormOpen,
  updateChatFriend,
  updateSeeAll,
} from "shared/actions";
import { FollowerInfo } from "shared/components/v2/campaign/components/follower_info.js";
import Avatar from "react-avatar";
import { updateUserProfileId } from "shared/actions";

class PeersCard extends React.Component {
  state = {
    showChatCallout: false,
  };

  userProfile = (id) => {
    this.props.updateSeeAll({
      isOpen: null,
      calledFrom: null,
    });
    this.props.updateUserProfileId(id);
    this.props.openProfilePopUp();
  };

  checkIcon = (props) => {
    if (props.type === "follower") {
      return (
        !this.props.item.is_followed && (
          <img
            onClick={(e) => this.handleFollowClick(e, props)}
            src={require("assets/images/icons/person_plus.svg")}
            className="mr-4 cursor-pointer"
            alt=""
          />
        )
      );
    } else if (props.type === "following") return "";
  };

  handleFollowClick = (e, props) => {
    e.stopPropagation();
    this.props.handleOnClick(props.item.id);
  };

  handleChatClick = (e) => {
    this.props.updateSeeAll({
      ...this.props.seeAll,
      isOpen: null,
    });
    e.stopPropagation();
    this.props.updateIsChatFormOpen(true);
    this.props.updateChatFriend(this.props.item);
  };

  interestAndChatFollow = () => (
    <>
      {this.props.showCharFollowButtons &&
        this.props.item.id !== this.props.userData.id && (
          <div className="text-right">
            <img
              onClick={(e) => this.handleChatClick(e)}
              src={require("assets/images/icons/chat_icon_dark.svg")}
              className="mr-4 pb-1 community-chat-icon cursor-pointer"
              alt=""
            />
            {this.props.type ? (
              this.checkIcon(this.props)
            ) : this.props.item.is_followed ? (
              ""
            ) : (
              <img
                onClick={(e) => this.handleFollowClick(e, this.props)}
                src={require("assets/images/icons/person_plus.svg")}
                className="mr-4 pb-1 cursor-pointer"
                alt=""
              />
            )}
          </div>
        )}
    </>
  );

  interestAndChatFollowDisabled = () => (
    <>
      <div className="group-invitation-pending">Invitation Pending</div>
    </>
  );

  hideOrShowInterest = () => {
    if (this.props.status) {
      if (this.props.status === STATUS.pending)
        return this.interestAndChatFollowDisabled();

      if (this.props.status === STATUS.approved)
        return this.interestAndChatFollow();
    } else return this.interestAndChatFollow();
  };

  paintUserName = (userName) => {
    if (parseInt(userName.length) < 19) {
      return this.props.item.user_name;
    } else {
      return this.props.item.user_name.substring(0, 16) + " ...";
    }
  };

  render() {
    return this.props.fromSeeAll === undefined || !this.props.fromSeeAll ? (
      <div className="new-community-card text-center">
        <div className="bg">
          <div className="flex flex-column align-items-center">
            <div className="mx-auto  mt-2">
              {this.props.item && this.props.item.profile_image_url ? (
                <img
                  src={this.props.item.profile_image_url}
                  className="community-card-pic"
                  alt=""
                />
              ) : (
                <Avatar
                  name={this.props.item.user_name}
                  round={true}
                  size={"61"}
                  textSizeRatio={1.75}
                  color="#007bb3"
                  maxInitials={1}
                  className={"community-avatar"}
                />
              )}
            </div>
            <div className="display-8 font-bold mb-1 mt-1 user-name">
              {this.props.item &&
                this.paintUserName(this.props.item.user_name.trim())}
            </div>
            <div className="font-12">
              {this.props.item.city ? this.props.item.city : ""}
              {this.props.item.city && this.props.item.country ? ", " : ""}
              {this.props.item.country ? this.props.item.country : ""}
            </div>
          </div>
          <div className="overlay">
            <h2 className="flex flex-row justify-content-center align-items-baseline pl-3 pt-0">
              <span
                className="pt-5 pr-4 cursor-pointer"
                onClick={() => this.userProfile(this.props.item.user_client_id)}
              >
                {this.props.item && this.props.item.profile_image_url ? (
                  <img
                    src={this.props.item.profile_image_url}
                    className="small-community-card-pic"
                    alt=""
                  />
                ) : (
                  <Avatar
                    name={this.props.item.user_name}
                    round={true}
                    size={"22"}
                    textSizeRatio={2.5}
                    color="#007bb3"
                    maxInitials={1}
                    className={"small-community-avatar"}
                  />
                )}
              </span>
              <span className="pb-5">{this.hideOrShowInterest()}</span>
            </h2>
          </div>
        </div>
      </div>
    ) : (
      <FollowerInfo
        id={this.props.item.id}
        selectedUser={this.props.item}
        name={this.props.item.user_name}
        imageUrl={this.props.item.profile_image_url}
        isFollowed={this.props.item.is_followed}
        closeCallout={this.props.closeCallout}
        fromSeeAll={this.props.fromSeeAll}
        setFollowerfollowing={() =>
          this.props.handleOnClick(this.props.item.id)
        }
      />
    );
  }
}

const mapActionToProps = {
  updateIsChatFormOpen,
  updateChatFriend,
  updateSeeAll,
  updateUserProfileId,
};

const mapStateToProps = (state) => ({
  userData: state.userData,
  seeAll: state.seeAll,
  userProfileId: state.userProfileId,
});

export default withRouter(
  connect(mapStateToProps, mapActionToProps)(PeersCard)
);
