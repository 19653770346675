import React, { useEffect } from 'react';
import { InputField } from 'shared/components/form_elements';
import { Form } from 'react-bootstrap';
import {CLIENT_ID} from 'config/constants/config';
import {NAME_SILHOUETTE_CLIENT_ID} from 'config/constants';
const stripe = window.Stripe(CLIENT_ID===NAME_SILHOUETTE_CLIENT_ID?process.env.REACT_APP_NS_STRIPE_PUBLISHABLE_KEY:process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY);
// const stripe = window.Stripe("pk_test_o7gaTjFdvdXoRdqmm22vJU66"); // Just for testing

function StripeCardElement(props){

  useEffect(() => {
    mountStripeElement();
  }, []);


  const mountStripeElement = () => {
    const elements = stripe.elements();

    const elementStyles = {
      base: {
        color: '#495057',
        fontSmoothing: 'antialiased',
        fontSize: '18px',
        '::placeholder': {
          color: '#344356',
          fontSize: '18px',
        }
      },
      invalid: {
        color: '#eb1c26',
        iconColor: '#fa755a',
        border: '1px solid red'
      },
      '::placeholder': {
        color: '#819a9f',
      },
    };

    const cardNumber = elements.create('cardNumber', {
      style: elementStyles,
      placeholder: 'Card number',

    });
    cardNumber.mount('#card-number');

    const cardExpiry = elements.create('cardExpiry', {
      style: elementStyles
    });
    cardExpiry.mount('#card-expiry');

    const cardCvc = elements.create('cardCvc', {
      style: elementStyles,
      placeholder: 'CSV',
    });
    cardCvc.mount('#card-cvc');

    const form = document.getElementById('payment-form');
    form.addEventListener('submit', function(e) {
      e.preventDefault();
      props.handleSubmit(stripe, cardNumber);
    });
  };

  return (
    <div className="col-12 mb-3 mb-md-0 px-0 card-details">

      <InputField
        required={true}
        type="text"
        handleChange={props.setFullName}
        placeholder="Full name"
      />

      <div id="card-number" className="form-control"></div>
      <div className="d-flex" >
        <div id="card-expiry" className="form-control"></div>

        <div className="form-element-dual d-flex" >
        <Form.Group className="mb-0" >
          <InputField
            type="text"
            handleChange={props.setZipCode}
            placeholder="ZIP code"
          />
        </Form.Group>
      </div>
      </div>
      <div id="card-cvc" className="form-control"></div>

      <div id="card-errors" className="error errorIcon" role="alert"></div>
    </div>
  );
}

export default StripeCardElement;
