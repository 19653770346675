import React, { useState } from "react";
import ControlPointIcon from "@mui/icons-material/ControlPoint";
import CircleIcon from "@mui/icons-material/Circle";
import { GrSearch } from "react-icons/gr";
import * as Utility from "../../../utils/utility";
import { PassportIcon } from "../../../shared/icons/icons";
export const LeftPanel = (props) => {
  const [search, setSearch] = useState("");
  let debouncedFn = null;

  const handleSearch = (ev) => {
    ev.persist();
    if (!debouncedFn) {
      debouncedFn = Utility.debounce(() => {
        const searchString = ev.target.value;
        setSearch(searchString);
      }, 500);
    }
    debouncedFn();
  };

  return (
    <div className=" h-100  d-flex flex-column" style={{ width: "22%" }}>
      <div className="p-3">
        <div className="d-flex flex-row justify-content-between px-1 pt-3">
          <h1 className="font-24-bold">{props.title}</h1>
          <span
            onClick={() => {
              props.setOpenCreate(true);
            }}
            className="cursor-pointer"
          >
            <ControlPointIcon />
          </span>
        </div>
        <div className="SeactionSearchContainer w-100">
          <div className="searchSectionWrapper bg-white">
            <input
              className="SeactionSearch"
              placeholder={`Search ${props.title}`}
              onChange={(ev) => {
                handleSearch(ev);
              }}
            />
            <span className="SeactionSearchIcon">
              <GrSearch className="regionIcon" />
            </span>
          </div>
        </div>
      </div>
      {/* const data = videos.filter(({name}) => (name.toLowerCase()).includes(search.toLowerCase())); */}
      {props.data &&
        props.data.length &&
        props.data
          .filter(
            ({ heading }) =>
              heading && heading.toLowerCase().includes(search.toLowerCase())
          )
          .map((item) => (
            <div
              key={item}
              className={`p-3 d-flex flex-row align-items-center justify-content-between cursor-pointer ${
                props.selectedDiscussion === item.id
                  ? "leftbar-items-border-active "
                  : "leftbar-items-border"
              }`}
              onClick={() => {
                props.title === "Discussions" && props.setSelectedIcon(0);
                props.setSelectedDiscussion(item.id);
              }}
            >
              <div className="d-flex flex-row align-items-center cursor-pointer">
                <PassportIcon w={25} h={25} />
                <span className="font-18-medium text-truncate ml-3">
                  {props.title === "Community" ? item.user_name : item.heading}
                </span>
              </div>
              <div className="left-panel-count">{props.count}</div>
            </div>
          ))}
    </div>
  );
};
