import React from 'react';

const DownloadComplete = (props) => {
    return (

        <div className="pwa-login-screen shar-form px-4 py-5 h-100 d-flex flex-column bg-primary text-white" >
        <div className="bg-white flex-center mx-auto splash-screen-logo" >
        <img src={process.env.PUBLIC_URL + '/icons/icon-72x72.png'} alt="GIL logo" />
        </div>
        <h2 className="text-center mt-3 mb-5">100%</h2>
        <p className="font-italic text-center display-7"> Download Complete.</p>
        <div className="bg-white overflow-hidden download-bar-outer mx-auto mt-2 w-100" >
          <div className="download-bar-inner h-100" />
        </div>
        <button className="mt-auto mb-5 text-white btn btn-splash-screen" onClick={props.handleButtonClick}> START</button>
    </div>
    );
}
export default DownloadComplete;