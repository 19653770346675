import React from "react";

const DiscussionsImages = ({ images, search }) => {
  const data = images.filter(({name}) => (name.toLowerCase()).includes(search.toLowerCase()));
  return (
    <div className="row w-100 ">
      {data.length > 0 ? data.map(({ id, url, name }) => {
        return (
          <div key={id} className="col-4 px-3 py-3">
            <img
              src={url}
              alt={name}
              className=""
              style={{ height: "160px" }}
            />
          </div>
        );
      }): <h4 className="m-3 mx-auto">No record found</h4>}
    </div>
  );
};

export default DiscussionsImages;
