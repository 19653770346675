import React, { useEffect, useRef, useState } from "react";
import { connect, useDispatch, useSelector } from "react-redux";
import { updateChatFriend, updateIsChatFormOpen } from "../../actions";
import SideCallout from "shared/components/templates/side_callout";
import { ChatOvalIcon, ChevronUpIcon } from "../../icons/icons";
import LeftSection from "./Components/LeftSection";
import RightSection from "./Components/RightSection";
import {
  GETALLJOINEDGROUPS,
  GETMYALLCHATFRIENDS,
  GROUPS,
} from "../../../config/constants/api_endpoints";
import * as Utility from "utils/utility";
import { GIL_CLIENT_ID } from "../../../config/constants";
import { toast } from "react-toastify";
import { withRouter } from "react-router-dom";
import { useCallback } from "react";
import socket from "../../../utils/sockets";
const CommunityChat = (props) => {
  const dispatch = useDispatch();
  const selectedUser = useSelector((state) => state.chatFriend);
  const [groupMembersData, setGroupMembersData] = useState([]);
  const [activeScreenName, setActiveScreenName] = useState("Landing");
  const [activeChatDetail, setActiveChatDetail] = useState(null);
  const [allGroups, setAllGroups] = useState([]);
  const [userChats, setUserChats] = useState([]);

  const addMember = (value) => {
    const newValue = [...groupMembersData];
    if (!newValue.find((data) => data.id === value.id)) {
      newValue.push(value);
    }
    setGroupMembersData(newValue);
  };

  const timerId = useRef(null);

  useEffect(() => {
    if (!selectedUser.is_discussion) {
      getUserAllChatsUser();
      getGroups();
    }
    return () => {
      clearInterval(timerId.current);
    };
  }, []);

  const removeMember = (id) => {
    let newValue = [...groupMembersData];
    newValue = newValue.filter((item) => item.id !== id);
    setGroupMembersData(newValue);
  };

  const getGroups = () => {
    const params = {
      clientId: GIL_CLIENT_ID,
      userClientId: props.userData.id,
      limit: "",
      forMyGroups: true,
    };

    Utility.sendRequest(GROUPS, 1, params, (err, res, body) => {
      body = JSON.parse(body);
      if (body.error) {
        toast.error(body.error.message, { containerId: "private" });
      } else {
        setAllGroups(body);
      }
    });
  };

  useEffect(() => {
    if (Object.keys(selectedUser).length > 0) {
      if (selectedUser.is_discussion) {
        setActiveScreenName("Chat");
        setActiveChatDetail(selectedUser);
      } else {
        if (
          selectedUser.statusConversation &&
          selectedUser.statusConversation == "startConversation"
        ) {
          setActiveScreenName("Landing");
          addMember(selectedUser);
        } else {
          setActiveScreenName("Chat");
          setActiveChatDetail(selectedUser);
        }
      }
    }

    return () => {
      dispatch(updateChatFriend({}));
    };
  }, [selectedUser]);

  const returnDecryptedMessage = (data, isgroup) => {
    const senderPrivateKey =
      data.chatPrivateKey === ""
        ? props.userData.private_key
        : data.chat_private_key;
    const receiverPrivateKey =
      data.chat_private_key === data.private_key
        ? props.userData.private_key
        : data.private_key;

    if (isgroup && data && data.group_private_key && data.recent_message) {
      const actualMessage = Utility.decryptAllMessages(
        data.recent_message,
        "",
        "",
        data.group_private_key
      );
      return actualMessage ? actualMessage : "";
    } else if (
      !Utility.isEmpty(senderPrivateKey) &&
      !Utility.isEmpty(receiverPrivateKey)
    ) {
      const sharedKey =
        senderPrivateKey.slice(0, 8) + receiverPrivateKey.slice(0, 8);
      const actualMessage = Utility.decryptSingleMessage(
        data.latest_message,
        sharedKey
      );
      if (actualMessage) {
        try {
          let subsTring = actualMessage;
          if (subsTring.length > 60) {
            subsTring = subsTring
              .substr(0, 60)
              .replaceAll("&nbsp;", " ")
              .replaceAll("<br>", "-/break-tag/-")
              .replaceAll("<span>", "-/span-tag/-");

            if (subsTring.includes("&")) {
              subsTring = subsTring.slice(0, subsTring.indexOf("&"));
            }
            if (subsTring.includes("<")) {
              subsTring = subsTring.slice(0, subsTring.indexOf("<"));
            }
            subsTring =
              subsTring
                .replaceAll(" ", "&nbsp;")
                .replaceAll("-/break-tag/-", "<br/>")
                .replaceAll("-/span-tag/-", "<span>") + "...";
          }
          return subsTring;
        } catch (err) {
          console.log(err);
          return actualMessage.length > 60
            ? actualMessage.substr(0, 60)
            : actualMessage;
        }
      }
    } else return "";
  };

  const getUserAllChatsUser = (search = "") => {
    const params = {
      user_client_id: props.userData.id,
      search,
    };
    Utility.sendRequest(GETMYALLCHATFRIENDS, 1, params, (err, res, body) => {
      body = JSON.parse(body);
      if (body.error) {
        console.log(body.error);
      } else {
        setUserChats(body.uniqueArr);
      }
    });
    if (timerId.current === null) {
      timerId.current = setInterval(() => {
        getGroups();
        getUserAllChatsUser();
      }, 5000);
    }
  };

  useEffect(() => {
    socket.on("chats", () => {
      getGroups();
      getUserAllChatsUser();
    });
  }, []);
  const [isChatClicked, setIsChatClicked] = useState(false);
  return (
    <>
      <SideCallout
        isHeaderVisible={false}
        innerPosition={
          !window.location.pathname.includes("campaign") && "pl-0"
        }
        position="position-fixed direct-message-callout mt-lg-5 w-100 pt-3"
        closeCallOut={() => {
          dispatch(updateIsChatFormOpen(false));
          dispatch(updateChatFriend({}));
        }}
      >
        <div className="CommunitySection communityTabSection">
          <div className={`CommunitySection-userNavigation  ${window.innerWidth <= 1199 && isChatClicked && 'd-none'}`}>
            <LeftSection
              addMember={addMember}
              getGroups={getGroups}
              getUserAllChatsUser={getUserAllChatsUser}
              activeChatDetail={activeChatDetail}
              activeScreenName={activeScreenName}
              setActiveScreenName={setActiveScreenName}
              selectedUser={selectedUser}
              setActiveChatDetail={setActiveChatDetail}
              handleSwitchAccount={props.handleSwitchAccount}
              setIsChatClicked={setIsChatClicked}
            />
          </div>
          <div className={`CommunitySection-ChatDisplays ${window.innerWidth <= 1199 && isChatClicked && 'w-100'}`}>
            <RightSection
              addMember={addMember}
              returnDecryptedMessage={returnDecryptedMessage}
              removeMember={removeMember}
              getGroups={getGroups}
              getUserAllChatsUser={getUserAllChatsUser}
              setGroupMembersData={setGroupMembersData}
              setActiveChatDetail={setActiveChatDetail}
              activeChatDetail={activeChatDetail}
              groupMembersData={groupMembersData}
              allGroups={allGroups}
              userChats={userChats}
              activeScreenName={activeScreenName}
              setActiveScreenName={setActiveScreenName}
              isChatClicked={isChatClicked}
              setIsChatClicked={setIsChatClicked}
            />
          </div>
        </div>
      </SideCallout>
    </>
  );
};

const mapActionToProps = {};

export default withRouter(CommunityChat);
