import React from 'react';
import  * as IconList from './icons'; 
import './icons.css';

export const IconsScreen = () => {
    const comp = []

    Object.keys(IconList).map((Obj) => {
      var Type = IconList[Obj];
      comp.push(<div className="icon-outer"><Type /><div className="icon-name" >{Obj}</div></div>)
    })
    
    return(
        <div className="icons-container" >
            {comp}
        </div>
    )
}

/*
    import { IconsScreen } from 'shared/icons/AllIcons';
    <IconsScreen />
*/