import React from 'react';
import { Form } from 'react-bootstrap';
import { FollowerInfo } from './follower_info';
import { MemberGroupIcon, ChevronUpIcon, CloseIcon } from 'shared/icons/icons';
import { InputField } from 'shared/components/form_elements';
import { Utility } from 'utils';
import { NoRecordFound } from 'shared/components/no_record_found';

export const ViewAgenda = (props) => {

  return(
    <>
       <div className="article-page-side h-100" >
         Agenda List
      </div>
    </>
  );
};
