import React, { Component } from 'react';
import ScreenView from 'shared/components/templates/screen_view';
import CustomGroupForm from 'shared/components/customGroup_form.js';
export default class CreateCustomGroupForm extends Component {

  render() {
    return (

      <>
        <ScreenView
          id={this.props.name + 'netork-category'}
          label={this.props.name + ' School'}
          formType='School'
          closeSummary={ this.props.onClose }>
          <CustomGroupForm
            closeSummary={this.props.onClose}
            isUpdateGroup={this.props.isUpdateGroup}
            data={this.props.data}
            getMenuDetails={this.props.getMenuDetails}
          />
          </ScreenView>
      </>
    );
  }
}
