import React, { useEffect } from "react";
import SideCallout from "shared/components/templates/side_callout";
import ControlPointIcon from "@mui/icons-material/ControlPoint";
import { Row, Col, Form, Button } from "react-bootstrap";
import { useState } from "react";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import RadioButtonUncheckedIcon from "@mui/icons-material/RadioButtonUnchecked";
import { Radio } from "@mui/material";
import Avatar from "react-avatar";
import { useSelector } from "react-redux";
import { CLIENT_ID } from "../../../../config/constants/config";
import { showLoader } from "../../../../shared/actions";
import * as Utility from "utils/utility";
import { toast } from "react-toastify";
import { GET_USERS_BY_NETWORKS } from "../../../../config/constants/api_endpoints";
import { CgClose } from "react-icons/cg";

const DiscussionsCreate = ({ handleClose, openCreate }, ...props) => {
  const [isPrivate, setIsPrivate] = useState(false);
  const [showInvites, setShowInvites] = useState(false);
  const [data, setData] = useState([]);
  const [search, setSearch] = useState("");
  const [selectOptions, setSelectOptions] = useState([]);
  const [invites, setInvites] = useState([]);
  const userData = useSelector((state) => state.userData);
  const [users, setUsers] = useState([]);
  const [formData, setFormData] = useState({
    title: "",
    message: "",
    invites: [],
    isPrivate: false,
  });

  const handleClick = (event, item) => {
    if (item) {
      if (!invites.find(({ id }) => id === item.id)) {
        setInvites([...invites, item]);
        setShowInvites(false);
      } else if (invites.length === 0) {
        setInvites([...invites, item]);
        setShowInvites(false);
      }
      if (invites.find(({ id }) => id === item.id)) {
        toast.warn("Participant Already Added");
      }
      event.preventDefault();
    }
  };

  const getUserDetails = () => {
    const params = {
      client_id: CLIENT_ID,
      type: "all",
      forCommunity: true,
    };

    showLoader(true);
    Utility.sendRequest(GET_USERS_BY_NETWORKS, 1, params, (err, res, body) => {
      body = JSON.parse(body);

      //   setIsApiResponse(true);
      showLoader(false);
      if (body.error) {
        toast.error(body.error.message, { containerId: "private" });
      } else {
        if (
          body.registrants &&
          CLIENT_ID === "0bc8dc7c-b5a3-4344-b313-819fc640f6b5"
        ) {
          const userList = [];
          const neighbourhood = [];
          body.registrants.sort(compare).forEach((element) => {
            if (element.network === "users") userList.push(element);
            else neighbourhood.push(element);
          });
          setUsers(userList);
          //   setNeighbourhoods(neighbourhood);
        } else {
          if (body.registrants) setUsers(body.registrants);
        }
      }
    });
  };

  function compare(a, b) {
    if (a.user_name < b.user_name) {
      return -1;
    }
    if (a.user_name > b.user_name) {
      return 1;
    }
    return 0;
  }

  useEffect(() => {
    getUserDetails();
  }, []);

  const handleRemoveValue = (i) => {
    const data = invites.filter((_, idx) => i !== idx);
    setInvites(data);
  };

  const handleSubmit = () => {
    setFormData({...formData, invites: invites, isPrivate: isPrivate})
    handleClose();
  }
  // console.log("Hello",invites)
  return (
    <>
      {openCreate && (
        <SideCallout
          closeCallOut={handleClose}
          position="position-fixed create-campaign-margin"
          calloutHeading="Add Discussions"
          calloutHeadingSvg={<ControlPointIcon />}
          isCreate={true}
          innerPosition="shadow-none pl-0"
        >
          <div>
            <Form className="audience-form">
              <div className="form-group">
                <input
                  required
                  type="text"
                  className="form-control form-control-sm w-100 box-shadow-create"
                  name="title"
                  id="title"
                  aria-describedby="helpId"
                  placeholder="Enter Title of Discussion"
                  onChange={(e) => {
                    setFormData({ ...formData, title: e.target.value });
                  }}
                />
                <div className="form-control form-control-sm w-100 box-shadow-create h-auto p-0 m-left-5 mb-3 invites-bottom">
                  <input
                    type="text"
                    className="form-control form-control-sm w-100 outline-none shadow-none m-0 border-none invites-input"
                    name="invites"
                    id="invites"
                    aria-describedby="helpId"
                    placeholder="Invite Participants to the Discussion"
                    onFocus={(e) => {
                      setShowInvites(true);
                      e.preventDefault();
                    }}
                    onChange={(e) => {
                      setSearch(e.target.value);
                      e.preventDefault();
                    }}
                  />
                  <div className="invites-container scroll-y">
                    {showInvites &&
                    users.filter((item) =>
                      search.length
                        ? item.user_name
                            .toLowerCase()
                            .includes(search.toLowerCase())
                        : item
                    ).length > 0
                      ? users
                          .filter((item) =>
                            search.length
                              ? item.user_name
                                  .toLowerCase()
                                  .includes(search.toLowerCase())
                              : item
                          )
                          .map((item, idx) => {
                            return (
                              <div
                                key={idx}
                                className="d-flex align-items-center invite-container ml-4 w-75 pl-4 my-2 cursor-pointer"
                                onClick={(event) => handleClick(event, item)}
                              >
                                <div className="d-flex align-items-center">
                                  {item.profile_image_url ? (
                                    <img
                                      src={item.profile_image_url || ""}
                                      className="img-fluid rounded-circle invites-image mr-4 "
                                      alt=""
                                    />
                                  ) : (
                                    <Avatar
                                      name={item && item.user_name}
                                      round={true}
                                      size={60}
                                      textSizeRatio={1.75}
                                      color="#007bb3"
                                      maxInitials={1}
                                      className={"mr-4"}
                                    />
                                  )}
                                  <div>
                                    <div className="font-20-bold user-name d-flex align-items-center">
                                      {item &&
                                        item.user_name &&
                                        item.user_name.trim()}
                                    </div>
                                    <div className="font-12 d-flex align-items-center invites-subtext">
                                      {item.city ? item.city : ""}
                                      {item.city && item.country ? ", " : ""}
                                      {item.country ? item.country : ""}
                                    </div>
                                  </div>
                                </div>
                              </div>
                            );
                          })
                      : showInvites && <h4>No Records Found</h4>}
                  </div>
                </div>
                {!showInvites &&
                  invites.map((item, idx) => {
                    return (
                      <div
                        key={idx}
                        className="d-flex align-items-center  justify-content-between mb-3 invite-container py-2 w-75 px-4 "
                      >
                        <div className="d-flex align-items-center">
                          {item.profile_image_url ? (
                            <img
                              src={item.profile_image_url || ""}
                              className="img-fluid rounded-circle invites-image mr-4 "
                              alt=""
                            />
                          ) : (
                            <Avatar
                              name={item && item.user_name}
                              round={true}
                              size={60}
                              textSizeRatio={1.75}
                              color="#007bb3"
                              maxInitials={1}
                              className={"mr-4"}
                            />
                          )}
                          <div>
                            <div className="font-20-bold user-name d-flex align-items-center">
                              {item && item.user_name && item.user_name.trim()}
                            </div>
                            <div className="font-12 d-flex align-items-center invites-subtext">
                              {item.city ? item.city : ""}
                              {item.city && item.country ? ", " : ""}
                              {item.country ? item.country : ""}
                            </div>
                          </div>
                        </div>
                        <div
                          onClick={(e) => {
                            handleRemoveValue(idx);
                            e.preventDefault();
                          }}
                          className="cursor-pointer"
                        >
                          <CgClose size={36} stroke={5} />
                        </div>
                      </div>
                    );
                  })}
                <textarea
                  type="text"
                  required
                  rows={3}
                  className="form-control form-control-sm w-100 box-shadow-create multi-line scroll-y"
                  name="message"
                  id="message"
                  aria-describedby="helpId"
                  placeholder="Add Welcome Message or share what the discussion is about to newcomers. All participants will see the message when they first join the discussion."
                  onChange={(e) => {
                    setFormData({ ...formData, message: e.target.value });
                  }}
                />
              </div>
              <div className="create-radio">
                <Radio
                  sx={{
                    "&:hover": {
                      bgcolor: "transparent",
                    },
                  }}
                  disableRipple
                  color="default"
                  checkedIcon={<CheckCircleIcon sx={{ color: "#272262" }} />}
                  icon={<RadioButtonUncheckedIcon sx={{ color: "#272262" }} />}
                  onClick={() => {
                    setIsPrivate(!isPrivate);
                  }}
                  checked={isPrivate}
                  {...props}
                />
                Private, Invite Only
              </div>
              <button
                disabled={
                  !(formData.message && formData.title && invites.length > 0)
                }
                onClick={handleSubmit}
                type="button"
                className="btn btn-primary create-button"
              >
                START DISCUSSION
              </button>
            </Form>
          </div>
        </SideCallout>
      )}
    </>
  );
};

export default DiscussionsCreate;
