import React from "react";
import { PlayIcon, PlayVideoIcon } from "../../../../shared/icons/icons";
import ReactPlayer from "react-player";

const DiscussionsVideos = ({ videos, search }) => {
  const data = videos.filter(({name}) => (name.toLowerCase()).includes(search.toLowerCase()));
  return (
    <div className="row w-100 ">
      {data.length > 0 ? data.map(({ id, url, thumbnail, name }) => {
        return (
          <div key={id} className="col-4 px-3 py-3">
            <div className=" react-player-container">
            <ReactPlayer
              playing={true}
              url={url}
              className="react-player-video"
              width="100%"
              height="160px"
              playIcon={<PlayVideoIcon />}
              light={thumbnail}
              controls={true}
              playsInline={true}
              loop={true}
            /></div>
          </div>
        );
      }): <h4 className="m-3 mx-auto">No record found</h4>}
    </div>
  );
};

export default DiscussionsVideos;
