import React from "react";
import Avatar from "react-avatar";
import FavoriteBorderIcon from "@mui/icons-material/FavoriteBorder";
import {
  ChatBubble,
  CloseIcon,
  CloseIconSharp,
  FavoriteFilledIcon,
  DownloadIcon,
} from "../../../shared/icons/icons";
import PersonAddAltIcon from "@mui/icons-material/PersonAddAlt";
import moment from "moment";
import { Utility } from "utils";
import Tooltip from "@material-ui/core/Tooltip";
import DeleteIcon from "@mui/icons-material/Delete";
import { BsDownload } from "react-icons/bs";

const InforCard = ({
  ImageSection,
  Heading,
  onClick,
  start_at,
  subHeading,
  onLeftSectionClick,
  onRightSectionClick,
  onSectionClick,
  isLike,
  leftSectionId = "",
  rightSectionId = "",
  imageSize,
  showLikeSvg,
  isEvent = false,
}) => {
  return (
    <div
      onClick={onSectionClick}
      className="w-100 cursor-pointer d-flex align-items-center Profile-InfoCard flex-nowrap"
    >
      <div className="Profile-InfoCard-profile">
        {leftSectionId === "event" || isEvent === true ? (
          <div className="profile-right-event-time-box">
            <div className="profile-right-event-time-box-day">
              {moment(start_at).format("ddd")}
            </div>
            <div className="profile-right-event-time-box-date">
              {new Date(start_at).getDate()}
            </div>
          </div>
        ) : ImageSection ? (
          <Avatar
            src={ImageSection || null}
            name={ImageSection}
            round={true}
            color="#007bb3"
            maxInitials={1}
            size={imageSize ? `${imageSize}` : 60}
            textSizeRatio={1.75}
            className="float-left mr-2"
          />
        ) : (
          <Avatar
            name={Heading}
            src={ImageSection}
            round={true}
            color="#007bb3"
            maxInitials={1}
            size={imageSize ? `${imageSize}` : 60}
            textSizeRatio={1.75}
            className="float-left mr-2"
          />
        )}
      </div>
      <div className="Profile-InfoCard-infoSection">
        <Tooltip title={Heading}>
          <div className="Profile-InfoCard-Heading">
            {Heading.length > 22 ? Heading.substring(0, 16) + "..." : Heading}
          </div>
        </Tooltip>

        {/* {!Utility.isEmpty(subHeading) ||
          subHeading !== "null" || */}
        {!Utility.isEmpty(subHeading) &&
          subHeading !== "null" &&
          subHeading !== "undefined" && (
            <div className="Profile-InfoCard-subHeading">{subHeading}</div>
          )}
      </div>
      {!showLikeSvg ? (
        <div
          onClick={onClick}
          className="Profile-InfoCard-rightSection cursor-pointer"
        >
          {leftSectionId === "like" &&
            (isLike ? (
              <FavoriteFilledIcon />
            ) : (
              <FavoriteBorderIcon className="Profile-InfoCard-rightSection-icon" />
            ))}
          {leftSectionId === "chat" && <ChatBubble />}
          {leftSectionId === "download" && <BsDownload onClick={onLeftSectionClick} className="text-success mr-2"/>}
          {rightSectionId === "delete" && <DeleteIcon onClick={onRightSectionClick} className="text-danger"/>}
          {/* {leftSectionId === "follow" && <PersonAddAltIcon />} */}
          {leftSectionId === "close" && <CloseIcon />}
        </div>
      ) : (
        ""
      )}
    </div>
  );
};

export default InforCard;
