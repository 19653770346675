import React, { useState } from "react";
import NewsBanner from "./NewsBanner";
import NewsHeader from "./NewsHeader";
import NewsLeftPanel from "./NewsLeftPanel";
import NewsRightPanel from "./NewsRightPanel";
import { useSelector } from "react-redux";
import { CLIENT_ID } from "../../../config/constants/config";
import {
  CAMPAIGN_DETAILS,
  CAMPAIGN_VIEWS,
  CAMPAIGN_SENTIMENT_DETAILS,
  CAMPAIGN_IS_LIKED,
} from "../../../config/constants/api_endpoints";
import { Utility } from "utils";
import { toast } from "react-toastify";
import { CAMPAIGN_TYPES_IDS } from "../../../config/constants";
import { useEffect } from "react";
import { useLocation } from "react-router-dom";
import { useDispatch } from "react-redux";
import { showLoader } from "../../actions";
import {  Skeleton } from "@mui/material";


const NewsDetails = () => {
  const userData = useSelector((state) => state.userData);
  const [newsDetails, setNewsDetails] = useState([]);
  const [viewers, setViewers] = useState([]);
  const [isLiked, setLiked] = useState(false);
  const isTab = Utility.pwaModeEnabled();
  const [newsDetailsLoading, setNewsDetailsLoading] = useState(false);
  const [newsLikeLoading, setnewsLikeLoading] = useState(false);


  const location = useLocation();
  const dispatch = useDispatch();

  const campaign_id = location.pathname.split("/")[2];

  const getAllMembers = (search = null) => {
    dispatch(showLoader(true));
    const params = {
      user_client_id: userData.id,
      campaign_id: campaign_id,
      search,
    };

    Utility.sendRequest(CAMPAIGN_VIEWS, 1, params, (err, res, body) => {
      body = JSON.parse(body);
      if (body.error) {
        toast.error(body.error.message, { containerId: "private" });
      } else {
        setViewers(body);
      }
      dispatch(showLoader(false));
    });
  };

  const getCampaignDetails = () => {
    // dispatch(showLoader(true));
    setNewsDetailsLoading(true);
    const params = {
      user_client_id: userData.id,
      campaign_id: campaign_id,
      publisher_network_id: CLIENT_ID,
    };

    Utility.sendRequest(CAMPAIGN_DETAILS, 1, params, (err, res, body) => {
      body = JSON.parse(body);

      if (body.error) {
        toast.error(body.error.message, { containerId: "private" });
      } else {
        if (body.data.length > 0) {
          const campaigns_data = body.data.filter(
            (item) => item.campaign_type_id !== CAMPAIGN_TYPES_IDS.section
          );
          setNewsDetails(campaigns_data);
        }
      }

      // dispatch(showLoader(false));
      setNewsDetailsLoading(false);
    });
  };

  const getSentimentDetails = () => {
    const params = {
      user_client_id: userData.id,
      campaign_id: campaign_id,
      client_id: CLIENT_ID,
    };

    Utility.sendRequest(
      CAMPAIGN_SENTIMENT_DETAILS,
      2,
      params,
      (err, res, body) => {
        body = JSON.parse(body);
        if (body.error) {
          toast.error(body.error.message, { containerId: "private" });
        } else {
          setLiked(body.is_active);
          getCampaignDetails();
          getCampaignLiked();
        }
      }
    );
  };

  const getCampaignLiked = () => {
    dispatch(showLoader(true));
    setnewsLikeLoading(true);

    const params = {
      campaign_id: campaign_id,
      user_client_id: userData.id,
      client_id: CLIENT_ID,
    };

    Utility.sendRequest(CAMPAIGN_IS_LIKED, 1, params, (err, res, body) => {
      body = JSON.parse(body);
      if (body.error) {
        toast.error(body.error.message, { containerId: "private" });
      } else {
        setLiked(body.is_active);
      }
      dispatch(showLoader(false));
      setnewsLikeLoading(false);
    });
  };

  const setCampaignViewed = () => {
    const params = {
      user_client_id: userData.id,
      campaign_id: campaign_id,
    };

    Utility.sendRequest(CAMPAIGN_VIEWS, 2, params, (err, res, body) => {
      body = JSON.parse(body);

      if (body.error) {
        toast.error(body.error.message, { containerId: "private" });
      } else {
        getAllMembers();
      }
    });
  };

  useEffect(() => {
    getCampaignDetails();
    getAllMembers();
    getCampaignLiked();
    setCampaignViewed();
  }, []);

  return (
    newsDetails &&
    newsDetails.length !== 0 && (
      <div className="background-sidebar-pages">
        <NewsHeader
          heading={newsDetails[0].heading}
          likes_count={newsDetails[0].likes_count}
          getSentimentDetails={getSentimentDetails}
          isLiked={isLiked}
          newsLikeLoading={newsLikeLoading}
        />

        <div className="newsDetailsContainer background-sidebar-pages">
        {newsDetailsLoading ? (
          <>
          <Skeleton
          animation="wave"
          variant="rectangular"
          width="100%"
          height={400}
        />
        <div className={`d-flex justify-content-between ${isTab ? "newsTabContent" : ""}`}>

        <Skeleton
          animation="wave"
          variant="rectangular"
          width= {isTab ? "700px" : "800px"}
          height={isTab ? "125px" : "400px"}
          className="mt-5"
        />

        <Skeleton
          animation="wave"
          variant="rectangular"
          width= {isTab ? "700px" : "360px"}
          height={isTab ? "700px" : "400px"}
          className="mt-5"
        />
        </div>
        </>

            // <Card
            //   style={{
            //     borderRadius: "10px",
            //     margin: "0 auto",
            //     width: isTab ? "743px" : "1240px",
            //   }}
            // >

            //   <CardContent>
            //     <Skeleton animation="wave" variant="text" height={30} />
            //     <Skeleton animation="wave" variant="text" height={30} />
            //     <Skeleton animation="wave" variant="text" height={30} />
            //     <Skeleton animation="wave" variant="text" height={30} />
            //   </CardContent>
            // </Card>
          )
        : <>
          <NewsBanner data={newsDetails[0]} userData={userData} />
          <div className={`d-flex justify-content-between ${isTab ? "newsTabContent" : ""}`}>
            {/* {isTab && <button
              className="btn btn-primary p-3 course-button setFullWidth websiteLink"
            // onClick={() => props.setSelectedItem(props.item)}
            >
              GO TO WEBSITE LINK
            </button>} */}
            {isTab && <NewsRightPanel viewers={viewers} />}
            <NewsLeftPanel
              campaign_id={campaign_id}
              description_1={newsDetails[0].description_1}
            />
            {!isTab && <NewsRightPanel viewers={viewers} />}
          </div>
          </>
          }
        </div>
      </div>
    )
  );
};

export default NewsDetails;
