import React from "react";
import { AiOutlineFileDone } from "react-icons/ai";
import { MdForum, MdPeople } from "react-icons/md";
import { CourseLessonIcon } from "../../../icons/icons";

const CourseDetailsHeader = (props) => {
  return (
    <div className="d-flex justify-content-between course-details-header-container">
      
      <div
        className={`panal-container cursor-pointer ${
          props.activePanal === "lesson" && "active-panal"
        } `}
        onClick={(e) => {
          props.handleChangepanal("lesson");
        }}
      >
        <CourseLessonIcon /> Lesson
      </div>
      <div
        className={`panal-container cursor-pointer ${
          props.activePanal === "assignments" && "active-panal"
        } `}
        onClick={() => {
          props.handleChangepanal("assignments");
        }}
      >
        <AiOutlineFileDone className="courseSidebarIcon" />
        Assignments
      </div>
      <div
        className={`panal-container cursor-pointer ${
          props.activePanal === "discussions" && "active-panal"
        } `}
        onClick={() => {
          props.handleChangepanal("discussions");
        }}
      >
        <MdForum className="courseSidebarIcon" />
        Discussions
      </div>
      <div
        className={`panal-container cursor-pointer ${
            props.activePanal === "peers" && "active-panal"
          } `}
        onClick={(e) => {
          props.handleChangepanal("peers");
        }}
      >
        <MdPeople className="courseSidebarIcon" />
        Peers
      </div>
    </div>
  );
};

export default CourseDetailsHeader;
