import React from 'react';
import EditorJSEmbed from "@editorjs/embed";
import Table from "@editorjs/table";
import List from "@editorjs/list";
import LinkTool from "@editorjs/link";
import Image from "@editorjs/image";
import Header from "@editorjs/header";
import Quote from "@editorjs/quote";
import CheckList from "@editorjs/checklist";
import Delimiter from "@editorjs/delimiter";
import SimpleImage from "@editorjs/simple-image";
import { API_PATH } from "../../config/constants/api_endpoints";
import * as Utility from "utils/utility";
import VideoTool from "editorjs-video-jdp";
import axios from "axios";
import Underline from "@editorjs/underline";
const FontFamily = require("editorjs-inline-font-family-tool");
const FontSize = require("editorjs-inline-font-size-tool");
const AlignmentTuneTool = require("editorjs-text-alignment-blocktune");

const getImageUrl = async () => {
  let res = "";
  try {
    const resp = await axios.get(`${API_PATH}/getImageUrl`);
    res = resp.data.data;
  } catch (err) {
    console.error(err);
  }
  return res;
};
const uploadfile = async (file) => {
  const value = await getImageUrl();
  if (value) {
    const imageURL = await Utility.uploadFile(file, value);
    return imageURL;
  }
  return "";
};
// const VideoTool = window.VideoTool;


export const EDITOR_JS_TOOLS = {
  embed: {
    class: EditorJSEmbed,
    config: {
      services: {
        youtube: true,
      },
    },
  },
  paragraph: {
    inlineToolbar: true,
    config: {
      placeholder: "What's your story... *",
    },
    tunes: ["alignmentTuneTool"],
  },
  table: { class: Table, inlineToolbar: true, tunes: ["alignmentTuneTool"] },
  underline: Underline,
  list: { class: List, inlineToolbar: true, tunes: ["alignmentTuneTool"] },
  fontSize: { class: FontSize },
  video: {
    class: VideoTool,
    config: {
      placeholder: "Paste image URL",
      uploader: {
        async uploadByFile(file) {
          const url = await uploadfile(file);
          if (url) {
            return {
              success: 1,
              file: {
                url,
                // any other image data you want to store, such as width, height, color, extension, etc
              },
            };
          } else {
            return {
              success: 0,
            };
          }
        },
      },
    },
  },
  fontFamily: FontFamily,
  alignmentTuneTool: AlignmentTuneTool,
  image: {
    class: Image,
    inlineToolbar: true,
    config: {
      placeholder: "Paste image URL",
      uploader: {
        async uploadByFile(file) {
          const url = await uploadfile(file);
          if (url) {
            return {
              success: 1,
              file: {
                url,
                // any other image data you want to store, such as width, height, color, extension, etc
              },
            };
          } else {
            return {
              success: 0,
            };
          }
        },
      },
    },
  },
  header: {
    class: Header,
    inlineToolbar: true,
    tunes: ["alignmentTuneTool"],
  },
  quote: { class: Quote, inlineToolbar: true },
  checklist: { class: CheckList, inlineToolbar: true },
  delimiter: { class: Delimiter, inlineToolbar: true },
  simpleImage: { class: SimpleImage, inlineToolbar: true },
};
