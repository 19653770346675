import React from "react";
import { Utility } from "../../../utils";

const Create_course = (props) => {
  return (
    <div
      className={`create-course ${
        window.location.pathname.includes("news") ? "createNewsBtn" : ""
      }
      ${window.location.pathname.includes("events") ? "createNewsBtn" : ""}
       ${
         window.location.pathname.includes("event-details")
           ? ""
           : `${Utility.pwaModeEnabled() ? "w-40" : "w-25"}`
       } text-center  bg-white p-3 button-radius ${props.class} mb-3`}
    >
      {props.heading === "CREATE AN EVENT" ? (
        // <Link to="/create-event">
          <button className="btn btn-primary p-2 course-button newsNestedButton"
          onClick={(e) => {
            e.preventDefault();
            props.onClick
              ? props.onClick()
              : !props.isHeader && props.setSelectedItem(props.item);
          }}
          >
            {props.isHeader
              ? props.heading
              : "CREATE " +
                (props.heading[0]
                  .toLowerCase()
                  .includes("a", "e", "i", "o", "u") ||
                props.heading[0].toLowerCase() === "e"
                  ? "AN "
                  : "A ") +
                props.heading}
          </button>
        // </Link>
      ) : (
        <button
          className="btn btn-primary p-2 course-button newsNestedButton"
          onClick={(e) => {
            e.preventDefault();
            props.onClick
              ? props.onClick()
              : !props.isHeader && props.setSelectedItem(props.item);
          }}
        >
          {props.isHeader
            ? props.heading
            : "CREATE " +
              (props.heading[0]
                .toLowerCase()
                .includes("a", "e", "i", "o", "u") ||
              props.heading[0].toLowerCase() === "e"
                ? "AN "
                : "A ") +
              props.heading}
        </button>
      )}
    </div>
  );
};
export default Create_course;
