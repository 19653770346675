import React, { Component } from "react";
import PasswordValidationFrame from "shared/components/password_validation_frame";
import VerifyUser from "./verifyUser";
import {
  Form,
  Image,
  Container,
  Spinner,
  Button,
  Dropdown,
} from "react-bootstrap";
import { Link } from "react-router-dom";
import { toast, ToastContainer } from "react-toastify";
import { InputField } from "shared/components/form_elements";
import { Utility } from "utils";
import {
  SIGN_UP,
  VERIFY_ACTIVATION_CODE,
  RESEND_CONFIRMATION_LINK,
} from "config/constants/api_endpoints";
import { CLIENT_ID } from "config/constants/config";
import CloseToast from "shared/components/close_toast";
import { BsFillCircleFill } from "react-icons/bs";
import { ChevronDownIcon } from "../../../shared/icons/icons";
import {
  COUNTRIES_BY_REGION,
  REGIONS_LIST,
} from "../../../config/constants/api_endpoints";

const validateFields = ["email", "password", "first_name", "last_name"];

const regionsData = [
  {
    region: "Global",
    color: "#000000",
    country: [],
  },
  {
    region: "Africa",
    color: "#812FC2",
    country: [
      "Angola",
      "Benin",
      "Botswana",
      "Burkina Faso",
      "Cameroon",
      "Central African Republic",
      "Chad",
      "Côte d’Ivoire",
      "DR Congo",
      "Eritrea",
      "Eswatini",
      "Ethiopia",
      "The Gambia",
      "Ghana",
      "Guinea",
      "Guinea-Bissau",
      "Kenya",
      "Lesotho",
      "Liberia",
      "Madagascar",
      "Malawi",
      "Mali",
      "Mauritius",
      "Morocco",
      "Mozambique",
      "Namibia",
      "Niger",
      "Nigeria",
      "Rwanda",
      "Senegal",
      "Seychelles",
      "Sierra Leone",
      "South Africa",
      "South Sudan",
      "Tanzania",
      "Uganda",
      "Zambia",
      "Zimbabwe",
    ],
  },

  {
    region: "Asia",
    color: "color: #1F71D9",
    country: [
      "Bangladesh",
      "Bhutan",
      "Cambodia",
      "India",
      "Indonesia",
      "Japan",
      "Malaysia",
      "Mongolia",
      "Myanmar",
      "Nepal",
      "Pakistan",
      "Philippines",
      "Singapore",
      "South Korea",
      "Sri Lanka",
      "Thailand",
    ],
  },

  {
    region: "Caribbean",
    color: "#E05A00",
    country: [
      "Antigua and Barbuda",
      "Barbados",
      "Grenada",
      "Guyana",
      "Haïti",
      "Jamaica",
      "St. Croix",
      "St. Kitts",
      "St. Lucia",
      "St. Vincent & the Grenadines",
      "Trinidad & Tobago",
    ],
  },

  {
    region: "Central Asia",
    color: "#00850D",
    country: ["Kazakhstan", "Kyrgyzstan", "Tajikistan", "Uzbekistan"],
  },

  {
    region: "Europe",
    color: "#CD0087",
    country: [
      "Albania",
      "Austria",
      "Azerbaijan",
      "Belgium",
      "Bosnia and Herzegovina",
      "Bulgaria",
      "Croatia",
      "Cyprus",
      "Czech Republic",
      "Denmark",
      "Estonia",
      "Finland",
      "France",
      "Germany",
      "Greece",
      "Hungary",
      "Ireland",
      "Israel",
      "Italy",
      "Kosovo",
      "Latvia",
      "Luxembourg",
      "Netherlands",
      "Northern Macedonia",
      "Norway",
      "Poland",
      "Portugal",
      "Russia",
      "Serbia",
      "Slovakia",
      "Spain",
      "Sweden",
      "Switzerland",
      "Turkey",
      "United Kingdom",
    ],
  },

  {
    region: "Latin America",
    color: "#C28700",
    country: [
      "Argentina",
      "Belize",
      "Bolivia",
      "Brazil",
      "Chile",
      "Colombia",
      "Costa Rica",
      "Cuba",
      "Dominican Republic",
      "Ecuador",
      "El Salvador",
      "Guatemala",
      "Honduras",
      "Mexico",
      "Nicaragua",
      "Panama",
      "Paraguay",
      "Peru ",
      "Uruguay",
      "Venezuela",
    ],
  },

  {
    region: "Middle East",
    color: "#008896",
    country: [
      "Algeria",
      "Egypt",
      "Jordan",
      "Kurdistan",
      "Palestine",
      "Qatar",
      "United Arab Emirates",
      "Yemen",
    ],
  },
  {
    region: "North America",
    color: "#DB381A",
    country: ["Canada", "United States of America"],
  },
  {
    region: "South Pacific",
    color: "#476682",
    country: ["Australia", "New Zealand", "Papua New Guinea"],
  },
];
class SignUp extends Component {
  constructor(props) {
    super(props);

    this.state = {
      formData: {},
      regions: {},
      RegionsList: [],
      countries: [],
      country: {},
      showLoader: false,
      isUserRegistered: false,
      screenName: "Register",
    };
  }

  componentDidMount = () => {
    this.getRegions();
  };

  handleChange = (e) => {
    let formData = this.state.formData;
    let password = {},
      showPasswordValidation = false;

    formData = Utility.validateAndReturnFormData(
      this,
      formData,
      e,
      validateFields
    );
    if (!Utility.isEmpty(formData.password)) {
      password = Utility.validatePassword(formData.password);
      showPasswordValidation = true;
    }

    this.setState({ formData, showPasswordValidation, password });
  };

  submit = (e, isSignUp = false) => {
    e.preventDefault();
    const formData = Utility.deepClone(this.state.formData);

    if (!Utility.showFormErrors(this, validateFields)) return;

    if (!Utility.isEmpty(this.state.password)) {
      const validatePassword = Object.values(this.state.password).map(
        (value) => {
          return value;
        }
      );
      if (validatePassword.includes(false)) return;
    }

    if (isSignUp) {
      formData.neighbourhood_id = this.state.country.id;
      this.setState({ showLoader: true });
      this.signUp(formData);
    } else {
      this.setState({
        screenName: "regionSelection",
      });
    }
  };
  handleCountry = (id, name) => {
    this.setState({ country: { id, name } });
  };

  signUp = (formData) => {
    formData.client_id = CLIENT_ID;
    formData.source = "web";

    Utility.sendRequest(SIGN_UP, 2, formData, (error, response, body) => {
      body = JSON.parse(body);

      this.setState({ showLoader: false });

      if (!body.error) {
        const verifyCodeParams = {
          email: formData.email,
          client_id: CLIENT_ID,
          is_token_require: true,
        };
        const resendVerificationParams = {
          email: formData.email,
          client_id: CLIENT_ID,
        };
        this.setState({
          screenName: "otp",
          verifyCodeParams,
          resendVerificationParams,
        });
      } else {
        this.setState({ showLoader: false });
        toast.error(body.error.message, { containerId: "public" });
      }
    });
  };

  handleRegionChange = (id, name) => {
    const params = {};
    Utility.sendRequest(
      `${COUNTRIES_BY_REGION}?region_id=${id}`,
      1,
      params,
      (err, res, body) => {
        body = JSON.parse(body);
        if (body.error) {
          toast.error(body.error.message, { containerId: "private" });
        } else {
          if (body && body.length > 0) {
            if (id === "1") {
              this.setState({ country: body[0] });
            } else {
              this.setState({ countries: body });
            }
          }
        }
      }
    );
    if (id !== "1") {
      this.setState({ country: {} });
    }
    this.setState({ regions: { id, name } });
  };

  getRegions = () => {
    const params = {};
    Utility.sendRequest(REGIONS_LIST, 1, params, (err, res, body) => {
      body = JSON.parse(body);
      if (body.error) {
        toast.error(body.error.message, { containerId: "private" });
      } else {
        const SignUpValues = body.data;
        this.setState({ RegionsList: SignUpValues });
      }
    });
  };

  onLogoClick = () => {
    this.props.history.push("/");
  };

  render() {
    return (
      <Container fluid="true" className="h-md-100 px-0 scroll-y">
        {this.state.screenName === "Register" && (
          <div className="container">
            <div className="register-outer p-sm-5 p-4 text-center mx-auto my-sm-5 my-3">
              <Image
                src={require("assets/images/logos/main-logo.png")}
                className="register-logo mb-4 cursor-pointer"
                alt="sharemeister"
                onClick={this.onLogoClick}
              />
              <h3 className="font-bold">It's all about you!</h3>
              <p className="my-4 display-7">
                Basic Info needed to get started.
              </p>
              <Form>
                <div className="new-landing-theme register-form">
                  <Form.Group>
                    <InputField
                      required={true}
                      type="text"
                      placeholder="First Name"
                      name="first_name"
                      ref="first_name"
                      handleChange={this.handleChange}
                    />
                  </Form.Group>
                  <Form.Group>
                    <InputField
                      required={true}
                      placeholder="Last Name"
                      type="text"
                      name="last_name"
                      ref="last_name"
                      handleChange={this.handleChange}
                    />
                  </Form.Group>
                  <Form.Group>
                    <InputField
                      required={true}
                      type="email"
                      placeholder="Email"
                      name="email"
                      ref="email"
                      handleChange={this.handleChange}
                    />
                  </Form.Group>
                  <Form.Group>
                    <InputField
                      required={true}
                      type="password"
                      placeholder="Password"
                      name="password"
                      ref="password"
                      handleChange={this.handleChange}
                    />
                    {this.state.showPasswordValidation && (
                      <PasswordValidationFrame password={this.state.password} />
                    )}
                  </Form.Group>
                </div>
                <p className="form-element-outer mx-auto display-8 mt-4 pt-3">
                  By selecting "Get Started" you agree to our{" "}
                  <Link
                    to="/terms_of_services"
                    target="_blank"
                    className="text-primary"
                  >
                    Terms of Service{" "}
                  </Link>{" "}
                  and{" "}
                  <Link
                    to="/privacy_policy"
                    target="_blank"
                    className="text-primary"
                  >
                    Privacy Policy{" "}
                  </Link>
                </p>
                <Link to="/users/sign_in" className="">
                  Already have an account?
                </Link>
                <div className="text-right mt-5">
                  <Button
                    type="submit"
                    className="btn btn-primary btn-login"
                    onClick={(e) => this.submit(e)}
                    disabled={this.state.showLoader}
                  >
                    {this.state.showLoader ? (
                      <Spinner animation="border" role="status" />
                    ) : (
                      "Get Started"
                    )}
                  </Button>
                </div>
              </Form>
            </div>
          </div>
        )}
        {this.state.screenName === "regionSelection" && (
          <div className="container h-90vh">
            <div className="register-outer p-sm-5 p-4 text-center mx-auto my-sm-5 my-3">
              <Button
                variant="link"
                className="color-primary text-left align-items-center"
                onClick={() => {
                  this.setState({ screenName: "Register" });
                }}
              >
                <img
                  className="width-10 align-baseline mr-3"
                  src={require("assets/images/chevron_left.svg")}
                  alt="left-icon"
                />
                <span className="display-7 font-bold">Back</span>
              </Button>
              <Image
                src={require("assets/images/logos/main-logo.png")}
                className="register-logo mb-4 cursor-pointer"
                alt="sharemeister"
                onClick={this.onLogoClick}
              />
              <h3 className="font-bold">It's all about you!</h3>
              <p className="my-4 display-7">
                Please Select Your Region and Country
              </p>
              <Form>
                <div className="new-landing-theme register-form">
                  <div className="mt-4">
                    <div className="my-3">
                      <Dropdown className="home-dropdown article-dropdown form-control h-auto py-0 mb-2">
                        <Dropdown.Toggle
                          variant="none"
                          className="py-0 text-left mb-0 ml-0 px-0 align-items-center justify-content-between w-100 border-bottom-0"
                          id="ranking"
                        >
                          {Object.keys(this.state.regions).length === 0 ? (
                            <span className="dropdown-title pl-3 font-20">
                              Select Your Region
                            </span>
                          ) : (
                            <span className="pl-3 font-20 dropdown-title">
                              {this.state.regions.name}
                            </span>
                          )}
                          <span className="pr-3">
                            <ChevronDownIcon />
                          </span>
                        </Dropdown.Toggle>

                        <Dropdown.Menu className="position-relative border-2 mt-0 pt-0 float-none p-0 font-16-bold  signup-menu-overflow scroll-y">
                          {this.state.RegionsList &&
                            this.state.RegionsList.length > 0 &&
                            this.state.RegionsList.map((e) => {
                              return (
                                <Dropdown.Item
                                  key={e.id}
                                  id="regions"
                                  className="font-20 py-2 dropdown-title signup-drop-menu"
                                  eventKey={["regions", e.name, "id", e.id]}
                                  onSelect={() =>
                                    this.handleRegionChange(e.id, e.name)
                                  }
                                >
                                  {e.name}
                                </Dropdown.Item>
                              );
                            })}
                        </Dropdown.Menu>
                      </Dropdown>
                      {this.state.regions.name === "Global" ? (
                        <></>
                      ) : (
                        <Dropdown className="home-dropdown article-dropdown form-control h-auto py-0">
                          <Dropdown.Toggle
                            variant="none"
                            disabled={
                              Object.keys(this.state.regions).length === 0
                            }
                            className="py-0 text-left mb-0 ml-0 px-0 align-items-center justify-content-between w-100 border-bottom-0"
                            id="ranking"
                          >
                            {Object.keys(this.state.country).length === 0 ? (
                              <span className="dropdown-title pl-3 font-20">
                                Select Your Country
                              </span>
                            ) : (
                              <span className="pl-3 font-20 dropdown-title ">
                                {this.state.country.name}
                              </span>
                            )}
                            <span className="pr-3">
                              <ChevronDownIcon />
                            </span>
                          </Dropdown.Toggle>

                          <Dropdown.Menu className="position-relative border-2 mt-0 pt-0 float-none p-0 font-16-bold signup-menu-overflow scroll-y">
                            {this.state.countries &&
                              this.state.countries.length > 0 &&
                              this.state.countries.map((e) => {
                                return (
                                  <Dropdown.Item
                                    key={e}
                                    id="regions"
                                    className="font-20 py-2 signup-drop-menu dropdown-title"
                                    eventKey={["country", e]}
                                    onSelect={() =>
                                      this.handleCountry(e.id, e.name)
                                    }
                                  >
                                    {e.name}
                                  </Dropdown.Item>
                                );
                              })}
                          </Dropdown.Menu>
                        </Dropdown>
                      )}
                    </div>
                  </div>
                  <div className="text-right mt-5">
                    <Button
                      type="submit"
                      disabled={
                        Object.keys(this.state.country).length === 0 &&
                        this.state.regions.id !== "1"
                      }
                      onClick={(e) => this.submit(e, true)}
                      className="btn btn-primary btn-login"
                    >
                      {this.state.showLoader ? (
                        <Spinner animation="border" role="status" />
                      ) : (
                        "Submit Details"
                      )}
                    </Button>
                  </div>
                </div>
              </Form>
            </div>
          </div>
        )}
        {this.state.screenName === "otp" && (
          <VerifyUser
            email={this.state.formData.email}
            platform={this.state.formData.email}
            history={this.props.history}
            showLogo={true}
            verifyCodeAPIName={VERIFY_ACTIVATION_CODE}
            resendActivationCodeAPI={RESEND_CONFIRMATION_LINK}
            showScreen={false}
            verifyCodeParams={this.state.verifyCodeParams}
            resendVerificationParams={this.state.resendVerificationParams}
          />
        )}
      </Container>
    );
  }
}

export default SignUp;
