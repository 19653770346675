import React, { useState } from 'react';
import Table from 'react-bootstrap/Table'
import ProgressBar from 'react-bootstrap/ProgressBar'
import { Row, Col } from 'react-bootstrap';
import moment from 'moment-timezone';
import { Utility } from 'utils';
import { Image } from 'react-bootstrap';
import Avatar from 'react-avatar';
import { CreditCardEdit } from 'shared/components/credit_card.js';


export default function UserLicensesSummary(props){
  const [isCreditCardEdit, setIsCreditCardEdit] = useState(false);
  const clientTimezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
  const today = moment().tz(clientTimezone).format();

  const notFirstInvoice = false;

  const { invoiceData, platformDetails, userData, userOwnerData, clientLicenseData } = props.licenseData;

  return (
    <>
      { !Utility.isEmpty(props.licenseData) &&
        <div className={`${props.fromFinanceModule ? 'pt-2' : 'pt-5'} pl-md-3`}>
          {!props.fromFinanceModule && <div className="font-18 b-700">Account Summary</div>}
          <div className="font-20 b-700 my-4">City Account</div>
          <div className={`${props.fromFinanceModule ? 'ml-0' : 'ml-lg-5'} box`}>
            {
                <div className="title">
                  {(invoiceData.is_paid && !props.fromLicense) ? 'Paid' : 'Active until 11:59:59 PM'} on {
                  moment(new Date(clientLicenseData.valid_until).getMonth() + 1, 'M').format('MMMM')} {new Date(clientLicenseData.valid_until).getDate()}, {new Date(clientLicenseData.valid_until).getFullYear()}.
                </div>
            }

            <div className="p-4">
              <Row className="profile-text-dark border-bottom">
                <Col md={3} className="border-right">
                  <div className="d-flex flex-column">
                    <div className="text-uppercase b-600">city</div>
                    <div className="text-uppercase">account</div>
                  </div>
                  <div className="mt-3">Base License</div>
                  <div className="mt-3 font-22 b-700">${Utility.paintUptoTwoDecimalPlaces(invoiceData.base_fee)}</div>
                  <p className="mt-3">City license includes up to 5,000 users and 1 Admin for end-to-end digital community experience.</p>
                </Col>
                <Col md={9} className="overflow-hidden">
                  <Row>
                    <Col md={12}>
                      <div className="text-uppercase b-600">Monthly report</div>
                      <div className="text-capitalize">
                        <>
                          {moment(new Date(clientLicenseData.valid_from).getMonth() + 1, 'M').format('MMMM')}, {new Date(clientLicenseData.valid_from).getDate()} {new Date(clientLicenseData.valid_from).getFullYear()} - {moment(new Date(clientLicenseData.valid_until).getMonth() + 1, 'M').format('MMMM')}, {new Date(clientLicenseData.valid_until).getDate()} {new Date(clientLicenseData.valid_until).getFullYear()}
                        </>
                      </div>
                    </Col>
                  </Row><Row className="h-100">
                    <Col md={3} className="pr-0">
                      <div className="border-right h-100">
                        <div className="mt-3">Users</div>
                        <div className="mt-3 font-22 b-700">1</div>
                        <div className="text-primary b-700 font-16">+$0</div>
                        <div className="mt-4 pr-3">
                          <p className="mb-0 font-13">$10 per 1k users</p>
                          {/*<ProgressBar now={60} />*/}
                        </div>
                      </div>
                    </Col>
                    <Col md={3} className="pr-0">
                      <div className="border-right h-100">
                        <div className="mt-3">Admins</div>
                        <div className="mt-3 font-22 b-700">1</div>
                        <div className="text-primary b-700 font-16">+$0</div>
                        <div className="mt-4 pr-3">
                          <p className="mb-0 font-13">${Utility.paintUptoTwoDecimalPlaces(invoiceData.per_admin_fee)} per 1 Admin</p>
                          {/*<ProgressBar now={60} />*/}
                        </div>
                      </div>
                    </Col>
                    <Col md={3} className="pr-0">
                      <div className="border-right h-100">
                        <div className="mt-3">Storage</div>
                        <div className="mt-3 font-22 b-700">0</div>
                        <div className="text-primary b-700 font-16">+$0.00</div>
                        <div className="mt-4 pr-3">
                          <p className="mb-0 font-13">${Utility.paintUptoTwoDecimalPlaces(invoiceData.per_data_usage_fee)} per GB</p>
                          {/*<ProgressBar now={60} />*/}
                        </div>
                      </div>
                    </Col>
                    <Col md={3}>
                      <div className="mt-3">Transaction</div>
                      <div className="mt-3 font-22 b-700">$0</div>
                      <div className="text-primary b-700 font-16">+$0</div>
                      <div className="mt-4 pr-3">
                        <p className="mb-0 font-13">${Utility.paintUptoTwoDecimalPlaces(invoiceData.per_transaction_percent_fee)} + 5% per transaction</p>
                      </div>
                    </Col>
                  </Row>
                </Col>
              </Row>
              <Row className="profile-text-dark">
                <Col md={3} className="border-right py-4">
                  <div className="d-flex flex-column">
                    <div className="text-uppercase b-700 font-13">Account Information</div>
                  </div>
                  <div className="mt-4 mb-2 b-500">City Name</div>
                  <div className="d-flex align-items-center">
                    {
                      userData.city_image_url ?
                        <Image className="rounded-circle" height={20} width={20} src={userData.city_image_url} alt="" />
                      :
                        <Avatar
                          name={invoiceData.platform_name}
                          round={true}
                          size="20"
                          textSizeRatio={1.75}
                          color='#007bb3'
                          maxInitials={1}
                        />
                    }
                    <div className="ml-3">{invoiceData.platform_name}</div>
                  </div>
                  <div className="mt-4 mb-2 b-500">City Owner</div>
                  <div className="d-flex align-items-center">

                {
                    userOwnerData.profile_image_url ?
                    <Image className="rounded-circle" height={20} width={20} src={userOwnerData.profile_image_url} alt="" />
                    :
                      <Avatar
                        name={invoiceData.user_name}
                        round={true}
                        size="20"
                        textSizeRatio={1.75}
                        color='#007bb3'
                        maxInitials={1}
                      />
                  }
                  <div className="ml-3 text-capitalize">{invoiceData.user_name}</div>
                </div>
                <div className="mt-4 mb-2 b-500">City Address</div>
                <div className="d-flex align-items-start">
                  <Image className="mt-1 licenses-icon-city" src={require('assets/images/profile/new-profile/address.png')} alt="" />
                  <div className="ml-3">
                    <p className="mb-0">{userData.client_address1}</p>
                  {/*<p className="mb-0">Address linke 2</p>
                    <p className="mb-0">{props.userData.client_city}</p>
                    <p className="mb-0">{props.userData.country}</p>
                    */}
                  </div>
                </div>
                <div className="mt-4 mb-2 b-500">City Contact</div>
                <div className="d-flex align-items-center mb-2">
                  <Image className="licenses-icon-call" src={require('assets/images/profile/new-profile/call-incoming.svg')} alt="" />
                  <div className="ml-3">
                    {Utility.isEmpty(props.userData.phone_cellular) ? 'N/A' : props.userData.phone_cellular}
                  </div>
                </div>
                <div className="d-flex align-items-center mb-2">
                  <Image className="licenses-icon-mail" src={require('assets/images/profile/new-profile/email.svg')} alt="" />
                  <div className="ml-3">
                    {invoiceData.user_email}
                  </div>
                </div>
                <div className="d-flex align-items-center">
                  <Image className="licenses-icon-web" src={require('assets/images/profile/new-profile/website.svg')} alt="" />
                  <div className="ml-3">
                    {Utility.isEmpty(userData.website) ? 'N/A' : userData.website}
                  </div>
                </div>
                <div className="mt-4 mb-2 b-500">Card on File</div>
                <div className="d-flex align-items-center">
                  <Image className="licenses-icon-card" src={require('assets/images/profile/new-profile/card.png')} alt="" />
                  <div className="ml-3">
                    Card ending in - {props.userData && props.userData.cards && props.userData.cards.cc_last_4_digits}
                  </div>
                </div>
                </Col>
                <Col md={9} className="overflow-hidden py-4">
                  <Row>
                    <Col md={12}>
                      <div className="text-uppercase b-700 font-13">Statement</div>
                    </Col>
                    <Col md={12}>
                      <div className="d-flex justity-content-between align-items-end">
                        <div className="mt-3">
                          <div className="text-primary b-700 font-20">
                            ${Utility.paintUptoTwoDecimalPlaces(invoiceData.total_invoice_amount)}
                          </div>
                          {notFirstInvoice &&
                            <p>Amount Due from {moment(new Date(clientLicenseData.valid_from).getMonth() + 1, 'M').format('MMMM')}, {new Date(clientLicenseData.valid_from).getDate()} {new Date(clientLicenseData.valid_from).getFullYear()} - {moment(new Date(clientLicenseData.valid_until).getMonth() + 1, 'M').format('MMMM')}, {new Date(clientLicenseData.valid_until).getDate()} {new Date(clientLicenseData.valid_until).getFullYear()}
                            </p>
                          }
                        </div>
                        <div className="ml-auto mb-3">Remaining Billing Cycle: <span className="b-600">0</span> day</div>
                      </div>
                    </Col>
                    <Col md={12}>
                      <Table responsive striped hover className="profile-text-dark city-account-summary">
                        <thead>
                          <tr>
                            <th className="font-20 border-top-0 border-bottom-0">Item</th>
                            <th className="font-20 border-top-0 border-bottom-0">Volume</th>
                            <th className="font-20 border-top-0 border-bottom-0">Price per Unit</th>
                            <th className="font-20 border-top-0 border-bottom-0 text-right">Price</th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr>
                            <td>Base License</td>
                            <td>1</td>
                            <td>${Utility.paintUptoTwoDecimalPlaces(invoiceData.base_fee)}</td>
                            <td className="text-right">$500.00</td>
                          </tr>
                          <tr>
                            <td>Users</td>
                            <td>1</td>
                            <td>${Utility.paintUptoTwoDecimalPlaces(invoiceData.per_user_fee)} per 1 User</td>
                            <td className="text-right">$0.00</td>
                          </tr>
                          <tr>
                            <td>Admins</td>
                            <td>1</td>
                            <td>${Utility.paintUptoTwoDecimalPlaces(invoiceData.per_admin_fee)} per 1 Admin</td>
                            <td className="text-right">$0.00</td>
                          </tr>
                          <tr>
                            <td>Storage</td>
                            <td>0</td>
                            <td>${Utility.paintUptoTwoDecimalPlaces(invoiceData.per_data_usage_fee)} per GB</td>
                            <td className="text-right">$0.00</td>
                          </tr>
                          <tr>
                            <td>Transactions</td>
                            <td>$0</td>
                            <td>$0.30 + 5%</td>
                            <td className="text-right">$0.00</td>
                          </tr>
                          <tr>
                            <td className="b-600" colSpan="3">Sub-total</td>
                            <td className="text-right b-600">${Utility.paintUptoTwoDecimalPlaces(500)}</td>
                          </tr>
                          <tr>
                            <td colSpan="2">Tax</td>
                            <td>{invoiceData.tax_percentage}%</td>
                            <td className="text-right">{Utility.paintUptoTwoDecimalPlaces(invoiceData.tax_amount)}</td>
                          </tr>
                          <tr>
                            <td colSpan="3"><span className="b-600">Total</span>
                            {
                              notFirstInvoice &&
                              <>
                                (Due on {moment(new Date(clientLicenseData.valid_until).getMonth() + 1, 'M').format('MMMM')}, {new Date(clientLicenseData.valid_until).getDate()} {new Date(clientLicenseData.valid_until).getFullYear()})
                              </>
                            }
                            </td>
                            <td className="text-right b-600">${Utility.paintUptoTwoDecimalPlaces(invoiceData.total_invoice_amount)}</td>
                          </tr>
                        </tbody>
                      </Table>
                    </Col>
                  </Row>
                </Col>
              </Row>
            </div>
          </div>
        </div>
      }

      {
        isCreditCardEdit &&
        <CreditCardEdit
          handleClose={() => setIsCreditCardEdit(false)}
          userData={props.userData}
          marginTop='mt-5 pt-2'
        />
      }
    </>
  );
}
