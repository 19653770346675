import React from "react";
import Avatar from "react-avatar";
const CourseCard = ({
  data,
  Totalcourses,
  index = 0,
  onClick,
  isNoLeftMargin,
}) => {
  return (
    <div
      key={data.id}
      onClick={onClick}
      className={`course-card cursor-pointer course-card-wrapper bg-white ${
        Totalcourses && Totalcourses.length === index + 1 && "mr-2"
      } ${index === 0 && !isNoLeftMargin && "ml-2"}  ${
        index > 0 && !isNoLeftMargin && "ml-3"
      }`}
    >
      {data.image_url ? (
        <span className={"image-container"}>
          <img
            className={"course-image article-card-image"}
            src={data.image_url}
            alt="#"
          />
        </span>
      ) : data.course_image_url ? (
        <span className={"image-container"}>
          <img
            className={"course-image article-card-image"}
            src={data.course_image_url}
            alt="#"
          />
        </span>
      ) : (
        <div className=" d-flex no-image-container border-right-0 w-100 align-items-center no-image-course">
          <Avatar
            name={data.author_name}
            round={true}
            size={"61"}
            textSizeRatio={1.75}
            color="#007bb3"
            maxInitials={1}
            className={"community-avatar"}
          />
        </div>
      )}
      <div className="course-card-content">
        <span className={"course-title-container mt-1 text-lowercase"}>
          {data.course_name
            ? data.course_name.length > 20
              ? data.course_name.slice(0, 20) + "..."
              : data.course_name
            : data.heading && data.heading.length > 20
            ? data.heading.slice(0, 20) + "..."
            : data.heading}
        </span>

        {((data.region_details && data.region_details.REGION_NAME) ||
          data.community_name ||
          data.region ||
          data.client_name ||
          data.country) && (
          <div
            className={`d-flex align-items-center text-golden ${
              (data.region || data.country) && "pt-5"
            }`}
          >
            {(data.region ||
              (data.region_details && data.region_details.REGION_NAME) ||
              data.country ||
              data.community_name) && (
              <input type="checkbox" id="region" className="checkbox-round" />
            )}
            <label htmlFor="region" className="mb-0 ml-1">
              {" "}
              {data.region
                ? data.region + "/"
                : data.region_details &&
                  data.region_details.REGION_NAME &&
                  data.region_details.REGION_NAME + "/"}
              {data.country
                ? data.country
                : data.community_name
                ? data.community_name
                : data.client_name}
            </label>
          </div>
        )}
      </div>
      {/* <span className="course-lesson-container">
                            {" "}
                            {sum(
                                data.sub_campaign_count.map((data) => {
                                    return data !== null ? parseInt(data) : 0;
                                })
                            )}
                            Lesson
                        </span> */}
    </div>
  );
};

export default CourseCard;
