import React, { Component } from 'react';
import { Container } from 'react-bootstrap';
import { connect } from 'react-redux';
import { toast } from 'react-toastify';
import { Utility } from 'utils';
import { showLoader } from 'shared/actions/index.js';
import { TOOLS, PUBLISHER_NETWORK_ID } from "config/constants";
import { TOOL_CAMPAIGN, GIFT_CARD_BRANDS, GIFT_CARDS, AUDIENCES } from 'config/constants/api_endpoints';
import GiftCardForm from 'scenes/tools/toolbox/gift_cards/public/components/gift_card_form';
import Audience from 'scenes/tools/toolbox/gift_cards/public/components/audience';
import ThankYouScreen from 'scenes/tools/toolbox/gift_cards/public/components/thankyou_screen';

const screen = {
  gift_card: 1,
  audience: 2,
  thankyou: 3
};

 class GiftCard  extends Component {
  constructor(props) {
    super(props);

    this.state = {
      toolData: {},
      selectedScreen: screen.gift_card,
      beneficiaryNickName: '',
      selectedBeneficiaryName: '',
      beneficiaries: [],
      selectedBrand: {},
      giftCardBrands: [],
      communityId: {},
      giftCardTransactionId: '',
      toolCampaignId: '',
      audienceData: [],
    };

    this.props.showLoader(true);
  }

  componentDidMount() {
    this.getGiftCardDetails();
    this.getGiftCardBrands();
  }

  getGiftCardDetails = () => {
    const id = this.extractIdFromUrl();

    if (!Utility.isEmpty(id)) {
      const params = { ':tool_id': TOOLS.gift_cards, ':id': id };

      this.setState({ toolCampaignId: id });

      const baseURL = Utility.getAPIEndpointWithParams(TOOL_CAMPAIGN, params);

      Utility.sendRequest(baseURL, 1, {}, (error, response, body) => {
        body = JSON.parse(body);

        if (!body.error) {
          this.setState({ toolData: body });
          this.checkBeneficiaryNickNamePresence(body);
          this.getBeneficiaries(body);
        } else {
          toast.error(body.error.message, { containerId: 'public' });
        }

         this.props.showLoader(false);
      });
    } else {
       this.props.showLoader(false);
      toast.error('Invalid URL', { containerId: 'public' });
    }
  }

  extractIdFromUrl = () => {
    const props = this.props;
    if(!(props.match && props.match.params && props.match.params.id)) return;

    const id = Utility.decode64(props.match.params.id);

    if(id.indexOf('gc') === -1 || id.indexOf('fg') === -1) return '';

    return id.split('gc')[1].split('fg')[0];
  }

  checkBeneficiaryNickNamePresence = (data) => {
    if(data && !Utility.isEmpty(data.beneficiary_nick_name)) {
      const communityId = (data.client_id).toString();

      this.setState({ beneficiaryNickName: data.beneficiary_nick_name, communityId: communityId, selectedBeneficiaryName: data.beneficiary_nick_name });
    }
  }

  getBeneficiaries = (data) => {
    if(data && !Utility.isEmpty(data.ToolCampaignBeneficiaries)) {
      this.setState({ beneficiaries: data.ToolCampaignBeneficiaries });
    }
  }

  getGiftCardBrands = () => {
    Utility.sendRequest(GIFT_CARD_BRANDS, 1, { limit: 1000 }, (error, response, body) => {
      body = JSON.parse(body);

      if (!body.error) {
        this.setState({ giftCardBrands: body.data });
      } else {
        toast.error(body.error.message, { containerId: 'public' });
      }
    });
  }

  saveGiftCard = (data) => {
    if(Utility.isEmpty(data.community_id)) data['community_id'] = this.state.communityId;

    data['tool_campaign_id'] = this.state.toolCampaignId;

    const selectedBrand = this.state.giftCardBrands.find(obj => obj.brand_id === data.brand_id);
    const selectedBeneficiary = this.state.beneficiaries.find(obj => obj.community_id === parseInt(data.community_id));
    let selectedBeneficiaryName = this.state.selectedBeneficiaryName;

    if(!Utility.isEmpty(selectedBeneficiary)) selectedBeneficiaryName = selectedBeneficiary.community_name;

    this.setState({ selectedBrand, selectedBeneficiaryName: selectedBeneficiaryName });
    this.getOtherUrlParams(data);
    this.props.showLoader(true);

    Utility.sendRequest(GIFT_CARDS, 2, data, (error, response, body) => {
      body = JSON.parse(body);

      if (!body.error) {
        this.props.showLoader(false);
        this.setState({ giftCardTransactionId: body.id, selectedScreen: screen.audience });
      } else {
        this.props.showLoader(false);
        toast.error(body.error.message, { containerId: 'public' });
      }
    });
  }

  getOtherUrlParams = (data) => {
    data['client_id'] = PUBLISHER_NETWORK_ID;
    data['cs_id'] = null;
    data['smc_id'] = null;
    const searchHash = new URLSearchParams(this.props.location.search);

    if(Utility.isAllSocialLinkParamsExist(searchHash)) {
      data['client_id'] = searchHash.get('client_id');
      data['cs_id'] = searchHash.get('cs_id');
      data['smc_id'] = searchHash.get('smc_id');
    }
  }

  saveAudience = (data) => {
    if(!Utility.isEmpty(this.state.audienceData)) data['audience_id'] = this.state.audienceData[0].id;

    data['publisher_network_id'] = PUBLISHER_NETWORK_ID;
    data['tool_id'] = TOOLS.gift_cards;
    data['tool_campaign_id'] = this.state.toolCampaignId;
    data['gcfg_transaction_id'] = this.state.giftCardTransactionId;
    delete data['is_terms_and_service_agreement'];

    if (Utility.isEmpty(this.state.audienceData)) {
      this.createAudience(data);
    } else {
      this.updateAudience(data);
    }
  }

  createAudience(data) {
    this.props.showLoader(true);
    Utility.sendRequest(AUDIENCES, 2, data, (error, response, body) => {
      body = JSON.parse(body);
      if (!body.error) {
        this.setState({ audienceData: body.data, selectedScreen: screen.thankyou });
        this.props.showLoader(false);
      } else {
        toast.error(body.error.message, { containerId: 'public' });
        this.props.showLoader(false);
      }
    });
  }

  updateAudience(data) {
    this.props.showLoader(true);
    Utility.sendRequest(AUDIENCES + `/${this.state.audienceData[0].id}`, 3, { email: data.email }, (error, response, body) => {
      body = JSON.parse(body);
      if (!body.error) {
        this.setState({ audienceData: body.data, selectedScreen: screen.thankyou });
        this.props.showLoader(false);
        toast.success('Updated Successfully', { containerId: 'public' });
      } else {
        toast.error(body.error.message, { containerId: 'public' });
        this.props.showLoader(false);
      }
    });
  }

  backSteps = () => {
    this.setState({ selectedScreen: screen.gift_card });
  }

  render() {
    return(
      <Container>
        <div className="giftCard-img mx-auto mb-5">
          {
            (!Utility.isEmpty(this.state.toolData) && !Utility.isEmpty(this.state.toolData.image_url)) &&
            <img src={require("assets/images/gift_card_logo.png")} alt="giftCard logo" />
          }
        </div>
        {
          (this.state.selectedScreen === screen.gift_card && (!Utility.isEmpty(this.state.toolData))) &&
          <GiftCardForm
            colorCode={this.state.toolData.color_code}
            beneficiaryNickName={this.state.beneficiaryNickName}
            beneficiaries={this.state.beneficiaries}
            giftCardBrands={this.state.giftCardBrands}
            saveGiftCard={this.saveGiftCard.bind(this)}
          />
        }
        {
          (this.state.selectedScreen === screen.audience) &&
          <Audience
            colorCode={this.state.toolData.color_code}
            selectedBrand={this.state.selectedBrand}
            selectedBeneficiary={this.state.selectedBeneficiaryName}
            saveAudience={this.saveAudience.bind(this)}
            backSteps={this.backSteps}
          />
        }
        {
          (this.state.selectedScreen === screen.thankyou) &&
          <ThankYouScreen
            selectedBrand={this.state.selectedBrand}
            colorCode={this.state.toolData.color_code}
            audienceData={this.state.audienceData}
            updateAudience={this.updateAudience.bind(this)}
            selectedBeneficiary={this.state.selectedBeneficiaryName}
            urlLocation={this.state.toolData.url_location}
            backSteps={this.backSteps}
          />
        }
        {
          (!Utility.isEmpty(this.state.toolData)) &&
          <div className="text-center mt-4">
            <img src={require("assets/images/giftcard-for-good.png")} alt="giftcard for good" className="mt-5 mb-3" />
          </div>
        }
      </Container>
    );
  }
}

const mapActionToProps = { showLoader };

const mapStateToProps = (state) => ({
  isLoading: state.isLoading
});

export default connect(mapStateToProps, mapActionToProps)(GiftCard);
