import React from "react";
import { CLIENT_ID } from "config/constants/config";

export default function termsOfServices() {
  return (
    <React.Fragment>
      {
        // ETHOS TERMS AND CONDITIONS
        CLIENT_ID === "0bc8dc7c-b5a3-4344-b313-819ppzhau6b5" ? (
          <section className="main-container">
            <div className="container">
              <div className="py-2 mb-5 text-light-black terms-services">
                <h3 className="ethos-tnc display-3 text-black-500 font-weight-bold mt-4 mb-0 text-center py-3">
                  Terms and Conditions
                </h3>
                <div className="ethos-tnc text-grey font-14">
                  <p>
                    These terms and conditions (&quot;Agreement&quot;) set forth
                    the general terms and conditions of your use of the ETHOS
                    VILLAGE website (&quot;Website&quot; or &quot;Service&quot;)
                    and any of its related products and services (collectively,
                    &quot;Services&quot;). This Agreement is legally binding
                    between you (&quot;User&quot;, &quot;you&quot; or
                    &quot;your&quot;) and Ethos Education Group (&quot;Ethos
                    Education Group LLC&quot;, &quot;we&quot;, &quot;us&quot; or
                    &quot;our&quot;). By accessing and using the Website and
                    Services, you acknowledge that you have read, understood,
                    and agree to be bound by the terms of this Agreement. If you
                    are entering into this Agreement on behalf of a business or
                    other legal entity, you represent that you have the
                    authority to bind such entity to this Agreement, in which
                    case the terms &quot;User&quot;, &quot;you&quot; or
                    &quot;your&quot; shall refer to such entity. If you do not
                    have such authority, or if you do not agree with the terms
                    of this Agreement, you must not accept this Agreement and
                    may not access and use the Website and Services. You
                    acknowledge that this Agreement is a contract between you
                    and Ethos Education Group LLC, even though it is electronic
                    and is not physically signed by you, and it governs your use
                    of the Website and Services.
                  </p>

                  <h5 className="text-blue pb-2 pt-5">Billing and payments</h5>
                  <p>
                    You shall pay all fees or charges to your account in
                    accordance with the fees, charges, and billing terms in
                    effect at the time a fee or charge is due and payable. If
                    auto-renewal is enabled for the Services you have subscribed
                    for, you will be charged automatically in accordance with
                    the term you selected. If, in our judgment, your purchase
                    constitutes a high-risk transaction, we will require you to
                    provide us with a copy of your valid government-issued photo
                    identification, and possibly a copy of a recent bank
                    statement for the credit or debit card used for the
                    purchase. We reserve the right to change products and
                    product pricing at any time. We also reserve the right to
                    refuse any order you place with us. We may, in our sole
                    discretion, limit or cancel quantities purchased per person,
                    per household or per order. These restrictions may include
                    orders placed by or under the same customer account, the
                    same credit card, and/or orders that use the same billing
                    and/or shipping address. In the event that we make a change
                    to or cancel an order, we may attempt to notify you by
                    contacting the e-mail and/or billing address/phone number
                    provided at the time the order was made.
                  </p>

                  <h5 className="text-blue pb-2 pt-5">
                    Accuracy of information
                  </h5>
                  <p>
                    Occasionally there may be information on the Website that
                    contains typographical errors, inaccuracies or omissions
                    that may relate to product descriptions, pricing,
                    availability, promotions and offers. We reserve the right to
                    correct any errors, inaccuracies or omissions, and to change
                    or update information or cancel orders if any information on
                    the Website or Services is inaccurate at any time without
                    prior notice (including after you have submitted your
                    order). We undertake no obligation to update, amend or
                    clarify information on the Website including, without
                    limitation, pricing information, except as required by law.
                    No specified update or refresh date applied on the Website
                    should be taken to indicate that all information on the
                    Website or Services has been modified or updated.
                  </p>

                  <h5 className="text-blue pb-2 pt-5">Advertisements</h5>
                  <p>
                    During your use of the Website and Services, you may enter
                    into correspondence with or participate in promotions of
                    advertisers or sponsors showing their goods or services
                    through the Website and Services. Any such activity, and any
                    terms, conditions, warranties or representations associated
                    with such activity, is solely between you and the applicable
                    third party. We shall have no liability, obligation or
                    responsibility for any such correspondence, purchase or
                    promotion between you and any such third party.
                  </p>

                  <h5 className="text-blue pb-2 pt-5">
                    Links to other resources
                  </h5>
                  <p>
                    Although the Website and Services may link to other
                    resources (such as websites, mobile applications, etc.), we
                    are not, directly or indirectly, implying any approval,
                    association, sponsorship, endorsement, or affiliation with
                    any linked resource, unless specifically stated herein. Some
                    of the links on the Website may be "affiliate links". This
                    means if you click on the link and purchase an item, Ethos
                    Education Group LLC may receive an affiliate commission. We
                    are not responsible for examining or evaluating, and we do
                    not warrant the offerings of, any businesses or individuals
                    or the content of their resources. We do not assume any
                    responsibility or liability for the actions, products,
                    services, and content of any other third parties. You should
                    carefully review the legal statements and other conditions
                    of use of any resource which you access through a link on
                    the Website and Services. Your linking to any other off-site
                    resources is at your own risk.
                  </p>

                  <h5 className="text-blue pb-2 pt-5">Prohibited uses</h5>
                  <p>
                    In addition to other terms as set forth in the Agreement,
                    you are prohibited from using the Website and Services or
                    Content: (a) for any unlawful purpose; (b) to solicit others
                    to perform or participate in any unlawful acts; (c) to
                    violate any international, federal, provincial or state
                    regulations, rules, laws, or local ordinances; (d) to
                    infringe upon or violate our intellectual property rights or
                    the intellectual property rights of others; (e) to harass,
                    abuse, insult, harm, defame, slander, disparage, intimidate,
                    or discriminate based on gender, sexual orientation,
                    religion, ethnicity, race, age, national origin, or
                    disability; (f) to submit false or misleading information;
                    (g) to upload or transmit viruses or any other type of
                    malicious code that will or may be used in any way that will
                    affect the functionality or operation of the Website and
                    Services, third party products and services, or the
                    Internet; (h) to spam, phish, pharm, pretext, spider, crawl,
                    or scrape; (i) for any obscene or immoral purpose; or (j) to
                    interfere with or circumvent the security features of the
                    Website and Services, third party products and services, or
                    the Internet. We reserve the right to terminate your use of
                    the Website and Services for violating any of the prohibited
                    uses.
                  </p>

                  <h5 className="text-blue pb-2 pt-5">
                    Intellectual property rights
                  </h5>
                  <p>
                    "Intellectual Property Rights" means all present and future
                    rights conferred by statute, common law or equity in or in
                    relation to any copyright and related rights, trademarks,
                    designs, patents, inventions, goodwill and the right to sue
                    for passing off, rights to inventions, rights to use, and
                    all other intellectual property rights, in each case whether
                    registered or unregistered and including all applications
                    and rights to apply for and be granted, rights to claim
                    priority from, such rights and all similar or equivalent
                    rights or forms of protection and any other results of
                    intellectual activity which subsist or will subsist now or
                    in the future in any part of the world. This Agreement does
                    not transfer to you any intellectual property owned by Ethos
                    Education Group LLC or third parties, and all rights,
                    titles, and interests in and to such property will remain
                    (as between the parties) solely with Ethos Education Group
                    LLC. All trademarks, service marks, graphics and logos used
                    in connection with the Website and Services, are trademarks
                    or registered trademarks of Ethos Education Group LLC or its
                    licensors. Other trademarks, service marks, graphics and
                    logos used in connection with the Website and Services may
                    be the trademarks of other third parties. Your use of the
                    Website and Services grants you no right or license to
                    reproduce or otherwise use any of Ethos Education Group LLC
                    or third party trademarks.
                  </p>

                  <h5 className="text-blue pb-2 pt-5">
                    Disclaimer of warranty
                  </h5>
                  <p>
                    You agree that such Service is provided on an "as is" and
                    "as available" basis and that your use of the Website and
                    Services is solely at your own risk. We expressly disclaim
                    all warranties of any kind, whether express or implied,
                    including but not limited to the implied warranties of
                    merchantability, fitness for a particular purpose and
                    non-infringement. We make no warranty that the Services will
                    meet your requirements, or that the Service will be
                    uninterrupted, timely, secure, or error-free; nor do we make
                    any warranty as to the results that may be obtained from the
                    use of the Service or as to the accuracy or reliability of
                    any information obtained through the Service or that defects
                    in the Service will be corrected. You understand and agree
                    that any material and/or data downloaded or otherwise
                    obtained through the use of Service is done at your own
                    discretion and risk and that you will be solely responsible
                    for any damage or loss of data that results from the
                    download of such material and/or data. We make no warranty
                    regarding any goods or services purchased or obtained
                    through the Service or any transactions entered into through
                    the Service unless stated otherwise. No advice or
                    information, whether oral or written, obtained by you from
                    us or through the Service shall create any warranty not
                    expressly made herein.
                  </p>

                  <h5 className="text-blue pb-2 pt-5">
                    Limitation of liability
                  </h5>
                  <p>
                    To the fullest extent permitted by applicable law, in no
                    event will Ethos Education Group LLC, its affiliates,
                    directors, officers, employees, agents, suppliers or
                    licensors be liable to any person for any indirect,
                    incidental, special, punitive, cover or consequential
                    damages (including, without limitation, damages for lost
                    profits, revenue, sales, goodwill, use of content, impact on
                    business, business interruption, loss of anticipated
                    savings, loss of business opportunity) however caused, under
                    any theory of liability, including, without limitation,
                    contract, tort, warranty, breach of statutory duty,
                    negligence or otherwise, even if the liable party has been
                    advised as to the possibility of such damages or could have
                    foreseen such damages. To the maximum extent permitted by
                    applicable law, the aggregate liability of Ethos Education
                    Group LLC and its affiliates, officers, employees, agents,
                    suppliers and licensors relating to the services will be
                    limited to an amount greater of one dollar or any amounts
                    actually paid in cash by you to Ethos Education Group LLC
                    for the prior one month period prior to the first event or
                    occurrence giving rise to such liability. The limitations
                    and exclusions also apply if this remedy does not fully
                    compensate you for any losses or fails of its essential
                    purpose.
                  </p>

                  <h5 className="text-blue pb-2 pt-5">Indemnification</h5>
                  <p>
                    You agree to indemnify and hold Ethos Education Group LLC
                    and its affiliates, directors, officers, employees, agents,
                    suppliers and licensors harmless from and against any
                    liabilities, losses, damages or costs, including reasonable
                    attorneys' fees, incurred in connection with or arising from
                    any third party allegations, claims, actions, disputes, or
                    demands asserted against any of them as a result of or
                    relating to your Content, your use of the Website and
                    Services or any willful misconduct on your part.
                  </p>

                  <h5 className="text-blue pb-2 pt-5">Severability</h5>
                  <p>
                    All rights and restrictions contained in this Agreement may
                    be exercised and shall be applicable and binding only to the
                    extent that they do not violate any applicable laws and are
                    intended to be limited to the extent necessary so that they
                    will not render this Agreement illegal, invalid or
                    unenforceable. If any provision or portion of any provision
                    of this Agreement shall be held to be illegal, invalid or
                    unenforceable by a court of competent jurisdiction, it is
                    the intention of the parties that the remaining provisions
                    or portions thereof shall constitute their agreement with
                    respect to the subject matter hereof, and all such remaining
                    provisions or portions thereof shall remain in full force
                    and effect.
                  </p>

                  <h5 className="text-blue pb-2 pt-5">Dispute resolution</h5>
                  <p>
                    The formation, interpretation, and performance of this
                    Agreement and any disputes arising out of it shall be
                    governed by the substantive and procedural laws of Texas,
                    United States without regard to its rules on conflicts or
                    choice of law and, to the extent applicable, the laws of
                    United States. The exclusive jurisdiction and venue for
                    actions related to the subject matter hereof shall be the
                    courts located in Texas, United States, and you hereby
                    submit to the personal jurisdiction of such courts. You
                    hereby waive any right to a jury trial in any proceeding
                    arising out of or related to this Agreement. The United
                    Nations Convention on Contracts for the International Sale
                    of Goods does not apply to this Agreement.
                  </p>

                  <h5 className="text-blue pb-2 pt-5">Assignment</h5>
                  <p>
                    You may not assign, resell, sub-license or otherwise
                    transfer or delegate any of your rights or obligations
                    hereunder, in whole or in part, without our prior written
                    consent, which consent shall be at our own sole discretion
                    and without obligation; any such assignment or transfer
                    shall be null and void. We are free to assign any of its
                    rights or obligations hereunder, in whole or in part, to any
                    third party as part of the sale of all or substantially all
                    of its assets or stock or as part of a merger.
                  </p>

                  <h5 className="text-blue pb-2 pt-5">
                    Changes and amendments
                  </h5>
                  <p>
                    We reserve the right to modify this Agreement or its terms
                    relating to the Website and Services at any time, effective
                    upon posting of an updated version of this Agreement on the
                    Website. When we do, we will post a notification on the main
                    page of the Website. Continued use of the Website and
                    Services after any such changes shall constitute your
                    consent to such changes.
                  </p>

                  <h5 className="text-blue pb-2 pt-5">
                    Acceptance of these terms
                  </h5>
                  <p>
                    You acknowledge that you have read this Agreement and agree
                    to all its terms and conditions. By accessing and using the
                    Website and Services you agree to be bound by this
                    Agreement. If you do not agree to abide by the terms of this
                    Agreement, you are not authorized to access or use the
                    Website and Services.
                  </p>

                  <h5 className="text-blue pb-2 pt-5">Contacting us</h5>
                  <p>
                    If you would like to contact us to understand more about
                    this Agreement or wish to contact us concerning any matter
                    relating to it, you may do so via the contact form.
                  </p>
                  <p className="mt-5">
                    This document was last updated on September 30, 2021.
                  </p>
                </div>
              </div>
            </div>
          </section>
        ) : (
          <section className="main-container">
            <div className="container">
              <div className="py-2 mb-5 text-light-black terms-services">
                <h3 className="display-3 text-black-500 font-weight-bold mt-4 mb-0 text-center py-3">
                  Terms and C
                </h3>
                <div className="pb-4 text-center">
                  <h5>SHAREMEISTER, INC.</h5>
                  <p className="text-grey">
                    <em>Your Social Community Building Partner</em>
                  </p>
                  <p className="text-blue my-2 text-uppercase font-bradon">
                    PLEASE READ THESE TERMS OF SERVICE CAREFULLY.
                  </p>
                </div>
                <div className="text-grey font-14">
                  <p>
                    SHAREMEISTER facilitates “Social Community Building”
                    solutions by providing Software-as-a-Service via the
                    Internet.{" "}
                  </p>
                  <p>
                    BY ACCEPTING THESE TERMS OF SERVICE, BY CLICKING A BOX
                    INDICATING YOUR ACCEPTANCE; BY EXECUTING AN ORDER FORM THAT
                    REFERENCES THESE TERMS; OR, FOR FREE SERVICES, BY USING SUCH
                    SERVICES, YOU AGREE TO THESE TERMS OF SERVICE. IF YOU ARE
                    ENTERING INTO THESE TERMS ON BEHALF OF A COMPANY OR OTHER
                    LEGAL ENTITY, YOU REPRESENT THAT YOU HAVE THE AUTHORITY TO
                    BIND SUCH ENTITY AND ITS AFFILIATES TO THESE TERMS AND
                    CONDITIONS, IN WHICH CASE THE TERMS &quot;YOU&quot;OR
                    &quot;YOUR&quot; SHALL REFER TO SUCH ENTITY AND ITS
                    AFFILIATES. IF YOU DO NOT HAVE SUCH AUTHORITY, OR IF YOU DO
                    NOT AGREE WITH THESE TERMS OF SERVICE, YOU MUST NOT ACCEPT
                    THIS AGREEMENT AND MAY NOT USE THE SERVICES.{" "}
                  </p>
                  <p>
                    You may not access the Services for any functionality or
                    performance benchmarking or any other competitive purposes.
                  </p>
                  <p>Table of Contents:</p>
                  <ul>
                    <li>
                      <strong>1. </strong> Definitions
                    </li>
                    <li>
                      <strong>2. </strong> Acceptance of Terms
                    </li>
                    <li>
                      <strong>3. </strong> Account Registration and Requirements
                    </li>
                    <li>
                      <strong>4. </strong> Sharing Content and Information
                    </li>
                    <li>
                      <strong>5. </strong> Software License Requirements
                    </li>
                    <li>
                      <strong>6. </strong> Billing and Payments
                    </li>
                    <li>
                      <strong>7. </strong> Term and Termination
                    </li>
                    <li>
                      <strong>8. </strong> Indemnification
                    </li>
                    <li>
                      <strong>9. </strong> Liability for our Services
                    </li>
                    <li>
                      <strong>10. </strong> General Provisions
                    </li>
                    <li>
                      <strong>11. </strong> Acceptance of Terms
                    </li>
                  </ul>
                  <h5 className="text-blue pb-2 pt-5">1. Definitions </h5>
                  <p>
                    &quot;Sharemeister&quot;, &quot;we&quot;, &quot;us&quot; or
                    “our” means the Sharemeister Inc., a California corporation.
                  </p>
                  <p>
                    “You”, “your”, or “Client” shall mean any person or entity
                    or sub-entity (e.g., individual, influencer, nonprofit
                    (501(c)(3)), company, corporation, partnership, sole
                    proprietor, limited liability company, media agency,
                    development firm, government, etc.) using Sharemeister
                    Software.
                  </p>
                  <p>
                    “Social Community Building Platform”, “Social Community
                    Building Solution” or “SCB” means the Software, Solutions
                    and Services provided by Sharemeister.
                  </p>
                  <p>
                    “Terms” or “Agreement” shall refer to this Terms of Service
                    agreement.{" "}
                  </p>
                  <p>
                    “Software” or “Software-as-a-Service” or “Service” or
                    “Services” or “Solutions” shall mean the Social Community
                    Building platform offered by Sharemeister and all related
                    architecture, modules, features, user experiences (UX), user
                    interface designs (UI), design and development solutions,
                    integration services, embedded technologies, and application
                    programming interfaces (APIs).
                  </p>
                  <p>
                    “Data” shall mean information provided by any Member to
                    Sharemeister in connection with the performance and use of
                    its Service
                  </p>
                  <h5 className="text-blue pb-2 pt-3">
                    2. Acceptance of Terms{" "}
                  </h5>
                  <div className="pl-5 pb-4">
                    <p>
                      <strong>2.1 Your Acceptance: </strong>
                    </p>
                    <p>
                      {" "}
                      The Sharemeister Social Community Building Platform (the
                      “SCB”) is a Software provided to You, the Client, by
                      Sharemeister. This is a binding agreement between You and
                      Sharemeister. You and Sharemeister may be collectively
                      referred to herein as “Parties” or individually as a
                      “Party”. The Terms of Service listed below govern use of
                      the Software provided by Sharemeister. By participating
                      with the Software, you agree to the Terms of Service
                      (“Terms of Service”) specified herein.
                    </p>
                    <p>
                      <strong>2.2 Your Use: </strong>
                    </p>
                    <p>
                      {" "}
                      By using the website located at www.Sharemeister.com (the
                      “Site”) or any Software-as-a-Service provided in
                      connection with the Site (the “Service” or “Services”), or
                      integrated in your Client website or mobile application or
                      other digital application, you agree to be bound by and to
                      abide by these Terms of Service, which may be amended by
                      Sharemeister from time to time in its sole discretion.
                      This agreement applies whether you create or use an
                      account through the Site or a third party site which uses
                      and relies on the Sharemeister Software and Services.
                      Amended Terms of Service are effective upon being posted.
                      The current version of these Terms of Service is available
                      by clicking the “Terms of Service” link at the Site, found
                      at www.sharemeister.com. Your continued use of the Site
                      and/or Services after we have made changes to the Terms of
                      Service signifies your acceptance of the modified Terms of
                      Service.
                    </p>
                    <p>
                      BY USING THE SERVICE, YOU REPRESENT THAT YOU ARE AT LEAST
                      18 YEARS OLD AND LEGALLY ABLE TO ENTER INTO THIS
                      AGREEMENT. IF YOU ARE LESS THAN 18 YEARS OLD BUT ARE AT
                      LEAST 16 YEARS OLD, YOU MAY ONLY USE THIS SITE AND/OR
                      SERVICE UNDER THE SUPERVISION OF A PARENT OR LEGAL
                      GUARDIAN WHO AGREES TO ABIDE BY THIS AGREEMENT. THE SITE
                      AND ITS RELATED SERVICES ARE NOT INTENDED FOR USE BY
                      CHILDREN UNDER 16 YEARS OF AGE. It is your responsibility
                      to review these Terms of Service periodically for changes,
                      and if at any time you find these Terms of Service
                      unacceptable, you must immediately leave the Site and
                      cease all use of the Service and the Site.{" "}
                    </p>
                  </div>
                  <h5 className="text-blue pb-2 pt-3">
                    3. Account Registration and Requirements{" "}
                  </h5>
                  <div className="pl-5 pb-4">
                    <p>
                      <strong>3.1 Registration: </strong>
                    </p>
                    <p>
                      In order to use the Services, you must create an account
                      through the Site (the “Account”) or where the Software is
                      integrated with a Client (the “Client Application”) and
                      agree to Sharemeister’s Terms of Service and Privacy
                      Policy.
                    </p>
                    <p>
                      <strong>3.2 Your Warranty: </strong>
                    </p>
                    <p>
                      {" "}
                      If you register as a Client or otherwise use the Services,
                      you represent and warrant to Sharemeister that: (i) you
                      are of legal age to form a binding contract; (ii) you will
                      provide Sharemeister with accurate, current and complete
                      registration information, and you will keep your
                      registration information accurate, current and complete at
                      all times; (iii) your registration and your use of the
                      Services is not prohibited by law; (iv) all such
                      registrations use an actual email address and telephone
                      number owned and operated by you; and (v) you are the
                      owner or authorized agent of the social media account,
                      bank account, and credit or debit cards associated with
                      your Account.
                    </p>
                    <p>
                      <strong>3.3 Account Type: </strong>
                    </p>
                    <p>
                      You must designate your primary account type, and any
                      applicable sub-account(s), as an Individual-user,
                      Influencer, Philanthropy, Marketplace, Media or Government
                      entity during the registration process.{" "}
                    </p>
                    <p>
                      <strong>3.4 Account Type Authenticity: </strong>
                    </p>
                    <p>
                      By creating an Account as a User, Influencer,
                      Philanthropy, Marketplace, Media or Governmental Entity,
                      you represent and warranty that you are in good standing
                      not in violation of any applicable laws, statutes,
                      ordinances, or regulations, including, but not limited to,
                      export laws, and you are responsible for complying with
                      all such laws. You represent and warrant that you are the
                      owner of any social media accounts linked to your
                      Sharemeister account. You represent and warrant that you
                      do not infringe on any third party’s intellectual property
                      rights (including trademarks, copyrights, patents, trade
                      secrets or other proprietary rights).{" "}
                    </p>
                    <p>
                      <strong>3.5 Community Account Verification: </strong>
                    </p>
                    <p>
                      {" "}
                      Prospective Clients registering as a “Philanthropic”
                      entity (e.g. nonprofit and charitable organizations,
                      501(c)(3), community-based organizations, et al) must
                      apply for the Service by registering at Sharemeister.com
                      and truthfully completing the application form.
                      Sharemeister will evaluate the prospective Philanthropic
                      Client’s application to verify its status as a nonprofit
                      or charitable organization, and once verified, the
                      prospective Client will be fully qualified to participate
                      in the Service.{" "}
                    </p>
                    <p>
                      <strong>3.6 Your Responsibilities:</strong>
                    </p>
                    <p>
                      {" "}
                      You are responsible for your own Account and all use of
                      the Services under it. You shall not share your Account
                      password nor use the Services through the Account of any
                      third party. You are solely responsible for managing your
                      password and for keeping your password confidential. You
                      are also solely responsible for restricting access to any
                      computer you use to access your Account. Your Account and
                      these Terms of Service are personal to you and are not
                      transferable by you to any third party without the prior
                      written consent of Sharemeister. You further represent
                      that you will create and/or use only one Account in
                      accordance with your role in your social community. Any
                      creation of additional Account(s) shall be considered a
                      violation of these Terms of Service.
                    </p>
                    <p>
                      <strong>3.7 Failure to Comply: </strong>
                    </p>
                    <p>
                      {" "}
                      If you do not comply with these Terms of Service at any
                      time, Sharemeister reserves the right, if applicable, to
                      terminate or suspend your password, Account, and/or access
                      to the Site (or any part thereof); to immediately
                      deactivate or delete your Account or Client Application
                      and all related information and files in your user
                      account; and/or to bar any further access to such
                      information and/or files.{" "}
                    </p>
                  </div>
                  <h5 className="text-blue pb-2 pt-3">
                    4. Sharing Content and Information{" "}
                  </h5>
                  <div className="pl-5 pb-4">
                    <p>
                      <strong>4.1 Client Data: </strong>
                    </p>
                    <p>
                      {" "}
                      You own and retain all right, title and interest in and to
                      the Data you provide to Sharemeister. You hereby grant to
                      Sharemeister a non-exclusive, non-transferable license to
                      access, use, reproduce and prepare derivative works based
                      on your Data for the purpose of providing the Software
                      hereunder and to improve or enhance the Services, and for
                      improving or enhancing products, applications and services
                      provided to its affiliates. This license includes use of
                      your Data to compile, use and disclose anonymous,
                      aggregated statistics that include it Data, provided that
                      no such information will directly identify and cannot
                      reasonably be used to identify you or any of its users or
                      employees. You shall be solely responsible for ensuring
                      that you have obtained all necessary third party consents
                      and made all required disclosures in connection with the
                      foregoing grant.{" "}
                    </p>
                    <p>
                      <strong>4.2 Permission: </strong>
                    </p>
                    <p>
                      {" "}
                      For content that is covered by intellectual property
                      rights, like photos and videos (“IP Content”), you
                      specifically give Sharemeister the following permission,
                      subject to your privacy and settings: you grant
                      Sharemeister a non-exclusive, transferable,
                      sub-licensable, royalty-free, worldwide license to use any
                      IP Content that you post on or in connection with
                      Sharemeister (IP License). This IP License ends when you
                      delete your Account and remove the IP Content from
                      Sharemeister platform unless your content has been shared
                      with others, and they have not deleted it.
                    </p>
                    <p>
                      <strong>4.3 Deleting Content: </strong>
                    </p>
                    <p>
                      {" "}
                      When you delete IP Content, it is deleted in a manner
                      similar to emptying the recycle bin on a computer.
                      However, you understand that removed content may persist
                      in backup copies for a reasonable period of time (but will
                      not be available to others).
                    </p>
                    <p>
                      <strong>4.4 Public Content: </strong>
                    </p>
                    <p>
                      {" "}
                      When you publish content or information using the Public
                      setting, it means that you are allowing everyone,
                      including people off of Sharemeister, to access and use
                      that information, and to associate it with you (i.e., your
                      name and profile picture).
                    </p>
                    <p>
                      <strong>4.5 Control and Ownership: </strong>
                    </p>
                    <p>
                      {" "}
                      Sharemeister owns its platform and services, including
                      those embedded or integrated into client sites. It has the
                      rights to distribute campaigns, data, resources and
                      revenues per the terms of service; it has the right to
                      collect, store, analyze and distribute all forms of
                      information in coordination with clients who opt-in to its
                      services.
                    </p>
                    <p>
                      <strong>4.6 License to use Feedback: </strong>
                    </p>
                    <p>
                      {" "}
                      We always appreciate your feedback or other suggestions
                      about Sharemeister, but you understand that we may use
                      them without any obligation to compensate you for them
                      (just as you have no obligation to offer them).
                    </p>
                  </div>
                  <h5 className="text-blue pb-2 pt-3">
                    5. Software License Requirements
                  </h5>
                  <p>
                    The Software License Requirements outline your rights and
                    responsibilities when using Sharemeister’s Social Community
                    Building Software (SCBS) and Application Programing
                    Interfaces (APIs), collectively referred to as the
                    “Software” or “Services”. Additional terms may apply to the
                    use of the Software, including additional terms and
                    conditions, terms within the accompanying documentation, and
                    any applicable policies or guidelines. If you register an
                    account, or contract Sharemeister to utilize its design
                    services and/or development services, use the Software as an
                    interface to, or in conjunction with other Sharemeister
                    products and services, or the user experience (UX) and/or
                    user interface (UI) design architecture, modules and
                    features required to utilize the Software, then these Terms
                    of Service for such products and services also apply.
                  </p>
                  <div className="pl-5 pb-4">
                    <p>
                      <strong>5.1 Licenses and Services: </strong>
                    </p>
                    <p>
                      Sharemeister offers and operates a Social Community
                      Building Platform. This service is offered as a
                      Software-as-a-Service and Clients pay for ongoing use of
                      the platform through licenses.
                    </p>
                    <div className="pl-5 pb-4">
                      <p>
                        <strong>5.1.1 Free Services: </strong>
                      </p>
                      <p>
                        {" "}
                        Sharemeister may make free services available to you.
                        You agree that Sharemeister may discontinue or modify
                        the Free Services at any time and without prior notice.
                        You agree that Sharemesiter, at its sole discretion, may
                        cancel your access to the Free Services at any time, and
                        for any or no reason. Sharemeister will not be liable to
                        you or any third party for any such cancellation.{" "}
                      </p>
                      <p>
                        <strong>
                          5.1.2 User Experience and User Interface Services:{" "}
                        </strong>
                      </p>
                      <p>
                        {" "}
                        For Clients wanting to restructure their user experience
                        or redesign their user interface, Sharemeister offers
                        its services. You agree that you will not duplicate any
                        User Experience a User Interface design architecture,
                        modules, solutions or features for the purposes of
                        redeveloping our Software. Terms and pricing are
                        available upon request and subject to these Terms of
                        Service.{" "}
                      </p>
                      <p>
                        <strong>5.1.3 Global Partner License: </strong>
                      </p>
                      <p>
                        {" "}
                        For Clients wanting a robust Software integration,
                        Sharemeister offers the Global Partner License. Terms
                        and pricing are available upon request and subject to
                        these Terms of Service.{" "}
                      </p>
                      <p>
                        <strong>5.1.4 Applications and Tools: </strong>
                      </p>
                      <p>
                        {" "}
                        Sharemeister, from time to time, makes available
                        embedded applications. Terms and pricing varies per
                        individual application, and clients must opt-in and
                        agree to the respective terms and conditions prior to
                        use, where applicable.{" "}
                      </p>
                      <p>
                        <strong>5.1.5 Acceptance and Activation: </strong>
                      </p>
                      <p>
                        {" "}
                        All fees and any additional terms and conditions are
                        outlined with each module, service and tool at the point
                        of activation and can be reviewed at any time in the
                        Account: Finances! Module.{" "}
                      </p>
                    </div>
                    <p>
                      <strong>5.2 Using the Software: </strong>
                    </p>
                    <div className="pl-5 pb-4">
                      <p>
                        <strong>5.2.1 Your End Users: </strong>
                      </p>
                      <p>
                        {" "}
                        You will require your end users to comply with any
                        applicable law and these terms. You will not knowingly
                        enable your end users to violate applicable law or these
                        terms.
                      </p>
                      <p>
                        <strong>5.2.2 Compliance with Law: </strong>
                      </p>
                      <p>
                        {" "}
                        You will use our Software only as permitted by law
                        (including without limitation laws regarding the import
                        or export of data or Software, privacy, or local laws).
                        You will not use the Software to encourage or promote
                        illegal activity{" "}
                      </p>
                      <p>
                        <strong>5.2.3 Permitted Access: </strong>
                      </p>
                      <p>
                        {" "}
                        You will only access (or attempt to access) the Software
                        by the means described in the documentation of that
                        Software. If Sharemeister assigns you developer
                        credentials or Client IDs, you must use them with the
                        applicable APIs. You will not misrepresent or mask
                        either your identity or your Software Client’s identity
                        when using the Software or developer accounts.{" "}
                      </p>
                      <p>
                        <strong>5.2.4 API Limitations: </strong>
                      </p>
                      <p>
                        {" "}
                        Sharemeister may set limits on the number of API
                        requests that you can make, at its sole discretion. You
                        agree to such limitations and will not attempt to
                        circumvent such limitations.{" "}
                      </p>
                      <p>
                        <strong>5.2.5 Open Source Software: </strong>
                      </p>
                      <p>
                        {" "}
                        Some of the Software required by or included in our
                        Software may be offered under an open source license.
                        There may be provisions in the open source license that
                        expressly override some of these terms, and in those
                        cases, the overriding provisions apply.{" "}
                      </p>
                      <p>
                        <strong>5.2.6 Monitoring: </strong>
                      </p>
                      <p>
                        {" "}
                        Sharemeister may monitor the use of the Software to
                        ensure quality, improve Sharemeister products and
                        services, and verify your compliance with these terms.
                        You will not interfere with such monitoring.
                        Sharemeister may use any technical means to overcome
                        such interference.{" "}
                      </p>
                      <p>
                        <strong>5.2.7 Communication with Sharemeister: </strong>
                      </p>
                      <p>
                        {" "}
                        You agree that we may send you certain communications in
                        connection with your use of the Software. Please review
                        the applicable documentation for information about
                        opting out of certain types of communication.{" "}
                      </p>
                      <p>
                        <strong>5.2.8 Feedback: </strong>
                      </p>
                      <p>
                        {" "}
                        If you provide feedback or suggestions about our
                        Software, then we may use such information without
                        obligation to you.{" "}
                      </p>
                    </div>
                    <p>
                      <strong>5.3 Software Clients: </strong>
                    </p>
                    <p>
                      The Software is designed to help you enhance your websites
                      and applications, such as mobile apps, web extensions and
                      other design interfaces. Sharemeister reserves the right
                      to investigate any Software Client for compliance with
                      these terms. Such investigations may include Sharemeister
                      accessing and using your Software Client, for example to
                      identify security issues that could affect Sharemeister or
                      its users. You consent to any such investigation.
                      Sharemeister may suspend access to our Software by you or
                      your Software Client without notice if we in good faith
                      believe that you may be in violation of these terms.
                    </p>
                    <div className="pl-5 pb-4">
                      <p>
                        <strong>5.3.1 Security: </strong>
                      </p>
                      <p>
                        {" "}
                        You will use commercially reasonable efforts to protect
                        user information collected by your Software Client,
                        including personally identifiable information (PII),
                        from unauthorized access or use and will promptly report
                        to your users any unauthorized access or use of such
                        information.
                      </p>
                      <p>
                        <strong>5.3.2 Ownership: </strong>
                      </p>
                      <p>
                        {" "}
                        Sharemeister does not acquire ownership in your Software
                        Clients, and by using our Software, you do not acquire
                        ownership of any rights in our Software or the content
                        that is accessed through our Software.{" "}
                      </p>
                      <p>
                        <strong>
                          5.3.3 User Privacy and Software Clients:{" "}
                        </strong>
                      </p>
                      <p>
                        {" "}
                        You will comply with all privacy laws and regulations
                        including those applying to PII. You will provide and
                        adhere to a privacy policy for your Software Client that
                        clearly and accurately describes to users of your
                        Software Client what user information you collect (such
                        as PII, login information and account data) and how you
                        use and share such information (including for
                        advertising) with Sharemeister and third parties. If
                        your privacy policy is used to comply with the EU Data
                        Protection Directive, then it must adhere to the US Safe
                        Harbor principles of Notice, Choice, Onward Transfer,
                        Security, Data Integrity, and Access at
                        http://www.export.gov/safeharbor/eu/eg_main_018475.asp.{" "}
                      </p>
                      <p>
                        <strong>5.3.4 Software Prohibitions: </strong>
                      </p>
                      <p>
                        {" "}
                        When using the Software, the following prohibitions
                        apply:{" "}
                      </p>
                      <li>
                        You will not sublicense the Software for use by a third
                        party. Consequently, you will not create a module or an
                        API Client that functions substantially the same as the
                        Software and offer it for use by third parties.
                      </li>
                      <li>
                        You will not perform an action with the intent of
                        introducing to Sharemeister products and services any
                        viruses, worms, defects, Trojan horses, malware or any
                        items of a destructive nature.
                      </li>
                      <li>
                        You will not defame, abuse, harass, stalk or threaten
                        others.
                      </li>
                      <li>
                        You will not interfere with or disrupt the Software or
                        the servers or networks providing the Software.
                      </li>
                      <li>
                        You will not promote or facilitate unlawful online
                        gambling or disruptive commercial messages or
                        advertisements.
                      </li>
                      <li>
                        You will not reverse engineer or attempt to extract the
                        source code from any Software or any related APIs,
                        except to the extent that this restriction is expressly
                        prohibited by applicable law.
                      </li>
                      <li>
                        Certain developer credentials are, by their nature,
                        considered confidential. You will not disclose such
                        confidential credentials to any third party except your
                        agent(s) using such information solely on your behalf in
                        accordance with these terms and under a written duty of
                        confidentiality.
                      </li>
                      <li>
                        Our communications to you may contain Sharemeister
                        confidential information. If you receive any materials
                        or communications that are clearly confidential or
                        marked confidential, then you will not disclose the
                        Sharemeister confidential information to any third party
                        without Sharemeister’s prior written consent.
                      </li>
                    </div>
                    <p>
                      <strong>
                        5.4 Software Modules, Solutions and Features:{" "}
                      </strong>
                    </p>
                    <div className="pl-5 pb-4">
                      <p>
                        <strong>
                          5.4.1 Social Community Building Software (SCBS) and
                          Application Programming Interfaces (API):{" "}
                        </strong>
                      </p>
                      <p>
                        {" "}
                        The SCBS contains design architecture, modules,
                        solutions and features engineered as APIs for integrated
                        use with any digital application.
                      </p>
                      <p>
                        <strong>5.4.2 Modules, Solutions and Features: </strong>
                      </p>
                      <div className="pl-5 pb-4">
                        <li>
                          <span className="font-weight-bold">
                            Dashboards! :{" "}
                          </span>
                          Organize your profile experience.
                        </li>
                        <li>
                          <span className="font-weight-bold">
                            Communications! :{" "}
                          </span>
                          Engage in multiple forms of conversation.
                        </li>
                        <li>
                          <span className="font-weight-bold">Networks! : </span>
                          Manage teams, internal networks, external networks and
                          audiences.
                        </li>
                        <li>
                          <span className="font-weight-bold">
                            Campaigns! :{" "}
                          </span>
                          Create, manage and distribute various types of
                          campaigns.
                        </li>
                        <li>
                          <span className="font-weight-bold">Tools! : </span>
                          Access premium collaboration tools to enhance your
                          social community.
                        </li>
                        <li>
                          <span className="font-weight-bold">
                            Statistics! :{" "}
                          </span>
                          Manage real-time data.
                        </li>
                        <li>
                          <span className="font-weight-bold">Shops! : </span>
                          Create, manage and distribute various types of
                          products & services.
                        </li>
                        <li>
                          <span className="font-weight-bold">Finances! : </span>
                          Manage the financial flow of transactions.
                        </li>
                        <li>
                          <span className="font-weight-bold">Expos! : </span>
                          Create, manage and distributed various types of Expo
                          activities.
                        </li>
                        <li>
                          <span className="font-weight-bold">Fandoms! : </span>
                          Syndicate your brand across multiple network channels.
                        </li>
                      </div>
                      <p>
                        <strong>
                          5.4.3 User Experience and User Interface Design
                          (UX/UI):{" "}
                        </strong>
                      </p>
                      <p>
                        {" "}
                        The Software contains user experience and user interface
                        design architecture, modules, solutions and features
                        required to utilize the technology that may be
                        customized by the Client. The Client is hereby granted
                        usage and reproduction rights to the design themes,
                        logos, fonts, graphics and photos customized by Client
                        in use of the Software. Except for the foregoing
                        license, all right, title and interest to all user
                        experience and user interface design architecture,
                        modules, solutions and features remain with
                        Sharemeister.{" "}
                      </p>
                    </div>
                    <p>
                      <strong>5.5 Content: </strong>
                    </p>
                    <div className="pl-5 pb-4">
                      <p>
                        <strong>
                          5.5.1 Content Accessible Through our Software:{" "}
                        </strong>
                      </p>
                      <p>
                        {" "}
                        Our Software contains some third party content (such as
                        text, images, videos, audio, or Software). This content
                        is the sole responsibility of the person that makes it
                        available. We may sometimes review content to determine
                        whether it is illegal or violates our policies, and we
                        may remove or refuse to display content. Content
                        accessible through our Software may be subject to
                        intellectual property rights, and, if so, you may not
                        use it unless you are licensed to do so by the owner of
                        that content or are otherwise permitted by law. Your
                        access to the content provided by the Software may be
                        restricted, limited, or filtered in accordance with
                        local laws, regulations, and policies.{" "}
                      </p>
                      <p>
                        <strong>5.5.2 Submission of Content: </strong>
                      </p>
                      <p>
                        Some of our Software allow the submission of content,
                        and except as expressly provided in these terms,
                        Sharemeister does not acquire any ownership of any
                        intellectual property rights that you or your end users
                        hold in the content that you submit to our Software
                        through your Software Client. By submitting, posting or
                        displaying content to or from the Software through your
                        Software Client, you give Sharemeister a perpetual,
                        irrevocable, worldwide, royalty-free, and non-exclusive
                        license to reproduce, adapt, modify, translate, publish,
                        publicly perform, publicly display and distribute such
                        content. However, Sharemeister will only use such
                        content for the purpose of enabling Sharemeister to
                        provide the Software and only in accordance with the
                        applicable Sharemeister privacy policies.{" "}
                      </p>
                      <p>
                        You agree that this license includes a right for
                        Sharemeister to make such content available to other
                        companies, organizations or individuals with whom
                        Sharemeister has relationships for the provision of
                        syndicated services, and to use such content in
                        connection with the provision of those services. By
                        submitting your content to our Software through your
                        Software Client, You represent and warrant that you have
                        the necessary rights (including the necessary rights
                        from your end users) to grant us the license.
                      </p>
                      <p>
                        <strong>5.5.3 Retrieval of Content: </strong>
                      </p>
                      <p>
                        {" "}
                        When a user’s non-public content is obtained through the
                        Software, you may not expose that content to other users
                        or to third parties without explicit opt-in consent from
                        that user.{" "}
                      </p>
                      <p>
                        <strong>5.5.4 Data Portability: </strong>
                      </p>
                      <p>
                        {" "}
                        Sharemeister supports data portability. By accessing
                        user’s data through the Software for use in any of your
                        services or applications, you agree to enable your users
                        of any such service or application to export their
                        equivalent data to other services or applications of
                        their choice in a way that’s substantially as fast and
                        easy as exporting such data from Sharemeister products
                        and services, subject to applicable laws.
                      </p>
                      <p>
                        <strong>5.5.5 Prohibitions on Content: </strong>
                      </p>
                      <p>
                        {" "}
                        Unless expressly permitted by the content owner or by
                        applicable law, you agree that you will not, and will
                        not permit your end users to, do the following with
                        content returned from the Software:{" "}
                      </p>
                      <li>
                        Scrape, build databases or otherwise create permanent
                        copies of such content, or keep cached copies longer
                        than permitted by the cache header;
                      </li>
                      <li>
                        Copy, translate, modify, create a derivative work of,
                        sell, lease, lend, convey, distribute, publicly display
                        or sublicense to any third party;
                      </li>
                      <li>
                        Duplicate any User Experience a User Interface design
                        architecture, modules, features for the purposes of
                        redeveloping our Software;
                      </li>
                      <li>Misrepresent the source or ownership; or</li>
                      <li>
                        Remove, obscure, or alter any copyright, trademark or
                        other proprietary rights notices, falsify or delete any
                        author attributions, legal notices or other labels of
                        the origin or source of material.
                      </li>
                    </div>
                    <p>
                      <strong>5.6 Brand Features; Attribution: </strong>
                    </p>
                    <div className="pl-5 pb-4">
                      <p>
                        <strong>5.6.1 Brand Features: </strong>
                      </p>
                      <p>
                        {" "}
                        “Brand Features” is defined as the trade names,
                        trademarks, service marks, logos, domain names, and
                        other distinctive brand features of each party. Except
                        where expressly stated, these terms do not grant either
                        party any right, title, or interest in or to the other
                        party’s Brand Features.{" "}
                      </p>
                      <p>
                        <strong>5.6.2 Attribution: </strong>
                      </p>
                      <p>
                        You agree to display any attribution(s) required by
                        Sharemeister as described in the documentation for the
                        Software. Sharemeister hereby grants to you a
                        nontransferable, nonsublicenseable, nonexclusive license
                        during the term to display Sharemeister’s Brand Features
                        for the purpose of promoting or advertising that you use
                        the Software. You must only use the Sharemeister Brand
                        Features in accordance with these terms and for the
                        purpose of fulfilling your obligations under this
                        Section. In using Sharemeister’s Brand Features, you
                        must follow the Sharemeister Brand Features Use
                        Guidelines supplied on the site. You understand and
                        agree that Sharemeister has the sole discretion to
                        determine whether your attribution(s) and use of
                        Sharemeister’s Brand Features are in accordance with the
                        above requirements and guidelines.
                      </p>
                      <p>
                        <strong>5.6.3 Publicity: </strong>
                      </p>
                      <p>
                        {" "}
                        You will not make any statement regarding your use of
                        the Software which suggests partnership with,
                        sponsorship by or endorsement by Sharemeister without
                        Sharemeister’s prior written approval.{" "}
                      </p>
                      <p>
                        <strong>5.6.4 Promotional and Marketing Use: </strong>
                      </p>
                      <p>
                        {" "}
                        In the course of promoting, marketing, or demonstrating
                        the Software you are using and the associated
                        Sharemeister products, Sharemeister may produce and
                        distribute incidental depictions, including screenshots,
                        video, or other content from your API Client, and may
                        use your company or product name. You grant us all
                        necessary rights for the above purposes.
                      </p>
                    </div>
                    <p>
                      <strong>5.7 Privacy and Copyright Protection: </strong>
                    </p>
                    <div className="pl-5 pb-4">
                      <p>
                        <strong>5.7.1 Sharemeister Privacy Policies: </strong>
                      </p>
                      <p>
                        {" "}
                        By using our Software, you agree that Sharemeister can
                        use submitted information in accordance with our privacy
                        policies.{" "}
                      </p>
                      <p>
                        <strong>5.7.2 Sharemeister DMCA Policy: </strong>
                      </p>
                      <p>
                        We provide information to help copyright holders manage
                        their intellectual property online, but we do not
                        provide legal advice. We respond to notices of alleged
                        copyright infringement and terminate accounts of repeat
                        infringers according to the process set out in the U.S.
                        Digital Millennium Copyright Act. If you think somebody
                        is violating your copyrights and want to notify us, you
                        can find information about submitting notices, and
                        Sharemeister’s policy about responding to notices at
                        http://www.Sharemeister.com/
                      </p>
                    </div>
                  </div>
                  <h5 className="text-blue pb-2 pt-3">
                    6. Billing and Payments
                  </h5>
                  <div className="pl-5 pb-4">
                    <p>
                      <strong>6.1 Fees: </strong>
                    </p>
                    <p>
                      {" "}
                      A description of Sharemeister’s fee structure is available
                      upon request and made available upon activation or
                      extended use of the service.{" "}
                    </p>
                    <p>
                      <strong>6.2 Payment: </strong>
                    </p>
                    <p>
                      {" "}
                      For any license services, an active bank account, credit
                      or debit card must be on file to utilize the paid
                      Sharemeister services. You are responsible for maintaining
                      and completing accurate billing information and to notify
                      us of any changes to such information. Payment for
                      licensed services will be due and charged on the 1st day
                      of each Calendar month, unless other arrangement has been
                      agreed upon by both parties in writing.
                    </p>
                    <p>
                      <strong>6.3 Invoices and Overdue Charges: </strong>
                    </p>
                    <p>
                      {" "}
                      Payments are due upon receipt from the invoice date,
                      unless otherwise specified in the license agreement. If
                      any invoice amount is not received by Us by the due date,
                      then those charges may be eligible for late charges, in
                      the amount of 5% of the outstanding balance or the maximum
                      rate permitted by law, whichever is lower.{" "}
                    </p>
                    <p>
                      <strong>6.4 Account Suspension: </strong>
                    </p>
                    <p>
                      {" "}
                      If your account balance is 30 or more days past the
                      invoice due date, We may, without limiting Our other
                      rights and remedies, ask for an immediate payment for the
                      full outstanding balance due and/or suspend our services
                      to you until such amounts are paid in full.{" "}
                    </p>
                    <p>
                      <strong>6.5 Future Functionality: </strong>
                    </p>
                    <p>
                      {" "}
                      Your purchases are not contingent or dependent upon any
                      future or potential features or functionality or any
                      public comments made by us regarding such potential future
                      features and functionality.{" "}
                    </p>
                    <p>
                      <strong>6.6 Taxes: </strong>
                    </p>
                    <p>
                      {" "}
                      You will be responsible for, and will promptly pay or
                      reimburse Sharemeister for, the payment of all sales, use,
                      excise, value-added or similar taxes, assessments, or
                      duties (or other similar charges) imposed by any
                      governmental agency (including any interest and penalty
                      imposed thereon as a result of any act or omission by You
                      that is in accordance with the direction or request of
                      Client) that are based on or with respect to any Services
                      or goods provided by Sharemeister to Client, or the
                      amounts payable to Sharemeister therefore.{" "}
                    </p>
                  </div>
                  <h5 className="text-blue pb-2 pt-3">
                    7. Term and Termination
                  </h5>
                  <div className="pl-5 pb-4">
                    <p>
                      <strong>7.1 Term of Terms of Service: </strong>
                    </p>
                    <p>
                      {" "}
                      These Terms of Service begin on the date you accept them
                      and continue until you terminate the service, or where
                      applicable, your paid service term expires, and you cease
                      use of all applicable services and adhere to the
                      obligations in the section titled “Obligations
                      Post-Termination.”
                    </p>
                    <p>
                      <strong>7.2 Term of Purchased Services: </strong>
                    </p>
                    <p>
                      {" "}
                      The term of each purchased service shall be as specified
                      in your service license agreement. Except as otherwise
                      specified in your initial service license agreement, your
                      service will automatically renew for additional periods
                      equal to the expiring service term, unless either Party
                      gives the other notice of non-renewal at least 30 days
                      before the end of the current and expiring service term.
                      Each service renewal term will renew at the then current
                      service pricing.
                    </p>
                    <p>
                      <strong>7.3 Termination: </strong>
                    </p>
                    <p>
                      {" "}
                      You may stop using our Service at any time. If you
                      contracted with us for a paid service, and you want to
                      terminate such service, you must provide Sharemeister with
                      30 days prior written notice and upon termination, cease
                      your use of the applicable Services. Sharemeister reserves
                      the right to terminate these terms or discontinue the
                      Service or any portion or feature for any reason and at
                      any time without liability or other obligation to you. In
                      no event will termination relieve you of your obligation
                      to pay any fees payable to us for the period prior to the
                      effective termination date.{" "}
                    </p>
                    <p>
                      <strong>7.4 Obligations Post-Termination: </strong>
                    </p>
                    <p>
                      {" "}
                      Upon any termination of this Agreement or discontinuation
                      of your access to the Service, you will immediately stop
                      using the Service, cease all use of the Sharemeister Brand
                      Features, and delete any cached or stored content that was
                      permitted by the cache header under Section 5.
                      Sharemeister may independently communicate with any
                      account owner whose account(s) are associated with your
                      Client Account and developer credentials to provide notice
                      of the termination of your right to use the Service.
                    </p>
                    <p>
                      <strong>7.5 Surviving Provisions: </strong>
                    </p>
                    <p>
                      {" "}
                      Notwithstanding termination of this Agreement, those terms
                      that by their nature are intended to continue indefinitely
                      will continue to apply, including but not limited to: the
                      indemnity and obligations under Section 4 above, Section 8
                      below and the provisions of Section 11.
                    </p>
                  </div>
                  <h5 className="text-blue pb-2 pt-3">8. Indemnification</h5>
                  <p>
                    <strong>INDEMNIFICATION: </strong> YOU AGREE TO HOLD
                    HARMLESS AND INDEMNIFY SHAREMEISTER, AND ITS SUBSIDIARIES,
                    AFFILIATES, OFFICERS, AGENTS, AND EMPLOYEES, OR PARTNERS,
                    FROM AND AGAINST ANY THIRD PARTY CLAIM ARISING FROM OR IN
                    FROM OR IN ANY WAY RELATED TO: YOUR MISUSE OR YOUR END
                    USER’S MISUSE OF THE SERVICES; OR YOUR VIOLATION OR YOUR END
                    USER’S VIOLATION OF THESE TERMS, INCLDUING ANY LIABILITY OR
                    EXPENSE ARISING FROM ALL CLAIMS, LOSSES, DAMAGES (ACTUAL AND
                    CONSEQUENTIAL), SUITES, LITIGATION COSTS AND ATTORNEYS’
                    FEES, OF EVERY KIND AND NATURE. IN SUCH A CASE, SHAREMEISTER
                    WILL PROVIDE YOU WITH WRITTEN NOTICE OF SUCH CLAIM, SUIT OR
                    ACTION.
                  </p>
                  <h5 className="text-blue pb-2 pt-3">
                    9. Liability for our Services
                  </h5>
                  <div className="pl-5 pb-4">
                    <p>
                      <strong>9.1 Warranties: </strong>
                    </p>
                    <p>
                      {" "}
                      NEITHER SHAREMEISTER NOR ITS SUPPLIERS OR DISTRIBUTORS
                      MAKE ANY SPECIFIC PROMISES ABOUT THE SERVICES. FOR
                      EXAMPLE, WE MAKE NO COMMITMENTS ABOUT THE QUALITY OF THE
                      SERVICES OR THE CONTENT ACCESSED THROUGH THE SERVICES,
                      THEIR RELIABILITY, AVAILABILITY OR ABILITY TO MEET YOUR
                      NEEDS. THE SERVICES AND CONTENT ACCESSED THROUGH THE
                      SERVICES ARE PROVIDED “AS IS”. SOME JURISDICTIONS PROVIDE
                      FOR CERTAIN WARRANTIES, LIKE THE IMPLIED WARRANTY OF THE
                      MEMBERABILITY, FITNESS FOR A PARTICULAR PURPOSE AND
                      NON-INFRINGEMENT. TO THE EXTENT PERMITTED BY LAW, WE
                      EXCLUDE ALL IMPLIED WARRANTIES.{" "}
                    </p>
                    <p>
                      <strong>9.2 Limitation of Liability: </strong>
                    </p>
                    <p>
                      {" "}
                      SHAREMEISTER AND ITS SUBSIDIARIES, AFFILIATES,
                      SHAREHOLDERS, DIRECTORS, OFFICERS, BOARD MEMBERS,
                      EMPLOYEES AND LICENSORS (“THE PARTIES”) WILL NOT BE LIABLE
                      (JOINTLY OR SEVERALLY) TO CLIENT, AUTHORIZED USERS, OR ANY
                      THIRD PARTY, FOR INDIRECT, CONSEQUENTIAL, SPECIAL,
                      INCIDENTAL, PUNITIVE, OR EXEMPLARY DAMAGES, INCLUDING,
                      WITHOUT LIMITATION, LOST PROFITS, LOST SAVINGS AND LOST
                      REVENUES (COLLECTIVELY, THE “EXCLUDED DAMAGES”), WHETHER
                      OR NOT CHARACTERIZED IN NEGLIGENCE, TORT, CONTRACT, OR
                      OTHER THEORY OF LIABILITY, EVEN IF ANY OF THE PARTIES HAVE
                      BEEN ADVISED OF THE POSSIBILITY OF OR COULD HAVE FORESEEN
                      ANY OF THE EXCLUDED DAMAGES, AND IRRESPECTIVE OF ANY
                      FAILURE OF AN ESSENTIAL PURPOSE OF A LIMITED REMEDY. IN NO
                      EVENT WILL THE LIABILITY OF THE PARTIES ARISING OUT OF ANY
                      CLAIM RELATED TO THIS AGREEMENT EXCEPT FOR INTELLECTUAL
                      PROPERTY INFRINGEMENT OR THE SUBJECT MATTER HEREOF EXCEED
                      THE AGGREGATE AMOUNT PAID BY SUBSCRIBER HEREUNDER IN THE
                      TWELVE (12) MONTHS IMMEDIATELY PRECEDING THE EVENT GIVING
                      RISE TO SUCH CLAIM. IF ANY APPLICABLE AUTHORITY HOLDS ANY
                      PORTION OF THIS SECTION TO BE UNENFORCEABLE, THEN THE
                      PARTIES’ LIABILITY WILL BE LIMITED TO THE FULLEST POSSIBLE
                      EXTENT PERMITTED BY APPLICABLE LAW. SHAREMEISTER WILL
                      INDEMNIFY, DEFEND AND HOLD HARMLESS CLIENT FOR ANY LOSS,
                      DAMAGE OR COST IN CONNECTION WITH ANY CLAIM OR ACTION
                      WHICH MAY BE BROUGHT BY ANY THIRD PARTY AGAINST LICENSOR
                      RELATING TO ANY BREACH OF THIS AGREEMENT BY SHAREMEISTER..
                    </p>
                  </div>
                  <h5 className="text-blue pb-2 pt-3">
                    10. General Provisions
                  </h5>
                  <div className="pl-5 pb-4">
                    <p>
                      <strong>10.1 Modification: </strong>
                    </p>
                    <p>
                      {" "}
                      Sharemeister may, in its sole discretion and without prior
                      notice, (a) revise these Terms of Service; (b) modify the
                      Site and/or the Software-as-a-Service; and (c) discontinue
                      the Site and/or Software-as-a-Service at any time.
                      Sharemeister shall post any revision to these Terms of
                      Service to the Site, and the revision shall be effective
                      immediately on such posting. You agree to review these
                      Terms of Service and other online policies posted on the
                      Site periodically to be aware of any revisions. You agree
                      that, by continuing to use or access the Site following
                      notice of any revision, you shall abide by any such
                      revision
                    </p>
                    <p>
                      <strong>10.2 General Legal Terms: </strong>
                    </p>
                    <p>
                      {" "}
                      These terms control the relationship between Sharemeister
                      and you. They do not create any third party beneficiary
                      rights. If you do not comply with these terms, and
                      Sharemeister does not take action right away, this does
                      not mean that Sharemeister is giving up any rights that it
                      may have (such as taking action in the future). If it
                      turns out that a particular term is not enforceable, this
                      will not affect any other terms. The laws of California,
                      U.S.A., excluding California’s choice of law rules, will
                      apply to any disputes arising out of or related to these
                      terms or the services. All claims arising out of or
                      relating to these terms or the services will be litigated
                      exclusively in the federal or state courts of Fresno
                      County, California, USA, and you and Sharemeister consent
                      to personal jurisdiction in those courts.
                    </p>
                  </div>
                  <h5 className="text-blue pb-2 pt-3">
                    11. Acceptance of Terms
                  </h5>
                  <p>
                    You acknowledge you have read, and agree to be bound by,
                    these Terms of Service and to comply with all applicable
                    laws and regulations. By your continued use of
                    Sharemeister.com, you agree to each of the Terms of Service
                    set forth and/or linked herein. You further agree to comply
                    with all local laws, regulations and rules regarding online
                    conduct and acceptable content. You represent that you have
                    the legal authority to accept these Terms of Service on
                    behalf of yourself or any party you represent. If you do not
                    agree to these Terms of Service, you are prohibited from
                    using Sharemeister.com or Sharemeister’s
                    Software-as-a-service.{" "}
                  </p>
                </div>
              </div>
            </div>
          </section>
        )
      }
    </React.Fragment>
  );
}
