import React, { Component } from 'react';
import { ChatOvalIcon, SmileIcon, SendIcon, ChevronUpIcon, CloseIcon } from 'shared/icons/icons';
import { Utility } from 'utils';
import { API_BASE_URL, CAMPAIGN_COMMENTS } from 'config/constants/api_endpoints';
import { CLIENT_ID } from 'config/constants/config';
import { toast } from 'react-toastify';
import Avatar from "react-avatar";
import moment from 'moment';
import socket from 'utils/sockets.js';
export class CampaignChat extends Component{
  constructor(props){
    super(props);
    this.state = {
      comment: "",
      allComments: {},
      campaignId: props.campaignId,
    };
  }

  componentDidMount = () => {
    this.props.getCampaignDetails();
    this.getAllComments();
    socket.on("comments", this.listenForComments);
  }

  componentWillUpdate = (nextProps) => {
    if(this.state.campaignId !== nextProps.campaignId){
      this.setState({
        campaignId: nextProps.campaignId
      }, () => {
        this.getAllComments();
      });
    }
  }

  componentWillUnmount = () => {
    socket.off('comments', this.listenForComments);
  }

  listenForComments = data => {
    const allComments = { ...this.state.allComments };
    const allKeys = Object.keys(allComments);
    const lastKey = allKeys[allKeys.length - 1];

    allComments[lastKey].push(data);
    this.setState({ allComments: allComments }, () => {
      this.props.incrementCommentCount();
    });
  }

  getAllComments = () => {
    const params = {
      campaign_id: this.state.campaignId,
      client_id: CLIENT_ID,
    };

    Utility.sendRequest(CAMPAIGN_COMMENTS, 1, params, (err, res, body) => {
      body = JSON.parse(body);
      if (body.error) {
        toast.error(body.error.message, { containerId: 'private' });
      } else {
        this.setState({ allComments: body.data });
      }
    });
  }

  handleCommentSubmit = () => {
    const params = {
      message: this.state.comment,
      campaign_id: this.state.campaignId,
      user_client_id: this.props.user_client_id,
      client_id: CLIENT_ID,
    };

    Utility.sendRequest(CAMPAIGN_COMMENTS, 2, params, (err, res, body) => {
      body = JSON.parse(body);
      if (body.error) {
        toast.error(body.error.message, { containerId: 'private' });
      }else{
        this.setState({ comment: '' });
      }
    });
  };

  handleKeyUp = (ev) => {
    if(!Utility.isEmpty(ev.target.value) && this.state.comment){
      if(ev.keyCode === 13)
        this.handleCommentSubmit();
    }
  }

  paintComments = (comments) => {
    const arr = [];
    comments.map(e => {
      arr.push(
        // Left Side chat box design
        <div className="d-flex mb-20" >
          <div className="user-avtar-outer mr-2" >
          {!Utility.isEmpty(e.creator_profile_image_url) ?
          <img src={e.creator_profile_image_url} className="avtar-30 rounded-circle" alt="" /> :
            <Avatar
              name={e.creator_name}
              round={true}
              color="#007bb3"
              maxInitials={1}
              size={"30"}
              textSizeRatio={1.75}
            />}
          </div>
          <div className="article-page-side-chat-body" >
            <div className="article-page-side-chat-left p-3 font-14" >
              {e.text}
            </div>
            <div className="d-flex justify-content-between mt-1" >
              <img
                hidden
                src={require('assets/images/icons/heart-light.svg')}
                alt="" className="cursor-pointer"
              />
              <span></span>
              <span className="mr-5 font-12 font-light" >
                {moment.utc(e.updated_at).local().format("hh:mm A")}
              </span>
            </div>
          </div>
        </div>
        // right side chat box design

        /*<div className="d-flex mb-20" >
          <div className="article-page-side-chat-body" >
            <div className="article-page-side-chat-right p-3 font-14" >
              {e.text}
            </div>
            <div className="d-flex justify-content-between mt-1" >
              <span className="ml-5 font-12 font-light" >
              {moment.utc(e.updated_at).local().format("hh:mm A")}
              </span>
              <img src={require('assets/images/icons/heart-light.svg')} alt="" className="cursor-pointer" />
            </div>
          </div>
          <div className="user-avtar-outer ml-2" >
            <img src={e.creator_profile_image_url || require("assets/images/users/user_no_image.png")} className="avtar-30 rounded-circle" alt="" />
          </div>
        </div>*/
      );
    });
    return arr;
  };

  render(){
    return (
      <>
        <div className="article-page-side h-100" >
          <div className="article-page-side-head py-3 px-4 align-items-center border-bottom" >
            <ChatOvalIcon fillClass="fill-less-black" />
            <span className="display-8 font-bold ml-2" >{this.props.campaignChatHeading}</span>
            <ChevronUpIcon
              handleClick={() => this.props.switchRightSection('none')}
              mainClass="chevron-up-icon"
            />
            {(CLIENT_ID==="0bc8dc7c-b5a3-4344-b313-819ppzhau6b5" || this.props.loadedFrom === 'campaignModule') &&
              <CloseIcon
                mainClass="ml-auto cursor-pointer"
                handleClick={() => this.props.switchRightSection('none')}
              />
            }
          </div>
          <div className="article-page-side-body article-page-side-body-chat" >
            {
              Object.keys(this.state.allComments).map(e => {
                return <>
                  <div className="text-center font-12 font-med mb-4">
                  {moment.utc(e).local().format("ll")}
                  </div>
                  {this.paintComments(this.state.allComments[e])}
                </>;
              })
            }
          </div>
          <div className="article-page-side-footer pt-4 border-top" >
            <div className="article-page-side-input pwa-form position-relative px-4 pb-4" >
              <input
                type="text"
                placeholder="Write a comment..."
                value={this.state.comment}
                onKeyUp={this.handleKeyUp}
                onChange={(ev) => this.setState({ comment: ev.target.value })}
                className="sm-input-height"
              />
              <div className="position-absolute article-page-side-actions" >
                {/*<SmileIcon mainClass="mr-3 cursor-pointer" fillClass="fill-primary" strokeClass="stroke-primary" />*/}
                <SendIcon
                  mainClass="ml-1 cursor-pointer"
                  fillClass={ this.state.comment
                              ? 'fill-primary'
                              : 'fill-grey'}
                  handleClick={ this.state.comment
                                ? this.handleCommentSubmit
                                : () => {}}
                />
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
}
