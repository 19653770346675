import { AES_IV } from 'config/constants';
import CryptoAES from 'crypto-js/aes';
import CryptoENC from 'crypto-js/enc-utf8';


export function loadState(){
  try {
    const stringState = localStorage.getItem('state');
    let decryptedState = CryptoAES.decrypt(stringState.toString(), AES_IV);
    decryptedState = decryptedState.toString(CryptoENC);

    return JSON.parse(decryptedState);
  } catch(err) {
    return undefined;
  }
}

export function saveState(state){
  try {

    const stringState = JSON.stringify(state);
    const encryptedState = CryptoAES.encrypt(stringState, AES_IV);

    localStorage.setItem("state", encryptedState);
  } catch(err) {
    console.log(err);
  }
}
