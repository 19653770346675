import React, { useState, useEffect } from "react";
import { SuggestedReadsCard } from "shared/components/suggested_reads";
import { Form } from "react-bootstrap";
import { InputField } from "shared/components/form_elements";
import { toast } from "react-toastify";
import { useSelector } from "react-redux";
import * as Utility from "utils/utility";
import { CLIENT_ID, CRM_MODULES } from "config/constants/config";
import {
  CAMPAIGNS_TOP_STORIES,
  ALL_PARENT_CAMPAIGNS,
  GROUP_CAMPAIGNS,
  COURSES_BY_CATEGORY_ID,
  CAMPAIGNS_BY_CATEGORY_NAME,
  GROUPS_BY_CATEGORY_NAME,
  GETALLJOINEDGROUPS,
  GET_USERS_BY_NETWORKS,
  GET_FOLLOWERS_FOLLOWING,
  CATEGORIES_BY_MODULE_ID,
} from "config/constants/api_endpoints";
import { CAMPAIGN_TYPES_IDS, GIL_CLIENT_ID, ROLE_IDS } from "config/constants";
import GroupCard from "shared/components/cards/group_card";
import Avatar from "react-avatar";
import MyCommunity from "shared/components/my_community";
import { ChevronLeftIcon } from "shared/icons/icons";
import {
  showLoader,
  updateSeeAll,
  updateIsChatFormOpen,
  updateChatFriend,
} from "shared/actions";
import { connect } from "react-redux";
import { MdPhoto } from "react-icons/md";
import { AiOutlineSearch } from "react-icons/ai";
import CourseContainer from "./CourseContainer";
import CourseContainerCard from "./CourseContainer";
import Topics from "../../../scenes/users/components/home/topics";
import Create_course from "../cards/create_course";
import { Search } from "@mui/icons-material";
import Welcome_region from "../cards/welcome_region";
import CampaignSelectionForm from "../../../scenes/users/components/create_campaign/campaign_selection_form";
import { useDispatch } from "react-redux";
import { updateCampaignFormOpen } from "shared/actions";
import { GrSearch } from "react-icons/gr";
import { allNews, allNewsCategories } from "../../../utils/mockData/news";
import {
  allCourses,
  allCoursesCategories,
  myCommunities,
} from "../../../utils/mockData/courses";
import { EventIcon, NewsIcon } from "../../icons/icons";
import CreateNews from "../cards/create_news";
import { allEvents, allEventsCategories } from "../../../utils/mockData/events";
import { DesktopDatePicker } from "./campaign/components/desktop_datepicker";
import EventContainerCard from "./EventsContainer";
import EventCard from "../../../scenes/profile/components/EventCard";
import { CAMPAIGN_STORIES } from "config/constants/api_endpoints";
import { REGIONS_LIST } from "../../../config/constants/api_endpoints";

function Articles(props) {
  const [startDate, setStartDate] = useState(new Date());
  const [endDate, setEndDate] = useState(new Date());
  const [isStartDateChanged, setIsStartDateChanged] = useState(false);
  const [isEndDateChanged, setIsEndDateChanged] = useState(false);
  const userData = useSelector((state) => state.userData);
  const refreshCampaignApi = useSelector((state) => state.refreshCampaignApi);
  const userId = props.userId ? props.userId : userData.id;
  const [campaignsTopStories, setCampaignsTopStories] = useState([]);
  const [filteredTopStories, setFilteredTopStories] = useState([]);
  const [campaignsFeaturedStories, setCampaignsFeaturedStories] = useState([]);
  const [filteredFeaturedStories, setFilteredFeaturedStories] = useState([]);
  const [search, setSearch] = useState("");
  const [isApiResponse, setIsApiResponse] = useState(false);
  const [heading, setHeading] = useState(props.heading);
  const [joinedGroups, setAllJoinedGroups] = useState([]);
  const [topicExperts, setTopicExperts] = useState([]);
  const [categoriesList, setCategoriesList] = useState([]);
  const [selectedItem, setSelectedItem] = useState("");
  const isCampaignFormOpen = useSelector((state) => state.isCampaignFormOpen);
  const [topicsFilter, setTopicsFilter] = useState([]);
  let debouncedFn = null;

  const dispatch = useDispatch();

  // const filterEventsByDate = (startDate, endDate, data) => {
  //   const filteredData = data.filter((item) => {
  //     return (
  //       new Date(item.start_at) - startDate >= 0 &&
  //       new Date(item.start_at) - endDate <= 0
  //     );
  //   });
  //   return filteredData;
  // };

  // useEffect(() => {
  //   if (startDate && endDate) {
  //     setCampaignsTopStories(
  //       filterEventsByDate(startDate, endDate, filteredTopStories)
  //     );
  //     setCampaignsFeaturedStories(
  //       filterEventsByDate(startDate, endDate, filteredFeaturedStories)
  //     );
  //   }
  // }, [startDate, endDate]);

  // API for getting featured/All courses, news, events
  const loadAllStories = async ({
    user_client_id,
    campTypeId,
    isFeatured,
    userId,
    start_at,
    end_at,
  }) => {
    const params = {
      publisher_network_id: CLIENT_ID,
      type: campTypeId,
      is_featured: isFeatured,
      user_id: userId,
      user_client_id: user_client_id,
      start_at: start_at,
      end_at: end_at,
    };

    await Utility.sendRequest(CAMPAIGN_STORIES, 1, params, (err, res, body) => {
      body = JSON.parse(body);

      if (body.error) {
        toast.error(body.error.message, { containerId: "private" });
      } else {
        const Data = body.data.filter(
          (items) =>
            items.corporate_is_active === true ||
            items.corporate_is_active === null
        );
        if (isFeatured === true) {
          setCampaignsFeaturedStories(Data);
          setFilteredFeaturedStories(Data);
        } else {
          setCampaignsTopStories(Data);
          setFilteredTopStories(Data);
        }
      }
    });
  };

  useEffect(() => {
    fetchData();
  }, [search, refreshCampaignApi]);
  useEffect(() => {
    getRegions();
  }, []);

  const fetchData = () => {
    if (props.type === "suggestedReads") {
      getCampaignsTopStoriesDetails();
    } else if (props.type === "myArticles") {
      getMyArticles();
    } else if (props.type === "groupArticles") {
      getGroupCampaigns();
    } else if (props.type === "courses") {
      if (!Utility.isEmpty(props.category_id)) getCourses();
      else getAllCourses();
    } else if (props.type === "category_articles") {
      getAllCampaigns(props.category_name, "articles");
      setHeading("Articles");
    } else if (props.type === "category_events") {
      getAllCampaigns(props.category_name, "events");
      setHeading("Events");
    } else if (props.type === "category_courses") {
      getAllCampaigns(props.category_name, "courses");
      setHeading("Courses");
    } else if (props.type === "category_groups") {
      getAllGroupsByCategory(props.category_name, "groups");
      setHeading("Groups");
    } else if (props.type === "category_experts") {
      getCategoryExperts(props.category_name);
      setHeading("Experts");
    }
  };

  const getData = (params, url) => {
    params.search = search;
    params.user_client_id = props.userData.id;

    props.showLoader(true);
    Utility.sendRequest(url, 1, params, (err, res, body) => {
      body = JSON.parse(body);

      setIsApiResponse(true);
      props.showLoader(false);
      if (body.error) {
        toast.error(body.error.message, { containerId: "private" });
      } else {
        if (url === ALL_PARENT_CAMPAIGNS) setCampaignsTopStories(body.data);
        else if (url === COURSES_BY_CATEGORY_ID) setCampaignsTopStories(body);
        // setCampaignsTopStories(body); //For setting News Data
        else setCampaignsTopStories(allNews);
      }
    });
  };

  const getCampaignsTopStoriesDetails = () => {
    const campTypeId = CAMPAIGN_TYPES_IDS.story;
    loadAllStories({ campTypeId: campTypeId });
    loadAllStories({
      user_client_id: userData.id,
      campTypeId: campTypeId,
      isFeatured: true,
      userId: userData.user_id,
    });
  };

  const getAllCampaigns = (topicName, type) => {
    const params = {
      client_id: CLIENT_ID,
      category_name: topicName,
      all: true,
    };
    setCampaignsTopStories([]);

    if (search) params.search = search;

    props.showLoader(true);
    Utility.sendRequest(
      CAMPAIGNS_BY_CATEGORY_NAME,
      1,
      params,
      (err, res, body) => {
        body = JSON.parse(body);

        props.showLoader(false);
        setIsApiResponse(true);

        if (body.error) {
          toast.error(body.error.message, { containerId: "private" });
        } else {
          if (type === "articles") {
            setCampaignsTopStories(body.articles);
          } else if (type === "events") {
            setCampaignsTopStories(body.events);
          } else if (type === "courses") {
            setCampaignsTopStories(body.courses);
          }
        }
      }
    );
  };

  const setDetailsFollowerfollowing = (followingId) => {
    const { id } = userData;
    if (id !== followingId) {
      const params = {
        followerId: id,
        followingId: followingId,
        clientId: CLIENT_ID,
      };

      Utility.sendRequest(
        GET_FOLLOWERS_FOLLOWING,
        2,
        params,
        (err, res, body) => {
          body = JSON.parse(body);
          if (body.error) {
            toast.error(body.error.message, { containerId: "private" });
          } else {
            getCategoryExperts(props.category_name);
          }
        }
      );
    }
  };

  const getCategoryExperts = (topicName = props.category_name) => {
    const params = {
      client_id: CLIENT_ID,
      category_name: topicName,
      forGroupMembers: true,
    };
    if (search) params.search = search;

    Utility.sendRequest(GET_USERS_BY_NETWORKS, 1, params, (err, res, body) => {
      body = JSON.parse(body);
      if (body.error) {
        toast.error(body.error.message, { containerId: "private" });
      } else {
        setTopicExperts(body.registrants);
      }
    });
  };

  const getAllGroupsByCategory = (topicName) => {
    const params = {
      client_id: CLIENT_ID,
      category_name: topicName,
      all: true,
    };

    if (search) params.search = search;

    props.showLoader(true);
    Utility.sendRequest(
      GROUPS_BY_CATEGORY_NAME,
      1,
      params,
      (err, res, body) => {
        body = JSON.parse(body);

        props.showLoader(false);
        setIsApiResponse(true);

        if (body.error) {
          toast.error(body.error.message, { containerId: "private" });
        } else {
          setCampaignsTopStories(body);
          getGroupsYouHaveJoined();
        }
      }
    );
  };

  const getGroupsYouHaveJoined = () => {
    const params = {
      user_client_id: userData.id,
    };

    Utility.sendRequest(GETALLJOINEDGROUPS, 1, params, (err, res, body) => {
      body = JSON.parse(body);
      if (body.error) {
        console.log(body.error);
      } else {
        setAllJoinedGroups(body.data);
      }
    });
  };

  const handleGroupClick = (selectedGroup) => {
    const group = joinedGroups.filter(
      (item) => item.group_id === selectedGroup.group_id
    );
    if (!Utility.isEmpty(group)) {
      props.updateIsChatFormOpen(true);
      props.updateChatFriend(group[0]);
    } else {
      props.updateIsChatFormOpen(true);
      props.updateChatFriend({
        ...selectedGroup,
        fromTopics: true,
      });
    }
    props.updateSeeAll({
      ...props.seeAll,
      isOpen: null,
    });
  };

  const getMyArticles = () => {
    const params = {
      client_id: CLIENT_ID,
      user_client_id: userId,
      is_approved: true,
      campaign_type_id: CAMPAIGN_TYPES_IDS.story,
      all: true,
    };

    getData(params, ALL_PARENT_CAMPAIGNS);
  };

  const getGroupCampaigns = () => {
    const params = {
      groupId: props.groupId,
      clientId: CLIENT_ID,
    };
    getData(params, GROUP_CAMPAIGNS);
  };

  const getFormattedDate = (date) => {
    return (
      date.getFullYear() +
      "-" +
      (date.getMonth() + 1 > 9
        ? date.getMonth() + 1
        : "0" + (date.getMonth() + 1)) +
      "-" +
      (date.getDate() > 9 ? date.getDate() : "0" + date.getDate())
    );
  };

  // const getCategoriesByModuleId = () => {
  //   const params = {
  //     module_id: CRM_MODULES.CAMPAIGNS,
  //     client_id: CLIENT_ID,
  //     campaign_type_id:
  //       props.type === "suggestedReads"
  //         ? CAMPAIGN_TYPES_IDS.story
  //         : CAMPAIGN_TYPES_IDS.courses,
  //   };

  //   Utility.sendRequest(
  //     CATEGORIES_BY_MODULE_ID,
  //     1,
  //     params,
  //     (err, res, body) => {
  //       body = JSON.parse(body);

  //       if (body.error) {
  //         toast.error(body.error.message, { containerId: "private" });
  //       } else {
  //         // setCategoriesList(body.data);
  //         if (props.type === "suggestedReads")
  //           setCategoriesList(allNewsCategories);
  //         else if (props.type === "eventsPage")
  //           setCategoriesList(allEventsCategories);
  //         else if (props.type === "courses")
  //           setCategoriesList(allCoursesCategories);
  //         else setCategoriesList([]);
  //       }
  //     }
  //   );
  // };

  const goBackLink = () => {
    //props.updateSeeAll({ isOpen: props.type, calledFrom: props.type });
    return `/topics/${props.category_name}`;
  };

  const handleSearch = (ev) => {
    ev.persist();

    if (!debouncedFn) {
      debouncedFn = Utility.debounce(() => {
        const searchString = ev.target.value;
        setSearch(searchString);
      }, 500);
    }
    debouncedFn();
  };

  const decrypteGroupMessages = (data) => {
    const actualMessage = Utility.decryptAllMessages(
      data.recent_message,
      "",
      "",
      data.group_private_key
    );
    return actualMessage;
  };

  useEffect(() => {
    if (
      props.type === "eventsPage" &&
      (isStartDateChanged || isEndDateChanged)
    ) {
      const campTypeId = CAMPAIGN_TYPES_IDS.event;
      const start_at = isStartDateChanged && getFormattedDate(startDate);
      const end_at = isEndDateChanged && getFormattedDate(endDate);
      loadAllStories({
        campTypeId: campTypeId,
        start_at: start_at,
        end_at: end_at,
      });
      loadAllStories({
        user_client_id: userData.id,
        campTypeId: campTypeId,
        isFeatured: true,
        userId: userData.user_id,
        start_at: start_at,
        end_at: end_at,
      });
    }
  }, [startDate, endDate]);

  useEffect(() => {
    if (props.type === "eventsPage") {
      const campTypeId = CAMPAIGN_TYPES_IDS.event;
      loadAllStories({ campTypeId: campTypeId });
      loadAllStories({
        user_client_id: userData.id,
        campTypeId: campTypeId,
        isFeatured: true,
        userId: userData.user_id,
      });
    }
  }, [props.type]);

  const getAllCourses = () => {
    const campTypeId = CAMPAIGN_TYPES_IDS.courses;
    loadAllStories({ campTypeId: campTypeId });
    loadAllStories({
      user_client_id: userData.id,
      campTypeId: campTypeId,
      isFeatured: true,
      userId: userData.user_id,
    });
  };

  const getRegions = () => {
    const params = {};
    Utility.sendRequest(REGIONS_LIST, 1, params, (err, res, body) => {
      body = JSON.parse(body);
      if (body.error) {
        toast.error(body.error.message, { containerId: "private" });
      } else {
        setCategoriesList(body.data);
      }
    });
  };

  const getCourses = () => {
    const campTypeId = CAMPAIGN_TYPES_IDS.courses;
    loadAllStories({ campTypeId: campTypeId });
    loadAllStories({
      user_client_id: userData.id,
      campTypeId: campTypeId,
      isFeatured: true,
      userId: userData.user_id,
    });
  };

  return (
    <div className="pwa-callout-body">
      {selectedItem === ("course" || "news") && (
        <CampaignSelectionForm
          position="position-fixed create-campaign-margin"
          userData={userData}
          setSelectedItem={setSelectedItem}
          selectedItem={selectedItem}
        />
      )}

      <>
        <div
          className={`pt-5 d-flex align-items-center mb-3 mb-lg-4  pl-4 pl-md-4  pr-0`}
        >
          {heading === "Courses" ? (
            <img
              src={require("assets/images/icons/courses.png")}
              // height={20}
              width={22}
            />
          ) : heading === "News" ? (
            <NewsIcon className="pt-1" w={25} h={25} />
          ) : heading === "Events" ? (
            <EventIcon w={25} h={22} />
          ) : (
            <></>
          )}
          <span className="ml-2 font-24-bold">{heading}</span>
        </div>
        <div className="home-page-padding">
          <div className="mt-0">
            <div className="d-flex ">
              {/* <Form className="shar-form search-form form-inline shadow">
                  <div className="course-input-group input-group">
                    <InputField
                      type="text"
                      className="h-25 headerSearchBar "
                      placeholder={`Search ${heading}`}
                      handleChange={(ev) => handleSearch(ev)}
                    />
                    <div className="input-group-append">
                      <span
                        className="input-group-text course-search-icon bg-white"
                        id="basic-addon2"
                      >
                        <Search />
                      </span>
                    </div>
                  </div>
                </Form> */}
              <div
                className="SeactionSearchContainer search-communities position-relative pl-2"
                style={{ left: "-1%" }}
              >
                <div className="searchSectionWrapper bg-white">
                  <input
                    className="SeactionSearch"
                    placeholder={`Search ${heading}`}
                    onChange={(ev) => handleSearch(ev)}
                  />

                  <span className="SeactionSearchIcon">
                    <GrSearch className="regionIcon" />
                  </span>
                </div>
              </div>

              {/* <Form className="shar-form search-form search-form-pwa sidebar-form">
              <Form.Group className="mb-0">
                <InputField
                  required={true}
                  type="text"
                  placeholder="Search Community"
                  handleChange={(ev) => handleSearch(ev)}
                />
              </Form.Group>
            </Form> */}
            </div>
          </div>
        </div>

        {/* {heading === "Courses" && (
            <div className="ml-3">
              {" "}
              <Topics
                className="topics"
                isRegion={true}
                setTopicsFilter={setTopicsFilter}
                topicsFilter={topicsFilter}
              />
            </div>
          )} */}
        {heading === "Courses" ? (
          <Create_course
            setSelectedItem={setSelectedItem}
            heading={"COURSE"}
            item="course"
          />
        ) : heading === "News" ? (
          <Create_course
            setSelectedItem={setSelectedItem}
            heading={"ARTICLE"}
            item="news"
          />
        ) : heading === "Events" ? (
          <div>
            <Create_course
              setSelectedItem={setSelectedItem}
              heading={"EVENT"}
              item="event"
            />
            <div className="datePickerContainer mt-4">
              <div className="startDatePicker">
                <p className="startDate">Start Date</p>
                <div className="mr-4">
                  <DesktopDatePicker
                    value={startDate}
                    setValue={setStartDate}
                    maxDate={endDate}
                    setIsChanged={setIsStartDateChanged}
                  />
                </div>
              </div>
              <div className="endDatePicker">
                <p className="endDate">End Date</p>
                <div className="mr-4">
                  <DesktopDatePicker
                    value={endDate}
                    setValue={setEndDate}
                    minDate={startDate}
                    setIsChanged={setIsEndDateChanged}
                  />
                </div>
              </div>
            </div>
          </div>
        ) : (
          <></>
        )}

        {props.type !== "category_groups" &&
          props.type !== "category_experts" &&
          props.type !== "eventsPage" && (
            <>
              <div className="mt-4">
                <CourseContainerCard
                  courseData={campaignsFeaturedStories}
                  PanalTitle={
                    props.type === "suggestedReads"
                      ? "Featured News"
                      : "Featured Courses"
                  }
                  category="featured"
                  type={props.type}
                  categoriesList={categoriesList}
                  isArticles={props.type === "suggestedReads" ? true : false}
                  search={search.toLowerCase()}
                  // width={CLIENT_ID===GIL_CLIENT_ID && props.type==="suggestedReads" && "93vw"}
                />
                <CourseContainerCard
                  courseData={campaignsTopStories}
                  PanalTitle={
                    props.type === "suggestedReads" ? "All News" : "All Courses"
                  }
                  type={props.type}
                  categoriesList={categoriesList}
                  isArticles={props.type === "suggestedReads" ? true : false}
                  search={search.toLowerCase()}
                  // width={CLIENT_ID===GIL_CLIENT_ID && props.type==="suggestedReads" && "93vw"}
                />
              </div>
              {categoriesList.map((categoryDetails) => {
                return (
                  <div key={categoryDetails.id} className="mt-4">
                    <CourseContainerCard
                      courseData={
                        categoryDetails.name === "Global"
                          ? campaignsTopStories.filter(
                              (data) =>
                                data.region_details &&
                                Object.keys(data.region_details).length > 0
                            )
                          : campaignsTopStories.filter(
                              (data) =>
                                data.region_details &&
                                Object.keys(data.region_details).length > 0 &&
                                data.region_details.REGION_NAME ===
                                  categoryDetails.name
                            )
                      }
                      PanalTitle={
                        categoryDetails.name === "Global"
                          ? props.type === "courses"
                            ? "Global Courses"
                            : "Global News"
                          : categoryDetails.name
                      }
                      colorCode={categoryDetails.color_code}
                      type={props.type}
                      categoriesList={categoriesList}
                      isArticles={
                        props.type === "suggestedReads" ? true : false
                      }
                      search={search.toLowerCase()}
                      // width={CLIENT_ID===GIL_CLIENT_ID && props.type==="suggestedReads" && "93vw"}
                    />
                  </div>
                );
              })}
              {/* <div className="mt-4">
                  <CourseContainer
                    courseData={campaignsTopStories}
                    PanalTitle="Freatured Course"
                    category="featured"
                    type={props.type}
                  />
                </div> */}
              {/* <div className="mt-4">
                  <CourseContainer
                    courseData={campaignsTopStories.filter((data) => {
                      return data.campaign_category === "Courses";
                    })}
                    PanalTitle="Courses"
                    category="courses"
                    type={props.type}
                  /> */}
              {/* campaignsTopStories.filter(data => {
              return data.campaign_category === "Courses"
            }) */}
              {/* </div> */}
              {/* <div className="mt-4">
                  <CourseContainer
                    courseData={campaignsTopStories.filter((data) => {
                      return data.campaign_category === "Course Test";
                    })}
                    PanalTitle="Course Test"
                    category="course-test"
                    type={props.type}
                  />
                  
                </div> */}
              {/* <div className="mt-4">
                  <CourseContainer
                    courseData={campaignsTopStories.filter((data) => {
                      return data.campaign_category === "Sustainable";
                    })}
                    PanalTitle="Sustainable"
                    category="sustainable"
                    type={props.type}
                  />
                  
                </div> */}
            </>
          )}
        {props.type === "eventsPage" && (
          <>
            <div className="mt-4 ml-2">
              <EventContainerCard
                eventsData={campaignsFeaturedStories}
                PanalTitle={"Featured Events"}
                category="featured"
                type={props.type}
                categoriesList={categoriesList}
                isArticles={props.type === "suggestedReads" ? true : false}
                search={search.toLowerCase()}
              />
              <EventContainerCard
                eventsData={campaignsTopStories}
                PanalTitle={"All Events"}
                type={props.type}
                categoriesList={categoriesList}
                isArticles={props.type === "suggestedReads" ? true : false}
                search={search.toLowerCase()}
              />
            </div>
            {categoriesList.map((categoryDetails) => {
              return (
                <div key={categoryDetails.id} className="mt-4 ml-2">
                  <EventContainerCard
                    eventsData={
                      categoryDetails.name === "Global"
                        ? campaignsTopStories.filter(
                            (data) =>
                              data.region_details &&
                              Object.keys(data.region_details).length > 0
                          )
                        : campaignsTopStories.filter(
                            (data) =>
                              data.region_details &&
                              Object.keys(data.region_details).length > 0 &&
                              data.region_details.REGION_NAME ===
                                categoryDetails.name
                          )
                    }
                    PanalTitle={
                      categoryDetails.name === "Global"
                        ? "Global Events"
                        : categoryDetails.name
                    }
                    colorCode={categoryDetails.color_code}
                    type={props.type}
                    categoriesList={categoriesList}
                    isArticles={props.type === "suggestedReads" ? true : false}
                    search={search.toLowerCase()}
                    // width={CLIENT_ID===GIL_CLIENT_ID && props.type==="suggestedReads" && "93vw"}
                  />
                </div>
              );
            })}
          </>
        )}
      </>
    </div>
  );
}

const mapActionToProps = {
  showLoader,
  updateSeeAll,
  updateIsChatFormOpen,
  updateChatFriend,
};

const mapStateToProps = (state) => ({
  userData: state.userData,
  seeAll: state.seeAll,
});

export default connect(mapStateToProps, mapActionToProps)(Articles);
