import React from 'react';
import UploadFile from '../file_upload';
import { CircleFilledAddLargeIcon } from 'shared/icons/icons';
import { Image } from 'react-bootstrap';
import moment from 'moment';
import Avatar from 'react-avatar';
import { Utility } from 'utils';

export default function CreatebleCard (props){
  const paintUpdateAt = (updatedAt) => {
    let date = moment(updatedAt).format('ll');
    const time = moment(updatedAt).format("hh:mm A");
    date = date.split(',')[0];
    return `Updated at ${time}, ${date}`;
  };

  return(
    <div
      onClick={props.handleClick}
      className={props.className + " overflow-hidden mb-4"} >
      <div className="align-items-center justify-content-center post-pic position-relative page-sidebar-upload w-100" >
        <label className="post-uplodad-label mb-0 cursor-pointer">
        { props.useUpload &&
          <>
            <UploadFile
              name='image_url'
              inputId="image_url"
              removeText="Remove image"
              categoryName="category_image"
              acceptTypes="image/x-png,image/gif,image/jpeg"
            />
            <div className="error errorIcon" id={'image_urlError'} />
            </>
          }
          {
            props.data && props.data.image_url ?
            <Image
            src={props.data && props.data.image_url}
            className="creatable-card-image"
            alt=""
            /> :
            <CircleFilledAddLargeIcon mainClass="add-icon-upper" fillClass="fill-primary" />
          }
        </label>
      </div>
      <div className="px-3 py-4" >
        {
          props.forTestimony &&
          <div
            className={`font-14 mb-20 font-bold`}
            style={{ color: props.data.category_color }}>
            {props.data.campaign_category}
          </div>
        }
        <h1 className="display-7 font-bold text-dark mb-3 creatable-card-title" >{props.title}</h1>
        <div className="font-13 font-medium" >
          {
            props.forTestimony ?
            <div className="d-flex mt-3" >
              {!Utility.isEmpty(props.data.author_image_url) ?
              <Image
                src={props.data.author_image_url}
                className="article-pic rounded-circle mr-0"
                alt=""
              />
              :
              <Avatar
              name={props.data.author_name}
              round={true}
              size={'20'}
              textSizeRatio={1.75}
              color='#007bb3'
              maxInitials={1}
              />
                }
              <div className='ml-2'>
                <div className="font-bold font-12 text-dark text-capitalize">{props.data.author_name}</div>
                <div className="font-12 text-secondary" >
                  {
                    props.data.image_url ?
                    paintUpdateAt(props.data.updated_at) :
                    'Share story with group...'
                  }
                </div>
              </div>
            </div>
          : props.description
        }
        </div>
      </div>
    </div>
  );
}
