import React, { Component } from 'react';
import { Row, Col, Form, Button } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { Utility } from 'utils';
import { InputField } from 'shared/components/form_elements';
import Card from 'scenes/tools/toolbox/gift_cards/public/components/card';
import GCSocialSharing from 'scenes/tools/toolbox/gift_cards/public/components/gc_social_sharing';

const validateFields = ['email'];

export default class ThankYouScreen extends Component {
  constructor(props) {
    super(props);

    this.state = {
      formData: {
        email: ''
      },
      isEmailUpdate: false
    };
  }

  handleChange = (e) => {
    let formData = this.state.formData;
    formData = Utility.validateAndReturnFormData(this, formData, e, validateFields);
    this.setState({ formData });
  }

  submit = (e) => {
    e.preventDefault();
    if (!Utility.showFormErrors(this, validateFields)) return;

    this.props.updateAudience(this.state.formData);
  }

  render(){
    return(
      <Row className="giftcard-outer-container mx-auto justify-content-center">
        <Col sm={6} >
          <Card
            colorCode={this.props.colorCode}
            selectedBrand={this.props.selectedBrand}
            selectedBeneficiary={this.props.selectedBeneficiary}
          />
        </Col>
        <Col sm={6} className="pl-5">
          <Link to={{}} onClick={() => this.props.backSteps()} className="font-bold d-block my-4 py-3 link-light-grey focus-none" >SUBMIT ANOTHER GIFT CARD</Link>
          <div className="donate-card-right-panel">
            <div className="donate-form2 thankyou_panel display-8">
              <div className="success-icon"><img src={require("assets/images/gift_card/success.svg")} alt="success"/> </div>
              <h2 style={{ color: this.props.colorCode }}>Thank you!</h2>
              <div className="entry_info mb-4">You have successfully donated your gift card!</div>
              <div className="thank_cont mb-5 pb-4 py-2">
                A confirmation email will be sent shortly to  <span id="user_email" className="link-light-grey">{(this.props.audienceData[0].email) ? this.props.audienceData[0].email : ''}</span>, providing the details of your gift.
              </div>
              <p>Did you provide the desired email address? <br/> Click <Link to={{}} onClick={ () => {this.setState({ isEmailUpdate: !this.state.isEmailUpdate }); }}  className="blue-text"> here</Link> to update.</p>
              <div id="error_message3"></div>
              <div id="update" className={(this.state.isEmailUpdate) ? " " : "email-update" }>
                <Form className="audience-form">
                  <Form.Group controlId="" className="mt-3">
                    <InputField
                      required={true}
                      name="email"
                      ref="email"
                      type="email"
                      handleChange={this.handleChange}
                      placeholder="Update Email Address"
                      inlineStyle= {{ border: `2px solid  ${this.props.colorCode }` }}
                    />
                  </Form.Group>
                  <Button type="submit" variant="primary" className="sm-btn mb-3" style={{ backgroundColor: this.props.colorCode, borderColor: this.props.colorCode, borderRadius: '0' }} onClick={(e) => this.submit(e)}>Update</Button>
                </Form>
              </div>
            </div>
            <div className="share_info font-bold text-black-muted">Share on social media</div>
            <GCSocialSharing brandName={this.props.selectedBrand.brand_name} urlLocation={this.props.urlLocation} clientName={this.props.selectedBeneficiary}/>
          </div>
        </Col>
      </Row>
    );
  }
}
