import {
  UPDATE_CRM_MODULES,
  UPDATE_USER_DATA,
  SHOW_LOADER,
  UPDATE_CLIENT_TYPES,
  UPDATE_SD_GOALS,
  UPDATE_SELECTED_MODULE,
  UPDATE_SELECTED_MENU_ITEM,
  UPDATE_SELECTED_GROUP,
  EDIT_COMPONENT,
  UPDATE_PRODUCTED_ID,
  UPDATE_SELECTED_MODULE_MENU,
  UPDATE_SELECTED_FILTERS,
  UPDATE_ACCOUNT_LIST,
  OPEN_NEIGHBORHOOD_FORM,
  UPDATE_PUBLIC_USER_DATA,
  IS_CAMPAIGN_FORM_OPEN,
  IS_NEIGHBORHOOD_FORM_OPEN,
  IS_CAMP_SEL_FORM_OPEN,
  CAMPAIGN_SELECTED_TAB,
  CAMPAIGN_FORM_SELECTED_ID,
  IS_CHAT_FORM_OPEN,
  CHAT_FRIEND,
  SEE_ALL,
  REFRESH_CAMPAIGN_API,
  REFRESH_EVENTS_API,
  OPEN_CITY_PURCHASE,
  UPDATE_LESSONS,
  PLAN_UPGRADE,
  ADMIN_ACCESS_REQUEST,
  ACCEPT_ADMIN_REQUEST,
  OPEN_PLUS_ICON,
  CURRENT_FOOTER_LINK,
  CART_ADD_ITEM,
  CART_REMOVE_ITEM,
  UPDATE_GUEST_DATA,
  USER_DISTRACTED,
  EXT_TOPIC,
  SESSION_START,
  EXTENSION_DATA,
  IS_CAMPAIGN_VIDEO_PLAYING,
  CATEGORY_LISTING_INFO,
  CART_REMOVE_ALL,
  IS_SESSION_MODAL_OPEN,
  NEW_CORPORATE_JOINED,
  SHOW_EXTENSION_MODAL_REPORT,
  USER_PROFILE_ID,
  SELECTED_SIDEBAR_ITEM,
} from "config/constants/action_types";
import {
  IS_COURSE_DROPDOWN,
  IS_COURSE_SIDEBAR,
  IS_PAGE_COURSE,
  IS_CITIZEN_SIDEBAR_OPEN,
  HEADER_SEARCH_BAR,
  SELECTED_LOCATION,
  UPDATE_USER_NEIGHBORHOOD,
  LISTENFORNEWMESSAGE,
} from "../../config/constants/action_types";

const updateCart = (cartData) => {
  return {
    type: CART_REMOVE_ALL,
    payload: cartData,
  };
};

const updateUserData = (userData) => {
  return {
    type: UPDATE_USER_DATA,
    payload: { userData },
  };
};

const updatenewMessage = (payload) => {
  return {
    type: LISTENFORNEWMESSAGE,
    payload,
  };
};

const userQualities = (payload) => {
  return {
    type: "USERQUALITIES",
    payload,
  };
};

const updatePublicUserData = (publicUserData) => {
  return {
    type: UPDATE_PUBLIC_USER_DATA,
    payload: { publicUserData },
  };
};

const updateClientTypes = (clientTypes) => {
  return {
    type: UPDATE_CLIENT_TYPES,
    payload: { clientTypes },
  };
};

const updateSDGoals = (goals) => {
  return {
    type: UPDATE_SD_GOALS,
    payload: { goals },
  };
};

const showLoader = (data) => {
  return {
    type: SHOW_LOADER,
    payload: { isLoading: data },
  };
};

const updateCrmModules = (crmModules) => {
  return {
    type: UPDATE_CRM_MODULES,
    payload: { crmModules },
  };
};

const updateSelectedModule = (selectedModuleItem) => {
  return {
    type: UPDATE_SELECTED_MODULE,
    payload: { selectedModuleItem },
  };
};

const updateSelectedMenuItem = (selectedMenuItem) => {
  return {
    type: UPDATE_SELECTED_MENU_ITEM,
    payload: { selectedMenuItem },
  };
};

const updateSelectedGroup = (selectedGroup) => {
  return {
    type: UPDATE_SELECTED_GROUP,
    payload: { selectedGroup },
  };
};

const editComponent = (edit) => {
  return {
    type: EDIT_COMPONENT,
    payload: { edit },
  };
};

const updateProductID = (productId) => {
  return {
    type: UPDATE_PRODUCTED_ID,
    payload: { productId },
  };
};

const updateSelectedModuleMenu = (selectedModuleMenu) => {
  return {
    type: UPDATE_SELECTED_MODULE_MENU,
    payload: { selectedModuleMenu },
  };
};

const updateSelectedFilters = (selectedFilters) => {
  return {
    type: UPDATE_SELECTED_FILTERS,
    payload: { selectedFilters },
  };
};

const updateAccountList = (accountList) => {
  return {
    type: UPDATE_ACCOUNT_LIST,
    payload: { accountList },
  };
};

const openNeighborhoodForm = (state) => {
  return {
    type: OPEN_NEIGHBORHOOD_FORM,
    payload: { state },
  };
};

const updateCampaignFormOpen = (state) => {
  return {
    type: IS_CAMPAIGN_FORM_OPEN,
    payload: { state },
  };
};

const updateIsNeighborhoodFormOpen = (state) => {
  return {
    type: IS_NEIGHBORHOOD_FORM_OPEN,
    payload: { state },
  };
};

const updateIsCampSelFormOpen = (state) => {
  return {
    type: IS_CAMP_SEL_FORM_OPEN,
    payload: { state },
  };
};

const updateCampaignSelectedTab = (state) => {
  return {
    type: CAMPAIGN_SELECTED_TAB,
    payload: { state },
  };
};

const updateCampaignFormSelectedId = (state) => {
  return {
    type: CAMPAIGN_FORM_SELECTED_ID,
    payload: { state },
  };
};

const updateIsChatFormOpen = (state) => {
  return {
    type: IS_CHAT_FORM_OPEN,
    payload: { state },
  };
};

const updateIsSessisonModalOpen = (state) => {
  return {
    type: IS_SESSION_MODAL_OPEN,
    payload: { state },
  };
};

const updateChatFriend = (state) => {
  return {
    type: CHAT_FRIEND,
    payload: { state },
  };
};

const updateSeeAll = (state) => {
  return {
    type: SEE_ALL,
    payload: { state },
  };
};

const updateRefreshCampaignAPI = (state) => {
  return {
    type: REFRESH_CAMPAIGN_API,
    payload: { state },
  };
};

const updateRefreshEventsAPI = (state) => {
  return {
    type: REFRESH_EVENTS_API,
    payload: { state },
  };
};

const updateOpenCityPurchase = (state) => {
  return {
    type: OPEN_CITY_PURCHASE,
    payload: { state },
  };
};

const updateLessons = (state) => {
  return {
    type: UPDATE_LESSONS,
    payload: { state },
  };
};

const updatePlanUpgraded = (state) => {
  return {
    type: PLAN_UPGRADE,
    payload: { state },
  };
};

const updateIsAdminAccessRequestFormOpen = (state) => {
  return {
    type: ADMIN_ACCESS_REQUEST,
    payload: { state },
  };
};

const updateIsAcceptedAdminRequestFormOpen = (state) => {
  return {
    type: ACCEPT_ADMIN_REQUEST,
    payload: { state },
  };
};

const openOrClosePlusIcon = (state) => {
  return {
    type: OPEN_PLUS_ICON,
    payload: { state },
  };
};

const updateFooterLink = (state) => {
  return {
    type: CURRENT_FOOTER_LINK,
    payload: { state },
  };
};

const updateSelectedSidebarItem = (state) => {
  return {
    type: SELECTED_SIDEBAR_ITEM,
    payload: { state },
  };
};

const addToCart = (product, quantity = 1) => {
  return {
    type: CART_ADD_ITEM,
    payload: {
      product,
      quantity,
    },
  };
};

const removeFromCart = (product) => {
  return {
    type: CART_REMOVE_ITEM,
    payload: product,
  };
};

const updateGuestData = (guestData) => {
  return {
    type: UPDATE_GUEST_DATA,
    payload: { guestData },
  };
};

const updateUserDistracted = (isUserDistracted) => {
  return {
    type: USER_DISTRACTED,
    payload: { isUserDistracted },
  };
};

const updateExtTopic = (extTopic) => {
  return {
    type: EXT_TOPIC,
    payload: { extTopic },
  };
};

const updateSessionStart = (isSessionStart) => {
  return {
    type: SESSION_START,
    payload: { isSessionStart },
  };
};

const updateExtUserId = (extUserId) => {
  return {
    type: EXTENSION_DATA,
    payload: { extUserId },
  };
};

const updateCategoryListingInfo = (state) => {
  return {
    type: CATEGORY_LISTING_INFO,
    payload: { state },
  };
};

const updateNewCorporateJoined = (state) => {
  return {
    type: NEW_CORPORATE_JOINED,
    payload: { state },
  };
};

const updateExtensionModalReport = (state) => {
  return {
    type: SHOW_EXTENSION_MODAL_REPORT,
    payload: { state },
  };
};

const updateIsCourseSidebar = (state) => {
  return {
    type: IS_COURSE_SIDEBAR,
    payload: state,
  };
};

const updateIsCourseDropdown = (state) => {
  return {
    type: IS_COURSE_DROPDOWN,
    payload: state,
  };
};

const updateIsPageCourse = (state) => {
  return {
    type: IS_PAGE_COURSE,
    payload: state,
  };
};

const updateIsCitizenSidebarOpen = (state) => {
  return {
    type: IS_CITIZEN_SIDEBAR_OPEN,
    payload: state,
  };
};

const updateUserProfileId = (state) => {
  return {
    type: USER_PROFILE_ID,
    payload: state,
  };
};

const headerSearchBar = (state) => {
  return {
    type: HEADER_SEARCH_BAR,
    payload: state,
  };
};

const setSelectedLocation = (state) => {
  return {
    type: SELECTED_LOCATION,
    payload: state,
  };
};

const setUserNeighborhoods = (state) => {
  return {
    type: UPDATE_USER_NEIGHBORHOOD,
    payload: state,
  };
};

export {
  updateExtTopic,
  updateSessionStart,
  updateCrmModules,
  updateUserData,
  updateClientTypes,
  updateSDGoals,
  showLoader,
  updateSelectedModule,
  updateSelectedMenuItem,
  updateSelectedGroup,
  editComponent,
  updateProductID,
  updateSelectedModuleMenu,
  updateSelectedFilters,
  updateAccountList,
  openNeighborhoodForm,
  updatePublicUserData,
  updateCampaignFormOpen,
  updatenewMessage,
  updateIsNeighborhoodFormOpen,
  updateIsCampSelFormOpen,
  updateCampaignSelectedTab,
  updateCampaignFormSelectedId,
  updateIsChatFormOpen,
  updateChatFriend,
  updateSeeAll,
  updateRefreshCampaignAPI,
  updateRefreshEventsAPI,
  updateOpenCityPurchase,
  updateLessons,
  updatePlanUpgraded,
  updateIsAdminAccessRequestFormOpen,
  updateIsAcceptedAdminRequestFormOpen,
  openOrClosePlusIcon,
  updateFooterLink,
  addToCart,
  updateCart,
  removeFromCart,
  updateGuestData,
  updateUserDistracted,
  updateExtUserId,
  updateCategoryListingInfo,
  updateIsSessisonModalOpen,
  updateNewCorporateJoined,
  updateExtensionModalReport,
  updateIsCourseSidebar,
  userQualities,
  updateIsCourseDropdown,
  updateIsPageCourse,
  updateIsCitizenSidebarOpen,
  headerSearchBar,
  updateUserProfileId,
  setSelectedLocation,
  updateSelectedSidebarItem,
  setUserNeighborhoods,
};
