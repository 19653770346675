import React from 'react';
import DetailListHeader from 'shared/components/templates/item_detail/detail_list_header';

export default function ItemDetailComm(props){
  return (
    <>
      <div className="h-100 scroll-y top-on-sm ModalOpen p-4 d-none d-lg-block col-xl-9 col-lg-8 col-12" >
        <div className="p-sm-3 h-100" >
          <DetailListHeader
            showFilterComponent={props.showFilterComponent}
            filterList={props.filterList}
            headerTitle={props.headerTitle}
          />
          <div className="mt-4 registants-table fill-available" >
            {props.children}
          </div>
        </div>
      </div>
    </>
  );
}
