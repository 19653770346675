import React, { useState, useEffect } from "react";
import { toast } from "react-toastify";
import { useSelector } from "react-redux";
import * as Utility from "utils/utility";
import { CLIENT_ID } from "config/constants/config";
import { CAMPAIGN_TYPES_IDS } from "config/constants";
import { CustomSlider } from "../../Slider";
import {
  showLoader,
  updateSeeAll,
  updateIsChatFormOpen,
  updateChatFriend,
} from "shared/actions";
import { connect } from "react-redux";
import CourseContainerCard from "../CourseContainer";
import Create_course from "../../cards/create_course";
import CampaignSelectionForm from "../../../../scenes/users/components/create_campaign/campaign_selection_form";
import { CAMPAIGN_STORIES } from "config/constants/api_endpoints";
import { REGIONS_LIST } from "../../../../config/constants/api_endpoints";
import { GrSearch } from "react-icons/gr";
import { GET_JOINED_NEIGHBORHOOD } from "../../../../config/constants/api_endpoints";
import { Card, CardContent, Skeleton, Grid } from "@mui/material";

function Courses(props) {
  const userData = useSelector((state) => state.userData);
  const refreshCampaignApi = useSelector((state) => state.refreshCampaignApi);
  const [campaignsTopStories, setCampaignsTopStories] = useState([]);
  // const [filteredTopStories, setFilteredTopStories] = useState([]);
  const [campaignsFeaturedStories, setCampaignsFeaturedStories] = useState([]);
  // const [filteredFeaturedStories, setFilteredFeaturedStories] = useState([]);
  const [search, setSearch] = useState("");
  const [categoriesList, setCategoriesList] = useState([]);
  const [selectedItem, setSelectedItem] = useState("");
  const isCampaignFormOpen = useSelector((state) => state.isCampaignFormOpen);
  const [flag, setFlag] = useState(true);
  const [joinedNeighborhood, setJoinedNeighborhood] = useState([]);
  const [isCourseDataLoading, setIsCourseDataLoading] = useState(false);
  const [isTopCourseDataLoading, setIsTopCourseDataLoading] = useState(false);
  let debouncedFn = null;

  useEffect(() => {
    props.userData.user_id && getJoinednaighbouHoods();
  }, [props.userData.user_id]);

  // API for getting featured/All courses, news, events

  const loadAllStories = async ({
    user_client_id,
    campTypeId,
    isFeatured,
    userId,
    start_at,
    end_at,
    get_public_private,
  }) => {
    setIsCourseDataLoading(true);
    const params = {
      publisher_network_id: CLIENT_ID,
      type: campTypeId,
      is_featured: isFeatured,
      user_id: userId,
      user_client_id: user_client_id,
      start_at: start_at,
      end_at: end_at,
      get_public_private: get_public_private,
      search: search,
    };

    await Utility.sendRequest(CAMPAIGN_STORIES, 1, params, (err, res, body) => {
      body = JSON.parse(body);
      // setIsCourseDataLoading(true);
      if (body.error) {
        toast.error(body.error.message, { containerId: "private" });
      } else {
        const Data = body.data.filter(
          (items) =>
            items.corporate_is_active === true ||
            items.corporate_is_active === null
        );
        if (isFeatured) {
          setCampaignsFeaturedStories(Data);
          // setFilteredFeaturedStories(Data);
          setIsTopCourseDataLoading(false);
        } else {
          setCampaignsTopStories(Data);
          // setFilteredTopStories(Data);
          setIsCourseDataLoading(false);
        }
      }
    });
  };
  const getJoinednaighbouHoods = () => {
    Utility.sendRequest(
      `${GET_JOINED_NEIGHBORHOOD}?user_id=${props.userData.user_id}&platform_id=${CLIENT_ID}`,
      1,
      {},
      (err, res, body) => {
        body = JSON.parse(body);

        if (body.error) {
          toast.error(body.error.message, { containerId: "private" });
        } else {
          setJoinedNeighborhood(
            body.map((item) => {
              return item.client_id;
            })
          );
        }
      }
    );
  };
  const getRegions = () => {
    const params = {};
    Utility.sendRequest(REGIONS_LIST, 1, params, (err, res, body) => {
      body = JSON.parse(body);
      if (body.error) {
        toast.error(body.error.message, { containerId: "private" });
      } else {
        setCategoriesList(body.data);
      }
    });
  };

  useEffect(() => {
    fetchData();
  }, [search, refreshCampaignApi]);

  useEffect(() => {
    getRegions();
  }, []);

  const fetchData = () => {
    if (!Utility.isEmpty(props.category_id)) getCourses();
    else getAllCourses();
  };

  const getAllCourses = () => {
    const campTypeId = CAMPAIGN_TYPES_IDS.courses;
    loadAllStories({
      campTypeId: campTypeId,
      userId: userData.user_id,
      get_public_private: true,
    });
    loadAllStories({
      user_client_id: userData.id,
      campTypeId: campTypeId,
      isFeatured: true,
      userId: userData.id,
    });
  };

  const handleSearch = (ev) => {
    ev.persist();

    if (!debouncedFn) {
      debouncedFn = Utility.debounce(() => {
        const searchString = ev.target.value;
        setSearch(searchString);
      }, 500);
    }
    debouncedFn();
  };

  const getCourses = () => {
    const campTypeId = CAMPAIGN_TYPES_IDS.courses;
    loadAllStories({ campTypeId: campTypeId });
    loadAllStories({
      user_client_id: userData.id,
      campTypeId: campTypeId,
      isFeatured: true,
      userId: userData.user_id,
    });
  };

  // console.log(campaignsFeaturedStories,"campaignsFeaturedStories")

  return (
    <>
      <div className="pwa-callout-body pl-1 pt-4 courses-page-container pb-2">
        {selectedItem === "course" && (
          <CampaignSelectionForm
            position="position-fixed create-campaign-margin"
            userData={userData}
            setSelectedItem={setSelectedItem}
            selectedItem={selectedItem}
            joinedNeighborhood={joinedNeighborhood}
          />
        )}
        <>
          <div className="d-flex justify-content-between align-items-center">
            <div className="courses-input pl-4">
              <div
                className={`pt-5 d-flex align-items-center mb-3 mb-lg-4  pl-4 pl-md-4  pr-0`}
              >
                <img
                  src={require("assets/images/icons/courses.png")}
                  width={22}
                />
                <span className="ml-2 font-24-bold">Courses</span>
              </div>
              <div className="SeactionSearchContainer w-100 position-relative pl-4">
                <div className="searchSectionWrapper bg-white mb-5">
                  <input
                    className="SeactionSearch"
                    placeholder={`Search Courses`}
                    onChange={(ev) => handleSearch(ev)}
                  />

                  <span className="SeactionSearchIcon">
                    <GrSearch className="regionIcon" />
                  </span>
                </div>
              </div>
            </div>
            {props.accounts &&
              props.accounts.Clients &&
              props.accounts.Clients.length > 0 && (
                <Create_course
                  onClick={() => props.history.push("/create-course")}
                  className="mt-5 w-100"
                  setSelectedItem={setSelectedItem}
                  heading={"COURSE"}
                  item="course"
                />
              )}
          </div>
          <>
            {campaignsFeaturedStories.length === 0 &&
              campaignsTopStories.length === 0 &&
              !isTopCourseDataLoading &&
              !isCourseDataLoading && (
                <div className="d-flex align-items-center justify-content-center margin my-55">
                  <div className="px-5 py-5">
                    <span className="font-bold display-6">
                      No Records Found
                    </span>
                  </div>
                </div>
              )}
            <div className="mt-4">
              {isTopCourseDataLoading ? (
                <CustomSlider>
                  <div className="d-flex">
                    {[1, 2, 3, 4, 5].map((item) => (
                      <Skeleton
                        variant="rounded"
                        width={260}
                        height={270}
                        key={item}
                        className="mr-3 rounded-20 "
                      />
                    ))}
                  </div>
                </CustomSlider>
              ) : (
                <CourseContainerCard
                  courseData={campaignsFeaturedStories}
                  PanalTitle={"Featured Courses"}
                  category="featured"
                  type={"courses"}
                  setFlag={setFlag}
                  categoriesList={categoriesList}
                  isArticles={false}
                  // search={search.toLowerCase()}
                  joinedNeighborhood={joinedNeighborhood}
                />
              )}
              {isCourseDataLoading ? (
                <CustomSlider>
                  <div className="d-flex">
                    {[1, 2, 3, 4, 5].map((item) => (
                      <Skeleton
                        variant="rounded"
                        width={260}
                        height={270}
                        key={item}
                        className="mr-3 rounded-20 "
                      />
                    ))}
                  </div>
                </CustomSlider>
              ) : (
                <CourseContainerCard
                  courseData={campaignsTopStories}
                  PanalTitle={"All Courses"}
                  type={"courses"}
                  setFlag={setFlag}
                  categoriesList={categoriesList}
                  isArticles={false}
                  // search={search.toLowerCase()}
                  joinedNeighborhood={joinedNeighborhood}
                />
              )}
            </div>
            {isCourseDataLoading ? (
              <CustomSlider>
                <div className="d-flex">
                  {[1, 2, 3, 4, 5].map((item) => (
                    <Skeleton
                      variant="rounded"
                      width={260}
                      height={270}
                      key={item}
                      className="mr-3 rounded-20 "
                    />
                  ))}
                </div>
              </CustomSlider>
            ) : (
              categoriesList.map((categoryDetails) => {
                return (
                  <div key={categoryDetails.id} className="mt-4">
                    <CourseContainerCard
                      courseData={
                        categoryDetails.name === "Global"
                          ? campaignsTopStories.filter(
                              (data) =>
                                data.region_details &&
                                Object.keys(data.region_details).length > 0
                            )
                          : campaignsTopStories.filter(
                              (data) =>
                                data.region_details &&
                                Object.keys(data.region_details).length > 0 &&
                                data.region_details.REGION_NAME ===
                                  categoryDetails.name
                            )
                      }
                      PanalTitle={
                        categoryDetails.name === "Global"
                          ? "Global Courses"
                          : categoryDetails.name
                      }
                      colorCode={categoryDetails.color_code}
                      setFlag={setFlag}
                      type={"courses"}
                      categoriesList={categoriesList}
                      isArticles={false}
                      search={search.toLowerCase()}
                      joinedNeighborhood={joinedNeighborhood}

                      // width={CLIENT_ID===GIL_CLIENT_ID && props.type==="suggestedReads" && "93vw"}
                    />
                  </div>
                );
              })
            )}
          </>
        </>
      </div>
      {/* <WeaFooter /> */}
    </>
  );
}

const mapActionToProps = {
  showLoader,
  updateSeeAll,
  updateIsChatFormOpen,
  updateChatFriend,
};

const mapStateToProps = (state) => ({
  userData: state.userData,
  seeAll: state.seeAll,
});

export default connect(mapStateToProps, mapActionToProps)(Courses);
