import My_community from "../my_community";
import * as Utility from "utils/utility";
import { Grid } from "@material-ui/core";
import * as React from "react";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import Modal from "@mui/material/Modal";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 1150,
  height: 700,
  bgcolor: "background.paper",
  border: "none",
  outline: "none",
  boxShadow: 24,
  p: 4,
};
const array = [
  {
    city: "abe",
    client_name: "GIL Neighbour",
    country: "India",
    id: "ac8bd4e0-a36d-11eb-b2d1-07e90fe25379",
    is_admin: true,
    is_followed: true,
    is_user_account_active: true,
    network: "clients",
    platform_id: "0bc8dc7c-b5a3-4344-b313-819fc640f6b5",
    private_key:
      "YWM4YmZiZjAtYTM2ZC0xMWViLWIyZDEtMDdlOTBmZTI1Mzc5I2lkI2FjOGJkNGUwLWEzNmQtMTFlYi1iMmQxLTA3ZTkwZmUyNTM3OQ==",
    role_id: "9bed9123-06a7-4b1f-bf1f-4ed9e779fd79",
    state: "Daman and Diu",
    user_count: "0",
    user_id: "cc932350-74bc-11ea-950b-71c6872dc7d9",
    user_name: "GIL Neighbour",
  },
];

export default function WelcomeRegion(props) {
  return (
    <div>
      <Modal
        open={props.open}
        onClose={() => {
          // if (reason !== "backdropClick") {
          props.handleClose();
          // }
        }}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <Typography id="modal-modal-title" variant="h6" component="h2">
            <div className="welcome-region-card pl-0">
              <div className="m-auto">
                <img
                  className="welcome-region-image "
                  src={require("assets/images/profile/region-card.png")}
                />
              </div>
              <h3 className="text-center m-2">Welcome to {props.location}</h3>
              <p className="welcome-region-members font-weight-bold">
                25.1k members
              </p>
              <button className="welcome-region-join ">
                <h5
                  className="text-white text-center pt-3"
                  onClick={() => props.handleClose()}
                >
                  Join the Conversation
                </h5>
              </button>
              <h5 className="welcome-region-heading5 font-weight-bold text-center">
                Connect with Top Curators
              </h5>
              <div className="welcome-region-profile-card"></div>
              <Grid container spacing={{ xs: 1, md: 5 }}>
                {Array.from(Array(5)).map((_, index) => (
                  <Grid>
                    {array.map((items) => (
                      <My_community
                        className="profile-card"
                        key={items.id}
                        item={items}
                        //handleOnClick={setDetailsFollowerfollowing}
                        showCharFollowButtons={true}
                        fromSeeAll={Utility.pwaModeEnabled()}
                        closeCallout={items.closeCallout}
                      />
                    ))}
                  </Grid>
                ))}
              </Grid>
            </div>
          </Typography>
        </Box>
      </Modal>
    </div>
  );
}
