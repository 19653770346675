import React from "react";

const ContentContainer = (props) => {
  return (
    <div className="mt-30 bg-white eLeftPanelText">
      <p className="display-5 b-700">About Event</p>
      <p
        className="b-400 eventContentContainer"
        dangerouslySetInnerHTML={{ __html: props.eventDetails.description }}
      ></p>
    </div>
  );
};

export default ContentContainer;
