import React, { useState, useEffect } from "react";
import { ClockIcon, LocationIcon, UserGroupIcon } from "shared/icons/icons";
import {
  EVENT_MEMBERS,
  CALENDAR_DETAILS,
} from "config/constants/api_endpoints";
import { STATUS } from "config/constants";
import { toast } from "react-toastify";
import { Date as newDate, Utility } from "utils";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { showLoader } from "shared/actions";
import { connect } from "react-redux";

const EventCard = (props) => {
  const userData = useSelector((state) => state.userData);
  const startDate = props.start_at ? new Date(props.start_at) : new Date();
  const [membersCount, setMembersCount] = useState(0);

  useEffect(() => {
    getAttendingMembersCount();
  }, [membersCount]);

  const getMonthName = () => {
    return startDate
      .toLocaleString("default", { month: "short" })
      .toLocaleUpperCase();
  };

  const getTime = () => {
    return startDate.toLocaleString("en-US", {
      hour: "numeric",
      minute: "numeric",
      hour12: true,
    });
  };

  const acceptInvitations = () => {
    const params = {
      memberId: userData.id,
      status: STATUS.approved,
      isActive: true,
      campaignId: props.id,
      userClientIds: [userData.id],
    };

    Utility.sendRequest(EVENT_MEMBERS, 2, params, (err, res, body) => {
      body = JSON.parse(body);

      if (body.error) {
        toast.error(body.error.message, { containerId: "private" });
      } else {
        // props.pageLoad(Math.random());
        toast.success("Event successfully added", { containerId: "private" });
      }
    });
  };

  const getAttendingMembersCount = () => {
    const params = {
      is_active: true,
      campaign_id: props.id,
    };

    Utility.sendRequest(
      `${CALENDAR_DETAILS}/count`,
      1,
      params,
      (err, res, body) => {
        body = JSON.parse(body);

        if (body.error) {
          toast.error(body.error.message, { containerId: "private" });
        } else {
          setMembersCount(body.count);
        }
      }
    );
  };

  const paintImage = () => {
    if (props.eventDetailLink) {
      return (
        <Link
          to={{
            pathname: `/campaign/${props.id}`,
            goBackLink: props.goBackLink,
          }}
        >
          <img
            src={props.image_url}
            className="additonal-card-main-img"
            alt=""
          />
        </Link>
      );
    } else {
      return (
        <img src={props.image_url} className="additonal-card-main-img" alt="" />
      );
    }
  };

  return (
    <div className="additional-card overflow-hidden mb-4 bg-white">
      <div className="additional-card-head position-relative">
        {paintImage()}
        <div className="additional-card-date text-center py-2 px-3 position-absolute lh-1">
          <div className="font-14 font-bold">{getMonthName()}</div>
          <div className="font-32-bold">{startDate.getDate()}</div>
        </div>
      </div>
      <div className="px-3 pb-2 font-14">
        <div
          style={{ color: props.category_color }}
          className="font-bold event-tag"
        >
          {props.campaign_category ? props.campaign_category : "Event Category"}
        </div>
        <h1 className="display-7 font-bold text-dark mb-2">
          {props.heading
            ? props.heading.length > 34
              ? props.heading.slice(0, 34) + "..."
              : props.heading
            : "Event Name"}
        </h1>
        <div className="d-flex flex-wrap text-muted font-semi-bold mb-2 ml-2">
          <div className="align-items-center lh-1 mb-1 w-100">
            <ClockIcon mainClass="mr-2" />
            <span className="pl-1">
              {startDate.toLocaleString("default", { month: "short" }) +
                " " +
                startDate.getDate() +
                " " +
                startDate.getFullYear() +
                " " +
                getTime()}
            </span>
          </div>
          {props.location && (
            <div className="align-items-center lh-1 mb-1 w-100">
              <LocationIcon mainClass="mr-2" />
              <span className="pl-1">{props.location}</span>
            </div>
          )}
          {/* <div className="align-items-center lh-1 mb-3 w-100">
            <UserGroupIcon mainClass="mr-2" />
            <span className="pl-1">
              {membersCount}{" "}
              {membersCount > 1 ? "Members Attending" : "Member Attending"}
            </span>
          </div> */}
        </div>
        <div className="d-flex justify-content-end mb-2">
          {/* <div className="align-items-center"> */}
          {/* <img
              src={process.env.PUBLIC_URL + "/icons/icon-72x72.png"}
              className="event-footer-img mr-2 rounded-circle bg-white"
              alt=""
            /> */}
          {/* <span className="font-14 font-italic text-muted pl-2 updated-time text-truncate">
              {Utility.pwaModeEnabled() ? "Public - " : "Updated "}
              {newDate.getLastUpdatedAtDuration(props.updated_at)}
            </span> */}
          {/* </div> */}
          {props.displayAddBtn && startDate >= new Date() && (
            <button
              className="btn btn-primary btn-sm btn-primary-pwa add-btn"
              onClick={() => acceptInvitations()}
            >
              Attend
            </button>
          )}
        </div>
      </div>
    </div>
  );
};

const mapActionToProps = { showLoader };

export default connect(null, mapActionToProps)(EventCard);
