import React, { useState } from 'react';
import { Col } from 'react-bootstrap';
import SideCallout from 'shared/components/templates/side_callout';
import CreditCardUpdate from 'shared/components/credit_card_update.js';
import { Utility } from 'utils';

export default function CreditCard(props){
  const [isEdit, setIsEdit] = useState(false);
  return (
    <>
      <div className="card-payment">
        <Col lg={4} md={12}>
          <div className="font-18 b-700 my-4">Card on File</div>
          <div className="card-payment-info p-3 d-flex align-items-start">
            <img
              src={require('assets/images/profile/new-profile/payment-card.svg')}
              alt="drodown-icon"
              className="mr-3"
            />
            <div>
              <p className="font-20 text-capitalize">{props.userData.first_name + " " + props.userData.last_name}</p>
              <p className="font-20">VISA ending in-{props.userData && props.userData.cards && props.userData.cards.cc_last_4_digits}</p>
              <p className="font-20">XX/XXXX</p>
              <p className="font-20 mb-0">{props.userData && props.userData.cards && props.userData.cards.cc_type}</p>
            </div>
          </div>
          <div className="card-payment-edit cursor-pointer" onClick={() => setIsEdit(true)}>
            <p className="m-0 text-primary">{Utility.isEmpty(props.userData.cards) ? 'Add' : 'Edit'}</p>
          </div>
        </Col>
      </div>

      {
        isEdit &&
        <CreditCardEdit
          handleClose={() => setIsEdit(false)}
          userData={props.userData}
          marginTop={props.marginTop}
        />
      }

    </>
  );
}


export const CreditCardEdit = (props) => {
  return (
    <SideCallout closeCallOut={props.handleClose}
      position={`position-fixed billing-payment-form ${props.marginTop}`}
      calloutHeading='Update Card on File'>
      <CreditCardUpdate
        handleClose={props.handleClose}
        userData={props.userData}
      />
    </SideCallout>
  );
};
