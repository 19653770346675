import React from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { STATUS } from "config/constants";
import {
  updateIsChatFormOpen,
  updateChatFriend,
  updateSeeAll,
  updateIsNeighborhoodFormOpen,
  openNeighborhoodForm,
  showLoader,
  updateUserData,
} from "shared/actions";
// import { FollowerInfo } from "shared/components/v2/campaign/components/follower_info.js";
import Avatar from "react-avatar";
import { updateUserProfileId } from "shared/actions";
import { Utility } from "utils";
import { toast } from "react-toastify";
import cookie from "react-cookies";
import {
  SWITCH_ACCOUNT,
  USER_AUTH,
} from "../../config/constants/api_endpoints";

class MyCommunity extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      showChatCallout: false,
    };
  }

  userProfile = (id) => {
    if (this.props.isCourse) {
      this.props.updateSeeAll({
        isOpen: null,
        calledFrom: null,
      });
      this.props.updateUserProfileId(id);
      this.props.openProfilePopUp();
    } else {
      this.props.updateSeeAll({
        isOpen: null,
      });
      this.getProfileOrSwitchProfile(id);
      // if (id === this.props.userData.id)
      //   return this.props.history.push("/profile");

      // this.props.history.push({
      //   pathname: `/profile/${id}`,
      //   state: { otherUserProfile: true },
      // });
    }
  };

  getProfileOrSwitchProfile = (id) => {
    if (id === this.props.userData.id)
      return this.props.history.push("/profile");

    const account_list = [
      ...this.props.accountList.Users,
      ...this.props.accountList.Clients,
      ...this.props.accountList.Parents,
      ...this.props.accountList.Admins,
    ];
    const profile = account_list.find((item) => item.id === id);

    if (profile) this.switchAccount(id, profile);
    else
      this.props.history.push({
        pathname: `/profile/${id}`,
        state: { otherUserProfile: true },
      });
  };

  switchAccount = (id, data) => {
    this.props.updateIsNeighborhoodFormOpen(false);
    this.props.openNeighborhoodForm({});
    this.props.showLoader(true);

    Utility.sendRequest(SWITCH_ACCOUNT, 1, { id }, (error, response, body) => {
      body = JSON.parse(body);

      if (body.error) {
        toast.error(body.error.message, { containerId: "public" });
        this.props.showLoader(false);
      } else {
        cookie.save("sessionToken", body.token, { path: "/" });

        Utility.sendRequest(USER_AUTH, 1, { id }, (error, response, body) => {
          body = JSON.parse(body);

          this.props.showLoader(false);

          if (!body.error) {
            this.props.updateUserData(body);

            if (window.location.pathname !== "/profile") {
              this.props.history.push({
                pathname: "/profile",
                state: { data },
              });
            }
          } else {
            toast.error(body.error.message, { containerId: "public" });
          }
        });
      }
    });
    // }
  };

  checkIcon = (props) => {
    if (props.type === "follower") {
      return (
        !this.props.item.is_followed && (
          <img
            onClick={(e) => this.handleFollowClick(e, props)}
            src={require("assets/images/icons/person_plus.svg")}
            className="mr-4 cursor-pointer"
            alt=""
          />
        )
      );
    } else if (props.type === "following") return "";
  };

  handleFollowClick = (e, props) => {
    e.stopPropagation();
    this.props.handleOnClick(props.item.id);
  };

  handleChatClick = (e) => {
    this.props.updateSeeAll({
      ...this.props.seeAll,
      isOpen: null,
    });
    e.stopPropagation();
    this.props.updateIsChatFormOpen(true);
    this.props.updateChatFriend(this.props.item);
  };

  interestAndChatFollow = () => (
    <>
      {this.props.showCharFollowButtons &&
        this.props.item.id !== this.props.userData.id && (
          <div className="text-right">
            {this.props.userData.network !== "clients" && (
              <img
                onClick={(e) => this.handleChatClick(e)}
                src={require("assets/images/icons/chat_icon_dark.svg")}
                className=" pb-1 community-chat-icon cursor-pointer"
                alt=""
              />
            )}
          </div>
        )}
    </>
  );

  interestAndChatFollowDisabled = () => (
    <>
      <div className="group-invitation-pending">Invitation Pending</div>
    </>
  );

  hideOrShowInterest = () => {
    if (this.props.status) {
      if (this.props.status === STATUS.pending)
        return this.interestAndChatFollowDisabled();

      if (this.props.status === STATUS.approved)
        return this.interestAndChatFollow();
    } else return this.interestAndChatFollow();
  };

  paintUserName = (userName) => {
    if (parseInt(userName.length) < 19) {
      return userName;
    } else {
      return userName.substring(0, 16) + " ...";
    }
  };

  render() {
    return (
      <div
        onClick={(event) => {
          event.preventDefault();
          Object.keys(this.props.item).includes("community_name") &&
            this.userProfile(this.props.item.user_client_id);
        }}
        className="new-community-card text-center"
      >
        <div className={`bg ${this.props.profilePage ? "h-100" : "h-100"}`}>
          <div
            className={`d-flex flex-column align-items-center h-100 justify-content-center  ${
              this.props.isFeatured && "pt-2"
            }`}
          >
            <div className="mx-auto  mt-2">
              {!this.props.isFeatured &&
                (this.props.item && this.props.item.profile_image_url ? (
                  <img
                    src={this.props.item && this.props.item.profile_image_url}
                    className="community-card-pic"
                    alt=""
                  />
                ) : (
                  <Avatar
                    name={
                      this.props.item &&
                      (this.props.item.user_name ||
                        this.props.item.community_name)
                    }
                    round={true}
                    size={"61"}
                    src={
                      this.props.item.logo_url
                        ? this.props.item.logo_url
                        : this.props.item.banner_image_url
                        ? this.props.item.banner_image_url
                        : this.props.item.profile_image_url
                    }
                    textSizeRatio={1.75}
                    color="#007bb3"
                    maxInitials={1}
                    className={"community-avatar"}
                  />
                ))}
              {this.props.isFeatured &&
                (this.props.item && this.props.item.logo_url ? (
                  <img
                    src={this.props.item && this.props.item.logo_url}
                    className="community-card-pic"
                    alt=""
                  />
                ) : (
                  <Avatar
                    name={this.props.item && this.props.item.name}
                    round={true}
                    size={"61"}
                    textSizeRatio={1.75}
                    color="#007bb3"
                    maxInitials={1}
                    className={"community-avatar"}
                  />
                ))}
            </div>
            <div className="display-8 font-bold mb-1 mt-1 user-name text-white">
              {!this.props.isFeatured &&
                this.props.item &&
                this.props.item.user_name &&
                this.paintUserName(this.props.item.user_name.trim())}
              {this.props.item &&
                this.props.item.community_name &&
                this.paintUserName(this.props.item.community_name.trim())}
            </div>
            <div className="font-12 text-white">
              {this.props.item.city ? this.props.item.city : ""}
              {this.props.item.city && this.props.item.country ? ", " : ""}
              {this.props.item.country ? this.props.item.country : ""}
            </div>
          </div>
          <div className="overlay">
            <div className="d-flex flex-row justify-content-center align-items-baseline pl-3 pt-0">
              <span
                className="pt-5 pr-3 cursor-pointer"
                // style={{ paddingTop: "45px" }}
                onClick={() =>
                  !this.props.isFeatured &&
                  !Object.keys(this.props.item).includes("community_name") &&
                  this.props.item.id
                    ? this.userProfile(
                        this.props.isCourse
                          ? this.props.item.user_client_id
                          : this.props.item.id
                      )
                    : this.userProfile(this.props.item.user_client_id)
                }
              >
                {this.props.item &&
                (this.props.item.profile_image_url ||
                  this.props.item.logo_url) ? (
                  <img
                    src={
                      this.props.item.profile_image_url ||
                      this.props.item.logo_url
                    }
                    className="small-community-card-pic"
                    alt=""
                  />
                ) : (
                  <Avatar
                    name={
                      this.props.item &&
                      (this.props.item.user_name ||
                        this.props.item.community_name)
                    }
                    round={true}
                    size={"29"}
                    textSizeRatio={2}
                    color="#007bb3"
                    maxInitials={1}
                    className={"small-community-avatar"}
                  />
                )}
                {/* <span className="text-black pt-1" style={{ fontSize: "10px" }}>
                  Profile
                </span> */}
              </span>
              {/* <span className="pb-0 d-flex flex-column">
                    {this.hideOrShowInterest()}{" "}
                  </span> */}
            </div>
            <span
              className={`d-flex jus flex-row justify-content-center`}
              // this.props.userData.network === "clients"
              //   ? "card-padding"
              //   : ""
            >
              <span
                className="text-black "
                style={{
                  fontSize: "10px",
                  // paddingLeft:
                  //   this.props.item && this.props.item.is_followed
                  //     ? "35px"
                  //     : "",
                }}
              >
                Profile
              </span>
              {/* {this.props.userData.network !== "clients" && (
                    <span
                      className="text-black "
                      style={{
                        fontSize: "10px",
                        paddingLeft: this.props.item.is_followed
                          ? "23px"
                          : this.props.item && this.props.item.profile_image_url
                          ? "23px"
                          : "19px",
                      }}
                    >
                      Chat
                    </span>
                  )} */}
            </span>
          </div>
        </div>
      </div>
    );
    // : (
    //   <FollowerInfo
    //     id={this.props.item.id}
    //     selectedUser={this.props.item}
    //     name={this.props.item.user_name}
    //     imageUrl={this.props.item.profile_image_url}
    //     isFollowed={this.props.item.is_followed}
    //     closeCallout={this.props.closeCallout}
    //     fromSeeAll={this.props.fromSeeAll}
    //     setFollowerfollowing={() =>
    //       this.props.handleOnClick(this.props.item.id)
    //     }
    //   />
    // );
  }
}

const mapActionToProps = {
  updateIsChatFormOpen,
  updateChatFriend,
  updateSeeAll,
  updateUserProfileId,
  updateIsNeighborhoodFormOpen,
  openNeighborhoodForm,
  showLoader,
  updateUserData,
};

const mapStateToProps = (state) => ({
  userData: state.userData,
  seeAll: state.seeAll,
  userProfileId: state.userProfileId,
  accountList: state.accountList,
});

export default withRouter(
  connect(mapStateToProps, mapActionToProps)(MyCommunity)
);
