import React from 'react';
import moment from 'moment';
import Avatar from 'react-avatar';
import { Utility } from 'utils';

export const SingleCampaign = (props) => {

  const paintCreatedAt = (createdAt) => {
    const date = moment(createdAt).format('ll');
    return `${date}`;
  };

  const paintDiv = () => {
    return (
      props.id ?
        <div className="border-top existing-article-outer d-flex py-3 cursor-pointer align-items-center"
          onClick={() => props.handleClick(props.id)}>
          {paintInnerDiv(props.id)}
        </div>
        :
        <div className="border-top existing-article-outer d-flex py-3 cursor-pointer align-items-center"
          onClick={props.handleClick}>
          {paintInnerDiv()}
        </div>
    );
  };

  const paintInnerDiv = (id) => {
    return (
      <>
        <div className="existing-article-img-outer">
          {
            ((props.imageUrl) || !props.id) &&
              !Utility.isEmpty(props.imageUrl) ?
              <img src={props.imageUrl} className="existing-article-img" />
              :
              <Avatar
                name={props.heading}
                round={true}
                size={id ? '67' : '50'}
                textSizeRatio={1.75}
                color='#007bb3'
                maxInitials={1}
              />
          }
        </div>
        <div className="existing-article-body ml-3" >
          <div className="existing-article-heading font-bold font-15 text-break" >
            {props.heading || ''}
          </div>
          {
            props.id &&
            <div className="font-12 text-muted" >
              {paintCreatedAt(props.createdAt)}
            </div>
          }
        </div>
      </>
    );
  };

  return (
    paintDiv()
  );
};
