import React from "react";
import { useState } from "react";
import SideCallout from "shared/components/templates/side_callout";
import NewsCard from "../../../scenes/profile/components/NewsCard";
import Avatar from "react-avatar";
import { toast } from "react-toastify";
import { CgClose } from "react-icons/cg";
import { CLIENT_ID, NAMES_MAPPING } from "../../../config/constants/config";
import {
  GET_USERS_BY_NETWORKS,
  SEND_FF_SHARE_NOTI,
} from "../../../config/constants/api_endpoints";
import { connect, useDispatch, useSelector } from "react-redux";
import { showLoader } from "../../actions";
import * as Utility from "utils/utility";
import { useEffect } from "react";
import {
  updateIsChatFormOpen,
  updateChatFriend,
  updateSeeAll,
} from "shared/actions";
import { useLocation, withRouter } from "react-router-dom";

const News_Confirmation = (props) => {
  const [showInvites, setShowInvites] = useState(false);
  const [search, setSearch] = useState("");
  const [invites, setInvites] = useState([]);
  const [usersList, setUsersList] = useState([]);
  const [neighbourhoods, setNeighbourhoods] = useState([]);
  const [loading, setLoading] = useState(false);
  const [filteredUsersList, setFilteredUsersList] = useState();
  const [filteredSearchList, setFilteredSearchList] = useState();
  const userData = useSelector((state) => state.userData);
  const [isNetworkUsersLoading, setIsNetworkUsersLoading] = useState(false);

  const { pathname } = useLocation();
  const dispatch = useDispatch();

  function compare(a, b) {
    if (a.user_name < b.user_name) {
      return -1;
    }
    if (a.user_name > b.user_name) {
      return 1;
    }
    return 0;
  }

  // useEffect(() => {
  //   getUserDetails();
  // }, []);

  const setSearchData = () => {
    if (search.length > 0) {
      let searchFilteredData = usersList.filter((data) =>
        data.user_name.toLowerCase().includes(search.toLowerCase())
      );
      setFilteredSearchList(searchFilteredData);
      setFilteredUsersList(searchFilteredData.slice(0, 100));
    } else {
      setFilteredSearchList(usersList);
      setFilteredUsersList(usersList.slice(0, 100));
    }
  };

  useEffect(() => {
    setSearchData();
  }, [search]);

  // const fetchMoreData = () => {
  //   if (filteredUsersList && filteredUsersList.length < usersList.length) {
  //     const length = filteredUsersList.length;
  //     setLoading(true);
  //     setTimeout(() => {
  //       let data = filteredUsersList.concat(
  //         filteredSearchList.slice(length, length + 100)
  //       );
  //       setFilteredUsersList(data);
  //       setLoading(false);
  //     }, 500);
  //   }
  // };

  // const handleScroll = (e) => {
  //   const bottom =
  //     (e.target.scrollHeight - e.target.scrollTop).toFixed(0) ===
  //     e.target.clientHeight.toFixed(0);
  //   if (bottom) {
  //     fetchMoreData();
  //   } else if (e.target.scrollTop === 0) {
  //     setFilteredUsersList(filteredSearchList.slice(0, 100));
  //   }
  // };
  let debouncedFn = null;
  const handleSearch = (event) => {
    event.persist();
    if (search.length === 0) {
      setFilteredUsersList([]);
      setUsersList([]);
      setFilteredSearchList([]);
    }
    setSearch(event.target.value);
    if (!debouncedFn) {
      debouncedFn = Utility.debounce(() => {
        if (search.length > 2) getUserDetails();
      }, 500);
    }
    debouncedFn();
  };
  const getUserDetails = () => {
    const params = {
      client_id: CLIENT_ID,
      type: "all",
      forCommunity: true,
      limit: 20,
    };

    // dispatch(showLoader(true));
    if (search) params.search = search;
    setIsNetworkUsersLoading(true);
    Utility.sendRequest(GET_USERS_BY_NETWORKS, 1, params, (err, res, body) => {
      body = JSON.parse(body);

      // dispatch(showLoader(false));
      setIsNetworkUsersLoading(false);
      if (body.error) {
        toast.error(body.error.message, { containerId: "private" });
      } else {
        if (
          body.registrants &&
          CLIENT_ID === "0bc8dc7c-b5a3-4344-b313-819fc640f6b5"
        ) {
          const userList = [];
          const neighbourhood = [];
          body.registrants.sort(compare).forEach((element) => {
            if (element.network === "users") userList.push(element);
            else neighbourhood.push(element);
          });
          setUsersList(userList);
          setFilteredSearchList(body.registrants);
          setFilteredUsersList(body.registrants.slice(0, 100));
          setNeighbourhoods(neighbourhood);
        } else {
          if (body.registrants) setUsersList(body.registrants);
        }
      }
    });
  };

  const handleArticleClick = (data) => {
    props.history.push({
      pathname: `/news-details/${data.id || data.parent_id}`,
      state: { from: pathname },
    });
  };

  const sendInvite = (newSelectedMembers) => {
    const params = {
      campaign_id: props.item.id,
      user_client_id: userData.id,
      shared_user_client_id: [newSelectedMembers.id],
      client_id: CLIENT_ID,
    };

    Utility.sendRequest(SEND_FF_SHARE_NOTI, 2, params, (err, res, body) => {
      body = JSON.parse(body);

      if (body.error) {
        toast.error(body.error.message, { containerId: "private" });
      } else
        toast.success(
          `${Utility.capitalize(
            NAMES_MAPPING.CAMPAIGN.story.parent
          )} shared successfully.`,
          { containerId: "private" }
        );
    });
  };

  const handleClick = (event, item) => {
    if (item) {
      if (!invites.find(({ id }) => id === item.id)) {
        sendInvite(item);
        setInvites([...invites, item]);
        // setShowInvites(false);
      } else if (invites.length === 0) {
        sendInvite(item);
        setInvites([...invites, item]);
        // setShowInvites(false);
      }
      if (invites.find(({ id }) => id === item.id)) {
        toast.warn("Article has already been shared with the participant");
      }
      event.preventDefault();
    }
  };

  const handleChatClick = (e, item) => {
    e.stopPropagation();
    dispatch(updateIsChatFormOpen(true));
    dispatch(updateChatFriend(item));
  };

  //console.log("Hello" , invites);
  return (
    <SideCallout
      position="position-absolute assignment-callout "
      customBodyClass="createArticle-container-class-success"
      temperoryClass="pl-0"
      closeCallOut={() => {
        dispatch(updateSeeAll({ isOpen: null, calledFrom: null }));
        props.history.push("/news");
      }}
      isProfilePage={false}
      isCommunity={true}
    >
      <div>
        <div className="d-flex justify-content-center w-100">
          <img
            className=""
            src={require("assets/images/icons/successfully_icon.png")}
          />
        </div>
        <div className="lead text-center pt-2">
          <p>You have successfully created an article.</p>
        </div>
        <div className="d-flex justify-content-center pt-5">
          <div className="form-control form-control-sm w-50 box-shadow-create h-auto p-0 m-left-5 mb-3 invites-bottom">
            <div className="d-flex align-items-center w-100">
              <input
                type="text"
                className="form-control form-control-sm outline-none shadow-none m-0 border-none invites-input "
                name="invites"
                id="invites"
                aria-describedby="helpId"
                placeholder="Write a name or group to share this Article"
                onFocus={(e) => {
                  setShowInvites(true);
                  e.preventDefault();
                }}
                onChange={(e) => {
                  handleSearch(e);
                  e.preventDefault();
                }}
                onKeyDown={(e) => {
                  if (
                    e.key === "Escape" ||
                    e.key === "Esc" ||
                    e.keyCode === 27
                  ) {
                    setShowInvites(false);
                    setSearch("");
                  }
                }}
                onClick={() => {
                  if (showInvites === false) {
                    setShowInvites(true);
                  }
                }}
              />
              {showInvites && (
                <div
                  onClick={(e) => {
                    e.preventDefault();
                    setShowInvites(false);
                  }}
                  className="cursor-pointer"
                >
                  <CgClose size={36} stroke={5} />
                </div>
              )}
            </div>
            <div className="invites-container scroll-y">
              {showInvites && filteredUsersList && filteredUsersList.length > 0
                ? filteredUsersList
                    .filter(
                      (data) => !invites.some((item) => item.id === data.id)
                    )
                    .map((item, idx) => {
                      return (
                        <div
                          key={idx}
                          className="d-flex align-items-center invite-container ml-4 w-75 pl-4 my-2 cursor-pointer"
                          onClick={(event) => handleClick(event, item)}
                        >
                          <div className="d-flex align-items-center">
                            {item.profile_image_url ? (
                              <img
                                src={item.profile_image_url || ""}
                                className="img-fluid rounded-circle invites-image mr-4 "
                                alt=""
                              />
                            ) : (
                              <Avatar
                                name={item && item.user_name}
                                round={true}
                                size={60}
                                textSizeRatio={1.75}
                                color="#007bb3"
                                maxInitials={1}
                                className={"mr-4"}
                              />
                            )}
                            <div>
                              <div className="font-20-bold user-name d-flex align-items-center">
                                {item &&
                                  item.user_name &&
                                  item.user_name.trim()}
                              </div>
                              <div className="font-12 d-flex align-items-center invites-subtext">
                                {item.city ? item.city : ""}
                                {item.city && item.country ? ", " : ""}
                                {item.country ? item.country : ""}
                              </div>
                            </div>
                            {loading && <p>Loading...</p>}
                          </div>
                        </div>
                      );
                    })
                : showInvites && <h4>No Records Found</h4>}
            </div>
          </div>
          {!showInvites &&
            invites.map((item, idx) => {
              return (
                <div
                  key={idx}
                  className="d-flex align-items-center  justify-content-between mb-3 invite-container py-2 w-50 px-4 "
                >
                  <div className="d-flex align-items-center">
                    {item.profile_image_url ? (
                      <img
                        src={item.profile_image_url || ""}
                        className="img-fluid rounded-circle invites-image mr-4 "
                        alt=""
                      />
                    ) : (
                      <Avatar
                        name={item && item.user_name}
                        round={true}
                        size={60}
                        textSizeRatio={1.75}
                        color="#007bb3"
                        maxInitials={1}
                        className={"mr-4"}
                      />
                    )}
                    <div>
                      <div className="font-20-bold user-name d-flex align-items-center">
                        {item && item.user_name && item.user_name.trim()}
                      </div>
                      <div className="font-12 d-flex align-items-center invites-subtext">
                        {item.city ? item.city : ""}
                        {item.city && item.country ? ", " : ""}
                        {item.country ? item.country : ""}
                      </div>
                    </div>
                  </div>
                  <div className="d-flex ">
                    <img
                      onClick={(e) => handleChatClick(e, item)}
                      src={require("assets/images/icons/chat_icon_dark.svg")}
                      className="mr-4 pb-1 invite-chat-icon cursor-pointer"
                      alt=""
                    />
                    {/* <div
                        onClick={(e) => {
                          handleRemoveValue(idx);
                          e.preventDefault();
                        }}
                        className="cursor-pointer"
                      >
                        <CgClose size={36} stroke={5} />
                      </div> */}
                  </div>
                </div>
              );
            })}
        </div>

        {
          <div className="d-flex justify-content-center mt-3">
            <NewsCard
              handleArticleClick={() => handleArticleClick(props.item)}
              data={props.item}
            />
          </div>
        }

        <div className="d-flex justify-content-center  p-5">
          <button
            onClick={() => handleArticleClick(props.item)}
            className="btn btn-primary go_to_article"
          >
            GO TO ARTICLE
          </button>
        </div>
      </div>
    </SideCallout>
  );
};

const mapActionToProps = {
  showLoader,
  updateSeeAll,
};

const mapStateToProps = (state) => ({
  userData: state.userData,
});

export default withRouter(
  connect(mapStateToProps, mapActionToProps)(News_Confirmation)
);
