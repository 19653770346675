import React, { useEffect } from "react";
import { useRef, useState } from "react";
import { EDITOR_JS_TOOLS } from "./EditorConstants";
import DragDrop from "editorjs-drag-drop";
import edjsParser from "editorjs-parser";
import { createReactEditorJS } from "react-editor-js";

function VideoParse(data, config) {
  const videoStyle = `margin-top: 20px; margin-bottom: 10px; padding: 0 20px;`;
  const captionStyle = `margin-bottom: 20px; padding: 0 20px;`;

  return `
  <video height="100%" width="100%" style="${videoStyle}" controls>
      <source src="${data.file.url}" type="video/mp4" />
      Your browser does not support the video tag.
  </video>
  <p style="${captionStyle}">${data.caption}</p>
  `;
}

function extractYouTubeVideoId(link) {
  const videoIdRegex =
    /(?:youtube\.com\/(?:[^\/]+\/[^\/]+\/|(?:v|e(?:mbed)?)\/|[^\/]+\?video_id=)|youtu\.be\/)([^\"&?\/ ]{11})/;
  const match = link.match(videoIdRegex);
  return match && match[1] ? match[1] : null;
}

function EmbedParse(data, config) {
  const captionStyle = `margin-bottom: 20px; padding: 0 20px;`;
  const embedStyle = `margin-top: 20px; margin-bottom: 10px; padding: 0 20px; width: 100%; height: 400px;`;
  const videoId = extractYouTubeVideoId(data.embed);
  const embedUrl = `https://www.youtube.com/embed/${videoId}`;

  return `
  <p>
   <iframe src="${embedUrl}" frameborder="0" allowfullscreen style="${embedStyle}"></iframe>
   <p style="${captionStyle}">${data.caption}</p>
   </p>
  `;
}

function TextParse(data, config) {
  const element = data.text.replace(/href="/g, 'target="_blank" style="text-decoration: underline;" href="') || data.text;
  const captionStyle = `margin-bottom: 20px; margin-top: 20px;`;

  return `
   <p style="${captionStyle}">${element}</p>
  `;
}

const customParsers = {
  video: VideoParse,
  embed: EmbedParse,
  paragraph: TextParse,
};

const FloatEditor = (props) => {
  const ReactEditorJS = createReactEditorJS();
  const parser = new edjsParser(undefined, customParsers);
  const DEFAULT_INITIAL_DATA = () => {
    return {
      time: new Date().getTime(),
      blocks: [],
    };
  };
  const editorCore = useRef(null);
  const [editorData, setEditorData] = useState(DEFAULT_INITIAL_DATA);
  async function handleSave() {
    const savedData = await editorCore.current.save();
    console.log(savedData);
    const markup = parser.parse(savedData);
    console.log(markup);
    props.setDescription(markup);
  }
  // This will run only once

  const handleInitialize = React.useCallback((instance) => {
    if (editorCore !== null) {
      editorCore.current = instance;
    }
  }, []);

  useEffect(() => {
    if (
      props.isEdit &&
      props.defaultData &&
      editorCore.current &&
      editorCore.current._editorJS
    ) {
      editorCore.current._editorJS.blocks.renderFromHTML(props.defaultData);
      props.setDescription(props.defaultData);
    }
  }, [props.defaultData]);

  const handleReady = () => {
    const editor = editorCore.current._editorJS;

    new DragDrop(editor);
  };

  return (
    <React.Fragment>
      {/* <div id={EDITTOR_HOLDER_ID}> </div> */}
      <ReactEditorJS
        defaultValue={DEFAULT_INITIAL_DATA}
        logLevel="ERROR"
        data={editorData}
        onInitialize={handleInitialize}
        onReady={handleReady}
        placeholder="Let`s write an awesome story!"
        onChange={async () => {
          handleSave();
        }}
        // autofocus: true,
        defaultBlock="paragraph"
        tools={EDITOR_JS_TOOLS}
      />
    </React.Fragment>
  );
};

export default FloatEditor;
