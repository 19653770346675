import React from "react";
import { Link } from "react-router-dom";
import {
  updateSeeAll,
  updateIsPageCourse,
  updateIsCitizenSidebarOpen,
  updateUserProfileId,
} from "shared/actions";
import { connect } from "react-redux";
import {
  MdKeyboardArrowLeft,
  MdKeyboardArrowRight,
  MdOutlineArticle,
} from "react-icons/md";

import { HiHome } from "react-icons/hi";
import { RiArticleFill } from "react-icons/ri";
import { DropArrow } from "../icons/icons";
import SeeAll from "./v2/see_all";
// import { IsCitizenSidebarOpen } from "../reducers";
class CitizenSidebar extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedMenu: "",
      openCallout: false,
    };
    this.handleClick.bind(this);
  }

  handleClick = (isExpanded) => {
    this.props.setIsExpanded(isExpanded);
    this.props.updateIsCitizenSidebarOpen(isExpanded);
    this.checkBorder();

    // if(this.props.isPageCourse)
    // this.props.setIsExpanded(false);
  };

  checkBorder = () => {
    var CitizenSidebar = document.getElementById("CitizenSidebar");
    var sidebarItems = CitizenSidebar.getElementsByClassName("sidebar-items");

    // if (!this.props.isExpanded) {
    //   // for (let i = 0; i < sidebarItems.length; i++) {
    //   //   sidebarItems[i].classList.remove("sidebarItemBorderLeft");
    //   // }
    // } else {
    for (let i = 0; i < sidebarItems.length; i++) {
      sidebarItems[i].classList.add("sidebarItemBorderLeft");
    }
    if (this.state.selectedMenu !== "")
      document
        .getElementById(this.state.selectedMenu)
        .classList.remove("sidebarItemBorderLeft");
  };

  handleSelectedItem = (menuItem) => {
    this.setState({ selectedMenu: menuItem });
    // if (!this.props.isExpanded) {
    var CitizenSidebar = document.getElementById("CitizenSidebar");
    var sidebarItems = CitizenSidebar.getElementsByClassName("sidebar-items");
    for (var i = 0; i < sidebarItems.length; i++) {
      if (sidebarItems[i].id !== menuItem)
        sidebarItems[i].classList.add("sidebarItemBorderLeft");
    }
    // if (!this.props.isExpanded)
    document.getElementById(menuItem).classList.remove("sidebarItemBorderLeft");
  };

  EventIcon() {
    return (
      <svg
        width="20"
        height="20"
        viewBox="0 0 20 20"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M0 10C0 4.47581 4.47581 0 10 0C15.5242 0 20 4.47581 20 10C20 15.5242 15.5242 20 10 20C4.47581 20 0 15.5242 0 10ZM7.56855 15.0403L14.6653 10.9677C15.3266 10.6008 15.3266 9.64516 14.6653 9.27419L7.56855 4.95968C6.92742 4.60484 6.12903 5.06452 6.12903 5.80645V14.1935C6.12903 14.9395 6.93145 15.3952 7.56855 15.0403Z"
          fill="#ff9900"
        />
      </svg>
    );
  }

  MediaIcon() {
    return (
      <svg
        width="25"
        height="25"
        viewBox="0 0 25 25"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M6.94444 6.94444C7.32798 6.94444 7.63889 6.63353 7.63889 6.25V2.08333C7.63889 1.6998 7.32798 1.38889 6.94444 1.38889C6.56091 1.38889 6.25 1.6998 6.25 2.08333V6.25C6.25 6.63353 6.56091 6.94444 6.94444 6.94444Z"
          fill="#272262"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M15.625 4.16666C15.625 7.04314 17.9569 9.37499 20.8333 9.37499C21.817 9.37601 22.7806 9.09657 23.6111 8.56944V20.9653C23.6167 21.2931 23.4918 21.6098 23.2639 21.8455C23.0359 22.0812 22.7237 22.2167 22.3958 22.2222H2.60417C2.27632 22.2167 1.96408 22.0812 1.73616 21.8455C1.50824 21.6098 1.38332 21.2931 1.3889 20.9653V5.42361C1.38325 5.08975 1.5129 4.76781 1.74835 4.53105C1.9838 4.2943 2.30502 4.16286 2.6389 4.16666H5.41667V6.24999C5.41667 7.09376 6.10068 7.77777 6.94445 7.77777C7.78822 7.77777 8.47223 7.09376 8.47223 6.24999V4.16666H15.625ZM11.2986 18.2292L7.6389 14.5417L11.2986 18.2292ZM7.6389 14.5417C7.30229 14.1714 7.31284 13.603 7.66294 13.2455L7.6389 14.5417ZM7.66294 13.2455C8.01305 12.888 8.58119 12.8656 8.95834 13.1944L7.66294 13.2455ZM8.95834 13.1944L11.3056 15.5417L8.95834 13.1944ZM11.3056 15.5417L16.6667 10.1805L11.3056 15.5417ZM16.6667 10.1805C16.9061 9.94113 17.255 9.84763 17.5821 9.93526L16.6667 10.1805ZM17.5821 9.93526C17.9092 10.0229 18.1646 10.2784 18.2522 10.6054L17.5821 9.93526ZM18.2522 10.6054C18.3398 10.932 18.2466 11.2805 18.0079 11.5198L18.2522 10.6054Z"
          fill="#232323"
        />
        <circle cx="20.8333" cy="4.16667" r="3.47222" fill="#232323" />
      </svg>
    );
  }

  GroupIcon() {
    return (
      <svg
        width="25"
        height="25"
        viewBox="0 0 25 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M5.68182 14.7413V17.0432C4.27159 17.4435 3.40909 17.9662 3.40909 18.5411C3.40909 19.768 7.31062 20.7666 12.1874 20.8077L12.5 20.809C17.5216 20.809 21.5909 19.7942 21.5909 18.5411C21.5909 17.9965 20.8168 17.4987 19.5363 17.1074L19.3182 17.0432V14.7424C22.7364 15.5509 25 16.9479 25 18.5411C25 21.0472 19.4023 23.0769 12.5 23.0769C5.59659 23.0769 0 21.0472 0 18.5411C0 16.9479 2.26364 15.5509 5.68182 14.7413ZM18.1818 6.80367C19.4364 6.80367 20.4545 7.81969 20.4545 9.07156V13.6073H15.9091V9.07156C15.9091 7.81969 16.9273 6.80367 18.1818 6.80367ZM6.81818 6.80367C8.07273 6.80367 9.09091 7.81969 9.09091 9.07156V13.6073H4.54545V9.07156C4.54545 7.81969 5.56364 6.80367 6.81818 6.80367ZM12.5 4.53578C13.7545 4.53578 14.7727 5.5518 14.7727 6.80367V11.3395H10.2273V6.80367C10.2273 5.5518 11.2455 4.53578 12.5 4.53578ZM18.1818 2.26789C19.1193 2.26789 19.8864 3.03444 19.8864 3.96881C19.8864 4.90318 19.1193 5.66973 18.1818 5.66973C17.2443 5.66973 16.4773 4.90318 16.4773 3.96881C16.4773 3.03444 17.2443 2.26789 18.1818 2.26789ZM6.81818 2.26789C7.75568 2.26789 8.52273 3.03444 8.52273 3.96881C8.52273 4.90318 7.75568 5.66973 6.81818 5.66973C5.88068 5.66973 5.11364 4.90318 5.11364 3.96881C5.11364 3.03444 5.88068 2.26789 6.81818 2.26789ZM12.5 0C13.4375 0 14.2045 0.766547 14.2045 1.70092C14.2045 2.63529 13.4375 3.40184 12.5 3.40184C11.5625 3.40184 10.7955 2.63529 10.7955 1.70092C10.7955 0.766547 11.5625 0 12.5 0Z"
          fill="#232323"
        />
      </svg>
    );
  }

  community() {
    return (
      <svg
        width="25"
        height="25"
        viewBox="0 0 29 22"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M23.206 0.0502167C24.6245 0.0502167 25.7851 1.21712 25.7851 2.6395C25.7851 4.06188 24.6245 5.22879 23.206 5.22879C21.7875 5.22879 20.627 4.06188 20.627 2.6395C20.627 1.21712 21.7875 0.0502167 23.206 0.0502167Z"
          fill="black"
        />
        <path
          d="M26.6448 17.3121H19.7673V10.4074C19.7673 8.50164 21.3079 6.95497 23.2061 6.95497C25.1043 6.95497 26.6448 8.50164 26.6448 10.4074V17.3121Z"
          fill="black"
        />
        <path
          d="M14.6092 3.50259C16.0277 3.50259 17.1883 4.6695 17.1883 6.09188C17.1883 7.51426 16.0277 8.68117 14.6092 8.68117C13.1907 8.68117 12.0302 7.51426 12.0302 6.09188C12.0302 4.6695 13.1907 3.50259 14.6092 3.50259Z"
          fill="black"
        />
        <path
          d="M18.0479 20.7645H11.1704V13.8597C11.1704 11.954 12.711 10.4074 14.6091 10.4074C16.5073 10.4074 18.0479 11.954 18.0479 13.8597V20.7645Z"
          fill="black"
        />
        <path
          d="M6.0124 0.0502167C7.43088 0.0502167 8.59145 1.21712 8.59145 2.6395C8.59145 4.06188 7.43088 5.22879 6.0124 5.22879C4.59392 5.22879 3.43335 4.06188 3.43335 2.6395C3.43335 1.21712 4.59392 0.0502167 6.0124 0.0502167Z"
          fill="black"
        />
        <path
          d="M9.45108 17.3121H2.57361V10.4074C2.57361 8.50164 4.11416 6.95497 6.01234 6.95497C7.91053 6.95497 9.45108 8.50164 9.45108 10.4074V17.3121Z"
          fill="black"
        />
        <rect
          x="0.69043"
          y="17.1238"
          width="27.619"
          height="4.14286"
          fill="black"
        />
      </svg>
    );
  }

  render() {
    return (
      <div
        className={this.props.isExpanded ? "Sidebar" : "Sidebar collapsed"}
        id="CitizenSidebar"
      >
        <div
          className={`sidebar-items  ${
            this.props.setSidebarItemData === "/home" && !this.props.isChatFormOpen
              ? "sidebar-selected-item"
              : "sidebarItemBorderLeft"
          }`}
          id="Home"
        >
          <Link to={"/home"}>
            <div
              className={`item   ${
                !this.props.isExpanded && "d-flex justify-content-center"
              }`}
              onClick={() => {
                this.props.updateSeeAll({
                  isOpen: null,
                  calledFrom: null,
                });
                this.handleSelectedItem("Home");
              }}
            >
              <abbr title="Home">
                <div
                  className={`row ${
                    !this.props.isExpanded &&
                    "align-items-center  justify-content-center"
                  }`}
                >
                  {this.props.isExpanded ? <DropArrow /> : ""}
                  <div className={this.props.isExpanded ? "px-1" : "px-3"}>
                    <div className="region-Icon">
                      <img src={require("assets/images/icons/HomeIcon.svg")} />
                    </div>
                  </div>
                  <div
                    className={
                      this.props.isExpanded
                        ? "d-block col-6 font-weight-bold text-white"
                        : "d-none"
                    }
                  >
                    Home
                  </div>
                </div>
                {!this.props.isExpanded && (
                  <div className="icon-label">Home</div>
                )}
              </abbr>
            </div>
          </Link>
        </div>

        <div
          className={`sidebar-items  ${
            this.props.setSidebarItemData === "/community-page" && !this.props.isChatFormOpen
              ? "sidebar-selected-item"
              : "sidebarItemBorderLeft"
          }`}
          id="Region_page"
        >
          <Link className={""} to={"/community-page"}>
            <div
              className={`item   ${
                !this.props.isExpanded && "d-flex justify-content-center"
              }`}
              onClick={() => {
                // this.props.updateSeeAll({
                //   isOpen: "region",
                //   calledFrom: "region",
                // });
                this.handleSelectedItem("Region_page");
              }}
            >
              <abbr title="Region">
                <div
                  className={`row ${
                    !this.props.isExpanded &&
                    "align-items-center  justify-content-center"
                  }`}
                >
                  {this.props.isExpanded ? <DropArrow /> : ""}
                  <div className={this.props.isExpanded ? "px-1" : "px-3"}>
                    <div className="region-Icon">
                      <img
                        src={require("assets/images/icons/region-icon.svg")}
                      />
                    </div>
                  </div>
                  <div
                    className={
                      this.props.isExpanded
                        ? "d-block col-6 font-weight-bold text-white"
                        : "d-none"
                    }
                  >
                    Communities
                  </div>
                  {!this.props.isExpanded && (
                    <div className="icon-label">Communities</div>
                  )}
                </div>
              </abbr>
            </div>
          </Link>
        </div>

        <div
          className={`sidebar-items  ${
            this.props.setSidebarItemData === "/courses" && !this.props.isChatFormOpen
              ? "sidebar-selected-item"
              : "sidebarItemBorderLeft"
          }`}
          id="Courses"
        >
          <Link className={""} to={"/courses"}>
            <div
              className={`item   ${
                !this.props.isExpanded && "d-flex justify-content-center"
              }`}
              onClick={() => {
                // this.props.updateSeeAll({
                //   isOpen: "courses",
                //   calledFrom: "courses",
                //   id: "1",
                // });
                this.handleSelectedItem("Courses");
              }}
            >
              <abbr title="Courses">
                <div
                  className={`row ${
                    !this.props.isExpanded &&
                    "align-items-center  justify-content-center"
                  }`}
                >
                  {this.props.isExpanded ? <DropArrow /> : ""}
                  <div className={this.props.isExpanded ? "px-1" : "px-3"}>
                    <div className="sidebar-icon">{this.EventIcon()}</div>
                  </div>
                  <div
                    className={
                      this.props.isExpanded
                        ? "d-block font-weight-bold text-white"
                        : "d-none"
                    }
                  >
                    Courses
                  </div>
                  {!this.props.isExpanded && (
                    <div className="icon-label">Courses</div>
                  )}
                </div>
              </abbr>
            </div>
          </Link>
        </div>
        <div
          className={`sidebar-items  ${
            this.props.setSidebarItemData === "/news" && !this.props.isChatFormOpen
              ? "sidebar-selected-item"
              : "sidebarItemBorderLeft"
          }`}
          id="News"
        >
          <Link className={""} to={"/news"}>
            <div
              className={`item   ${
                !this.props.isExpanded && "d-flex justify-content-center"
              }`}
              onClick={() => {
                // this.props.updateSeeAll(pwa-callout-body pl-4 pt-4 courses-page-container pb-
                //   isOpen: "article",
                //   calledFrom: "article",
                // });
                this.handleSelectedItem("News");
              }}
            >
              <abbr title="News">
                <div
                  className={`row ${
                    !this.props.isExpanded &&
                    "align-items-center  justify-content-center"
                  }`}
                >
                  {this.props.isExpanded ? <DropArrow /> : ""}
                  <div className={this.props.isExpanded ? "px-1" : "px-3"}>
                    <div className="sidebar-icon">
                      <img
                        src={require("assets/images/icons/news.svg")}
                        height="20px"
                      />
                    </div>
                  </div>
                  <div
                    className={
                      this.props.isExpanded
                        ? "d-block font-weight-bold text-white"
                        : "d-none"
                    }
                  >
                    News
                  </div>
                </div>
                {!this.props.isExpanded && (
                  <div className="icon-label">News</div>
                )}
              </abbr>
            </div>
          </Link>
        </div>

        <div
          className={`sidebar-items  ${
            this.props.setSidebarItemData === "/events" && !this.props.isChatFormOpen
              ? "sidebar-selected-item"
              : "sidebarItemBorderLeft"
          }`}
          id="Events"
        >
          <Link className={""} to={"/events"}>
            <div
              className={`item   ${
                !this.props.isExpanded && "d-flex justify-content-center"
              }`}
              onClick={() => {
                // this.props.updateSeeAll({
                //   isOpen: "events",
                //   calledFrom: "events",
                // });
                this.handleSelectedItem("Events");
              }}
            >
              <abbr title="Events">
                <div
                  className={`row ${
                    !this.props.isExpanded &&
                    "align-items-center  justify-content-center"
                  }`}
                >
                  {this.props.isExpanded ? <DropArrow /> : ""}
                  <div className={this.props.isExpanded ? "px-1" : "px-3"}>
                    <div className="sidebar-icon">
                      <img src={require("assets/images/icons/events.svg")} />
                    </div>
                  </div>
                  <div
                    className={
                      this.props.isExpanded
                        ? "d-block font-weight-bold text-white"
                        : "d-none"
                    }
                  >
                    Events
                  </div>
                </div>
                {!this.props.isExpanded && (
                  <div className="icon-label event-margin">Events</div>
                )}
              </abbr>
            </div>
          </Link>
        </div>

        <div className="p-4">
          <div className="">
            {this.props.isExpanded ? (
              <Link
                className="text-dark"
                onClick={() => this.handleClick(false)}
              >
                <div className="row align-items-center ">
                  <MdKeyboardArrowLeft className="sidebarIcon" />

                  <div
                    className={
                      this.props.isExpanded
                        ? "d-inline col-8 text-white"
                        : "d-none"
                    }
                  >
                    <small>Close Sidebar</small>
                  </div>
                </div>
              </Link>
            ) : (
              <Link
                className="text-dark"
                onClick={() => this.handleClick(true)}
              >
                <div className="row align-items-center justify-content-center">
                  {" "}
                  <MdKeyboardArrowRight className="sidebarIcon" />
                </div>
              </Link>
            )}
          </div>
        </div>
      </div>
    );
  }
}
const mapActionToProps = {
  updateSeeAll,
  updateIsPageCourse,
  updateIsCitizenSidebarOpen,
  updateUserProfileId,
};

const mapStateToProps = (state) => ({
  isPageCourse: state.isPageCourse,
  userData: state.userData,
  isCitizenSidebarOpen: state.isCitizenSidebarOpen,
  seeAll: state.seeAll,
  userProfileId: state.userProfileId,
  setSidebarItemData: state.setSidebarItemData,
  isChatFormOpen: state.isChatFormOpen,
});

export default connect(mapStateToProps, mapActionToProps)(CitizenSidebar);
