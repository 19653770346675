import React, { useState } from "react";
import { AiOutlineHeart, AiFillHeart } from "react-icons/ai";
import { Link } from "react-router-dom";
import { BackArrowIcon } from "../../../shared/icons/icons";

const RegionHeader = (props) => {
  const [isFollowing, setIsFollowing] = useState(false);
  const handleClick = (state) => {
    setIsFollowing(state);
  };
  return (
    <div className="d-flex w-100 regionPageHeader">
      <div className="d-flex w-100 bd-highlight pb-1 w-25">
        <div className="px-2 mr-2 bd-highlight w-100 d-flex justify-content-between align-items-center">
          <div className=" d-flex align-items-center">
            <Link to={"/home"} onClick={() => {}}>
              <div className="ml-2 pt-1">
                <BackArrowIcon />
              </div>
            </Link>
            <div className="ml-3 d-flex align-items-center">
              <img src={props.url} alt="Region Icon" style={{ height: 45 }} />
              <div className="pt-1">
                <h5 className="ml-2">
                  {props.location}
                  <br />
                  <small>{props.region}</small>
                </h5>
              </div>
            </div>
          </div>
          <div
            className="shadow-sm bg-white rounded-lg py-2 px-4 cursor-pointer"
            style={{ color: "#422A66" }}
            onClick={() => handleClick(!isFollowing)}
          >
            {isFollowing ? (
              <AiFillHeart size={25} color="#422A66" className="mr-2" />
            ) : (
              <AiOutlineHeart size={25} color="#422A66" className="mr-2" />
            )}
            <span className="pt-1">Follow</span>
          </div>
        </div>
      </div>
    </div>
  );
};

export default RegionHeader;
