import React, { Component } from "react";
import { Utility } from "utils";
import ReportIcon from "@mui/icons-material/Report";
import { updateUserData, showLoader } from "shared/actions";
import { toast } from "react-toastify";
import { SENDMAIL_TO_ADMIN } from "../../../config/constants/api_endpoints";
import { CLIENT_ID } from "../../../config/constants/config";
import { connect } from "react-redux";

export class ActuveteAccountModal extends Component {
  constructor(props) {
    super(props);

    this.state = {
      formData: {
        email: localStorage.getItem("email") || "",
        password: Utility.loadStates("password") || "",
        client_id: CLIENT_ID.toString(),
      },
      adminContectFrom: {
        Name: "",

        Discription: "",
      },
      adminContectFromError: {
        Name: "",
        Discription: "",
      },
      localStorage: [],
      showLoader: false,
      isLogin: false,
      inputType: "password",
      remember_me: localStorage.getItem("remember_me") || false,
    };
  }

  componentDidUpdate = () => {
    console.log(this.props);
  };
  handleChangeInFrom = (event) => {
    const name = event.target.name;
    const value = event.target.value;
    let CurrentValue = { ...this.state.adminContectFrom };
    CurrentValue[name] = value;
    const Error = this.validateContactForm(name);
    this.setState({
      adminContectFromError: {
        ...this.state.adminContectFromError,
        [name]: Error,
      },
    });
    this.setState({
      adminContectFrom: CurrentValue,
    });
  };
  handleContactForm = () => {
    const NameError = this.validateContactForm("Name");
    const DescriptionError = this.validateContactForm("Discription");
    this.setState({
      adminContectFromError: {
        Name: NameError,

        Discription: DescriptionError,
      },
    });
    if (!(NameError || DescriptionError)) {
      this.props.showLoader(true);
      const params = {
        name: this.state.adminContectFrom.Name,
        admin_name: "Gil",
        reason: this.state.adminContectFrom.Discription,
        email: this.props.user.email,
        sent_to: "contact.gil@worldea.org",
      };
      Utility.sendRequest(
        SENDMAIL_TO_ADMIN,
        2,
        params,
        (error, response, body) => {
          body = JSON.parse(body);

          if (!body.error) {
            toast.success(body.message, { containerId: "public" });
            // cookie.save('usersSession', body.token);
            this.props.handleClose();
          } else {
            toast.error(body.error.message, { containerId: "public" });
          }
          this.props.showLoader(false);
        }
      );
    }
  };

  validateContactForm = (name) => {
    let isValid = "";
    if (
      !(
        this.state.adminContectFrom[name] &&
        this.state.adminContectFrom[name].trim()
      )
    ) {
      isValid = `${name} is a Required Field`;
    } else if (
      name === "Email" &&
      !/^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/.test(
        this.state.adminContectFrom[name]
      )
    ) {
      isValid = "Enter A valid email";
    }
    return isValid;
  };
  render() {
    return (
      <>
        <div className="active-modal">
          <div className="modal-title">
            <ReportIcon />
            <p className="modal-title-text">Contact Us</p>
          </div>

          <div className="modal-body">
            We are facing issue in retrieving your profile details. Possible
            reason is web site is going through maintenance. You can drop a
            message to the administrator using form below. You will be notified
            when the issue get's troubleshooted
          </div>
          {this.props.user && this.props.user.email && (
            <p className="modal-body font-bold">{this.props.user.email}</p>
          )}
          <div className="w-100 ml-4">
            <input
              onChange={this.handleChangeInFrom}
              value={this.state.adminContectFrom.Name}
              className="modal-input"
              name="Name"
              placeholder="Enter Your Name"
            />
            <p className="ml-3 error-text">
              {this.state.adminContectFromError.Name}
            </p>
          </div>
          {/* <div className="w-100 ml-4">
            <input
              type="email"
              name="Email"
              className="modal-input"
              placeholder="Enter your email"
              readOnly
              //   onChange={this.handleChangeInFrom}
              value={}
            />
            <p className="ml-3 error-text">
              {this.state.adminContectFromError.Email}
            </p> */}
          {/* </div> */}
          <div className="w-100 ml-4">
            <textarea
              name="Discription"
              onChange={this.handleChangeInFrom}
              value={this.state.adminContectFrom.Discription}
              className="modal-textArea"
              placeholder="Enter Your Message..."
            />
            <p className="ml-3 error-text">
              {this.state.adminContectFromError.Discription}
            </p>
          </div>
          <button
            onClick={this.handleContactForm}
            className="btn-primary modal-btn"
            placeholder="Enter your message"
          >
            submit
          </button>
        </div>
      </>
    );
  }
}

const mapActionToProps = { updateUserData, showLoader };
const mapStateToProps = (state) => ({
  userData: state.userData,
});
export default connect(mapStateToProps, mapActionToProps)(ActuveteAccountModal);
