import React, { useRef, useState, useEffect } from "react";
import {
  InputField,
  DropDown,
  Textarea,
  InputValidationText,
} from "shared/components/form_elements";
import { Utility } from "utils";
import Avatar from "react-avatar";
import { AiOutlineArrowLeft } from "react-icons/ai";
import {
  COUNTRIES,
  STATES,
  TIMEZONE,
  CAMPAIGNS,
  FEATURED_COMMUNITY,
} from "config/constants/api_endpoints";
import DatePicker from "react-multi-date-picker";
import DatePanel from "react-multi-date-picker/plugins/date_panel";
import TimePicker from "react-multi-date-picker/plugins/time_picker";
import { useSelector, useDispatch } from "react-redux";
import { CAMPAIGN_TYPES_IDS, ROLE_IDS } from "config/constants";
import { updateSeeAll } from "../../../actions";
import { toast } from "react-toastify";
import UploadFile from "shared/components/file_upload";
import { CLIENT_ID } from "../../../../config/constants/config";
import {
  CAMPAIGN_DETAILS,
  GET_CLIENTS_SUMMARY,
  GET_USERS_BY_NETWORKS,
  MESSAGE_IMAGE_URL,
} from "../../../../config/constants/api_endpoints";
import { CgClose } from "react-icons/cg";
import InforCard from "../../../../scenes/profile/components/InforCard";

import { EventIcon } from "../../../icons/icons";
import moment from "moment";
import { Autocomplete, Skeleton, TextField } from "@mui/material";
import { Spinner } from "react-bootstrap";
import { NewUploadView } from "../../NewFileUpload";
import { withRouter } from "react-router-dom";
function CreateEvent(props) {
  const region = useRef("region");
  let userData = useSelector((state) => state.userData);
  const [countyData, setCountryData] = useState([]);
  const [stateData, setStateData] = useState([]);
  const [timeZoneData, setTimeZoneData] = useState([]);
  const dispatch = useDispatch();
  const [speakerShowInvites, setSpeakerShowInvites] = useState(false);
  const [attendeesShowInvites, setAttendeesShowInvites] = useState(false);
  const [search, setSearch] = useState("");
  const [speakerSearch, setSpeakerSearch] = useState("");
  const [invites, setInvites] = useState([]);
  const [attendeesInvite, setAttendeesInvite] = useState([]);
  const [communityData, setCommunityData] = useState([]);
  const [imageFile, setImageFile] = useState("");
  const [eventData, setEventData] = useState([]);
  const [values, setValues] = useState([]);
  const eventDescriptionref = useRef("");
  const [eventImage, setEventImage] = useState("");
  const [success, setSuccess] = useState(false);
  const [createrId, setCreaterId] = useState([]);
  const [joinedNeignBourhood, setJoinedNeighbourhood] = useState([]);
  const [isNetworkUsersLoading, setIsNetworkUsersLoading] = useState(false);
  const [isAttendeesDataLoading, setIsAttendeesDataLoading] = useState(false);
  const [attendees, setAttendees] = useState([]);
  const [isCreateEventPosting, setIsCreateEventPosting] = useState(false);
  const [ranking, setRanking] = useState(null);
  let debouncedFn = null;
  const [formCheck, setFormCheck] = useState({
    zipCodeCheck: {
      value: false,
      msg: "",
    },
    startDateCheck: false,
    startTimeCheck: false,
    endDateCheck: false,
    endTimeCheck: false,
  });
  const [users, setUsers] = useState([]);
  const [defaultcountryName, setDefaultCountryName] = useState("");
  const [defaultStateName, setDefaultStateName] = useState("");
  const [cropping, setCropping] = useState(false);
  const [formData, setFormData] = useState({
    eventName: "",
    image_url: "",
    eventDescription: "",
    eventLocation: "",
    eventLink: "",
    streetAdrress1: "",
    streetAddress2: "",
    countryId: "",
    cityName: "",
    stateName: "",
    zipCode: "",
    timeZone: "",
    startDate: "",
    startTime: "",
    endDate: "",
    endTime: "",
    speakerId: [],
    attendeesId: [],
    community_name: "",
  });

  const accounts = useSelector((state) => state.accountList);
  useEffect(() => {
    let response = [];
    if (
      userData &&
      userData.role_id !== ROLE_IDS.CLIENT_ADMIN &&
      userData.role_id !== ROLE_IDS.CLIENT_OWNER
    ) {
      if (accounts && accounts.Clients && accounts.Clients.length > 0) {
        const allAccounts = [...accounts.Clients];
        let tempBody = allAccounts.filter(
          (value, index, self) =>
            index === self.findIndex((t) => t.id === value.id)
        );
        response = [
          ...tempBody.map((items) => {
            if (items.client_id === userData.client_id) {
              setCreaterId([...createrId, items.client_id]);
            }
            return {
              name: items.user_name,
              value: items.client_id ? items.client_id : items.id,
            };
          }),
        ];
        setJoinedNeighbourhood(response);
      }
      if (
        userData &&
        (userData.role_id === ROLE_IDS.PARENT_ADMIN ||
          userData.role_id === ROLE_IDS.PARENT_OWNER)
      ) {
        const params = {
          client_id: CLIENT_ID,
          type: "clients",
        };
        Utility.sendRequest(
          GET_CLIENTS_SUMMARY,
          1,
          params,
          async (error, response, body) => {
            body = JSON.parse(body);
            response = body.clients.map((data) => {
              return {
                name: data.user_name,
                value: data.client_id,
              };
            });
            setJoinedNeighbourhood([...joinedNeignBourhood, ...response]);
          }
        );
      }
    } else {
      if (userData) {
        setCreaterId(userData.client_id);
        setJoinedNeighbourhood([
          { name: userData.user_name, value: userData.client_id },
        ]);
      }
    }
  }, [userData, accounts]);

  const getAllCountries = (defaultCountry, defaultState) => {
    Utility.sendRequest(COUNTRIES, 1, { limit: 300 }, (err, response, body) => {
      body = JSON.parse(body);
      if (!body.error) {
        if (defaultCountry && props.isUpdate) {
          const counteyValue = body.data.filter(
            (data) => data.name === defaultCountry.trim()
          );
          if (counteyValue && counteyValue.length > 0) {
            setDefaultCountryName(counteyValue[0].id);
          }
        }
        setCountryData(body.data);
      } else {
        toast.error(JSON.parse(body).error.message, { containerId: "private" });
      }
    });
  };
  const getStateByCountry = (countryId, defaultStateId) => {
    Utility.sendRequest(
      STATES,
      1,
      { country_id: countryId, limit: 200, order: "name" },
      (err, response, body) => {
        body = JSON.parse(body);
        if (!body.error) {
          if (countryId && defaultStateId) {
            let currentData = { ...formData };
            const stateValue = body.data.filter(
              (data) => data.name === defaultStateId.trim()
            );
            currentData.countryId = countryId;
            currentData.stateName = stateValue[0].id;
            setFormData(currentData);
          }
          setStateData(body.data);
        } else {
          toast.error(JSON.parse(body).error.message, {
            containerId: "private",
          });
        }
      }
    );
  };

  const getTimezone = () => {
    Utility.sendRequest(TIMEZONE, 1, { limit: 200 }, (err, response, body) => {
      body = JSON.parse(body);

      if (!body.error) {
        setTimeZoneData(body.data);
      } else {
        toast.error(JSON.parse(body).error.message, { containerId: "private" });
      }
    });
  };

  const getCampaignDetails = (campId) => {
    // props.showLoader(true);
    const params = {
      user_client_id: userData.id,
      campaign_id: campId,
      publisher_network_id: CLIENT_ID,
    };

    Utility.sendRequest(CAMPAIGN_DETAILS, 1, params, (err, res, body) => {
      body = JSON.parse(body);

      if (body.error) {
        toast.error(body.error.message, { containerId: "private" });
      } else {
        if (body.data && body.data.length > 0) {
          const campaigns_data = body.data.filter(
            (item) => item.campaign_type_id !== CAMPAIGN_TYPES_IDS.section
          );
          getUserDetails(
            campaigns_data[0].non_profits,
            campaigns_data[0].organizers
          );
          setRanking(campaigns_data[0].ranking);
          let curentData = { ...formData };
          const address = campaigns_data[0].location
            .replaceAll(",", "")
            .split("%^splt^% ");
          curentData = {
            ...curentData,
            eventName: campaigns_data[0].heading,
            eventDescription: campaigns_data[0].description
              .replace(/<[^>]*>/g, "")
              .replaceAll("&nbsp", "  "),
            eventLink: campaigns_data[0].meeting_url,
            timeZone: campaigns_data[0].campaigns_timezone,
          };
          setEventImage(campaigns_data[0].image_url);
          if (address && address.length > 0) {
            getAllCountries(address[2], address[4]);
          } else {
            getAllCountries();
          }
          if (address[0] && !address[0].includes("undefined")) {
            curentData.streetAdrress1 = address[0].trim();
          }
          if (address[1] && !address[1].includes("undefined")) {
            curentData.streetAddress2 = address[1].trim();
          }
          if (address[2] && !address[2].includes("undefined")) {
            setDefaultCountryName(address[2].trim());
          }
          if (address[3] && !address[3].includes("undefined")) {
            curentData.cityName = address[3].trim();
          }

          if (address[4] && !address[4].includes("undefined")) {
            setDefaultStateName(address[4].trim());
          }
          if (address[5] && !address[5].includes("undefined")) {
            curentData.zipCode = address[5].trim();
          }
          if (campaigns_data[0].campaigns_dates) {
            setValues(
              campaigns_data[0].campaigns_dates.map((data) => new Date(data))
            );
          }

          // if (campaigns_data[0].non_profits_list) {
          //   setInvites();
          // }
          // if(campaigns_data[0].organizers_list) {
          //   setAttendeesInvite(campaigns_data[0].organizers_list)
          // }
          setFormData(curentData);
        }
      }

      props.showLoader(false);
    });
  };

  useEffect(() => {
    if (props.isUpdate) {
      getCampaignDetails(props.campId);
    }
  }, []);

  const handleChange = (e, checkValue) => {
    if (checkValue) {
      if (e.target.name == "zipCode") {
        if (!/^[0-9]+$/.test(e.target.value)) {
          setFormCheck((prev) => ({
            ...prev,
            zipCodeCheck: {
              value: true,
              msg: "Please only add numeric value",
            },
          }));
        } else if (e.target.value.length < 4) {
          setFormCheck((prev) => ({
            ...prev,
            zipCodeCheck: {
              value: true,
              msg: "Value must be greater than 4",
            },
          }));
        } else if (e.target.value.length > 10) {
          setFormCheck((prev) => ({
            ...prev,
            zipCodeCheck: {
              value: true,
              msg: "Value must be less than 10",
            },
          }));
        } else {
          setFormCheck((prev) => ({
            ...prev,
            zipCodeCheck: {
              value: false,
              msg: "",
            },
          }));

          setFormData((prevState) => ({
            ...prevState,
            [e.target.name]: e.target.value,
          }));
        }
      }
    } else if (e.target.name == "timeZone") {
      setFormData((prevState) => ({
        ...prevState,
        [e.target.name]: e.target.value,
      }));
    } else {
      setFormData((prevState) => ({
        ...prevState,
        [e.target.name]: e.target.value,
      }));
    }
  };

  const handleClick = (event, item, comp) => {
    if (item && comp == "speaker") {
      setFormData((prev) => {
        return {
          ...prev,
          speakerId: [...formData.speakerId, item.id],
        };
      });
      if (!invites.find(({ id }) => id === item.id)) {
        setInvites([...invites, item]);
        setSpeakerShowInvites(false);
      }
      if (invites.find(({ id }) => id === item.id && comp !== "speaker")) {
        toast.warn("Participant Already Added");
      }
      event.preventDefault();
    } else {
      setFormData((prev) => {
        return {
          ...prev,
          attendeesId: [...formData.attendeesId, item.id],
        };
      });
      if (!attendeesInvite.find(({ id }) => id === item.id)) {
        setAttendeesInvite([...attendeesInvite, item]);
        setAttendeesShowInvites(false);
      } else if (invites.length === 0) {
        setAttendeesInvite([...invites, item]);
        setAttendeesShowInvites(false);
      }
      if (invites.find(({ id }) => id === item.id && comp !== "attendees")) {
        toast.warn("Participant Already Added");
      }
      event.preventDefault();
    }
  };

  const handleSpeakerSearch = (event) => {
    event.persist();
    setAttendeesShowInvites(false);
    if (speakerSearch.length === 0) {
      setUsers([]);
    }
    setSearch("");
    setSpeakerSearch(event.target.value);
    if (!debouncedFn) {
      debouncedFn = Utility.debounce(() => {
        if (speakerSearch.length > 2) getUserDetails();
      }, 500);
    }
    debouncedFn();
  };
  const handleAttendeesSearch = (event) => {
    event.persist();
    setSpeakerShowInvites(false);
    if (search.length === 0) {
      setAttendees([]);
    }
    setSpeakerSearch("");
    setSearch(event.target.value);
    if (!debouncedFn) {
      debouncedFn = Utility.debounce(() => {
        if (search.length > 2) getUserDetails();
      }, 500);
    }
    debouncedFn();
  };

  const getUserDetails = async (defaultAttendees, defaultOrganizers) => {
    const params = {
      client_id: CLIENT_ID,
      type: "users",
      forCommunity: true,
      limit: 20,
    };

    // dispatch(showLoader(true));
    if (speakerSearch) {
      params.search = speakerSearch;
      setIsNetworkUsersLoading(true);
    } else {
      if (search) {
        params.search = search;
        setIsAttendeesDataLoading(true);
      }
    }
    await Utility.sendRequest(
      GET_USERS_BY_NETWORKS,
      1,
      params,
      (err, res, body) => {
        body = JSON.parse(body);
        if (body.error) {
          toast.error(body.error.message, { containerId: "private" });
          // dispatch(showLoader(false));
          setIsNetworkUsersLoading(false);
          setIsAttendeesDataLoading(false);
        } else {
          if (
            body.registrants &&
            CLIENT_ID === "0bc8dc7c-b5a3-4344-b313-819fc640f6b5"
          ) {
            const userList = [];
            const neighbourhood = [];
            body.registrants.sort(compare).forEach((element) => {
              if (element.network === "users") userList.push(element);
              else neighbourhood.push(element);
            });
            if (defaultAttendees) {
              setInvites(
                userList.filter((data) => defaultAttendees.includes(data.id))
              );
            }
            if (defaultOrganizers) {
              setAttendeesInvite(
                userList.filter((data) => defaultOrganizers.includes(data.id))
              );
            }
            if (search) {
              setAttendees(body.registrants);
            } else {
              setUsers(body.registrants);
            }
          } else {
            if (body.registrants) setUsers(body.registrants);
          }
          setIsAttendeesDataLoading(false);
          setIsNetworkUsersLoading(false);
        }
      }
    );
  };

  const dataM = countyData.filter((item) => item.id == formData.countryId);
  const countryName = dataM && dataM.length !== 0 && dataM[0].name;
  const updateCampaignMediaUrl = (campId, imageURL, videoURL = "", index) => {
    const params = {
      image_url: imageURL,
      video_url: videoURL,
    };
    // dispatch(showLoader(true));
    Utility.sendRequest(
      CAMPAIGNS + "/" + campId,
      3,
      params,
      (err, res, body) => {
        body = JSON.parse(body);

        if (body.error) {
          toast.error(body.error.message, { containerId: "private" });
        } else {
          if (typeof createrId !== "string") {
            if (index === createrId.length - 1) {
              if (props.isNotSideBar) {
                props.closeCallout();
                props.runGetCall(CAMPAIGN_TYPES_IDS.event);
                props.handleMenuItemClick(
                  functionevent(props.menus, props.selectedMenuItemId)
                );
              } else {
                props.history.push(`event-confirmation/${body.id}`);
                setEventData(body);
                setSuccess(true);
              }
            }
          } else {
            if (props.isNotSideBar) {
              props.closeCallout();
              props.runGetCall(CAMPAIGN_TYPES_IDS.event);
              props.handleMenuItemClick(
                functionevent(props.menus, props.selectedMenuItemId)
              );
            } else {
              props.history.push(`event-confirmation/${body.id}`);
              setEventData(body);
              setSuccess(true);
            }
          }
        }
        // dispatch(showLoader(false));
      }
    );
  };

  const functionevent = (menus, selectedMenuItemId) => {
    for (var i = 0; i < menus.length; i++) {
      if (selectedMenuItemId === menus[i].id) {
        return menus[i];
      }
    }
  };

  const uploadFiles = async (id, imageSignedURL, index) => {
    let imageURL = eventImage;
    if (imageFile !== "") {
      imageURL = await Utility.uploadFile(imageFile, imageSignedURL);
    }
    updateCampaignMediaUrl(id, imageURL, "", index);
  };

  const createEvent = (CORP_ID, index) => {
    // dispatch(showLoader(true));
    setIsCreateEventPosting(true);
    let formattedDate = values
      .join(",")
      .split(",")
      .map((data) => {
        if (data) {
          return moment
            .utc(moment(data, "DD-MM-YYYY HH:mm").format("YYYY-MM-DD HH:mm"))
            .local()
            .format();
        }
      });
    let Statename = "";
    if (formData.stateName && stateData) {
      const values = stateData.filter((data) => data.id === formData.stateName);
      if (values) {
        Statename = values[0].name;
      }
    }
    const params = {
      heading: `${formData.eventName}`,
      sub_heading: "Agenda title 2",
      description: `<span> ${formData.eventDescription
        .trim()
        .replaceAll(/(\r\n|\r|\n)/g, "<br>")
        .replaceAll(" ", "&nbsp")} <\span>`,
      description_1: formData.eventDescription,
      location: `${
        formData.streetAdrress1 ? formData.streetAdrress1 : ""
      } %^splt^% ${
        formData.streetAddress2 ? formData.streetAddress2 : ""
      } %^splt^% ${countryName ? `${countryName},` : ""} %^splt^% ${
        formData.cityName ? `${formData.cityName},` : ""
      } %^splt^% ${Statename ? Statename : ""} %^splt^% ${
        formData.zipCode ? formData.zipCode : ""
      }`,

      video_url: "",
      document_url: "",
      embedded_video_link: "",
      image_url: `${formData.image_url}`,
      embedded_image_link: "",
      imageSignedURL: "",
      videoSignedURL: "",
      documentSignedURL: "",
      parent_image_url: "",
      parent_heading: "",
      category_id: "1a8727d6-141c-43de-8214-1837ab1ef370",
      ranking: null,
      meeting_url: `${formData.eventLink}`,
      parent_id: null,
      non_profits_id: null,
      campaign_type_id: `${CAMPAIGN_TYPES_IDS.event}`,
      author_name: `${userData.user_name}`,
      author_email: `${userData.email}`,
      author_id: `${userData.user_id}`,
      created_by: `${userData.user_id}`,
      publisher_network_id: `${CLIENT_ID}`,
      updated_by: `${userData.user_id}`,
      user_client_id: `${userData.id}`,
      schedule_start: "2022-09-13T11:08:58.081Z",
      client_id: `${CLIENT_ID}`,
      non_profits: [...formData.attendeesId],
      organizers: [...formData.speakerId],
      campaigns_timezone: formData.timeZone,
      groups: [],
      campaigns_dates: formattedDate,
      start_at:
        formData.startDate === "" || formData.startTime === ""
          ? new Date().toISOString()
          : `${formData.startDate} ${formData.startTime}`,
      start_at_show:
        formData.startDate === "" || formData.startTime === ""
          ? new Date().toISOString()
          : `${formData.startDate} ${formData.startTime}`,
      end_at:
        formData.endDate === "" || formData.endTime === ""
          ? new Date().toISOString()
          : `${formData.endDate} ${formData.endTime}`,
      end_at_show:
        formData.endDate === "" || formData.endTime === ""
          ? new Date().toISOString()
          : `${formData.endDate} ${formData.endTime}`,
      is_public: true,
      plain_description: `${formData.eventDescription}`,
      is_repeated: false,
      repeat_details: {
        start_time: "2022-09-13T16:38:58+05:30",
        end_time: "2022-09-13T16:38:58+05:30",
        frequency_type: "no_repeat",
        frequency: [],
      },

      corporate_id: CORP_ID,
    };
    Utility.sendRequest(CAMPAIGNS, 2, params, async (err, res, body) => {
      body = JSON.parse(body);

      if (body.error) {
        toast.error(body.error.message, { containerId: "private" });
      } else {
        const campData = body;
        if (!Utility.isEmpty(eventImage)) {
          await uploadFiles(campData.id, campData.imageSignedURL, index);
        } else {
          if (props.isFromAdmin) {
            props.runGetCall(CAMPAIGN_TYPES_IDS.event);
            props.handleMenuItemClick(
              functionevent(props.menus, props.selectedMenuItemId)
            );
          }
          setSuccess(true);
          setEventData(body);
        }
        if (index === createrId.length - 1) {
          toast.success(body.message, { containerId: "private" });
        }
      }
      if (index === createrId.length - 1) {
        setIsCreateEventPosting(false);
      }
    });
  };

  const updateEvent = (CORP_ID, index) => {
    setIsCreateEventPosting(true);

    let formattedDate = values
      .map((date) => Boolean(date.getDate))
      .includes(false)
      ? values
          .join(",")
          .split(",")
          .map((data) => {
            if (data) {
              return moment
                .utc(
                  moment(data, "DD-MM-YYYY HH:mm").format("YYYY-MM-DD HH:mm")
                )
                .local()
                .format();
            }
          })
      : values;
    let Statename = "";
    if (formData.stateName && stateData) {
      const values = stateData.filter((data) => data.id === formData.stateName);
      if (values) {
        Statename = values[0].name;
      }
    }
    const params = {
      heading: `${formData.eventName}`,
      sub_heading: "Agenda title 2",
      description: `<span> ${formData.eventDescription
        .trim()
        .replaceAll(/(\r\n|\r|\n)/g, "<br>")
        .replaceAll(" ", "&nbsp")} <\span>`,
      description_1: formData.eventDescription,
      location: `${
        formData.streetAdrress1 ? formData.streetAdrress1 : ""
      } %^splt^% ${
        formData.streetAddress2 ? formData.streetAddress2 : ""
      } %^splt^% ${countryName ? `${countryName},` : ""} %^splt^% ${
        formData.cityName ? `${formData.cityName},` : ""
      } %^splt^% ${Statename ? Statename : ""} %^splt^% ${
        formData.zipCode ? formData.zipCode : ""
      }`,

      video_url: "",
      document_url: "",
      embedded_video_link: "",
      image_url: `${formData.image_url}`,
      embedded_image_link: "",
      imageSignedURL: "",
      videoSignedURL: "",
      documentSignedURL: "",
      parent_image_url: "",
      parent_heading: "",
      category_id: "1a8727d6-141c-43de-8214-1837ab1ef370",
      ranking: ranking,
      meeting_url: `${formData.eventLink}`,
      parent_id: null,
      non_profits_id: null,
      campaign_type_id: `${CAMPAIGN_TYPES_IDS.event}`,
      author_name: `${userData.user_name}`,
      author_email: `${userData.email}`,
      author_id: `${userData.user_id}`,
      created_by: `${userData.user_id}`,
      publisher_network_id: `${CLIENT_ID}`,
      updated_by: `${userData.user_id}`,
      user_client_id: `${userData.id}`,
      schedule_start: "2022-09-13T11:08:58.081Z",
      client_id: `${CLIENT_ID}`,
      non_profits: [...formData.attendeesId],
      organizers: [...formData.speakerId],
      campaigns_timezone: formData.timeZone,
      groups: [],
      campaigns_dates: formattedDate,
      start_at:
        formData.startDate === "" || formData.startTime === ""
          ? new Date().toISOString()
          : `${formData.startDate} ${formData.startTime}`,
      start_at_show:
        formData.startDate === "" || formData.startTime === ""
          ? new Date().toISOString()
          : `${formData.startDate} ${formData.startTime}`,
      end_at:
        formData.endDate === "" || formData.endTime === ""
          ? new Date().toISOString()
          : `${formData.endDate} ${formData.endTime}`,
      end_at_show:
        formData.endDate === "" || formData.endTime === ""
          ? new Date().toISOString()
          : `${formData.endDate} ${formData.endTime}`,
      is_public: true,
      plain_description: `${formData.eventDescription}`,
      is_repeated: false,
      repeat_details: {
        start_time: "2022-09-13T16:38:58+05:30",
        end_time: "2022-09-13T16:38:58+05:30",
        frequency_type: "no_repeat",
        frequency: [],
      },

      corporate_id: CORP_ID,
    };
    Utility.sendRequest(
      `${CAMPAIGNS}/${props.campId}`,
      3,
      params,
      async (err, res, body) => {
        body = JSON.parse(body);

        if (body.error) {
          toast.error(body.error.message, { containerId: "private" });
        } else {
          const campData = body;
          if (!Utility.isEmpty(eventImage)) {
            Utility.sendRequest(
              MESSAGE_IMAGE_URL,
              1,
              {},
              async (error, response, body) => {
                body = JSON.parse(body);
                let newType;
                if (body.error) {
                  console.log(body.error);
                } else {
                  await uploadFiles(campData.id, body.path, index);
                }
              }
            );
          } else {
            if (props.isFromAdmin) {
              props.closeCallout();
              props.runGetCall(CAMPAIGN_TYPES_IDS.event);
              props.handleMenuItemClick(
                functionevent(props.menus, props.selectedMenuItemId)
              );
            }
            setSuccess(true);
            setEventData(body);
          }
          if (index === createrId.length - 1) {
            toast.success(body.message, { containerId: "private" });
          }
        }
        if (index === createrId.length - 1) {
          setIsCreateEventPosting(false);
        }
      }
    );
  };

  const handlePost = () => {
    const formDataEmpty = Object.values(formData).every((x) => {
      return x === null || x === "" || x.length === 0;
    });

    const checkValidation = Object.entries(formCheck).filter((item) => {
      return item[1] == true;
    });

    if (checkValidation.length !== 0) {
      toast.error("Please fill the form correctly", { containerId: "private" });
    } else if (formDataEmpty) {
      toast.error("Please fill the form, form is empty", {
        containerId: "private",
      });
    } else if (
      formData.eventName === "" ||
      formData.eventDescription === "" ||
      !(formData.image_url || (eventImage && eventImage.includes("http"))) ||
      (values && values.length <= 0) ||
      values.includes("Invalid date") ||
      (createrId && createrId.length <= 0)
    ) {
      toast.error("Please fill the required fields", {
        containerId: "private",
      });
    } else {
      if (createrId && createrId.length > 0) {
        if (!props.isUpdate) {
          const creator_id = Array.isArray(createrId) ? createrId : [createrId];
          creator_id.map((data, index) => {
            createEvent(data, index);
          });
        } else {
          if (typeof createrId === "string") {
            updateEvent(createrId);
          } else {
            createrId.map((data, index) => {
              updateEvent(data, index);
            });
          }
        }
      }
    }
  };

  function compare(a, b) {
    if (a.user_name < b.user_name) {
      return -1;
    }
    if (a.user_name > b.user_name) {
      return 1;
    }
    return 0;
  }

  const getfeaturedCommunity = () => {
    const params = {
      platform_id: CLIENT_ID,
      user_id: userData.user_id,
      is_client_active: true,
    };

    Utility.sendRequest(FEATURED_COMMUNITY, 1, params, (err, res, body) => {
      body = JSON.parse(body);

      if (body.error) {
        toast.error(body.error.message, { containerId: "private" });
      } else {
        let tempBody = body.data.filter(
          (value, index, self) =>
            index === self.findIndex((t) => t.id === value.id)
        );
        setCommunityData(tempBody);
      }
    });
  };

  const setImage = (inputId, previewURL, file) => {
    setImageFile(file);
    setEventImage(previewURL);
    setFormData((prevState) => ({
      ...prevState,
      image_url: file.name,
    }));
    document.getElementById("groupImageUrlError").innerText = "";
  };

  const removeImage = (inputId) => {
    if (inputId === "image_url") {
      handleChange({
        target: {
          id: "imageUrl",
          name: "imageUrl",
          value: "",
        },
      });
      setEventImage("");
    }
  };

  useEffect(() => {
    if (!props.isUpdate) {
      // getUserDetails();
      getAllCountries();
    }
    getTimezone();
    getfeaturedCommunity();
  }, []);

  useEffect(() => {
    getStateByCountry(formData.countryId);
  }, [formData.countryId]);

  useEffect(() => {
    if (defaultcountryName) {
      getStateByCountry(defaultcountryName, defaultStateName);
    }
  }, [defaultcountryName]);

  const handleRemoveValue = (i, name) => {
    if (name == "speaker") {
      const data = invites.filter((_, idx) => i !== idx);
      setInvites(data);
      setFormData((prev) => {
        return {
          ...prev,
          speakerId: [...data],
        };
      });
    } else {
      const data = invites.filter((_, idx) => i !== idx);
      setInvites(data);
      setFormData((prev) => {
        return {
          ...prev,
          attendeesId: [...data],
        };
      });
    }
  };

  return (
    <>
      <div
        className={`pwa-callout-body courses-page-container h-100  mt-1  overflow-x-hidden pb-md-5 mb-md-5 mb-lg-0 pb-lg-4  create-event-bottom ${
          props.isNotSideBar && "bg-white"
        }`}
      >
        <div
          className={`${
            props.isNotSideBar
              ? "position-fixed d-flex justify-content-between left-0 CreateEvent-page-sidebar-head"
              : "row justify-content-between align-items-center bg-white"
          } `}
        >
          <div
            className={` ${
              userData.role_id === ROLE_IDS.USER ? "" : "ml-4"
            } d-flex align-items-center`}
          >
            <AiOutlineArrowLeft
              style={{ width: "20px", height: "20px" }}
              className="CourseHeaderIcon ml-3 mr-2 cursor-pointer"
              onClick={() => {
                props.isNotSideBar
                  ? props.changeDisCard()
                  : dispatch(
                      updateSeeAll({
                        isOpen: "newsDiscard",
                        calledFrom: "newsDiscard",
                      })
                    ) && props.history.goBack();
              }}
            />
            <EventIcon />
            <label className="b-700 ml-2 my-0 pt-1">Create an Event</label>
          </div>
          <div className="col-1 d-flex justify-content-end m-2">
            {props.isUpdate ? (
              <button
                className="btn btn-primary p-1 course-button"
                onClick={handlePost}
                disabled={isCreateEventPosting}
              >
                {isCreateEventPosting ? (
                  <Spinner animation="border" role="status" />
                ) : (
                  "Update"
                )}
              </button>
            ) : (
              <button
                className="btn btn-primary p-1 course-button"
                onClick={handlePost}
                disabled={isCreateEventPosting}
              >
                {isCreateEventPosting ? (
                  <Spinner animation="border" role="status" />
                ) : (
                  "Post"
                )}
              </button>
            )}
          </div>
        </div>

        <div
          className={`row mt-15 mb-0 justify-content-center ${
            props.isNotSideBar && "pt-5"
          }`}
        >
          <div className="create-event-width justify-content-start">
            <label className="font-20-bold">Who is the Organizer ?</label>
          </div>
        </div>

        <div className="row mt-0 justify-content-center">
          <div className="create-event-width">
            <InforCard
              imageSize="37"
              showLikeSvg={true}
              ImageSection={
                userData.profile_image_url
                  ? userData.profile_image_url
                  : userData.user_name
              }
              Heading={userData.user_name}
              subHeading={`${userData.user_city ? userData.user_city : ""} ${
                userData.creator_state ? +", " + userData.creator_state : ""
              } ${
                userData.creator_country ? +", " + userData.creator_country : ""
              }`}
              isLike={""}
            />
          </div>
        </div>

        <div className="row mt-4 mb-0 justify-content-center">
          <div className="create-event-width justify-content-start">
            <label className="font-20-bold">
              Upload event details
              <span className="text-red">*</span>
            </label>
          </div>
        </div>

        <div className="row mt-0 justify-content-center">
          <InputField
            required={true}
            type="text"
            className="field-common-styling create-event-width"
            name="eventName"
            // ref="address1"
            value={formData && formData.eventName ? formData.eventName : ""}
            placeholder="Event Name *"
            handleChange={handleChange}
            showErrorComp={true}
          />
        </div>

        <div className="row mt-15 mb-4 justify-content-center display-inline-block">
          <div
            className="create-event-width align-items-center justify-content-center post-pic page-sidebar-upload create-article-upload ml-1"
            style={{ borderRadius: "15px" }}
          >
            <div className="w-100 align-items-center justify-content-center post-pic position-relative page-sidebar-upload create-article-upload">
              <div className="post-uplodad-label mb-0 cursor-pointer">
                {eventImage ? (
                  <UploadFile
                    name="image_url"
                    inputId="image_url"
                    removeText="Remove image"
                    categoryName="publisher_campaign_image"
                    acceptTypes="image/x-png,image/gif,image/jpeg"
                    uploadedFilePreviewUrl={eventImage}
                    handleFileChange={setImage}
                    removeUploadedFile={removeImage}
                  />
                ) : (
                  <NewUploadView
                    name="image_url"
                    inputId="image_url"
                    removeText="Remove image"
                    categoryName="publisher_campaign_image"
                    acceptTypes="image/x-png,image/gif,image/jpeg"
                    uploadedFilePreviewUrl={eventImage}
                    setCropping={setCropping}
                    isFormInSide={props.location.pathname.includes("campaigns")}
                    handleFileChange={setImage}
                    fromEditUser={true}
                    removeUploadedFile={removeImage}
                  />
                )}

                <div
                  className="error errorIcon text-left pl-2 mb-3"
                  id={"groupImageUrlError"}
                />
                {/* <div className="text-center">
                  <AddProfileImage
                    mainClass="add-icon-upper rounded-0 bg-none"
                    fillClass="fill-primary"
                  />
                  <h3
                    className="position-absolute"
                    style={{
                      top: "calc(50% - -30px)",
                      left: "calc(50% - 70px)",
                      fontSize: "1.25rem",
                    }}
                  >
                    Add an Image *
                  </h3>
                </div> */}
              </div>
            </div>
          </div>
        </div>
        <p
          className="f-weight-bold position-relative justify-content-center"
          style={{ fontSize: "10px" }}
        >
          the Image size should be 950 x 300
        </p>
        <div className="row mt-15 pt-3 position-relative justify-content-center">
          <Textarea
            required={true}
            type="text"
            className="field-common-styling create-event-width"
            name="eventDescription"
            ref={eventDescriptionref}
            value={formData.eventDescription ? formData.eventDescription : ""}
            placeholder={`About Event Description *`}
            handleChange={handleChange}
            showErrorComp={true}
          />
        </div>

        <div className="row mt-15 row justify-content-center">
          <Autocomplete
            multiple
            disableCloseOnSelect
            id="joinedNeighbourhood"
            options={joinedNeignBourhood}
            getOptionLabel={(option) => option.name}
            value={
              joinedNeignBourhood &&
              joinedNeignBourhood.filter(
                (data) => createrId && createrId.includes(data.value)
              )
            }
            onChange={(event, newValue) => {
              setCreaterId([...newValue.map((item) => item.value)]);
            }}
            renderOption={(props, option) => {
              return (
                <li
                  className="community-option-container"
                  key={option.value}
                  {...props}
                >
                  <input
                    className="community-option-container-checkbox"
                    type="checkbox"
                    checked={createrId.includes(option.value)}
                  />
                  <p className="community-option-container-text">
                    {option.name}
                  </p>
                </li>
              );
            }}
            name="joinedNeighbourhood"
            className="field-common-styling bg-white create-event-width"
            renderInput={(params) => (
              <TextField
                {...params}
                className={`${
                  createrId &&
                  createrId.length > 0 &&
                  "autoComplete-textfield-styling-hideplaceHolder"
                } autoComplete-textfield-styling`}
                placeholder={createrId.length > 0 ? "" : "Select A Community *"}
                variant="outlined"
              />
            )}
          />
        </div>

        <div className="row mt-15 justify-content-center">
          <div className="create-event-width justify-content-start">
            <label className="font-20-bold">Where is the event?</label>
          </div>
        </div>

        <div className="row mt-0 justify-content-center">
          <InputField
            type="text"
            className="field-common-styling create-event-width"
            name="eventLink"
            // ref="address1"
            value={formData.eventLink}
            placeholder="Event URL link"
            handleChange={handleChange}
            showErrorComp={true}
          />
        </div>

        <div className="row mt-15 justify-content-center">
          <InputField
            type="text"
            className="field-common-styling create-event-width"
            name="streetAdrress1"
            // ref="address1"
            value={formData.streetAdrress1}
            placeholder="Street Address"
            handleChange={handleChange}
            showErrorComp={true}
          />
        </div>

        <div className="row mt-15 justify-content-center">
          <InputField
            type="text"
            className="field-common-styling create-event-width"
            name="streetAddress2"
            // ref="address1"
            value={formData.streetAddress2}
            placeholder="Street Address"
            handleChange={handleChange}
            showErrorComp={true}
          />
        </div>

        <div className="row mt-15 row justify-content-center">
          <DropDown
            id="id"
            optionId="id"
            optionValue="name"
            name="countryId"
            value={formData.countryId}
            ref={region}
            options={[{ name: "Select Country", value: "" }, ...countyData]}
            handleChange={handleChange}
            className="field-common-styling create-event-width"
            showErrorComp={true}
          />
        </div>

        <div className="row mt-15 mr-auto ml-auto create-event-width justify-content-between">
          <div className="d-flex justify-content-between w-100">
            <InputField
              type="cityName"
              className="field-common-styling mr-4 w-75"
              name="cityName"
              // ref="address1"
              value={formData.cityName}
              placeholder="City"
              showErrorComp={true}
              handleChange={handleChange}
            />

            <DropDown
              id="country_id"
              optionId="id"
              optionValue="name"
              value={formData.stateName}
              name="stateName"
              ref={region}
              options={[{ name: "Select State", new: "" }, ...stateData]}
              showErrorComp={true}
              handleChange={handleChange}
              className="field-common-styling ml-1 w-50"
            />
          </div>
        </div>

        <div className="row mt-15 mr-auto ml-auto create-event-width justify-content-start">
          <div className="d-flex justify-content-start w-75">
            <InputField
              type="text"
              className="field-common-styling w-75"
              name="zipCode"
              // ref="address1"
              value={formData.zipCode}
              placeholder="Zip Code"
              handleChange={handleChange}
              showErrorComp={true}
              checkValue={true}
            />
          </div>
          <InputValidationText
            display={formCheck.zipCodeCheck.value}
            msg={formCheck.zipCodeCheck.msg}
          />
        </div>

        <div className="row mt-15 justify-content-center">
          <div className="create-event-width justify-content-start">
            <label className="font-20-bold">When is the event?</label>
          </div>
        </div>

        <div className="row mt-0 row justify-content-center">
          <DropDown
            id="id"
            optionId="name"
            optionValue="name"
            name="timeZone"
            value={formData.timeZone}
            ref={region}
            options={[{ name: "Timezone *", value: "" }, ...timeZoneData]}
            handleChange={handleChange}
            className="field-common-styling create-event-width"
            showErrorComp={true}
          />
        </div>

        <div className="row mt-15 mb-0 justify-content-center">
          <div className="create-event-width justify-content-start">
            <label className="font-20-bold">
              Select Date and Time of Event*
            </label>
          </div>
        </div>

        <div className="row mt-10 justify-content-center">
          <div className="d-flex justify-content-between create-event-width">
            <div className="w-100">
              <DatePicker
                format="DD-MM-YYYY HH:mm"
                sort
                placeholder="DD-MM-YYYY HH:MM"
                plugins={[
                  <TimePicker
                    className={`${
                      props.isNotSideBar && "react-multi-timePicker"
                    }`}
                    position={props.isNotSideBar ? "bottom" : "right"}
                    hideSeconds
                  />,
                  <DatePanel
                    className={`${
                      props.isNotSideBar && "Custom-width-multiDatePicker"
                    } custom-panal-class`}
                  />,
                ]}
                minDate={moment().toDate()}
                multiple
                value={values}
                className="custom-datepicker-class"
                containerClassName="w-100  p-2"
                inputClass="field-common-styling w-100 mr-1 p-3"
                onChange={(date) => {
                  if (date && date.length > 0) {
                    setValues(date);
                  }
                }}
              />
            </div>
          </div>
        </div>

        <div className="row mt-15 justify-content-center">
          <div className="create-event-width justify-content-start">
            <label className="font-20-bold">Who is going to the event?</label>
          </div>
        </div>

        <div className="row mt-15 justify-content-center">
          <div className=" create-event-width justify-content-between">
            <div className="">
              <input
                type="text"
                className="field-common-styling w-100 form-control"
                name="invitesSpeaker"
                id="invitesSpeaker"
                aria-describedby="helpId"
                placeholder="Speaker"
                onFocus={(e) => {
                  setSpeakerShowInvites(true);
                  setSearch("");
                  e.preventDefault();
                }}
                onChange={(e) => {
                  handleSpeakerSearch(e);
                  e.preventDefault();
                }}
              />

              <div className="invites-container scroll-y">
                {
                  // speakerShowInvites &&
                  // users.filter((item) =>
                  //   speakerSearch.length
                  //     ? item.user_name
                  //         .toLowerCase()
                  //         .includes(speakerSearch.toLowerCase())
                  //     : item
                  // ).length > 0
                  //   ? users
                  //       .filter((item) =>
                  //         speakerSearch.length
                  //           ? item.user_name
                  //               .toLowerCase()
                  //               .includes(speakerSearch.toLowerCase())
                  //           : item
                  //       )
                  isNetworkUsersLoading ? (
                    <div className="w-100">
                      <Skeleton
                        variant="rounded"
                        animation="wave"
                        height={100}
                      />
                    </div>
                  ) : speakerShowInvites && users.length > 0 ? (
                    users.map((item, idx) => {
                      return (
                        <div
                          key={idx}
                          className="d-flex bg-white bg-white align-items-center Profile-InfoCard ml-4 w-75 pl-4 my-2 cursor-pointer"
                          onClick={(event) =>
                            handleClick(event, item, "speaker")
                          }
                        >
                          <div className="d-flex align-items-center">
                            {item.profile_image_url ? (
                              <img
                                src={item.profile_image_url || ""}
                                className="img-fluid rounded-circle invites-image mr-4 "
                                alt=""
                              />
                            ) : (
                              <Avatar
                                name={item && item.user_name}
                                round={true}
                                size={30}
                                textSizeRatio={1.75}
                                color="#007bb3"
                                maxInitials={1}
                                className={"mr-4"}
                              />
                            )}
                            <div>
                              <div className="font-20-bold user-name d-flex align-items-center">
                                {item &&
                                  item.user_name &&
                                  item.user_name.trim()}
                              </div>
                              <div className="font-12 d-flex align-items-center invites-subtext">
                                {item.city ? item.city : ""}
                                {item.city && item.country ? ", " : ""}
                                {item.country ? item.country : ""}
                              </div>
                            </div>
                          </div>
                        </div>
                      );
                    })
                  ) : (
                    speakerShowInvites && <h4>No Records Found</h4>
                  )
                }
              </div>
            </div>
            {!speakerShowInvites &&
              invites.map((item, idx) => {
                return (
                  <div
                    key={idx}
                    className="d-flex align-items-center bg-white justify-content-between mb-3 Profile-InfoCard py-2 w-100 px-4 "
                  >
                    <div className="d-flex align-items-center">
                      {item.profile_image_url ? (
                        <img
                          src={item.profile_image_url || ""}
                          className="img-fluid rounded-circle invites-image mr-4 "
                          alt=""
                        />
                      ) : (
                        <Avatar
                          name={item && item.user_name}
                          round={true}
                          size={30}
                          textSizeRatio={1.75}
                          color="#007bb3"
                          maxInitials={1}
                          className={"mr-4"}
                        />
                      )}
                      <div>
                        <div className="font-20-bold user-name d-flex align-items-center">
                          {item && item.user_name && item.user_name.trim()}
                        </div>
                        <div className="font-12 d-flex align-items-center invites-subtext">
                          {item.city ? item.city : ""}
                          {item.city && item.country ? ", " : ""}
                          {item.country ? item.country : ""}
                        </div>
                      </div>
                    </div>
                    <div
                      onClick={(e) => {
                        handleRemoveValue(idx, "speaker");
                        e.preventDefault();
                      }}
                      className="cursor-pointer"
                    >
                      <CgClose size={36} stroke={5} />
                    </div>
                  </div>
                );
              })}
          </div>
        </div>

        <div className="row mt-15 justify-content-center">
          <div className=" create-event-width justify-content-between">
            <div className="">
              <input
                type="text"
                className="field-common-styling w-100 form-control"
                name="invitesAttendees"
                id="invitesAttendees"
                aria-describedby="helpId"
                placeholder="Invite Attendees"
                onFocus={(e) => {
                  setAttendeesShowInvites(true);
                  setSpeakerSearch("");
                  e.preventDefault();
                }}
                onChange={(e) => {
                  handleAttendeesSearch(e);
                  e.preventDefault();
                }}
              />

              <div className="invites-container scroll-y">
                {
                  // users.filter((item) =>
                  //   search.length
                  //     ? item.user_name
                  //         .toLowerCase()
                  //         .includes(search.toLowerCase())
                  //     : item
                  // ).length > 0
                  //   ? users
                  //       .filter((item) =>
                  //         search.length
                  //           ? item.user_name
                  //               .toLowerCase()
                  //               .includes(search.toLowerCase())
                  //           : item
                  //       )
                  isAttendeesDataLoading ? (
                    <div className="w-100">
                      <Skeleton
                        variant="rounded"
                        animation="wave"
                        height={100}
                      />
                    </div>
                  ) : attendeesShowInvites && attendees.length > 0 ? (
                    attendees.map((item, idx) => {
                      return (
                        <div
                          key={idx}
                          className="d-flex bg-white align-items-center Profile-InfoCard ml-4 w-75 pl-4 my-2 cursor-pointer"
                          onClick={(event) =>
                            handleClick(event, item, "attendees")
                          }
                        >
                          <div className="d-flex align-items-center">
                            {item.profile_image_url ? (
                              <img
                                src={item.profile_image_url || ""}
                                className="img-fluid rounded-circle invites-image mr-4 "
                                alt=""
                              />
                            ) : (
                              <Avatar
                                name={item && item.user_name}
                                round={true}
                                size={30}
                                textSizeRatio={1.75}
                                color="#007bb3"
                                maxInitials={1}
                                className={"mr-4"}
                              />
                            )}
                            <div>
                              <div className="font-20-bold user-name d-flex align-items-center">
                                {item &&
                                  item.user_name &&
                                  item.user_name.trim()}
                              </div>
                              <div className="font-12 d-flex align-items-center invites-subtext">
                                {item.city ? item.city : ""}
                                {item.city && item.country ? ", " : ""}
                                {item.country ? item.country : ""}
                              </div>
                            </div>
                          </div>
                        </div>
                      );
                    })
                  ) : (
                    attendeesShowInvites && <h4>No Records Found</h4>
                  )
                }
              </div>
            </div>
            {!attendeesShowInvites &&
              attendeesInvite.map((item, idx) => {
                return (
                  <div
                    key={idx}
                    className="d-flex align-items-center bg-white justify-content-between mb-3 Profile-InfoCard py-2 w-100 px-4 "
                  >
                    <div className="d-flex align-items-center">
                      {item.profile_image_url ? (
                        <img
                          src={item.profile_image_url || ""}
                          className="img-fluid rounded-circle invites-image mr-4 "
                          alt=""
                        />
                      ) : (
                        <Avatar
                          name={item && item.user_name}
                          round={true}
                          size={30}
                          textSizeRatio={1.75}
                          color="#007bb3"
                          maxInitials={1}
                          className={"mr-4"}
                        />
                      )}
                      <div>
                        <div className="font-20-bold user-name d-flex align-items-center">
                          {item && item.user_name && item.user_name.trim()}
                        </div>
                        <div className="font-12 d-flex align-items-center invites-subtext">
                          {item.city ? item.city : ""}
                          {item.city && item.country ? ", " : ""}
                          {item.country ? item.country : ""}
                        </div>
                      </div>
                    </div>
                    <div
                      onClick={(e) => {
                        handleRemoveValue(idx, "attendees");
                        e.preventDefault();
                      }}
                      className="cursor-pointer"
                    >
                      <CgClose size={36} stroke={5} />
                    </div>
                  </div>
                );
              })}
          </div>
        </div>
      </div>
    </>
  );
}

export default withRouter(CreateEvent);
