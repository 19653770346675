import { Chip, IconButton } from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import React, { useEffect, useState } from "react";
import { CloseChip, CloseIcon } from "../../../../icons/icons";
import Avatar from "react-avatar";
import UserChip from "./UserChip";
import {
  GROUPS,
  GROUP_MEMBERS,
} from "../../../../../config/constants/api_endpoints";
import { Utility } from "utils";
import { CLIENT_ID, CRM_MODULES } from "config/constants/config";
import { useDispatch, useSelector } from "react-redux";
import { showLoader, updateIsChatFormOpen } from "../../../../actions";
import { toast } from "react-toastify";
import MessageFeedSection from "./MessageFeedSection";
import { Button } from "react-bootstrap";
import { useMediaQuery } from "@material-ui/core";

const ChatLanding = (props) => {
  const HandleDelete = (id) => {
    props.removeMember(id);
  };
  const userData = useSelector((state) => state.userData);
  const dispatch = useDispatch();
  const [groupName, setGroupName] = useState();
  const isTab = useMediaQuery("(max-width:840px)");
  const AddGroupMembers = (data) => {
    const params = {
      groupId: data.id,
      userClientIds: props.groupMembersData.map((users) => users.id),
      status: "approved",
    };

    Utility.sendRequest(GROUP_MEMBERS, 2, params, (err, res, body) => {
      body = JSON.parse(body);
      if (body.error) {
        toast.error(body.error.message, { containerId: "private" });
      } else {
        if (body.data) {
          dispatch(showLoader(false));
          toast.success("You have successfully joined this group", {
            containerId: "private",
          });
          props.setGroupMembersData([]);
          scrollToBottom();
        } else {
          dispatch(showLoader(true));
          toast.success(body.message, { containerId: "private" });
          props.setActiveScreenName("Chat");
          props.setActiveChatDetail(data);
        }
      }
    });
  };

  const scrollToBottom = () => {
    let containerDiv = document.getElementById("commentsContainer");
    containerDiv.scrollTop = containerDiv.scrollHeight + 100;
  };
  const createGroup = (event) => {
    event.preventDefault();
    const payload = {
      clientId: CLIENT_ID,
      groupDescription: " ",
      groupName: groupName,
      is_private: true,
      userClientId: userData.id,
    };
    Utility.sendRequest(GROUPS, 2, payload, async (err, res, body) => {
      body = JSON.parse(body);

      dispatch(showLoader(true));
      if (body.error) {
        dispatch(showLoader(false));
      } else {
        props.getGroups();
        AddGroupMembers(body.data);
      }
    });
  };

  return (
    <div className="h-100 CommunitySection-right">
      <div className="d-flex">
        <div className="CommunitySection-right-header pl-3">Messages</div>
        <Button
          variant="link"
          className="color-primary ml-auto pl-3 text-left align-items-center"
          onClick={() => {
            dispatch(updateIsChatFormOpen(false));
          }}
        >
          {!isTab && (
            <span className="display-7 font-bold">
              <CloseIcon />
            </span>
          )}
        </Button>
      </div>

      <div className="SeactionSearchContainer mt-20 pl-3 justify-content-start">
        <div className="GroupUsersSectionWrapper AdduserToGroupInput py-1 w-100">
          <div readOnly className="SeactionSearch d-flex align-items-center ">
            <p className="d-flex align-items-center m-0">To:</p>
            <div className="UserchipContainer">
              {props.groupMembersData &&
                props.groupMembersData.length > 0 &&
                props.groupMembersData.map((items) => {
                  return (
                    <UserChip
                      items={items}
                      HandleDelete={() => HandleDelete(items.id)}
                    />
                  );
                })}
            </div>
          </div>
          <span className="SeactionSearchIcon ml-auto">
            {props.groupMembersData && props.groupMembersData.length <= 1 && (
              <IconButton
                onClick={() => {
                  props.setActiveScreenName("Chat");
                  props.setActiveChatDetail(props.groupMembersData[0]);
                }}
                disabled={
                  !(props.groupMembersData && props.groupMembersData.length > 0)
                }
                className={`${
                  !(
                    props.groupMembersData && props.groupMembersData.length > 0
                  ) && "disabledButton"
                } CommonIconButton `}
              >
                <AddIcon />
              </IconButton>
            )}
          </span>
        </div>
      </div>
      {props.groupMembersData && props.groupMembersData.length > 1 && (
        <div className="SeactionSearchContainer mt-3 pl-3 justify-content-start">
          <div className="GroupUsersSectionWrapper AdduserToGroupInput py-1 w-100">
            <input
              onChange={(event) => setGroupName(event.target.value)}
              className="SeactionSearch GroupNameInput searchSectionWrapper py-1 w-80"
              placeholder="Enter Groupchat Name (e.g. Prayer Group)"
            />
            <span className="SeactionSearchIcon ml-auto">
              {props.groupMembersData && props.groupMembersData.length > 1 && (
                <IconButton
                  onClick={createGroup}
                  disabled={!groupName}
                  className={`${
                    !groupName && "disabledButton"
                  } CommonIconButton `}
                >
                  <AddIcon />
                </IconButton>
              )}
            </span>
          </div>
        </div>
      )}
      <div className="pl-3 MessageSectionBody scroll-y" id="commentsContainer">
        <MessageFeedSection {...props} />
      </div>
    </div>
  );
};

export default ChatLanding;
