import React, { Component }  from 'react';
import { MdChevronLeft, MdChevronRight } from 'react-icons/md';
import { Button } from 'react-bootstrap';

export default class Slider extends Component {
  constructor(props) {
    super(props);

    this.state = {
      images: [
        "marketplace_bg_1.png",
        "marketplace_bg_2.png",
        "marketplace_bg_1.png",
      ],
      currentIndex: 0,
      translateValue: 0
    };
  }

  goToPrevSlide = () => {
    if(this.state.currentIndex === 0)
      return;

    this.setState(prevState => ({
      currentIndex: prevState.currentIndex - 1,
      translateValue: prevState.translateValue + this.slideWidth()
    }));
  }

  goToNextSlide = () => {
    // Exiting the method early if we are at the end of the images array.
    // We also want to reset currentIndex and translateValue, so we return
    // to the first image in the array.
    if(this.state.currentIndex === this.state.images.length - 1) {
      return this.setState({
        currentIndex: 0,
        translateValue: 0
      });
    }

    // This will not run if we met the if condition above
    this.setState(prevState => ({
      currentIndex: prevState.currentIndex + 1,
      translateValue: prevState.translateValue + -(this.slideWidth())
    }));
  }

  slideWidth = () => {
    return document.querySelector('.mp-slide').clientWidth;
  }

  render() {
    return (
      <div className="mp-slider">
        <div className="mp-slider-wrapper"
          style={{
            transform: `translateX(${this.state.translateValue}px)`,
            transition: 'transform ease-out 0.45s'
          }}>
            {
              this.state.images.map((image, i) => (
                <Slide key={i} image={image} />
              ))
            }
        </div>

        <LeftArrow
         goToPrevSlide={this.goToPrevSlide}
        />

        <RightArrow
         goToNextSlide={this.goToNextSlide}
        />
      </div>
    );
  }
}

const Slide = ({ image }) => {
  const styles = {
    backgroundImage: `url(${require('assets/images/tools/market_place/' + image)})`,
  };
  return(
    <div className="mp-slide" style={styles}>
      <div className="flex h-100">
        <div className="mp-banner-text text-white">
          <h2>Build App</h2>
          <p>Sed nec vehicula sem. Etiam nec volutpat massa. Fusce tempor egestas sem.</p>
        </div>
        <div className="mt-auto ml-auto">
          <Button varaint="primary" >Get Stated</Button>
        </div>
      </div>
    </div>
  );
};

const LeftArrow = (props) => {
  return (
    <div className="mp-backArrow mp-arrow" onClick={props.goToPrevSlide}>
      <MdChevronLeft />
    </div>
  );
};

const RightArrow = (props) => {
  return (
    <div className="mp-nextArrow mp-arrow" onClick={props.goToNextSlide}>
      <MdChevronRight />
    </div>
  );
};
