import React from 'react';
import { SingleCampaign } from './single_campaign';
import { Utility } from 'utils';
import { NoRecordFound } from 'shared/components/no_record_found';

export const DropdownInside = (props) => {
  return(
    <>
      {
        (!props.isSearch && Utility.isEmpty(props.campaignsList))
        && <NoRecordFound />
      }

      {
        !Utility.isEmpty(props.campaignsList) &&
        props.campaignsList.map(e => {
          return(
            <SingleCampaign
              key={e.id}
              id={e.id}
              imageUrl={e.image_url || e.embedded_image_link}
              videoUrl={e.video_url || e.embedded_video_link}
              heading={e.heading}
              createdAt={e.created_at}
              handleClick={props.handleEditClick}
            />
          );
        })
      }

      { props.isSearch &&
        <SingleCampaign
          imageUrl={props.parent_image_url}
          heading={props.parent_heading || props.newCampaign.heading}
          createdAt={props.newCampaign.created_at}
          handleClick={props.handleCreateClick}
        />
      }
    </>
  );
};
