import { ClassNames } from "@emotion/react";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { WEA_QUALITY_MASTER } from "../../../config/constants/api_endpoints";
import * as Utility from "utils/utility";
import { showLoader } from "shared/actions";
import { Autocomplete, Skeleton, TextField } from "@mui/material";
import { ROLE_IDS } from "../../../config/constants";
import HighlightOffIcon from "@mui/icons-material/HighlightOff";

const GodCalling = ({
  heading,
  section,
  isProfile,
  isPublic,
  GodQualities,
  subHeading,
  selectedGodQualities,
  isEditing = true,
  addSelectedQualities,
  classtoOverride,
  removeSelectedQualities,
  user,
  from,
  allQualities,
  allGodUserQualities,
  setSelectedValue,
  selectedValue,
  newSelected,
  setNewSelected,
  loading,
}) => {
  const userQualitiesData = useSelector((state) => state.userQualities);
  const [allGodQualities] = useState([]);
  const handleChange = (event, values) => {
    setSelectedValue(values);
    if (setNewSelected) setNewSelected(values);
  };

  useEffect(() => {
    allQualities &&
      allQualities.map((items) => {
        if (!allGodQualities.find((item) => item.name === items.title))
          allGodQualities.push({
            value: items.id,
            name: items.title,
            section,
          });
        return allGodQualities;
      });
  }, [allQualities]);

  const removeNewSelected = (id) => {
    setNewSelected(newSelected.filter((val) => val.value !== id));
    setSelectedValue(newSelected.filter((val) => val.value !== id));
  };

  return loading ? (
    <div className="AboutMainContainer d-flex">
      {[1, 2, 3, 4, 5].map((item) => (
        <Skeleton variant="rectangular" width={160} height={50} className="mr-2 rounded"/>
      ))}
    </div>
  ) : (
    (selectedGodQualities.length > 0 || isEditing) && (
      <div
        className={`AboutMainContainer ff-Inter-Bold overflow-hidden ${classtoOverride}`}
      >
        <p className="section-head">{heading}</p>
        {isPublic && (
          <p className="section-subHead">
            You have{" "}
            {selectedGodQualities
              ? selectedGodQualities.filter(
                  (items) =>
                    userQualitiesData &&
                    userQualitiesData.some(
                      (data) =>
                        data.id === items.id && data.section === items.section
                    )
                ).length
              : 0}{" "}
            common areas with {user}
          </p>
        )}
        {from === "edit" && (
          <div className="posted-by-container w-100">
            <Autocomplete
              multiple
              disableCloseOnSelect
              id="joinedNeighbourhood"
              options={allGodQualities}
              value={selectedValue}
              getOptionLabel={(option) => option.name}
              onChange={handleChange}
              renderOption={(props, option) => {
                return (
                  <li
                    className="community-option-container"
                    // key={option.value}
                    {...props}
                  >
                    <input
                      className="community-option-container-checkbox"
                      type="checkbox"
                      checked={selectedValue.includes(option)}
                    />
                    <p className="community-option-container-text">
                      {option.name}
                    </p>
                  </li>
                );
              }}
              name="joinedNeighbourhood"
              className="field-common-styling ml-0 w-100"
              renderInput={(params) => (
                <TextField
                  {...params}
                  className={`${"autoComplete-textfield-styling-hideplaceHolder"} autoComplete-textfield-styling`}
                  placeholder="Select a option"
                  variant="outlined"
                />
              )}
            />
          </div>
        )}
        <div className="d-flex flex-wrap">
          {!isPublic
            ? GodQualities &&
              GodQualities.length !== 0 &&
              GodQualities.map(({ id, title }) => {
                return (
                  <div
                    key={id}
                    className={`qualitySelectorContainer
                     ${isEditing && "cursor-default"}
                     ${
                       selectedGodQualities &&
                       selectedGodQualities.length > 0 &&
                       selectedGodQualities.find((data) => data.id === id)
                         ? `selected `
                         : `unSelected`
                     }`}
                  >
                    <div className="d-flex justify-content-between">
                      <div>{title}</div>
                      {from === "edit" && (
                        <div
                          onClick={() => {
                            {
                              selectedGodQualities &&
                                selectedGodQualities.length > 0 &&
                                selectedGodQualities.find(
                                  (data) => data.id === id
                                ) &&
                                removeSelectedQualities(id, section);
                            }
                          }}
                        >
                          <HighlightOffIcon className="text-white  cursor-pointer ml-2" />
                        </div>
                      )}
                    </div>
                  </div>
                );
              })
            : selectedGodQualities &&
              selectedGodQualities.length !== 0 &&
              selectedGodQualities.map(({ id, title }) => {
                return (
                  <div
                    className={`qualitySelectorContainer cursor-default"} ${
                      userQualitiesData &&
                      userQualitiesData.length > 0 &&
                      userQualitiesData.find(
                        (data) => data.id === id && data.section === section
                      )
                        ? `selected `
                        : `unSelected`
                    }`}
                  >
                    {title}
                  </div>
                );
              })}
          {!isPublic &&
            newSelected &&
            newSelected.length != 0 &&
            newSelected.map(({ value, name }) => {
              return (
                <div key={value} className="qualitySelectorContainer selected">
                  <div className="d-flex justify-content-between">
                    <div>{name}</div>
                    {from === "edit" && (
                      <div
                        onClick={() => {
                          {
                            removeNewSelected(value);
                          }
                        }}
                      >
                        <HighlightOffIcon className="text-white  cursor-pointer ml-2" />
                      </div>
                    )}
                  </div>
                </div>
              );
            })}
        </div>
      </div>
    )
  );
};

export default GodCalling;
