import React, { Component } from 'react';
import { Utility } from 'utils';
import { connect } from 'react-redux';
import { ACTIVATE_USER, LOGIN, USER_AUTH } from 'config/constants/api_endpoints.js';
import { toast } from 'react-toastify';
import { updateUserData } from 'shared/actions';
import Welcome from 'shared/components/welcome';
import cookie from 'react-cookies';

class ActivateUser extends Component {
  constructor(props) {
    super(props);

    const obj = this.filterParams(props);

    this.state = {
      message: obj.message,
      formData: obj.formData,
      autoLoginParams: obj.autoLoginParams || {},
      isActivated: false,
      showLoader: true,
    };
  }

  componentDidMount = () => {
    this.activateUser();
  }

  filterParams = (props) => {
    const obj = {
      message: 'Invalid Request',
      formData: {}
    };
    const search = props.location.search;
    const searchHash = new URLSearchParams(search);

    if (!Utility.isEmpty(search) && searchHash.has('u') && searchHash.has('secure_token')) {
      obj.formData.user_id = searchHash.get('u');
      obj.formData.secure_token = searchHash.get('secure_token');
      obj.message = '';
    }

    if (searchHash.has('alt')) obj.autoLoginParams = { alt: searchHash.get('alt') };

    return obj;
  }

  activateUser = () => {
    if (!Utility.isEmpty(this.state.formData.user_id) && !Utility.isEmpty(this.state.formData.secure_token)) {
      Utility.sendRequest(ACTIVATE_USER, 2, this.state.formData, (error, response, body) => {
        body = JSON.parse(body);
        if (body.error) {
          this.setState({ message: body.error.message, showLoader: false });
          toast.error(body.error.message, { containerId: 'public' });
        } else {
          this.setState({ message: body.message, isActivated: true, showLoader: false });
          Utility.clearUserSession();
          toast.success(body.message, { containerId: 'public' });
        }
      });
    } else {
      toast.error(this.state.message, { containerId: 'public' });
    }
  }

  sendToHome = (e) => {
    e.preventDefault();

    if (this.state.isActivated && !Utility.isEmpty(this.state.autoLoginParams)) {
      Utility.sendRequest(LOGIN, 2, this.state.autoLoginParams, (error, response, body) => {
        body = JSON.parse(body);
        if (body.error) {
          toast.error(body.error.message, { containerId: 'public' });
        } else {
          cookie.save('sessionToken', body.token, { path: '/' });
          Utility.sendRequest(USER_AUTH, 1, body.token, (error, response, body) => {
            body = JSON.parse(body);
            if (!body.error) {
              this.props.updateUserData(body);
              this.props.history.push('/profile');
            } else {
              toast.error(body.error.message, { containerId: 'public' });
            }
          });
        }
      });
    } else {
      this.props.history.push('/users/sign_in');
    }
  }

  render() {
    return (
      <Welcome
        isActivated={this.state.isActivated}
        showLoader={this.state.showLoader}
        message={this.state.message}
        submit={this.sendToHome}
      />
    );
  }
}

const mapActionToProps =  { updateUserData };
const mapStateToProps = (state) => ({
  userData: state.userData
});
export default connect(mapStateToProps, mapActionToProps)(ActivateUser);
