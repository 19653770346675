import React, { useEffect, useState } from "react";
import ShareMenu from "./shareMenu";
import Avatar from "react-avatar";
import moment from "moment-timezone";
import { useSelector } from "react-redux";
import * as Utility from "utils/utility";
import { toast } from "react-toastify";
import {
  EVENT_MEMBERS,
  EVENT_REMOVE,
  MEMBER_ALL_EVENT,
} from "../../../../../config/constants/api_endpoints";
import { CircularProgress } from "@mui/material";
import { ROLE_IDS } from "../../../../../config/constants";
const DetailsContainer = (props) => {
  const [allMyEvents, setAllMyEvents] = useState([]);
  const [isMember, setIsMember] = useState(false);
  const userData = useSelector((state) => state.userData);
  const [Loader, setLoader] = useState(false);
  useEffect(() => {
    getMyEvents();
  }, []);

  useEffect(() => {
    if (allMyEvents && props.eventDetails && props.eventDetails.campaign_id) {
      setIsMember(
        allMyEvents.some((item) => item.id === props.eventDetails.campaign_id)
      );
    }
  }, [allMyEvents, props.eventDetails]);

  const startDate = props.eventDetails.start_at
    ? new Date(props.eventDetails.start_at)
    : new Date();
  const endDate = props.eventDetails.end_at
    ? new Date(props.eventDetails.end_at)
    : new Date();
  const getTime = (date) => {
    return date.toLocaleString("en-US", {
      hour: "numeric",
      minute: "numeric",
      hour12: true,
    });
  };

  const formatDate = (date) => {
    return (
      date.toLocaleString("default", { month: "short" }) +
      " " +
      date.getDate() +
      " " +
      date.getFullYear() +
      " " +
      getTime(date)
    );
  };

  const DateComponent = ({ month, day }) => (
    <>
      <p className="mb-1 b-700">{month}</p>
      <p className="b-700">{day}</p>
    </>
  );

  const getMyEvents = () => {
    const params = {};

    setLoader(true);
    Utility.sendRequest(
      `${MEMBER_ALL_EVENT}/${userData.id}`,
      1,
      params,
      (err, res, body) => {
        body = JSON.parse(body);
        if (body.error) {
          toast.error(body.error.message, { containerId: "private" });
          setLoader(false);
        } else {
          // For Setting My Events data
          setLoader(false);
          setAllMyEvents(
            body.filter(
              (items) =>
                items.corporate_is_active === true ||
                items.corporate_is_active === null
            )
          );
        }
      }
    );
  };

  const eventDateFilter = () => {
    const arrayDate = props.eventDetails.campaigns_dates;
    let filterDate = [];
    let currentDate = moment().format("YYYY-MM-DD");

    if (arrayDate) {
      filterDate = arrayDate.filter((date) => {
        let endDate = moment(moment(date).format("YYYY-MM-DD"));
        let days = endDate.diff(currentDate, "days");
        return days >= 0 && date;
      });
    }

    return arrayDate ? (
      filterDate.length > 0 ? (
        <DateComponent
          month={moment(filterDate[0]).format("MMM")}
          day={moment(filterDate[0]).format("DD")}
        />
      ) : (
        <DateComponent
          month={moment(arrayDate[arrayDate.length - 1]).format("MMM")}
          day={moment(arrayDate[arrayDate.length - 1]).format("DD")}
        />
      )
    ) : (
      <DateComponent
        month={getMonthName(startDate)}
        day={startDate.getDate()}
      />
    );
  };

  const sendRSVP = async () => {
    const params = {
      campaignId: props.eventDetails.campaign_id,
      userClientIds: [userData.id],
      status: "approved",
      is_active: true,
      memberId: userData.id,
    };
    setLoader(true);
    await Utility.sendRequest(EVENT_MEMBERS, 2, params, (err, res, body) => {
      body = JSON.parse(body);

      if (body.error) {
        toast.error(body.error.message, { containerId: "private" });
      } else {
        if (body.data) {
          toast.success('The event has been added to "My Events"', {
            containerId: "private",
          });
          setIsMember(true);
          props.getEventMember();
          // dispatch(updateRefreshEventsAPI(Math.random()));
        }
      }
      setLoader(false);
    });
  };

  const removeRSVP = async () => {
    const params = {
      campaign_id: props.eventDetails.campaign_id,
      user_client_id: [userData.id],
    };
    setLoader(true);
    await Utility.sendRequest(EVENT_REMOVE, 2, params, (err, res, body) => {
      body = JSON.parse(body);

      if (body.error) {
        toast.error(body.error.message, { containerId: "private" });
      } else {
        if (body.data) {
          toast.success('The event has been removed from "My Events"', {
            containerId: "private",
          });
          setIsMember(false);
          setLoader(false);
          props.getEventMember();
          // dispatch(updateRefreshEventsAPI(Math.random()));
        }
      }
    });
  };

  const getMonthName = (date) => {
    return date
      .toLocaleString("default", { month: "short" })
      .toLocaleUpperCase();
  };

  const dateFormating = () => {
    return props.eventDetails.campaigns_dates.map((date, i) => {
      return (
        <div className="buttletccss">
          {moment(date)
            .tz(props.eventDetails.campaigns_timezone.split(" (")[0])
            .format("MMM DD YYYY HH:mm")}
        </div>
      );
    });
  };
  return (
    <div className="eLeftPanelDescrp dynamic_height">
      {props.eventDetails && props.eventDetails.image_url ? (
        <div className="w-100 detailContainerImage">
          <img
            style={{ height: "100%" }}
            src={props.eventDetails.image_url}
            className=""
            alt="race"
          />
        </div>
      ) : (
        <div className="w-100 detailContainerImage d-flex align-items-center justify-content-center">
          <Avatar
            name={props.eventDetails.heading}
            round={true}
            size={"200"}
            textSizeRatio={1.75}
            color="#007bb3"
            maxInitials={1}
            // className={"w-100 detailContainerImage"}
          />
        </div>
      )}
      <div className="d-flex">
        <div className="eLeftPanelDateBox">
          <div className="additional-card-date text-center py-2 px-3 lh-1 eventDateBox">
            {/* <p className="mb-1 b-700">{getMonthName(startDate)}</p>
            <p className="b-700">{startDate.getDate()}</p> */}
            {eventDateFilter()}
          </div>
        </div>
        <div className="eLeftPanelDetailsBox">
          <div>
            <div className="d-flex dateAtimecss">
              <img
                src={require("assets/images/icons/clock.svg")}
                className="mr-13px"
                alt="clock"
              />
              <p className="mt-auto mb-auto">
                {/* {formatDate(startDate) + " - " + formatDate(endDate)} */}
                {props.eventDetails.campaigns_dates &&
                props.eventDetails.campaigns_timezone ? (
                  dateFormating()
                ) : (
                  <>{formatDate(startDate) + " - " + formatDate(endDate)}</>
                )}
              </p>
            </div>
            {/* <p className="ml-5 font-weight-bold">
              Timezone :{" "}
              {props.eventDetails.campaigns_timezone &&
                props.eventDetails.campaigns_timezone.split(' (')[0]}
            </p> */}
            {props.eventDetails && props.eventDetails.meeting_url && (
              <div className="d-flex mt-13px">
                <img
                  src={require("assets/images/icons/chain.svg")}
                  className="mr-13px"
                  alt="chain"
                />
                <p className="mt-auto mb-auto">
                  {props.eventDetails.meeting_url}
                </p>
              </div>
            )}
            {props.eventDetails &&
              props.eventDetails.location &&
              props.eventDetails.location.replaceAll("%^splt^%", "").trim() && (
                <div className="d-flex mt-13px">
                  <img
                    src={require("assets/images/icons/location.svg")}
                    className="mr-13px"
                    alt="location"
                  />
                  <p className="mt-auto mb-auto">
                    {props.eventDetails.location
                      .replaceAll("%^splt^%", ",")
                      .replaceAll("undefined", "")
                      .replaceAll(", ,", ",")}
                  </p>
                </div>
              )}
            {props.eventDetails && props.eventDetails.region_name && (
              <div className="d-flex mt-13px">
                <img
                  src={require("assets/images/icons/oval.svg")}
                  className="mr-13px"
                  alt="oval"
                />
                <p className="mt-auto mb-auto">
                  {props.eventDetails.region_name}
                </p>
              </div>
            )}
          </div>
          <div className="d-flex align-items-end attend-button-row">
            {[
              ROLE_IDS.USER,
              ROLE_IDS.PARENT_ADMIN,
              ROLE_IDS.PARENT_OWNER,
            ].includes(userData.role_id) &&
              props.eventDetails &&
              props.eventDetails.meeting_url && (
                <button
                  className="btn btn-primary p-3 course-button attend-button"
                  onClick={(e) => {
                    e.stopPropagation();
                    window.open(props.eventDetails.meeting_url, "_blank");
                  }}
                >
                  ATTEND
                </button>
              )}
            {[
              ROLE_IDS.USER,
              ROLE_IDS.PARENT_ADMIN,
              ROLE_IDS.PARENT_OWNER,
            ].includes(userData.role_id) && (
              <>
                {Loader ? (
                  <div className="rsvpLoaderContainer">
                    <CircularProgress />
                  </div>
                ) : (
                  <>
                    {isMember ? (
                      <button
                        className="btn rsvpBtn rmbutton p-3 course-button attend-button"
                        onClick={(e) => {
                          e.preventDefault();
                          removeRSVP();
                        }}
                      >
                        REMOVE
                      </button>
                    ) : (
                      <button
                        className="btn rsvpBtn btn-primary p-3 course-button attend-button"
                        onClick={(e) => {
                          e.preventDefault();
                          sendRSVP();
                        }}
                      >
                        RSVP
                      </button>
                    )}
                  </>
                )}
              </>
            )}
            <div
              className={
                props.eventDetails.meeting_url == null ? "mt-4" : "mr-3"
              }
            >
              <ShareMenu userData={props.userData} />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default DetailsContainer;
