import React, { Component } from "react";
import { NavLink } from "react-router-dom";
import { HashLink as Link } from "react-router-hash-link";
import { Navbar, Nav } from "react-bootstrap";

export class HomeNavbar extends Component {

  showMenu = (event, isToggle = false) => {
    const body = document.getElementsByTagName("body")[0];
    isToggle
      ? body.classList.toggle("js-no-scroll")
      : body.classList.remove("js-no-scroll");
    document.getElementsByClassName("navbar-toggler-icon")[0].click();
  };

  render() {
    return (
      <header className="gil-header">
        <Navbar
          collapseOnSelect
          expand="xl"
          className="shar-navbar d-flex align-items-center display-7 font-bold px-xl-5 py-xl-3 p-3 navbar-fixed"
        >
          <NavLink to={"/"} className="w-md-100">
            <img
              src={require("assets/images/logos/main-logo.png")}
              alt="Gil Logo"
              className="home-nav-logo"
            />
          </NavLink>
          <Navbar.Toggle
            className="gil-landing-toggler"
            aria-controls="responsive-navbar-nav"
            onClick={() => this.showMenu(true)}
          />
          <Navbar.Collapse id="responsive-navbar-nav">
            <div className="navbar-outer d-flex align-items-xl-center">
              <Nav className="mx-xl-auto">
                <ul className="list-unstyled d-flex navbar-menu-list pt-3 pt-xl-0 display-md-6">
                  <li className="mb-3 mb-xl-0">
                    <Link
                      exact
                      to={"/#purpose"}
                      className="mr-xl-4 mr-xl-3"
                      activeClassName="nav-link-active"
                      onClick={this.showMenu}
                    >
                      Purpose
                    </Link>
                  </li>
                  <li className="mb-3 mb-xl-0">
                    <Link
                      exact
                      to={"/#peerLearning"}
                      className="mr-xl-4 mr-xl-3"
                      onClick={this.showMenu}
                    >
                      Peer Learning
                    </Link>
                  </li>
                  <li className="mb-3 mb-xl-0">
                    <Link
                      exact
                      to={"/#members"}
                      className="mr-xl-4 mr-xl-3"
                      onClick={this.showMenu}
                    >
                      Members
                    </Link>
                  </li>
                  <li className="mb-3 mb-xl-0">
                    <Link
                      exact
                      to={"/#partners"}
                      className="mr-xl-4 mr-xl-3"
                      onClick={this.showMenu}
                    >
                      Partners
                    </Link>
                  </li>
                  <li className="mb-3 mb-xl-0">
                    <Link
                      exact
                      to={"/#upcomingEvents"}
                      className="mr-xl-4 mr-xl-3"
                      onClick={this.showMenu}
                    >
                      Upcoming Events
                    </Link>
                  </li>
                  <li className="mb-3 mb-xl-0">
                    <Link exact to={"/#about"} onClick={this.showMenu}>
                      About
                    </Link>
                  </li>
                </ul>
              </Nav>
              <div className="shar-navbar-links align-items-center mt-auto mt-xl-0 mb-5 mb-xl-0">
                <NavLink
                  to={"/users/sign_in"}
                  className="font-bold mr-xl-5 mr-xl-3 mb-3 mb-xl-0 font-15 text-uppercase"
                  onClick={this.showMenu}
                >
                  Login
                </NavLink>
                <NavLink
                  to={"/users/sign_up"}
                  className="btn btn-md  btn-primary display-md-6 text-uppercase font-16 font-weight-bold"
                  onClick={this.showMenu}
                >
                  Register
                </NavLink>
              </div>
            </div>
          </Navbar.Collapse>
        </Navbar>
      </header>
    );
  }
}
