import React from "react";
import ShareMenu from "../../EventDetails/eventLeftPanel/detailsContainer/shareMenu";
import ReactPlayer from "react-player";

const NewsBanner = ({ data, userData }) => {
  const paintCreaterName = () => {
    if (data) {
      if (data.region_name && data.creator_name)
        return data.region_name + "/" + data.creator_name;
      else if (data.region_name) return data.region_name;
      else if (data.creator_name) return data.creator_name;
    }
  };

  return (
    <div className="react-player-box d-flex bg-white">
      <div className="react-player-video border-news-card">
        {/* <ReactPlayer
          url="https://www.youtube.com/watch?v=KuDWifo1q1U"
          controls={true}
          width="100%"
          height="100%"
        /> */}
        <img
          src={data.image_url}
          alt={data.heading}
          className="campaign-banner-img"
        />
      </div>
      <div className="react-player-content">
        <p className="display-5 b-700">{data.heading}</p>
        {/* <p className="b-400">{data.description}</p> */}
        <p
          className="description-scroll"
          dangerouslySetInnerHTML={{
            __html: data.description,
          }}
        />

        <div className="d-flex">
          <img
            src={require("assets/images/icons/blue-oval.svg")}
            className="mr-2"
            alt="blue-oval"
          />
          <p className="mt-auto mb-auto">{paintCreaterName()}</p>
        </div>

        <div className="news-share-button">
          <ShareMenu userData={userData} />
        </div>
      </div>
    </div>
  );
};

export default NewsBanner;
