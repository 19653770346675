import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { MdKeyboardArrowLeft, MdKeyboardArrowRight } from "react-icons/md";
import {
  DropArrow,
  CommunityIcon,
  CoursesIcon,
  DiscussionsIcon,
  EventIcon,
  GroupIcon,
  NewsIcon,
} from "../../../shared/icons/icons";

const RegionSidebar = ({ setSelectedIcon }) => {
  const [isExpanded, setIsExpanded] = useState(false);
  const sidebarData = [
    { id: 0, name: "Discussions", icon: <DiscussionsIcon />, link: "#" },
    { id: 1, name: "Courses", icon: <CoursesIcon />, link: "#" },
    { id: 2, name: "News", icon: <NewsIcon />, link: "#" },
    { id: 3, name: "Events", icon: <EventIcon />, link: "#" },
    { id: 4, name: "Community", icon: <CommunityIcon />, link: "#" },
  ];

  const handleSelectedItem = (menuItem) => {
    setSelectedIcon(menuItem);
    var Sidebar = document.getElementById("Sidebar");
    var sidebarItems = Sidebar.getElementsByClassName("sidebar-items");
    for (var i = 0; i < sidebarItems.length; i++) {
      if (sidebarItems[i].id !== menuItem)
        sidebarItems[i].classList.add("sidebarItemBorderLeft");
    }
    document.getElementById(menuItem).classList.remove("sidebarItemBorderLeft");
  };

  useEffect(() => {
    handleSelectedItem(0);
  }, []);

  const handleClick = (state) => {
    setIsExpanded(state);
  };

  return (
    <>
      <div
        className={
          isExpanded
            ? "Sidebar regionSidebar"
            : "Sidebar collapsed regionSidebar"
        }
        id="Sidebar"
      >
        {sidebarData.map(({ id, name, icon, link }) => {
          return (
            <div
              className="sidebar-items sidebarItemBorderLeft"
              id={id}
              key={id}
            >
              <div className="item">
                <abbr title={name}>
                  <Link
                    to={link}
                    onClick={() => {
                      handleSelectedItem(id);
                    }}
                  >
                    <div className="row align-items-center">
                      {isExpanded ? <DropArrow /> : ""}
                      <div className={isExpanded ? "px-1" : "px-3"}>
                        <div className="region-Icon">{icon}</div>
                      </div>
                      <div
                        className={
                          isExpanded
                            ? "d-block col-6 font-weight-bold"
                            : "d-none"
                        }
                      >
                        {name}
                      </div>
                    </div>
                  </Link>
                </abbr>
              </div>
            </div>
          );
        })}
        <div className="p-4">
          <div className="">
            {isExpanded ? (
              <Link className="text-dark" onClick={() => handleClick(false)}>
                <div className="row align-items-center">
                  <MdKeyboardArrowLeft className="sidebarIcon" />

                  <div className={isExpanded ? "d-inline col-8" : "d-none"}>
                    <small>Close Sidebar</small>
                  </div>
                </div>
              </Link>
            ) : (
              <Link className="text-dark" onClick={() => handleClick(true)}>
                <div className="row align-items-center justify-content-center">
                  {" "}
                  <MdKeyboardArrowRight className="sidebarIcon" />
                </div>
              </Link>
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export default RegionSidebar;
