import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { DatePickerInput } from "./datepicker_input";
import { useEffect, useState } from "react";
import React from "react";

export const DesktopDatePicker = ({
  value,
  setValue,
  minDate,
  maxDate,
  setIsChanged,
  isEmpty,
}) => {
  const [open, setOpen] = useState(false);

  return (
    <LocalizationProvider dateAdapter={AdapterDateFns}>
      <DatePicker
        className="desktop-datepicker"
        value={value}
        open={open}
        minDate={minDate && new Date(minDate)}
        maxDate={maxDate && new Date(maxDate)}
        closeOnSelect
        onClose={() => setOpen(false)}
        onChange={(newValue) => {
          setValue(newValue);
          setIsChanged(true);
        }}
        renderInput={({ inputRef, inputProps, InputProps }) => (
          <DatePickerInput
            inputRef={inputRef}
            inputProps={inputProps}
            InputProps={InputProps}
            isMobile={false}
            setOpen={setOpen}
            isEmpty={isEmpty}
          />
        )}
      />
    </LocalizationProvider>
  );
};
