import React, { Component } from "react";
import {
  CLIENTS_BENEFICIARIES,
  CLIENTS_PUBLISHERS,
  CAMPAIGNS,
  USERS,
  CATEGORIES,
} from "config/constants/api_endpoints";
import {
  showLoader,
  updateSelectedMenuItem,
  updateSelectedGroup,
} from "shared/actions";
import { MENU } from "config/constants";
import { Utility, String } from "utils";
import { connect } from "react-redux";
import { toast } from "react-toastify";
import ScreenView from "shared/components/templates/screen_view";
import PayoutSchedule from "shared/components/payout_schedule";
import StoryForm from "../components/forms/story_form";
import InitiativeForm from "../components/forms/initiative_form";
import PostForm from "../components/forms/post_form";
import CreateCampaign from "../../users/components/create_campaign";
import moment from "moment";
import { CLIENT_ID } from "config/constants/config";
import { CAMPAIGN_TYPES_IDS } from "config/constants";
import NewsCreationForm from "../../../shared/components/NewsCreationForm";
import CreateEvent from "../../../shared/components/v2/leftPanelPages/CreateEvent";
import DiscardChanges from "../../../shared/components/v2/discard_changes";

const validateFields = {
  story: [
    "heading",
    "embedded_video_link",
    "publisher_network_id",
    "storymap_url",
    "embedded_image_link",
  ],
  initiative: [
    "heading",
    "embedded_video_link",
    "publisher_network_id",
    "embedded_image_link",
  ],
  post: ["heading"],
  event: [
    "heading",
    "embedded_video_link",
    "publisher_network_id",
    "category_id",
    "embedded_image_link",
  ],
};

let toastId = null;

class FormContainer extends Component {
  _isMounted = false;

  constructor(props) {
    super(props);

    const state = this.getState(props.formType.id);

    this.state = {
      isDisCard: false,
      menus: this.props.menus,
      handleMenuItemClick: this.props.handleMenuItemClick,
      ...state,
    };
  }

  componentDidMount = () => {
    this._isMounted = true;

    if (!Utility.isEmpty(this.props.userData)) {
      this.getDefaultListDetails();
    }
  };

  componentDidUpdate = (prevProps, prevStates) => {
    const userData = this.props.userData;

    if (
      this.props.formType.id !== prevProps.formType.id ||
      this.props.campId !== prevProps.campId ||
      (Utility.isEmpty(prevProps.userData) && !Utility.isEmpty(userData))
    ) {
      this.setState(this.getState(this.props.formType.id), () => {
        this.getDefaultListDetails();
      });
    }

    if (
      !Utility.isEmpty(this.props.campId) &&
      ((!Utility.isEmpty(this.state.categories) &&
        Utility.isEmpty(prevStates.categories)) ||
        (!Utility.isEmpty(this.state.users) &&
          Utility.isEmpty(prevStates.users)) ||
        (!Utility.isEmpty(this.state.publisherNetworks) &&
          Utility.isEmpty(prevStates.Networks)) ||
        (!Utility.isEmpty(this.state.nonProfits) &&
          Utility.isEmpty(prevStates.nonProfits)))
    ) {
      this.setDefaultValues();
    }
  };

  componentWillUnmount = () => (this._isMounted = false);

  getDefaultListDetails = () => {
    // if (!Utility.isEmpty(this.props.campId))
    //   this.getFormData(this.props.campId);

    // if(this.props.formType.id == 2) this.getCategories(CLIENT_ID, this.props.formType.id);
    this.getPrimaryContactList(CLIENT_ID);
    // this.getBeneficiaryList(CLIENT_ID);
  };

  getState = (campaignTypeId, isReset = false) => {
    const state = this.createFormData(campaignTypeId);
    state.imageFile = "";
    state.imagePreviewUrl = "";
    state.videoFile = "";
    state.videoPreviewUrl = "";
    state.campaignNonProfits = [];
    state.scheduleStart = moment.utc().format();
    state.nonProfits = !isReset ? [] : this.state.nonProfits;
    state.categories = !isReset ? [] : this.state.categories;

    if (campaignTypeId !== CAMPAIGN_TYPES_IDS.post) {
      state.publisherNetworks = !isReset ? [] : this.state.publisherNetworks;
    }

    if (campaignTypeId === CAMPAIGN_TYPES_IDS.story) {
      state.isScheduleNow = true;
    } else if (campaignTypeId === CAMPAIGN_TYPES_IDS.post) {
      state.categories = !isReset ? [] : this.state.categories;
    } else if (campaignTypeId === CAMPAIGN_TYPES_IDS.event) {
      state.isScheduleNow = true;
      state.isEventStart = true;
      state.isEventDatesEditable = false;
      state.users = !isReset ? [] : this.state.users;
    }

    return state;
  };

  createFormData = (formId) => {
    switch (formId) {
      case CAMPAIGN_TYPES_IDS.post:
        return this.postFormData();
      case CAMPAIGN_TYPES_IDS.initiative:
        return this.initiativeFormData();
      case CAMPAIGN_TYPES_IDS.event:
        return this.eventFormData();
      default:
        return this.storyFormData();
    }
  };

  storyFormData = () => {
    return {
      formData: {
        heading: "",
        sub_heading: "",
        description: "",
        video_url: "",
        embedded_video_link: "",
        image_url: "",
        embedded_image_link: "",
        sustainable_development_goal_id: null,
        category_id: null,
        publisher_network_id: null,
        non_profits_id: "",
        storymap_url: "",
        website_url: "",
        hashtags: "",
        is_global_feed: false,
        is_channel_feed: false,
        is_network_feed: false,
      },
    };
  };

  initiativeFormData = () => {
    return {
      formData: {
        heading: "",
        description: "",
        video_url: "",
        embedded_video_link: "",
        image_url: "",
        embedded_image_link: "",
        publisher_network_id: null,
        hashtags: "",
        category_id: null,
      },
    };
  };

  postFormData = () => {
    return {
      formData: {
        heading: "",
        description: "",
        video_url: "",
        image_url: "",
        website_url: "",
        publisher_network_id: null,
        sustainable_development_goal_id: null,
        category_id: null,
        is_global_feed: false,
        is_channel_feed: false,
        is_network_feed: false,
      },
    };
  };

  eventFormData = () => {
    return {
      formData: {
        heading: "",
        sub_heading: "",
        description: "",
        video_url: "",
        embedded_video_link: "",
        image_url: "",
        embedded_image_link: "",
        sustainable_development_goal_id: null,
        publisher_network_id: null,
        non_profits_id: "",
        hashtags: "",
        start_at: moment.utc().format(),
        end_at: moment.utc().format(),
        location: "",
        category_id: null,
        primary_contact_user_id: null,
        primary_contact_client_id: CLIENT_ID,
        is_global_feed: false,
        is_channel_feed: false,
        is_network_feed: false,
      },
    };
  };

  setDefaultValues = () => {
    const formData = this.state.formData;
    const data = {
      client_id: formData.client_id,
      category_id: formData.category_id,
      primary_contact_user_id: formData.primary_contact_user_id,
    };

    const keys = Object.keys(data);
    keys.forEach((key) => {
      const element = document.getElementById(key);

      if (element && !Utility.isEmpty(data[key])) {
        element.value = data[key];
      }
    });
  };

  getFormData = (campId) => {
    this.props.showLoader(true);

    Utility.sendRequest(
      `${CAMPAIGNS}/${campId}`,
      1,
      { signed_url: true },
      (err, res, body) => {
        body = JSON.parse(body);
        const formData = body;
        const scheduleStartDate = formData.CampaignSchedules[0].schedule_start;
        const startAt = formData.start_at;
        const isEventStart =
          moment().diff(formData.start_at) >= 0 &&
          moment().diff(formData.end_at) <= 0
            ? true
            : false;

        // this.getCategories(formData.client_id, formData.campaign_type_id);
        this.setState({
          formData: {
            heading: formData.heading,
            sub_heading: formData.sub_heading,
            description: formData.description,
            video_url: formData.videoUrl,
            embedded_video_link: formData.embedded_video_link,
            image_url: formData.image_url,
            embedded_image_link: formData.embedded_image_link,
            sustainable_development_goal_id:
              formData.sustainable_development_goal_id || null,
            category_id: formData.category_id || null,
            primary_contact_user_id: formData.primary_contact_user_id || null,
            client_id: CLIENT_ID,
            storymap_url: formData.storymap_url,
            website_url: formData.website_url,
            hashtags: formData.hashtags,
            location: formData.location,
            start_at: startAt,
            end_at: formData.end_at,
          },
          scheduleStart: scheduleStartDate,
          imagePreviewUrl: !Utility.isEmpty(formData.image_url)
            ? formData.image_url
            : "",
          videoPreviewUrl: !Utility.isEmpty(formData.video_url)
            ? formData.video_url
            : "",
          imageSignedURL: formData.imageSignedURL,
          videoSignedURL: formData.videoSignedURL,
          campaignNonProfits: formData.CampaignSchedules[0].CampaignNonProfits,
          schedule_start: scheduleStartDate,
          isScheduleNow: moment().diff(scheduleStartDate) >= 0 ? true : false,
          isEventDatesEditable:
            isEventStart || moment().diff(formData.end_at) <= 0 ? false : true,
          isEventStart,
        });

        this.props.showLoader(false);
      }
    );
  };

  getCategories = (client_id, moduleTypeId, event = null) => {
    const params = { client_id: client_id, module_type_id: moduleTypeId };

    if (!Utility.isEmpty(client_id)) {
      Utility.sendRequest(CATEGORIES, 1, params, (err, res, body) => {
        body = JSON.parse(body);
        if (body.error)
          toast.error(body.error.message, { containerId: "private" });
        else this.setState({ categories: body.data });
      });
    } else this.setState({ categories: [] });

    if (event !== null) this.handleChange(event);
  };

  // getPublisherNetworks = (clientId) => {
  //   const params = { client_id: clientId };
  //
  //   Utility.sendRequest(CLIENTS_PUBLISHERS, 1, params, (err, res, body) => {
  //     body = JSON.parse(body);
  //
  //     if (body.error) {
  //       toast.error(body.error.message, { containerId: 'private' });
  //     } else {
  //       const publishers = body.data;
  //       publishers.unshift({ id: '', name: 'Select Network' });
  //
  //       if (this._isMounted) this.setState({ publisherNetworks: body.data });
  //     }
  //   });
  // }

  getBeneficiaryList = (clientId) => {
    const params = { client_id: clientId };

    Utility.sendRequest(CLIENTS_BENEFICIARIES, 1, params, (err, res, body) => {
      body = JSON.parse(body);

      if (body.error) {
        toast.error(body.error.message, { containerId: "private" });
      } else {
        const beneficiaries = body.data;
        beneficiaries.unshift({
          id: "",
          name: "Select a benefiting Nonprofit Organization",
        });

        if (this._isMounted) this.setState({ nonProfits: beneficiaries });
      }
    });
  };

  getPrimaryContactList = (publisherNetworkId) => {
    const params = { client_id: publisherNetworkId };

    if (!Utility.isEmpty(publisherNetworkId)) {
      Utility.sendRequest(USERS, 1, params, (err, res, body) => {
        body = JSON.parse(body);
        if (body.error)
          toast.error(body.error.message, { containerId: "private" });
        else {
          const clientUsers = body;
          clientUsers.unshift({ id: "", user_name: "Select primary contact" });

          this.setState({ users: clientUsers });
        }
      });
    } else this.setState({ users: [] });
  };

  handleChange = (event) => {
    const fieldName = event.target.name;
    const obj = this;
    const formTypeName = obj.props.formType.name;
    let formData = this.state.formData;

    if (fieldName === "non_profits" && event.target.value !== "") {
      const campaignNonProfits = this.state.campaignNonProfits;
      const ind = campaignNonProfits.findIndex(
        (item) => parseInt(item.client_id) === parseInt(event.target.value)
      );

      if (ind === -1) {
        this.addNonprofits(parseInt(event.target.value));
      } else {
        toast.error("This beneficiary is already added!!", {
          containerId: "private",
        });

        const ele = document.getElementById("non_profits");

        if (ele) ele.value = "";
      }
    }

    if (
      fieldName === "embedded_image_link" &&
      Utility.isEmpty(this.state.imagePreviewUrl)
    ) {
      Utility.isFilePathEmpty(
        "image_url",
        event.target.value,
        this.props.formType.name
      );
    }

    formData = Utility.validateAndReturnFormData(
      obj.refs[formTypeName],
      formData,
      event,
      validateFields[formTypeName],
      formTypeName
    );

    this.setState({ formData });
  };

  changeDescription = (data) => {
    const dataHash = this.state.formData;
    dataHash["description"] = data;

    this.setState({ formData: dataHash });

    if (this.props.formType.id !== CAMPAIGN_TYPES_IDS.post) {
      Utility.isRichTextEmpty("description", data, this.props.formType.name);
    } else {
      this.isPostTemplateValid(
        this.state.imagePreviewUrl,
        this.state.videoPreviewUrl,
        this.state.formData.description
      );
    }
  };

  handleFileChange = (inputId, previewURL, file) => {
    if (inputId === "image_url") {
      this.setState({ imagePreviewUrl: previewURL, imageFile: file }, () => {
        if (this.props.formType.id !== CAMPAIGN_TYPES_IDS.post) {
          Utility.isFilePathEmpty(
            "image_url",
            this.state.imagePreviewUrl,
            this.props.formType.name
          );
        } else {
          this.isPostTemplateValid(
            this.state.imagePreviewUrl,
            this.state.videoPreviewUrl,
            this.state.formData.description
          );
        }
      });
    } else if (inputId === "video_url") {
      this.setState({ videoPreviewUrl: previewURL, videoFile: file }, () => {
        if (this.props.formType.id === CAMPAIGN_TYPES_IDS.post) {
          this.isPostTemplateValid(
            this.state.imagePreviewUrl,
            this.state.videoPreviewUrl,
            this.state.formData.description
          );
        }
      });
    }
  };

  handleScheduleDate = (dateEvent, sourceName) => {
    let scheduleDate = moment.utc(dateEvent._d);
    scheduleDate = moment(moment(scheduleDate).format());

    if (sourceName === "schedule_start") {
      this.setState({ scheduleStart: scheduleDate._i });
    } else {
      const formData = { ...this.state.formData };
      formData[sourceName] = scheduleDate._i;

      this.setState({ formData });
    }
  };

  handleScheduleDateCheckbox = (event, sourceName) => {
    if (sourceName === "schedule_now") {
      const ele = document.getElementById("schedule_start");

      if (event.target.checked) {
        ele.classList.add("events-none");

        this.setState({ scheduleStart: moment.utc().format() });
      } else ele.classList.remove("events-none");

      this.setState({ isScheduleNow: event.target.checked });
    } else {
      const startAtField = document.getElementById("start_at");
      const endAtField = document.getElementById("end_at");

      if (event.target.checked) {
        const formData = this.state.formData;

        startAtField.classList.add("events-none");
        endAtField.classList.add("events-none");
        formData.start_at = moment.utc().format();
        formData.end_at = moment.utc().format();

        this.setState({ formData });
      } else {
        startAtField.classList.remove("events-none");
        endAtField.classList.remove("events-none");
      }

      this.setState({ isEventStart: event.target.checked });
    }

    this.handleChange(event);
  };

  removeUploadedFile = (inputId) => {
    if (inputId === "image_url") {
      this.setState({ imageFile: "", imagePreviewUrl: "" }, () => {
        if (Utility.isEmpty(this.state.formData.embedded_image_link)) {
          if (this.props.formType.id !== CAMPAIGN_TYPES_IDS.post) {
            Utility.isFilePathEmpty(
              "image_url",
              this.state.imagePreviewUrl,
              this.props.formType.name
            );
          } else {
            this.isPostTemplateValid(
              this.state.imagePreviewUrl,
              this.state.videoPreviewUrl,
              this.state.formData.description
            );
          }
          document.getElementById("image_url").value = "";
        }
      });
    } else {
      this.setState({ videoFile: "", videoPreviewUrl: "" }, () => {
        if (this.props.formType.id === CAMPAIGN_TYPES_IDS.post) {
          this.isPostTemplateValid(
            this.state.imagePreviewUrl,
            this.state.videoPreviewUrl,
            this.state.formData.description
          );
        }
        document.getElementById("video_url").value = "";
      });
    }
  };

  isPostTemplateValid = (imageUrl, videoUrl, description) => {
    const err = document.getElementById("postTemplateError");
    const errMessage =
      "Please upload either an image or content or video along with the image.";

    if (
      Utility.isEmpty(imageUrl) &&
      Utility.isEmpty(videoUrl) &&
      Utility.isEmpty(description)
    ) {
      err.textContent = errMessage;

      return false;
    } else if (!Utility.isEmpty(videoUrl) && Utility.isEmpty(imageUrl)) {
      err.textContent = errMessage;

      return false;
    } else {
      err.textContent = "";

      return true;
    }
  };

  addNonprofits = (id) => {
    const campaignNonProfits = this.state.campaignNonProfits;
    const nonProfits = this.state.nonProfits;
    const data = nonProfits.find((obj) => parseInt(obj.id) === id);
    const nonprofitsData = {
      client_id: id,
      name: data.name,
      image_url: data.image_url ? data.image_url : null,
      is_active: true,
    };
    campaignNonProfits.push(nonprofitsData);

    this.setState({ campaignNonProfits }, () => {
      const ele = document.getElementById("nonprofits");

      if (ele) ele.value = "";
    });
  };

  createParams = (campaignTypeId, method, isApproved = false) => {
    const userData = this.props.userData;
    let params = Object.assign({}, this.state.formData);

    if (method === "POST") {
      params.author_name = userData.user_name;
      params.author_email = userData.email;
      params.author_id = userData.user_id;
      params.created_by = userData.user_id;
      params.is_approved = isApproved;
      params.source = "web";
    }

    params.user_client_id = userData.id;
    params.client_id = CLIENT_ID;
    params.campaign_type_id = campaignTypeId;
    params.plain_description = Utility.getPlainTextFromRichText(
      params.description
    );
    params.description = params.description.toString("html");
    params.updated_by = userData.user_id;
    params.schedule_start = this.state.scheduleStart;
    params.publisher_network_id = CLIENT_ID;
    params.non_profits = [];
    // params = this.setNonProfitIds(params);

    if (method === "PUT") delete params.publisher_network_id;

    return params;
  };

  // setNonProfitIds = (params) => {
  //   const userData = this.props.userData;
  //   const campaignNonProfitIds = this.state.campaignNonProfits.map((item) => { return item.client_id; });
  //   let selectedPublisherNetwork = {};
  //
  //   if (!Utility.isEmpty(this.state.publisherNetworks)) {
  //      selectedPublisherNetwork = this.state.publisherNetworks.find((item) => { return parseInt(item.id) === parseInt(params.publisher_network_id); });
  //   }
  //
  //   if (Utility.isEmpty(campaignNonProfitIds)) {
  //     if (this.props.formType.id === CAMPAIGN_TYPES_IDS.post && userData.Client.client_type_id === 2 && userData.Client.is_global_media_partner) {
  //       campaignNonProfitIds.push(parseInt(userData.Client.id, 10));
  //     } else if (selectedPublisherNetwork.client_type_id === 2) {
  //       campaignNonProfitIds.push(parseInt(selectedPublisherNetwork.id, 10));
  //     }
  //   }
  //
  //   params.is_beneficiary_associated = campaignNonProfitIds.length > 0 ? true : false;
  //   params.non_profits = campaignNonProfitIds;
  //
  //   return params;
  // }

  removeSelectedNonprofits = (item) => {
    const campaignNonProfits = this.state.campaignNonProfits;
    const ind = campaignNonProfits.findIndex(
      (obj) => obj.client_id === item.client_id
    );

    if (ind !== -1) campaignNonProfits.splice(ind, 1);

    this.refs[
      this.props.formType.name
    ].refs.non_profits.refs.non_profits.selectedIndex =
      campaignNonProfits.length;

    this.setState({ campaignNonProfits });
  };

  uploadFiles = async (id, imageSignedURL, videoSignedURL) => {
    const self = this;
    let imageURL = self.state.imagePreviewUrl;
    let videoURL = self.state.videoPreviewUrl;

    if (!Utility.isEmpty(self.state.imageFile.size)) {
      imageURL = await Utility.uploadFile(self.state.imageFile, imageSignedURL);
    }

    if (!Utility.isEmpty(self.state.videoFile.size)) {
      videoURL = await Utility.uploadFile(self.state.videoFile, videoSignedURL);
    }

    this.updateCampaignMediaUrl(id, imageURL, videoURL);
  };

  updateCampaignMediaUrl = (campId, imageURL, videoURL) => {
    const params = {
      image_url: imageURL,
      video_url: videoURL,
    };

    Utility.sendRequest(
      CAMPAIGNS + "/" + campId,
      3,
      params,
      (err, res, body) => {
        body = JSON.parse(body);

        if (body.error) {
          toast.error(body.error.message, { containerId: "private" });
        }
      }
    );
  };

  handleFormErrors = () => {
    const formName = this.props.formType.name;
    const isValid = Utility.showFormErrors(
      this.refs[formName],
      validateFields[formName],
      formName
    );
    const ele = document.getElementById("agree-terms");

    if (formName === "post") {
      return (
        this.isPostTemplateValid(
          this.state.imagePreviewUrl,
          this.state.videoPreviewUrl,
          this.state.formData.description
        ) && isValid
      );
    }

    const isDescriptionEmpty = Utility.isRichTextEmpty(
      "description",
      this.state.formData.description,
      formName
    );
    const isFilePathEmpty =
      Utility.isFilePathEmpty(
        "image_url",
        this.state.imagePreviewUrl,
        formName
      ) &&
      Utility.isFilePathEmpty(
        "image_url",
        this.state.formData.embedded_image_link,
        formName
      );
    const isTermsConfirmed = ele ? ele.checked : true;

    if (ele) Utility.handleTermsError(isTermsConfirmed);

    return !(
      isDescriptionEmpty ||
      isFilePathEmpty ||
      !isTermsConfirmed ||
      !isValid ||
      (formName === "event" ? !this.isEventDatesValid() : false)
    );
  };

  isEventDatesValid = () => {
    let isFormValid = true;

    if (
      moment(this.state.formData.start_at).diff(this.state.formData.end_at) > 0
    ) {
      if (!toast.isActive(toastId)) {
        toastId = toast.warning("End date should be greater than start date", {
          containerId: "private",
        });
      }
      isFormValid = false;
    } else {
      if (
        moment(this.state.scheduleStart).diff(this.state.formData.start_at) > 0
      ) {
        if (!toast.isActive(toastId)) {
          toastId = toast.warning(
            "Publish date should not be greater than start date",
            { containerId: "private" }
          );
        }
        isFormValid = false;
      }

      return isFormValid;
    }
  };

  resetFormData = () => {
    let selectedGroup = this.props.selectedGroup;

    if (Utility.isEmpty(this.props.campId)) {
      const formType = this.props.formType;
      const state = this.getState(formType.id, true);
      this.setState(state);
    } else {
      if (!Utility.isEmpty(selectedGroup.parent_menu_id)) {
        selectedGroup = MENU.campaigns.find(
          (item) => item.id === selectedGroup.parent_menu_id
        );
        this.props.backToPreviousMenu(selectedGroup);
      }
    }
  };

  createCampaign = (params, campaignTypeId) => {
    Utility.sendRequest(CAMPAIGNS, 2, params, async (err, res, body) => {
      body = JSON.parse(body);

      if (body.error) {
        toast.error(body.error.message, { containerId: "private" });
      } else {
        const campData = body;

        if (
          !Utility.isEmpty(this.state.imagePreviewUrl) ||
          !Utility.isEmpty(this.state.videoPreviewUrl)
        ) {
          await this.uploadFiles(
            campData.id,
            campData.imageSignedURL,
            campData.videoSignedURL
          );
        }

        if (
          [
            CAMPAIGN_TYPES_IDS.story,
            CAMPAIGN_TYPES_IDS.initiative,
            CAMPAIGN_TYPES_IDS.event,
          ].includes(campaignTypeId)
        ) {
          this.props.unApprovedCampaignsCount(campaignTypeId);
        }

        this.props.myCampaignsCount(campaignTypeId);
        this.props.closeSummary(true);
        toast.success(body.message, { containerId: "private" });
      }

      this.props.showLoader(false);
    });
  };

  updateCampaign = (campId) => {
    let selectedGroup = this.props.selectedGroup;
    const params = this.createParams(this.props.formType.id, "PUT");
    const self = this;
    Utility.sendRequest(
      CAMPAIGNS + "/" + campId,
      3,
      params,
      async (err, res, body) => {
        body = JSON.parse(body);

        if (body.error) {
          toast.error(body.error.message, { containerId: "private" });
        } else {
          await this.uploadFiles(
            campId,
            self.state.imageSignedURL,
            self.state.videoSignedURL
          );
          this.props.showLoader(false);

          if (!Utility.isEmpty(selectedGroup.parent_menu_id)) {
            selectedGroup = MENU.campaigns.find(
              (item) => item.id === selectedGroup.parent_menu_id
            );
            this.props.backToPreviousMenu(selectedGroup);
          }

          toast.success(body.message, { containerId: "private" });
        }
      }
    );
  };

  submit = (campaignTypeId, isApproved) => {
    const isFormValid = this.handleFormErrors();

    if (!isFormValid) return;

    this.props.showLoader(true);

    if (Utility.isEmpty(this.props.campId)) {
      const params = this.createParams(campaignTypeId, "POST", isApproved);
      this.createCampaign(params, campaignTypeId);
    } else {
      this.updateCampaign(this.props.campId);
    }
  };

  closeSummary = () => {
    let selectedGroup = this.props.selectedGroup;
    if (
      !Utility.isEmpty(selectedGroup.parent_menu_id) &&
      !Utility.isEmpty(this.props.campId)
    ) {
      selectedGroup = MENU.campaigns.find(
        (item) => item.id === selectedGroup.parent_menu_id
      );
      this.props.backToPreviousMenu(selectedGroup);
    } else {
      this.props.closeSummary();
    }
  };

  render() {
    const formType = this.props.formType;
    const headerLabel =
      (Utility.isEmpty(this.props.campId) ? "Create" : "Edit") +
      " " +
      String.capitalizeFirstLetter(formType.name);

    return (
      <>
        {!Utility.isEmpty(this.props.userData) &&
          Utility.isEmpty(this.props.selectedModule) && (
            <>
              <ScreenView
                hideHeader={
                  formType.id === CAMPAIGN_TYPES_IDS.story ||
                  formType.id === CAMPAIGN_TYPES_IDS.event
                }
                id={"create-" + formType.name}
                label={headerLabel}
                formType={formType}
                campId={this.props.campId}
                backToPreviousMenu={this.props.backToPreviousMenu}
                selectedGroup={this.props.selectedGroup}
                closeSummary={this.closeSummary}
              >
                {formType.id === CAMPAIGN_TYPES_IDS.initiative && (
                  <InitiativeForm
                    {...this.state}
                    ref={formType.name}
                    campId={this.props.campId}
                    handleChange={this.handleChange.bind(this)}
                    handleFileChange={this.handleFileChange.bind(this)}
                    changeDescription={this.changeDescription.bind(this)}
                    removeUploadedFile={this.removeUploadedFile.bind(this)}
                    removeSelectedNonprofits={this.removeSelectedNonprofits.bind(
                      this
                    )}
                    resetFormData={this.resetFormData.bind(this)}
                    submitFormData={this.submit.bind(this)}
                  />
                )}
                {formType.id === CAMPAIGN_TYPES_IDS.post && (
                  <PostForm
                    {...this.state}
                    ref={formType.name}
                    campId={this.props.campId}
                    handleChange={this.handleChange.bind(this)}
                    handleFileChange={this.handleFileChange.bind(this)}
                    removeUploadedFile={this.removeUploadedFile.bind(this)}
                    changeDescription={this.changeDescription.bind(this)}
                    removeSelectedNonprofits={this.removeSelectedNonprofits.bind(
                      this
                    )}
                    resetFormData={this.resetFormData.bind(this)}
                    submitFormData={this.submit.bind(this)}
                  />
                )}

                {formType.id === CAMPAIGN_TYPES_IDS.event && (
                  <>
                    {!this.state.isDisCard ? (
                      <CreateEvent
                        {...this.props}
                        {...this.state}
                        selectedMenuItemId="93ab8d70-3d6b-4f3f-a702-8ead10a90bcf"
                        isFromAdmin={true}
                        runGetCall={this.props.loadStories}
                        changeDisCard={() => {
                          this.setState({ isDisCard: true });
                        }}
                        isUpdate={this.props.campId ? true : false}
                        closeSummary={this.closeSummary}
                        isNotSideBar={true}
                        closeCallout={() => {
                          this.closeSummary();
                        }}
                        hideHead={true}
                      />
                    ) : (
                      <DiscardChanges
                        heading="article"
                        to="/news"
                        isNotSideBar={true}
                        history={this.props.history}
                        changeDisCard={(value) =>
                          this.setState({ isDisCard: value })
                        }
                        closeCallout={() => {
                          this.closeSummary();
                          this.setState({ isDisCard: false });
                        }}
                        selectedMenuItemId="93ab8d70-3d6b-4f3f-a702-8ead10a90bcf"
                        menus={this.state.menus}
                        handleMenuItemClick={this.props.handleMenuItemClick}
                      />
                    )}
                  </>
                )}
                {formType.id === CAMPAIGN_TYPES_IDS.story && (
                  <>
                    {!this.state.isDisCard ? (
                      <>
                        <NewsCreationForm
                          isFromEdmin={true}
                          runGetCall={this.props.loadStories}
                          changeDisCard={() => {
                            this.setState({ isDisCard: true });
                          }}
                          isUpdate={this.props.campId ? true : false}
                          {...this.props}
                          {...this.state}
                          selectedMenuItemId="6f8b1d1e-c537-4580-835b-8b6b58bfd4ec"
                          isNotSideBar={true}
                          hideHead={true}
                          closeCallout={() => {
                            this.closeSummary();
                          }}
                        />
                      </>
                    ) : (
                      <DiscardChanges
                        heading="article"
                        to="/news"
                        selectedMenuItemId2="6f8b1d1e-c537-4580-835b-8b6b58bfd4ec"
                        handleMenuItemClick={this.state.handleMenuItemClick}
                        menus={this.state.menus}
                        isNotSideBar={true}
                        history={this.props.history}
                        changeDisCard={(value) =>
                          this.setState({ isDisCard: value })
                        }
                        closeCallout={() => {
                          this.closeSummary();
                          this.setState({ isDisCard: false });
                        }}
                      />
                    )}
                  </>
                )}
              </ScreenView>
            </>
          )}
      </>
    );
  }
}

const mapActionToProps = {
  showLoader,
  updateSelectedMenuItem,
  updateSelectedGroup,
};

const mapStateToProps = (state) => ({
  userData: state.userData,
  selectedModule: state.selectedModule,
  selectedMenuItem: state.selectedMenuItem,
  selectedGroup: state.selectedGroup,
});

export default connect(mapStateToProps, mapActionToProps)(FormContainer);
