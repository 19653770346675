import React from "react";
import { TickFilledIcon } from "shared/icons/icons";
import { Utility } from "utils";
import { CREATE_EDIT_QUESTIONS } from "../../../config/constants/api_endpoints";
import { Spinner } from "react-bootstrap";

export default function QuestionsList(props) {
  return (
    <div className="mcq-qa mb-3">
      <div className="text-secondary font-weight-bold mb-3 font-16">
        Questions &amp; Answers
      </div>
      {props.question_lists.map((list) => {
        const index =
          list.question_options_attributes !== null &&
          list.question_options_attributes.findIndex((x) => x.is_correct === 1);
        const correctAnswerObj =
          list.question_options_attributes !== null &&
          list.question_options_attributes[index];
        return (
          <div className="mcq-set mb-4" key={list.id}>
            <div className="row">
              <div className="col-5 font-weight-bold">
                <div className="font-18">{list.question_title}</div>
                <div className="text-primary-light font-14 mt-1">
                  {Utility.getQuestionType(list.question_type)}
                </div>
              </div>

              <div className="col-4 d-flex ml-auto">
                <div
                  className="btn-edit flex-center font-weight-bold font-16 text-center cursor-pointer"
                  onClick={() => props.editQuiz(list)}
                >
                  Edit
                </div>
                <div
                  className="btn-edit flex-center ml-5 font-weight-bold font-16 text-center cursor-pointer"
                  onClick={() => props.DeleteQuestion(list, list.id)}
                >
                  {props.deletingQuiz ? (
                    <Spinner animation="border" role="status" />
                  ) : (
                    "Delete"
                  )}
                </div>
              </div>
            </div>
          </div>
        );
      })}
    </div>
  );
}
