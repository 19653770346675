import React from "react";
import ReactDOM from "react-dom/client";
import SMRoutes from "./config/routes";
import { store } from "./store";
import { Provider } from "react-redux";
import * as serviceWorker from "./serviceWorker";
import "./scss/main.scss";
import { createBrowserHistory } from "history";
import { BrowserRouter as Router, Route } from "react-router-dom";
import { Utility } from "utils";
import cookie from "react-cookies";
import { CLIENT_NAME } from "config/constants/config";
const createHistory = createBrowserHistory();
const url = window.location.href;
const params = {};
const domain_name = url
  .replace("http://", "")
  .replace("https://", "")
  .replace("www.", "")
  .split(/[/?#]/)[0];
const arr = domain_name.split(".");
let title = CLIENT_NAME;
let favIcon = "/favicon/favicon.png";

if (arr.length === 3) params.crm_sub_domain = arr.splice(0, 1);
cookie.save("subDomain", {}, { path: "/" });

Utility.updateFavIconAndTitle(title, favIcon);
const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <Provider store={store}>
    <Router>
      <Route component={SMRoutes} history={createHistory} />
    </Router>
  </Provider>
);
// }

// Register service worker for #Ahura because of push notification received from chrome extension
// serviceWorker.register();
