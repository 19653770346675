import { Chip } from "@mui/material";
import React from "react";
import Avatar from "react-avatar";
import { CloseChip } from "../../../../icons/icons";

const UserChip = ({ items, HandleDelete }) => {
  return (
    <>
      <div className="chipContainer">
        <div className="userprofile">
          <Avatar
            src={items.profile_image_url}
            className="userprofile-Avatar"
            name={items.user_name}
          />
          <p className="userprofile-name">{items.user_name}</p>
        </div>
        <div className="Removeuser cursor-pointer" onClick={HandleDelete}>
          <CloseChip />
        </div>
      </div>
    </>
  );
};

export default UserChip;
