import React from 'react';
import { Utility } from 'utils';
import { DownloadIcon, TickFilledIcon, WrongAnswerIcon } from 'shared/icons/icons';

const AssignmentEdit = (props) => {
  return(
    <>
      <div className="text-primary font-weight-bold font-24 mb-5 text-center" >Review Your Answer</div>
        <div className="d-flex align-items-center pb-2" >
          <div className="course-sidecallout d-flex overflow-hidden p-4 align-items-center mcq-set-dark text-white lesson-tile-primary">
            <div className="position-relative ml-2">
              <div className="font-24 font-weight-bold">{props.campaignDetails[0].heading}</div>
              <div className="b-600 font-14 mt-1" >{props.courseDetails[0].heading}</div>
            </div>
        </div>
          <button className="btn btn-primary btn-half-rounded font-weight-bold px-5 ml-auto mt-4 mt-md-0 mr-auto mr-md-0" onClick={props.submitAssignmentDetails}>Confirm Answer</button>
      </div>
      <div className="courses-tab mb-5" >
        
      {props.questionLists && props.questionLists.length > 0 && props.questionLists.map((item, index) => {
          const currentIndex = item.question_options_attributes.findIndex(x => x.selected_answer === true || x.is_essay === true);
          const selectOption = item.question_options_attributes[currentIndex];
          const buttonClass = selectOption && selectOption.selected_answer == selectOption.is_correct ? 'correct-answer-bg' : 'bg-white';
          const buttonText = selectOption && selectOption.selected_answer == selectOption.is_correct ? 'Correct' : 'Incorrect';
        return (
        <div className="mcq-set mb-4 mt-20" key={index}>
          <div className='row' >
            <div className="col-md-5 col-6 font-weight-bold">
              <div className="font-18">{(index + 1)}.{item.question_title}</div>
              <div className="text-primary-light font-14 mt-1" >{Utility.getQuestionType(item.question_type)}</div>
            </div>
            <div className="col-md-5 col-6 d-flex">
              <label className="cursor-pointer mb-0">
                <input type="checkbox" className="toolbox-card-checkbox" />
                <div className="toolbox-card-checkbox-outer">
                  <TickFilledIcon  fillClass="fill-primary" />
                </div>
              </label>
              <div className="font-18 ml-3 font-weight-bold" >{Utility.getAlphabet(currentIndex)}. {selectOption.option_value}</div>
            </div>
            <div className="col-md-2 col-12 mt-4 mt-md-4">
              <button className="btn-edit flex-center font-weight-bold font-16 text-center bg-white border-0" onClick={() => props.editQuestion(item)}>Edit</button>
            </div>
          </div>
        </div>
        )
      })}
      </div>
      <div className="d-flex flex-column align-items-center" >
        <button className="btn btn-primary btn-half-rounded px-5 mb-4 font-weight-bold" onClick={() => props.submitAssignmentDetails(props.campaignDetails[0].campaign_id)} >Confirm Answers</button>
        {/* <button className="btn btn-link" >Start Over</button> */}
      </div>
    </>
  );
}

export default AssignmentEdit;
