export const capitalizeFirstLetter = (string) => {
  if(!string) return null;
  return string.charAt(0).toUpperCase() + string.slice(1);
};

export const eclipsContent = (string, limit) =>
{
  const dots = "...";
  if(string.length > limit)
  {
    string = string.substring(0, limit) + dots;
  }

  return string;
};
