import React, { memo, useEffect, useState } from "react";
import { Table } from "react-bootstrap";
// import { IoIosArrowDown } from 'react-icons/io';
import { Spinner } from "react-bootstrap";
import { NoRecordFound } from "shared/components/no_record_found";
import TableRows from "./table_rows";
import { Utility } from "utils";
import { BsArrowLeftShort } from "react-icons/bs";
import { Skeleton } from "@mui/material";

let TableList = (props) => {
  // const showLoader = props.loader;

  return (
    <>
      {props.selectedViewData &&
        props.selectedViewData.active === "NeighborhoodUsers" && (
          <span
            onClick={() => props.handleBack()}
            className="cursor-pointer font-20"
          >
            <BsArrowLeftShort size={35} />
            Back
          </span>
        )}
      <Table striped responsive size="sm" className="shar-table">
        <TableTitles tableTitles={props.tableTitles} />
        {props.loader ? (
          <tbody className="w-100">
            {[1, 2, 3, 4, 5, 6, 7].map((item) => (
              <tr>
                {[...Array(props.tableTitles.length).keys()].map((item) => (
                  <td>
                    <Skeleton
                      variant="rectangular"
                      className="w-100"
                      height={60}
                      key={item}
                    />
                  </td>
                ))}
              </tr>
            ))}
          </tbody>
        ) : (
          props.data && (
            <TableRows
              removeUserFromNeighborhood={props.removeUserFromNeighborhood}
              alterAdminStatusInNeighborhood={
                props.alterAdminStatusInNeighborhood
              }
              data={props.data}
              onActionClick={props.openChat}
              onToggleClick={props.onToggleClick}
              setAdminAfterToggle={props.setAdminAfterToggle}
              userData={props.userData}
              selectedClients={props.selectedClients}
              selectedUsers={props.selectedUsers}
              addUser={props.addUser}
              selectedMenuItemName={props.selectedMenuItemName}
              editStory={props.editStory}
              editRanking={props.editRanking}
              selectedActionItem={props.selectedActionItem}
              selectedMenuItemId={props.selectedMenuItemId}
              history={props.history}
              allCategories={props.allCategories}
              handleSelectCategory={props.handleSelectCategory}
              remove={props.removeCampaign}
              activateDeactivateUser={props.activateDeactivateUser}
              subCategoriesData={props.subCategoriesData}
              handleSelectSubCategory={props.handleSelectSubCategory}
              selectedViewData={props.selectedViewData}
              handleSetSelectedViewData={props.handleSetSelectedViewData}
            />
          )
        )}
      </Table>
      {Utility.isEmpty(props.data) && !props.loader && <NoRecordFound />}
      {/* {props.showLoader && (
        <span className="justify-content-center">
          <Spinner animation="border" role="status" />
        </span>
      )} */}
    </>
  );
};

export const TableTitles = (props) => {
  return (
    <>
      <thead>
        <tr>
          {props.tableTitles.map((item, index) => {
            return (
              <th className="border-top-0 border-bottom-0" key={index}>
                {item}{" "}
                {/*<IoIosArrowDown className="table-heading-sort ml-2" />*/}{" "}
              </th>
            );
          })}
        </tr>
      </thead>
    </>
  );
};

export default TableList = memo(TableList);
