import React, { Component } from 'react';
import { connect } from 'react-redux';
import { updateSelectedGroup } from 'shared/actions';
import { MenuTitle } from 'shared/components/templates/menu_list';
import { Row, Col, Table, Dropdown } from 'react-bootstrap';
import { ChevronDownIcon, ChevronLeftIcon, CloseIcon } from 'shared/icons/icons';
import SideCallout from 'shared/components/templates/side_callout';
import { CLIENT_LICENSES } from 'config/constants/api_endpoints';
import { Utility } from 'utils';
import { NoRecordFound } from 'shared/components/no_record_found';
import { toast } from 'react-toastify';

class Clients extends Component {
  constructor(props) {
    super(props);

    this.state = {
      callOutOpen : false,
      clientLicense: []
    };
  }

  componentDidMount = () => {
    const params = { platform_id: this.props.userData.platform_id };
    this._isMounted = true;

    Utility.sendRequest(CLIENT_LICENSES, 1, params, (err, res, body) => {
      body = JSON.parse(body);

      if (body.error) {
        toast.error(body.error.message, { containerId: 'private' });
      } else if (this._isMounted) {
        this.setState({ clientLicense: JSON.parse(res.body) });
      }
    });
  }

  trData = () => {
    return this.state.clientLicense.map(e => {
      return(
        <tr key={e.id}>
          <td>
            <div className="align-items-center">
              <div style={{ background: '#d0d0d0' }} className="round-circle mr-2" />
              <span className="font-bold">{e.client_name}</span>
            </div>
          </td>
          <td className="action-td-chat" >
            <div className="align-items-center flex-nowrap">
              <Dropdown>
                <Dropdown.Toggle variant="table-dropdown" id="dropdown-basic">
                  <span>
                    Licenses
                  </span>
                  <ChevronDownIcon/>
                </Dropdown.Toggle>

                <Dropdown.Menu >
                  <Dropdown.Item>Option 1</Dropdown.Item>
                  <Dropdown.Item>Option 2</Dropdown.Item>
                </Dropdown.Menu>
              </Dropdown>
              <div className="bg-primary rounded-circle lesson-img-arrow flex-center mr-3 cursor-pointer" onClick={() => this.setState({ callOutOpen: true })} >
                <ChevronLeftIcon fillClass="fill-white" />
              </div>
            </div>
          </td>
        </tr>
      );
    });
  }

  render() {
    return  (
      <>
        <Col xl={9} lg={8} xs={12} className="ModalOpen top-on-sm h-100 p-4 overflow-auto scroll-y" >
          <Row className="mx-0 p-sm-3">
            <div className="network-box-shadow h-100 mt-3 mt-sm-0">
              <div className="network-item-header d-flex align-items-center">
                <div className="round-circle mr-2">
                </div>
                <MenuTitle menuName={this.props.selectedMenuItem.menu_name}/>
              </div>
            </div>
          </Row>
          <Table striped responsive size="sm" className="shar-table mb-5">
            <thead>
                <tr>
                  <th className="border-top-0 border-bottom-0">Name</th>
                  <th className="border-top-0 border-bottom-0">Action</th>
                </tr>
            </thead>
            <tbody>
              {this.trData().length ? this.trData() : <NoRecordFound /> }
            </tbody>
          </Table>
        </Col>
        {
          this.state.callOutOpen &&
          <SideCallout
            position="position-fixed sub-product-callout"
            calloutHeading='Client Name Details'
            calloutHeadingSvg={<div style={{ background: '#d0d0d0' }} className="round-circle mr-2" />}
            calloutCloseIcon={ <CloseIcon /> }
            closeCallOut={() => this.setState({ callOutOpen: false })}
          >
            <Table striped responsive size="sm" className="shar-table">
              <thead>
                  <tr>
                    <th className="border-top-0 border-bottom-0 font-bold">Subscription</th>
                    <th className="border-top-0 border-bottom-0"></th>
                  </tr>
              </thead>
              <tbody>
                <tr>
                  <td>Base License</td>
                  <td className="cnd-price-box-td" >
                    <div className="cnd-price-box-outer" >
                      <div className="cnd-price-box flex-center font-semi-bold display-8 bg-white">$500 </div>
                    </div>
                  </td>
                </tr>
                 <tr>
                  <td>Per User</td>
                  <td className="cnd-price-box-td" >
                    <div className="cnd-price-box-outer" >
                      <div className="cnd-price-box flex-center font-semi-bold display-8 bg-white">$0.10 </div>
                    </div>
                  </td>
                </tr>
                 <tr>
                  <td>Per Admin</td>
                  <td className="cnd-price-box-td" >
                    <div className="cnd-price-box-outer" >
                      <div className="cnd-price-box flex-center font-semi-bold display-8 bg-white">$100 </div>
                    </div>
                  </td>
                </tr>
                <tr>
                  <td>Per GB</td>
                  <td className="cnd-price-box-td" >
                    <div className="cnd-price-box-outer" >
                      <div className="cnd-price-box flex-center font-semi-bold display-8 bg-white">$0.25 </div>
                    </div>
                  </td>
                </tr>
                <tr>
                  <td>Per Transaction</td>
                  <td className="cnd-price-box-td" >
                    <div className="cnd-price-box-outer" >
                      <div className="cnd-price-box flex-center font-semi-bold display-8 bg-white">$0.30 </div>
                      <span className="mx-2" >+</span>
                      <div className="cnd-price-box flex-center font-semi-bold display-8 bg-white">$2.9% </div>
                    </div>
                  </td>
                </tr>
                <tr>
                  <td className="font-bold" >Sourcing Partner</td>
                  <td className="cnd-price-box-td" >
                    <div className="cnd-price-box-outer" >
                      <Dropdown>
                        <Dropdown.Toggle className="cnd-price-box-dropdown"  variant="cnd-price-box-dropdown" id="dropdown-basic">
                          <img src={require('assets/images/john.png')} className="rounded-circle"  alt="" />
                          <span className="ml-3">Jonathan Hoeflinger</span>
                          <ChevronDownIcon/>
                        </Dropdown.Toggle>

                        <Dropdown.Menu >
                          <Dropdown.Item>Option 1</Dropdown.Item>
                          <Dropdown.Item>Option 2</Dropdown.Item>
                        </Dropdown.Menu>
                      </Dropdown>
                    </div>
                  </td>
                </tr>
                <tr>
                  <td>Sourcing Partner %</td>
                  <td className="cnd-price-box-td" >
                    <div className="cnd-price-box-outer" >
                      <div className="cnd-price-box flex-center font-semi-bold display-8 bg-white">$5.0 %</div>
                    </div>
                  </td>
                </tr>
              </tbody>
            </Table>
            <div className="mb-lg-5 text-center" >
              <button className="my-lg-5 my-4 btn btn-primary br-15 font-bold px-5" style={{ borderRadius: 25 }} >Update</button>
            </div>
          </SideCallout>
        }
      </>
    );
  }
}

const mapStateToProps = (state) => ({
  userData: state.userData,
  selectedMenuItem: state.selectedMenuItem,
  selectedGroup: state.selectedGroup
});

const mapActionToProps = { updateSelectedGroup };

export default connect(mapStateToProps, mapActionToProps)(Clients);
