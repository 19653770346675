import { css } from "@emotion/core";
import React, { Component } from "react";
import moment from "moment";
import { connect } from "react-redux";
import { toast } from "react-toastify";
import { Tab, Tabs } from "react-bootstrap";
import { Utility } from "utils";
import {
  CAMPAIGNS,
  CATEGORIES_BY_MODULE_ID,
  CAMPAIGNS_BY_ID,
} from "config/constants/api_endpoints";
import { CLIENT_ID, CRM_MODULES } from "config/constants/config";
import { ChevronLeftIcon } from "shared/icons/icons";
import Section from "./sections";
import Assignment from "./assignment";
import CourseForm from "./course_form";
import { showLoader } from "shared/actions";
import { withRouter } from "react-router-dom";
import { CAMPAIGN_TYPES_IDS } from "../../../config/constants";

const validateFields = {
  courses: [
    "embedded_image_link",
    "image_url",
    "category_id",
    "description",
    "heading",
  ],
};

// const override = css`
//   display: block;
//   margin: 0 auto;
//   border-color: red;
//   z-index: 100;
//   position: absolute;
//   top: 50%;
//   left: 50%;
//   font-size: 50px;
//   color: white;
//   transform: translate(-50%, -50%);
// `;

class CourseContainer extends Component {
  constructor(props) {
    super(props);
    this.state = {
      formName: "courses",
      categoriesList: [],
      formStates: this.initialState(),
      newCampaign: this.getFormData(),
      isLoading: this.props.isLoading,
      isClose: false,
      isCreateCourseLoading: false,
    };
  }

  initialState = () => {
    return {
      selectedCampaignId: this.props.editCampaignId,
      selectedMenuItemId: this.props.selectedMenuItemId,
      menus: this.props.menus,
      openCategory: this.props.openCategory,
      handleMenuItemClick: this.props.handleMenuItemClick,
      showDropdown: false,
      isEdit: false,
      isCreate: true,
      isNewChap: false,
      isChapToggle: false,
      isCreateOrEdit: false,
      search: null,
      imageFile: "",
      imagePreviewUrl: "",
      videoFile: "",
      ranking: 10,
      videoPreviewUrl: "",
    };
  };

  getFormData = () => {
    const userData = { ...this.props.userData };

    return {
      heading: "",
      sub_heading: "",
      description: "",
      location: "",
      createrId: [],
      video_url: "",
      embedded_video_link: "",
      image_url: "",
      embedded_image_link: "",
      imageSignedURL: "",
      videoSignedURL: "",
      ranking: 10,
      parent_image_url: "",
      parent_heading: "",
      category_id: null,
      parent_id: null,
      non_profits_id: null,
      campaign_type_id: "39ab8d70-3d6b-4f3f-a702-8ead10a90bcf",
      author_name: userData.user_name,
      author_email: userData.email,
      author_id: userData.user_id,
      created_by: userData.user_id,
      publisher_network_id: CLIENT_ID,
      updated_by: userData.user_id,
      user_client_id: userData.id,
      schedule_start: new Date(),
      client_id: CLIENT_ID,
      non_profits: [],
      organizers: [],
      groups: [],
      is_public: true,
      corporate_id: userData.client_id || "",
    };
  };

  getCategoriesByModuleId = () => {
    const params = {
      module_id: CRM_MODULES.CAMPAIGNS,
      client_id: CLIENT_ID,
      campaign_type_id: "39ab8d70-3d6b-4f3f-a702-8ead10a90bcf",
    };

    if (this.props.campaignSelectedTab !== "group") {
      Utility.sendRequest(
        CATEGORIES_BY_MODULE_ID,
        1,
        params,
        (err, res, body) => {
          body = JSON.parse(body);

          if (body.error) {
            toast.error(body.error.message, { containerId: "private" });
          } else {
            this.setState({ categoriesList: body.data });
          }
        }
      );
    }
  };

  handleCreaterId = (values) => {
    const error = Utility.getElementById("category_idError");
    if (error) error.innerText = "";
    this.setState({
      createrId: values,
    });
  };

  handleDropdown = (evKey) => {
    const formData = { ...this.state.newCampaign };
    formData[evKey.split(",")[0]] = evKey.split(",")[1];

    Utility.getElementById("category_idError").innerText = "";

    this.setState({
      newCampaign: formData,
    });
  };

  handleInputChange = (ev) => {
    let formData = { ...this.state.newCampaign };
    formData = Utility.validateAndReturnFormData(
      this.refs[this.state.formName],
      formData,
      ev,
      validateFields[this.state.formName],
      this.state.formName
    );
    this.setState({ newCampaign: formData });
  };

  changeDescription = (data) => {
    const dataHash = this.state.newCampaign;
    dataHash["description"] = data;

    this.setState({ newCampaign: dataHash });
  };

  getCampaignsById = (create_campaign_id) => {
    const formStates = { ...this.state.formStates };

    if (this.state.formStates.selectedCampaignId) {
      const params = {
        campaign_id: create_campaign_id
          ? create_campaign_id
          : this.state.formStates.selectedCampaignId,
        client_id: CLIENT_ID,
        user_client_id: this.props.userData.id,
      };

      Utility.sendRequest(CAMPAIGNS_BY_ID, 1, params, (err, res, body) => {
        body = JSON.parse(body);
        if (body.error) {
          toast.error(body.error.message, { containerId: "private" });
        } else {
          if (body.data) {
            if (body.data.corporate_id) {
              this.setState({
                createrId: [body.data.corporate_id],
              });
            }
            this.setState(
              {
                newCampaign: {
                  ...body.data,
                  start_at: moment.utc(body.data.start_at).local(),
                  end_at: moment.utc(body.data.end_at).local(),
                },
              },
              () => {
                formStates.imagePreviewUrl = this.state.newCampaign.image_url;
                formStates.videoPreviewUrl = this.state.newCampaign.video_url;
                formStates.isEdit = true;
                formStates.isCreate = false;
                formStates.isNewChap = false;
                formStates.isCreateOrEdit = true;
                formStates.showDropdown = false;
                this.setState({
                  formStates,
                });
              }
            );
          }
        }
      });
    }
  };

  componentDidMount = () => {
    this.getCategoriesByModuleId();
    this.getCampaignsById();
  };

  handleFileChange = (inputId, previewURL, file) => {
    const formStates = { ...this.state.formStates };

    if (inputId === "image_url") {
      formStates.imagePreviewUrl = previewURL;
      formStates.imageFile = file;
    } else if (inputId === "video_url") {
      formStates.videoPreviewUrl = previewURL;
      formStates.videoFile = file;
    }

    this.setState({ formStates }, () => {
      if (Utility.isEmpty(this.state.newCampaign.embedded_image_link))
        Utility.isFilePathEmpty(
          "image_url",
          this.state.formStates.imagePreviewUrl,
          this.state.formName
        );
    });
  };

  removeUploadedFile = (inputId) => {
    const formStates = { ...this.state.formStates };
    const newCampaign = { ...this.state.newCampaign };

    if (inputId === "image_url") {
      formStates.imagePreviewUrl = "";
      formStates.imageFile = "";
      newCampaign.image_url = "";
    } else {
      formStates.videoPreviewUrl = "";
      formStates.videoFile = "";
      newCampaign.video_url = "";
    }
    this.setState({ formStates }, () => {
      this.setState({ newCampaign });
    });
  };

  handleIsFeatured = (value) => {
    const newCampaign = { ...this.state.newCampaign };
    if (value) {
      newCampaign.ranking = 5;
      this.setState({
        newCampaign: newCampaign,
      });
    } else {
      newCampaign.ranking = 10;
      this.setState({
        newCampaign: newCampaign,
      });
    }
  };
  handlePublicPrivate = (value) => {
    const newCampaign = { ...this.state.newCampaign };

    newCampaign.is_public = value;
    this.setState({
      newCampaign: newCampaign,
    });
  };

  handleFormErrors = () => {
    const Heading = Utility.getElementById("heading");
    let isValid;
    let headingError;
    let mediaError = false;
    if (!(this.state.createrId && this.state.createrId.length > 0)) {
      const error = Utility.getElementById("category_idError");

      if (error) error.innerText = "Neighbourhood is a required field";
      isValid = false;
    } else {
      const error = Utility.getElementById("category_idError");
      if (error) error.innerText = "";
      isValid = true;
    }
    if (!(Heading && Heading.value)) {
      const error = Utility.getElementById("headingError");

      if (error) error.innerText = "Heading is a required field";
      headingError = false;
    } else {
      const error = Utility.getElementById("headingError");
      if (error) error.innerText = "";
      headingError = true;
    }

    if (
      !(
        this.state.formStates.imagePreviewUrl ||
        this.state.formStates.videoPreviewUrl ||
        this.state.newCampaign.embedded_image_link ||
        this.state.newCampaign.embedded_video_link
      )
    ) {
      const errormedia = Utility.getElementById("image_urlError");
      if (errormedia) {
        errormedia.textContent =
          "Either select an image/video or fill up the embedded link below";
      }
      mediaError = false;
    } else {
      const errormedia = Utility.getElementById("image_urlError");
      if (errormedia) errormedia.innerText = "";
      mediaError = true;
    }

    return isValid && mediaError && headingError ? true : false;
  };

  createCampaign = (item, index) => {
    // this.props.showLoader(true);
    this.setState({
      isCreateCourseLoading: true,
    });
    Utility.sendRequest(
      CAMPAIGNS,
      2,
      {
        ...this.state.newCampaign,
        description: this.state.newCampaign.description
          ? this.state.newCampaign.description
          : " ",
        corporate_id: item,
      },
      async (err, res, body) => {
        body = JSON.parse(body);

        if (body.error) {
          toast.error(body.error.message, { containerId: "private" });
          this.setState({
            isCreateCourseLoading: false,
          });
        } else {
          const campData = body;
          const afterSuccessCampaign = body;
          afterSuccessCampaign.createdOrUpdated = " created ";
          afterSuccessCampaign.image_url =
            this.state.formStates.imagePreviewUrl;
          afterSuccessCampaign.author_image_url =
            this.props.userData.profile_image_url;
          afterSuccessCampaign.campaign_category =
            this.state.categoriesList.map((e) => {
              return e.id == afterSuccessCampaign.category_id ? e.name : "";
            });
          this.state.categoriesList.forEach((e) => {
            if (e.id === afterSuccessCampaign.category_id)
              afterSuccessCampaign.category_color = e.color_code;
          });

          if (
            !Utility.isEmpty(this.state.formStates.imagePreviewUrl) ||
            !Utility.isEmpty(this.state.formStates.videoPreviewUrl)
          ) {
            await this.uploadFiles(
              campData.id,
              campData.imageSignedURL,
              campData.videoSignedURL,
              item,
              index
            );
          }

          if (this.props.location.pathname !== "/course-create") {
            this.props.loadStories(this.props.campaignSelectedTab);
            this.setState(
              {
                newCampaign: this.getFormData(),
                formStates: this.initialState(),
              },
              () => {
                if (
                  this.state.createrId &&
                  this.state.createrId.length === index + 1
                ) {
                  this.setState({ afterSuccessCampaign }, () => {
                    this.props.setIsFormOpen(false);
                    const item = this.functions(
                      this.props.menus,
                      this.props.selectedMenuItemId
                    );
                    this.props.handleMenuItemClick(item);
                  });
                }
              }
            );
          }
          this.setState({
            isCreateCourseLoading: false,
          });
        }
      }
    );
  };

  updateCampaign = (item, index) => {
    // this.props.showLoader(true);
    this.setState({
      isCreateCourseLoading: true,
    });
    Utility.sendRequest(
      CAMPAIGNS + "/" + this.state.formStates.selectedCampaignId,
      3,
      {
        ...this.state.newCampaign,
        description: this.state.newCampaign.description
          ? this.state.newCampaign.description
          : " ",
      },
      async (err, res, body) => {
        body = JSON.parse(body);

        if (body.error) {
          toast.error(body.error.message, { containerId: "private" });

          this.setState({
            isCreateCourseLoading: false,
          });
        } else {
          if (
            !Utility.isEmpty(this.state.newCampaign.imageSignedURL) &&
            !Utility.isEmpty(this.state.newCampaign.videoSignedURL)
          ) {
            await this.uploadFiles(
              this.state.formStates.selectedCampaignId,
              this.state.newCampaign.imageSignedURL,
              this.state.newCampaign.videoSignedURL,
              item,
              index
            );
          }
          const afterSuccessCampaign = body;
          afterSuccessCampaign.createdOrUpdated = " updated ";
          afterSuccessCampaign.image_url =
            this.state.formStates.imagePreviewUrl;
          afterSuccessCampaign.author_image_url =
            this.props.userData.profile_image_url;
          afterSuccessCampaign.campaign_category =
            this.state.categoriesList.map((e) => {
              return e.id == afterSuccessCampaign.category_id ? e.name : "";
            });
          this.state.categoriesList.forEach((e) => {
            if (e.id === afterSuccessCampaign.category_id)
              afterSuccessCampaign.category_color = e.color_code;
          });
          if (this.props.location.pathname !== "/course-create") {
            this.setState(
              {
                newCampaign: this.getFormData(),
                formStates: this.initialState(),
              },
              () => {
                if (
                  this.state.createrId &&
                  this.state.createrId.length === index + 1
                ) {
                  this.setState({ afterSuccessCampaign }, () => {
                    this.setState({ showSuccess: true });
                  });
                  this.props.loadStories(this.props.campaignSelectedTab);
                  this.props.setIsFormOpen(false);
                }
              }
            );
          } else {
            if (this.state.isClose) {
              this.props.history.push(
                `/confirm-submit/${this.state.formStates.selectedCampaignId}`
              );
            } else {
              this.setState({
                formStates: {
                  ...this.state.formStates,
                  imagePreviewUrl: this.state.formStates.imagePreviewUrl,
                  videoPreviewUrl: this.state.formStates.videoPreviewUrl,
                },
              });
              this.getCampaignsById(this.state.formStates.selectedCampaignId);
            }
          }

          this.setState({
            isCreateCourseLoading: false,
          });
        }
      }
    );
  };

  uploadFiles = async (id, imageSignedURL, videoSignedURL, item, index) => {
    let imageURL = this.state.formStates.imagePreviewUrl;
    let videoURL = this.state.formStates.videoPreviewUrl;

    if (!Utility.isEmpty(this.state.formStates.imageFile.size)) {
      imageURL = await Utility.uploadFile(
        this.state.formStates.imageFile,
        imageSignedURL
      );
    }

    if (!Utility.isEmpty(this.state.formStates.videoFile.size)) {
      videoURL = await Utility.uploadFile(
        this.state.formStates.videoFile,
        videoSignedURL
      );
    }
    await this.updateCampaignMediaUrl(id, imageURL, videoURL, item, index);
  };

  updateCampaignMediaUrl = (campId, imageURL, videoURL, item, index) => {
    const params = {
      image_url: imageURL,
      video_url: videoURL,
    };

    Utility.sendRequest(
      CAMPAIGNS + "/" + campId,
      3,
      params,
      (err, res, body) => {
        body = JSON.parse(body);

        if (body.error) {
          toast.error(body.error.message, { containerId: "private" });
        } else {
          if (
            this.state.createrId &&
            this.state.createrId.length === index + 1
          ) {
            if (this.props.location.pathname !== "/course-create") {
              this.props.loadStories(this.props.campaignSelectedTab);
              this.props.setIsFormOpen(false);
              const item = this.functions(
                this.props.menus,
                this.props.selectedMenuItemId
              );
              this.props.handleMenuItemClick(item);
            } else {
              if (this.state.isClose) {
                this.props.history.push(`/confirm-submit/${campId}`);
              } else {
                const newCampaigns = {
                  ...this.state.newCampaign,
                  image_url: imageURL,
                  video_ur: videoURL,
                };

                const FormState = {
                  ...this.state.formStates,
                  selectedCampaignId: campId,
                  selectedMenuItemId: CAMPAIGN_TYPES_IDS.courses,
                  showDropdown: false,
                  isEdit: false,
                  isCreate: false,
                  isNewChap: false,
                  isChapToggle: false,
                  isCreateOrEdit: false,
                  search: null,
                  imageFile: "",
                  imagePreviewUrl: imageURL,
                  videoFile: "",
                  ranking: 10,
                  videoPreviewUrl: videoURL,
                };
                this.setState({
                  formStates: FormState,
                  newCampaign: newCampaigns,
                });
                this.getCampaignsById(campId);
              }
            }
          }
        }
      }
    );
  };

  submit = (ev, key) => {
    ev.preventDefault();
    const isFormValid = this.handleFormErrors();
    if (!isFormValid) {
      var elmnt = document.getElementById("form");
      if (elmnt) elmnt.scrollIntoView();
      return;
    }
    if (key === "close") {
      this.setState({
        isClose: true,
      });
    }
    if (this.state.formStates.isCreate || this.state.formStates.isNewChap) {
      this.state.createrId &&
        this.state.createrId.map((item, index) => {
          this.createCampaign(item, index);
        });
    } else
      this.state.createrId &&
        this.state.createrId.map((item, index) => {
          this.updateCampaign(item, index);
        });
  };

  functions(menus, selectedMenuItemId) {
    if (menus && menus.length) {
      for (var i = 0; i < menus.length; i++) {
        if (selectedMenuItemId === menus[i].id) {
          // console.log(selectedMenuItemId)

          return menus[i];
        }
      }
    }
  }

  render() {
    console.log(this.props, "from");
    // console.log(this.props, "menus")
    const { selectedCampaignId } = this.state.formStates;
    const { heading } = this.state.newCampaign;

    const item = this.functions(
      this.props.menus,
      this.props.selectedMenuItemId
    );

    return (
      <>
        {/* {this.props.location.pathname !== "/course-create" &&
          this.props.isLoading && (
            <div className="sweet-loading loader">
              Loading ...
              <ClipLoader
                css={override}
                sizeUnit={"px"}
                size={80}
                color={"#0db6ea"}
                loading={true}
              />
            </div>
          )} */}
        <div className="h-100 scroll-y top-on-sm pl-5 pr-5 pb-5 d-none d-lg-block col-xl-9 col-lg-8 col-12">
          <div className=" mt-4">
            <div className="d-flex flex-wrap justify-content-between">
              {this.props.location.pathname !== "/course-create" && (
                <div className="align-items-center w-100 mb-4">
                  <ChevronLeftIcon
                    mainClass="mr-3 ml-n4 cursor-pointer"
                    handleClick={() => this.props.handleMenuItemClick(item)}
                  />
                  <span className="font-20 font-medium">
                    {heading || "Create Course"}
                  </span>
                </div>
              )}
              <div className="align-items-center mt-2 mt-sm-0 w-100">
                <div className="d-block w-100 courses-tab">
                  <Tabs id="uncontrolled-tab-example">
                    <Tab eventKey="course" title="Course Syllabus">
                      <CourseForm
                        {...this.state}
                        ref={this.state.formName}
                        key={"course"}
                        handleDropdown={this.handleDropdown}
                        item={item}
                        handleCreaterId={this.handleCreaterId}
                        handleMenuItemClick={this.props.handleMenuItemClick}
                        setIsFormOpen={this.props.setIsFormOpen}
                        handleFileChange={this.handleFileChange}
                        removeUploadedFile={this.removeUploadedFile}
                        handleChange={this.handleInputChange}
                        submit={this.submit}
                        handleIsFeatured={this.handleIsFeatured}
                        changeDescription={this.changeDescription}
                        handlePublicPrivate={this.handlePublicPrivate}
                        isCreateCourseLoading={this.state.isCreateCourseLoading}
                        calledFrom={this.props.calledFrom}
                      />
                    </Tab>
                    <Tab eventKey="section" title="Sections & Lessons">
                      <div className="row mx-0 justify-content-between mt-3">
                        {selectedCampaignId ? (
                          <Section campId={selectedCampaignId} />
                        ) : (
                          <p>
                            You will have to create course first to enable this
                            tab.
                          </p>
                        )}
                      </div>
                    </Tab>
                    <Tab eventKey="assignment" title="Assignments">
                      {selectedCampaignId ? (
                        <Assignment campId={selectedCampaignId} />
                      ) : (
                        <p className="mt-3">
                          You will have to create course first to enable this
                          tab.
                        </p>
                      )}
                    </Tab>
                  </Tabs>
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
}

const mapActionToProps = { showLoader };

const mapStateToProps = (state) => ({
  userData: state.userData,
  publicUserData: state.publicUserData,
  neighborhoodState: state.neighborhoodState,
  isLoading: state.isLoading,
  isChatFormOpen: state.isChatFormOpen,
  seeAll: state.seeAll,
  campaignSelectedTab: state.campaignSelectedTab,
  campaignId: state.campaignFormSelectedId,
  isCreateCourseLoading: state.isCreateCourseLoading,
});

export default connect(
  mapStateToProps,
  mapActionToProps
)(withRouter(CourseContainer));
