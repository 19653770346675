import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Row } from 'react-bootstrap';
import { MENU } from  'config/constants';
import { showLoader, updateSelectedMenuItem } from 'shared/actions';
import { Utility } from 'utils';
import { DROPDOWN_ACTIONS, SM_CLIENT_ID } from 'config/constants';
import Menu from 'shared/components/menu';
import StatementList from 'scenes/finances/statements/statement_list';
import AccountList from 'scenes/finances/accounts/account_list';
import Billing from 'scenes/finances/billing';
import Clients from 'scenes/finances/clients';
import Licenses from 'scenes/finances/licenses';
import { WelcomeOverlays } from 'shared/components/welcome_overlays';

class FinanceContainer extends Component {
  constructor(props) {
    super(props);

    props.showLoader(Utility.isEmpty(props.userData));
    props.updateSelectedMenuItem(MENU.finances[0]);
  }

  componentDidUpdate = () => {
    if (Utility.isEmpty(this.props.selectedMenuItem) && !Utility.isEmpty(this.props.userData)) {
      this.setState({ selectedMenuItem: MENU.finances[0] });
    }
  }

  handleMenuItemClick = (selectedMenuItem) => {
    this.props.updateSelectedMenuItem(selectedMenuItem);
    Utility.showComponent(true, 'account-list');
  }

  componentDidUpdate = () => {
    const list = document.getElementById('menu-list');
    if (list) list.classList.add('d-block');
  }

  render() {
    const { is_super_owner, platform_id, role } = this.props.userData;
    if (is_super_owner || role !== 'USER') {
      return (
        <Row className="no-gutters h-100">
          {
            !Utility.isEmpty(this.props.userData) &&
            Utility.isEmpty(this.props.selectedModule) &&
            <Menu
              selectedModule={{ name: 'Finances' }}
              forFinance={true}
              dropdownItems={DROPDOWN_ACTIONS.finances}
              handleMenuItemClick={this.handleMenuItemClick}
              selectedMenuItemId={this.props.selectedMenuItem.id}
              menus={MENU.finances}
              menus2={MENU.financesClients}
              isButton={false}
              isSuperOwner={this.props.userData.is_super_owner}
            />
          }
          {
            this.props.selectedMenuItem.id === 1 && !Utility.isEmpty(this.props.userData) &&
            <AccountList
              userData={this.props.userData}
              selectedMenuItem={this.props.selectedMenuItem}
              updateSelectedMenuItem={this.props.updateSelectedMenuItem}
            />
          }
          {
            this.props.selectedMenuItem.id === 2 && !Utility.isEmpty(this.props.userData) &&
            <Licenses />
          }
          {
            this.props.selectedMenuItem.id === 3 &&
            <StatementList
              userData={this.props.userData}
              selectedMenuItem={this.props.selectedMenuItem} updateSelectedMenuItem={this.props.updateSelectedMenuItem}
            />
          }
          {
            this.props.selectedMenuItem.id === 4 &&
            <Billing
              userData={this.props.userData}
              selectedMenuItem={this.props.selectedMenuItem} updateSelectedMenuItem={this.props.updateSelectedMenuItem}
            />
          }

          {
            this.props.selectedMenuItem.id === 5 &&
            <Clients
              userData={this.props.userData}
              selectedMenuItem={this.props.selectedMenuItem} updateSelectedMenuItem={this.props.updateSelectedMenuItem}
            />
          }
        </Row>
      );
    }
    else
    {
      return (
        <WelcomeOverlays
          heading="Welcome to Finances!"
          subHeading="Manage the financial flow of your digital community transactions."
          mainText="Ask for a customized DEMO to learn how all the available features of this module can be used to serve the requirements of your digital application."
          btnText="See example!"
        />
      );
    }

  }
}

const mapActionToProps = { showLoader, updateSelectedMenuItem };

const mapStateToProps = (state) => ({
  userData: state.userData,
  selectedModule: state.selectedModule,
  selectedMenuItem: state.selectedMenuItem,
  accountList: state.accountList,
});

export default connect(mapStateToProps, mapActionToProps)(FinanceContainer);
