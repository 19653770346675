import React from "react";
import { useSelector } from "react-redux";
import { ROLE_IDS } from "../../../config/constants";
import LockIcon from "@mui/icons-material/Lock";
import { useLocation } from "react-router-dom";
import { Skeleton } from "@mui/material";

const ProfileActivity = ({ profileActivities, userData, ...props }) => {
  const User = useSelector((state) => state.userData);
  const location = useLocation();
  const handleItemClick = (data) => {
    if (data.campaign_type_id === "6f8b1d1e-c537-4580-835b-8b6b58bfd4ec")
      props.history.push({
        pathname: `/news-details/${data.campaign_id}`,
      });
    else if (data.campaign_type_id === "93ab8d70-3d6b-4f3f-a702-8ead10a90bcf") {
      props.history.push({
        pathname: `/event-details/${data.campaign_id}`,
      });
    } else
      props.history.push({
        pathname: `/campaign/${data.campaign_id}`,
      });
  };
  return (
    <div
      className={`${
        User.id !== userData.id &&
        userData.role_id === ROLE_IDS.USER &&
        (props.is_Followed === false || props.is_Followed === null)
          ? "overflow-none"
          : ""
      } AboutMainContainer position-relative pr-0 ff-Inter-Bold scroll-y activity-container`}
    >
      {/* {location.pathname.includes("profile") && (
        <>
          {User.id !== userData.id &&
            userData.role_id === ROLE_IDS.USER &&
            (props.is_Followed === false || props.is_Followed === null) && (
              <div className="overlay-Lock">
                <LockIcon />
              </div>
            )}
        </>
      )} */}
      <div className="PanalHeader pr-3 d-flex align-items-center justify-content-between">
        <p className="section-head ff-Inter-Bold d-flex align-items-center">
          Activities
        </p>
      </div>
      <div>
        {props.loading ? (
          [1, 2, 3, 4, 5, 6].map((item) => (
            <Skeleton variant="text" height={50} className="mx-3" key={item} />
          ))
        ) : profileActivities && profileActivities.length > 0 ? (
          profileActivities.map((items) => {
            switch (items.campaign_type_id) {
              case "6f8b1d1e-c537-4580-835b-8b6b58bfd4ec":
                return (
                  <div className="activity-cards-container">
                    {/* <NewsCard
                      key={items.id}
                      data={items}
                      onClick={() => handleItemClick(items)}
                    /> */}
                    <div className="activity-name">
                      {userData.user_name}
                      <p className="activity-actionName ml-2">
                        {items.liked_id ? "Liked" : " Commented on "} News{" "}
                        <span
                          onClick={() => handleItemClick(items)}
                          className="cursor-pointer campaign-heading-activity"
                        >
                          {items.heading}
                        </span>
                      </p>{" "}
                    </div>
                  </div>
                );
              case "93ab8d70-3d6b-4f3f-a702-8ead10a90bcf":
                return (
                  <div className="activity-cards-container">
                    {/* <EventCard key={items.id} items={items} /> */}
                    <div className="activity-name">
                      {userData.user_name}
                      <p className="activity-actionName ml-2">
                        {items.liked_id ? "Liked" : " Commented on "} Event{" "}
                        <span
                          onClick={() => handleItemClick(items)}
                          className="cursor-pointer campaign-heading-activity"
                        >
                          {items.heading}
                        </span>
                      </p>{" "}
                    </div>
                  </div>
                );
              default:
                return (
                  <div className="activity-cards-container">
                    {/* <CourseCard
                      key={items.id}
                      data={items}
                      onClick={() => handleItemClick(items)}
                    /> */}
                    <div className="activity-name">
                      {userData.user_name}
                      <p className="activity-actionName ml-2">
                        {items.liked_id ? "Liked" : " Commented on "} Course{" "}
                        <span
                          onClick={() => handleItemClick(items)}
                          className="cursor-pointer campaign-heading-activity"
                        >
                          {items.liked_id ? items.heading : items.course_name}
                        </span>
                      </p>{" "}
                    </div>
                  </div>
                );
            }
          })
        ) : (
          <div className="no-found">
            <span className="font-bold display-6">
              No Recent Activities found
            </span>
          </div>
        )}
      </div>
    </div>
  );
};

export default ProfileActivity;
