import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { Utility } from 'utils';
import {
  CLIENT_ID
} from "config/constants/config";
import {
  GIL_CLIENT_ID
} from "config/constants";
import { HiHome } from 'react-icons/hi';

export class SidebarMenuItem extends Component {
  selectModule = (moduleName) => {
    if (['Home', 'Communications', 'Networks', 'Campaigns', 'Statistics', 'Finances', 'Shops'].includes(Utility.capitalize(moduleName))) {
      moduleName = moduleName.toLowerCase();
      this.props.history.push(`/${moduleName}`);
      document.getElementById('inner-nav').click();
    }
  }

  render() {
    const IconComponent = this.props.item.icon_component;

    return (
      <li className={this.props.isMenuActive ? 'active sidebar-selected-item' : 'sidebarItemBorderLeft'} 
        onClick={() => { this.selectModule(this.props.item.name); }}>
        <Link to={{}} title={this.props.item.name} className="flex-column" >
          {/* <img className={this.props.item.id === 0 ? 'dashboard-icon' : ''}
          src={require('assets/images/' + this.props.item.image_url)} alt={alt} /> */}
          <IconComponent fillClass="fill-black" />
          <div className="text-captalize d-inline d-lg-none text-dark display-7 font-bold ml-2" >{this.props.item.name}</div>
          <div className="icon-label-sideBar">{this.props.item.name}</div>
        </Link>
      </li>
    );
  }
}
