import React, { useState, useEffect } from "react";
import HomeContainer from "shared/components/home_container";
import CreatebleCard from "shared/components/cards/creatable_card";
import GroupCard from "shared/components/cards/group_card";
import InviteCard from "shared/components/cards/invite_card";
import { InputField } from "shared/components/form_elements";
import { toast } from "react-toastify";
import { Form, Spinner } from "react-bootstrap";
import { Utility } from "utils";
import {
  GROUPS,
  MY_INVITATION,
  GROUP_MEMBERS,
  GETALLJOINEDGROUPS,
} from "config/constants/api_endpoints";
import { CLIENT_ID, GROUPS_LIMIT } from "config/constants/config";
import { CAMPAIGN_TYPES_IDS } from "config/constants";
import CreateCampaign from "../users/components/create_campaign";
import {
  updateCampaignFormOpen,
  updateCampaignSelectedTab,
  updateSeeAll,
  updateRefreshCampaignAPI,
  showLoader,
  updateIsChatFormOpen,
  updateChatFriend,
} from "shared/actions";
import { useSelector, useDispatch } from "react-redux";
import SeeAll from "shared/components/v2/see_all";
import { MdPhoto } from "react-icons/md";
import Avatar from "react-avatar";

const pwaModeEnabled = Utility.pwaModeEnabled();

export default function GroupHome(props) {
  const dispatch = useDispatch();
  const userData = useSelector((state) => state.userData);
  const seeAll = useSelector((state) => state.seeAll);
  const isCampaignFormOpen = useSelector((state) => state.isCampaignFormOpen);
  const isChatFormOpen = useSelector((state) => state.isChatFormOpen);
  const refreshCampaignApi = useSelector((state) => state.refreshCampaignApi);
  const updateRefreshCampaignAPI = useSelector(
    (state) => state.refreshCampaignApi
  );
  const [groupsData, setGroupsData] = useState([]);
  const [search, setSearch] = useState("");
  const [invitation, setInvitations] = useState([]);
  const [refresh, setRefresh] = useState(0);
  const [loader, setLoader] = useState(false);
  const [allGroups, setAllGroups] = useState([]);
  const [seeMoreOrLess, setSeeMoreOrLess] = useState("See More");
  const [groupsLength, setgroupsLength] = useState(0);
  const [joinedGroups, setAllJoinedGroups] = useState([]);
  let debouncedFn = null;

  useEffect(() => {
    dispatch(showLoader(true));
    getGroups();
    getGroupsYouHaveJoined(0);
    getGroupsYouHaveJoined();
    dispatch(updateCampaignFormOpen(false));
    window.scrollTo(0, 0);
    return () => {
      dispatch(updateCampaignSelectedTab(CAMPAIGN_TYPES_IDS.story));
      !pwaModeEnabled && dispatch(updateCampaignFormOpen(false));
    };
  }, [refresh]);

  useEffect(() => {
    getGroups();
    getGroupsYouHaveJoined(0);
    getGroupsYouHaveJoined();
  }, [refreshCampaignApi, isChatFormOpen]);

  useEffect(() => {
    if (props.location.calledFrom === "profileGroupsSeeAll") {
      dispatch(
        updateSeeAll({ isOpen: "publicGroups", calledFrom: "publicGroups" })
      );
    }
    getGroups();
    myInvitation();
  }, [search]);

  const handleSearch = (ev) => {
    ev.persist();

    if (!debouncedFn) {
      debouncedFn = Utility.debounce(() => {
        const searchString = ev.target.value;
        setSearch(searchString);
      }, 500);
    }
    debouncedFn();
  };

  const doRefresh = () => {
    setRefresh(Math.random());
  };

  const getGroups = () => {
    dispatch(showLoader(true));
    const params = {
      clientId: CLIENT_ID,
      user_client_id: userData.id,
      search,
      isPrivate: false,
    };

    Utility.sendRequest(GROUPS, 1, params, (err, res, body) => {
      body = JSON.parse(body);
      if (body.error) {
        toast.error(body.error.message, { containerId: "private" });
      } else {
        setGroupsData(body);
      }
      dispatch(showLoader(false));
    });
  };

  const getGroupsYouHaveJoined = (limit = 3) => {
    const params = {
      user_client_id: userData.id,
    };
    if (limit === 3) {
      params.limit = limit;
    }

    Utility.sendRequest(GETALLJOINEDGROUPS, 1, params, (err, res, body) => {
      body = JSON.parse(body);
      if (body.error) {
        console.log(body.error);
      } else {
        if (limit === 0) {
          setgroupsLength(body.data.length);
          setAllJoinedGroups(body.data);
        }
        setAllGroups(body.data);
      }
    });
  };

  const handleGroupClick = (selectedGroup) => {
    const group = joinedGroups.filter(
      (item) => item.group_id === selectedGroup.id
    );
    if (!Utility.isEmpty(group)) {
      //group[0].group_id = group[0].id;
      dispatch(updateIsChatFormOpen(true));
      dispatch(updateChatFriend(group[0]));
    } else {
      selectedGroup.group_id = selectedGroup.id;
      dispatch(updateIsChatFormOpen(true));
      dispatch(
        updateChatFriend({
          ...selectedGroup,
          fromTopics: true,
        })
      );
    }
  };

  const myInvitation = () => {
    Utility.sendRequest(
      MY_INVITATION + "/" + userData.id,
      1,
      null,
      (err, res, body) => {
        body = JSON.parse(body);

        if (!body.error) {
          setInvitations(body);
        }
      }
    );
  };

  // const paintGroups = (groupsDataArg, publicGroup) => {
  //   const arr = [];
  //   groupsDataArg.length &&
  //     groupsDataArg.map(e => {
  //       arr.push(
  //         <GroupCard
  //           key={e.id}
  //           id={e.id}
  //           link={`/groups/${e.id}`}
  //           imageUrl={e.image_url}
  //           name={e.name}
  //           publicGroup={publicGroup}
  //           calledFrom={seeAll.calledFrom}
  //           goBackLink='/groups'
  //           description={e.description}
  //         />
  //       );
  //     });
  //   return (
  //     arr
  //   );
  // };

  const handleInvitationBtnClick = (
    groupMemberId,
    approvedOrDeclined,
    groupName
  ) => {
    const params = {
      status: approvedOrDeclined,
    };

    Utility.sendRequest(
      GROUP_MEMBERS + "/" + groupMemberId,
      3,
      params,
      (err, res, body) => {
        body = JSON.parse(body);

        if (!body.error) {
          toast.success(`You have ${approvedOrDeclined} to join ${groupName}`, {
            containerId: "private",
          });
          getGroups(userData.id);
          getGroupsYouHaveJoined();
          myInvitation();
        }
      }
    );
  };

  const paintMyInvitations = () => {
    return invitation.map((e) => {
      return (
        <InviteCard
          key={e.id}
          data={e}
          handleClick={handleInvitationBtnClick}
          showButton={true}
        />
      );
    });
  };

  const handleChatClick = (item) => {
    dispatch(updateIsChatFormOpen(true));
    dispatch(updateChatFriend(item));
  };

  const seeMoreOrLessCourses = () => {
    if (seeMoreOrLess === "See More") {
      setSeeMoreOrLess("See Less");
      getGroupsYouHaveJoined(0);
    } else {
      setSeeMoreOrLess("See More");
      getGroupsYouHaveJoined();
    }
  };

  const decrypteGroupMessages = (data) => {
    const actualMessage = Utility.decryptAllMessages(
      data.recent_message,
      "",
      "",
      data.group_private_key
    );
    return actualMessage;
  };

  return (
    <>
      <HomeContainer
        name="Groups"
        className="group-home"
        goBackLink="/home"
        history={props.history}
      >
        <p>Form your own mentorship team or manage other mentor groups.</p>
        <h1 className="font-24-bold mb-4 home-page-heading">
          My Groups
          {allGroups.length > 2 && !Utility.pwaModeEnabled() && (
            <span
              className="see-all-text display-7"
              onClick={() =>
                dispatch(
                  updateSeeAll({
                    isOpen: "publicGroups",
                    calledFrom: "publicGroups",
                  })
                )
              }
            >
              See All
            </span>
          )}
        </h1>
        {Utility.pwaModeEnabled() &&
          !Utility.isEmpty(allGroups) &&
          allGroups.map((items) => {
            return (
              <>
                <div className="pwa-chat-view">
                  <div
                    onClick={() => handleChatClick(items)}
                    className="px-1 pwa-chat-unit align-items-center flex-nowrap position-relative cursor-pointer"
                  >
                    <div className="rounded-circle ml-n1" />
                    {!Utility.isEmpty(items.image_url) ? (
                      <img
                        className="pcu-img rounded-circle"
                        src={items.image_url}
                        alt=""
                      />
                    ) : (
                      <Avatar
                        name={items.name}
                        round={true}
                        size={"60"}
                        className="pcu-img"
                        textSizeRatio={1.75}
                        color="#007bb3"
                        maxInitials={1}
                      />
                    )}
                    <div className="pcu-text-outer" style={{ marginLeft: 8 }}>
                      <div className="font-weight-bold text-truncate display-8">
                        {items.name}
                      </div>
                      <div className="text-truncate font-14 b-500">
                        {items.recent_message ? (
                          decrypteGroupMessages(items)
                        ) : (
                          <>
                            {items.recent_message_time && (
                              <>
                                <div className="media-image">
                                  <MdPhoto />
                                </div>
                                Media
                              </>
                            )}
                          </>
                        )}
                      </div>
                    </div>
                    {items.unread_messages_count && (
                      <div className="align-self-end bg-primary text-white pcu-count flex-center b-500">
                        {items.unread_messages_count}
                      </div>
                    )}
                    <div className="font-12 position-absolute pcu-time">
                      {items.recent_message_time &&
                        Utility.returnDuration(items.recent_message_time)}
                    </div>
                  </div>
                </div>
              </>
            );
          })}
        {Utility.pwaModeEnabled() && groupsLength > 3 && (
          <div className="text-center mb-4 mt-2">
            <span
              onClick={() => seeMoreOrLessCourses()}
              className="cursor-pointer font-weight-bold display-8 text-primary"
            >
              {seeMoreOrLess}
            </span>
          </div>
        )}
        {!Utility.isEmpty(invitation) && (
          <div className="mt-3 pb-4">
            <h1 className="font-24-bold mb-4">My Invitations</h1>
            <div className="group-container d-flex flex-wrap">
              {paintMyInvitations()}
            </div>
          </div>
        )}
        <div className="mt-3 pb-4">
          <h1 className="font-24-bold mb-4">Public Groups</h1>
          <Form className="shar-form search-form search-form-pwa sidebar-form">
            <Form.Group className="mb-30 align-items-center flex-nowrap d-flex">
              <InputField
                type="text"
                handleChange={(ev) => handleSearch(ev)}
                placeholder="Search Public Groups..."
                className="mr-3 mb-0"
              />
              {loader && <Spinner animation="border" size="sm" role="status" />}
            </Form.Group>
          </Form>
          {Utility.isEmpty(groupsData) && (
            <div className="d-flex align-items-center justify-content-center mt-5">
              <div className="px-5">
                <span className="font-bold display-6">No groups found</span>
              </div>
            </div>
          )}
          {Utility.pwaModeEnabled() &&
            !Utility.isEmpty(groupsData) &&
            groupsData.map((items) => {
              return (
                <>
                  <div className="pwa-chat-view">
                    <div
                      onClick={() => handleGroupClick(items)}
                      className="px-1 pwa-chat-unit align-items-center flex-nowrap position-relative cursor-pointer"
                    >
                      <div className="rounded-circle ml-n1" />
                      {!Utility.isEmpty(items.image_url) ? (
                        <img
                          className="pcu-img rounded-circle"
                          src={items.image_url}
                          alt=""
                        />
                      ) : (
                        <Avatar
                          name={items.name}
                          round={true}
                          size={"60"}
                          className="pcu-img"
                          textSizeRatio={1.75}
                          color="#007bb3"
                          maxInitials={1}
                        />
                      )}
                      <div className="pcu-text-outer" style={{ marginLeft: 8 }}>
                        <div className="font-weight-bold text-truncate display-8">
                          {items.name}
                        </div>
                        <div className="text-truncate font-14 b-500">
                          {items.recent_message ? (
                            decrypteGroupMessages(items)
                          ) : (
                            <>
                              {items.recent_message_time && (
                                <>
                                  <div className="media-image">
                                    <MdPhoto />
                                  </div>
                                  Media
                                </>
                              )}
                            </>
                          )}
                        </div>
                      </div>
                      {items.unread_messages_count && (
                        <div className="align-self-end bg-primary text-white pcu-count flex-center b-500">
                          {items.unread_messages_count}
                        </div>
                      )}
                      <div className="font-12 position-absolute pcu-time">
                        {items.recent_message_time &&
                          Utility.returnDuration(items.recent_message_time)}
                      </div>
                    </div>
                  </div>
                </>
              );
            })}
        </div>
      </HomeContainer>
      {seeAll.isOpen && (
        <SeeAll
          closeCallout={() =>
            dispatch(updateSeeAll({ isOpen: null, calledFrom: null }))
          }
          calledFrom={seeAll.calledFrom}
          width={"100%"}
        />
      )}
      {isCampaignFormOpen && Utility.pwaModeEnabled() && (
        <CreateCampaign
          // doRefresh={() => dispatch(updateRefreshCampaignAPI(Math.random()))}
          position="position-absolute gil-pwa-callout"
          loadedFrom="webCampaign"
          isFormOpen={isCampaignFormOpen}
          setIsFormOpen={(arg) => dispatch(updateCampaignFormOpen(arg))}
        />
      )}
    </>
  );
}
