export const UPDATE_USER_DATA = 'update_user_data';
export const UPDATE_PUBLIC_USER_DATA = 'update_public_user_data';
export const UPDATE_CLIENT_TYPES = 'update_client_types';
export const UPDATE_SD_GOALS = 'update_sd_goals';
export const UPDATE_CRM_MODULES = 'update_crm_modules';
export const SHOW_LOADER = 'show_loader';
export const UPDATE_SELECTED_MODULE = 'update_selected_module';
export const UPDATE_SELECTED_MENU_ITEM = 'update_selected_menu_item';
export const UPDATE_SELECTED_GROUP = 'update_selected_group';
export const EDIT_COMPONENT = 'edit';
export const UPDATE_PRODUCTED_ID = 'product_id';
export const UPDATE_ATTRIBUTES = 'update_attributes';
export const UPDATE_SELECTED_FILTERS = 'update_selected_filters';
export const UPDATE_RANGE_VALUE = 'update_range_value';
export const UPDATE_SELECTED_MODULE_MENU = 'update_selected_module_menu';
export const UPDATE_ACCOUNT_LIST = 'update_account_list';
export const OPEN_NEIGHBORHOOD_FORM = 'update_neighborhood_state';
export const IS_CAMPAIGN_FORM_OPEN = 'is_campaign_form_open';
export const IS_NEIGHBORHOOD_FORM_OPEN = 'is_neighborhood_form_open';
export const IS_CAMP_SEL_FORM_OPEN = 'is_camp_sel_form_open';
export const CAMPAIGN_SELECTED_TAB = 'campaign_selected_tab';
export const CAMPAIGN_FORM_SELECTED_ID = 'campaign_form_selected_id';
export const IS_CHAT_FORM_OPEN = 'isChatFormOpen';
export const CHAT_FRIEND = 'chatFriend';
export const SEE_ALL = 'seeAll';
export const REFRESH_CAMPAIGN_API = 'refreshCampaignApi';
export const REFRESH_EVENTS_API = 'refreshEventApi';
export const OPEN_CITY_PURCHASE = 'open_city_purchase';
export const UPDATE_LESSONS = 'update_lessons';
export const PLAN_UPGRADE = 'plan_upgrade';
export const ADMIN_ACCESS_REQUEST = 'admin_access_request';
export const LISTENFORNEWMESSAGE = 'listen_for_new_message';
export const ACCEPT_ADMIN_REQUEST = 'accept_admin_request';
export const OPEN_PLUS_ICON = 'open_plus_icon';
export const CURRENT_FOOTER_LINK = 'current_footer_link';
export const CART_ADD_ITEM = 'cart_add_item';
export const CART_REMOVE_ITEM = 'cart_remove_item';
export const UPDATE_GUEST_DATA = 'update_guest_data';
export const USER_DISTRACTED = 'user_distracted';
export const SESSION_START = 'start_session';
export const EXT_TOPIC = 'ext_topic';
export const EXTENSION_DATA = 'extension_data';
export const IS_CAMPAIGN_VIDEO_PLAYING = 'is_campaign_video_playing';
export const CATEGORY_LISTING_INFO = 'category_listing_info';
export const CART_REMOVE_ALL = 'cart_remove_all';
export const IS_SESSION_MODAL_OPEN = 'is_session_modal_open';
export const NEW_CORPORATE_JOINED = 'new_corporate_joined';
export const SHOW_EXTENSION_MODAL_REPORT = 'show_extension_modal_report';
export const IS_COURSE_SIDEBAR = "is_course_sidebar";
export const IS_COURSE_DROPDOWN = "is_course_dropdown";
export const IS_PAGE_COURSE = "is_page_course";
export const IS_CITIZEN_SIDEBAR_OPEN = "is_citizen_sidebar_open";
export const HEADER_SEARCH_BAR = "header_search_bar";
export const USER_PROFILE_ID = "user_profile_id";
export const SELECTED_LOCATION = "selected_location";
export const SELECTED_SIDEBAR_ITEM = "selected_sidebar_item";
export const UPDATE_USER_NEIGHBORHOOD = "update_user_neighborhood";