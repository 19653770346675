import React from "react";
import { DocumentsIcon } from "../../../../shared/icons/icons";

const DiscussionsDocs = ({ docs, search }) => {
  const data = docs.filter(({ name }) =>
    name.toLowerCase().includes(search.toLowerCase())
  );
  return (
    <div>
      <table className=" table table-discussions table-md table-hover ">
        <tbody className="">
          {data.length > 0 ? (
            data.map(({ name, id, url }) => {
              return (
                <tr key={id} className="tr-discussions">
                  <td className="border-0 doc-name pl-4">
                    <DocumentsIcon />
                    <span className="ml-3 doc-name">{name}</span>
                  </td>
                </tr>
              );
            })
          ) : (
            <div className="d-flex justify-items-center w-100">
              <h4 className="m-3 mx-auto">No record found</h4>
            </div>
          )}
        </tbody>
      </table>
    </div>
  );
};

export default DiscussionsDocs;
